import { useMutation } from "react-query";
import { useState } from "react";
import { createMultiProjectSpaceObject } from "@src/features/projectSpaceObjects/api";
import LexoRank from "@src/utils/LexoRank";
import { CEILING_OBJECT_ID, FLOOR_OBJECT_ID, SPACE_LOCATIONS, WALL_OBJECT_ID } from "../../utils";

const useSpaceObjectAdd = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { isLoading, mutateAsync } = useMutation(
    ({ input }) => {
      setError("");
      setIsSuccess(false);
      return createMultiProjectSpaceObject(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createMultiProjectSpaceObjects.success) {
          setError(`Error: ${data.createMultiProjectSpaceObjects.message}`);
        } else {
          setIsSuccess(true);
        }
      },
      onError: (e) => {
        setError(`Error: ${e.message}`);
      },
    }
  );

  const getInteriorSpaceObjects = (projectSpace) => {
    const objects = [];
    let rank = LexoRank.getNewRank(objects, 0, 0);
    objects.push(
      ...Array.from({ length: 4 }).map(() => {
        const obj = {
          name: "Wall",
          objectsId: WALL_OBJECT_ID,
          projectSpaceId: projectSpace.id,
          sortOrder: rank.toString(),
        };
        rank = rank.increment();
        return obj;
      })
    );
    objects.push({
      name: "Floor",
      objectsId: FLOOR_OBJECT_ID,
      projectSpaceId: projectSpace.id,
      sortOrder: rank.toString(),
    });
    rank = rank.increment();
    objects.push({
      name: "Ceiling",
      objectsId: CEILING_OBJECT_ID,
      projectSpaceId: projectSpace.id,
      sortOrder: rank.toString(),
    });
    return objects;
  };

  const addObjectToProjectSpace = async (projectSpace, spaceLocation = SPACE_LOCATIONS.INTERIOR) => {
    if (spaceLocation === SPACE_LOCATIONS.INTERIOR) {
      const mutationResult = await mutateAsync({
        input: getInteriorSpaceObjects(projectSpace),
      });
      const { data: newObjects } = mutationResult.createMultiProjectSpaceObjects;
      return newObjects;
    }
  };

  return {
    isLoading,
    isSuccess,
    error,
    addObjectToProjectSpace,
  };
};

export default useSpaceObjectAdd;
