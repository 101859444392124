import { useMemo } from "react";
import { useQuery } from "react-query";
import { getColors } from "../../api";

const useColors = (filters = {}, hookOptions = { disableQuery: false }) => {
  const { data, isLoading } = useQuery(["colors", filters], () => getColors(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const contactsAsOptions = useMemo(() => {
    if (!data?.getColors?.length) {
      return [];
    }
    return data.getColors.map((color) => {
      return {
        value: color.id,
        title: color.name,
        color,
      };
    });
  }, [data]);

  return {
    data: data?.getColors,
    isLoading,
    options: contactsAsOptions,
  };
};

export default useColors;
