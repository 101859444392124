const uiSchema = {
  groups: [
    {
      title: "Person",
      properties: ["firstName", "lastName", "email", "phone", "leadSource"],
    },
    {
      title: "Location",
      properties: ["address"],
    },
    {
      title: "Scope",
      properties: ["type", "rooms"],
    },
    {
      title: "Budget",
      properties: ["clientBudgetLow", "clientBudgetHigh"],
      className: "grid grid-cols-2 gap-2",
    },
    {
      title: "Project Owner",
      properties: ["staff"],
      isLast: true,
    },
  ],
};

export default uiSchema;
