import { useQuery } from "react-query";
import { getMetabaseDashboard } from "../api";

const useNotes = (dashboardId) => {
  const { data, isLoading, isSuccess } = useQuery(["dashboard", dashboardId], () => getMetabaseDashboard(dashboardId), {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.getMetabaseDashboard,
    isLoading,
    isSuccess,
  };
};

export default useNotes;
