import AddProduct from "./AddProduct";
import AddProductInline from "./AddProductInline";
import EditProduct from "./EditProduct";

export const productActions = {
  add: {
    id: "add",
    items: {
      "add single product": {
        id: "add-single-product",
        label: "Add single product",
        sidebar: AddProduct,
      },
      "add multiple products": {
        id: "add-multiple-products",
        label: "Add multiple products",
        inline: AddProductInline,
      },
    },
  },
  edit: {
    id: "edit",
    label: "Edit",
    modal: EditProduct,
  },
  delete: {
    id: "delete",
    label: "Delete",
    modal: () => <div className="p-10">Delete product form</div>,
  },
  "add media": {
    id: "add media",
    label: "Add Media",
    modal: () => <div className="p-10">Add media form</div>,
  },
  "add note": {
    id: "add note",
    label: "Add Note",
    modal: () => <div className="p-10">Add note form</div>,
  },
  "add document": {
    id: "add document",
    label: "Add Document",
    modal: () => <div className="p-10">Add document form</div>,
  },
  "set thumbnail": {
    id: "set thumbnail",
    label: "Set Thumbnail",
    modal: () => <div className="p-10">Set thumbnail form</div>,
  },
};
