import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchaseOrders: [],
  purchaseOrderItems: [],
};

export const purchaseOrdersSlice = createSlice({
  name: "purchaseOrders",
  initialState,
  reducers: {
    setPurchaseOrders: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        purchaseOrders: action.payload,
      };
    },
    setPurchaseOrderItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        purchaseOrderItems: action.payload,
      };
    },
  },
});

export const { setPurchaseOrders, setPurchaseOrderItems } = purchaseOrdersSlice.actions;

export default purchaseOrdersSlice.reducer;

export const getPurchaseOrders = (state) => state.purchaseOrders.purchaseOrders;
export const getPurchaseOrderItems = (state) => state.purchaseOrders.purchaseOrderItems;
