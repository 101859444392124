import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Button, EmptyState } from "@buildappeal/react-component-library";

const ERROR_404_IMAGE = "https://res.cloudinary.com/build-appeal/image/upload/v1645213219/Error404_c3sx2a.jpg";

const Error404Page = () => {
  return (
    <div className={clsx("flex h-[calc(100vh_-_64px)] w-full items-center justify-center")}>
      <EmptyState
        title="Sorry, we couldn't find that page."
        subtitle="Sorry, this page either doesn't exist anymore or you have a broken link."
        image={ERROR_404_IMAGE}
      >
        <Link to="/">
          <Button label="Back to home" size="md" appearance="primary" />
        </Link>
      </EmptyState>
    </div>
  );
};

export default Error404Page;
