import {
  Button,
  EmailField,
  FileUpload,
  Notice,
  PersonField,
  PhoneField,
  ProgressBar,
  SectionGroup,
} from "@buildappeal/react-component-library";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createStaff } from "./api";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  avatar: {
    file: null,
    filename: null,
    type: null,
  },
  status: "active",
};

const AddStaff = ({ onClose }) => {
  const queryClient = useQueryClient();
  const [formState, setFormState] = useState({ ...initialFormState });
  const [loading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const isFormValid = () => {
    if (!formState?.email?.length) {
      showErrMsg("Email is Mandatory");
      return false;
    }

    showErrMsg("");
    return true;
  };

  const showErrMsg = (msg) => {
    setErrMsg(msg);
    if (msg) {
      showErrMsgNotification();
    }
  };
  const showErrMsgNotification = () => {
    setShowErrorMessage(true);
    setTimeout(() => {
      setShowErrorMessage(false);
    }, 2000);
  };

  const createStaffMutation = useMutation(
    (data) => {
      setIsLoading(true);
      return createStaff(data);
    },
    {
      onSuccess: (data) => {
        if (!data.createStaff.success) {
          showErrMsgNotification();
          return;
        }

        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
        setFormState({ ...initialFormState });

        queryClient.setQueryData("staff", (old) => {
          if (!old) return { getStaff: [data.createStaff.data] };
          return { getStaff: [data.createStaff.data, ...old.getStaff] };
        });
        onClose();
      },
      onError: () => {
        showErrMsgNotification();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit = () => {
    if (!isFormValid()) {
      return;
    }
    const data = {
      ...formState,
      fullName: `${formState.firstName} ${formState.lastName}`,
    };
    createStaffMutation.mutate(data);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;

      handleOnChange("avatar", {
        file: binaryStr.split("base64,")[1],
        filename: file.name,
        type: file.type,
      });
    };

    reader.readAsDataURL(file);
  };

  const handleOnChange = async (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCancel = (field) => {
    setFormState((prev) => ({
      ...prev,
      [field]: {
        file: null,
        filename: null,
        type: null,
      },
    }));
  };
  return (
    <div className="mt-16 flex w-full flex-col justify-center pl-10 pr-14">
      <div className="w-full">
        {loading && <ProgressBar min={0} max={100} now={90} className="my-4" />}
        {!loading && showSuccessMessage && <Notice type="success" message="Staff successfully created" />}
        {!loading && showErrorMessage && (
          <Notice type="critical" message={errMsg || "Staff creation failed. Please try again!"} />
        )}
        <SectionGroup className="my-4" title="Create Staff">
          <p className="mt-8 text-md font-normal text-neutral-800">Details</p>
          <PersonField
            value={{
              firstName: formState.firstName,
              lastName: formState.lastName,
            }}
            onChange={({ target: { value } }, fieldName) => setFormValue({ [fieldName]: value })}
            className="mt-5"
          />
          <PhoneField
            label="Phone Number"
            value={formState.phone}
            onChange={(value) => setFormValue({ phone: value })}
            className="mt-3"
            defaultCountry="US"
            maxLength={14}
          />
          <EmailField
            label="Email"
            value={formState.email}
            onChange={({ target: { value } }) => setFormValue({ email: value })}
            className="mt-3"
            required
          />
          <p className="mt-12 text-md font-normal text-neutral-800">Profile Photo</p>
          <FileUpload
            handleSelectedFiles={(files) => {
              files.length > 0 ? handleFileUpload(files[0]) : handleCancel("avatar");
            }}
            acceptedFileTypes={["image/jpeg", "image/png", "image/heic", "image/webp"]}
            multiple={false}
            appearance="button"
            buttonLabel="Add photo"
            maxFiles={1}
            maxFileSize={1000000}
            // uploadResults={uploadResults}
            className="mt-8"
          />

          <Button
            className="mt-8 w-full"
            disabled={loading}
            appearance="primary"
            label="Create Staff"
            size="sm"
            onClick={onSubmit}
          />
        </SectionGroup>
      </div>
    </div>
  );
};
export default AddStaff;
