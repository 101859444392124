import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

const FileInfoLoading = ({
  spaceOptions = [],
  isImage,
  src = "",
  showCoordinatesSection,
  finishOptions,
  productTypeOptions,
}) => {
  return (
    <div>
      <div className="mt-2 flex flex-col gap-y-4">
        {!!spaceOptions?.length && <Skeleton className="h-14 w-full" />}
        <Skeleton className="h-14 w-full" />
        {isImage && !!src && showCoordinatesSection && <Skeleton className="h-14 w-full" />}
        <Skeleton className="h-14 w-full" />
        {isImage && !!src && <Skeleton className="h-14 w-full" />}
        {!!finishOptions?.length && <Skeleton className="h-14 w-full" />}
        {!!productTypeOptions?.length && <Skeleton className="h-14 w-full" />}
      </div>
      {isImage && (
        <div className="flex justify-between py-2">
          <Skeleton className="h-7 !w-[74px]" />
          <Skeleton className="h-7 !w-[48px] rounded-full" />
        </div>
      )}
      <Skeleton className="h-9 w-full" />
    </div>
  );
};

FileInfoLoading.propTypes = {
  spaceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  isImage: PropTypes.bool,
  src: PropTypes.string,
  showCoordinatesSection: PropTypes.bool,
  finishOptions: PropTypes.array, // eslint-disable-line
  productTypeOptions: PropTypes.array, // eslint-disable-line
};

export default FileInfoLoading;
