import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { truncate, chunk } from "lodash";
import {
  UilImage as ThumbnailIcon,
  UilMultiply as CloseIcon,
  UilAngleUp as UpIcon,
  UilAngleDown as DownIcon,
  UilBox as BoxIcon,
} from "@iconscout/react-unicons";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { UisCheckCircle as CheckIcon, UisTimesCircle as FailIcon } from "@iconscout/react-unicons-solid";

import ConfirmationModal from "@utils/components/ConfirmationModal";
import useUpdateFiles from "@features/files/hooks/useUpdateFiles";
import useUpdateProducts from "@features/products/hooks/useUpdateProducts";
import useUpdatePartners from "@features/partners/hooks/useUpdatePartners";
import useUpdateProjects from "@features/projects/hooks/useUpdateProjects";
import { areItemsAlreadyInQueue, actionResponseKeyMap } from "./utils";

const GenActionUpdateBulkItems = ({
  itemsInput,
  skipQueryUpdate,
  projectId,
  onSuccess,
  onClose,
  triggeredBy,
  numberBatchToSend = 5,
}) => {
  const [updateQueue, setUpdateQueue] = useState([]);
  const [fullUpdateStatus, setFullUpdateStatus] = useState({});
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isViewUp, setIsViewUp] = useState(true);
  const [updateState, setUpdateState] = useState({});

  const { updateFilesAsync } = useUpdateFiles({
    projectId,
    skipQueryUpdate,
  });
  const { updateProductsAsync } = useUpdateProducts();
  const { updatePartnersAsync } = useUpdatePartners();
  const { updateProjectsAsync } = useUpdateProjects();

  const updateItems = async () => {
    if (!updateQueue.length) return;
    if (["productsMultiSelect", "partnersMultiSelect"].includes(triggeredBy)) {
      onSuccess?.();
    }

    const filesToUpdate = [...itemsInput];
    const newUpdateStatus = {};
    filesToUpdate.forEach((item) => {
      if (!newUpdateStatus[item.groupTitle]) {
        newUpdateStatus[item.groupTitle] = {};
      }
      newUpdateStatus[item.groupTitle][item.name] = {
        status: "Saving",
        name: item.name,
        groupTitle: item.groupTitle,
      };
    });

    setFullUpdateStatus((prevState) => ({ ...prevState, ...newUpdateStatus }));
    const filesSet = chunk(filesToUpdate, numberBatchToSend);
    for (let i = 0; i < filesSet.length; i++) {
      const currentSet = filesSet[i];
      const inputs = currentSet.map((item) => item.input);
      const updateStatus =
        triggeredBy === "filesMultiSelect"
          ? await updateFilesAsync(inputs)
          : triggeredBy === "partnersMultiSelect"
          ? await updatePartnersAsync(inputs)
          : triggeredBy === "projectsMultiSelect"
          ? await updateProjectsAsync(inputs)
          : await updateProductsAsync(inputs);
      if (updateStatus[actionResponseKeyMap[triggeredBy]].success) {
        onSuccess?.(updateStatus[actionResponseKeyMap[triggeredBy]].data);
        let newUpdateStatus = {};
        currentSet.forEach((item) => {
          newUpdateStatus[item.groupTitle] = {
            ...newUpdateStatus[item.groupTitle],
            [item.name]: {
              status: "Completed",
              name: item.name,
              groupTitle: item.groupTitle,
            },
          };
        });
        setFullUpdateStatus((prevState) => {
          let updatedState = {};
          Object.keys(newUpdateStatus).forEach((groupKey) => {
            updatedState[groupKey] = { ...prevState[groupKey], ...newUpdateStatus[groupKey] };
          });
          return { ...prevState, ...updatedState };
        });
      }
    }
  };

  const getFileStatus = (itemNameKey, groupTitle) => {
    if (!fullUpdateStatus[groupTitle]?.[itemNameKey]) {
      return null;
    }
    const itemStatus = fullUpdateStatus[groupTitle][itemNameKey];
    if (itemStatus.status === "Failed") {
      return <FailIcon className="h-6 w-6 text-red-700" aria-hidden="true" />;
    }
    if (itemStatus.status === "Completed") {
      return <CheckIcon className="h-6 w-6 text-green-700" aria-hidden="true" />;
    }
    return <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-neutral-900" />;
  };

  const onCloseClick = () => {
    setUpdateQueue([]);
    onClose({
      isOpen: false,
      currentAction: null,
      triggeredBy: null,
      actionProps: null,
    });
  };

  useEffect(() => {
    if (!updateQueue.length) return;
    let updatingItems = 0;
    Object.keys(fullUpdateStatus || {}).forEach((groupKey) => {
      const itemsList = fullUpdateStatus[groupKey];
      Object.keys(itemsList).forEach((itemNameKey) => {
        if (itemsList[itemNameKey].status === "Saving") {
          updatingItems++;
        }
      });
    });
    setUpdateState((prevState) => ({
      isUpdating: !!updatingItems,
      updatingItems,
    }));
  }, [fullUpdateStatus]);

  useEffect(() => {
    if (!itemsInput?.length) {
      return;
    }
    let filesInQueue = areItemsAlreadyInQueue(updateQueue, itemsInput, true);
    if (!filesInQueue) {
      setUpdateQueue((prevQ) => [...prevQ, ...itemsInput]);
    }
  }, [itemsInput, updateQueue]);

  useEffect(() => {
    if (!updateQueue?.length) {
      return;
    }
    updateItems();
  }, [updateQueue]);

  return (
    <>
      <ConfirmationModal
        isOpen={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
        onDelete={onCloseClick}
        title="Cancel operation"
        message="Closing this window  will remove all files from the update queue and you will no longer be able to track progress or add further details. Update of files will not be cancelled."
      />

      <div
        className={clsx(
          "text-x flex-flex-col fixed left-10 z-[9999999999999] min-h-[320px] w-[416px] drop-shadow-2xl",
          {
            "bottom-0": isViewUp,
            "bottom-[-264px]": !isViewUp,
          }
        )}
      >
        <div className="flex h-[64px] flex-row items-center justify-between rounded-t-lg bg-neutral-900  px-5 text-neutral-50">
          <div className="items-center text-xs">
            {!updateState?.isUpdating && !updateState?.updatingItems && updateQueue?.length ? (
              <span className="flex items-center">
                {" "}
                <CheckIcon className="mr-2 h-6 w-6 text-neutral-50" aria-hidden="true" />
                {`Updated ${updateQueue.length} items.`}
              </span>
            ) : (
              <span className="flex items-center">
                <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-neutral-50" />{" "}
                {`Updating ${updateState?.updatingItems} items.`}
              </span>
            )}
          </div>
          <div className="flex flex-row items-center">
            {isViewUp ? (
              <DownIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(false)} />
            ) : (
              <UpIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(true)} />
            )}
            <CloseIcon
              className="h-4 w-4"
              onClick={() => {
                if (!updateState?.isUpdating && !updateState?.updatingItems && updateQueue?.length) {
                  onCloseClick();
                  return;
                }
                setIsCancelOpen(true);
              }}
            />
          </div>
        </div>
        {isViewUp ? (
          <div className="text-primary divide-y-1 flex h-[308px] flex-col overflow-y-auto bg-white">
            {Object.keys(fullUpdateStatus || {}).map((groupTitle) => {
              const groupItems = fullUpdateStatus[groupTitle];
              return (
                <div key={groupTitle}>
                  {Object.keys(groupItems || {}).map((itemName) => {
                    const itemStatus = groupItems[itemName];
                    return (
                      <div key={itemName} className="flex min-h-[56px] flex-row items-center justify-between px-5">
                        <div className="flex flex-row">
                          <span className="mr-3">
                            {triggeredBy === "filesMultiSelect" ? (
                              <ThumbnailIcon width={20} className="text-accent-700" />
                            ) : (
                              <BoxIcon width={20} className="text-accent-700" />
                            )}
                          </span>
                          <span className="text-sm">{truncate(itemStatus.name, { length: 34 })}</span>
                        </div>
                        {getFileStatus(itemName, groupTitle)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default GenActionUpdateBulkItems;
