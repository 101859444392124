import { TableCells, TableFilters, TableCards } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const contractTypes = [
  "Home Improvement",
  "Change Order",
  "Contractor - Purchase Order",
  "Contractor - Lien Waiver",
];

export const contractTypesAsOptions = contractTypes.map((contractType) => {
  return {
    title: contractType,
    value: contractType,
  };
});

export const contractStatusTypes = ["Signed", "Draft", "Awaiting Signature"];

export const contractStatusAsOptions = contractStatusTypes.map((status) => {
  return {
    title: status,
    value: status,
  };
});

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Signed: "green",
  Draft: "gray",
  Sent: "orange",
  Completed: "green",
  Viewed: "green",
  "Awaiting Approval": "orange",
  Approved: "green",
  Rejected: "red",
  "Awaiting Payment": "orange",
  Paid: "green",
  Voided: "red",
  Declined: "red",
  "External Review": "gray",
};

export const columns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "project",
    Header: "Project",
    accessor: (originalRow) => {
      return originalRow.project?.places?.addressLine1
        ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
        : null;
    },
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original.project?.places?.city}
          addressState={originalRow.row.original.project?.places?.state}
          addressZip={originalRow.row.original.project?.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  {
    id: "type",
    Header: "Type",
    accessor: "contractType",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "name",
    Header: "Signee Name",
    accessor: (row) => row.clients?.[0]?.fullName,
    Cell: (originalRow) => {
      return <TableCells.UserCell {...originalRow} />;
    },
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "lastUpdate",
    Header: "Last Update",
    accessor: (originalRow) => {
      const dt = new Date(originalRow.lastUpdated);
      return dt.toISOString();
    },
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    formatAccessor: "MMM DD, YYYY",
    showTimeAccessor: true,
  },
  {
    id: "owner",
    Header: "Owner",
    accessor: (row) => row.staff?.[0]?.fullName,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          avatarSrc={originalRow.row.original.staff?.[0]?.avatar || ""}
          initials={generateInitials(originalRow.row.original.staff?.[0]?.fullName)}
          {...originalRow}
        />
      );
    },
    disableSortBy: true,
  },
];

export const loadingPlaceholderContract = {
  id: "",
  name: "",
  contractType: "",
  status: "",
  lastUpdated: "",
  document_id: null,
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
    thumbnail: null,
  },
  clients: [
    {
      id: "",
      fullName: "",
      avatar: null,
    },
  ],
  staff: [],
};
