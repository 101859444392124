import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductTypes } from "../../api";
import { setProductTypes, getProductTypes } from "../../productsSlice";

const useProductTypesObjects = () => {
  const dispatch = useDispatch();
  const allProductTypes = useSelector(getProductTypes);

  const { data, isLoading, error } = useQuery("productTypes", fetchProductTypes, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (!data || error) {
      return;
    }

    dispatch(setProductTypes(data.getProductTypeObjects));
  }, [data, error]);

  const productTypeOptions = useMemo(() => {
    if (!allProductTypes?.length) {
      return [];
    }
    return allProductTypes
      .map((productType) => {
        return {
          value: productType.id,
          title: productType.description,
          productType,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [allProductTypes]);

  return {
    data: allProductTypes,
    options: productTypeOptions,
    isLoading,
  };
};

export default useProductTypesObjects;
