import { UisCheckCircle as CheckIcon, UisTimesCircle as CloseIcon } from "@iconscout/react-unicons-solid";
import clsx from "clsx";
import {
  UilEllipsisV as OptionsIcon,
  UilRocket as ActivityIcon,
  UilVideo as VideoIcon,
} from "@iconscout/react-unicons";

import { UilPhoneSlash as CancelIcon, UilSchedule as ScheduleIcon } from "@iconscout/react-unicons";
import { IconButton, MenuDropdown } from "@buildappeal/react-component-library";

import { CONSULTATION_STATUSES, CONSULTATION_ACTION_MODES } from "../utils";

export const renderConsultationStatus = (status, isPast, eventName) => {
  const eventNameText = eventName ? `${eventName} - ` : null;
  switch (status) {
    case CONSULTATION_STATUSES.SCHEDULED:
      return (
        <div className={clsx("flex items-center gap-2", isPast ? "text-orange-800" : "text-primary-700")}>
          {!isPast && <CheckIcon className="h-4 w-4 shrink-0" />}
          <span className="text-2xs">
            {eventNameText}
            {isPast ? "Not Completed" : "Scheduled"}
          </span>
        </div>
      );
    case CONSULTATION_STATUSES.CANCELLED:
      return (
        <div className="flex items-center gap-2 text-red-800">
          <CloseIcon className="h-4 w-4 shrink-0" />
          <span className="text-2xs">{eventNameText}Cancelled</span>
        </div>
      );
    default:
      return (
        <div className="flex items-center gap-2 text-primary-400">
          <span className="text-2xs">
            {eventNameText}
            {status}
          </span>
        </div>
      );
  }
};

export const getConsultationMenu = (status, consultation, launchAction, isPast, onRescheduleCancelAction) => {
  let actions;

  if (status === CONSULTATION_STATUSES.SCHEDULED && !isPast && consultation.calendlyLink) {
    actions = [
      {
        id: "zoom_link",
        label: "Open Zoom",
        icon: VideoIcon,
        wrapper: <div />,
        onClick: (e) => {
          e.stopPropagation();
          window.open(consultation.calendlyLink, "_blank", "noopener,noreferrer");
        },
      },
      {
        id: "start_consultation",
        label: "Start Event",
        icon: ActivityIcon,
        wrapper: <div />,
        onClick: (e) => {
          e.stopPropagation();
          launchAction();
        },
      },
      {
        id: "reschedule_consultation",
        label: "Reschedule Event",
        icon: ScheduleIcon,
        wrapper: <div />,
        onClick: (e) => {
          e.stopPropagation();
          onRescheduleCancelAction({
            mode: CONSULTATION_ACTION_MODES.RESCHEDULE,
            consultation,
          });
        },
      },
      {
        id: "cancel_consultation",
        label: "Cancel Event",
        icon: CancelIcon,
        wrapper: <div />,
        onClick: (e) => {
          e.stopPropagation();
          onRescheduleCancelAction({
            mode: CONSULTATION_ACTION_MODES.CANCEL,
            consultation,
          });
        },
      },
    ];
  }

  return actions ? (
    <MenuDropdown
      labelClassName="bg-white !p-0 rounded-full"
      labelComponent={<IconButton icon={OptionsIcon} />}
      showArrow={false}
      items={actions}
    />
  ) : null;
};
