import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { flatMap } from "lodash";
import { orderBy } from "lodash";
import { fetchMarkets } from "../../api";
import { setMarkets, getMarkets } from "../../marketsSlice";

const useMarkets = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const markets = useSelector(getMarkets);

  const { data, isLoading } = useQuery(["markets", filters], () => fetchMarkets(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setMarkets(data.getMarkets));
  }, [data, isLoading, dispatch]);

  const marketsAsOptions = useMemo(() => {
    if (!markets?.length) {
      return [];
    }
    return orderBy(
      markets.map((market) => {
        return {
          value: market.id,
          title: market.name,
          market,
        };
      }),
      ["title"],
      ["asc"]
    );
  }, [markets]);

  const marketServices = useMemo(() => {
    if (!markets?.length) {
      return [];
    }
    return flatMap(markets, (market) => market.services);
  }, [markets]);
  const marketPartners = useMemo(() => {
    if (!markets?.length) {
      return [];
    }
    return flatMap(markets, (market) => market.partners);
  }, [markets]);

  const marketSuppliers = useMemo(() => {
    if (!markets?.length) {
      return [];
    }
    return flatMap(markets, (market) => market.suppliers);
  }, [markets]);

  return {
    data: markets,
    dataMarketServices: marketServices,
    dataMarketPartners: marketPartners,
    dataMarketSuppliers: marketSuppliers,
    isLoading,
    options: marketsAsOptions,
  };
};

export default useMarkets;
