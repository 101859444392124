import SendEmail from "./components/SendEmail";

export const emailActions = {
  add: {
    id: "add",
    label: "Create Email",
    component: SendEmail,
    container: "collapsedExpand",
  },
};
