import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchActions } from "../api";
import { getActions, getActionsById, setActions } from "../actionsObjectsSlice";

const useActions = () => {
  const dispatch = useDispatch();
  const actions = useSelector(getActions);
  const actionsById = useSelector(getActionsById);

  const { data, isLoading } = useQuery("actions", () => fetchActions({}), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setActions(data.getActions));
  }, [data, isLoading]);

  const actionsAsOptions = useMemo(() => {
    if (!actions?.length) {
      return [];
    }
    return actions
      .map((action) => {
        return {
          value: action.id,
          title: action.verbPresent,
          action,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [actions]);

  return {
    data: actions,
    dataById: actionsById,
    isLoading,
    options: actionsAsOptions,
  };
};

export default useActions;
