import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateProposal } from "../../api";

const useUpdateProposal = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const editProposalMutation = useMutation(
    (input) => {
      return updateProposal(input);
    },
    {
      onSuccess: (data) => {
        if (!data.updateProposal.success) {
          setError(`Error: ${data.updateProposal?.message}`);
        } else {
          queryClient.setQueryData(["proposals", filters], (old) => {
            if (!old?.getProposals) {
              return { getProposals: [data.updateProposal.data] };
            }
            return {
              getProposals: old?.getProposals?.map((proposal) => {
                if (proposal.id === data.updateProposal.data.id) {
                  return {
                    ...proposal,
                    ...data.updateProposal.data,
                  };
                }
                return proposal;
              }),
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    editProposal: editProposalMutation.mutate,
    editProposalAsync: editProposalMutation.mutateAsync,
    editProposalLoading: editProposalMutation.isLoading,
    editProposalSuccess: editProposalMutation.isSuccess,
  };
};

export default useUpdateProposal;
