import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notes: [],
  noteItems: [],
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setNotes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        notes: action.payload,
      };
    },
    setNoteItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        noteItems: action.payload,
      };
    },
  },
});

export const { setNotes, setNoteItems } = notesSlice.actions;

export default notesSlice.reducer;

export const getNotes = (state) => state.notes.notes;
export const getNoteItems = (state) => state.notes.noteItems;
