import { useSelector } from "react-redux";

import CommonActivities from "@src/features/activities/common/CommonActivities";
import useUserActivities from "../users/hooks/useUserActivities";
import { getPartner } from "./partnersSlice";

const PartnerActivity = () => {
  const partner = useSelector(getPartner);
  const { data, isLoading } = useUserActivities(
    { actorUid: partner?.user?.id || 0 },
    { disableQuery: !partner?.user?.id }
  );

  return <CommonActivities data={data} isLoading={isLoading} />;
};

export default PartnerActivity;
