import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_LOOKS = gql`
  query getAllLook($filters: LookFilterFields!) {
    getAllLook(filters: $filters) {
      id
      name
      createdAt
      products {
        id
        createdAt
        name
        brand
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        contentType
      }
      spaces {
        id
        name
        location
      }
    }
  }
`;

const GET_LOOK = gql`
  query getLookByID($id: Int!) {
    getLookByID(id: $id) {
      id
      name
      createdAt
      products {
        id
        createdAt
        name
        brand
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        contentType
      }
      spaces {
        id
        name
        location
      }
    }
  }
`;

const CREATE_LOOK = gql`
  mutation createLook($input: CreateLookRequest!) {
    createLook(input: $input) {
      code
      success
      message
      data {
        id
        name
        createdAt
        products {
          id
          createdAt
          name
          brand
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          contentType
        }
        spaces {
          id
          name
          location
        }
      }
    }
  }
`;

const UPDATE_LOOK = gql`
  mutation updateLook($input: UpdateLookRequest!) {
    updateLook(input: $input) {
      code
      success
      message
      data {
        id
        name
        createdAt
        products {
          id
          createdAt
          name
          brand
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          contentType
        }
        spaces {
          id
          name
          location
        }
      }
    }
  }
`;

const DELETE_LOOK = gql`
  mutation deleteLook($id: Int!) {
    deleteLook(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;
export const fetchLooks = async (filters = {}) => {
  return await graphQLClient.request(GET_LOOKS, { filters });
};

export const createLook = async (input) => {
  return await graphQLClient.request(CREATE_LOOK, { input });
};

export const updateLook = async (input) => {
  return await graphQLClient.request(UPDATE_LOOK, { input });
};

export const deleteLook = async (id) => {
  return await graphQLClient.request(DELETE_LOOK, { id });
};

export const fetchLookById = async (id) => {
  return await graphQLClient.request(GET_LOOK, { id });
};
