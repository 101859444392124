import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useDelete, useRealtime, useUpsert } from "react-supabase";

export function useOnlineUsers(selfUser) {
  const [{ data = [], error }] = useRealtime(
    "online_users",
    {
      select: {
        columns: "id,user_id,metadata,updatedAt",
      },
    },
    (data, payload) => data.user_id === payload.user_id
  );
  const [, addUserToTable] = useUpsert("online_users", {
    options: {
      onConflict: "user_id",
    },
  });
  const [, removeUserFromTable] = useDelete("online_users");

  useEffect(() => {
    if (error) {
      console.log("Unable to get online users info");
    }
  }, [error]);

  useEffect(() => {
    if (!selfUser || process.env.REACT_APP_IS_LOCAL) return;
    addUser(selfUser);

    const intervalId = setInterval(() => {
      addUser(selfUser);
    }, 30000); // Refresh every 30 seconds
    return () => {
      clearInterval(intervalId);
    };
  }, [selfUser]);

  const addUser = (user) => {
    return addUserToTable({ metadata: { ...user }, user_id: user.uuid, updatedAt: new Date() });
  };

  const removeUser = (user) => {
    return removeUserFromTable((query) => query.eq("user_id", user.uuid));
  };

  const onlineUsers = useMemo(() => {
    if (!data) return;

    return data.filter(
      (user) => dayjs().subtract(15, "second").isSameOrBefore(dayjs(user.updatedAt)) && user.user_id !== selfUser?.uuid
    );
  }, [data, selfUser]);
  return { onlineUsers, addUser, removeUser };
}
