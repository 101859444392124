import React, { useState, useMemo, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import {
  UilTimes as CloseIcon,
  UilBars as MenuIcon,
  UilSun as SunIcon,
  UilSearch as SearchIcon,
  UilMoon as MoonIcon,
} from "@iconscout/react-unicons";
import {
  Avatar,
  PrimaryNavigation,
  NavItem,
  Logo,
  MenuDropdown,
  Modal,
  Search,
  AvatarMultiple,
} from "@buildappeal/react-component-library";
import * as Sentry from "@sentry/react";

import Toasts from "@src/utils/components/Toasts/Toasts";
import useKeyPress from "@src/utils/hooks/useKeyPress";
import { useOnlineUsers } from "@src/utils/hooks/useOnlineUsers";
import generateInitials from "@src/utils/generateInitials";
import useCurrentTheme from "@src/utils/hooks/useCurrentTheme";

import { algoliaSearchIndices, algoliaSearchClient } from "../../utils/algolia";
import useUser from "../auth/hooks/useUser";
import useSearch from "../search/hooks/useSearch";
import { menuItems, navUserItems, toggleHtmlClass } from "./utils";
import ErrorFallbackPage from "./ErrorFallbackPage";
import { useQuickViewUpdater } from "./contexts/QuickViewContext";

const Layout = () => {
  const { user } = useUser();
  const [show, toggle] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showCommandMenu, setShowCommandMenu] = useState(false);
  const { onSearchResultClick, gotoSearchResultPage, onSearchSuccess } = useSearch();
  const { theme: currentTheme, setCurrentTheme } = useCurrentTheme();
  const { onlineUsers } = useOnlineUsers(user);
  const onQuickViewOpen = useQuickViewUpdater();

  const handleQuickViewOpen = (path, data) => {
    onQuickViewOpen({
      isOpen: true,
      path,
      selectedItem: {
        id: data.id,
        type: data.type,
      },
    });
  };

  const finalUserNavItems = useMemo(() => {
    return navUserItems({ id: user?.id, role: user?.role }).map((item) => {
      if (item.quickViewProps) {
        const { path, data } = item.quickViewProps;
        return {
          ...item,
          onClick: (e) => {
            e.stopPropagation();
            handleQuickViewOpen(path, data);
          },
        };
      }
      return {
        ...item,
      };
    });
  }, [user]);

  useKeyPress(() => setShowSearch(true), ["KeyK"]);

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches && localStorage.getItem("theme") !== "light") {
      setCurrentTheme("dark");
      toggleHtmlClass("dark", true);
    }
  }, []);

  // Add to localstorage if persistence required
  const onThemeToggle = () => {
    if (currentTheme === "light") {
      setCurrentTheme("dark");
    } else {
      setCurrentTheme("light");
    }
  };

  const searchDropdownProps = {
    onSearchResultClick,
    gotoSearchResultPage,
    algoliaIndices: algoliaSearchIndices,
    algoliaSearchClient,
    onSearchSuccess,
  };

  const initials = useMemo(() => {
    let results = "";
    if (user?.firstName) {
      results = results + user?.firstName?.[0];
    }
    if (user?.lastName) {
      results = results + user?.lastName?.[0];
    }
    return results;
  }, [user]);

  const onlineUsersImages = useMemo(
    () =>
      onlineUsers.map(({ metadata: user }) => ({
        src: user?.avatar,
        title: user?.fullName,
        initials: generateInitials(user.fullName),
        alt: `online user image`,
        iconWrapperClassName: "!bg-green-600 !h-2 !w-2",
        icon: () => <div />,
      })),
    [onlineUsers]
  );

  const onlineUsersList = useMemo(
    () =>
      onlineUsers.slice(3).map(({ metadata: user, user_id }) => ({
        id: user_id,
        label: user?.fullName,
        avatar: user?.avatar,
        initials: generateInitials(user.fullName),
      })),
    [onlineUsers]
  );

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  return (
    <div id="layout">
      <ErrorBoundary
        FallbackComponent={ErrorFallbackPage}
        onError={(error, info) => {
          Sentry.withScope((scope) => {
            scope.setExtras(error);
            Sentry.captureException(error);
          });
        }}
      >
        <PrimaryNavigation
          left={
            <>
              <NavItem wrapper={<Link to="/" />}>
                <Logo />
              </NavItem>
            </>
          }
          center={
            <>
              {menuItems?.map((menuItem) => {
                if (menuItem.subItems?.length > 0) {
                  return (
                    <MenuDropdown
                      isLink={menuItem.isLink}
                      wrapper={menuItem.wrapper}
                      key={menuItem.id}
                      label={menuItem.label}
                      items={menuItem.subItems}
                    />
                  );
                }

                return (
                  <NavItem key={menuItem.id} {...menuItem}>
                    {menuItem.label}
                  </NavItem>
                );
              })}
            </>
          }
          right={
            <>
              {user && (
                <>
                  {onlineUsersList.length ? (
                    <MenuDropdown
                      className="max-h-[350px] overflow-y-auto"
                      labelComponent={<AvatarMultiple size="xs" images={onlineUsersImages} maxImages={3} />}
                      items={onlineUsersList}
                      showArrow={false}
                    />
                  ) : (
                    <AvatarMultiple size="xs" images={onlineUsersImages} maxImages={3} className="mr-4" />
                  )}
                </>
              )}
              <NavItem wrapper={<button onClick={handleShowSearch} />} icon={SearchIcon} />
              <NavItem
                icon={currentTheme === "light" ? MoonIcon : SunIcon}
                onClick={() => {
                  onThemeToggle();
                }}
              />
              <MenuDropdown
                classNameContainer="hidden lg:block"
                labelComponent={<Avatar initials={initials} src={user?.avatar} size="xs" />}
                items={finalUserNavItems}
                showArrow
              />
              <NavItem className="block lg:hidden" icon={show ? CloseIcon : MenuIcon} onClick={() => toggle(!show)} />
            </>
          }
        >
          <PrimaryNavigation.Mobile isOpen={show}>
            <div className="border-b border-neutral-200 pb-6">
              {menuItems.map((menuItem) => (
                <PrimaryNavigation.Item key={menuItem.id} showArrow={!!menuItem.subItems} items={menuItem.subItems}>
                  {menuItem.label}
                </PrimaryNavigation.Item>
              ))}
            </div>
            {user && (
              <div className="flex items-center justify-between border-t border-neutral-200 py-8 dark:bg-black dark:text-neutral-100">
                <div className="flex items-center space-x-4">
                  <Avatar initials={initials} src={user?.avatar} />
                  <span className="text-lg font-light">Hi, {user?.firstName}</span>
                </div>
                <NavItem className="text-sm text-primary-700" wrapper={<Link to={`/users/${user?.uuid}`} />}>
                  Account
                </NavItem>
              </div>
            )}
          </PrimaryNavigation.Mobile>
        </PrimaryNavigation>

        <div className="w-full">
          <Outlet />
        </div>

        <Search {...searchDropdownProps} isOpen={showSearch} onClose={() => setShowSearch(false)} />

        <Modal
          title="Command Menu"
          fullScreen
          isOpen={showCommandMenu}
          onClose={() => setShowCommandMenu(false)}
        ></Modal>
        <Toasts />
      </ErrorBoundary>
    </div>
  );
};

export default Layout;
