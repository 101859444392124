const formSchema = {
  title: "Create Invoice",
  properties: {
    project: {
      type: "project",
      label: "Project",
      required: true,
    },
    invoiceTypes: {
      type: "select",
      label: "Invoice Type",
    },
    total: {
      type: "currency",
      label: "Total",
    },
    dueDate: {
      type: "datetime",
      label: "Due Date",
    },
  },
};

export default formSchema;
