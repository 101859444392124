import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: localStorage.getItem("theme") ?? "light",
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setCurrentTheme: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        theme: action.payload,
      };
    },
  },
});

export const { setCurrentTheme } = layoutSlice.actions;

export default layoutSlice.reducer;

export const getCurrentTheme = (state) => state.layout.theme;
