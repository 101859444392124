import { TableCells, TableFilters, NavCard, TableCards } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Pending: "bg-orange-500",
  "In Progress": "bg-orange-500",
  Draft: "bg-gray-500",
  Paid: "bg-green-500",
};

export const budgetColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={`Budget #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          title={`Budget #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  estimatedTotal: {
    id: "estimatedTotal",
    Header: "Estimated Amount",
    accessor: "estimatedTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  daysToComplete: {
    id: "daysToComplete",
    Header: "Days to Complete",
    accessor: "daysToComplete",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: ({ table, getValue }) => {
      return (
        <TableCells.StatusCell
          status={getValue()}
          circleColor={STATUS_COLOR_CODES[getValue()] || "bg-gray-500"}
          show
          table={table}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TextRow,
  },
};

export const budgetItemsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: (originalRow) =>
      originalRow.budget?.project?.places?.addressLine1
        ? `${originalRow.budget?.project?.places?.addressLine1} ${
            originalRow.budget?.project?.places?.addressLine2 || ""
          }`.trim()
        : null,
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original?.budget?.project?.places?.city}
          addressState={originalRow.row.original?.budget?.project?.places?.state}
          addressZip={originalRow.row.original?.budget?.project?.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  budget: {
    id: "budgetId",
    Header: "Budget ID",
    accessor: "budget.id",
    Cell: TableCells.TextCell,
  },
  descriptiion: {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  estimatedAmount: {
    id: "estimatedAmount",
    Header: "Estimated Amount",
    accessor: "estimatedAmount",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
};
