import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  markets: [],
};

export const marketsSlice = createSlice({
  name: "markets",
  initialState,
  reducers: {
    setMarkets: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        markets: action.payload,
      };
    },
  },
});

export const { setMarkets } = marketsSlice.actions;

export default marketsSlice.reducer;

export const getMarkets = (state) => state.markets?.markets;
