import { TableCells, TableFilters, NavCard, TableCards } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Pending: "bg-orange-500",
  Draft: "bg-gray-500",
  Paid: "bg-green-500",
  "Processing Payment": "bg-orange-500",
};

export const billColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  from: {
    id: "from",
    Header: "From",
    accessor: (row) => row.partner?.companyName,
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={`Bill #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          title={`Bill #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  billDate: {
    id: "billDate",
    Header: "Issued",
    accessor: "billDate",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  billCode: {
    id: "billCode",
    Header: "Bill Code",
    accessor: "billCode",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  dueDate: {
    id: "dueDate",
    Header: "Due",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          show
          status={originalRow.getValue()}
          circleColor={originalRow.getValue() ? STATUS_COLOR_CODES[originalRow.getValue()] || "bg-primary-500" : ""}
          {...originalRow}
        />
      );
    },
    renderCard: TableCards.TextRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
};

export const billItemsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: (originalRow) =>
      originalRow.bill?.project?.places?.addressLine1
        ? `${originalRow.bill?.project?.places?.addressLine1} ${
            originalRow.bill?.project?.places?.addressLine2 || ""
          }`.trim()
        : null,
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original?.bill?.project?.places?.city}
          addressState={originalRow.row.original?.bill?.project?.places?.state}
          addressZip={originalRow.row.original?.bill?.project?.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  bill: {
    id: "billId",
    Header: "Bill ID",
    accessor: "bill.id",
    Cell: TableCells.TextCell,
  },
  description: {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  quantity: {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  subTotal: {
    id: "subTotal",
    Header: "Subtotal",
    accessor: "subTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
};
