import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  materials: [],
  materialsById: {},
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    setMaterials: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        materials: action.payload,
      };
    },
    setMaterialDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        materialsById: {
          ...originalState.materialsById,
          [action.payload.id]: action.payload,
        },
      };
    },
  },
});

export const { setMaterials, setMaterialDetail } = materialsSlice.actions;

export default materialsSlice.reducer;

export const getMaterials = (state) => state.materials.materials;
export const selectMaterialById = (id) => (state) => state.materials.materialsById[id];
