import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_BILLS = gql`
  query getBills($filters: BillFilterFields!) {
    getBills(filters: $filters) {
      id
      createdAt
      billCode
      billPrefix
      billNumber
      billDate
      dueDate
      total
      status
      xeroInvoiceId
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      documents {
        id
        uploadedAt
        name
        path
        fileType
      }
      partner {
        id
        companyName
      }
    }
  }
`;

const GET_PROJECT_BILLS = gql`
  query getBills($filters: BillFilterFields!) {
    getBills(filters: $filters) {
      id
      billCode
      billDate
      dueDate
      total
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      partner {
        id
        companyName
      }
    }
  }
`;

const GET_BILL = gql`
  query getBillByID($id: Int!) {
    getBillByID(id: $id) {
      id
      createdAt
      billCode
      billPrefix
      billNumber
      billDate
      dueDate
      total
      status
      xeroInvoiceId
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      documents {
        id
        uploadedAt
        name
        path
        fileType
      }
      partner {
        id
        companyName
      }
    }
  }
`;

const GET_BILL_ITEMS = gql`
  query getBillItems($filters: BillItemFilterFields!) {
    getBillItems(filters: $filters) {
      id
      description
      quantity
      subTotal
      total
      bill {
        id
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
      }
    }
  }
`;

const CREATE_BILL = gql`
  mutation createBill($input: CreateBillRequest!) {
    createBill(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        billCode
        billPrefix
        billNumber
        billDate
        dueDate
        total
        status
        xeroInvoiceId
        project {
          id
          places {
            id
            addressLine1
            city
            state
            state
          }
          projectStages {
            id
            name
            stageType
          }
        }
        documents {
          id
          uploadedAt
          name
          path
          fileType
        }
        partner {
          id
          companyName
        }
        billItems {
          id
          createdAt
          description
          quantity
          subTotal
          total
        }
      }
    }
  }
`;
const UPDATE_BILL = gql`
  mutation UpdateBill($input: UpdateBillRequest!) {
    updateBill(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        updatedAt
        billCode
        billPrefix
        billNumber
        billDate
        dueDate
        total
        status
        xeroInvoiceId
        project {
          id
          places {
            id
            addressLine1
            city
            state
            state
          }
          projectStages {
            id
            name
            stageType
          }
        }
        documents {
          id
          uploadedAt
          name
          path
          fileType
        }
        partner {
          id
          companyName
        }
        billItems {
          id
          createdAt
          description
          quantity
          subTotal
          total
        }
      }
    }
  }
`;

export const fetchBills = async (filters = {}) => {
  return await graphQLClient.request(GET_BILLS, { filters });
};

export const fetchBillItems = async (filters = {}) => {
  return await graphQLClient.request(GET_BILL_ITEMS, { filters });
};

export const createBill = async (input) => {
  return await graphQLClient.request(CREATE_BILL, { input });
};

export const updateBill = async (input) => {
  return await graphQLClient.request(UPDATE_BILL, { input });
};

export const fetchBill = async (id) => {
  return await graphQLClient.request(GET_BILL, { id });
};

export const fetchProjectBills = async (filters = {}) => {
  return await graphQLClient.request(GET_PROJECT_BILLS, { filters });
};
