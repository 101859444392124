const uiSchema = {
  groups: [
    {
      title: "Location",
      properties: ["address"],
    },
    {
      title: "",
      properties: ["floors"],
    },
    {
      title: "Info",
      properties: ["type", "accessDifficulty"],
    },
    {
      title: "Contact",
      properties: ["contact"],
    },
    {
      title: "Project Owner",
      properties: ["staff"],
      isLast: true,
    },
  ],
};

export default uiSchema;
