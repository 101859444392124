import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_BA_TYPES = gql`
  query getBATypes($typeName: String!) {
    getBATypes(typeName: $typeName)
  }
`;

export const fetchBATypes = async (typeName) => {
  return await graphQLClient.request(GET_BA_TYPES, { typeName });
};
