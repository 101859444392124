import dayjs from "dayjs";
import { NavCard, TableCells, TableFilters, TableCards } from "@buildappeal/react-component-library";
import generateInitials from "@src/utils/generateInitials";
import TaskMediaCell from "./TaskMediaCell";

export const tasksColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city ? original.project?.places?.city + "," : ""} ${
            original.project?.places?.state || ""
          } ${original.project?.places?.zipCode || ""}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={original?.project?.thumbnail?.path}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageOnly
        />
      );
    },
    maxWidth: "3fr",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  address: {
    id: "address",
    Header: "Address",
    accessor: (row) => row.project?.places?.addressLine1 || "",
    subtitleAccessor: (row) => {
      const { city, state, zipCode } = row?.project?.places;
      return `${city}, ${state} ${zipCode}`;
    },
    className: "whitespace-nowrap",
    renderCard: ({ row, column, table }) => {
      const { city, state, zipCode } = row?.original?.project?.places;
      return (
        <TableCards.TitleRow
          getValue={() => {
            return row?.original?.project?.places?.addressLine1 || "";
          }}
          subtitle={`${city}, ${state} ${zipCode}`}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  media: {
    id: "files",
    Header: "Files",
    imgListAccessor: "images",
    accessor: (row) => {
      return row.files;
    },
    minWidth: "300px",
    Cell: ({ row, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <TaskMediaCell
          files={row?.original?.files || []}
          fallbackMessage=""
          row={row}
          column={column}
          isLoading={isLoading}
        />
      );
    },
  },
  actionsObjects: {
    id: "task",
    Header: "Task",
    accessor: (originalRow) => `${getTaskTitle(originalRow)}`,
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.TextRow
          getValue={() => {
            return `${getTaskTitle(row.original)}`;
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    minWidth: "300px",
  },
  startOn: {
    id: "startDate",
    Header: "Start Date",
    accessor: (originalRow) => (originalRow.startOn ? dayjs(new Date(originalRow.startOn)).format() : null),
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  dueDate: {
    id: "dueDate",
    Header: "Due Date",
    accessor: (originalRow) => (originalRow.dueDate ? dayjs(new Date(originalRow.dueDate)).format() : null),
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  assignedToUser: {
    id: "assignedTo",
    Header: "Assigned To",
    accessor: (originalRow) => originalRow?.row?.original?.assignedToUser?.fullName || "",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          avatarSrc={originalRow?.row?.original?.assignedToUser?.avatar || ""}
          hasTooltip
          hasLabel={false}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: ({ row, column, table }) => {
      const assignedToUser = row?.original?.assignedToUser;
      if (!assignedToUser) return null;
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            return [
              {
                avatar: assignedToUser?.avatar,
                name: assignedToUser?.fullName,
              },
            ];
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
  },
  assignedByUser: {
    id: "assignedBy",
    Header: "Assigned By",
    accessor: (originalRow) => originalRow?.row?.original?.assignedByUser?.fullName || "",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={originalRow?.row?.original?.assignedByUser?.fullName}
          column={originalRow.column}
          row={originalRow.row}
          avatarSrc={originalRow?.row?.original?.assignedByUser?.avatar}
          initials={generateInitials(
            originalRow?.row?.original?.assignedByUser?.fullName || originalRow?.row?.original?.assignedByUser?.email
          )}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: ({ row, column, table }) => {
      const assignedByUser = row?.original?.assignedByUser;
      if (!assignedByUser) return null;
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            return [
              {
                avatar: assignedByUser?.avatar,
                name: assignedByUser?.fullName,
              },
            ];
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
  },
  completed: {
    id: "completed",
    Header: "Completed",
    accessor: (originalRow) => {
      return !!originalRow?.completed;
    },
    Cell: (originalRow) => <TableCells.BooleanCell value={!!originalRow.row.original.completed} {...originalRow} />,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
};

export const getTaskTitle = (task) => {
  return `${task.actionObject?.action.verbPresent} ${task.actionObject?.object.description}`;
};
