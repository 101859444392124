import { omit } from "lodash";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createProject } from "../../api";

export const useCreateProject = ({ onSuccessCallback, filters = {} }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading, mutateAsync } = useMutation(
    (data) => {
      const payload = {
        ...omit(data, [
          "linkedContact",
          "contactFirstName",
          "contactLastName",
          "contactPhone",
          "contactEmail",
          "contact",
        ]),
        clients: data?.clients || [data?.contactEmail],
      };
      return createProject(payload);
    },
    {
      onSuccess: (data) => {
        if (!data.createProject.success) {
          setError(`Error: ${data.createProject.message}`);
          return;
        }

        setIsSuccess(true);

        queryClient.setQueryData(["projects", filters], (old) => {
          if (!old) return { getProjects: [data.createProject.data] };
          return { getProjects: [...old.getProjects, data.createProject.data] };
        });

        onSuccessCallback?.();
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
      onSettled: () => {
        setIsSuccess(false);
      },
    }
  );

  return { createProjectMutation: mutate, createProjectAsync: mutateAsync, isLoading, isSuccess, error };
};

export default useCreateProject;
