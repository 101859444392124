import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchMaterials } from "../../api";
import { setMaterials, getMaterials } from "../../materialsSlice";

const useMaterials = (filters = {}) => {
  const dispatch = useDispatch();
  const materials = useSelector(getMaterials);

  const { data, isLoading } = useQuery(["materials", filters], () => fetchMaterials({}), {
    refetchOnWindowFocus: false,
  });

  const materialsAsOptions = useMemo(() => {
    if (!materials?.length) {
      return [];
    }
    return materials
      .map((material) => {
        return {
          value: material.id,
          title: material.name,
          material,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [materials]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setMaterials(data.getMaterials));
  }, [data, isLoading, dispatch]);

  return {
    data: materials,
    isLoading,
    options: materialsAsOptions,
  };
};

export default useMaterials;
