import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { omit } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import useToast from "@src/utils/hooks/useToast";
import { BA_FILE_TABLE_TYPES } from "@src/utils/constants";
import isMediaFile from "@src/utils/isMedia";
import useProjects from "../projects/hooks/useProjects";
import usePartners from "../partners/hooks/usePartners";
import { FILE_CONTENT_TYPES, FILE_CONTENT_TYPES_LABEL, FILE_PATHS } from "../files/utils";
import useMultiFilesUpload from "../files/hooks/useMultiFilesUpload";
import billFormSchema from "./formSchema";
import billUiSchema from "./uiSchema";
import useAddBill from "./hooks/useAddBill";
// Use Form component to display form
// Update formSchema after all dynamic options are fetched
// https://storybook-buildappeal.s3.amazonaws.com/main/index.html?path=/story/mdx-form--basic For details
const AddBill = ({ onClose, projectId }) => {
  const { options: projectsAddressAsOptions, isLoading: projectOptionLoading } = useProjects();
  const { options: partnersAsOptions, isLoading: partnersOptionsLoading } = usePartners();

  const { startUpload } = useMultiFilesUpload();
  const { addBillAsync, error } = useAddBill(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const [formSchema, setFormSchema] = useState(null);

  const uploadFiles = async (billId, projectId, filesList) => {
    const finalFilesList = (filesList || []).map((file) => {
      const fileKey = `${FILE_PATHS[FILE_CONTENT_TYPES.project]}/${projectId}/${uuidv4()}`;
      return {
        key: fileKey,
        contentType: FILE_CONTENT_TYPES_LABEL[FILE_CONTENT_TYPES.bill],
        type: isMediaFile(file.type) ? BA_FILE_TABLE_TYPES.MEDIA : BA_FILE_TABLE_TYPES.DOCUMENT,
        file,
        projectId,
        billId,
      };
    });
    const results = await startUpload(finalFilesList);
    if (results?.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  const handleSubmit = async (formState) => {
    const data = {
      ...omit(formState, ["project", "billItems", "createdAt", "partner", "documents"]),
    };

    if (formState.xeroInvoiceId) {
      data.xeroInvoiceId = parseInt(formState.xeroInvoiceId);
    }
    if (formState.project) {
      data.projectId = formState.project.value;
    }
    if (formState.total) {
      data.total = parseFloat(formState.total);
    }

    if (formState.partner) {
      data.partnerId = formState.partner.value;
    }

    data.status = "New";

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));

    const res = await addBillAsync(data);

    if (res.createBill?.success) {
      let documents = [];

      if (formState?.documents?.length) {
        const billId = res.createBill.data.id;
        const projectId = res.createBill.data.project?.id;
        const finalFilesList = await uploadFiles(billId, projectId, formState.documents);
        if (finalFilesList?.length) {
          documents = finalFilesList.map((fileInfo) => ({
            path: fileInfo.key,
          }));
        }
      }
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Bill Successfully Created");
      queryClient.setQueryData(["bills", {}], (old) => {
        if (!old?.getBills) {
          return {
            getBills: [{ ...res.createBill.data, documents: [...documents] }],
          };
        }
        let bill = old?.getBills.find((b) => b.id === res.createBill.data.id);
        if (!bill) {
          bill = { ...res.createBill.data, documents: [...documents] };
          return {
            getBills: [bill, ...old?.getBills],
          };
        } else {
          return {
            getBills: old?.getBills.map((b) => {
              if (b.id === bill.id) {
                return { ...b, documents: [...documents] };
              }

              return b;
            }),
          };
        }
      });
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (projectOptionLoading || partnersOptionsLoading) {
      return;
    }
    const finalSchema = {
      ...billFormSchema,
    };
    const allProperties = billFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "project") {
          acc[key].options = projectsAddressAsOptions;
          if (projectId) {
            acc[key].initialValue = projectsAddressAsOptions.find((option) => option.value === projectId);
          }
        }

        if (key === "partner") {
          acc[key].options = partnersAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [projectsAddressAsOptions]);

  if (projectOptionLoading || partnersOptionsLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={billUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddBill;
