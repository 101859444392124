import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  budgets: [],
  budgetItems: [],
};

export const budgetsSlice = createSlice({
  name: "budgets",
  initialState,
  reducers: {
    setBudgets: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        budgets: action.payload,
      };
    },
    setBudgetItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        budgetItems: action.payload,
      };
    },
  },
});

export const { setBudgets, setBudgetItems } = budgetsSlice.actions;

export default budgetsSlice.reducer;

export const getBudgets = (state) => state.budgets.budgets;
export const getBudgetItems = (state) => state.budgets.budgetItems;
