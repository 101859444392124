import { Button, TableCells, TableCards } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const clientsColumns = {
  id: {
    id: "id",
    Header: "Id",
    accessor: "id",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  fullName: {
    id: "name",
    Header: "Name",
    accessor: "fullName",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={originalRow?.row?.original?.fullName}
          avatarSrc={originalRow.row?.original?.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
          initials={generateInitials(originalRow?.row?.original?.fullName || originalRow?.row?.original?.email)}
          {...originalRow}
        />
      );
    },
    renderCard: TableCards.TitleCard,
  },
  email: {
    id: "email",
    Header: "Email",
    accessor: "email",
    Cell: (originalRow) => {
      const handleClick = () => {
        originalRow?.column?.onOpenClick("send", originalRow);
      };

      return originalRow?.row?.original?.email ? (
        <div className="p-4" onClick={(e) => e?.stopPropagation()}>
          <Button label="Email" onClick={handleClick} appearance="secondary" />
        </div>
      ) : (
        <div />
      );
    },
    renderCard: TableCards.TextRow,
  },
  phone: {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
    renderCard: TableCards.TextRow,
  },
  lastContact: {
    id: "lastContact",
    Header: "Last Contact",
    accessor: "lastContact",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  lastLogin: {
    id: "lastLogin",
    Header: "Last Login",
    accessor: (originalRow) => {
      if (!originalRow.lastLogin) return "";
      const dt = new Date(originalRow.lastLogin);
      return dt.toDateString();
    },
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
};
