import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import { ReactQueryDevtools } from "react-query/devtools";
import { StytchProvider, initStytch } from "@stytch/stytch-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";
import App from "./App";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();
const stytch = initStytch(process.env.REACT_APP_STYTCH_TOKEN);

H.init(process.env.REACT_APP_HIGHLIGHT_PROJECT_ID); // Only in production

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_STAGE !== "production" ? 1 : 0.7,
  debug: process.env.REACT_APP_STAGE !== "production",
  environment: process.env.REACT_APP_STAGE,
  beforeSend: (event) => {
    if (process.env.REACT_APP_IS_LOCAL) {
      return null;
    }
    return event;
  },
  enabled: !process.env.REACT_APP_IS_LOCAL,
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <StytchProvider stytch={stytch}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </StytchProvider>
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
