import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";

const initialState = {
  actionsObjects: [],
  actionsObjectsById: {},
  actions: [],
  objects: [],
  projectStages: [],
};

export const actionsObjectsSlice = createSlice({
  name: "actionsObjects",
  initialState,
  reducers: {
    setActionsObjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        actionsObjects: action.payload.filter((actionObject) => actionObject.isActive),
        actionsObjectsById: keyBy(action.payload, "id"),
      };
    },
    setActions: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        actions: action.payload,
        actionsById: keyBy(action.payload, "id"),
      };
    },
    setObjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        objects: action.payload,
        objectsById: keyBy(action.payload, "id"),
      };
    },
    setProjectStages: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projectStages: action.payload,
      };
    },
  },
});

export const { setActionsObjects, setActions, setObjects, setProjectStages } = actionsObjectsSlice.actions;

export default actionsObjectsSlice.reducer;

export const getActionsObjects = (state) => state.actionsObjects.actionsObjects;
export const getActions = (state) => state.actionsObjects.actions;
export const getActionsById = (state) => state.actionsObjects.actionsById;
export const getObjects = (state) => state.actionsObjects.objects;
export const getProjectStages = (state) => state.actionsObjects.projectStages;
export const getObjectsById = (state) => state.actionsObjects.objectsById;
export const getActionsObjectsById = (state) => state.actionsObjects.actionsObjectsById;
