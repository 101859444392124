import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storyboards: [],
  storyboardItems: [],
};

export const storyboardsSlice = createSlice({
  name: "storyboards",
  initialState,
  reducers: {
    setStoryboards: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        storyboards: action.payload,
      };
    },
    setStoryboardItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        storyboardItems: action.payload,
      };
    },
  },
});

export const { setStoryboards, setStoryboardItems } = storyboardsSlice.actions;

export default storyboardsSlice.reducer;

export const getStoryboards = (state) => state.storyboards.storyboards;
export const getStoryboardItems = (state) => state.storyboards.storyboardItems;
