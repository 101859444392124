import { Pill, PillGroup } from "@buildappeal/react-component-library";
import { useClearRefinements, useCurrentRefinements } from "react-instantsearch-hooks-web";

const CurrentRefinements = ({ transformItems }) => {
  const { items: filters, refine: onRemove } = useCurrentRefinements({ transformItems });
  const { refine: clearFilters } = useClearRefinements();

  return (
    <PillGroup onClearAll={clearFilters}>
      {filters.map((filterValue) => (
        <>
          {filterValue.refinements.map((refinement) => (
            <Pill key={refinement.label} label={refinement.label} onRemove={() => onRemove(refinement)} />
          ))}
        </>
      ))}
    </PillGroup>
  );
};

export default CurrentRefinements;
