import { gql } from "graphql-request";
import axios from "@src/utils/axios";

import graphQLClient, { setClientHeader } from "@src/utils/graphQLClient";
import { setCookie, getCookie, removeCookie } from "@src/utils/universalCookie";
const AUTH_URL = process.env.REACT_APP_AUTH_URL;

const GET_CURRENT_USER = gql`
  query getCurrentUserDetails {
    getCurrentUserDetails {
      uuid
      email
      role
      clients {
        id
        firstName
        lastName
        avatar
        places {
          city
          state
          zipCode
        }
        phone
      }
      staff {
        id
        firstName
        lastName
        fullName
        avatar
        phone
        title
        email
        phone
        avatar
      }
    }
  }
`;

const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUserDetails($input: UpdateUserRequest!) {
    updateCurrentUserDetails(input: $input) {
      email
      role
      clients {
        id
        firstName
        lastName
        avatar
        city
        state
        zipCode
        phone
      }
      staff {
        id
        firstName
        lastName
        avatar
        phone
      }
    }
  }
`;

export const internalLogin = async ({ token, provider = "" }) => {
  const loginResponse = await axios.post(
    `${AUTH_URL}/login?application=staff`,
    {
      provider,
    },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      withCredentials: false,
    }
  );
  if (loginResponse.headers?.authorization || loginResponse.headers?.["x-amzn-remapped-authorization"]) {
    const finalAuthHeader =
      loginResponse.headers?.["x-amzn-remapped-authorization"] || loginResponse.headers?.authorization;
    setCookie("ba_session", finalAuthHeader.split(" ")[1], {
      path: "/",
      maxAge: 259200, // cookie will be removed after 3 days - TEMPORARILY FOR DEVELOPMENT
      domain: process.env.REACT_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
    setClientHeader("Authorization", finalAuthHeader.split(" ")[1]);
  }
  return { status: "redirect", email: loginResponse.data.email };
};

export const verifyToken = async () => {
  const verifyTokenResponse = await axios.get(`${AUTH_URL}/verifyToken`, {
    headers: {
      Authorization: getCookie("ba_session"),
    },
  });

  if (verifyTokenResponse.headers?.authorization || verifyTokenResponse.headers?.["x-amzn-remapped-authorization"]) {
    const finalAuthHeader =
      verifyTokenResponse.headers?.["x-amzn-remapped-authorization"] || verifyTokenResponse.headers?.authorization;
    setCookie("ba_session", finalAuthHeader.split(" ")[1], {
      path: "/",
      maxAge: 259200, // cookie will be removed after 3 days - TEMPORARILY FOR DEVELOPMENT
      domain: process.env.REACT_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
    setClientHeader("Authorization", finalAuthHeader.split(" ")[1]);
    return verifyTokenResponse.data.email;
  }
};

export const getImpersonateToken = async (clientEmail) => {
  const impersonateTokenResponse = await axios.post(
    `${AUTH_URL}/impersonate-mode`,
    {
      clientEmail,
    },
    {
      headers: {
        Authorization: getCookie("ba_session"),
      },
    }
  );
  if (impersonateTokenResponse.status === 200) {
    setCookie("ba_imp_token", impersonateTokenResponse.data, {
      path: "/",
      maxAge: 3600,
      domain: process.env.REACT_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
    return "redirect";
  }
  return null;
};

export const logout = async () => {
  removeCookie("ba_session");
};

export const getCurrentUser = async () => {
  return await graphQLClient.request(GET_CURRENT_USER);
};

export const updateCurrentUser = async (input) => {
  return await graphQLClient.request(UPDATE_CURRENT_USER, { input });
};
