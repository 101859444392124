import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, keyBy, merge } from "lodash";

const initialState = {
  contracts: [],
  contractsById: {},
  contractDocumentTemplates: [],
};

export const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    setContracts: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        contracts: action.payload,
        contractsById: merge(cloneDeep(originalState.contractsById), keyBy(action.payload, "id")),
      };
    },
    setContractDocumentTemplates: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        contractDocumentTemplates: action.payload,
      };
    },
  },
});

export const { setContracts, setContractDocumentTemplates } = contractsSlice.actions;

export default contractsSlice.reducer;

export const getContracts = (state) => state.contracts.contracts;
export const selectContractsById = (state) => state.projects.contractsById;
export const getContractDocumentTemplates = (state) => state.contracts.contractDocumentTemplates;
