import axios from "axios";

const baseUrl = `https://api.calendly.com/`;
const token = process.env.REACT_APP_CALENDLY_TOKEN;

export const getUserUri = async () => {
  return await axios(`https://api.calendly.com/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token} `,
    },
  });
};

export const eventPageUrl = async (organizationUrl) => {
  return await axios(`${baseUrl}/event_types?organization=${organizationUrl}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token} `,
    },
  });
};

export const getConsultationEvents = async (eventUUID, organizationUrl) => {
  return await axios(`${baseUrl}scheduled_events?uuid=${eventUUID}&organization=${organizationUrl}&scope=user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const getConsultationEvent = async (uri) => {
  return await axios(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const getEventInvitee = async (uri) => {
  return await axios(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const getEventInviteeWithEventUserUUID = async (eventUUID, userUUID) => {
  return await axios(`${baseUrl}scheduled_events/${eventUUID}/invitees/${userUUID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const listEventInviteesForUUID = async (eventUUID, inviteeUUID) => {
  return await axios(`${baseUrl}scheduled_events/${eventUUID}/invitees/${inviteeUUID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const listEventInvitees = async (uri) => {
  return await axios(uri, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const getAllScheduledEvents = async (organizationUrl) => {
  return await axios(`${baseUrl}scheduled_events`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};

export const cancelEvent = async (uri) => {
  const linkURIParts = uri.split("/");
  const consultationUUID = linkURIParts[linkURIParts.length - 1];
  if (!consultationUUID) {
    return;
  }
  return await axios(`${baseUrl}/scheduled_events/${consultationUUID}/cancellation `, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};
