import {
  TableCells,
  TableFilters,
  TableCards,
  NavCard,
  Button,
  TableHeaders,
} from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  Disabled: "gray",
  Active: "green",
};

export const partnersColumns = {
  select: {
    id: "select",
    accessor: "selected_row",
    Header: TableHeaders.CheckboxAllPageHeader,
    Cell: TableCells.CheckboxCell,
    renderCard: TableCards.CheckboxInputRow,
    disableSortBy: true,
    minWidth: "50px",
    maxWidth: "50px",
  },
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  logo: {
    id: "logo",
    Header: "logo",
    accessor: "image",
    Cell: ({ row: { original }, column, table }) => {
      const finalImg = original.image;
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={original.companyName}
          image={finalImg}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const finalImg = original.image;
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageContainerClassName="h-64 flex items-center justify-center bg-neutral-100"
          imageClassName="w-32 h-32 object-cover rounded-lg"
          imageOnly
        />
      );
    },
  },
  companyName: {
    id: "companyName",
    Header: "Company Name",
    accessor: "name",
    Cell: ({ row: { original }, column, table }) => {
      const finalImg = original.image;
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={original.name}
          image={finalImg}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          showPlaceholder
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TitleRow,
    minWidth: "400px",
  },
  services: {
    id: "services",
    Header: "Services",
    accessor: "services",
    Cell: TableCells.BadgesCell,
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    hasFilterSearch: true,
    minWidth: "400px",
  },
  email: {
    id: "email",
    Header: "Message",
    accessor: "email",
    Cell: (originalRow) => {
      const handleClick = () => {
        originalRow?.column?.onOpenClick("send", originalRow);
      };
      const isLoading = !!originalRow?.table?.options?.meta?.isLoading;

      return originalRow?.row?.original?.email || isLoading ? (
        <div className="p-4" onClick={(e) => e?.stopPropagation()}>
          <Button isDataLoading={isLoading} label="Email" onClick={handleClick} appearance="secondary" />
        </div>
      ) : (
        <div />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TextRow,
  },
  website: {
    id: "website",
    Header: "Website",
    accessor: "website",
    Cell: (props) => {
      return props?.getValue() ? <TableCells.ButtonCell {...props} /> : null;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    className: "w-[60%]",
    renderCard: TableCards.LinkRow,
    labelAccessor: "Link",
    hrefAccessor: "website",
    targetAccessor: "_blank",
  },
  officePhone: {
    id: "officePhone",
    Header: "Company Phone",
    accessor: "companyPhone",
    Cell: TableCells.PhoneNumberCell,
    renderCard: TableCards.TextRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  markets: {
    id: "markets",
    Header: "Market",
    accessor: "markets",
    Cell: ({ getValue, ...rest }) => {
      return <TableCells.TextCell getValue={() => getValue()?.join(", ") || ""} {...rest} />;
    },
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    className: "w-[10%] hidden",
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: () => "Active",
    Cell: (originalRow) => {
      return <TableCells.StatusCell status={"Active"} {...originalRow} show />;
    },
    statusAccessor: "status",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: (originalRow) => (
      <TableCards.StatusRow status={"Active"} circleColor={STATUS_COLOR_CODES.Active} {...originalRow} />
    ),
  },
};
