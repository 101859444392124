import PropTypes from "prop-types";
import cx from "classnames";

const SIZE = {
  sm: "sm",
  lg: "lg",
};

const sizeClassName = {
  [SIZE.sm]: "text-sm text-neutral-500",
  [SIZE.lg]: "text-2xl font-medium",
};

const labelClassName = {
  [SIZE.sm]: "text-sm text-neutral-500",
  [SIZE.lg]: "text-md font-medium",
};
const Totals = ({ value, label, size = SIZE.sm, classNameValue = "", showBorder }) => {
  return (
    <div className={cx("flex items-center justify-between py-2", showBorder && "border-b border-neutral-200")}>
      <span className={cx(labelClassName[size])}>{label}</span>
      <span className={cx(sizeClassName[size], classNameValue)}>${value}</span>
    </div>
  );
};

Totals.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZE)),
};

export default Totals;
