import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  suppliersQuotes: [],
};

export const suppliersQuotesSlice = createSlice({
  name: "suppliersQuotes",
  initialState,
  reducers: {
    setSuppliersQuotes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        suppliersQuotes: action.payload,
      };
    },
  },
});

export const { setSuppliersQuotes } = suppliersQuotesSlice.actions;

export default suppliersQuotesSlice.reducer;

export const getSuppliersQuotes = (state) => state.suppliersQuotes?.suppliersQuotes;
