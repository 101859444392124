import { TableCells, TableCards } from "@buildappeal/react-component-library";
import dayjs from "dayjs";

import generateInitials from "@utils/generateInitials";
import { getAddressFromPlaces, getProjectLink, isAlgoliaAttributeValueEmpty, titleCase } from "@utils/common";
import { PAGE_LEVEL } from "@features/layout/constants";
import { getFullName } from "@features/people/utils";

export const commonNotesColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    minWidth: "70px",
    maxWidth: "70px",
  },
  {
    id: "date",
    Header: "Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  {
    id: "message",
    Header: "Message",
    accessor: "message",
    Cell: TableCells.TextCell,
  },
  {
    id: "type",
    Header: "Type",
    accessor: "noteType",
    Cell: TableCells.TextCell,
  },
  {
    id: "by",
    Header: "By (Staff)",
    accessor: (row) => row.staff?.fullName,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          avatarSrc={originalRow.row.original?.staff?.avatar}
          initials={generateInitials(originalRow.getValue() || originalRow.row.original?.email)}
          {...originalRow}
        />
      );
    },
  },
];

export const columns = [...commonNotesColumns];
columns.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.project?.places?.addressLine1
      ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original.project?.places?.city}
        addressState={originalRow.row.original.project?.places?.state}
        addressZip={originalRow.row.original.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const loadingPlaceholderNote = {};

export const getSubTitle = (note, pageLevel) => {
  const { project, partners, suppliers, contacts } = note;

  const partner = partners?.[0];
  const supplier = suppliers?.[0];
  const contact = contacts?.[0];

  if (project) {
    return project?.places && pageLevel !== PAGE_LEVEL.projects ? getAddressFromPlaces(project?.places) : null;
  }

  if (partner) {
    return `${partner.companyName} (Partner)`;
  }

  if (supplier) {
    return `${supplier.name} (Supplier)`;
  }

  if (contact && contact?.partners?.length > 0) {
    return `${getFullName(contact)} at ${contact.partners[0]?.companyName} (Partner)`;
  } else if (contact && contact?.suppliers?.length > 0) {
    return `${getFullName(contact)} at ${contact.suppliers[0]?.name} (Supplier)`;
  } else if (contact) {
    return `${getFullName(contact)} (Contact)`;
  }

  return null;
};

export const getSubTitleLink = (note, pageLevel) => {
  const { project, partners, suppliers, contacts } = note;

  const partner = partners?.[0];
  const supplier = suppliers?.[0];
  const contact = contacts?.[0];

  if (project) {
    return project.id && pageLevel !== PAGE_LEVEL.projects ? getProjectLink(project) : null;
  }

  if (partner) {
    return `/partners?id=${partner.id}`;
  }

  if (supplier) {
    return `/suppliers?id=${supplier.id}`;
  }

  if (contact && contact?.partners?.length > 0) {
    return `/partners?id=${contact.partners[0]?.id}`;
  } else if (contact && contact?.suppliers?.length > 0) {
    return `/suppliers?id=${contact.suppliers[0]?.id}`;
  } else if (contact) {
    return `/people?id=contact_${contact.id}`;
  }

  return null;
};

export const transformNoteCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    noteType: "Type",
    "project.fullAddress": "Project",
  };
  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => {
      if (refinement.attribute === "createdAt") {
        return {
          ...refinement,
          label: refinement.operator.includes(">")
            ? `From: ${dayjs(new Date(refinement.value)).format("MMM D, YYYY")}`
            : `To: ${dayjs(new Date(refinement.value)).format("MMM D, YYYY")}`,
        };
      }

      return {
        ...refinement,
        label: isAlgoliaAttributeValueEmpty(refinement.label)
          ? `No ${refinementAttributeToLabelMap[item.attribute]}`
          : titleCase(refinement.label),
      };
    }),
  }));
};
