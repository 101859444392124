import { Avatar, NavCard, TableCards, TableCells, TableFilters } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Signed: "green",
  Draft: "gray",
  Sent: "orange",
  Completed: "green",
  Viewed: "green",
  "Awaiting Approval": "orange",
  Approved: "green",
  Rejected: "red",
  "Awaiting Payment": "orange",
  Paid: "green",
  Voided: "red",
  Declined: "red",
  "External Review": "gray",
};

export const contractColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column }) => {
      return (
        <NavCard
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city ? original.project?.places?.city + "," : ""} ${
            original.project?.places?.state || ""
          } ${original.project?.places?.zipCode || ""}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          actions={column.actions || []}
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column }) => {
      return (
        <NavCard
          layout="card"
          image={original?.project?.thumbnail?.path}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageOnly
        />
      );
    },
    maxWidth: "3fr",
  },
  clients: {
    id: "clients",
    Header: "Signer",
    accessor: (row) => {
      return row?.clients?.[0]?.fullName;
    },
    avatarAccessor: (row) => row?.clients?.[0]?.avatar || "",
    Cell: (originalRow) => {
      const { row, column, table } = originalRow;
      const isLoading = !!table?.options?.meta?.isLoading;

      if (!isLoading && !row?.original?.clients.length) {
        return null;
      }
      return (
        <TableCells.UserCell
          column={column}
          row={row}
          avatarSrc={row?.original?.clients?.[0].avatar}
          initials={generateInitials(row?.original?.clients?.[0]?.fullName || row?.original?.clients?.[0]?.email)}
          {...originalRow}
          id={row?.original?.clients?.[0]?.id}
          path="/clients"
          role="client"
          type="client"
          onQuickViewOpen={(data) => {
            column.onQuickViewOpen({ ...data, selectedItem: { ...data.selectedItem, isReducedView: true } });
          }}
        />
      );
    },
    className: "whitespace-nowrap w-[10%]",
    renderCard: ({ row, column, table }) => {
      const client = row?.original?.client;
      if (!client) return null;
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            const client = row?.original?.client;
            return [
              {
                avatar: client?.avatar,
                name: client?.fullName,
              },
            ];
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    minWidth: "200px",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  address: {
    id: "address",
    Header: "Address",
    accessor: (row) => row.project?.places?.addressLine1 || "",
    subtitleAccessor: (row) => {
      const { city, state, zipCode } = row?.project?.places;
      return `${city}, ${state} ${zipCode}`;
    },
    className: "whitespace-nowrap",
    renderCard: ({ row, column }) => {
      const { city, state, zipCode } = row?.original?.project?.places;
      return (
        <TableCards.TitleRow
          getValue={() => {
            return row?.original?.project?.places?.addressLine1 || "";
          }}
          subtitle={`${city}, ${state} ${zipCode}`}
          row={row}
          column={column}
        />
      );
    },
  },
  contractType: {
    id: "type",
    Header: "Type",
    accessor: "contractType",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TextRow,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  createdBy: {
    id: "createdBy",
    Header: "Created By",
    accessor: ({ creator }) => creator,
    Cell: ({ row: { original }, table, column }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      const creator = original.creator?.staff;

      return (
        <div className="py-4 px-[18px]">
          <Avatar
            isLoading={isLoading}
            size="sm"
            hasTooltip
            src={creator?.avatar}
            title={creator?.fullName}
            handleQuickViewOpen={(data) => {
              column.onQuickViewOpen({ ...data, selectedItem: { ...data.selectedItem, isReducedView: true } });
            }}
          />
        </div>
      );
    },
    disableSortBy: true,
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: (row) => {
      return row.status;
    },
    Cell: ({ row: { original }, table }) => {
      return (
        <TableCells.StatusCell
          status={original.status?.name}
          circleColor={`bg-[${original.status?.color}]` || "bg-grey"}
          table={table}
          show
        />
      );
    },
    className: "whitespace-nowrap",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    maxWidth: "2fr",
    titleClassName: "text-3xs font-normal",
    subtitleClassName: "text-xs font-normal",
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.StatusRow
          getValue={() => {
            return row?.original?.status?.name;
          }}
          row={row}
          column={column}
          table={table}
          status={row?.original?.status?.name}
        />
      );
    },
  },
  lastUpdated: {
    id: "lastUpdate",
    Header: "Last Update",
    accessor: (originalRow) => {
      const dt = new Date(originalRow.lastUpdated);
      return dt.toISOString();
    },
    Cell: TableCells.DateCell,
    formatAccessor: "MMM DD, YYYY",
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  staff: {
    id: "owner",
    Header: "Owner",
    accessor: (row) => row.staff?.[0]?.fullName,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value=""
          avatarSrc={originalRow.row.original.staff?.[0]?.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
          initials={generateInitials(originalRow.row.original.staff?.[0]?.fullName)}
          {...originalRow}
        />
      );
    },
    disableSortBy: true,
    renderCard: ({ row, column }) => {
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            return (
              row?.original?.staff?.map((owner) => ({
                avatar: owner.avatar,
                name: owner.fullName,
              })) || []
            );
          }}
          row={row}
          column={column}
        />
      );
    },
  },
};
