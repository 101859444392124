import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_INVOICES = gql`
  query getInvoices($filters: InvoiceFilterFields!) {
    getInvoices(filters: $filters) {
      id
      title
      invoiceCode
      invoiceTypes
      invoiceDate
      dueDate
      total
      status
      pandaDocUUID
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_PROJECT_INVOICES = gql`
  query getInvoices($filters: InvoiceFilterFields!) {
    getInvoices(filters: $filters) {
      id
      title
      invoiceCode
      invoiceTypes
      invoiceDate
      dueDate
      total
      status
      pandaDocUUID
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      invoiceItems {
        id
        description
        quantity
        subTotal
        total
      }
    }
  }
`;

const GET_INVOICE = gql`
  query getInvoiceByID($id: Int!) {
    getInvoiceByID(id: $id) {
      id
      title
      invoiceCode
      invoiceTypes
      invoiceDate
      dueDate
      total
      status
      pandaDocUUID
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        projectStages {
          id
          name
          stageType
        }
      }
      document {
        id
        uploadedAt
        name
        path
        fileType
      }
    }
  }
`;

const GET_INVOICE_ITEMS = gql`
  query getInvoiceItems($filters: InvoiceItemFilterFields!) {
    getInvoiceItems(filters: $filters) {
      id
      description
      quantity
      subTotal
      total
      invoice {
        id
        project {
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
      }
    }
  }
`;

const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceRequest!) {
    createInvoice(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        invoiceCode
        invoicePrefix
        invoiceNumber
        invoiceTypes
        title
        invoiceDate
        dueDate
        total
        status
        xeroInvoiceID
        project {
          id
          places {
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        invoiceItems {
          id
          createdAt
          description
          quantity
          subTotal
          total
        }
        document {
          id
          uploadedAt
          name
          path
          fileType
        }
      }
    }
  }
`;
const UPDATE_INVOICE = gql`
  mutation updateInvoice($input: UpdateInvoiceRequest!) {
    updateInvoice(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        invoiceCode
        invoicePrefix
        invoiceNumber
        invoiceTypes
        title
        invoiceDate
        dueDate
        total
        status
        xeroInvoiceID
        project {
          id
          places {
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        invoiceItems {
          id
          createdAt
          description
          quantity
          subTotal
          total
        }
        document {
          id
          uploadedAt
          name
          path
          fileType
        }
      }
    }
  }
`;

const GET_INVOICE_URL = gql`
  query getInvoiceDocUrl($docId: String!, $recipient: String!) {
    getInvoiceDocUrl(docId: $docId, recipient: $recipient)
  }
`;

export const fetchInvoices = async (filters = {}) => {
  return await graphQLClient.request(GET_INVOICES, { filters });
};

export const fetchInvoice = async (id) => {
  return await graphQLClient.request(GET_INVOICE, { id });
};

export const fetchInvoiceItems = async (filters = {}) => {
  return await graphQLClient.request(GET_INVOICE_ITEMS, { filters });
};

export const createInvoice = async (input) => {
  return await graphQLClient.request(CREATE_INVOICE, { input });
};

export const updateInvoice = async (input) => {
  return await graphQLClient.request(UPDATE_INVOICE, { input });
};

export const fetchProjectInvoices = async (filters = {}) => {
  if (!filters?.projectIds?.length) {
    return null;
  }
  return await graphQLClient.request(GET_PROJECT_INVOICES, { filters });
};

export const getInvoiceDocUrl = async (docId, recipient) => {
  return await graphQLClient.request(GET_INVOICE_URL, { docId, recipient });
};
