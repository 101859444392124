import { useState } from "react";
import { useMutation } from "react-query";
import { createLook } from "../../api";

const useAddLook = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      return createLook(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createLook.success) {
          setError(`Error: ${data.createLook?.message}`);
        } else {
          setIsSuccess(true);
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addLook: mutate,
    isLoading,
    isSuccess,
    error,
    addLookAsync: mutateAsync,
  };
};

export default useAddLook;
