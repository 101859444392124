import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";
import { fetchServices } from "../../api";
import { setServices, getServices } from "../../servicesSlice";

const useServices = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const services = useSelector(getServices);

  const { data, isLoading } = useQuery(["services", filters], () => fetchServices(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setServices(data.getServices));
  }, [data, isLoading, dispatch]);

  const servicesAsOptions = useMemo(() => {
    if (!services?.length) {
      return [];
    }
    return orderBy(
      services.map((serviceEntry) => {
        return {
          value: serviceEntry.id,
          title: serviceEntry.name,
          service: serviceEntry,
        };
      }),
      ["title"],
      ["asc"]
    );
  }, [services]);
  return {
    data: services,
    options: servicesAsOptions,
    isLoading,
  };
};

export default useServices;
