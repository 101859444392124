import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setSearchResults, getSearchResults } from "../../searchSlice";

const useSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchResults = useSelector(getSearchResults);

  const onBackClick = () => navigate(-1);
  const onSearchResultClick = (searchResult) => {
    const { index, id, uuid } = searchResult;

    if (index === "projects") {
      const { projectStageType } = searchResult;
      return navigate(`/${projectStageType === "Lead" ? "leads" : "projects"}?id=${id}&showCustomLeft=true`);
    }
    if (index === "products") return navigate(`/products?id=${id}`);
    if (["users", "clients", "staff"].includes(index)) return navigate(`/people?id=${uuid}`);
    if (index === "partners") return navigate(`/partners?id=${id}`);
    if (index === "suppliers") return navigate(`/suppliers?${id}`);
  };
  const gotoSearchResultPage = (search) => {
    if (search) return navigate(`/search?s=${search}`);
    return navigate("/search");
  };

  const onSearchSuccess = (processedResults = []) => {
    dispatch(setSearchResults(processedResults));
  };

  return {
    onBackClick,
    onSearchResultClick,
    gotoSearchResultPage,
    data: searchResults,
    onSearchSuccess,
  };
};

export default useSearch;
