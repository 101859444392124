import { TableCells, CloudinaryImage, NavCard, TableCards } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const purchaseColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={`Purchase #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          title={`Purchase #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  purchaseItems: {
    id: "products",
    Header: "Product",
    accessor: (originalRow) => {
      return (originalRow.purchaseItems || "").map((item) => {
        return item.product.name;
      });
    },
    Cell: (originalRow) => {
      if (!originalRow.row.original.purchaseItems?.length) return "";
      const imgItems =
        originalRow.row.original.purchaseItems.length > 3
          ? originalRow.row.original.purchaseItems.slice(0, 3)
          : originalRow.row.original.purchaseItems;
      return (
        <div className="flex flex-row p-2">
          {imgItems.map((item) => {
            if (!item.product.media?.[0]?.path) return "";
            return (
              <CloudinaryImage
                imgSrc={item.product.media?.[0]?.path || ""}
                alt={item.product?.name}
                className="mr-1 h-14 w-16 min-w-[4rem] rounded-md object-cover"
                transformations={{
                  width: 95,
                  height: 83,
                }}
              />
            );
          })}
          {originalRow.row.original.purchaseItems.length > 3 ? (
            <div>{`+ ${originalRow.row.original.purchaseItems.length - 3} more`}</div>
          ) : (
            ""
          )}
        </div>
      );
    },
    minWidth: "25vw",
    maxWidth: "30vw",
  },
  estimatedDelivery: {
    id: "estimatedDelivery",
    Header: "Estimated Delivery",
    accessor: "estimatedDelivery",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  purchaseDate: {
    id: "purchaseDate",
    Header: "Purchase Date",
    accessor: "purchaseDate",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  vendorOrderNumber: {
    id: "vendorOrderNumber",
    Header: "Vendor Order Number",
    accessor: "vendorOrderNumber",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  subTotal: {
    id: "subTotal",
    Header: "Sub Total",
    accessor: "subTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  shippingTotal: {
    id: "shippingTotal",
    Header: "Shipping Total",
    accessor: "shippingTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  taxTotal: {
    id: "taxTotal",
    Header: "Tax Total",
    accessor: "taxTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
};

export const purchaseItemsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: (originalRow) =>
      originalRow.purchase?.project?.places?.addressLine1
        ? `${originalRow.purchase?.project?.places?.addressLine1} ${
            originalRow.purchase?.project?.places?.addressLine2 || ""
          }`.trim()
        : null,
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original?.purchase?.project?.places?.city}
          addressState={originalRow.row.original?.purchase?.project?.places?.state}
          addressZip={originalRow.row.original?.purchase?.project?.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  purchase: {
    id: "purchaseId",
    Header: "Purchase ID",
    accessor: (row) => row.purchase?.id,
    Cell: TableCells.TextCell,
  },
  quantity: {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  product: {
    id: "product",
    Header: "Product",
    accessor: (row) => row.product?.name,
    Cell: TableCells.TextCell,
  },
  costPerItem: {
    id: "costPerItem",
    Header: "Cost Per Item",
    accessor: "costPerItem",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  extendedCost: {
    id: "total",
    Header: "Total",
    accessor: "extendedCost",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
};
