import { useConfigure } from "react-instantsearch-hooks-web";
import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";
import { transformPartnerCurrentRefinementItems } from "./utils";

const PartnerFilters = () => {
  useConfigure({
    filters: "companyType:partner",
  });
  return (
    <div className="mb-3 space-y-4">
      <div className="my-5 flex flex-wrap items-center gap-2.5">
        <DynamicFacetsList>
          <AlgoliaFacetFilter attribute="services" label="Services" searchable />
          <AlgoliaFacetFilter attribute="markets" label="Market" searchable />
        </DynamicFacetsList>
      </div>
      <CurrentRefinements transformItems={transformPartnerCurrentRefinementItems} />
    </div>
  );
};

export default PartnerFilters;
