import React, { Suspense } from "react";
import lazyWithRetry from "@utils/LazyWithRetry";
import { VIEW_TYPES } from "./constants";

const ProductView = lazyWithRetry(() => import("@src/features/products/view"));
const SupplierView = lazyWithRetry(() => import("@src/features/suppliers/view"));
const ConsultationsView = lazyWithRetry(() => import("@src/features/consultations/view"));
const EmailView = lazyWithRetry(() => import("@src/features/emails/view"));
const ProjectView = lazyWithRetry(() => import("@src/features/projects/view"));
const InvoiceView = lazyWithRetry(() => import("@src/features/invoices/view"));
const ContractView = lazyWithRetry(() => import("@src/features/contracts/view"));
const MessageView = lazyWithRetry(() => import("@src/features/messages/view"));
const ActivityView = lazyWithRetry(() => import("@src/features/activities/view"));
const ServiceView = lazyWithRetry(() => import("@src/features/services/view"));
const DesignStyleView = lazyWithRetry(() => import("@src/features/designs/view"));
const StoryboardsView = lazyWithRetry(() => import("@src/features/storyboards/view"));
const TaskView = lazyWithRetry(() => import("@src/features/tasks/view"));
const ActionObjectView = lazyWithRetry(() => import("@src/features/actionsObjects/view"));

const BillView = lazyWithRetry(() => import("@src/features/bills/view"));
const BidView = lazyWithRetry(() => import("@src/features/bids/view"));
const BudgetView = lazyWithRetry(() => import("@src/features/budgets/view"));
const ChangeOrderView = lazyWithRetry(() => import("@src/features/changeOrders/view"));
const ProposalView = lazyWithRetry(() => import("@src/features/proposals/view"));
const PurchaseOrderView = lazyWithRetry(() => import("@src/features/purchaseOrders/view"));
const PurchaseView = lazyWithRetry(() => import("@src/features/purchases/view"));
const StaffView = lazyWithRetry(() => import("@src/features/staff/view"));
const ClientView = lazyWithRetry(() => import("@src/features/clients/view"));
const PartnerView = lazyWithRetry(() => import("@src/features/partners/view"));
const ContactView = lazyWithRetry(() => import("@src/features/contacts/view"));
const MarketView = lazyWithRetry(() => import("@src/features/markets/view"));
const NoteView = lazyWithRetry(() => import("@src/features/notes/view"));
const PeopleView = lazyWithRetry(() => import("@src/features/people/view"));
const EstimateView = lazyWithRetry(() => import("@src/features/estimates/view"));
const FinishView = lazyWithRetry(() => import("@src/features/finishes/view"));
const ObjectView = lazyWithRetry(() => import("@src/features/objects/view"));
const ProjectMapView = lazyWithRetry(() => import("@src/features/projects/view/MapView"));
const ConsultationsMapView = lazyWithRetry(() => import("@src/features/consultations/view/MapView"));
const PartnersMapView = lazyWithRetry(() => import("@src/features/partners/view/MapView"));
const SuppliersMapView = lazyWithRetry(() => import("@src/features/suppliers/view/MapView"));
const MaterialView = lazyWithRetry(() => import("@src/features/materials/view"));
const LookView = lazyWithRetry(() => import("@src/features/looks/view"));

const CentralUIGenerator = ({ currentPath = "", activeView, data, ...rest }) => {
  if (!currentPath) {
    return null;
  }

  if (activeView === VIEW_TYPES.Map && !rest.selectedItem) {
    return (
      <div className="absolute top-0 left-0 bottom-0 right-0 h-full w-full">
        <Suspense fallback={<div />}>
          {["/projects", "/leads"].includes(currentPath) ? <ProjectMapView projects={data} {...rest} /> : null}
          {currentPath === "/consultations" ? <ConsultationsMapView consultations={data} {...rest} /> : null}
          {currentPath === "/partners" ? <PartnersMapView partners={data} {...rest} /> : null}
          {currentPath === "/suppliers" ? <SuppliersMapView suppliers={data} {...rest} /> : null}
        </Suspense>
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <Suspense fallback={<div />}>
        {currentPath === "/products" ? <ProductView {...rest} /> : null}
        {currentPath === "/suppliers" ? <SupplierView {...rest} /> : null}
        {currentPath === "/consultations" ? <ConsultationsView {...rest} /> : null}
        {currentPath === "/emails" ? <EmailView {...rest} /> : null}
        {["/projects", "/leads"].includes(currentPath) ? <ProjectView {...rest} /> : null}
        {currentPath === "/contracts" ? <ContractView {...rest} /> : null}
        {currentPath === "/messages" ? <MessageView {...rest} /> : null}
        {currentPath === "/activities" ? <ActivityView {...rest} /> : null}
        {currentPath === "/financials/invoices" ? <InvoiceView {...rest} /> : null}
        {currentPath === "/financials/bids" ? <BidView {...rest} /> : null}
        {currentPath === "/financials/bills" ? <BillView {...rest} /> : null}
        {currentPath === "/financials/budgets" ? <BudgetView {...rest} /> : null}
        {currentPath === "/financials/change-orders" ? <ChangeOrderView {...rest} /> : null}
        {currentPath === "/financials/proposals" ? <ProposalView {...rest} /> : null}
        {currentPath === "/financials/purchase-orders" ? <PurchaseOrderView {...rest} /> : null}
        {currentPath === "/financials/purchases" ? <PurchaseView {...rest} /> : null}
        {currentPath === "/financials/estimates" ? <EstimateView {...rest} /> : null}
        {currentPath === "/services" ? <ServiceView {...rest} /> : null}
        {currentPath === "/designs/styles" ? <DesignStyleView {...rest} /> : null}
        {currentPath === "/designs/storyboards" ? <StoryboardsView {...rest} /> : null}
        {currentPath === "/tasks" ? <TaskView {...rest} /> : null}
        {currentPath === "/staff" ? <StaffView {...rest} /> : null}
        {currentPath === "/markets" ? <MarketView {...rest} /> : null}
        {currentPath === "/tasks/actions" ? <ActionObjectView {...rest} /> : null}
        {currentPath === "/clients" ? <ClientView {...rest} /> : null}
        {currentPath === "/partners" ? <PartnerView {...rest} /> : null}
        {currentPath === "/contacts" ? <ContactView {...rest} /> : null}
        {currentPath === "/notes" ? <NoteView {...rest} /> : null}
        {currentPath === "/people" ? <PeopleView {...rest} /> : null}
        {currentPath === "/materials" ? <MaterialView {...rest} /> : null}
        {currentPath === "/finishes" ? <FinishView {...rest} /> : null}
        {currentPath === "/objects" ? <ObjectView {...rest} /> : null}
        {currentPath === "/looks" ? <LookView {...rest} /> : null}
      </Suspense>
    </div>
  );
};

export default CentralUIGenerator;
