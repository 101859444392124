import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Checkbox,
  FileUpload,
  Notice,
  ProgressBar,
  Select,
  TextField,
} from "@buildappeal/react-component-library";
import { useMutation, useQueryClient } from "react-query";

import { US_STATES } from "@src/utils/constants";
import usePlacesWidget from "@src/utils/hooks/usePlacesWidget";
import { FILE_TYPES } from "../files/utils";
import useMultiFilesUpload from "../files/hooks/useMultiFilesUpload";
import useContacts from "../contacts/hooks/useContacts";
import { updateSupplier } from "./api";

const initialFormState = {
  id: null,
  name: "",
  addressLine1: "",
  addressLine2: "",
  website: "",
  email: "",
  phone: "",
  city: "",
  state: "",
  zipCode: "",
  contactFirstName: "",
  contactLastName: "",
  contactEmail: "",
};

// TODO: Update this to use hooks and Form

const EditSupplier = ({ supplier = {}, onClose, objectsAsOptions }) => {
  const { options: contactsAsOptions } = useContacts();
  const queryClient = useQueryClient();
  const [files, setFiles] = useState(null);
  const [uploadResults, setUploadResults] = useState(null);
  const [chooseExistingContact, setChooseExistingContact] = useState(!!supplier?.contacts?.[0]?.id);

  const [formState, setFormState] = useState({
    ...initialFormState,
    ...supplier,
    categoryIds: supplier?.categories?.map((category) => ({
      title: category?.description,
      value: category?.id,
    })),
    linkedContact: { title: supplier?.contacts?.[0]?.fullName, value: supplier?.contacts?.[0]?.id },
  });
  const [loading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { startUpload } = useMultiFilesUpload();

  const onPlaceSelected = (place) => {
    const { address, city, state, zipCode } = place;
    setFormValue({
      addressLine1: address,
      city,
      state: US_STATES.find((s) => s.title === state)?.value,
      zipCode,
    });
  };

  const { ref: addressRef } = usePlacesWidget({
    onPlaceSelected,
  });

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const isFormValid = () => {
    showErrMsg("");
    return true;
  };

  const showErrMsg = (msg) => {
    setErrMsg(msg);
    if (msg) {
      showErrMsgNotification();
    }
  };

  const showErrMsgNotification = () => {
    setShowErrorMessage(true);
    setTimeout(() => {
      setShowErrorMessage(false);
    }, 2000);
  };

  const uploadFiles = async (supplierId) => {
    const finalFilesList = (files || []).map((file) => {
      const fileKey = `suppliers/${supplierId}/${uuidv4()}`;
      return {
        key: fileKey,
        type: FILE_TYPES.media,
        file,
      };
    });
    const results = await startUpload(finalFilesList, true);
    setUploadResults(results);
    if (results.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  const updateSupplierMutation = useMutation(
    async (data) => {
      setIsLoading(true);
      // TODO: check where to use the status value
      const supplierData = {
        id: data.id,
        name: data.name,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        website: data.website,
        email: data.email,
        phone: data.phone,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
        contactFirstName: !chooseExistingContact ? data.contactFirstName : undefined,
        contactLastName: !chooseExistingContact ? data.contactLastName : undefined,
        contactEmail: !chooseExistingContact ? data.contactEmail : undefined,
        contactPhone: !chooseExistingContact ? data.contactPhone : undefined,
        contactId: chooseExistingContact ? data.linkedContact?.value : undefined,
        categoryIds: data?.categoryIds.map((cat) => cat.value),
      };

      if (files?.length && data.id) {
        const finalFilesList = await uploadFiles(data.id);
        if (finalFilesList?.length) {
          supplierData.logo = finalFilesList.map((fileInfo) => fileInfo.key);
        }
      }
      return updateSupplier(supplierData);
    },
    {
      onSuccess: (data) => {
        if (!data.updateSupplier?.success) {
          showErrMsgNotification();
          return;
        }
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
        setFormState({ ...initialFormState });
        setFiles(null);
        queryClient.setQueryData("suppliers", (old) => {
          if (!old) return { getSuppliers: [data.updateSupplier.data] };
          return {
            getSuppliers: old.getSuppliers.map((supplier) => {
              if (supplier.id === data.updateSupplier.data.id) {
                return {
                  ...supplier,
                  ...data.updateSupplier.data,
                };
              }
              return supplier;
            }),
          };
        });
      },
      onError: () => {
        showErrMsgNotification();
      },
      onSettled: () => {
        setIsLoading(false);
        onClose();
      },
    }
  );

  const onSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    const data = { ...formState };
    updateSupplierMutation.mutate(data);
  };

  const onDelete = ({ id }) => {
    if (!id) return;
  };

  useEffect(() => {
    setFormState({
      ...supplier,
      linkedContact: { title: supplier?.contacts?.[0]?.fullName, value: supplier?.contacts?.[0]?.id },
      categoryIds: supplier?.categories?.map((category) => ({
        title: category?.description,
        value: category?.id,
      })),
    });
  }, [supplier]);

  return (
    <div className="mt-16 flex w-full flex-col justify-center px-3">
      <div className="w-full">
        <p className="my-4 text-3xl font-medium">Edit Supplier</p>
        {loading && <ProgressBar min={0} max={100} now={90} className="my-4" />}
        {!loading && showSuccessMessage && <Notice type="success" message="Supplier successfully updated" />}
        {!loading && showErrorMessage && (
          <Notice type="critical" message={errMsg || "Supplier creation failed. Please try again!"} />
        )}
        <TextField
          label="Business Name"
          value={formState.name}
          onChange={({ target: { value } }) => setFormValue({ name: value })}
          className="mt-4"
          required
        />
        <TextField
          label="Email"
          type="email"
          value={formState.email}
          onChange={({ target: { value } }) => setFormValue({ email: value })}
          className="mt-4"
        />
        <TextField
          label="Phone"
          type="tel"
          value={formState.phone}
          onChange={({ target: { value } }) => setFormValue({ phone: value.toString() })}
          className="mt-4"
        />
        <TextField
          label="Website"
          value={formState.website}
          onChange={({ target: { value } }) => setFormValue({ website: value.toString() })}
          className="mt-4"
        />
        <Select
          label="Categories"
          value={formState.categoryIds}
          options={objectsAsOptions}
          onChange={(value) => setFormValue({ categoryIds: value })}
          className="mt-4"
          multiple
        />
        <TextField
          inputRef={addressRef}
          label="Address Line 1"
          value={formState.addressLine1}
          onChange={({ target: { value } }) => setFormValue({ addressLine1: value })}
          className="mt-4"
          required
        />
        <TextField
          label="Address Line 2"
          value={formState.addressLine2}
          onChange={({ target: { value } }) => setFormValue({ addressLine2: value })}
          className="mt-4"
        />
        <TextField
          label="City"
          value={formState.city}
          onChange={({ target: { value } }) => setFormValue({ city: value })}
          className="mt-4"
          required
        />
        <Select
          label="State"
          value={{
            title: US_STATES.find((state) => state.value === formState.state)?.title ?? "",
            value: formState.state ?? "",
          }}
          options={US_STATES}
          onChange={(opt) => setFormValue({ state: opt?.value })}
          className="mt-4"
        />
        <TextField
          label="Zip Code"
          type="number"
          value={formState.zipCode}
          onChange={({ target: { value } }) => setFormValue({ zipCode: value.toString() })}
          className="mt-6"
        />
        <p className="mt-4 font-medium">Logo</p>
        <div className="mt-4">
          <FileUpload
            handleSelectedFiles={(files) => {
              return setFiles(files);
            }}
            acceptedFileTypes={["image/jpeg", "image/png", "video/*", "image/heic", "image/webp"]}
            multiple={false}
            maxFiles={1}
            uploadResults={uploadResults}
            className="mx-auto"
          />
        </div>

        <Checkbox
          label="Already has a Contact"
          value={chooseExistingContact}
          onChange={(opt) => {
            setChooseExistingContact((prev) => !prev);
          }}
          checked={chooseExistingContact}
          className="my-4"
        />

        {chooseExistingContact ? (
          <>
            <Select
              label="Link Contact"
              className="mt-4"
              options={contactsAsOptions}
              value={{
                title: formState.linkedContact?.title,
                value: formState.linkedContact?.value,
              }}
              onChange={(opt) => {
                setFormValue({ linkedContact: opt });
              }}
            />
          </>
        ) : (
          <>
            <TextField
              label="First Name"
              type="text"
              value={formState.contactFirstName}
              onChange={({ target: { value } }) => setFormValue({ contactFirstName: value.toString() })}
              className="mt-4"
            />
            <TextField
              label="Last Name"
              type="text"
              value={formState.contactLastName}
              onChange={({ target: { value } }) => setFormValue({ contactLastName: value.toString() })}
              className="mt-4"
            />
            <TextField
              label="Email"
              type="text"
              value={formState.contactEmail}
              onChange={({ target: { value } }) => setFormValue({ contactEmail: value.toString() })}
              className="mt-4"
            />
            <TextField
              label="Phone"
              type="text"
              value={formState.contactPhone}
              onChange={({ target: { value } }) => setFormValue({ contactPhone: value.toString() })}
              className="mt-4"
            />
          </>
        )}
        <div className=" mt-8 flex justify-between">
          <Button className="mt-8" disabled={loading} label="Update Supplier" size="md" onClick={onSubmit} />
          <Button
            appearance="secondary"
            className="mt-8"
            disabled={loading}
            label="Delete Supplier"
            size="md"
            onClick={() => onDelete({ id: supplier?.id })}
          />
        </div>
      </div>
    </div>
  );
};

export default EditSupplier;
