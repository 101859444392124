import { configureStore } from "@reduxjs/toolkit";
import { setAutoFreeze } from "immer";

import emailReducer from "@src/features/emails/emailsSlice";
import authReducer from "@src/features/auth/authSlice";
import partnersReducer from "@src/features/partners/partnersSlice";
import projectsReducer from "@src/features/projects/projectsSlice";
import productsReducer from "@src/features/products/productsSlice";
import contractsReducer from "@src/features/contracts/contractsSlice";
import suppliersReducer from "@src/features/suppliers/suppliersSlice";
import clientsReducer from "@src/features/clients/clientsSlice";
import staffReducer from "@src/features/staff/staffSlice";
import leadsReducer from "@src/features/leads/leadsSlice";
import budgetsReducer from "@src/features/budgets/budgetsSlice";
import searchReducer from "@src/features/search/searchSlice";
import estimatesReducer from "@src/features/estimates/estimatesSlice";
import invoicesReducer from "@src/features/invoices/invoicesSlice";
import bidsReducer from "@src/features/bids/bidsSlice";
import billsReducer from "@src/features/bills/billsSlice";
import tasksReducer from "@src/features/tasks/tasksSlice";
import presentationsReducer from "@src/features/presentations/presentationsSlice";
import storyboardsReducer from "@src/features/storyboards/storyboardsSlice";
import consultationsReducer from "@src/features/consultations/consultationsSlice";
import notesReducer from "@src/features/notes/notesSlice";
import changeOrdersReducer from "@src/features/changeOrders/changeOrdersSlice";
import purchaseOrdersReducer from "@src/features/purchaseOrders/purchaseOrdersSlice";
import proposalsReducer from "@src/features/proposals/proposalsSlice";
import usersReducer from "@src/features/users/usersSlice";
import activitiesReducer from "@src/features/activities/activitiesSlice";
import insightsReducer from "@src/features/insights/insightsSlice";
import servicesReducer from "@src/features/services/servicesSlice";
import templatesReducer from "@src/features/templates/templatesSlice";
import materialsReducer from "@src/features/materials/materialsSlice";
import spacesReducer from "@src/features/spaces/spacesSlice";
import materialTypesReducer from "@src/features/materialTypes/materialTypesSlice";
import projectSpaceObjectsReducer from "@src/features/projectSpaceObjects/projectSpaceObjectsSlice";
import filesReducer from "@src/features/files/filesSlice";
import designsStylesReducer from "@src/features/designs/designStyleSlice";
import actionsObjectsReducer from "@src/features/actionsObjects/actionsObjectsSlice";
import purchasesReducer from "@src/features/purchases/purchasesSlice";
import suppliersQuotesSlice from "@src/features/suppliersQuotes/suppliersQuotesSlice";
import messagesReducer from "@src/features/messages/messageSlice";
import contactsReducer from "@src/features/contacts/contactsSlice";
import MarketsReducer from "@src/features/markets/marketsSlice";
import ObjectsReducer from "@src/features/objects/objectsSlice";
import PeopleReducer from "@src/features/people/peopleSlice";
import mediaCoordinatesReducer from "@src/features/mediaCoordinates/mediaCoordinatesSlice";
import estimateSelectionsReducer from "@src/features/estimateSelections/estimateSelectionsSlice";
import layoutReducer from "@src/features/layout/layoutSlice";
import generalActionsReducer from "@src/utils/components/GeneralActions/generalActionsSlice";

setAutoFreeze(false);

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
    products: productsReducer,
    contracts: contractsReducer,
    contacts: contactsReducer,
    suppliers: suppliersReducer,
    clients: clientsReducer,
    staff: staffReducer,
    leads: leadsReducer,
    search: searchReducer,
    partners: partnersReducer,
    budgets: budgetsReducer,
    estimates: estimatesReducer,
    invoices: invoicesReducer,
    bids: bidsReducer,
    bills: billsReducer,
    tasks: tasksReducer,
    presentations: presentationsReducer,
    storyboards: storyboardsReducer,
    consultations: consultationsReducer,
    notes: notesReducer,
    changeOrders: changeOrdersReducer,
    purchaseOrders: purchaseOrdersReducer,
    proposals: proposalsReducer,
    users: usersReducer,
    activities: activitiesReducer,
    insights: insightsReducer,
    services: servicesReducer,
    materials: materialsReducer,
    templates: templatesReducer,
    spaces: spacesReducer,
    materialTypes: materialTypesReducer,
    projectSpaceObjects: projectSpaceObjectsReducer,
    files: filesReducer,
    designsStyles: designsStylesReducer,
    actionsObjects: actionsObjectsReducer,
    purchases: purchasesReducer,
    suppliersQuotes: suppliersQuotesSlice,
    messages: messagesReducer,
    markets: MarketsReducer,
    objects: ObjectsReducer,
    people: PeopleReducer,
    mediaCoordinates: mediaCoordinatesReducer,
    estimateSelections: estimateSelectionsReducer,
    layout: layoutReducer,
    generalActions: generalActionsReducer,
    emails: emailReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      localStorage.getItem("performance")
        ? {
            serializableCheck: false,
            immutableCheck: false,
          }
        : { serializableCheck: false }
    ),
});
