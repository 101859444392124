import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_FILE_FINISHES = gql`
  query getFinishes($filters: FinishFilterFields!) {
    getFinishes(filters: $filters) {
      id
      name
      type
      parent {
        id
        name
        type
      }
      media {
        id
        name
        fileType
        path
        type
        isThumbnail
        isFeatured
        sortOrder
      }
      documents {
        id
        name
        fileType
        path
        type
        contentType
        sortOrder
      }
    }
  }
`;

const GET_FILE_FINISH_BY_ID = gql`
  query getFinishesById($id: Int!) {
    getFinishesById(id: $id) {
      id
      name
      type
      parent {
        id
        name
        type
      }
      media {
        id
        name
        fileType
        path
        type
        isThumbnail
        isFeatured
        sortOrder
      }
      documents {
        id
        name
        fileType
        path
        type
        contentType
        sortOrder
      }
    }
  }
`;

const ADD_FILE_FINISH = gql`
  mutation addFinish($input: CreateFinishRequest!) {
    addFinish(input: $input) {
      code
      success
      message
      data {
        id
        name
        type
        parent {
          id
          name
          type
        }
        media {
          id
          name
          fileType
          path
          type
          isThumbnail
          isFeatured
          sortOrder
        }
        documents {
          id
          name
          fileType
          path
          type
          contentType
          sortOrder
        }
      }
    }
  }
`;

const UPDATE_FILE_FINISH = gql`
  mutation updateFinish($input: UpdateFinishRequest!) {
    updateFinish(input: $input) {
      code
      success
      message
      data {
        id
        name
        type
        parent {
          id
          name
          type
        }
        media {
          id
          name
          fileType
          path
          type
          isThumbnail
          isFeatured
          sortOrder
        }
        documents {
          id
          name
          fileType
          path
          type
          contentType
          sortOrder
        }
      }
    }
  }
`;

const DELETE_FILE_FINISH = gql`
  mutation deleteFinish($id: Int!) {
    deleteFinish(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

export const fetchFinishes = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_FINISHES, { filters });
};

export const fetchFinishById = async (id) => {
  return await graphQLClient.request(GET_FILE_FINISH_BY_ID, { id });
};

export const addFinish = async (input = {}) => {
  return await graphQLClient.request(ADD_FILE_FINISH, { input });
};

export const updateFinish = async (input = {}) => {
  return await graphQLClient.request(UPDATE_FILE_FINISH, { input });
};

export const deleteFinish = async (id) => {
  return await graphQLClient.request(DELETE_FILE_FINISH, { id });
};
