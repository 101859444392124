import { FullWidthContainer, Toggle } from "@buildappeal/react-component-library";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useCreateOrUpdateNotification from "../users/hooks/useCreateOrUpdateNotification";
import useNotifications from "../users/hooks/useNotifications";
import { getPartner } from "./partnersSlice";

const initialState = {
  id: 0,
  isProfile: true,
  emailTaskAssigned: false,
  emailWeeklyDigest: false,
  emailDailyDigest: false,
  notificationSms: false,
  notificationEmails: false,
};
const PartnerSettings = () => {
  const partner = useSelector(getPartner);
  const { data: notifications } = useNotifications(
    {
      isProfile: true,
      baUsersId: partner?.user?.id,
    },
    { disableQuery: !partner?.user?.id }
  );
  const { upsertNotification, upsertNotificationLoading } = useCreateOrUpdateNotification({
    isProfile: true,
    baUsersId: partner?.user?.id,
  });

  function handleUpsertNotification(data) {
    upsertNotification({
      isProfile: data.isProfile,
      emailTaskAssigned: data.emailTaskAssigned,
      emailWeeklyDigest: data.emailWeeklyDigest,
      emailDailyDigest: data.emailDailyDigest,
      notificationSms: data.notificationSms,
      notificationEmails: data.notificationEmails,
      id: data.id,
      userUid: partner?.user?.id,
    });
  }

  const [formState, setFormState] = useState(() => ({ ...initialState }));
  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
    handleUpsertNotification({ id: formState?.id, isProfile: formState?.isProfile, ...valueObject });
  };

  useEffect(() => {
    if (notifications.length) {
      setFormState(notifications[0]);
    }
  }, [notifications]);
  return (
    <FullWidthContainer className="py-10">
      <div className="space-y-14 font-light">
        <div className="grid grid-cols-[1fr_50px_50px] gap-5">
          <>
            <h2 className="text-xl font-light">Messaging</h2>
            <h6 className="text-center text-xs font-medium">Email</h6>
            <h6 className="text-center text-xs font-medium">Text</h6>
          </>
          <>
            <span>Receive a new message</span>
            <Toggle
              checked={formState?.notificationEmails}
              onChange={(newValue) => setFormValue({ notificationEmails: newValue })}
              disabled={upsertNotificationLoading}
            />
            <Toggle
              checked={formState?.notificationSms}
              onChange={(newValue) => setFormValue({ notificationSms: newValue })}
              disabled={upsertNotificationLoading}
            />
          </>
          <>
            <span>Email Task Assigned</span>
            <div />
            <Toggle
              checked={formState?.emailTaskAssigned}
              onChange={(newValue) => setFormValue({ emailTaskAssigned: newValue })}
              disabled={upsertNotificationLoading}
            />
          </>
          <>
            <span>Email Weekly Digest</span>
            <div />
            <Toggle
              checked={formState?.emailWeeklyDigest}
              onChange={(newValue) => setFormValue({ emailWeeklyDigest: newValue })}
              disabled={upsertNotificationLoading}
            />
          </>
          <>
            <span>Email Daily Digest</span>
            <div />
            <Toggle
              checked={formState?.emailDailyDigest}
              onChange={(newValue) => setFormValue({ emailDailyDigest: newValue })}
              disabled={upsertNotificationLoading}
            />
          </>
        </div>
      </div>
    </FullWidthContainer>
  );
};

export default PartnerSettings;
