import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    email: "",
    currentUser: null,
  },
  reducers: {
    setIsLoggedIn: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        isLoggedIn: action.payload,
      };
    },
    setUserEmail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        email: action.payload,
      };
    },
    setCurrentUser: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        currentUser: action.payload,
      };
    },
  },
});

export const { setIsLoggedIn, setUserEmail, setCurrentUser } = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectCurrentUser = (state) => state.auth.currentUser;

export default authSlice.reducer;
