import React, { useState, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Notice, ProgressBar, TextField, Select, SelectProject } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";
import useBATypes from "@src/utils/hooks/useBATypes";
import { createNote } from "../notes/api";
import useUser from "../auth/hooks/useUser";
import useProjects from "../projects/hooks/useProjects";

const initialFormState = {
  noteType: "",
  message: "",
  project: null,
  projectSpace: null,
};
const AddNotes = ({ onClose, project }) => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const [formState, setFormState] = useState({ ...initialFormState });
  const [loading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { baTypes: noteTypes } = useBATypes("noteTypes");
  const { options: projectsAddressAsOptions, isLoading: projectsAddressAsOptionsLoading } = useProjects();

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const isFormValid = () => {
    if (!formState?.noteType?.length) {
      showErrMsg("First name is Mandatory");
      return false;
    }

    if (!formState?.message?.length) {
      showErrMsg("Last name is Mandatory");
      return false;
    }

    if (!formState?.project && !project) {
      showErrMsg("project is Mandatory");
      return false;
    }

    showErrMsg("");
    return true;
  };

  const showErrMsg = (msg) => {
    setErrMsg(msg);
    if (msg) {
      showErrMsgNotification();
    }
  };

  const showErrMsgNotification = () => {
    setShowErrorMessage(true);
    setTimeout(() => {
      setShowErrorMessage(false);
    }, 2000);
  };

  const createNoteMutation = useMutation(
    (data) => {
      setIsLoading(true);
      const noteData = {
        noteType: data.noteType,
        message: data.message,
        projectId: data.project?.id,
        staffId: user?.id,
        projectSpaceId: data?.projectSpace?.id,
      };

      return createNote(noteData);
    },
    {
      onSuccess: (data) => {
        if (!data.createNote.success) {
          showErrMsgNotification();
          return;
        }
        // TODO: Handle success notification display

        const queryId = project ? ["projectNotes", project?.id] : "notes";

        queryClient.setQueryData(queryId, (old) => {
          if (!old) return { getNotes: [data.createNote.data] };
          return { getNotes: [data.createNote.data, ...old.getNotes] };
        });
        setShowSuccessMessage(false);
        setFormState({ ...initialFormState });
        onClose?.();
      },
      onError: () => {
        showErrMsgNotification();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const onSubmit = () => {
    if (!isFormValid()) {
      return;
    }

    const data = formState;
    createNoteMutation.mutate(data);
  };

  const projectSpaces = formState?.project?.spaces;

  const projectSpacesAsOptions = useMemo(() => {
    if (!projectSpaces?.length) {
      return [];
    }
    return projectSpaces?.map((space) => ({
      title: space?.spaceNickname,
      value: space?.id,
      space,
    }));
  }, [projectSpaces]);

  return (
    <div className="mt-16 flex w-full flex-col justify-center px-3">
      <div className="w-full">
        <p className="my-4 text-3xl font-medium">Create Note</p>
        {loading && <ProgressBar min={0} max={100} now={90} className="my-4" />}
        {!loading && showSuccessMessage && <Notice type="success" message="Note successfully created" />}
        {!loading && showErrorMessage && (
          <Notice type="critical" message={errMsg || "Note creation failed. Please try again!"} />
        )}

        <Select
          className="mt-4"
          label="Note Type"
          value={{
            title: formState.noteType,
            value: formState.noteType || "",
          }}
          options={noteTypes?.map((noteType) => ({
            title: noteType,
            value: noteType,
          }))}
          onChange={(opt) => {
            setFormValue({ noteType: opt?.value });
          }}
        />

        <TextField
          label="Message"
          value={formState.message}
          onChange={({ target: { value } }) => setFormValue({ message: value })}
          className="mt-4"
          required
        />
        {!project && (
          <SelectProject
            className="mt-4"
            label="Project"
            value={{ title: getAddressFromPlaces(formState.project?.places), value: formState.project?.id || "" }}
            options={projectsAddressAsOptions}
            isLoading={projectsAddressAsOptionsLoading}
            onChange={(opt) => {
              setFormValue({ project: opt?.project });
            }}
          />
        )}

        {(formState.project || project) && (
          <Select
            className="mt-4"
            label="Project Space"
            value={{
              title: formState.projectSpace?.spaceNickname,
              value: formState.projectSpace?.id,
            }}
            options={projectSpacesAsOptions}
            onChange={(opt) => {
              setFormValue({ projectSpace: opt?.space });
            }}
          />
        )}
        <Button className="mt-8" disabled={loading} label="Create Note" size="md" onClick={onSubmit} />
      </div>
    </div>
  );
};
export default AddNotes;
