import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PURCHASE_ORDERS = gql`
  query getPurchaseOrders($filters: PurchaseOrderFilterFields!) {
    getPurchaseOrders(filters: $filters) {
      id
      poDate
      dueDate
      title
      poCode
      type
      total
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_PROJECT_PURCHASE_ORDERS = gql`
  query getPurchaseOrders($filters: PurchaseOrderFilterFields!) {
    getPurchaseOrders(filters: $filters) {
      id
      poDate
      dueDate
      title
      poCode
      type
      total
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_PURCHASE_ORDER = gql`
  query getPurchaseOrderByID($id: Int!) {
    getPurchaseOrderByID(id: $id) {
      id
      poDate
      dueDate
      title
      poCode
      type
      total
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_PURCHASE_ORDER_ITEMS = gql`
  query getPurchaseOrderItems($filters: PurchaseOrderItemFilterFields!) {
    getPurchaseOrderItems(filters: $filters) {
      id
      description
      quantity
      subTotal
      total
      purchaseOrder {
        id
        project {
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
      }
    }
  }
`;

const CREATE_PURCHASE_ORDER = gql`
  mutation createPurchaseOrder($input: CreatePurchaseOrderRequest!) {
    createPurchaseOrder(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        poCode
        poPrefix
        poNumber
        type
        title
        poDate
        dueDate
        total
        status
        xeroInvoiceID
        project {
          id
          places {
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
      }
    }
  }
`;
const UPDATE_PURCHASE_ORDER = gql`
  mutation updatePurchaseOrder($input: UpdatePurchaseOrderRequest!) {
    updatePurchaseOrder(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        poCode
        poPrefix
        poNumber
        type
        title
        poDate
        dueDate
        total
        status
        xeroInvoiceID
        project {
          id
          places {
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
      }
    }
  }
`;
export const fetchPurchaseOrders = async (filters = {}) => {
  return await graphQLClient.request(GET_PURCHASE_ORDERS, { filters });
};

export const fetchPurchaseOrderItems = async (filters = {}) => {
  return await graphQLClient.request(GET_PURCHASE_ORDER_ITEMS, { filters });
};

export const createPurchaseOrder = async (input) => {
  return await graphQLClient.request(CREATE_PURCHASE_ORDER, { input });
};

export const updatePurchaseOrder = async (input) => {
  return await graphQLClient.request(UPDATE_PURCHASE_ORDER, { input });
};

export const fetchPurchaseOrder = async (id) => {
  return await graphQLClient.request(GET_PURCHASE_ORDER, { id });
};

export const fetchProjectPurchaseOrders = async (filters = {}) => {
  return graphQLClient.request(GET_PROJECT_PURCHASE_ORDERS, { filters });
};
