import { useMemo } from "react";
import { useQuery } from "react-query";
import { fetchProducts } from "../../api";

const useProducts = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const { data, isLoading, refetch } = useQuery(["products", filters], () => fetchProducts(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
    refetchInterval: Infinity,
  });

  const productsAsOptions = useMemo(() => {
    if (!data?.getProducts?.length) {
      return [];
    }
    return data?.getProducts.map((product) => {
      return {
        value: product.id,
        title: product.name,
        product,
        item: product,
      };
    });
  }, [data]);

  return {
    data: data?.getProducts,
    isLoading,
    options: productsAsOptions,
    refetch,
  };
};

export default useProducts;
