import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_SUPPLIERS = gql`
  query getSuppliers($filters: SupplierFilterFields) {
    getSuppliers(filters: $filters) {
      id
      name
      email
      addressLine1
      city
      zipCode
      latitude
      longitude
      state
      labelColor
      website
      logo
      phone
      isActive
      isFullService
      categories {
        id
        description
      }
      markets {
        id
        name
      }
      contacts {
        id
        fullName
        firstName
        lastName
        phone
        email
      }
    }
  }
`;

const GET_SUPPLIER = gql`
  query getSupplierByID($id: Int!) {
    getSupplierByID(id: $id) {
      id
      name
      email
      addressLine1
      city
      zipCode
      state
      latitude
      longitude
      labelColor
      website
      logo
      phone
      isActive
      isFullService
      categories {
        id
        description
      }
      markets {
        id
        name
      }
      contacts {
        id
        fullName
        firstName
        lastName
        phone
        email
      }
      documents {
        id
        name
        path
        fileType
        type
      }
    }
  }
`;

const CREATE_SUPPLIER = gql`
  mutation createSupplier($input: CreateSupplierRequest!) {
    createSupplier(input: $input) {
      code
      success
      message
      data {
        id
        name
        email
        addressLine1
        city
        zipCode
        state
        latitude
        longitude
        labelColor
        website
        logo
        phone
        isActive
        isFullService
        categories {
          id
          description
        }
        contacts {
          id
          fullName
          firstName
          lastName
          phone
          email
        }
      }
    }
  }
`;

const UPDATE_SUPPLIER = gql`
  mutation updateSupplier($input: UpdateSupplierRequest!) {
    updateSupplier(input: $input) {
      code
      success
      message
      data {
        id
        name
        email
        addressLine1
        city
        zipCode
        state
        latitude
        longitude
        labelColor
        website
        logo
        phone
        isActive
        isFullService
        categories {
          id
          description
        }
        markets {
          id
          name
        }
        contacts {
          id
          fullName
          firstName
          lastName
          phone
          email
        }
      }
    }
  }
`;

const GET_SUPPLIER_QUOTES = gql`
  query getSupplierQuotes($filters: SupplierQuoteFilterFields!) {
    getSupplierQuotes(filters: $filters) {
      id
      total
      project {
        id
        places {
          id
          addressLine1
          city
          zipCode
          state
        }
        projectStages {
          id
          name
          stageType
        }
      }
      document {
        id
        uploadedAt
        name
        path
        fileType
      }
      quoteNumber
      createdAt
      supplierId
    }
  }
`;

const CREATE_SUPPLIER_QUOTE = gql`
  mutation createSupplierQuote($input: CreateSupplierQuoteRequest!) {
    createSupplierQuote(input: $input) {
      code
      success
      message
      data {
        id
        project {
          id
          places {
            id
            addressLine1
            city
            zipCode
            state
          }
          projectStages {
            id
            name
            stageType
          }
        }
        document {
          id
          uploadedAt
          name
          path
          fileType
        }
        total
        quoteNumber
        createdAt
        supplierId
      }
    }
  }
`;
export const fetchSuppliers = async (filters) => {
  return await graphQLClient.request(GET_SUPPLIERS, { filters });
};

export const fetchSupplierQuotes = async (filters) => {
  return await graphQLClient.request(GET_SUPPLIER_QUOTES, { filters });
};

export const fetchSupplierById = async (id) => {
  return await graphQLClient.request(GET_SUPPLIER, { id });
};

export const createSupplier = async (input = {}) => {
  return await graphQLClient.request(CREATE_SUPPLIER, { input });
};

export const updateSupplier = async (input = {}) => {
  return await graphQLClient.request(UPDATE_SUPPLIER, { input });
};

export const createSupplierQuote = async (input = {}) => {
  return await graphQLClient.request(CREATE_SUPPLIER_QUOTE, { input });
};
