import { useMemo } from "react";
import { useQuery } from "react-query";

import { getProductVariants } from "../../api";

const useProductVariants = (productIds) => {
  const { data, isLoading } = useQuery(["productVariants", productIds], () => getProductVariants(productIds), {
    enabled: !!productIds?.length,
  });

  const productVariantsAsOptions = useMemo(() => {
    if (!data?.getAllProductsVariants?.length) {
      return [];
    }
    return data?.getAllProductsVariants.map((variant) => {
      console.log("🚀 @@ ~ variant", variant);
      return {
        value: variant.id,
        title: `${variant.name} (${variant.products.name})`,
        item: variant,
      };
    });
  }, [data]);

  return {
    data: data?.getAllProductsVariants,
    options: productVariantsAsOptions,
    isLoading,
  };
};

export default useProductVariants;
