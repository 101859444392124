import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { createProductVariants } from "../../api";
import { updateBatchProductsWithNewVariants } from "../../productsSlice";

const useAddProductVariants = () => {
  const dispatch = useDispatch();

  const { isLoading, mutateAsync, mutate } = useMutation(
    (input) => {
      return createProductVariants(input);
    },
    {
      onSuccess: (data) => {
        if (data?.createProductVariants?.success) {
          dispatch(
            updateBatchProductsWithNewVariants({
              newProduct: data.createProductVariants.data?.[0],
              deletedIds: data.createProductVariants?.deletedIds,
            })
          );
        }
      },
      onError: (e) => {
        console.log("@@ Error ", e);
      },
    }
  );

  return {
    isLoading,
    createProductVariantsAsync: mutateAsync,
    createProductVariants: mutate,
  };
};

export default useAddProductVariants;
