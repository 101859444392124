import { TableCells, TableFilters, AvatarMultiple, TableCards } from "@buildappeal/react-component-library";
import { groupBy, orderBy, omit } from "lodash";
import { getAddressFromPlaces, isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";

import generateInitials from "../../utils/generateInitials";

export const STATUS_COLOR_CODES = {
  "Lead - Unscheduled Consultation": "orange",
  "Lead - Scheduled Consultation": "orange",
  "Planning - Scope of Work": "red",
  "Planning - Job Visit": "red",
  "Design - Story Boards": "orange",
  "Planning - Scheduling": "orange",
  "Procurement - Ordering & Delivery": "gray",
  "Procurement - Product Selection": "gray",
  "Build - Demolition": "green",
  "Build - Final Walk-Through": "green",
  "Build - Installation": "green",

  Completed: "green",
  General: "gray",
};

export const columns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    className: "w-[0.1%]",
  },
  {
    id: "address",
    Header: "Address",
    accessor: (row) => row.places.addressLine1,
    className: "whitespace-nowrap",
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original.places?.city}
          addressState={originalRow.row.original.places?.state}
          addressZip={originalRow.row.original.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  {
    id: "client",
    Header: "Client",
    accessor: (row) => row?.clients?.[0]?.fullName,
    Cell: (originalRow) => {
      return (
        <AvatarMultiple
          images={
            originalRow?.row?.original?.clients?.map((client) => ({
              src: client?.avatar,
              title: client?.fullName,
              initials: generateInitials(client?.fullName),
              alt: "Client Image",
            })) || []
          }
          size="sm"
          hasTooltip
        />
      );
    },
  },
  {
    id: "status",
    Header: "Status",
    accessor: (row) => row?.projectStages?.name,
    Cell: (originalRow) => {
      if (!originalRow.getValue()) {
        return null;
      }
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] ?? "gray"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    className: "whitespace-nowrap",
  },
  {
    id: "startDate",
    Header: "Start Date",
    accessor: (originalRow) => {
      if (!originalRow.projectedStart) return "";
      const dt = new Date(originalRow.projectedStart);
      return dt.toISOString();
    },
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  {
    id: "completionDate",
    Header: "Completion Date",
    accessor: (originalRow) => {
      if (!originalRow.projectedCompletion) return "";
      const dt = new Date(originalRow.projectedCompletion);
      return dt.toISOString();
    },
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  {
    id: "spaces",
    Header: "Spaces",
    accessor: (originalRow) => {
      const count = originalRow["spaces"]?.length;
      return count || "";
    },
    Cell: TableCells.TextCell,
    disableSortBy: true,
  },
  {
    id: "staff",
    Header: "Staff Owner",
    accessor: "staff[0].fullName",
    Cell: (originalRow) => {
      return (
        <AvatarMultiple
          images={
            originalRow?.row?.original?.staff?.map((staff) => ({
              src: staff?.avatar,
              title: staff?.fullName,
              alt: "Staff Image",
              initials: generateInitials(staff?.fullName),
            })) || []
          }
          size="sm"
          hasTooltip
        />
      );
    },
    disableSortBy: true,
  },
];

export const columnsContracts = [
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "type",
    Header: "Type",
    accessor: "contractType",
  },
  {
    id: "signee",
    Header: "Signee",
    accessor: "clients[0].fullName",
    Cell: TableCells.UserCell,
  },
  {
    id: "staff",
    Header: "Staff",
    accessor: "staff[0].fullName",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={originalRow.row.original.staff?.[0]?.fullName}
          avatarSrc={originalRow.row.original.staff?.[0]?.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
        />
      );
    },
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: TableCells.BadgeCell,
    colorAccessor: "gray",
    className: "whitespace-nowrap",
  },
  {
    id: "lastUpdated",
    Header: "Last Updated",
    accessor: (originalRow) => {
      const dt = new Date(originalRow.lastUpdated);
      return dt.toISOString();
    },
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
];

export const columnsSpaces = [
  {
    id: "id",
    Header: "Id",
    accessor: "id",
  },
  {
    id: "nickname",
    Header: "Nickname",
    accessor: (originalRow) => {
      return originalRow["spaces"]?.spaceNickname;
    },
  },
  {
    id: "width",
    Header: "Width",
    accessor: (originalRow) => {
      return originalRow["spaces"]?.measurementsWidth;
    },
  },
  {
    id: "length",
    Header: "Length",
    accessor: (originalRow) => {
      return originalRow["spaces"]?.measurementsLength;
    },
  },
  {
    id: "sqft",
    Header: "Sq Ft",
    accessor: (originalRow) => {
      return originalRow["spaces"]?.sqft;
    },
  },
  {
    id: "roomNumber",
    Header: "Room Number",
    accessor: (originalRow) => {
      return originalRow["spaces"]?.roomNumber;
    },
  },
  {
    id: "floor",
    Header: "Floor",
    accessor: (originalRow) => {
      return originalRow["spaces"]?.floor;
    },
  },
];

export const columnFiles = [
  {
    id: "select",
    accessor: "selected_row",
    Cell: TableCells.CheckboxCell,
    disableSortBy: true,
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: (originalRow) => {
      const [title, subtitle] = originalRow.getValue().split(".");
      const { path, fileType } = originalRow.row.original;
      return (
        <div className="flex">
          <TableCells.ImageCell
            getValue={() => (fileType.includes("image") ? path : "")}
            alt="fileName"
            row={originalRow.row}
            column={originalRow.column}
          />
          <TableCells.TitleCell
            getValue={() => title}
            subtitle={"." + subtitle}
            row={originalRow.row}
            column={originalRow.column}
          />
        </div>
      );
    },
    className: "w-3/4",
  },
  {
    id: "created",
    Header: "Created",
    accessor: "uploadedAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
];

export const getProjectSpacesAsOptions = (spaces, { excludeSpaceIds = [] } = {}) => {
  if (!spaces) {
    return [];
  }
  return spaces
    .filter((space) => !excludeSpaceIds.includes(space.id))
    .map((projectSpace) => ({
      id: projectSpace.id,
      title: projectSpace.spaceNickname,
      label: projectSpace.spaceNickname,
      value: projectSpace.id,
      projectSpace,
    }));
};

export const mockSpaces = [
  {
    id: 1,
    name: "Living Room",
    budget: 40201,
    profit: 4309,
    profitPercentage: 12,
  },
  {
    id: 2,
    name: "Kitchen",
    budget: 56302,
    profit: 3309,
    profitPercentage: 12,
  },
  {
    id: 3,
    name: "Bathroom",
    budget: 17829,
    profit: 2309,
    profitPercentage: 12,
  },
  {
    id: 4,
    name: "Outdoor Space",
    budget: 12098,
    profit: 1309,
    profitPercentage: 12,
  },
];

export const findNestedChildren = (parentItem, allProjectSpaceObject, finalChildrenObj = []) => {
  let finalChildList = { ...finalChildrenObj };
  const finalObject = { ...parentItem, children: [] };
  finalObject.children = allProjectSpaceObject[parentItem.id]?.length
    ? allProjectSpaceObject[parentItem.id].map((child) => {
        return findNestedChildren(child, allProjectSpaceObject, finalChildList);
      })
    : [];
  return finalObject;
};

export const PresentationStatus = ["Draft", "Submitted", "Approved", "Rejected"];

export const getAllNestedChildObjectsFlatMap = (object) => {
  const children = [];
  if (object.children) {
    children.push(...object.children);
    object.children.forEach((childObj) => {
      children.push(...getAllNestedChildObjectsFlatMap(childObj));
    });
  }
  return children;
};

export const addProjectToSpace = (space, project) => ({
  ...space,
  project: {
    id: project?.id,
    name: project?.name,
    spaces: project?.spaces,
  },
});

export const getProjectSpaceObjectOptions = (object, parentName = "") => {
  const currObj = omit(object, "children");
  const currObjName = (parentName ? parentName + " - " : "") + currObj.nickname;
  const options = [
    {
      title: currObjName,
      value: currObj.id,
      projectSpaceObject: currObj,
    },
  ];

  if (object.children.length) {
    object.children.forEach((childObj) => {
      options.push(...getProjectSpaceObjectOptions(childObj, currObjName));
    });
  }
  return options;
};

export const AccessDifficultyOptions = ["Easy", "Medium", "Difficult", "NA"];

export const loadingPlaceholderProject = {
  id: "",
  color: "",
  projectedStart: "",
  projectedCompletion: "",
  accessDifficulty: "",
  lockboxCode: "",
  dateCreated: "",
  clients: {
    id: "282",
    fullName: "",
    email: "",
    avatar: "",
  },
  staff: [
    {
      id: 3,
      fullName: "",
      email: "",
      avatar: "",
      isOwner: "",
    },
    {
      id: 6,
      fullName: "",
      email: "",
      avatar: "",
      isOwner: "",
    },
  ],
  spaces: [],
  projectStages: {
    name: "",
    stageType: "",
    order: "",
  },
  places: {
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
    latitude: "",
    longitude: "",
  },
  thumbnail: "",
  leadSource: "",
  type: "",
};
export const LeadSource = ["Quiz", "Houzz"].sort((a, b) => a.localeCompare(b));

export const transformProjectBoardViewData = (data) => {
  const groupedProjects = groupBy(data, "projectStatus");
  return orderBy(
    Object.keys(groupedProjects).map((key) => ({
      title: key,
      className: "dark:bg-neutral-700",
      items: orderBy(
        groupedProjects[key].map((project) => ({
          ...project,
          title: project.client?.fullName,
          subtitle: getAddressFromPlaces(project?.places),
          image: project.thumbnail,
        })),
        "id",
        "desc"
      ),
    })),
    "items.[0].projectStatus",
    "asc"
  );
};

export const getSpaceIcon = (spaceName) => {
  const sanitizedSpaceName = spaceName ? spaceName.replace("/", " ") : "";
  return `https://res.cloudinary.com/build-appeal/image/upload/v1656611706/ba-media/spaceIcons/${(
    sanitizedSpaceName || ""
  )
    .split(" ")
    .join("_")}.svg`;
};

export const QUESTION_FORMAT = {
  Text: "text",
  Options: "options",
};

export const transformQuestionData = (data, projectId, spaceId, projectSpaceId) => {
  const result = {};
  const defaultCategory = "General";

  if (data.quizAnswers?.length > 0) {
    const quizAnswer = projectSpaceId
      ? data.quizAnswers?.find((answer) => answer.projectSpaceId === projectSpaceId && answer.projectId === projectId)
      : data.quizAnswers?.find((answer) => answer.projectId === projectId && !answer.projectSpaceId);

    result.id = quizAnswer?.id;
    result.textAnswer = quizAnswer?.textAnswer;
    result.optionsAnswer = quizAnswer?.optionsAnswer;
    result.title = data.title;
    result.options = data.answerOptions;
    result.projectId = projectId;
    result.spaceId = spaceId;
    result.projectSpaceId = projectSpaceId;
    result.sanityQuestionId = data.id;
    result.sanityId = data.sanityQuestionId;
    result.questionFormat = data.allowMultiAnswers ? QUESTION_FORMAT.Options : QUESTION_FORMAT.Text;
    result.user = quizAnswer?.user;
    result.timestamp = quizAnswer?.timestamp || quizAnswer?.createdAt;
    result.category = data.category || defaultCategory;
  } else if (data.sanityQuizQuestion) {
    result.id = data.id;
    result.textAnswer = data.textAnswer;
    result.optionsAnswer = data.optionsAnswer;
    result.title = data.sanityQuizQuestion.questionTitle.questionTitle;
    result.options = data.sanityQuizQuestion.questionAnswers;
    result.projectId = data.projectId;
    result.spaceId = data.spaceId;
    result.projectSpaceId = data.projectSpaceId;
    result.questionFormat = data.sanityQuizQuestion.allowMultiAnswers ? QUESTION_FORMAT.Options : QUESTION_FORMAT.Text;
    result.user = data.user;
    result.timestamp = data?.timestamp || data?.createdAt;
    result.category = data.sanityQuizQuestion.category?.value || defaultCategory;
  } else if (data.sanityQuestion) {
    result.id = data.id;
    result.textAnswer = data.textAnswer;
    result.title = data.sanityQuestion.title;
    result.options = data.sanityQuestion.answerOptions;
    result.projectId = data.projectId;
    result.spaceId = data.spaceId;
    result.projectSpaceId = data.projectSpaceId;
    result.user = data.user;
    result.timestamp = data?.timestamp || data?.createdAt;
    result.questionFormat = data.sanityQuestion.allowMultiAnswers ? QUESTION_FORMAT.Options : QUESTION_FORMAT.Text;
    result.category = data.sanityQuestion.category || defaultCategory;
  } else {
    result.title = data.title;
    result.options = data.answerOptions;
    result.sanityQuestionId = data.id;
    result.projectId = projectId;
    result.spaceId = spaceId;
    result.projectSpaceId = projectSpaceId;
    result.category = data.category || defaultCategory;
    result.questionFormat = data.allowMultiAnswers ? QUESTION_FORMAT.Options : QUESTION_FORMAT.Text;
  }

  if (data.displayAs === "yes-no" || data.sanityQuestion?.displayAs === "yes-no") {
    result.options = [
      {
        _key: "yes",
        primaryText: "Yes",
      },
      {
        _key: "no",
        primaryText: "No",
      },
    ];
  }

  return result;
};
export const COST_ICON = {
  "I mainly care about accomplishing the look.": 1,
  "I want the look and high-quality products.": 2,
  "I want the best results and best in-class products. ": 3,
};

export const PROJECT_DRAWINGS_CONTENT_TYPE = {
  moodboards: "Moodboards",
  elevations: "Elevations",
  floorplan: "Floorplan",
  ceiling_plan_rcp: "Ceiling Plan (RCP)",
  three_d_renders: "3D Rendering",
  presentation: "Presentation",
};

export const transformProjectCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    type: "Type",
    projectStatus: "Stage",
    "currentStatus.name": "Status",
    source: "Source",
    "staff.fullName": "Owner",
  };

  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => ({
      ...refinement,
      label: isAlgoliaAttributeValueEmpty(refinement.label)
        ? `No ${refinementAttributeToLabelMap[item.attribute]}`
        : titleCase(refinement.label),
    })),
  }));
};

export const PROJECT_SPACE_PROPERTY_NAME = {
  spaceNickname: "spaceNickname",
};

export const PROJECT_TABLES = {
  projects: "projects",
  projectsSpaces: "projects_spaces",
};
