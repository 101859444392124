import React, { useEffect, useMemo, useState } from "react";
import { Button, Select } from "@buildappeal/react-component-library";

import { projectDocumentOptions } from "@features/files/utils";
import useBATypes from "@src/utils/hooks/useBATypes";
import { getAddressFromPlaces } from "@src/utils/common";
import isMediaFile from "@src/utils/isMedia";
import useUpdateFiles from "@features/files/hooks/useUpdateFiles";
import { FILE_TYPES } from "../files/utils";

const UploadProjectFile = ({ project = {}, onSuccess, dragUploadedResults }) => {
  const [formState, setFormState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);

  const { baTypes: contentTypes } = useBATypes("contentTypes");
  const { updateFilesAsync } = useUpdateFiles({ projectId: project.id });

  const visibleProjectDocumentOptions = useMemo(
    () => projectDocumentOptions.filter((option) => project[option.value]?.length > 0),
    [project]
  );

  const uploadedFilesByType = useMemo(() => {
    if (!uploadResults || uploadResults.uploadCount === 0) {
      return {
        media: [],
        documents: [],
      };
    }

    return {
      media: uploadResults.uploadedFiles.filter((file) => isMediaFile(file.fileType)),
      documents: uploadResults.uploadedFiles.filter((file) => !isMediaFile(file.fileType)),
    };
  }, [uploadResults]);

  const onlyMediaUploaded = uploadedFilesByType.media.length && !uploadedFilesByType.documents.length;
  const onlyDocumentsUploaded = !uploadedFilesByType.media.length && uploadedFilesByType.documents.length;

  useEffect(() => {
    if (dragUploadedResults) {
      setUploadResults(dragUploadedResults);
    }
  }, [dragUploadedResults]);

  const [errors, setErrors] = useState({
    document: "",
    spaces: "",
    files: "",
  });

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));

    if (valueObject.documentType) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        document: "",
      }));
    }
    if (valueObject.spaces) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        spaces: "",
      }));
    }
    if (valueObject.files) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        files: "",
      }));
    }
  };

  const handleClose = () => {
    setFormState({});
    setErrors({});
    setIsLoading(false);
    onSuccess();
  };

  const onUpdateFileInfo = async () => {
    let finalInput = [
      ...uploadedFilesByType.media.map((file) => ({
        id: file.id,
        type: FILE_TYPES.media,
      })),
      ...uploadedFilesByType.documents.map((file) => ({
        id: file.id,
        type: FILE_TYPES.document,
      })),
    ];

    const type = formState.documentType?.value;
    if (type && !formState[type]) {
      setErrors((prev) => ({
        ...prev,
        document: "Please select a document",
      }));
      return;
    }
    finalInput = finalInput.map((file) => {
      const finalFileInput = {
        ...file,
        contentType: formState.contentType || null,
        spacesId: formState.spaces?.spaceId ? [formState.spaces.spaceId] : null,
        projectSpacesId: formState.spaces?.id ? [formState.spaces.id] : null,
      };

      if (type) {
        const documentId = formState[type].id;
        if (type === "invoices") {
          finalFileInput.invoiceId = documentId;
        } else if (type === "bills") {
          finalFileInput.billId = documentId;
        } else {
          finalFileInput.contractId = documentId;
        }
      }

      return finalFileInput;
    });

    try {
      setIsLoading(true);
      setErrors((prev) => ({
        ...prev,
        files: "",
      }));
      const result = await updateFilesAsync(finalInput);
      setIsLoading(false);
      const isSuccess = result.updateFilesInfo.success;
      if (isSuccess) {
        onSuccess(uploadResults?.uploadedFiles);
        handleClose();
      } else {
        setErrors((prev) => ({
          ...prev,
          files: "Failed to save details",
        }));
      }
    } catch (e) {
      setIsLoading(false);
      setErrors((prev) => ({
        ...prev,
        files: "Failed to save details: " + e.message,
      }));
    }
  };

  useEffect(() => {
    if (!uploadResults || uploadResults.uploadCount === 0) {
      return;
    }

    // close modal if both media and documents are uploaded
    if (!onlyMediaUploaded && !onlyDocumentsUploaded) {
      handleClose();
    }
  }, [uploadResults, onlyMediaUploaded, onlyDocumentsUploaded]);

  return (
    <>
      {errors.files && <p className="my-2 px-6 text-sm font-semibold text-red-700">{errors.files}</p>}
      {uploadResults && (
        <>
          <div className="flex min-h-[500px] w-full flex-1 flex-col overflow-y-auto px-6 pt-8 pb-6">
            <span className="text-lg text-neutral-800">
              Add {uploadResults?.uploadCount} {uploadResults?.uploadCount > 1 ? "Files to: " : "File to: "}
              {getAddressFromPlaces(project.places)}
            </span>
            {onlyMediaUploaded ? (
              <div className="mt-4 flex flex-col space-y-2">
                {project?.spaces ? (
                  <div>
                    <Select
                      label="Select space (optional)"
                      value={{
                        title: formState.spaces?.title || "",
                        value: formState.spaces?.value || "",
                      }}
                      options={project?.spaces.map((space) => {
                        return {
                          value: space.id,
                          title: space.spaceNickname,
                          ...space,
                          space,
                        };
                      })}
                      onChange={(opt) => {
                        setFormValue({ spaces: opt });
                      }}
                    />
                    {errors.spaces && <p className="my-2 text-sm font-semibold text-red-700">{errors.spaces}</p>}
                  </div>
                ) : null}
              </div>
            ) : null}
            {onlyDocumentsUploaded ? (
              <div className="mt-4 flex flex-col space-y-2">
                <div>
                  {visibleProjectDocumentOptions?.length ? (
                    <Select
                      label="Document Type"
                      value={{
                        title: formState.documentType?.title || "",
                        value: formState.documentType?.value || "",
                      }}
                      options={visibleProjectDocumentOptions}
                      onChange={(opt) => {
                        setFormValue({ documentType: opt });
                      }}
                    />
                  ) : null}
                  {errors.document && <p className="my-2 text-sm font-semibold text-red-700">{errors.document}</p>}
                </div>
                {formState.documentType ? (
                  <ProjectDocumentSelectInput
                    project={project}
                    option={formState[formState.documentType.value]}
                    documentType={formState.documentType.value}
                    setOption={setFormValue}
                  />
                ) : null}
              </div>
            ) : null}
            <div className="mt-4 flex flex-col space-y-2">
              <div>
                <Select
                  label="Content type"
                  value={{
                    title: formState.contentType || "",
                    value: formState.contentType || "",
                  }}
                  options={contentTypes?.map((content) => ({
                    title: content,
                    value: content,
                  }))}
                  onChange={(opt) => {
                    setFormValue({ contentType: opt?.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full border border-t border-neutral-200 px-6 py-6">
            <Button
              label={isLoading ? "Loading ..." : "Save"}
              onClick={onUpdateFileInfo}
              disabled={isLoading}
              className="w-full rounded-[4px]"
            />
          </div>
        </>
      )}
    </>
  );
};

const ProjectDocumentSelectInput = ({ documentType, project, option, setOption }) => {
  const invoicesAsOptions = useMemo(() => {
    if (!project) return [];
    return project.invoices.map((invoice) => {
      return {
        value: invoice.id,
        title: invoice.title,
        invoice,
      };
    });
  }, [project]);

  const contractsAsOptions = useMemo(() => {
    if (!project) return [];
    return project.contracts.map((contract) => {
      return {
        value: contract.id,
        title: contract.name,
        contract,
      };
    });
  }, [project]);

  const billsAsOptions = useMemo(() => {
    if (!project) return [];
    return project.bills.map((bill) => {
      return {
        value: bill.id,
        title: bill.billCode,
        bill,
      };
    });
  }, [project]);

  const DOCUMENT_TYPE_OPTIONS = {
    invoices: {
      title: "Invoice",
      options: invoicesAsOptions,
      key: "invoice",
      optionTitleKey: "title",
    },
    contracts: {
      title: "Contract",
      options: contractsAsOptions,
      key: "contract",
      optionTitleKey: "name",
    },
    bills: {
      title: "Bill",
      options: billsAsOptions,
      key: "bill",
      optionTitleKey: "billCode",
    },
  };

  if (!documentType) {
    return null;
  }

  const { title, options, key, optionTitleKey } = DOCUMENT_TYPE_OPTIONS[documentType];

  return (
    <Select
      label={title}
      value={{
        title: option?.[optionTitleKey] || "",
        value: option?.[optionTitleKey] || "",
      }}
      options={options}
      onChange={(opt) => {
        setOption({ [documentType]: opt?.[key] });
      }}
    />
  );
};
export default UploadProjectFile;
