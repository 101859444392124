import clsx from "clsx";
import React from "react";
import Skeleton from "react-loading-skeleton";

const ActivityLoading = ({ className }) => {
  return (
    <div>
      <div className={clsx("mb-4 flex items-center gap-x-3", className)}>
        <Skeleton circle={true} className="h-8 !w-8" />
        <div>
          <Skeleton className="h-3 !w-[200px]" />
          <Skeleton className="h-2 !w-5" />
        </div>
      </div>
    </div>
  );
};

export default ActivityLoading;
