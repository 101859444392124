import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchSuppliersQuotes } from "../../api";
import { setSuppliersQuotes, getSuppliersQuotes } from "../../suppliersQuotesSlice";

const useSuppliersQuotes = (filters = {}) => {
  const dispatch = useDispatch();
  const suppliersQuotes = useSelector(getSuppliersQuotes);

  const { data, isLoading } = useQuery(["suppliersQuotes", filters], () => fetchSuppliersQuotes({ ...filters }), {
    refetchOnWindowFocus: false,
  });

  const suppliersQuotesAsOptions = useMemo(() => {
    if (!suppliersQuotes?.length) {
      return [];
    }

    return suppliersQuotes.map((suppliersQuote) => {
      return {
        title: `${suppliersQuote?.quoteNumber || ""} - ${suppliersQuote?.supplier?.name || ""}`,
        value: suppliersQuote.id,
        suppliersQuote,
      };
    });
  }, [suppliersQuotes]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setSuppliersQuotes(data.getSuppliersQuotes));
  }, [data, isLoading, dispatch]);

  return {
    suppliersQuotes,
    isLoading,
    suppliersQuotesLoading: isLoading,
    suppliersQuotesAsOptions,
  };
};

export default useSuppliersQuotes;
