import { useDispatch, useSelector } from "react-redux";
import { getCurrentTheme, setCurrentTheme } from "@src/features/layout/layoutSlice";
import { toggleHtmlClass } from "@src/features/layout/utils";

const useCurrentTheme = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(getCurrentTheme);

  const setTheme = (theme) => {
    if (theme === "dark") {
      dispatch(setCurrentTheme("dark"));
      toggleHtmlClass("dark", true);
      localStorage?.setItem("theme", "dark");
      return;
    }
    toggleHtmlClass("dark", false);
    dispatch(setCurrentTheme("light"));
    localStorage?.setItem("theme", "light");
  };
  return {
    theme: currentTheme,
    setCurrentTheme: setTheme,
  };
};

export default useCurrentTheme;
