import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  changeOrders: [],
  changeOrderItems: [],
};

export const changeOrdersSlice = createSlice({
  name: "changeOrders",
  initialState,
  reducers: {
    setChangeOrders: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        changeOrders: action.payload,
      };
    },
    setChangeOrderItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        changeOrderItems: action.payload,
      };
    },
  },
});

export const { setChangeOrders, setChangeOrderItems } = changeOrdersSlice.actions;

export default changeOrdersSlice.reducer;

export const getChangeOrders = (state) => state.changeOrders.changeOrders;
export const getChangeOrderItems = (state) => state.changeOrders.changeOrderItems;
