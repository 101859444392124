import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_ESTIMATE_SELECTIONS = gql`
  query getEstimateSelections($filters: EstimateSelectionFilterFields!) {
    getEstimateSelections(filters: $filters) {
      id
      projectId
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        avatar
        fullName
      }
      status
      objects {
        id
        object {
          id
          description
        }
        attributes {
          id
          attribute {
            id
            name
            icon
          }
        }
        materials {
          id
          material {
            id
            name
            media {
              id
              name
              fileType
              path
              type
              isThumbnail
              sortOrder
            }
          }
        }
        colors {
          id
          color {
            id
            name
            icon
            colorValue
          }
        }
        products {
          id
          quantity
          productVariant {
            id
            name
            cost
            vendorURL
            sku
            media {
              id
              path
              fileType
              sortOrder
              isThumbnail
            }
          }
          product {
            id
            name
            cost
            objects {
              id
              name
            }
            media {
              id
              path
              fileType
              sortOrder
              isThumbnail
            }
          }
        }
      }
    }
  }
`;

const GET_PROJECT_ESTIMATE_SELECTIONS = gql`
  query getEstimateSelectionForProject($projectId: Int!) {
    getEstimateSelectionForProject(projectId: $projectId) {
      id
      projectId
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        avatar
        fullName
      }
      status
      objects {
        id
        projectSpaceId
        object {
          id
          description
        }
        attributes {
          id
          attribute {
            id
            name
            icon
            type
          }
        }
        materials {
          id
          material {
            id
            name
            media {
              id
              name
              fileType
              path
              type
              isThumbnail
              sortOrder
            }
          }
        }
        finishes {
          id
          finish {
            id
            name
          }
        }
        colors {
          id
          color {
            id
            name
            icon
            colorValue
          }
        }
        products {
          id
          quantity
          productVariantId
          productVariant {
            id
            name
            cost
            vendorURL
            sku
            media {
              id
              path
              fileType
              sortOrder
              isThumbnail
            }
          }
          product {
            id
            name
            cost
            objects {
              id
              description
            }
            media {
              id
              path
              fileType
              sortOrder
              isThumbnail
            }
            variants {
              id
              name
            }
            suppliers {
              name
            }
          }
        }
      }
    }
  }
`;

const UPSERT_ESTIMATE_SELECTION_OBJECT = gql`
  mutation upsertEstimateSelectionObject($input: EstimateSelectionObjectRequest!) {
    upsertEstimateSelectionObject(input: $input) {
      code
      message
      success
      data {
        id
        projectId
        projectSpaceId
        object {
          id
          description
        }
        attributes {
          id
          attribute {
            id
            name
            icon
            type
          }
        }
        materials {
          id
          material {
            id
            name
            media {
              id
              name
              fileType
              path
              type
              isThumbnail
              sortOrder
            }
          }
        }
        finishes {
          id
          finish {
            id
            name
          }
        }
        colors {
          id
          color {
            id
            name
            icon
            colorValue
          }
        }
        products {
          id
          quantity
          productVariantId
          productVariant {
            id
            name
            cost
            vendorURL
            sku
            media {
              id
              path
              fileType
              sortOrder
              isThumbnail
            }
          }
          product {
            id
            name
            cost
            objects {
              id
              description
            }
            media {
              id
              path
              fileType
              sortOrder
              isThumbnail
            }
          }
        }
      }
    }
  }
`;

const UPDATE_ESO_PRODUCT_QTY = gql`
  mutation updateESOProductQuantity($input: ESOProductRequest!) {
    updateESOProductQuantity(input: $input) {
      code
      message
      success
    }
  }
`;

const DELETE_ESTIMATE_SELECTION_OBJECT = gql`
  mutation deleteEstimateSelectionObject($id: BigInt) {
    deleteEstimateSelectionObject(id: $id) {
      code
      message
      success
    }
  }
`;

export const fetchEstimateSelections = async (filters = {}) => {
  return await graphQLClient.request(GET_ESTIMATE_SELECTIONS, { filters });
};

export const upsertEstimateSelectionObject = async (input = {}) => {
  return await graphQLClient.request(UPSERT_ESTIMATE_SELECTION_OBJECT, { input });
};

export const getProjectEstimateSelections = async (projectId) => {
  return await graphQLClient.request(GET_PROJECT_ESTIMATE_SELECTIONS, { projectId });
};

export const updateESOProductQuantity = async (input = {}) => {
  return await graphQLClient.request(UPDATE_ESO_PRODUCT_QTY, { input });
};

// Accepts idObj: { id: BigInt }
export const deleteEstimateSelectionObject = async (idObj) => {
  return await graphQLClient.request(DELETE_ESTIMATE_SELECTION_OBJECT, idObj);
};
