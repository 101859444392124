import { useCallback, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import { ToastContext } from "../providers/toast";

const useToast = () => {
  const context = useContext(ToastContext);

  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  const { dispatch } = context;

  const addToast = useCallback(
    (
      description = "",
      {
        actionLabel = "",
        onActionClick = null,
        secondaryActionlabel,
        onSecondaryActionClick = null,
        duration = 3000,
        showClose = true,
      } = {}
    ) => {
      dispatch({
        type: "toast/addToast",
        payload: {
          description,
          actionLabel,
          onActionClick,
          secondaryActionlabel,
          onSecondaryActionClick,
          id: uuidv4(),
          duration,
          showClose,
        },
      });
    },
    [dispatch]
  );

  const removeToast = useCallback(
    (id = 0) => {
      dispatch({
        type: "toast/removeToast",
        payload: id,
      });
    },
    [dispatch]
  );

  return {
    ...context,
    addToast,
    removeToast,
  };
};

export default useToast;
