import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchStoryboards } from "../../api";
import { setStoryboards, getStoryboards } from "../../storyboardsSlice";

const useStoryboards = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const storyboards = useSelector(getStoryboards);

  const { data, isLoading } = useQuery(["storyboards", filters], () => fetchStoryboards(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions?.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setStoryboards(data.getStoryboards));
  }, [data, isLoading, dispatch]);

  return {
    data: storyboards,
    isLoading,
  };
};

export default useStoryboards;
