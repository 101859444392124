import { useState, useEffect, useMemo } from "react";
import { Button, ConfirmationToast, Select, Sidebar, Toggle } from "@buildappeal/react-component-library";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import has from "lodash/has";
import noop from "lodash/noop";
import intersection from "lodash/intersection";
import { UilImport as DownloadIcon } from "@iconscout/react-unicons";

import CreateNewVariants from "@src/features/products/components/CreateNewVariants";
import isMediaFile from "@utils/isMedia";
import { useGeneralActionsUpdater } from "@utils/providers/generalActions";
import useBATypes from "@utils/hooks/useBATypes";
import useProductVariants from "@features/products/hooks/useProductVariants";
import useFinishes from "@features/finishes/hooks/useFinishes";
import useProductTypes from "@features/products/hooks/useProductTypes";
import { downloadFiles } from "@features/files/utils";
import useDeleteFiles from "./hooks/useDeleteFiles";
import { FILE_TYPES } from "./utils";

const FileMultiSelectAction = ({
  isOpen = false,
  selectedFiles,
  onClose = noop,
  projectId,
  projectName,
  onDeleteActionSuccess = noop,
  onEditActionSuccess = noop,
  skipQueryUpdate = false,
  filters = {},
  spaceOptions = [],
  contentTypeOptions = [],
  designStyleOptions = [],
  hideEditAction = false,
  isProduct = false,
  itemData = null,
}) => {
  const [fileProductIds, setFileProductIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showVariantCreate, setShowVariantCreate] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showEdit, setShowEdit] = useState(isOpen);
  const [formState, setFormState] = useState({});
  const [isVariantCreate, setIsVariantCreate] = useState(false);

  const { deleteFilesAsync } = useDeleteFiles({ projectId, skipQueryUpdate, ...filters });
  const { setActionsState } = useGeneralActionsUpdater();
  const { options: finishesAsOptions, isLoading: finishesLoading } = useFinishes();
  const { options: productVariantsOptions } = useProductVariants(fileProductIds);
  const { options: tagsAsOptions } = useBATypes("moodTags");
  const { options: productTypeOptions } = useProductTypes();

  useEffect(() => {
    if (isOpen) {
      onEditActionClick();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!selectedFiles.length) {
      return;
    }
    const pIds = selectedFiles.map((file) => file.productId).filter(Boolean);
    const uniqueProductIds = uniq(pIds);
    if (!isEqual(uniqueProductIds, fileProductIds)) {
      setFileProductIds();
    }

    const tags = intersection(...selectedFiles.map((file) => file.tags).filter(Boolean));
    const finishes = intersection(...selectedFiles.map((file) => file.finishes).filter(Boolean));
    const designStyles = intersection(...selectedFiles.map((file) => file.designStyles).filter(Boolean));
    const contentType = selectedFiles.every((file) => file.contentType === selectedFiles[0].contentType)
      ? selectedFiles[0].contentType
      : undefined;
    const productTypes = intersection(...selectedFiles.map((file) => file.productTypes).filter(Boolean));
    const spaces = intersection(
      ...selectedFiles.map((file) => (projectId ? file.projectSpaces : file.spaces)).filter(Boolean)
    );

    setFormValue({
      tags: tags?.map((tagName) => ({
        title: tagName,
        value: tagName,
      })),
      finishes: finishes
        ?.map((f) => {
          return finishesAsOptions.find((finishOption) => finishOption.title === f);
        })
        .filter(Boolean),
      designStyles: designStyles
        ?.map((style) => designStyleOptions.find((styleOption) => styleOption.title === style))
        .filter(Boolean),
      contentType: contentType,
      objects: productTypes
        ?.map((productType) => productTypeOptions.find((option) => option.title === productType))
        .filter(Boolean),
      spaces: projectId
        ? spaces
            ?.map((projectSpaceId) => spaceOptions.find((projectSpace) => projectSpace.space.id === projectSpaceId))
            .filter(Boolean)
        : spaces?.map((spaceName) => spaceOptions.find((space) => space.title === spaceName)).filter(Boolean),
    });
  }, [selectedFiles]);

  useEffect(() => {
    if (!itemData?.id || !isProduct) {
      return;
    }
    setShowVariantCreate(true);
  }, [itemData]);

  const productTypeOptionsFilter = useMemo(() => {
    return productTypeOptions?.filter((option) => option.productType.objectType === "Product") || [];
  }, [productTypeOptions]);

  const showNotificationMessageToast = (msg, timer = null) => {
    setNotificationMessage(msg);

    if (timer) {
      setTimeout(() => {
        setNotificationMessage("");
      }, timer);
    }
  };

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const showSuccessNotificationToast = (msg) => {
    showNotificationMessageToast(msg, 2000);
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  const handleDeleteSelectedFiles = async () => {
    if (isLoading) return;
    setIsLoading(true);
    showNotificationMessageToast("Deleting Files...");
    try {
      await deleteFilesAsync(
        selectedFiles.map((file) => ({
          id: file.id,
          type: isMediaFile(file.fileType) ? FILE_TYPES.media : FILE_TYPES.document,
        }))
      );

      setIsLoading(false);
      showSuccessNotificationToast("Files deleted successfuly.");
      onDeleteActionSuccess(selectedFiles);
    } catch (err) {
      setIsLoading(false);
      showNotificationMessageToast(`Failed to delete files. Try again!`, 2000);
    }
  };

  const handleSave = async () => {
    let groupDetails = {
      tags: [],
      spaces: [],
      designStyles: [],
      finishes: [],
      objects: [],
      contentType: has(formState, "contentType") ? formState.contentType : "",
    };
    const tagsInput = formState.tags?.map((tag) => {
      groupDetails.tags.push(tag.title);
      return { tagName: tag.value };
    });
    const spacesInput = uniq(
      formState.spaces?.map((projectSpace) => {
        groupDetails.spaces.push(projectSpace.space?.name);
        return projectSpace.space.spaceId || projectSpace.space.id;
      })
    );
    const designStylesInput = formState.designStyles?.map((designStyle) => {
      groupDetails.designStyles.push(designStyle.title);
      return designStyle.value;
    });
    const finishesInput = formState.finishes?.map((finish) => {
      groupDetails.finishes.push(finish?.title);
      return finish.value;
    });
    const objectsInput = formState.objects?.map((finish) => {
      groupDetails.objects.push(finish?.title);
      return finish.value;
    });

    const projectSpaces = projectId ? formState.spaces?.map((projectSpace) => projectSpace.space.id) : null;
    let groupTitle = "";
    Object.keys(groupDetails).forEach((key) => {
      let tagTitle = "";
      if (key === "tags") {
        tagTitle = "Tags: ";
      }
      if (key === "spaces") {
        tagTitle = "Spaces: ";
      }
      if (key === "designStyles") {
        tagTitle = "Design Styles: ";
      }
      if (key === "contentType") {
        tagTitle = "Content Type: ";
        groupTitle += `${tagTitle} ${groupDetails[key]}`;
      }
      if (key !== "contentType" && groupDetails[key].length > 0) {
        groupTitle += `${tagTitle} ${groupDetails[key].join(", ")} `;
      }
    });

    const filesInput = selectedFiles.map((file) => {
      const input = {
        id: file.id,
      };

      if (spacesInput) {
        input.spacesId = spacesInput;
      }

      if (projectSpaces) {
        input.projectSpacesId = projectSpaces;
      }

      if (tagsInput) {
        input.tags = tagsInput;
      }

      if (designStylesInput) {
        input.designStylesId = designStylesInput;
      }

      if (finishesInput) {
        input.finishes = finishesInput;
      }

      if (objectsInput) {
        input.objects = objectsInput;
      }

      if (has(formState, "contentType")) {
        input.contentType = formState.contentType;
      }

      if (has(formState, "productVariant")) {
        input.productVariantId = formState.productVariant.value;
      }
      if (has(formState, "isFeatured")) {
        input.isFeatured = formState.isFeatured;
      }

      return { input, name: file.name, type: file.type, id: file.id, groupTitle };
    });

    setActionsState({
      isOpen: true,
      currentAction: "updateBulkItems",
      triggeredBy: "filesMultiSelect",
      actionProps: {
        itemsInput: filesInput,
        onSuccess: onEditActionSuccess,
        skipQueryUpdate,
      },
    });
    handleSidebarClose();
  };

  const onEditActionClick = () => {
    setShowEdit(true);
  };

  const handleSidebarClose = () => {
    setShowEdit(false);
    setFormState({});
    onClose();
  };

  const handleZipDownload = async () => {
    await downloadFiles({ files: selectedFiles, projectName });
  };

  const onVariantCreateActionClick = () => {
    setIsVariantCreate(true);
    setShowEdit(true);
  };

  const count = selectedFiles.length;

  const variantsProps = useMemo(() => {
    return isProduct && showVariantCreate
      ? {
          tertiaryActionLabel: "Create Variants",
          onTertiaryActionClick: onVariantCreateActionClick,
        }
      : {};
  }, [showVariantCreate]);

  if (!count) {
    return null;
  }

  return (
    <>
      <ConfirmationToast
        show={!showEdit && count && !isLoading && !notificationMessage}
        label={`${count} file${count > 1 ? "s" : ""} selected`}
        onCancel={onClose}
        actionLabel="Edit"
        onActionClick={hideEditAction ? null : onEditActionClick}
        message={notificationMessage}
        secondaryActionlabel="Delete"
        onSecondaryActionClick={handleDeleteSelectedFiles}
        icon={DownloadIcon}
        iconActionClick={handleZipDownload}
        {...variantsProps}
      />
      <Sidebar
        isOpen={showEdit}
        onClose={handleSidebarClose}
        classNameContainer={isVariantCreate ? "!max-w-[1022px]" : "!max-w-lg"}
      >
        {isVariantCreate ? (
          <CreateNewVariants
            selectedFiles={selectedFiles}
            onSuccess={() => {
              onClose?.();
            }}
            selectedParentProduct={itemData}
          />
        ) : (
          <div className="flex h-full w-full flex-col space-y-4 px-6 py-10">
            <h3 className="text-xl">{`Edit ${count} File${count > 1 ? "s" : ""}`}</h3>
            <div className="mt-6 flex flex-1 flex-col gap-4 pt-4">
              <Select
                label="Spaces"
                value={formState.spaces || []}
                options={spaceOptions}
                onChange={(value) => setFormValue({ spaces: value })}
                showButton
                multiple
              />
              {fileProductIds?.length && productVariantsOptions?.length ? (
                <Select
                  value={formState.productVariant || {}}
                  label="Product Variants"
                  options={productVariantsOptions}
                  onChange={(value) => setFormValue({ productVariant: value })}
                  showButton
                  className="mt-4"
                />
              ) : null}
              <Select
                label="Add Tags"
                value={formState.tags || []}
                options={tagsAsOptions}
                onChange={(value) => setFormValue({ tags: value })}
                showButton
                multiple
              />
              <Select
                label="Content Type"
                value={{
                  title: formState.contentType || "",
                  value: formState.contentType || "",
                }}
                options={contentTypeOptions?.map((option) => ({
                  title: option,
                  value: option,
                }))}
                onChange={(opt) => {
                  return setFormValue({ contentType: opt?.value });
                }}
              />

              <Select
                label="Design Style"
                value={formState.designStyles || []}
                options={designStyleOptions}
                onChange={(value) => setFormValue({ designStyles: value })}
                showButton
                multiple
              />

              <Select
                label="Finish"
                value={formState.finishes || []}
                options={finishesAsOptions}
                onChange={(value) => {
                  setFormValue({ finishes: value });
                }}
                isLoading={finishesLoading}
                multiple
              />

              <Select
                label="Product Type"
                value={formState.objects || []}
                options={productTypeOptionsFilter}
                onChange={(value) => {
                  setFormValue({ objects: value });
                }}
                multiple
              />

              <Toggle
                label="Featured"
                checked={formState.isFeatured}
                onChange={(value) => {
                  setFormValue({ isFeatured: value });
                }}
                className="mt-2"
              />
            </div>
            <div className="flex w-full">
              <Button label={"Save"} className="w-full !rounded-sm" onClick={handleSave} />
            </div>
          </div>
        )}
      </Sidebar>
    </>
  );
};

export default FileMultiSelectAction;
