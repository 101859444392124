import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { setBatchedEmails, getBatchedEmails } from "@features/emails/emailsSlice";
import useEmailsCount from "../useEmailsCount";
import { fetchEmails } from "../../api";

const useBatchedEmails = (filters = {}, hookOptions = { disableQuery: true }) => {
  const { disableQuery = true } = hookOptions;
  const dispatch = useDispatch();
  const finalEmails = useSelector(getBatchedEmails);
  const { data: emailsCount, isLoading: emailsCountLoading } = useEmailsCount(filters, { disableQuery });
  const queryClient = useQueryClient();
  const [skip, setSkip] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalBaseEmailCount, setTotalBaseEmailCount] = useState(0);

  const { data, isLoading: isBatchedFetchLoading } = useQuery(
    ["emails", skip],
    (queryKeyCtx) => fetchEmails({ ...filters, skip: queryKeyCtx.queryKey[1] }),
    {
      refetchOnWindowFocus: false,
      enabled: skip !== null,
      onSuccess: (data) => {
        if (data.getEmails?.length) {
          setTotalBaseEmailCount((prev) => prev + data.getEmails?.length);
          queryClient.setQueryData(["emails", {}], (old) => {
            return {
              getEmails: [...(old?.getEmails || []), ...data.getEmails],
            };
          });
        }
      },
    }
  );

  useEffect(() => {
    if (!data?.getEmails?.length || emailsCountLoading || finalEmails.length === emailsCount) {
      return;
    }

    setIsLoading(false);
    dispatch(setBatchedEmails(data.getEmails));
  }, [data, isBatchedFetchLoading, emailsCountLoading, emailsCount]);

  useEffect(() => {
    if (!totalBaseEmailCount) {
      return;
    }
    if (totalBaseEmailCount < emailsCount) {
      setSkip(totalBaseEmailCount);
    }
  }, [totalBaseEmailCount, emailsCount]);

  useEffect(() => {
    if (disableQuery || finalEmails.length) {
      return;
    }
    setIsLoading(true);
    setSkip(0);
  }, [disableQuery, finalEmails]);

  return {
    data: finalEmails,
    isLoading,
    isBatchedFetchLoading,
    totalCount: emailsCount > finalEmails.length ? emailsCount : finalEmails.length,
  };
};

export default useBatchedEmails;
