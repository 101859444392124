import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  suppliers: [],
  supplierById: {},
  supplierQuotes: [],
};

export const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setSuppliers: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        suppliers: action.payload.filter((supplier) => supplier.isActive),
      };
    },
    setSupplierDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        supplierById: {
          ...originalState.productsById,
          [action.payload.id]: action.payload,
        },
      };
    },
    setSupplierQuotes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        supplierQuotes: action.payload,
      };
    },
  },
});

export const { setSuppliers, setSupplierDetail, setSupplierQuotes } = suppliersSlice.actions;

export default suppliersSlice.reducer;

export const getSuppliers = (state) => state.suppliers.suppliers;

export const selectSupplierById = (id) => (state) => state.suppliers.supplierById[id];

export const getSupplierQuotes = (state) => state.suppliers.supplierQuotes;
