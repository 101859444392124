import { useState, useMemo } from "react";
import { Button, Counter } from "@buildappeal/react-component-library";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import clsx from "clsx";

import useToast from "@src/utils/hooks/useToast";
import Loading from "@src/utils/components/Loading";
import Separator from "@src/utils/components/Separator";
import useSpaces from "./hooks/useSpaces";
import useAddSpacesToProject from "./hooks/useAddSpacesToProject";
import { getSpaceIconUrl, SPACE_LOCATIONS } from "./utils";

export default function AddMultipleSpace({ selectedProject, onClose, onSuccess, showToast = true }) {
  const [selectedSpaces, selectSpaces] = useState({});
  const { addToast } = useToast();
  const { data, isLoading } = useSpaces();
  const spaces = useMemo(
    () =>
      groupBy(
        sortBy(
          (data || [])?.map((space) => ({
            ...space,
            count: selectedProject?.spaces?.filter((projectSpace) => projectSpace.spaceId === space.id)?.length || 0,
          })),
          "sortOrder"
        ),
        "location"
      ),
    [data]
  );

  const { addSpacesToProjectAsync, isLoading: savingLoading } = useAddSpacesToProject(selectedProject.id);

  const handleChangeCounter = (space, count) => {
    selectSpaces({
      ...selectedSpaces,
      [space.id]: {
        ...space,
        count,
      },
    });
  };

  const getSpacesToCreate = (selectedSpaces) => {
    const spacesToCreate = Object.keys(selectedSpaces).flatMap((spaceId) =>
      Array(selectedSpaces[spaceId].count).fill({
        name: selectedSpaces[spaceId].name,
        space: parseInt(spaceId),
        project: selectedProject.id,
      })
    );

    return spacesToCreate;
  };

  const onSubmit = async () => {
    if (!selectedProject?.id) {
      return;
    }

    try {
      await addSpacesToProjectAsync(getSpacesToCreate(selectedSpaces));
      onClose();
      onSuccess?.();
      if (showToast) {
        addToast("Spaces added successfully");
      }
    } catch (err) {
      addToast("Error adding spaces");
    }
  };

  const selectedSpacesCount = useMemo(() => getSpacesToCreate(selectedSpaces).length, [selectedSpaces]);

  if (isLoading) return <Loading />;

  return (
    <div className="pt-8">
      {!!spaces[SPACE_LOCATIONS.INTERIOR]?.length && (
        <div className="space-y-4">
          <p className="text-md text-neutral-800">Interior Spaces</p>
          <div className="grid grid-cols-2 gap-x-16">
            {spaces[SPACE_LOCATIONS.INTERIOR]?.map((space) => (
              <div className="flex items-center justify-between" key={space.id}>
                <Counter
                  size="sm"
                  label={`${space.name} ${space.count > 0 && !getSpaceIconUrl(space.name) ? `(${space.count})` : ""}`}
                  onChange={(countSpaces) => handleChangeCounter(space, countSpaces)}
                  value={selectedSpaces[space.id]?.count || 0}
                  image={getSpaceIconUrl(space.name)}
                  badgeCount={space.count}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <Separator className="my-8" />

      {!!spaces[SPACE_LOCATIONS.EXTERIOR]?.length && (
        <div className="space-y-4">
          <p className="text-md text-neutral-800">Exterior Spaces</p>
          <div className="grid grid-cols-2 gap-x-16">
            {spaces[SPACE_LOCATIONS.EXTERIOR]?.map((space) => (
              <div className="flex items-center justify-between" key={space.id}>
                <Counter
                  size="sm"
                  label={`${space.name} ${space.count > 0 && !getSpaceIconUrl(space.name) ? `(${space.count})` : ""}`}
                  onChange={(countSpaces) => handleChangeCounter(space, countSpaces)}
                  value={selectedSpaces[space.id]?.count || 0}
                  image={getSpaceIconUrl(space.name)}
                  badgeCount={space.count}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="sticky bottom-0 left-0 right-0 bg-white pb-5 pt-2 dark:bg-midnight">
        <Button
          disabled={savingLoading || !selectedSpacesCount}
          label={
            !selectedSpacesCount
              ? "Add Spaces"
              : `Add ${selectedSpacesCount} Space${selectedSpacesCount > 1 ? "s" : ""}`
          }
          onClick={onSubmit}
          isLoading={savingLoading}
          className={clsx(
            "w-full !rounded-sm",
            !selectedSpacesCount
              ? "!bg-primary-400 hover:!bg-primary-400 dark:!border-neutral-600 dark:!bg-transparent"
              : ""
          )}
        />
      </div>
    </div>
  );
}
