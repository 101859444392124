import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchBills } from "../../api";
import { setBills, getBills } from "../../billsSlice";

const useBills = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const bills = useSelector(getBills);

  const { data, isLoading } = useQuery(["bills", filters], () => fetchBills(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setBills(data.getBills));
  }, [data, isLoading, dispatch]);

  return {
    data: bills,
    isLoading,
  };
};

export default useBills;
