const dataQueryIdFiltersMap = {
  "/people": (id, curr) => {
    const [type, queryId] = id?.split("_");
    return type === curr?.personType?.toLowerCase() && queryId === String(curr?.id);
  },
  "/products": (id, curr) => id === curr.selectorId,
  default: (id, curr) => id === curr.id,
};

export default dataQueryIdFiltersMap;
