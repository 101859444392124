import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { formatUSD } from "@src/utils/common";
import { fetchPurchases } from "../../api";
import { setPurchases, getPurchases } from "../../purchasesSlice";

const usePurchases = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const purchases = useSelector(getPurchases);

  const { data, isLoading, refetch } = useQuery(["purchases", filters], () => fetchPurchases(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  const formatter = formatUSD.format;
  const purchasesAsOptions = useMemo(() => {
    if (!purchases?.length) {
      return [];
    }

    return purchases.map((purchase) => {
      return {
        title: `${purchase?.id} - ${formatter(purchase?.estimatedTotal)}`,
        value: purchase.id,
      };
    });
  }, [purchases]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setPurchases(data.getPurchases));
  }, [data, isLoading, dispatch]);

  return {
    data: purchases,
    isLoading,
    purchasesLoading: isLoading,
    options: purchasesAsOptions,
    refetch,
  };
};

export default usePurchases;
