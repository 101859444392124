const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: ["project", "supplier", "supplierQuote", "vendorOrderNumber"],
    },
    {
      title: "Costs",
      properties: ["subTotal", "shippingTotal", "taxTotal", "total"],
    },
    {
      title: "Delivery",
      properties: ["purchaseDate", "estimatedDelivery", "isInventory"],
    },
    {
      title: "Documents",
      properties: ["documents"],
      isLast: true,
    },
  ],
};

export default uiSchema;
