import { useQuery } from "react-query";
import { fetchFileById } from "../../api";

const useFileDetail = (id, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const { data, isLoading, refetch } = useQuery(["files", id], () => fetchFileById(id), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
    refetchInterval: false,
    staleTime: Infinity,
  });

  return {
    data: data?.getFileInfoByID,
    isLoading,
    refetch,
  };
};

export default useFileDetail;
