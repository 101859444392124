import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  people: [],
};

export const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    setPeople: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        people: action.payload,
      };
    },
  },
});

export const { setPeople } = peopleSlice.actions;

export default peopleSlice.reducer;

export const getPeople = (state) => state.people.people;
