import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bids: [],
  bidItems: [],
};

export const bidsSlice = createSlice({
  name: "bids",
  initialState,
  reducers: {
    setBids: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        bids: action.payload,
      };
    },
    setBidItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        bidItems: action.payload,
      };
    },
  },
});

export const { setBids, setBidItems } = bidsSlice.actions;

export default bidsSlice.reducer;

export const getBids = (state) => state.bids.bids;
export const getBidItems = (state) => state.bids.bidItems;
