import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import sortBy from "lodash/sortBy";

import { fetchSpaces } from "../../api";
import { getSpaces, setSpaces } from "../../spacesSlice";

const useSpaces = (filters = {}) => {
  const dispatch = useDispatch();
  const spaces = useSelector(getSpaces);

  const { data, isLoading, refetch } = useQuery(["spaces", filters], () => fetchSpaces(filters), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setSpaces(data.getSpaces));
  }, [data, isLoading, dispatch]);

  const spacesAsOptions = useMemo(() => {
    if (!spaces?.length) {
      return [];
    }

    return sortBy(spaces, "sortOrder").map((space) => ({
      title: space.name,
      value: space.id,
      space,
    }));
  }, [spaces]);

  return {
    data: spaces,
    isLoading,
    refetch,
    options: spacesAsOptions,
  };
};

export default useSpaces;
