export const formSchema = {
  title: "Create Partner",
  properties: {
    companyName: {
      type: "text",
      label: "Company Name",
      required: true,
    },
    email: {
      type: "email",
      label: "Company Email",
      required: true,
    },
    officePhone: {
      type: "phone",
      label: "Company Phone",
      required: true,
      defaultCountry: "US",
      maxLength: 14,
    },
    website: {
      type: "url",
      label: "Website",
      validate: {
        isURL: true,
      },
    },

    address: {
      type: "address",
      label: "Address",
    },
    services: {
      type: "selectMultiple",
      label: "Services",
    },
    einNumber: {
      type: "text",
      label: "EIN Number",
    },
    logo: {
      type: "file",
      label: "Logo",
      maxFiles: 1,
      multiple: false,
      handleSelectedFiles: (files) => {},
    },
    license: {
      type: "file",
      label: "Contractors License",
      acceptedFileTypes: [".pdf"],
      maxFiles: 1,
      multiple: false,
      appearance: "button",
      handleSelectedFiles: (files) => {},
    },
    w9: {
      type: "file",
      label: "W9",
      acceptedFileTypes: [".pdf"],
      maxFiles: 1,
      multiple: false,
      appearance: "button",
      handleSelectedFiles: (files) => {},
    },
    coi: {
      type: "file",
      label: "Certificate of Insurance",
      acceptedFileTypes: [".pdf"],
      maxFiles: 1,
      multiple: false,
      appearance: "button",
      handleSelectedFiles: (files) => {},
    },
    workersComp: {
      type: "file",
      label: "Workers Compensation",
      acceptedFileTypes: [".pdf"],
      maxFiles: 1,
      multiple: false,
      appearance: "button",
      handleSelectedFiles: (files) => {},
    },
    generalLiability: {
      type: "file",
      label: "General Liability",
      acceptedFileTypes: [".pdf"],
      maxFiles: 1,
      multiple: false,
      appearance: "button",
      handleSelectedFiles: (files) => {},
    },
    checkBoxVal: {
      type: "checkbox",
      isConditional: true,
      formCondition: {
        true: {
          contact: {
            type: "select",
            label: "Select Contact",
          },
        },
        false: {
          contactFirstName: {
            type: "text",
            label: "Contact First Name",
          },
          contactLastName: {
            type: "text",
            label: "Contact Last Name",
          },
          contactEmail: {
            type: "email",
            label: "Contact Email",
          },
          contactPhone: {
            type: "phone",
            label: "Contact Phone",
            defaultCountry: "US",
            maxLength: 14,
          },
        },
      },
      label: "Already has a Contact",
    },
  },
};
