import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import {
  UilPlus as PlusIcon,
  UilCloudUpload as CloudUploadIcon,
  UilCameraChange as CameraChangeIcon,
} from "@iconscout/react-unicons";
import { Button } from "@buildappeal/react-component-library";

import { addFileDetails } from "./utils";

const FolderUpload = ({
  handleSelectedFiles,
  acceptedFileTypes = ["image/jpeg", "image/png", "image/heic", ".pdf", ".docx", "video/*", ".dwg"],
  className = "",
  isResponsive = false,
  isDisabled = false,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleSelectedFiles,
    accept: acceptedFileTypes,
    multiple: true,
    getFilesFromEvent: (event) => myCustomFileGetter(event),
  });

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [filesCount, setFilesCount] = useState({
    files: 0,
    folders: 0,
  });
  const [allFiles, setAllFiles] = useState([]);
  const [totalCount, setTotalCount] = useState({
    files: 0,
    folders: 0,
  });

  async function myCustomFileGetter(event) {
    setIsDraggingOver(true);
    let files = [];
    let updatedFilesCount = { ...filesCount };

    if (event.dataTransfer) {
      const directoryEntries = event.dataTransfer.items;
      for (let i = 0; i < directoryEntries.length; i++) {
        let dataItem = directoryEntries[i].webkitGetAsEntry();
        if (dataItem) {
          if (dataItem?.isDirectory) {
            updatedFilesCount.folders = updatedFilesCount.folders + 1;
            let directoryName = dataItem.name;
            const reader = dataItem.createReader();
            reader.readEntries((entries) => {
              const entriesLength = entries.length;
              setTotalCount((prev) => ({ ...(prev || {}), files: (prev.files || 0) + entriesLength }));
              entries.forEach((dir, key) => {
                dir.file((fl) => {
                  updatedFilesCount.files = updatedFilesCount.files + 1;
                  files.push(addFileDetails(fl, directoryName));
                  setFilesCount(updatedFilesCount);
                  setAllFiles((prevFiles) => [...(prevFiles || []), addFileDetails(fl, directoryName)]);
                });
              });
            });
          }
        }
      }
    }

    return files;
  }

  useEffect(() => {
    if (allFiles.length === totalCount.files) {
      handleSelectedFiles(allFiles);
    }
  }, [allFiles]);

  return (
    <div {...getRootProps()}>
      <div className={clsx(isResponsive ? "flex justify-center lg:hidden" : "hidden")}>
        <input {...getInputProps()} type="file" className={clsx("hidden", className)} />
        <Button
          appearance="secondary"
          size="xs"
          className="default"
          label="Add files or folders"
          icon={PlusIcon}
          type="button"
        />
      </div>
      <div
        className={clsx(
          isResponsive ? "hidden lg:flex" : "flex",
          "flex flex-col items-center justify-center rounded-xl py-7",
          isDraggingOver
            ? "accent-dashed-border-desktop accent-dashed-border-mobile"
            : "neutral-dashed-border-desktop neutral-dashed-border-mobile"
        )}
      >
        <CloudUploadIcon className="mb-[1.125rem] hidden h-12 w-12 shrink-0 text-accent-700 sm:block" />
        <CameraChangeIcon className="mb-3 block h-6 w-6 shrink-0 text-accent-700 sm:hidden" />
        <span className="mb-3 hidden text-md text-neutral-800 sm:block">Drop folders here to upload</span>
        <span className="mb-3 hidden text-center text-md text-accent-800 sm:block">
          Please note only folders should be added and that the folders names are ids of product.
        </span>

        <input
          {...getInputProps()}
          type="file"
          className={clsx("hidden", className)}
          disabled={isDisabled}
          directory=""
          webkitdirectory=""
        />
      </div>
      {filesCount?.files ? (
        <div className="flex flex-row items-center justify-center text-md text-neutral-800">
          <span className="mr-5">Folders : {filesCount.folders}</span>
          <span>Files : {filesCount.files}</span>
        </div>
      ) : null}
    </div>
  );
};

FolderUpload.propTypes = {
  handleSelectedFiles: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  isResponsive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default FolderUpload;
