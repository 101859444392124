import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailsBatched: [],
};

export const emailsSlice = createSlice({
  name: "emails",
  initialState,
  reducers: {
    setBatchedEmails: (state, action) => {
      const originalState = original(state);

      return {
        ...originalState,
        emailsBatched: [...(originalState.emailsBatched || []), ...(action.payload || [])],
      };
    },
  },
});

export const { setBatchedEmails } = emailsSlice.actions;

export default emailsSlice.reducer;

export const getBatchedEmails = (state) => state.emails.emailsBatched;
