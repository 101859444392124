import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices } from "../../api";
import { setInvoices, getInvoices } from "../../invoicesSlice";

const useInvoices = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const invoices = useSelector(getInvoices);

  const { data, isLoading } = useQuery(["invoices", filters], () => fetchInvoices(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setInvoices(data.getInvoices));
  }, [data, isLoading, dispatch]);

  const invoicesAsOptions = useMemo(() => {
    if (!invoices?.length) {
      return [];
    }
    return invoices.map((invoice) => {
      return {
        value: invoice.id,
        title: invoice.title,
        invoice,
      };
    });
  }, [invoices]);

  return {
    data: invoices,
    isLoading,
    options: invoicesAsOptions,
  };
};

export default useInvoices;
