import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PRODUCTS = gql`
  query getProducts($filters: ProductsFilterFields!) {
    getProducts(filters: $filters) {
      id
      name
      description
      leadTimeDays
      cost
      sku
      brand
      status
      isStocked
      dimensions
      finish
      colors {
        id
        name
        icon
        colorValue
      }
      isTaxExempt
      isRefundable
      vendorURL
      unit
      isActive
      suppliers {
        id
        name
        website
      }
      designStyles {
        id
        name
        description
      }
      spaces {
        id
        name
      }
      objects {
        id
        description
        parentObject {
          id
          description
        }
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        productId
      }
      createdAt
      materials {
        id
        name
      }
      finishes {
        id
        name
      }
      variants {
        id
        name
        cost
        colors {
          id
          name
          icon
          colorValue
        }
        vendorURL
        sku
        media {
          id
          path
          fileType
          sortOrder
          isThumbnail
        }
      }
      dtAttributes {
        id
        name
        type
        icon
        objectName
      }
    }
  }
`;

const GET_PRODUCTS_WITH_ATTRIBUTES = gql`
  query getProducts($filters: ProductsFilterFields!) {
    getProducts(filters: $filters) {
      id
      name
      description
      leadTimeDays
      cost
      sku
      status
      brand
      isStocked
      dimensions
      finish
      colors {
        id
        name
        icon
        colorValue
      }
      isTaxExempt
      isRefundable
      vendorURL
      unit
      width
      height
      depth
      isActive
      suppliers {
        id
        name
        website
      }
      type {
        id
        description
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        productId
      }
      createdAt
      designStyles {
        id
        name
        description
      }
      spaces {
        id
        name
      }
      collections {
        id
        name
      }
      attributes {
        id
        attribute {
          id
          name
          dataType
          parentAttributeId
          sortBy
        }
        options {
          name
          additionalCost
        }
      }
      dtAttributes {
        id
        name
        type
        icon
        objectName
      }
    }
  }
`;

const GET_PRODUCT_TYPES = gql`
  query getProductTypeObjects {
    getProductTypeObjects {
      id
      description
      objectType
    }
  }
`;

const GET_PRODUCT = gql`
  query getProductByID($id: Int!) {
    getProductByID(id: $id) {
      id
      name
      description
      leadTimeDays
      cost
      photos
      sku
      status
      brand
      isStocked
      dimensions
      finish
      colors {
        id
        name
        icon
        colorValue
      }
      isTaxExempt
      isRefundable
      vendorURL
      unit
      createdAt
      width
      height
      depth
      isActive
      suppliers {
        id
        name
        website
      }
      objects {
        id
        description
        parentObject {
          id
          description
        }
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        productId
      }
      designStyles {
        id
        name
        description
      }
      spaces {
        id
        name
      }
      materials {
        id
        name
      }
      finishes {
        id
        name
      }
      collections {
        id
        name
      }
      documents {
        id
        name
        path
        fileType
        type
      }
      cads {
        id
        name
        path
        fileType
        type
      }
      variants {
        id
        name
        cost
        colors {
          id
          name
          icon
          colorValue
        }
        vendorURL
        sku
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
        }
      }
      dtAttributes {
        id
        name
        type
        icon
        objectName
      }
    }
  }
`;

const GET_PRODUCT_VARIANT_DETAILS = gql`
  query getProductByID($id: Int!, $variantId: BigInt) {
    getProductByID(id: $id, variantId: $variantId) {
      id
      name
      description
      leadTimeDays
      cost
      photos
      sku
      status
      brand
      isStocked
      dimensions
      finish
      colors {
        id
        name
        icon
        colorValue
      }
      isTaxExempt
      isRefundable
      vendorURL
      unit
      createdAt
      width
      height
      depth
      isActive
      suppliers {
        id
        name
        website
      }
      objects {
        id
        description
        parentObject {
          id
          description
        }
      }
      collections {
        id
        name
      }
      variants {
        id
        name
        cost
        colors {
          id
          name
          icon
          colorValue
        }
        sku
        vendorURL
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
        }
        designStyles {
          id
          name
          description
        }
        materials {
          id
          name
        }
        finishes {
          id
          name
        }
        documents {
          id
          name
          path
          fileType
          type
        }
        cads {
          id
          name
          path
          fileType
          type
        }
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductRequest!) {
    createProduct(input: $input) {
      code
      success
      message
      data {
        id
        name
        description
        leadTimeDays
        cost
        photos
        sku
        status
        brand
        isStocked
        dimensions
        vendorURL
        finish
        colors {
          id
          name
          icon
          colorValue
        }
        isTaxExempt
        isRefundable
        unit
        createdAt
        width
        height
        depth
        isActive
        objects {
          id
          description
          parentObject {
            id
            description
          }
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          productId
        }
        suppliers {
          id
          name
          website
        }
        designStyles {
          id
          name
          description
        }
        spaces {
          id
          name
        }
        materials {
          id
          name
        }
        finishes {
          id
          name
        }
        collections {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductRequest!) {
    updateProduct(input: $input) {
      code
      success
      message
      data {
        id
        name
        description
        leadTimeDays
        cost
        photos
        sku
        status
        brand
        isStocked
        dimensions
        vendorURL
        finish
        colors {
          id
          name
          icon
          colorValue
        }
        isTaxExempt
        isRefundable
        unit
        createdAt
        width
        height
        depth
        isActive
        objects {
          id
          description
          parentObject {
            id
            description
          }
        }
        suppliers {
          id
          name
          website
        }
        dtAttributes {
          id
          name
          type
          icon
          objectName
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          productId
        }
        designStyles {
          id
          name
          description
        }
        spaces {
          id
          name
        }
        materials {
          id
          name
        }
        finishes {
          id
          name
        }
        collections {
          id
          name
        }
        variants {
          id
          name
          cost
          colors {
            id
            name
            icon
            colorValue
          }
          sku
          vendorURL
          media {
            id
            path
            name
            fileType
            sortOrder
            isThumbnail
            type
          }
          designStyles {
            id
            name
            description
          }
          materials {
            id
            name
          }
          finishes {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
            type
          }
          cads {
            id
            name
            path
            fileType
            type
          }
        }
      }
    }
  }
`;

const GET_PRODUCT_VARIANTS = gql`
  query getAllProductsVariants($productIds: [Int]!) {
    getAllProductsVariants(productIds: $productIds) {
      id
      name
      products {
        id
        name
      }
    }
  }
`;

const DELETE_PRODUCTS = gql`
  mutation deleteProducts($input: [DeleteProductRequest]!) {
    deleteProducts(input: $input) {
      code
      success
      message
      deletedIds
    }
  }
`;

const UPDATE_PRODUCTS = gql`
  mutation updateProducts($input: [UpdateProductRequest]!) {
    updateProducts(input: $input) {
      code
      success
      message
      data {
        id
        name
        description
        leadTimeDays
        cost
        photos
        sku
        status
        brand
        isStocked
        dimensions
        vendorURL
        finish
        colors {
          id
          name
          icon
          colorValue
        }
        isTaxExempt
        isRefundable
        unit
        createdAt
        width
        height
        depth
        isActive
        dtAttributes {
          id
          name
          type
          icon
          objectName
        }
        objects {
          id
          description
          parentObject {
            id
            description
          }
        }
        suppliers {
          id
          name
          website
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          productId
        }
        designStyles {
          id
          name
          description
        }
        spaces {
          id
          name
        }
        materials {
          id
          name
        }
        finishes {
          id
          name
        }
        collections {
          id
          name
        }
        variants {
          id
          name
          cost
          colors {
            id
            name
            icon
            colorValue
          }
          sku
          vendorURL
          media {
            id
            path
            name
            fileType
            sortOrder
            isThumbnail
            type
          }
          designStyles {
            id
            name
            description
          }
          materials {
            id
            name
          }
          finishes {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
            type
          }
          cads {
            id
            name
            path
            fileType
            type
          }
        }
      }
    }
  }
`;

const GET_PRODUCTS_COUNT = gql`
  query getProductsCount($filters: ProductsFilterFields!) {
    getProductsCount(filters: $filters)
  }
`;

const UPDATE_PRODUCT_VARIANTS = gql`
  mutation updateProductVariants($input: [UpdateProductVariantRequest]!) {
    updateProductVariants(input: $input) {
      code
      success
      message
      data {
        id
        name
        description
        leadTimeDays
        cost
        photos
        sku
        status
        brand
        isStocked
        dimensions
        vendorURL
        finish
        colors {
          id
          name
          icon
          colorValue
        }
        isTaxExempt
        isRefundable
        unit
        createdAt
        width
        height
        depth
        isActive
        objects {
          id
          description
          parentObject {
            id
            description
          }
        }
        suppliers {
          id
          name
          website
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          productId
        }
        variants {
          id
          name
          cost
          colors {
            id
            name
            icon
            colorValue
          }
          sku
          vendorURL
          media {
            id
            path
            name
            fileType
            sortOrder
            isThumbnail
            type
          }
          designStyles {
            id
            name
            description
          }
          materials {
            id
            name
          }
          finishes {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
            type
          }
          cads {
            id
            name
            path
            fileType
            type
          }
        }
      }
    }
  }
`;

const UPDATE_PRODUCT_VARIANT_THUMBNAIL = gql`
  mutation updateProductVariantThumbnail($input: UpdateProductVariantThumbnailRequest!) {
    updateProductVariantThumbnail(input: $input) {
      code
      success
      message
      data {
        id
        name
        description
        leadTimeDays
        cost
        photos
        sku
        status
        brand
        isStocked
        dimensions
        vendorURL
        finish
        colors {
          id
          name
          icon
          colorValue
        }
        isTaxExempt
        isRefundable
        unit
        createdAt
        width
        height
        depth
        isActive
        objects {
          id
          description
          parentObject {
            id
            description
          }
        }
        suppliers {
          id
          name
          website
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          productId
        }
        variants {
          id
          name
          cost
          colors {
            id
            name
            icon
            colorValue
          }
          sku
          vendorURL
          media {
            id
            path
            name
            fileType
            sortOrder
            isThumbnail
            type
          }
          designStyles {
            id
            name
            description
          }
          materials {
            id
            name
          }
          finishes {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
            type
          }
          cads {
            id
            name
            path
            fileType
            type
          }
        }
      }
    }
  }
`;

const CREATE_PRODUCT_VARIANTS = gql`
  mutation createProductVariants($input: [CreateVariantDetails]!) {
    createProductVariants(input: $input) {
      code
      success
      message
      deletedIds
      data {
        id
        name
        description
        leadTimeDays
        cost
        photos
        sku
        status
        brand
        isStocked
        dimensions
        vendorURL
        finish
        colors {
          id
          name
          icon
          colorValue
        }
        isTaxExempt
        isRefundable
        unit
        createdAt
        width
        height
        depth
        isActive
        objects {
          id
          description
          parentObject {
            id
            description
          }
        }
        suppliers {
          id
          name
          website
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          productId
        }
        variants {
          id
          name
          cost
          colors {
            id
            name
            icon
            colorValue
          }
          sku
          vendorURL
          media {
            id
            path
            name
            fileType
            sortOrder
            isThumbnail
            type
          }
          designStyles {
            id
            name
            description
          }
          materials {
            id
            name
          }
          finishes {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
            type
          }
          cads {
            id
            name
            path
            fileType
            type
          }
        }
      }
    }
  }
`;

const CREATE_NEW_PRODUCT_VARIANTS = gql`
  mutation createNewProductVariants($input: [CreateNewProductVariantRequest]!) {
    createNewProductVariants(input: $input) {
      code
      success
      message
      data {
        id
        name
        description
        leadTimeDays
        cost
        photos
        sku
        status
        brand
        isStocked
        dimensions
        vendorURL
        finish
        colors {
          id
          name
          icon
          colorValue
        }
        isTaxExempt
        isRefundable
        unit
        createdAt
        width
        height
        depth
        isActive
        objects {
          id
          description
          parentObject {
            id
            description
          }
        }
        suppliers {
          id
          name
          website
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          productId
        }
        variants {
          id
          name
          cost
          colors {
            id
            name
            icon
            colorValue
          }
          sku
          vendorURL
          media {
            id
            path
            name
            fileType
            sortOrder
            isThumbnail
            type
          }
          designStyles {
            id
            name
            description
          }
          materials {
            id
            name
          }
          finishes {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
            type
          }
          cads {
            id
            name
            path
            fileType
            type
          }
        }
      }
    }
  }
`;

const GET_RPODUCTS_BY_IDS = gql`
  query getProductsByIds($ids: [Int]!) {
    getProductsByIds(ids: $ids) {
      id
      name
      description
      leadTimeDays
      cost
      photos
      sku
      status
      brand
      isStocked
      dimensions
      finish
      colors {
        id
        name
        icon
        colorValue
      }
      isTaxExempt
      isRefundable
      vendorURL
      unit
      createdAt
      width
      height
      depth
      isActive
      suppliers {
        id
        name
        website
      }
      finishes {
        id
        name
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        productId
      }
      materials {
        id
        name
      }
      dtAttributes {
        id
        name
        type
      }
      variants {
        id
        name
        cost
        colors {
          id
          name
          icon
          colorValue
        }
        vendorURL
        sku
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
        }
        materials {
          id
          name
        }
      }
    }
  }
`;
export const createNewProductVariants = async (input) => {
  return await graphQLClient.request(CREATE_NEW_PRODUCT_VARIANTS, { input });
};

export const createProductVariants = async (input) => {
  return await graphQLClient.request(CREATE_PRODUCT_VARIANTS, { input });
};

export const fetchProducts = async (filters = {}) => {
  return await graphQLClient.request(GET_PRODUCTS, { filters });
};

export const fetchProductsWithAttributes = async (filters = {}) => {
  return await graphQLClient.request(GET_PRODUCTS_WITH_ATTRIBUTES, { filters });
};

export const fetchProductTypes = async () => {
  return await graphQLClient.request(GET_PRODUCT_TYPES);
};

export const fetchProductById = async (id) => {
  return await graphQLClient.request(GET_PRODUCT, { id });
};

export const createProduct = async (input = {}) => {
  return await graphQLClient.request(CREATE_PRODUCT, { input });
};

export const updateProduct = async (input = {}) => {
  return await graphQLClient.request(UPDATE_PRODUCT, { input });
};

export const deleteProducts = async (input = []) => {
  return await graphQLClient.request(DELETE_PRODUCTS, { input });
};

export const updateProducts = async (input = []) => {
  return await graphQLClient.request(UPDATE_PRODUCTS, { input });
};

export const getProductsCount = async (filters = {}) => {
  return await graphQLClient.request(GET_PRODUCTS_COUNT, { filters });
};

export const getProductVariants = async (productIds = []) => {
  if (!productIds?.length) return [];

  return await graphQLClient.request(GET_PRODUCT_VARIANTS, { productIds });
};

export const getProductVariantDetails = async (filters = {}) => {
  if (!filters?.id && !filters?.variantId) {
    return;
  }
  return await graphQLClient.request(GET_PRODUCT_VARIANT_DETAILS, { id: filters.id, variantId: filters.variantId });
};

export const updateProductVariants = async (input = []) => {
  return await graphQLClient.request(UPDATE_PRODUCT_VARIANTS, { input });
};

export const updateProductVariantThumbnail = async (input = {}) => {
  return await graphQLClient.request(UPDATE_PRODUCT_VARIANT_THUMBNAIL, { input });
};

export const fetchProductsByIds = async (ids = []) => {
  if (!ids?.length) return null;
  return await graphQLClient.request(GET_RPODUCTS_BY_IDS, { ids });
};
