import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEstimates } from "../../api";
import { setEstimates, getEstimates } from "../../estimatesSlice";

const useAllEstimates = (filters = {}, hookOptions) => {
  const dispatch = useDispatch();
  const estimates = useSelector(getEstimates);

  const { data, isLoading } = useQuery(["estimates", filters], () => fetchAllEstimates({}), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions?.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setEstimates(data.getEstimates));
  }, [data, isLoading, dispatch]);

  const estimatesAsOptions = useMemo(() => {
    if (!estimates?.length) {
      return [];
    }

    return estimates
      .map((estimate) => ({
        title: estimate.total,
        value: estimate.id,
        estimate,
      }))
      .sort((a, b) => a.estimate.total - b.estimate.total);
  }, [estimates]);

  return {
    data: estimates,
    options: estimatesAsOptions,
    isLoading,
  };
};

export default useAllEstimates;
