import { gql } from "graphql-request";
import axios from "@src/utils/axios";
import graphQLClient from "@src/utils/graphQLClient";
import { getCookie } from "@src/utils/universalCookie";

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

// fileType : One of<"media", "document", "secureDocument">
// method: "GET" | "PUT"
export const getPreSignedUrl = async (fileKey, contentType, fileType, method = "GET") => {
  const sessionCookie = getCookie("ba_session");
  if (!sessionCookie) {
    return null;
  }
  const resp = await axios.post(
    `${AUTH_URL}/presigned-url`,
    {
      fileName: fileKey,
      contentType,
      type: fileType,
      method,
    },
    {
      headers: {
        Authorization: getCookie("ba_session"),
      },
    }
  );

  if (!resp.data) {
    return null;
  }
  return resp.data;
};

// Each object must contain a `type` property to allow picking the right bucket
// Should be one of "media", "document", "secureDocument"
export const getMultiPreSignedUrl = async (files) => {
  const sessionCookie = getCookie("ba_session");
  if (!sessionCookie) {
    return null;
  }
  const resp = await axios.post(
    `${AUTH_URL}/multi-presigned-url`,
    {
      files: JSON.stringify(files),
    },
    {
      headers: {
        Authorization: getCookie("ba_session"),
      },
    }
  );

  if (!resp.data) {
    return null;
  }
  return resp.data;
};

export const uploadtoS3 = async (file, url, fileType) => {
  const resp = await axios({
    method: "PUT",
    url,
    data: file,
    headers: {
      "Content-Type": fileType,
    },
    withCredentials: false,
  });

  return resp;
};

const ADD_FILE = gql`
  mutation addFileInfo($input: FileInfoRequest!) {
    addFileInfo(input: $input) {
      code
      success
      message
      data {
        id
        name
        path
        fileType
        sortOrder
        type
        isThumbnail
        isFeatured
        altText
        shareUrl
      }
    }
  }
`;

const GET_FILES = gql`
  query getFilesInfo($filters: FileInfoFilterFields!) {
    getFilesInfo(filters: $filters) {
      totalCount
      skip
      take
      files {
        id
        name
        path
        fileType
        contentType
        type
        uploadedAt
        description
        metadata
        isThumbnail
        isFeatured
        shareUrl
        sortOrder
        productId
        tags {
          tagName
        }
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            zipCode
            state
          }
          projectStages {
            id
            name
            stageType
          }
        }
        spaces {
          id
          name
          location
        }
        projectSpaces {
          id
          spaceNickname
          spaceId
          location
          name
        }
        product {
          id
          createdAt
          name
          brand
        }
        designStyles {
          id
          name
          description
        }
        createdBy {
          fullName
          avatar
          id
        }
        finishes {
          id
          name
        }
        objects {
          id
          description
        }
        altText
      }
    }
  }
`;

const GET_FILE = gql`
  query getFileInfoByID($id: BigInt!) {
    getFileInfoByID(id: $id) {
      id
      name
      path
      fileType
      contentType
      type
      uploadedAt
      description
      metadata
      isThumbnail
      isFeatured
      sortOrder
      productId
      shareUrl
      tags {
        tagName
      }
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          zipCode
          state
        }
        projectStages {
          id
          name
          stageType
        }
      }
      spaces {
        id
        name
        location
      }
      projectSpaces {
        id
        spaceNickname
        spaceId
        location
        name
      }
      product {
        id
        createdAt
        name
        brand
      }
      designStyles {
        id
        name
        description
      }
      createdBy {
        fullName
        avatar
        id
      }
      finishes {
        id
        name
      }
      objects {
        id
        description
      }
      altText
    }
  }
`;

const GET_FILE_SPACES = gql`
  query getFileInfoSpaces($filters: FileInfoFilterFields!) {
    getFileInfoSpaces(filters: $filters) {
      id
      name
    }
  }
`;

const GET_FILE_TYPES = gql`
  query getFileInfoTypes($filters: FileInfoFilterFields!) {
    getFileInfoTypes(filters: $filters) {
      fileType
    }
  }
`;

const GET_FILE_INFO_TAGS = gql`
  query getFileInfoTags($filters: FileInfoFilterFields) {
    getFileInfoTags(filters: $filters)
  }
`;

const GET_FILE_INFO_DESIGN_STYLES = gql`
  query getFileInfoDesignStyles($filters: FileInfoFilterFields) {
    getFileInfoDesignStyles(filters: $filters) {
      id
      name
    }
  }
`;

const GET_FILE_INFO_FINISHES = gql`
  query getFileInfoFinishes($filters: FileInfoFilterFields) {
    getFileInfoFinishes(filters: $filters) {
      id
      name
    }
  }
`;

const GET_FILE_INFO_PRODUCT_TYPES = gql`
  query getFileInfoProductTypes($filters: FileInfoFilterFields) {
    getFileInfoProductTypes(filters: $filters) {
      id
      objectType
      description
    }
  }
`;

const GET_FILE_CONTENT_TYPES = gql`
  query getFileInfoContentTypes($filters: FileInfoFilterFields!) {
    getFileInfoContentTypes(filters: $filters) {
      contentType
    }
  }
`;

const GET_FILE_USERS = gql`
  query getFileInfoUsers($filters: FileInfoFilterFields!) {
    getFileInfoUsers(filters: $filters) {
      id
      fullName
      role
    }
  }
`;

const GET_FILE_EMPTY_FILTERS = gql`
  query getFileInfoEmptyFilters($filters: FileInfoFilterFields!) {
    getFileInfoEmptyFilters(filters: $filters) {
      spaces
      contentType
      tags
      productType
      designStyle
      finish
      fileType
      createdBy
    }
  }
`;

const UPDATE_FILE = gql`
  mutation updateFileInfo($input: FileInfoRequest!) {
    updateFileInfo(input: $input) {
      code
      success
      message
      data {
        id
        uploadedAt
        shareUrl
        spaces {
          id
          name
          location
        }
        projectSpaces {
          id
          spaceNickname
          spaceId
          location
          name
        }
        tags {
          tagName
        }
        designStyles {
          id
          name
          description
        }
        name
        contentType
        sortOrder
        type
        isThumbnail
        isFeatured
        description
        fileType
        productId
        productVariants {
          id
        }
        path
        finishes {
          id
          name
        }
        objects {
          id
          description
        }
        altText
      }
    }
  }
`;

const DELETE_FILE = gql`
  mutation deleteFileInfo($id: BigInt!) {
    deleteFileInfo(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

const UPDATE_FILES = gql`
  mutation updateFilesInfo($input: [FileInfoRequest]!) {
    updateFilesInfo(input: $input) {
      code
      success
      message
      data {
        id
        uploadedAt
        fileType
        shareUrl
        spaces {
          id
          name
          location
        }
        projectSpaces {
          id
          spaceNickname
          spaceId
          location
          name
        }
        tags {
          tagName
        }
        designStyles {
          id
          name
          description
        }
        name
        contentType
        sortOrder
        type
        isThumbnail
        isFeatured
        productId
        path
        objects {
          id
          description
        }
        altText
      }
    }
  }
`;

const DELETE_FILES = gql`
  mutation deleteFilesInfo($input: [FileInfoRequest]!) {
    deleteFilesInfo(input: $input) {
      code
      success
      message
      deletedIds
    }
  }
`;

const UPDATE_THUMBNAIL = gql`
  mutation updateThumbnail($input: FileThumbnailRequest!) {
    updateThumbnail(input: $input) {
      code
      success
      message
      data {
        id
        uploadedAt
        spaces {
          id
          name
          location
        }
        projectSpaces {
          id
          spaceNickname
          spaceId
          location
          name
        }
        tags {
          tagName
        }
        designStyles {
          id
          name
          description
        }
        name
        contentType
        sortOrder
        type
        isThumbnail
        isFeatured
        path
        altText
      }
    }
  }
`;

export const addFile = (input) => {
  return graphQLClient.request(ADD_FILE, { input });
};

export const fetchFiles = async (filters = {}) => {
  return await graphQLClient.request(GET_FILES, { filters });
};

export const fetchFileById = async (id, fileType) => {
  return await graphQLClient.request(GET_FILE, { id, fileType });
};

export const fetchFileTypes = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_TYPES, { filters });
};

export const fetchFileInfoTags = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_INFO_TAGS, { filters });
};

export const fetchFileInfoDesignStyles = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_INFO_DESIGN_STYLES, { filters });
};

export const fetchFileInfoFinishes = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_INFO_FINISHES, { filters });
};

export const fetchFileInfoProductTypes = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_INFO_PRODUCT_TYPES, { filters });
};

export const fetchFileContentTypes = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_CONTENT_TYPES, { filters });
};

export const fetchFileEmptyFilters = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_EMPTY_FILTERS, { filters });
};

export const fetchFileSpaces = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_SPACES, { filters });
};

export const fetchFileUsers = async (filters = {}) => {
  return await graphQLClient.request(GET_FILE_USERS, { filters });
};

export const updateFile = (input) => {
  return graphQLClient.request(UPDATE_FILE, { input });
};

export const deleteFile = (id) => {
  return graphQLClient.request(DELETE_FILE, { id });
};

export const updateFiles = (input) => {
  return graphQLClient.request(UPDATE_FILES, { input });
};

export const deleteFiles = (input) => {
  return graphQLClient.request(DELETE_FILES, { input });
};

export const updateThumbnail = (input) => {
  return graphQLClient.request(UPDATE_THUMBNAIL, { input });
};
