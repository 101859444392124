import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
};

export const messagesSlice = createSlice({
  name: "Messages",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        messages: action.payload.messages,
      };
    },
  },
});

export const { setMessages } = messagesSlice.actions;

export default messagesSlice.reducer;

export const getMessages = (state) => state.messages.messages;
