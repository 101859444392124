import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes } from "../../api";
import { setNotes, getNotes } from "../../notesSlice";

const useNotes = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const notes = useSelector(getNotes);

  const { data, isLoading, isSuccess } = useQuery(["notes", filters], () => fetchNotes(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setNotes(data.getNotes));
  }, [data, isLoading, dispatch]);

  return {
    data: notes,
    dataQuery: data?.getNotes,
    isLoading,
    isSuccess,
  };
};

export default useNotes;
