import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";

import { getRequestQueue, getIsLoading, popFirstRequestFromQueue, updateLoadingStatus } from "./generalActionsSlice";
import REQUEST_MAP from "./requestMap";
/**
 *  Universal component that manages the queue of requests to be sent to the backend
 *  Accepts request objects of type:
 *   {
 *     id: string, // Generated to track dependencies only required for requests that have dependencies
 *     type: "string", // Must be a key in the REQUEST_MAP
 *     input: "object", // The input object to be sent with the request
 *     onDone: "function", // Callback function to which response is returned as is (Does NOT check for success or failure)
 *     onError: "function", // Callback function to which any error is returned as is
 *     dependantOn: "string", // Optional, if the request depends on another request, this is the id of that request
 *     dependantProperty: "string", // Optional, if the request depends on another request,
 *         // this is the property of the response to be used in the input. For example if "id" is passed
 *         // the id in input that matched id passed above will be updated to { id: "response.[type].data.id" }
 *     getDependantValue: "function", // Optional, if dependantProperty value is nested in response this method returns it from response
 *         // If above is not present, dependantProperty is used in response as well
 *   }
 *   All state is maintained in Redux through the generalActions slice.
 */
const GenActionRequestQueue = ({ displaySave }) => {
  const requestQueue = useSelector(getRequestQueue);
  const isLoading = useSelector(getIsLoading);

  const dispatch = useDispatch();

  // Stores responses for requests that send an id
  const [requestsWithDependants, setRequestsWithDependants] = useState({});
  const [showSaveWindow, setShowSaveWindow] = useState(false);

  const processRequest = async () => {
    if (isLoading) {
      return;
    }
    const requestToProcess = cloneDeep(requestQueue[0]);
    if (!requestToProcess) {
      return;
    }
    dispatch(updateLoadingStatus(true));
    const {
      type,
      input,
      onDone,
      dependantOn,
      dependantProperty,
      getDependantValue,
      onError,
      id = null,
    } = requestToProcess;
    const request = REQUEST_MAP[type];
    try {
      let finalInput = input;
      if (dependantOn) {
        const dependantResponse = requestsWithDependants[dependantOn];
        if (!dependantResponse) {
          onError?.("Dependant request not found");
          dispatch(updateLoadingStatus(false));
          return;
        }
        // Update required if property is nested in input
        finalInput[dependantProperty] =
          typeof getDependantValue === "function" // For nested properties
            ? getDependantValue(dependantResponse)
            : dependantResponse[dependantProperty];
      }
      dispatch(popFirstRequestFromQueue());
      const response = await request(finalInput);
      if (id && response) {
        setRequestsWithDependants({
          ...requestsWithDependants,
          [id]: response?.[type]?.data,
        });
      }
      dispatch(updateLoadingStatus(false));
      onDone(response);
    } catch (error) {
      // Temporary log to debug
      console.log("@@ ERROR IN GEN ACT REQUEST QUEUE ========> ", error);
      dispatch(updateLoadingStatus(false));
      if (onError) {
        onError(error);
      }
      return;
    }
  };

  useEffect(() => {
    if (isLoading || !requestQueue.length) return;
    processRequest();
  }, [requestQueue, isLoading]);

  useEffect(() => {
    if (!displaySave) {
      if (!isLoading && showSaveWindow) {
        setShowSaveWindow(false);
      }
      return;
    }

    if (isLoading) {
      setShowSaveWindow(true);
      return;
    }
    if (!requestQueue?.length) {
      setShowSaveWindow(false);
    }
  }, [displaySave, isLoading]);
  return (
    <div>
      {showSaveWindow ? (
        <div className="text-x flex-flex-col fixed right-10 bottom-0 z-[9999999999999] flex h-[64px] w-[416px] flex-row items-center  rounded-t-lg bg-neutral-900 px-5  text-neutral-50 drop-shadow-2xl">
          <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-neutral-50" /> Saving Design Studio
          updates...{" "}
        </div>
      ) : null}
    </div>
  );
};

export default GenActionRequestQueue;
