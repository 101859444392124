import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_METABASE_DASHBOARD = gql`
  query getMetabaseDashboard($dashboardId: Int!) {
    getMetabaseDashboard(dashboardId: $dashboardId) {
      id
      embeddingURL
    }
  }
`;

export const getMetabaseDashboard = async (dashboardId) => {
  return await graphQLClient.request(GET_METABASE_DASHBOARD, { dashboardId });
};
