export const uiSchema = {
  groups: [
    {
      title: "Business",
      properties: ["companyName", "email", "officePhone", "website", "services", "einNumber"],
    },
    {
      title: "Location",
      properties: ["address"],
    },
    {
      title: "Logo",
      properties: ["logo"],
    },
    {
      title: "Contractors License",
      properties: ["license"],
    },
    {
      title: "W9",
      properties: ["w9"],
    },
    {
      title: "Certificate of Insurance",
      properties: ["coi"],
    },
    {
      title: "Workers Compensation",
      properties: ["workersComp"],
    },
    {
      title: "General Liability",
      properties: ["generalLiability"],
    },
    {
      title: "Contact",
      properties: ["contactFirstName", "contactLastName", "contactEmail", "contactPhone", "checkBoxVal", "contact"],
    },
  ],
};
