import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_CONSULTATIONS = gql`
  query getConsultations($filters: ConsultationFilterFields!) {
    getConsultations(filters: $filters) {
      id
      createdAt
      consultationDate
      calendlyLink
      status
      recording
      calendlyEventName
      calendlyEventUri
      calendlyInviteeUri
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
          latitude
          longitude
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      staff {
        id
        avatar
        fullName
        email
      }
      clients {
        id
        avatar
        fullName
        email
      }
    }
  }
`;

const GET_CONSULTATION = gql`
  query getConsultationByID($id: Int!) {
    getConsultationByID(id: $id) {
      id
      createdAt
      consultationDate
      calendlyLink
      calendlyEventName
      calendlyEventUri
      calendlyInviteeUri
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
          latitude
          longitude
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      staff {
        id
        avatar
        fullName
        email
      }
      clients {
        id
        avatar
        fullName
        email
      }
    }
  }
`;

const CREATE_CONSULTATION = gql`
  mutation createConsultation($input: CreateConsultationRequest!) {
    createConsultation(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        consultationDate
        calendlyLink
        status
        calendlyEventName
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
            latitude
            longitude
          }
          thumbnail {
            id
            path
          }
          projectStages {
            id
            name
            stageType
          }
        }
        staff {
          id
          avatar
          fullName
          email
        }
        clients {
          id
          avatar
          fullName
          email
        }
      }
    }
  }
`;

const UPDATE_CONSULTATION = gql`
  mutation updateConsultation($input: UpdateConsultationRequest!) {
    updateConsultation(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        consultationDate
        calendlyLink
        status
        calendlyEventUri
        calendlyEventName
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
            latitude
            longitude
          }
          thumbnail {
            id
            path
          }
          projectStages {
            id
            name
            stageType
          }
        }
        staff {
          id
          avatar
          fullName
          email
        }
        clients {
          id
          avatar
          fullName
          email
        }
      }
    }
  }
`;

export const fetchConsultations = async (filters = {}) => {
  return await graphQLClient.request(GET_CONSULTATIONS, { filters });
};

export const fetchConsultation = async (id) => {
  return await graphQLClient.request(GET_CONSULTATION, { id });
};

export const createConsultation = async (input = {}) => {
  return await graphQLClient.request(CREATE_CONSULTATION, { input });
};

export const updateConsultation = async (input = {}) => {
  return await graphQLClient.request(UPDATE_CONSULTATION, { input });
};
