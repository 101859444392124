import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  materialTypes: [],
};

export const materialTypesSlice = createSlice({
  name: "materialTypes",
  initialState,
  reducers: {
    setMaterialTypes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        materialTypes: action.payload,
      };
    },
  },
});

export const { setMaterialTypes } = materialTypesSlice.actions;

export default materialTypesSlice.reducer;

export const getMaterialTypes = (state) => state.materialTypes.materialTypes;
