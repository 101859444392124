import { TableCells, TableFilters, TableCards } from "@buildappeal/react-component-library";

export const commonActionsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  actionObject: {
    id: "actionObject",
    Header: "Action Object",
    accessor: (originalRow) => `${originalRow.action.verbPresent} ${originalRow.object.description}`,
    Cell: TableCells.TextCell,
  },
  action: {
    id: "action",
    Header: "Action",
    accessor: (originalRow) => `${originalRow.action.verbPresent}`,
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  object: {
    id: "object",
    Header: "Object",
    accessor: (originalRow) => `${originalRow.object.description}`,
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  services: {
    id: "services",
    Header: "Services",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    accessor: (originalRow) => {
      return originalRow?.services?.map((service) => service.name)?.join(", ") || "";
    },
    Cell: (props) => (
      <TableCells.BadgesCell items={props.row.original.services?.map((service) => service.name)} {...props} />
    ),
  },
  stages: {
    id: "stages",
    Header: "Stages",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    accessor: (originalRow) => {
      return originalRow?.stages?.map((service) => service.name)?.join(", ") || "";
    },
    Cell: (props) => <TableCells.BadgesCell items={props.row.original.stages?.map((stage) => stage.name)} {...props} />,
  },
  isMilestone: {
    id: "isMilestone",
    Header: "Is Milestone",
    accessor: (originalRow) => !!originalRow.isMilestone,
    Cell: TableCells.BooleanCell,
  },
  isCriticalPath: {
    id: "isCriticalPath",
    Header: "Is Critical Path",
    accessor: (originalRow) => !!originalRow.isCriticalPath,
    Cell: TableCells.BooleanCell,
  },
  baseLaborTime: {
    id: "baseLaborTime",
    Header: "Base Labor Time",
    accessor: "baseLaborTime",
    Cell: TableCells.TextCell,
  },
  baseLaborUnitType: {
    id: "baseLaborUnitType",
    Header: "Base Labor Unit Type",
    accessor: "baseLaborUnitType",
    Cell: TableCells.TextCell,
  },
  baseLaborCost: {
    id: "baseLaborCost",
    Header: "Base Labor Cost",
    accessor: "baseLaborCost",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  points: {
    id: "points",
    Header: "Points",
    accessor: "points",
    Cell: TableCells.TextCell,
  },
};
