import {
  Button,
  Select,
  Sidebar,
  Textarea,
  TextField,
  Toggle,
  SelectColor,
} from "@buildappeal/react-component-library";
import { uniq, intersectionBy, has, noop } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";
import useFinishes from "@src/features/finishes/hooks/useFinishes";
import useDTAttributes from "@features/attributes/hooks/useDTAttributes";
import useColors from "@src/features/colors/hooks/useColors";
import useDesignStyles from "@features/designs/hooks/useDesignStyles";
import useMaterials from "@features/materials/hooks/useMaterials";
import useSuppliers from "@features/suppliers/hooks/useSuppliers";
import useCollections from "@features/collections/hooks/useCollections";
import useSpaces from "@features/spaces/hooks/useSpaces";
import useDeleteProducts from "./hooks/useDeleteProducts";
import useProductTypes from "./hooks/useProductTypes";
import CreateProductVariant from "./components/CreateProductVariants";
import { getAddRemoveIds } from "./utils";

const ProductsMultiSelect = ({
  editing = false,
  deleting = false,
  loading = false,
  setLoading = noop,
  setNotificationMessage = noop,
  onEditSuccess = noop,
  onDeleteSuccess = noop,
  onError = noop,
  selectedItems = [],
  onClose = noop,
  isVariantCreate = false,
  onVariantCreateSuccess = noop,
}) => {
  const { deleteProductsAsync } = useDeleteProducts();
  const { options: productTypeOptions } = useProductTypes();
  const { suppliersAsOptions } = useSuppliers();
  const { options: designStylesAsOptions, designStylesLoading } = useDesignStyles();
  const { options: materialsAsOptions, isLoading: materialsLoading } = useMaterials();
  const { setActionsState } = useGeneralActionsUpdater();
  const { options: finishesAsOptions, isLoading: finishesLoading } = useFinishes();
  const { options: colorsAsOptions } = useColors();
  const { options: spacesAsOptions, isLoading: spacesLoading } = useSpaces();
  const onlyVariantsSelected = useMemo(
    () => selectedItems.filter((item) => item.isVariant).length === selectedItems.length,
    [selectedItems]
  );
  const { options: dtAttributeOptions, isLoading: dtAttributesLoading } = useDTAttributes();

  const [formState, setFormState] = useState({});
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    const suppliers = intersectionBy(...selectedItems.map((product) => product.suppliers), "id");
    const objects = intersectionBy(...selectedItems.map((product) => product.objects), "id");
    const materials = intersectionBy(...selectedItems.map((product) => product.materials), "id");
    const finishes = intersectionBy(...selectedItems.map((product) => product.finishes), "id");
    const spaces = intersectionBy(...selectedItems.map((product) => product.spaces), "id");
    const dtAttributes = intersectionBy(...selectedItems.map((product) => product.dtAttributes), "id");
    setFormValue({
      suppliers: suppliers?.map((s) => ({
        value: s.id,
        title: s.name,
        supplier: s,
      })),
      objects: objects?.map((obj) => ({
        value: obj.id,
        title: obj.description,
        productType: obj,
      })),
      materials: materials?.map((m) => ({
        value: m.id,
        title: m.name,
        material: m,
      })),
      finishes: finishes?.map((f) => ({
        value: f.id,
        title: f.name,
        finish: f,
        item: f,
      })),
      spaces: spaces?.map((s) => ({
        title: s.name,
        value: s.id,
        space: s,
      })),
      dtAttributes: dtAttributes?.map((dt) => ({
        title: dt.name,
        value: dt.id,
        dtAttribute: dt,
      })),
    });
  }, [selectedItems]);

  const { options: collectionsAsOptions } = useCollections(
    { supplierIds: formState.suppliers?.map((s) => s.value) },
    { disableQuery: !formState.suppliers?.length }
  );

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const handleDeleteProducts = async () => {
    if (loading) return;
    setLoading(true);
    setNotificationMessage("Deleting...");
    try {
      const data = await deleteProductsAsync(selectedItems.map((product) => ({ id: product.id })));

      if (!data.deleteProducts?.success) {
        setNotificationMessage(`Failed to delete products. Try again!`, 2000);
        onError();
        return;
      }
      onDeleteSuccess();
    } catch (err) {
      setNotificationMessage(`Failed to delete products. Try again!`, 2000);
      onError();
    }
  };

  const handleSave = async () => {
    if (loading) return;
    let groupDetails = {
      suppliers: [],
      materials: [],
      finishes: [],
      designStyles: [],
      collections: [],
      colors: [],
      objects: [],
      spaces: [],
      productObjects: has(formState, "contentType") ? formState.contentType : "",
      dtAttributeIds: [],
    };
    setLoading(true);

    const productObjectsId = formState.productObject?.id;
    const objects = formState.objects?.map((object) => {
      groupDetails.objects.push(object.title);
      return object.value;
    });
    const suppliers = formState.suppliers?.map((supplier) => {
      groupDetails.suppliers.push(supplier.title);
      return supplier.title;
    });
    const designStyles = formState.designStyles?.map((item) => {
      groupDetails.designStyles.push(item.title);
      return item.value;
    });
    const materials = formState.materials?.map((item) => {
      groupDetails.materials.push(item.title);
      return item.value;
    });
    const finishes = formState.finishes?.map((item) => {
      groupDetails.finishes.push(item.title);
      return item.value;
    });
    const collections = formState.collections?.map((item) => {
      groupDetails.collections.push(item.title);
      return item.value;
    });
    const colors = formState.colors?.map((item) => {
      groupDetails.colors.push(item.title);
      return item.value;
    });
    const spaces = formState.spaces?.map((item) => {
      groupDetails.spaces.push(item.title);
      return item.value;
    });
    const dtAttributeIds = formState.dtAttributes?.map((item) => {
      groupDetails.dtAttributeIds.push(item.title);
      return item.value;
    });
    let groupTitle = "";
    Object.keys(groupDetails).forEach((key) => {
      if (key === "contentType") {
        groupTitle += groupDetails[key];
      }

      if (key !== "contentType" && groupDetails[key].length > 0) {
        groupTitle += groupDetails[key].join(", ");
      }
    });
    if (has(formState, "brand")) {
      groupTitle += formState.brand;
    }

    if (has(formState, "description")) {
      groupTitle += formState.description;
    }

    if (has(formState, "leadTimeDays")) {
      groupTitle += parseInt(formState.leadTimeDays, 10);
    }

    if (has(formState, "isStocked")) {
      groupTitle += formState.isStocked;
    }

    const productsInput = selectedItems.map((product) => {
      const isVariant = product.isVariant;
      const input = {
        id: isVariant ? product.productId : product.id,
      };

      if (productObjectsId) {
        input.productObjectsId = productObjectsId;
      }

      if (objects) {
        input.objects = objects;
      }

      if (suppliers) {
        input.suppliers = uniq(suppliers);
      }

      if (designStyles) {
        input.designStyles = isVariant
          ? designStyles.filter((designStyleId) => {
              return !(product.designStyles || []).find((designStyle) => designStyle.id === designStyleId);
            })
          : designStyles;
      }

      if (materials) {
        input.materials = isVariant
          ? materials.filter((materialId) => !(product.materials || []).find((mat) => mat.id === materialId))
          : materials;
      }

      if (finishes) {
        input.finishes = isVariant
          ? finishes.filter((finishId) => !(product.finishes || []).find((finish) => finish.id === finishId))
          : finishes;
      }

      if (collections) {
        input.collections = collections;
      }

      if (colors) {
        input.colors = colors;
      }

      if (spaces) {
        input.spaces = spaces;
      }

      if (dtAttributeIds) {
        input.dtAttributeIds = isVariant ? null : getAddRemoveIds(dtAttributeIds, product.dtAttributes, true);
      }

      if (product.isVariant) {
        input.productVariantId = product.variantId;
      }

      if (has(formState, "brand")) {
        input.brand = formState.brand;
      }

      if (has(formState, "description")) {
        input.description = formState.description;
      }

      if (has(formState, "leadTimeDays")) {
        input.leadTimeDays = parseInt(formState.leadTimeDays, 10);
      }

      if (has(formState, "isStocked")) {
        input.isStocked = formState.isStocked;
      }

      return { input, name: product.name, id: product.id, groupTitle };
    });

    setActionsState({
      isOpen: true,
      currentAction: "updateBulkItems",
      triggeredBy: "productsMultiSelect",
      actionProps: {
        itemsInput: productsInput,
        onSuccess: onEditSuccess,
      },
    });
    setLoading(false);
    setShowEdit(false);
    onClose?.();
  };

  useEffect(() => {
    if (!deleting) return;
    handleDeleteProducts();
  }, [deleting]);

  useEffect(() => {
    if (!editing) return;
    setShowEdit(true);
  }, [editing]);

  useEffect(() => {
    if (!isVariantCreate) return;
    setShowEdit(true);
  }, [isVariantCreate]);

  const handleSidebarClose = () => {
    setShowEdit(false);
    onClose();
  };

  return (
    <Sidebar
      isOpen={showEdit}
      onClose={handleSidebarClose}
      classNameContainer={isVariantCreate ? "!max-w-[892px]" : "!max-w-lg"}
    >
      {isVariantCreate ? (
        <CreateProductVariant
          selectedItems={selectedItems}
          onSuccess={() => {
            setShowEdit(false);
            onClose?.();
            onVariantCreateSuccess();
          }}
        />
      ) : (
        <div className="flex h-full w-full flex-col space-y-4 px-6 py-10">
          <h3 className="text-xl">{`Edit ${selectedItems.length} ${
            onlyVariantsSelected ? "Product Variant" : "Product"
          }${selectedItems.length > 1 ? "s" : ""}`}</h3>
          <div className="mt-6 flex flex-1 flex-col gap-4">
            {!onlyVariantsSelected && (
              <>
                <Select
                  className="mt-4"
                  label="Product Types"
                  value={formState.objects}
                  options={productTypeOptions}
                  onChange={(value) => {
                    setFormValue({ objects: value });
                  }}
                  multiple
                />
                <Select
                  label="Suppliers"
                  value={formState.suppliers || []}
                  options={suppliersAsOptions}
                  onChange={(value) => {
                    setFormValue({ suppliers: value });
                  }}
                  multiple
                />
                {!!collectionsAsOptions.length && (
                  <Select
                    label="Collections"
                    value={formState.collections || []}
                    options={collectionsAsOptions}
                    onChange={(value) => {
                      setFormValue({ collections: value });
                    }}
                    multiple
                  />
                )}
                <TextField
                  label="Brand"
                  value={formState.brand || ""}
                  onChange={({ target: { value } }) => setFormValue({ brand: value })}
                />
                <Textarea
                  label="Description"
                  value={formState.description || ""}
                  onChange={({ target: { value } }) => setFormValue({ description: value })}
                />
                <TextField
                  type="number"
                  label="Lead Time (days)"
                  value={formState.leadTimeDays || ""}
                  onChange={({ target: { value } }) => setFormValue({ leadTimeDays: value })}
                />
                <Toggle
                  label="In Stock"
                  checked={formState.isStocked || false}
                  onChange={(newValue) => setFormValue({ isStocked: newValue })}
                  position="left"
                />
              </>
            )}

            <Select
              label="Design Style"
              value={formState.designStyles || []}
              options={designStylesAsOptions}
              onChange={(value) => {
                setFormValue({ designStyles: value });
              }}
              multiple
              isLoading={designStylesLoading}
            />
            <Select
              label="Material"
              value={formState.materials || []}
              options={materialsAsOptions}
              onChange={(value) => {
                setFormValue({ materials: value });
              }}
              isLoading={materialsLoading}
              multiple
            />
            <Select
              label="Finish"
              value={formState.finishes || []}
              options={finishesAsOptions}
              onChange={(value) => {
                setFormValue({ finishes: value });
              }}
              isLoading={finishesLoading}
              multiple
            />
            <SelectColor
              label="Color"
              value={formState.colors || []}
              options={colorsAsOptions}
              onChange={(value) => {
                setFormValue({ colors: value });
              }}
              sortBy="az"
              multiple
            />
            <Select
              label="Attributes"
              value={formState.dtAttributes || []}
              options={dtAttributeOptions}
              onChange={(value) => {
                setFormValue({ dtAttributes: value });
              }}
              isLoading={dtAttributesLoading}
              multiple
            />
            {!onlyVariantsSelected && (
              <Select
                label="Space"
                value={formState.spaces || []}
                options={spacesAsOptions}
                onChange={(value) => {
                  setFormValue({ spaces: value });
                }}
                isLoading={spacesLoading}
                multiple
              />
            )}
          </div>
          <div className="flex w-full">
            <Button
              isLoading={loading}
              label={loading ? "Saving..." : "Save"}
              className="w-full !rounded-sm"
              onClick={handleSave}
            />
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default ProductsMultiSelect;
