import React, { useState, useMemo, useEffect } from "react";
import { SelectProject, Button, Select } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";

import { getAddressFromPlaces, formatCurrency } from "@src/utils/common";
import useProjects from "@features/projects/hooks/useProjects";
import useServices from "@features/services/hooks/useServices";
import ProposalCreatedView from "./components/ProposalCreated";
import ApplyProduct from "./components/ApplyProduct";
import ApplyProductItem from "./components/ApplyProductItem";
import { ApplyProductType } from "./components/utils";
import useAddProposal from "./hooks/useAddProposal";

const AddProposal = ({ onClose }) => {
  const [proposalProject, setProposalProject] = useState(null);
  const [proposalSpacesInput, setProposalSpacesInput] = useState([]);
  const [proposalState, setProposalState] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [newProposal, setNewProposal] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { options: projectsAddressAsOptions, isLoading: projectOptionLoading } = useProjects();
  const { data: services, isLoading: servicesLoading } = useServices(
    {
      spaceIds: proposalSpacesInput.map((item) => item.spaceId).filter(Boolean),
    },
    {
      disableQuery: !proposalSpacesInput?.map((item) => item.spaceId)?.filter(Boolean)?.length,
    }
  );
  const { addProposalAsync } = useAddProposal();

  const handleSubmit = async () => {
    const input = {
      projectId: proposalProject?.id,
      type: "Services",
      proposalItems: [],
    };
    const selectedProjectSpaceIds = Object.keys(proposalState);
    if (!selectedProjectSpaceIds.length) {
      return;
    }
    setSubmitLoading(true);
    selectedProjectSpaceIds.forEach((projectSpaceId) => {
      const spaceServiceItems = proposalState[projectSpaceId];
      const spaceDetails = proposalSpacesInput.find((item) => +item.value === +projectSpaceId);
      Object.keys(spaceServiceItems).forEach((serviceId) => {
        if (spaceServiceItems[serviceId].isSelected) {
          input.proposalItems.push({
            projectSpaceId: +projectSpaceId,
            serviceId: +serviceId,
            name: spaceServiceItems[serviceId].name,
            cost: spaceServiceItems[serviceId].baseCost,
            spaceName: spaceDetails.title,
          });
        }
      });
    });

    const proposalResp = await addProposalAsync(input);
    if (proposalResp?.createProposal?.success) {
      setNewProposal(proposalResp?.createProposal?.data);
      setShowSuccessView(true);
    }
    setSubmitLoading(false);
  };

  const spaceOptions = useMemo(() => {
    if (!proposalProject?.id) return [];
    let finalSpaces = proposalProject.spaces?.map((space) => ({
      value: space.id,
      title: space.spaceNickname || space.name,
      spaceId: space.spaceId,
      sortOrder: space.sortOrder,
      spaceName: space.name,
    }));
    return finalSpaces.sort((a, b) => a.sortOrder - b.sortOrder);
  }, [proposalProject]);

  const updateServiceSelection = (projectSpaceId, serviceId, selected, cost) => {
    const newProposalState = { ...proposalState };
    newProposalState[projectSpaceId] = {
      ...newProposalState[projectSpaceId],
      [serviceId]: {
        ...newProposalState[projectSpaceId]?.[serviceId],
        isSelected: selected,
      },
    };
    setProposalState(newProposalState);
    const updatedCost = selected ? totalCost + cost : totalCost - cost;
    setTotalCost(updatedCost);
  };

  const getTotalCostFromProposalState = (proposalStateValue) => {
    let updatedCost = 0;
    Object.keys(proposalStateValue).forEach((projectSpaceId) => {
      Object.keys(proposalStateValue[projectSpaceId]).forEach((serviceId) => {
        if (proposalStateValue[projectSpaceId][serviceId].isSelected) {
          updatedCost += proposalStateValue[projectSpaceId][serviceId].baseCost;
        }
      });
    });
    return updatedCost;
  };

  const onToggleAll = (projectSpaceId, toggleState) => {
    const updatedProposalState = { ...proposalState };
    if (!updatedProposalState[projectSpaceId]) {
      updatedProposalState[projectSpaceId] = {};
      const relevantSpaceInput = proposalSpacesInput.find((item) => +item.value === +projectSpaceId);
      if (!relevantSpaceInput) {
        return;
      }
      const relevantServices = services?.find((item) => +item.spaceId === +relevantSpaceInput.spaceId);
      if (!relevantServices?.length) {
        return;
      }
      relevantServices.forEach((service) => {
        if (!updatedProposalState[projectSpaceId][service.id]) {
          const serviceForSpace = service.spaces.find((item) => item.spaceId === +relevantSpaceInput.spaceId);
          updatedProposalState[projectSpaceId][service.id] = {
            name: service.name,
            id: service.id,
            baseCost: serviceForSpace.baseCost,
            isSelected: toggleState,
          };
        }
      });
    } else {
      Object.keys(updatedProposalState[projectSpaceId]).forEach((serviceId) => {
        updatedProposalState[projectSpaceId][serviceId].isSelected = toggleState;
      });
    }
    const updatedCost = getTotalCostFromProposalState(updatedProposalState);
    setTotalCost(updatedCost);
    setProposalState(updatedProposalState);
  };

  useEffect(() => {
    if (!services?.length || servicesLoading || !proposalSpacesInput?.length) {
      if (!proposalSpacesInput?.length && Object.keys(proposalState).length) {
        setProposalState({});
        setTotalCost(0);
      }
      return;
    }
    let updatedProposalState = { ...proposalState };

    proposalSpacesInput.forEach((spaceInput) => {
      if (!updatedProposalState[spaceInput.value]) {
        updatedProposalState[spaceInput.value] = {};
      }
      const relevantServices = services.filter((service) =>
        service.spaces.some((item) => item.spaceId === spaceInput.spaceId)
      );
      if (relevantServices?.length) {
        relevantServices.forEach((service) => {
          if (!updatedProposalState[spaceInput.value][service.id]) {
            const serviceForSpace = service.spaces.find((item) => item.spaceId === spaceInput.spaceId);
            updatedProposalState[spaceInput.value][service.id] = {
              name: service.name,
              id: service.id,
              baseCost: serviceForSpace.baseCost,
              isSelected: false,
            };
          }
        });
      }
    });
    const spacesInProposalState = Object.keys(updatedProposalState);

    if (proposalSpacesInput?.length < spacesInProposalState?.length) {
      let updatedCost = totalCost;
      spacesInProposalState.forEach((spaceId) => {
        if (!proposalSpacesInput?.find((item) => +item.value === +spaceId) && updatedProposalState[spaceId]) {
          // Deduct costs
          Object.keys(updatedProposalState[spaceId]).forEach((serviceId) => {
            if (updatedProposalState[spaceId][serviceId].isSelected) {
              updatedCost -= updatedProposalState[spaceId][serviceId].baseCost;
            }
          });
          delete updatedProposalState[spaceId];
        }
      });
      setTotalCost(updatedCost);
    }

    setProposalState(updatedProposalState);
  }, [services, servicesLoading, proposalSpacesInput]);

  if (projectOptionLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (showSuccessView && newProposal?.id) {
    return (
      <div className="p-5">
        <ProposalCreatedView proposal={newProposal} onClose={onClose} />
      </div>
    );
  }

  return (
    <>
      <div className="h-full">
        <div className="flex flex-col p-5">
          <SelectProject
            className="mt-4"
            label="Project"
            isLoading={projectOptionLoading}
            value={{ title: getAddressFromPlaces(proposalProject?.places), value: proposalProject?.id || "" }}
            options={projectsAddressAsOptions}
            onChange={(opt) => {
              if (!opt?.project?.id) return;

              setProposalProject(opt?.project);
            }}
          />
          <Select
            className="mt-4"
            label="Select Space"
            value={proposalSpacesInput}
            options={spaceOptions}
            onChange={(opt) => {
              setProposalSpacesInput(opt);
            }}
            multiple
          />
        </div>
        {servicesLoading ? (
          <div className="flex h-[62px] w-full items-center justify-center">
            <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
          </div>
        ) : null}
        {Object.keys(proposalState || {}).length ? (
          <div className="grid grid-cols-2 gap-x-2 p-5">
            {Object.keys(proposalState).map((projectSpaceId) => {
              const proposalSpaceItem = proposalState[projectSpaceId];
              const spaceValue = proposalSpacesInput.find((item) => +item.value === +projectSpaceId);
              return (
                <ApplyProduct
                  spaceName={spaceValue?.spaceName}
                  type={ApplyProductType.SqFt}
                  onToggleAll={(toggleState) => onToggleAll(projectSpaceId, toggleState)}
                  spaceNickname={spaceValue?.title}
                >
                  {Object.keys(proposalSpaceItem).map((serviceId) => {
                    const serviceItem = proposalSpaceItem[serviceId];
                    return (
                      <ApplyProductItem
                        title={serviceItem.name}
                        cost={formatCurrency(serviceItem.baseCost)}
                        isSelected={serviceItem.isSelected}
                        type={ApplyProductType.SqFt}
                        onChange={(value) =>
                          updateServiceSelection(projectSpaceId, serviceId, value, serviceItem.baseCost)
                        }
                      />
                    );
                  })}
                </ApplyProduct>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="sticky bottom-0 items-center border-t border-neutral-200 bg-white  px-10 pt-5 pb-6 dark:border-midnightborder">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <span className="text-md text-neutral-700 dark:text-neutral-400">Total</span>
            <span className="ml-2 text-md text-neutral-700 dark:text-neutral-400">{formatCurrency(totalCost)}</span>
          </div>
          <Button
            className=""
            isLoading={submitLoading}
            onClick={handleSubmit}
            label="Submit"
            disabled={submitLoading}
          />
        </div>
      </div>
    </>
  );
};

export default AddProposal;
