import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      email
      role
      lastLogin
      clients {
        id
        fullName
        firstName
        lastName
        email
        phone
        avatar
        type
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
      }
      staff {
        id
        fullName
        firstName
        lastName
        email
        phone
        avatar
        title
      }
      partners {
        id
        email
        avatar
        city
        state
        addressLine1
        zipCode
        companyName
      }
    }
  }
`;
const GET_NOTIFICATIONS = gql`
  query getNotifications($filters: NotificationFilterFields!) {
    getNotifications(filters: $filters) {
      id
      createdAt
      isProfile
      user {
        id
        uuid
        firstName
        lastName
        email
      }
      emailTaskAssigned
      emailWeeklyDigest
      emailDailyDigest
      notificationSms
      notificationEmails
    }
  }
`;

const CREATE_OR_UPDATE_NOTIFICATION = gql`
  mutation createOrUpdateNotification($input: UpsertNotificationRequest!) {
    createOrUpdateNotification(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        isProfile
        user {
          id
          uuid
          firstName
          lastName
          email
        }
        emailTaskAssigned
        emailWeeklyDigest
        emailDailyDigest
        notificationSms
        notificationEmails
      }
    }
  }
`;
export const fetchUsers = async (filters = {}) => {
  return await graphQLClient.request(GET_USERS, { filters });
};

export const fetchNotifications = async (filters = {}) => {
  return await graphQLClient.request(GET_NOTIFICATIONS, { filters });
};

export const createOrUpdateNotification = async (input) => {
  return await graphQLClient.request(CREATE_OR_UPDATE_NOTIFICATION, { input });
};
