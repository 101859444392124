import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

const initialState = {
  projects: [],
  leadProjects: [],
  projectNotes: [],
  projectsById: {},
  leadProjectsById: {},
  filesProjectSpaceObjects: [],
  projectAdjacentSpaces: [],
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projects: action.payload,
        projectsById: merge(cloneDeep(originalState.projectsById), keyBy(action.payload, "id")),
      };
    },
    setLeadProjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        leadProjects: action.payload,
        leadProjectsById: merge(cloneDeep(originalState.projectsById), keyBy(action.payload, "id")),
      };
    },
    setProjectDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projectsById: {
          ...originalState.projectsById,
          [action.payload.id]: {
            ...(originalState.projectsById[action.payload.id] || {}),
            ...action.payload,
          },
        },
      };
    },
    setProjectActivites: (state, action) => {
      const originalState = original(state);

      const { id, ...data } = action.payload;
      return {
        ...originalState,
        projectsById: {
          ...originalState.projectsById,
          [action.payload.id]: {
            ...(originalState.projectsById[action.payload.id] || {}),
            ...data,
          },
        },
      };
    },
    addTeamMember: (state, action) => {
      const originalState = original(state);

      return {
        ...originalState,
        projectsById: {
          ...originalState.projectsById,
          [action.payload.projectId]: {
            ...originalState.projectsById[action.payload.projectId],
            staff: [...originalState.projectsById[action.payload.projectId].staff, action.payload.staff],
          },
        },
      };
    },
    setFilesProjectSpaceObjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        filesProjectSpaceObjects: action.payload,
      };
    },
    setProjectNotes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projectNotes: action.payload,
      };
    },
    setProjectAdjacentSpaces: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projectAdjacentSpaces: action.payload,
      };
    },
  },
});

export const {
  setProjects,
  setProjectDetail,
  addTeamMember,
  setProjectActivites,
  setProjectNotes,
  setFilesProjectSpaceObjects,
  setProjectAdjacentSpaces,
  setLeadProjects,
} = projectsSlice.actions;

export const selectProjects = (state) => state.projects.projects;
export const selectProjectsById = (state) => state.projects.projectsById;
export const selectLeadProjects = (state) => state.projects.leadProjects;
export const selectLeadProjectsById = (state) => state.projects.leadProjectsById;

export const selectProjectById = (id) => (state) => state.projects.projectsById[id];
export const selectFilesProjectSpaceObjects = (state) => state.projects.filesProjectSpaceObjects;
export const selectProjectNotes = (state) => state.projects.projectNotes;
export const selectProjectAdjacentSpaces = (state) => state.projects.projectAdjacentSpaces;

export default projectsSlice.reducer;
