import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { LoadingSpinner } from "@buildappeal/react-component-library";
import { setCurrentUser, setIsLoggedIn, setUserEmail } from "@src/features/auth/authSlice";
import { logout } from "./api";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useQuery("logout", logout, {
    onSuccess: () => {
      dispatch(setIsLoggedIn(false));
      dispatch(setUserEmail(""));
      dispatch(setCurrentUser(null));
      navigate("/login");
    },
    onError: (error) => {
      window.alert(error.message);
      console.error(error);
    },
  });

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="w-52">
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default Logout;
