import React, { useState, useEffect } from "react";
import { Button, Modal, TextField } from "@buildappeal/react-component-library";

const FileEditAltTextModal = ({ isOpen, onClose, altText, onEdit }) => {
  const [altTextInput, setAltTextInput] = useState(altText);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setAltTextInput(altText);
  }, [altText]);

  const handleEdit = async () => {
    try {
      setIsLoading(true);
      await onEdit?.("altText", altTextInput);
      onClose();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Edit Alt Text">
      <div className="p-10">
        <TextField value={altTextInput} onChange={(e) => setAltTextInput(e.target.value)} label="Alt Text" />

        {error && <p className="my-3 text-xs text-red-700">{error}</p>}

        <div className="mt-7 flex items-center space-x-2">
          <Button
            label={isLoading ? "Saving ..." : "Save"}
            onClick={handleEdit}
            disabled={isLoading}
            isLoading={isLoading}
          />
          <Button label="Cancel" onClick={onClose} appearance="tertiary" />
        </div>
      </div>
    </Modal>
  );
};

export default FileEditAltTextModal;
