import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_MEDIA_COORDINATES = gql`
  query getMediaCoordinates($filters: MediaCoordinateFilterFields!) {
    getMediaCoordinates(filters: $filters) {
      id
      mediaId
      projectSpaceObjectId
      xCoordinate
      yCoordinate
      projectSpaceObject {
        id
        nickname
      }
    }
  }
`;

const CREATE_MEDIA_COORDINATE = gql`
  mutation createMediaCoordinate($input: MediaCoordinateRequest!) {
    createMediaCoordinate(input: $input) {
      code
      success
      message
      data {
        id
        mediaId
        projectSpaceObjectId
        xCoordinate
        yCoordinate
        projectSpaceObject {
          id
          nickname
        }
      }
    }
  }
`;

const DELETE_MEDIA_COORDINATE = gql`
  mutation deleteMediaCoordinate($id: Int!) {
    deleteMediaCoordinate(id: $id) {
      code
      success
      message
    }
  }
`;

export const fetchMediaCoordinates = async (filters = {}) => {
  return await graphQLClient.request(GET_MEDIA_COORDINATES, { filters });
};

export const createMediaCoordinate = async (input = {}) => {
  return await graphQLClient.request(CREATE_MEDIA_COORDINATE, { input });
};

export const deleteMediaCoordinate = async (id) => {
  return await graphQLClient.request(DELETE_MEDIA_COORDINATE, { id });
};
