const formSchema = {
  title: "Create Bill",
  properties: {
    project: {
      type: "project",
      label: "Project",
      required: true,
    },
    billNumber: {
      type: "text",
      label: "Bill Number",
    },
    dueDate: {
      type: "datetime",
      label: "Due Date",
    },
    billDate: {
      type: "datetime",
      label: "Bill Date",
      required: true,
    },
    total: {
      type: "currency",
      label: "Total",
      required: true,
    },
    partner: {
      type: "select",
      label: "Partner",
      required: true,
    },
    documents: {
      type: "file",
      label: "Documents",
      acceptedFileTypes: [".pdf", ".docx", "image/jpeg", "image/png", "video/*", "image/heic", "image/webp"],
      multiple: true,
      maxFiles: 25,
      handleSelectedFiles: () => {},
      required: true,
    },
  },
};

export default formSchema;
