import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestQueue: [],
  isLoading: false,
  isError: false,
};

export const generalActionsSlice = createSlice({
  name: "generalActions",
  initialState,
  reducers: {
    pushToQueue: (state, action) => {
      const originalState = original(state);
      const { requestQueue } = originalState;
      return {
        ...originalState,
        requestQueue: [...requestQueue, action.payload],
      };
    },
    updateLoadingStatus: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        isLoading: action.payload,
      };
    },
    updateErrorStatus: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        isError: action.payload,
      };
    },
    popFirstRequestFromQueue: (state) => {
      const originalState = original(state);
      const { requestQueue } = originalState;
      return {
        ...originalState,
        requestQueue: requestQueue.slice(1),
      };
    },
  },
});

export const { pushToQueue, updateLoadingStatus, updateErrorStatus, popFirstRequestFromQueue } =
  generalActionsSlice.actions;

export default generalActionsSlice.reducer;

export const getRequestQueue = (state) => state.generalActions.requestQueue;
export const getIsLoading = (state) => state.generalActions.isLoading;
export const getIsError = (state) => state.generalActions.isError;
