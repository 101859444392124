import React, { useRef, useEffect } from "react";

const ContextMenu = ({ openContext, onCloseContext, children }) => {
  const contextMenu = useRef();

  useEffect(() => {
    window.addEventListener("click", outsideContextMenuHandler);
    return () => {
      window.removeEventListener("click", outsideContextMenuHandler);
    };
  }, []);

  const outsideContextMenuHandler = (event) => {
    if (openContext.show && !contextMenu?.current?.contains(event.target)) {
      onCloseContext();
    }
  };
  if (openContext.show) {
    return (
      <div
        ref={contextMenu}
        className="fixed z-50"
        style={{ top: `${openContext.offsetY}px`, left: `${openContext.offsetX}px` }}
      >
        {children}
      </div>
    );
  }
  return null;
};

export default ContextMenu;
