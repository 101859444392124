const uiSchema = {
  groups: [
    {
      properties: ["project", "total", "dueDate", "billDate", "total", "partner", "documents"],
      isLast: true,
    },
  ],
};

export default uiSchema;
