import React, { useEffect, useState } from "react";
import { FileUpload, Modal } from "@buildappeal/react-component-library";
import { isEmpty } from "lodash";

import { areItemsAlreadyInQueue } from "@src/utils/components/GeneralActions/utils";
import UploadProjectFile from "@features/projects/UploadProjectFile";
import isMediaFile from "@src/utils/isMedia";
import useMultiFilesUpload from "@features/files/hooks/useMultiFilesUpload";

import UploadFileForm from "../UploadFile";
import { getFileKey } from "../utils";
import UploadFileWindow from "./UploadFileWindow";

const GenActionUploadFiles = ({
  isOpen,
  item,
  onSuccess,
  onClose,
  triggeredBy,
  hideProjectDetails,
  processFiles,
  isOverlayDrag,
  additionalFields = {},
  dragFiles = [],
  dragRejectedFiles = [],
}) => {
  const { startUpload, uploadStatus, rejectFiles } = useMultiFilesUpload();

  const [uploadingQueue, setUploadingQueue] = useState([]);
  const [isCurrentFilesSetUpload, setIsCurrentFilesSetUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadResults, setUploadResults] = useState({});
  const [showAddDetails, setShowAddDetails] = useState(false);

  const processFilesForUpload = (finalFiles) => {
    if (finalFiles?.length === 0) {
      return [];
    }

    const finalFilesList = (finalFiles || []).map((file) => {
      const fileKey = getFileKey(triggeredBy, item);
      let addlFields = {};
      if (triggeredBy === "project" && item?.id) {
        addlFields.projectId = item.id;
      }
      return {
        key: fileKey,
        file: file.file?.type ? file.file : file,
        type: isMediaFile(file.type || file.file?.type || "") ? "media" : file.type === "cad" ? "cad" : "document",
        ...addlFields,
        ...additionalFields,
      };
    });
    return finalFilesList;
  };

  const onUploadClick = async (newFiles) => {
    setIsUploading(true);
    setIsCurrentFilesSetUpload(true);
    onClose({
      isOpen: false,
    });
    const finalFiles = newFiles?.length ? newFiles : dragFiles || [];
    setUploadingQueue((prevQ) => [...(prevQ || []), ...finalFiles]);
    const finalFilesList =
      typeof processFiles === "function" ? processFiles(finalFiles, item) : processFilesForUpload(finalFiles);
    setIsCurrentFilesSetUpload(false);

    const results = await startUpload(finalFilesList);
    setUploadResults((prevResults) => ({
      uploadCount: (prevResults.uploadCount || 0) + results.uploadCount,
      uploadedFiles: [...(prevResults.uploadedFiles || []), ...results.uploadedFiles],
      errorFileData: [...(prevResults.errorFileData || []), ...results.errorFileData],
    }));
  };

  const onRejectedFiles = (rejectedFiles) => {
    setIsModalOpen(false);
    setIsUploading(true);
    rejectFiles(rejectedFiles);
  };

  const onUploadFinishedOrClose = () => {
    setIsUploading(false);
    onSuccess(uploadResults, triggeredBy, item?.id);
    setShowAddDetails(true);
    setIsModalOpen(true);
  };

  const resetAction = () => {
    setUploadResults([]);
    setIsModalOpen(false);
    onClose({
      isOpen: false,
      currentAction: null,
      triggeredBy: null,
      actionProps: null,
    });
    onSuccess(uploadResults, triggeredBy, item?.id);
  };

  useEffect(() => {
    if (isOpen !== isModalOpen) {
      setIsModalOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(uploadResults)) {
      onSuccess(uploadResults, triggeredBy, item?.id);
    }
  }, [uploadResults]);

  useEffect(() => {
    if (!isOverlayDrag || !dragFiles?.length || areItemsAlreadyInQueue(uploadingQueue, dragFiles)) {
      return;
    }

    onUploadClick();
  }, [isOverlayDrag, dragFiles]);

  useEffect(() => {
    if (!isOverlayDrag || !dragRejectedFiles?.length) {
      return;
    }

    onRejectedFiles(dragRejectedFiles);
  }, [isOverlayDrag, dragRejectedFiles]);

  return (
    <>
      {isUploading && !isEmpty(uploadStatus) ? (
        <UploadFileWindow
          totalFilesCount={uploadingQueue?.length}
          fileUploadStatus={uploadStatus}
          onDone={onUploadFinishedOrClose}
          onCloseClick={resetAction}
          triggeredBy={triggeredBy}
        />
      ) : null}
      {isModalOpen ? (
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            if (showAddDetails) {
              resetAction();
              return;
            }
            setIsModalOpen(false);
            onClose({
              isOpen: false,
            });
          }}
          title=" "
          titleClassName="h-6"
          classNameBg="overflow-y-hidden bg-white h-full min-h-[500px] inline-flex flex-col relative"
        >
          <>
            {!showAddDetails ? (
              <div className="w-full overflow-y-auto">
                <div className="w-full flex-1 overflow-y-auto px-6 pt-8 pb-2">
                  <span className="text-lg text-neutral-800">Add Files</span>
                  <FileUpload
                    handleAcceptedFiles={(files) => {
                      onUploadClick(files);
                    }}
                    handleRejectedFiles={onRejectedFiles}
                    acceptedFileTypes={[
                      "image/jpeg",
                      "image/png",
                      ".pdf",
                      ".dwg",
                      ".docx",
                      "video/*",
                      "audio/*",
                      "image/heic",
                      "image/webp",
                      ".dwg",
                    ]}
                    multiple
                    isDisabled={isCurrentFilesSetUpload}
                    maxFiles={50}
                    uploadResults={uploadResults}
                    className="mx-auto py-4"
                  />
                </div>
              </div>
            ) : null}
            {showAddDetails && !hideProjectDetails ? (
              <UploadProjectFile
                project={item}
                onSuccess={() => {
                  resetAction();
                }}
                dragUploadedResults={uploadResults}
              />
            ) : null}
            {showAddDetails && hideProjectDetails ? (
              <UploadFileForm
                onSuccess={() => {
                  resetAction();
                }}
                dragUploadedResults={uploadResults}
              />
            ) : null}
          </>
        </Modal>
      ) : null}
    </>
  );
};

export default GenActionUploadFiles;
