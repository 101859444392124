import { useEffect, useState } from "react";
import { SelectPeople, SelectProject, Select, Button } from "@buildappeal/react-component-library";
import { isEmpty } from "lodash";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { useNavigate } from "react-router-dom";

import useToast from "@src/utils/hooks/useToast";
import useCalendlyEvents from "@src/features/consultations/hooks/useCalendlyEvents";
import useProjects from "@src/features/projects/hooks/useProjects";
import useStaff from "@features/staff/hooks/useStaff";
import { getAddressFromPlaces, getProjectLink } from "@src/utils/common";
import { getConsultationEvent, getEventInvitee } from "@utils/api/calendlyApi";
import useAddConsultation from "./hooks/useAddConsultation";
import { isCalendlyEvent } from "./utils";
import AddConsultationSuccess from "./components/AddConsultationSuccess";

const initialFormState = {
  project: undefined,
  client: undefined,
  staff: undefined,
  eventType: null,
};

const AddConsultation = ({ onClose, project, isOpen, onSuccess }) => {
  const { options: projectsAddressAsOptions, isLoading: projectLoading } = useProjects(
    {},
    { disableQuery: !!project?.id }
  );
  const { addToast } = useToast();
  const { createConsultationAsync } = useAddConsultation();
  const { data: calendlyEvents, options: eventOptions, isLoading: eventsLoading } = useCalendlyEvents();
  const navigate = useNavigate();
  const { options: staffAsOptions, isLoading: staffOptsLoading } = useStaff();

  const [formState, setFormState] = useState({ ...initialFormState, project });
  const [guestDropdownOptions, setGuestDropdownOptions] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [newConsultation, setNewConsultation] = useState(null);

  const handleSubmit = async () => {
    const requiredEvent = formState.eventType?.event;
    const schedulePageUrl = requiredEvent?.scheduling_url;
    if (!schedulePageUrl) {
      return;
    }
    let calendlyParams = "?";
    const clientGuest = formState.guests.find((guest) => guest.type === "Client");
    const client = clientGuest?.client;
    calendlyParams +=
      "guests=" +
      formState.guests
        .map((guest) => {
          if (guest.type === "Staff" || guest.client?.id !== client?.id) {
            return guest.client?.email || guest.staff?.email;
          }
          return null;
        })
        .filter(Boolean)
        .join(",");

    window?.Calendly.initPopupWidget({
      url: schedulePageUrl + calendlyParams,
      prefill: {
        email: client?.email,
        firstName: client?.firstName,
        lastName: client?.lastName,
        name: client.fullName ? client.fullName : `${client.client?.firstName || ""} ${client.client?.lastName || ""}`,
      },
    });

    setFormLoading(true);
    return;
  };

  async function callGetConsultationEvents(ff) {
    try {
      const uri = ff.payload.event.uri;
      const inviteeUri = ff.payload.invitee.uri;
      const [res, invitee] = await Promise.all([getConsultationEvent(uri), getEventInvitee(inviteeUri)]);
      if (!isEmpty(res?.data?.resource) && !isEmpty(invitee?.data)) {
        const { location } = res?.data?.resource;
        let data = {
          projectId: project?.id ? project.id : formState.project?.id,
          clientIds: formState.guests
            ?.map((guest) => {
              if (guest.type === "Client") {
                return guest.client.id;
              }
              return null;
            })
            .filter(Boolean),
          staffIds: formState.guests
            ?.map((guest) => {
              if (guest.type === "Staff" || guest.staff?.id) {
                return guest.staff.id;
              }
              return null;
            })
            .filter(Boolean),
          calendlyLink: location.join_url,
          calendlyEventUri: uri,
          calendlyInviteeUri: inviteeUri,
          consultationDate: res?.data?.resource?.start_time,
          status: "Scheduled",
          calendlyEventName: formState.eventType?.title,
        };

        const resp = await createConsultationAsync(data);
        window?.Calendly?.closePopupWidget();
        if (resp.createConsultation?.success) {
          setShowSuccess(true);
          setNewConsultation(resp.createConsultation?.data);
          onSuccess?.();
        }
        setFormLoading(false);
      }
    } catch (e) {
      addToast(e.message);
    }
  }

  const messageEventHandler = async function (e) {
    if (isCalendlyEvent(e) && isOpen) {
      const ff = e.data;
      if (ff.event === "calendly.event_scheduled" && ff.payload.event.uri && calendlyEvents?.length) {
        await callGetConsultationEvents(ff);
      }
    }
  };

  const setFormValue = (newValues) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newValues,
    }));
  };

  const handleLaunchConsultation = () => {
    if (!newConsultation?.project?.id) {
      return;
    }
    navigate(`${getProjectLink(newConsultation.project)}&showCustomLeft=true&consultationId=${newConsultation.id}`);
  };

  useEffect(() => {
    if (!isEmpty(formState.guests) && calendlyEvents?.length && isOpen) {
      window.addEventListener("message", messageEventHandler);
    }
    return () => window.removeEventListener("message", messageEventHandler);
  }, [formState, isOpen]);

  useEffect(() => {
    if (isEmpty(formState.project) || staffOptsLoading) {
      return;
    }
    const newClientOptions = formState.project.clients?.map((client) => ({
      title: client.fullName,
      value: client.id,
      type: "Client",
      client,
    }));
    const projectStaffIds = [];
    const newStaffOptions = formState.project.staff?.map((staff) => {
      projectStaffIds.push(staff?.id);
      return { title: staff.fullName, value: staff.id, type: "Staff", staff };
    });
    const newOptions = [
      ...newClientOptions,
      ...newStaffOptions,
      ...(staffAsOptions || [])
        .map((staffOpt) => {
          if (!projectStaffIds.includes(staffOpt.value)) {
            return { ...staffOpt, type: "Staff" };
          }
        })
        .filter(Boolean),
    ];
    setGuestDropdownOptions(newOptions);
    setFormValue({ guests: [...newClientOptions, ...newStaffOptions] });
  }, [formState?.project, staffAsOptions, staffOptsLoading]);

  if (formLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (showSuccess && newConsultation?.id) {
    return (
      <AddConsultationSuccess
        guests={formState.guests}
        consultation={newConsultation}
        onClose={onClose}
        onLaunchConsultation={handleLaunchConsultation}
      />
    );
  }

  return (
    <div className="relative h-full px-10">
      <div className="my-5 text-lg">Create Event</div>
      <Select
        options={eventOptions}
        onChange={(option) => {
          setFormValue({ eventType: option });
        }}
        label="Calendly Event Type"
        value={{ title: formState.eventType?.title || "", value: formState.eventType?.value || "" }}
        isLoading={eventsLoading}
      />
      {!project?.id && (
        <SelectProject
          className="mt-4"
          label="Project"
          value={{ title: getAddressFromPlaces(formState.project?.places), value: formState.project?.id || "" }}
          options={projectsAddressAsOptions}
          onChange={(opt) => {
            setFormValue({ project: opt?.project });
          }}
          isLoading={projectLoading}
        />
      )}
      <div className="mt-10 mb-2 text-md">Guests{formState?.guests?.length ? ` (${formState.guests.length})` : ""}</div>
      <SelectPeople
        className="mt-4"
        label="Guests"
        value={formState?.guests?.map((guest) => ({
          guest,
          title: guest?.title,
          value: guest?.value || guest?.id,
        }))}
        people={guestDropdownOptions}
        onChange={(opt) => {
          setFormValue({ guests: opt });
        }}
        disabled={staffOptsLoading}
        multiple
      />
      <div className="absolute left-0 bottom-0 mb-5 w-full items-center bg-white px-10 pb-6 dark:bg-midnight">
        <Button
          label="Schedule Calendly Event"
          className="w-full"
          isLoading={formLoading}
          size="sm"
          onClick={handleSubmit}
          disabled={!formState?.eventType?.value || !formState?.project?.id || !formState?.guests?.length}
        />
      </div>
    </div>
  );
};
export default AddConsultation;
