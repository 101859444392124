import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createStoryboard } from "../../api";

const useAddStoryboard = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addStoryboardMutation = useMutation(
    (input) => {
      return createStoryboard(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createStoryboard.success) {
          setError(`Error: ${data.createStoryboard?.message}`);
        } else {
          queryClient.setQueryData(["storyboards", {}], (old) => {
            return { getStoryboards: [data.createStoryboard.data, ...old.getStoryboards] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addStoryboard: addStoryboardMutation.mutate,
    addStoryboardLoading: addStoryboardMutation.isLoading,
    addStoryboardSuccess: addStoryboardMutation.isSuccess,
    error,
    addStoryboardAsync: addStoryboardMutation.mutateAsync,
  };
};

export default useAddStoryboard;
