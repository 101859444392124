import { motion } from "framer-motion";
import { Toast } from "@buildappeal/react-component-library";
import PropTypes from "prop-types";

import clsx from "clsx";
import useTimeout from "@src/utils/hooks/useTimeout";

const ToastAnimated = ({
  description = "",
  actionLabel = "",
  onActionClick = null,
  onClose = null,
  showClose = true,
  secondaryActionlabel,
  onSecondaryActionClick = null,
  alwaysVisible = false,
  className = "",
  duration = 3000,
}) => {
  useTimeout(onClose, alwaysVisible ? null : duration);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={clsx("fixed left-1/2 bottom-12 z-50 -translate-x-1/2 -translate-y-1/2", className)}
    >
      <Toast
        description={description}
        actionLabel={actionLabel}
        onActionClick={onActionClick}
        secondaryActionLabel={secondaryActionlabel}
        onSecondaryActionClick={onSecondaryActionClick}
        onClose={onClose}
        showClose={showClose}
      />
    </motion.div>
  );
};

ToastAnimated.propTypes = {
  description: PropTypes.string,
  actionLabel: PropTypes.string,
  onActionClick: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  alwaysVisible: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  mRef: PropTypes.shape({ current: PropTypes.any }),
};

export default ToastAnimated;
