const formSchema = {
  title: "Create Service",
  properties: {
    name: {
      type: "text",
      label: "Name",
      required: true,
    },
    parent: {
      type: "select",
      label: "Parent Service",
    },
  },
};

export default formSchema;
