import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setServices: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        services: action.payload,
      };
    },
  },
});

export const { setServices } = servicesSlice.actions;

export default servicesSlice.reducer;

export const getServices = (state) => state.services.services;
