import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createBill } from "../../api";

const useAddBill = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addBillMutation = useMutation(
    (input) => {
      return createBill(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createBill.success) {
          setError(`Error: ${data.createBill?.message}`);
        } else {
          queryClient.setQueryData(["bills", filters], (old) => {
            return { getBills: [data.createBill.data, ...old?.getBills] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addBill: addBillMutation.mutate,
    addBillAsync: addBillMutation.mutateAsync,
    addBillLoading: addBillMutation.isLoading,
    addBillSuccess: addBillMutation.isSuccess,
    error,
  };
};

export default useAddBill;
