import { useState, useMemo, useEffect } from "react";
import { Button, Select, TextField, IconButton, CloudinaryImage } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { UilTimes as CloseIcon } from "@iconscout/react-unicons";

import useToast from "@src/utils/hooks/useToast";
import useColors from "@src/features/colors/hooks/useColors";
import useMaterials from "@src/features/materials/hooks/useMaterials";
import useFinishes from "@src/features/finishes/hooks/useFinishes";
import useAddNewProductVariants from "../hooks/useAddNewProductVariants";

const emptyVariantInput = {
  name: "",
  cost: "",
  sku: "",
  vendorURL: "",
  colors: [],
  materials: [],
  finishes: [],
  files: [],
};

const CreateNewVariants = ({ selectedParentProduct, onSuccess, selectedFiles }) => {
  const { options: colorOptions, isLoading: colorsLoading } = useColors();
  const { options: materialOptions, isLoading: materialsLoading } = useMaterials();
  const { options: finishOptions, isLoading: finishesLoading } = useFinishes();
  const { addToast } = useToast();

  const [variantInputs, setVariantInputs] = useState([{ ...emptyVariantInput }]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableFileOptsForVariants, setAvailableFileOptsForVariants] = useState({
    0: [],
  });
  const [selectedVariantFileIds, setSelectedVariantFileIds] = useState([]);

  const { createNewProductVariantsAsync } = useAddNewProductVariants();

  const handleVariantCreate = async () => {
    const finalVariantInput = variantInputs.map((item) => {
      return {
        name: item.name,
        productId: selectedParentProduct.id,
        cost: item.cost,
        sku: item.sku,
        vendorURL: item.vendorURL,
        colorIds: item.colors?.map((color) => color.value),
        materialIds: item.materials?.map((material) => material.value),
        finishesIds: item.finishes?.map((finish) => finish.value),
        fileIds: item.files?.map((file) => file.value),
      };
    });

    setIsLoading(true);
    const resp = await createNewProductVariantsAsync(finalVariantInput);

    if (resp.createNewProductVariants?.success) {
      addToast("Successfully created new variants");
      onSuccess?.();
    } else {
      addToast("Failed to creat new variants");
    }
    setIsLoading(true);
  };

  const updateVariantInput = (newVariantValues, variantIndex) => {
    if (newVariantValues?.files?.length) {
      setSelectedVariantFileIds((prev) => {
        const ids = newVariantValues.files.map((file) => file.value);
        return [...prev, ...ids];
      });
    }
    setVariantInputs((prev) => {
      const updatedInputs = prev.map((val, idx) => {
        if (idx === variantIndex) {
          return {
            ...val,
            ...newVariantValues,
          };
        }
        return val;
      });
      return updatedInputs;
    });
  };

  const deleteVariantInput = (index) => {
    setVariantInputs((prev) => {
      const updatedInputs = prev
        .map((val, idx) => {
          if (idx === index) {
            return null;
          }
          return val;
        })
        .filter(Boolean);
      return updatedInputs;
    });
  };

  const addVariantInput = () => {
    const variantInputsLength = variantInputs.length;
    setAvailableFileOptsForVariants((prev) => {
      return {
        ...prev,
        [variantInputsLength]: fileOptions.filter((fl) => !selectedVariantFileIds.includes(fl.value)),
      };
    });
    setVariantInputs([...variantInputs, { ...emptyVariantInput }]);
  };

  const fileOptions = useMemo(() => {
    if (!selectedFiles.length) return [];
    return selectedFiles.map((file) => ({
      value: file.id,
      title: file.name,
      file,
    }));
  }, [selectedFiles]);

  const renderFileOption = (option) => {
    return (
      <div className="flex cursor-pointer items-center gap-x-5 rounded p-3 hover:bg-neutral-100">
        <CloudinaryImage
          imgSrc={option.file.path}
          className="h-[150px] w-[150px] shrink-0 rounded"
          transformations={{
            width: 150,
            height: 150,
          }}
        />
        <div className="flex flex-col">
          <span className="text-sm">{option.title}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!fileOptions.length) return;
    setAvailableFileOptsForVariants((prev) => {
      return {
        ...prev,
        0: fileOptions,
      };
    });
  }, [fileOptions]);

  return (
    <div className="position-relative flex w-full flex-col p-10">
      {isLoading ? (
        <div className="pt-15 absolute top-0 left-0 z-10 flex h-full w-full justify-center bg-slate-100 bg-opacity-60 pt-40">
          <SpinnerIcon role="status" className="mr-2 mt-[200px] h-10 w-10 animate-spin fill-primary-700" />
        </div>
      ) : null}
      {selectedParentProduct?.id ? <div className="mb-5 text-lg">{selectedParentProduct.name} Variants</div> : null}
      {variantInputs.map((variantInput, index) => {
        return (
          <div className="mb-4 flex w-full flex-col border-b border-neutral-300 pb-4">
            <div className="flex flex-row justify-between">
              <span>Variant Details {index + 1}</span>{" "}
              <IconButton icon={CloseIcon} onClick={() => deleteVariantInput(index)} />
            </div>
            <div className="flex flex-row">
              <TextField
                label="Variant Name"
                className="mt-4 flex-1"
                value={variantInput?.name || ""}
                onChange={({ target: { value } }) => updateVariantInput({ name: value }, index)}
                required
              />
              <TextField
                label="Variant Cost (Optional)"
                className="mt-4 ml-2"
                value={variantInput?.cost || ""}
                onChange={({ target: { value } }) => updateVariantInput({ cost: value }, index)}
              />
            </div>
            <div className="flex flex-row">
              <TextField
                label="Variant URL"
                className="mt-4 flex-1"
                value={variantInput?.vendorURL || selectedParentProduct?.vendorURL || ""}
                onChange={({ target: { value } }) => updateVariantInput({ vendorURL: value }, index)}
              />
              <TextField
                label="Variant SKU"
                className="mt-4 ml-2 "
                value={variantInput?.sku || ""}
                onChange={({ target: { value } }) => updateVariantInput({ sku: value }, index)}
                required
              />
            </div>
            <Select
              label="Select Variant files"
              className="mt-2"
              value={variantInput?.files || []}
              options={availableFileOptsForVariants[index]}
              onChange={(opt) => {
                updateVariantInput({ files: opt }, index);
              }}
              renderOption={renderFileOption}
              disabled={!fileOptions.length}
              multiple
              showResults
            />
            <div className="flex flex-row">
              <Select
                label="Select Colors"
                className="mt-2"
                value={variantInput?.colors || []}
                options={colorOptions}
                onChange={(opt) => {
                  updateVariantInput({ colors: opt }, index);
                }}
                isLoading={colorsLoading}
                multiple
              />
              <Select
                label="Select Materials"
                className="mt-2 ml-2"
                value={variantInput?.materials || []}
                options={materialOptions}
                onChange={(opt) => {
                  updateVariantInput({ materials: opt }, index);
                }}
                isLoading={materialsLoading}
                multiple
              />
              <Select
                label="Select Finishes"
                className="mt-2 ml-2"
                value={variantInput?.finishes || []}
                options={finishOptions}
                onChange={(opt) => {
                  updateVariantInput({ finishes: opt }, index);
                }}
                isLoading={finishesLoading}
                multiple
                showResults
              />
            </div>
          </div>
        );
      })}
      <Button label="Add Another Variant" onClick={addVariantInput} appearance="outline" size="md" />

      <Button className="mt-10" label="Create Variants" onClick={handleVariantCreate} loading={isLoading} />
    </div>
  );
};

export default CreateNewVariants;
