import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { omit } from "lodash";
import useToast from "@src/utils/hooks/useToast";
import useProjects from "../projects/hooks/useProjects";
import useContractDocumentTemplates from "./hooks/useContractDocumentTemplates";
import billFormSchema from "./formSchema";
import billUiSchema from "./uiSchema";
import useAddContract from "./hooks/useAddContract";
// Use Form component to display form
// Update formSchema after all dynamic options are fetched
// https://storybook-buildappeal.s3.amazonaws.com/main/index.html?path=/story/mdx-form--basic For details
const AddContract = ({ onClose, projectId }) => {
  const { options: projectsAddressAsOptions, isLoading: projectOptionLoading } = useProjects();
  const { options: templatesAsOptions, isLoading: templateOptionsLoading } = useContractDocumentTemplates();

  const { addContractAsync, error } = useAddContract(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();

  const [formSchema, setFormSchema] = useState(null);
  const [project, setProject] = useState(null);

  const handleSubmit = async (formState) => {
    const data = {
      ...omit(formState, ["project", "template", "recipients"]),
    };

    if (formState.project) {
      const contractProject = formState?.project?.project;
      const clientName = contractProject.clients.length ? contractProject.clients[0].fullName : "";
      const senderName = contractProject.staff.length ? contractProject.staff[0].fullName : "";
      data.projectDetails = {
        id: contractProject?.id,
        name: formState?.project?.title,
        clientFirstName: clientName.split(" ")[0],
        clientLastName: clientName.split(" ")[1],
        senderFirstName: senderName.split(" ")[0],
        senderLastName: senderName.split(" ")[1],
        clientCompany: formState?.project?.title,
        senderCompany: "BuildAppeal", //TODO: This may change
      };
    }

    if (formState.recipients) {
      data.recipients = formState.recipients?.map((recipient) => recipient?.descriptor) || [];
    }

    if (formState.template) {
      data.template_uuid = formState.template.value;
    }

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));

    const res = await addContractAsync(data);

    if (res.createContract?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Contract Successfully Created");
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (projectOptionLoading || templateOptionsLoading) {
      return;
    }
    const finalSchema = {
      ...billFormSchema,
    };
    const allProperties = billFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "project") {
          acc[key].options = projectsAddressAsOptions;
          if (projectId) {
            acc[key].initialValue = projectsAddressAsOptions.find((option) => option.value === projectId);
          }
          acc[key].onChangeTrigger = (newVal) => {
            setProject(newVal.project);
          };
        }

        if (key === "template") {
          acc[key].options = templatesAsOptions;
        }
        if (key === "recipients" && project) {
          acc[key].items = project?.clients
            ? project?.clients.map((client) => ({
                id: client?.id,
                label: client?.fullName,
                descriptor: client?.email,
              }))
            : [];
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [projectsAddressAsOptions, project, templateOptionsLoading, projectOptionLoading]);

  if (projectOptionLoading || templateOptionsLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={billUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddContract;
