import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createTask } from "../../api";

const useAddTask = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      return createTask(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createTask.success) {
          setError("Failed to create task");
        } else {
          setIsSuccess(true);

          queryClient.invalidateQueries("allTasks");
        }
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  return {
    addTask: mutate,
    isLoading,
    isSuccess,
    error,
    addTaskAsync: mutateAsync,
  };
};

export default useAddTask;
