import React, { useState } from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import {
  EmptyState,
  Button,
  PrimaryNavigation,
  NavItem,
  Logo,
  MenuDropdown,
  SearchModal,
  Modal,
} from "@buildappeal/react-component-library";
import { UisFire as FireIcon } from "@iconscout/react-unicons-solid";
import { UilTimes as CloseIcon, UilBars as MenuIcon } from "@iconscout/react-unicons";
import useKeyPress from "@src/utils/hooks/useKeyPress";
import { fallbackMenuItems, menuItems } from "./utils";

const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  const [show, toggle] = useState(false);
  const [showSearchMobile, setShowSearchMobile] = useState(false);
  const [showCommandMenu, setShowCommandMenu] = useState(false);

  useKeyPress(() => setShowCommandMenu(true), ["KeyK"]);
  useKeyPress(() => setShowSearchMobile(true), ["KeyS"]);

  return (
    <>
      <Helmet>
        <title>Error | Build Appeal</title>
      </Helmet>
      <PrimaryNavigation
        left={
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <NavItem wrapper={<a href="/" />}>
              <Logo />
            </NavItem>
          </>
        }
        center={
          <>
            {fallbackMenuItems?.map((menuItem) => {
              if (menuItem.subItems?.length > 0) {
                return (
                  <MenuDropdown
                    isLink={menuItem.isLink}
                    wrapper={menuItem.wrapper}
                    key={menuItem.id}
                    label={menuItem.label}
                    items={menuItem.subItems}
                  />
                );
              }

              return (
                <NavItem key={menuItem.id} {...menuItem}>
                  {menuItem.label}
                </NavItem>
              );
            })}
          </>
        }
        right={
          <>
            <NavItem className="block lg:hidden" icon={show ? CloseIcon : MenuIcon} onClick={() => toggle(!show)} />
          </>
        }
      >
        <PrimaryNavigation.Mobile isOpen={show}>
          <div className="border-b border-neutral-200 pb-6">
            {menuItems.map((menuItem) => (
              <PrimaryNavigation.Item key={menuItem.id} showArrow={!!menuItem.subItems} items={menuItem.subItems}>
                {menuItem.label}
              </PrimaryNavigation.Item>
            ))}
          </div>
        </PrimaryNavigation.Mobile>
      </PrimaryNavigation>
      {error?.name === "ChunkLoadError" ? (
        <div className={clsx("flex h-[calc(100vh_-_64px)] w-full flex-col items-center justify-center")}>
          <div className="mb-[50px]">
            <FireIcon className="h-[150px] w-[150px] text-accent-700" />
          </div>
          <div className="mb-20 text-xl">Woohoo! A new version of the site is available.</div>

          <Button label="Refresh" size="md" appearance="primary" onClick={() => window.location.reload()} />
        </div>
      ) : (
        <div className={clsx("flex h-[calc(100vh_-_64px)] w-full items-center justify-center")}>
          <EmptyState
            title="Sorry, an error occurred"
            subtitle="Something seems to have broken the page. A notification will be sent in production and a fix will be added."
          >
            <div className="flex min-w-[250px] flex-row justify-between">
              <Button label="Go back" size="md" appearance="primary" onClick={resetErrorBoundary} />
              <a href="/">
                <Button label="Home" size="md" appearance="primary" />
              </a>
            </div>
          </EmptyState>
        </div>
      )}
      <SearchModal isOpen={showSearchMobile} onClose={() => setShowSearchMobile(false)}></SearchModal>
      <Modal title="Command Menu" fullScreen isOpen={showCommandMenu} onClose={() => setShowCommandMenu(false)}></Modal>
    </>
  );
};

export default ErrorFallbackPage;
