import axios from "axios";
import noop from "lodash/noop";
import { v4 as uuidv4 } from "uuid";

const CLOUDINARY_UPLOAD_URL = "https://api.cloudinary.com/v1_1/build-appeal/";
const UPLOAD_PRESET = "ml_default";
const IMG_UPLOAD_PRESET = "et_default";

const uploadLargeFiles = async (fileData, isVideo) => {
  const file = fileData.file;
  const size = file.size;
  const sliceSize = 60000000;
  let start = 0;

  let folderPath = fileData.key.split("/");
  const fileName = folderPath.pop();
  folderPath = folderPath.join("/");
  const requestUrl = `${CLOUDINARY_UPLOAD_URL}${isVideo ? "video" : "image"}/upload`;
  const XUniqueUploadId = uuidv4();
  const finalPreset = isVideo ? UPLOAD_PRESET : IMG_UPLOAD_PRESET;
  const commonData = new FormData();
  commonData.append("upload_preset", finalPreset);
  commonData.append("public_id", fileName);
  commonData.append("folder", `ba-media/${process.env.REACT_APP_STAGE}/${folderPath}`);

  const loop = async () => {
    var end = start + sliceSize;

    if (end > size) {
      end = size;
    }
    var fileSlice = slice(file, start, end);
    commonData.set("file", fileSlice);

    const headers = {
      "Content-Type": "multipart/form-data",
      "X-Unique-Upload-Id": XUniqueUploadId,
      "Content-Range": "bytes " + start + "-" + (end - 1) + "/" + size,
    };
    const result = await axios.post(requestUrl, commonData, {
      headers,
    });

    if (end < size) {
      start += sliceSize;
      return await loop();
    } else {
      return result;
    }
  };

  const res = await loop();
  return res;
};

function slice(file, start, end) {
  var slice = file.mozSlice ? file.mozSlice : file.webkitSlice ? file.webkitSlice : file.slice ? file.slice : noop;
  return slice.bind(file)(start, end);
}

const cloudinaryUtils = {
  uploadCloudinaryFiles: async (fileData, isVideoOrAudio = false) => {
    let folderPath = fileData.key.split("/");
    const fileName = folderPath.pop();
    folderPath = folderPath.join("/");

    // if file is of larger size we upload file in chunks
    if (fileData.file instanceof Blob && fileData.file.size) {
      const res = await uploadLargeFiles(fileData, isVideoOrAudio);
      if (res.data?.asset_id) {
        return { status: 200 };
      }
      return null;
    }
    const finalPreset = isVideoOrAudio ? UPLOAD_PRESET : IMG_UPLOAD_PRESET;

    const res = await axios.post(`${CLOUDINARY_UPLOAD_URL}${isVideoOrAudio ? "video" : "image"}/upload`, {
      file: fileData.file,
      upload_preset: finalPreset,
      public_id: fileName,
      folder: `ba-media/${process.env.REACT_APP_STAGE}/${folderPath}`,
    });

    if (res.data?.asset_id) {
      return { status: 200 };
    }
    return null;
  },
};

export default cloudinaryUtils;
