import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";
import AlgoliaTabFilters from "@src/utils/components/AlgoliaComponents/TabFilters";
import { transformTaskCurrentRefinementItems } from "./utils";

const TaskFilters = ({ hideTabFilters, resetToDefault }) => {
  return (
    <div className="mb-3 space-y-4">
      <AlgoliaTabFilters
        items={[
          { id: "pending", label: "Pending", value: "completed:false" },
          {
            id: "completed",
            label: "Completed",
            value: "completed:true",
          },
          { id: "all", label: "All", value: "" },
        ]}
        className={hideTabFilters ? "hidden" : ""}
        defaultSelected={hideTabFilters && resetToDefault ? "active" : null}
      />
      <div className="my-5 flex flex-wrap items-center gap-2.5">
        <DynamicFacetsList>
          <AlgoliaFacetFilter attribute="assignedToUser.fullName" label="Assigned To" searchable />
          <AlgoliaFacetFilter attribute="assignedByUser.fullName" label="Assigned By" searchable />
        </DynamicFacetsList>
      </div>
      <CurrentRefinements transformItems={transformTaskCurrentRefinementItems} />
    </div>
  );
};

export default TaskFilters;
