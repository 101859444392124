import { useMemo } from "react";
import { useQuery } from "react-query";
import { fetchBATypes } from "../api/getBATypes";

const useBATypes = (typeName) => {
  const { data, isLoading } = useQuery(["types", typeName], () => fetchBATypes(typeName), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    refetchInterval: Infinity,
  });

  const typeAsOptions = useMemo(() => {
    if (!data?.getBATypes?.length) {
      return [];
    }
    return data?.getBATypes
      ?.map((type) => {
        return {
          value: type,
          title: type,
        };
      })
      .sort((a, b) => a.title?.localeCompare(b.title));
  }, [data?.getBATypes]);

  const baTypes = data?.getBATypes || [];
  return {
    isLoading,
    baTypes,
    options: typeAsOptions,
  };
};

export default useBATypes;
