import React from "react";
import clsx from "clsx";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { LoadingSpinner } from "@buildappeal/react-component-library";
import { LOADING_TYPES } from "../constants";

const loaders = {
  [LOADING_TYPES.SPINNER]: <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />,
  [LOADING_TYPES.HOUSE]: (
    <div className="w-52">
      <LoadingSpinner />
    </div>
  ),
};

const LoadingWrapper = ({ isSmall, isFullScreen, children }) => {
  return (
    <div
      className={clsx(
        "flex w-full items-center justify-center",
        !isSmall && "h-[calc(100vh_-_17rem)]",
        isFullScreen && "!h-screen w-screen"
      )}
    >
      {children}
    </div>
  );
};

const Loading = ({ isSmall = false, type = LOADING_TYPES.SPINNER, isFullScreen = false }) => {
  if (isFullScreen) {
    return (
      <div className="fixed inset-0 z-50 h-screen w-screen">
        <LoadingWrapper isSmall={isSmall} isFullScreen>
          {loaders[type]}
        </LoadingWrapper>
      </div>
    );
  }
  return <LoadingWrapper isSmall={isSmall}>{loaders[type]}</LoadingWrapper>;
};

export default Loading;
