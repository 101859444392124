import { useLocation } from "react-router-dom";

const capitalizeString = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const prettifyString = (s) => {
  // replace dashes with spaces
  const prettyTitle = s.replace(/-/g, " ");

  return capitalizeString(prettyTitle);
};

const usePageTitle = (firstTitle = "") => {
  const currentLocation = useLocation();
  if (currentLocation?.pathname?.length > 0) {
    const routeParts = currentLocation?.pathname?.split("/");
    const pageSection = routeParts[routeParts.length - 1];
    const pageTitle = `${prettifyString(firstTitle)} ${prettifyString(pageSection)} | Build Appeal`;
    return prettifyString(pageSection).length > 0 ? pageTitle.trim() : "Build Appeal";
  } else {
    return "Build Appeal";
  }
};

export default usePageTitle;
