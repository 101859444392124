import React from "react";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaDateRangeFilter from "@src/utils/components/AlgoliaComponents/DateRangeFilter";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";
import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import AlgoliaTabFilters from "@src/utils/components/AlgoliaComponents/TabFilters";
import { transformActivityCurrentRefinementItems } from "../utils";

const ActivityFilters = ({ showProjectFilter = true, filters, hideTabFilters = false }) => {
  return (
    <div className="space-y-4">
      <AlgoliaTabFilters
        items={[
          {
            id: "all",
            label: "All",
            value: "NOT project:fullAddress OR project.isTest:false",
          },
          {
            id: "projectd",
            label: "Projects",
            value: "NOT projectStageType:Lead AND project.isTest:false",
          },
          {
            id: "leads",
            label: "Leads",
            value: "projectStageType:Lead AND project.isTest:false",
          },
          {
            id: "test",
            label: "Test",
            value: "project.isTest:true",
          },
        ]}
        className={hideTabFilters ? "hidden" : ""}
        defaultFilters={filters}
      />
      <div className="my-5 flex flex-wrap items-center gap-2.5">
        <DynamicFacetsList>
          <AlgoliaDateRangeFilter attribute="activityDate" />
          {showProjectFilter && <AlgoliaFacetFilter attribute="project.fullAddress" label="Project" searchable />}
          <AlgoliaFacetFilter attribute="activityType.eventTitle" label="Type" />
        </DynamicFacetsList>
      </div>
      <CurrentRefinements transformItems={transformActivityCurrentRefinementItems} />
    </div>
  );
};

export default ActivityFilters;
