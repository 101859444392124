import React from "react";
import clsx from "clsx";
import { UilTimes as CloseIcon } from "@iconscout/react-unicons";
import { CoreContainer, IconButton } from "@buildappeal/react-component-library";

const SidebarLayout = ({
  Sidebar,
  isOpen,
  children,
  onClose,
  className,
  containerClassName,
  contentClassName,
  ...props
}) => {
  return (
    <div className={clsx("flex", className)} {...props}>
      <div className={clsx(isOpen ? `w-[calc(100vw-450px)] overflow-x-auto ${contentClassName}` : "w-full")}>
        <CoreContainer className={clsx("py-10", containerClassName)}>{children}</CoreContainer>
      </div>
      <div
        className={clsx(
          "fixed top-[3.32rem] right-0 self-start",
          "z-10 flex-shrink-0 border-l border-neutral-200 bg-white transition-all duration-300  ease-linear dark:border-midnightborder dark:bg-midnight",
          isOpen ? "w-[450px] translate-x-0" : "hidden w-0 translate-x-[450px]"
        )}
      >
        <div className="fixed top-3 left-3 z-10">
          <IconButton icon={CloseIcon} onClick={onClose} />
        </div>
        {isOpen && <div className="h-[calc(100vh-3.32rem)] overflow-y-auto">{Sidebar}</div>}
      </div>
    </div>
  );
};

export default SidebarLayout;
