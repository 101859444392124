import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "@buildappeal/react-component-library";

import useToast from "@utils/hooks/useToast";
import useBATypes from "@utils/hooks/useBATypes";
import useAddActivity from "@features/activities/hooks/useAddActivity";
import useUser from "@features/auth/hooks/useUser";
import useSpaces from "../spaces/hooks/useSpaces";
import useCreateProject from "../projects/hooks/useCreateProject";
import { ProjectTypes } from "../projects/view/utils";
import useStaff from "../staff/hooks/useStaff";
import leadsFormSchema from "./formSchema";
import leadsUiSchema from "./uiSchema";
import useAddLead from "./hooks/useAddLead";

const AddLead = ({ onClose }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [formSchema, setFormSchema] = useState(null);

  const { options: spacesAsOptions, isLoading: spacesLoading } = useSpaces();
  const { options: staffAsOptions, isLoading: staffOptionLoading } = useStaff();
  const { baTypes: leadSources, isLoading: leadSourcesLoading } = useBATypes("leadSource");
  const { addToast } = useToast();
  const { createLeadAsync, error } = useAddLead();
  const { createProjectAsync } = useCreateProject({
    filters: { projectStages: { type: "Lead" } },
  });
  const { addNewActivity } = useAddActivity();

  const handleSubmit = async (formState) => {
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));

    const projectData = {
      addressLine1: formState.addressLine1,
      city: formState.city,
      state: formState.state?.value,
      zipcode: formState.zipCode,
      clients: [formState.email],
      contactEmail: formState.email,
      spacesIds: formState?.rooms?.map((room) => +room.value),
      leadSource: formState?.leadSource?.value ?? "Manual",
      type: formState?.type?.value,
      staff: formState?.staff ? [formState.staff.email] : null,
    };
    const clientData = {
      firstName: formState.firstName,
      lastName: formState.lastName,
      email: formState.email,
      phone: formState.phone,
      clientBudgetLow: formState.clientBudgetLow,
      clientBudgetHigh: formState.clientBudgetHigh,
      leadOwner: user.id,
      type: "Lead",
    };

    const createClientResp = await createLeadAsync(clientData);
    if (!createClientResp.createClient?.success) return;

    const createProjectResp = await createProjectAsync(projectData);
    if (!createProjectResp.createProject?.success) return;
    addNewActivity({
      activityDate: new Date(),
      activityType: "leadCreated",
      actor: user.uuid,
      projectId: createProjectResp.createProject.data.id,
      eventPayload: {
        ...clientData,
        name: `${user.firstName} ${user.lastName}`,
      },
    });
    navigate(`/leads?id=${createProjectResp.createProject.data.id}`);
    addToast("Lead Successfully Created");
    onClose();
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    const finalSchema = {
      ...leadsFormSchema,
    };
    const allProperties = leadsFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "rooms") {
          acc[key].isLoading = spacesLoading;
          acc[key].options = spacesAsOptions;
        }
        if (key === "leadSource") {
          acc[key].isLoading = leadSourcesLoading;
          acc[key].options = leadSources?.map((opt) => ({
            title: opt,
            value: opt,
          }));
        }
        if (key === "type") {
          acc[key].options = ProjectTypes?.map((opt) => ({
            title: opt,
            value: opt,
          }));
        }
        if (key === "staff") {
          acc[key].isLoading = staffOptionLoading;
          acc[key].options = staffAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [spacesAsOptions, staffAsOptions, spacesLoading, staffOptionLoading, leadSourcesLoading]);

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={leadsUiSchema} isInSidebar isCreate />
    </>
  );
};
export default AddLead;
