import sortBy from "lodash/sortBy";
import { TableCards, TableCells, TableFilters, TableHeaders, NavCard } from "@buildappeal/react-component-library";

import { isImageFile } from "@utils/isMedia";

export const productColumns = {
  select: {
    id: "select",
    accessor: "selected_row",
    Header: TableHeaders.CheckboxAllPageHeader,
    Cell: TableCells.CheckboxCell,
    renderCard: TableCards.CheckboxInputRow,
    disableSortBy: true,
    minWidth: "50px",
    maxWidth: "50px",
  },
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: ({ row: { original }, column, table }) => {
      const variants = original.variants?.length;
      const description = !original.isVariant && !variants && sortBy(original?.materials)?.filter(Boolean).join(", ");
      let finalImg = original.thumbnail;
      if (!finalImg && variants) {
        finalImg = original.variants[0].media?.length
          ? original.variants[0].media.find((media) => media.isThumbnail)?.path ||
            original.variants[0].media.find((media) => isImageFile(media.fileType))?.path
          : "";
      }
      const isLoading = !!table?.options?.meta?.isLoading;
      let finalDesc = "";
      if (original.isVariant && original.variantName) {
        finalDesc = `Variant: ${original.variantName}`;
      }
      if (!original.isVariant && variants) {
        finalDesc = ` ${variants} variants`;
      }
      return (
        <NavCard
          isLoading={isLoading}
          title={`${original.name}`}
          description={
            description
              ? `${description}${original?.type?.description || ""}`
              : original?.type?.description || `${finalDesc}`
          }
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          titleClassName="line-clamp-1"
          descriptionClassName="line-clamp-1"
          showPlaceholder
        />
      );
    },
    subtitleAccessor: "sku",
    Filter: TableFilters.SelectColumnFilter,
    renderCard: ({ row: { original }, column, table }) => {
      const finalImg = original.thumbnail || "";
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          imgTransformations={{
            sizes: "(max-width: 768px) 400px, (max-width: 1024px) 500px",
            srcSetArray: ["400", "500"],
            maxWidth: "700",
          }}
          className="mb-3"
          titleClassName="line-clamp-2"
          imageClassName="h-80 w-full rounded-t-lg object-contain p-2"
          showPlaceholder
          imageOnly
        />
      );
    },
    maxWidth: "3fr",
    disableSortBy: true,
  },
  title: {
    id: "title",
    Header: "Name",
    accessor: (row) => row.name || "",
    className: "whitespace-nowrap",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: ({ row, column, table }) => {
      const description = row?.original?.materials?.filter(Boolean).join(", ");
      return (
        <TableCards.TitleRow
          getValue={() => {
            return row?.original?.name || "";
          }}
          subtitle={
            description
              ? `${description}  ${row?.original?.type?.description || ""}`
              : row?.original?.type?.description || ""
          }
          row={row}
          column={column}
          table={table}
          className="line-clamp-2"
        />
      );
    },
    disableSortBy: true,
  },
  id: {
    id: "id",
    Header: "ID",
    accessor: (row) => row.id?.toString(),
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    renderCard: TableCards.TextRow,
    maxWidth: "80px",
    disableSortBy: true,
  },
  photos: {
    id: "photos",
    Header: "",
    accessor: (row) => row.photos?.[0],
    Cell: (originalRow) => {
      const finalImg =
        originalRow.row.original.media?.length && isImageFile(originalRow.row.original.media[0].fileType)
          ? originalRow.row.original.media[0].path
          : originalRow.row.original.photos?.[0] || "";
      return <TableCells.ImageCell value={finalImg} alt={originalRow.row.original.name} />;
    },
    disableSortBy: true,
    className: "w-[80px]",
  },
  price: {
    id: "price",
    Header: "Price",
    accessor: "cost",
    Cell: TableCells.CurrencyCell,
    Filter: TableFilters.PriceColumnFilter,
    filter: "between",
    className: "w-[11%]",
    renderCard: TableCards.PriceRow,
    disableSortBy: true,
  },
  type: {
    id: "type",
    Header: "Type",
    accessor: (row) =>
      row.objects?.map((item) => ({
        title: item,
        value: item,
        description: item,
      })),

    Cell: (props) => (
      <TableCells.BadgesCell
        items={props?.row?.original?.objects?.filter(Boolean)?.sort((a, b) => a?.localeCompare(b))}
        {...props}
      />
    ),
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    className: "w-[10%]",
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.map((item) => item.description).join(", ") || "",
    hasFilterSearch: true,
    filterOptionsGroupKey: "parentObject.id",
    filterOptionsGroupLabelKey: "parentObject.description",
    disableSortBy: true,
  },
  brand: {
    id: "brand",
    Header: "Brand",
    accessor: "brand",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    className: "w-[10%]",
    disableSortBy: true,
  },
  leadTime: {
    id: "leadTime",
    Header: "Lead Time",
    accessor: "leadTimeDays",
    Filter: TableFilters.LeadTimeColumnFilter,
    filter: TableFilters.filterGreaterThan,
    className: "w-[10%]",
    renderCard: TableCards.TextRow,
    disableSortBy: true,
  },
  suppliers: {
    id: "supplier",
    Header: "Supplier",
    accessor: (row) => row.suppliers?.[0]?.name || "",
    Cell: (originalRow) => {
      const { row, column } = originalRow;
      return (
        <TableCells.TextCell
          column={column}
          row={row}
          id={row?.original?.suppliers?.[0]?.id}
          path="/suppliers"
          onQuickViewOpen={column.onQuickViewOpen}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    className: "w-[10%]",
    renderCard: TableCards.TextRow,
    hasFilterSearch: true,
    disableSortBy: true,
  },
  materials: {
    id: "materials",
    Header: "Material",
    accessor: (row) => row.materials?.filter(Boolean),
    Cell: ({ getValue, ...rest }) => {
      return <TableCells.TextCell getValue={() => getValue()?.join(", ") || ""} {...rest} />;
    },
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    className: "w-[10%] hidden",
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
    disableSortBy: true,
  },
  designStyles: {
    id: "designStyles",
    Header: "Design Style",
    accessor: (row) => row.designStyles?.filter(Boolean),
    Cell: ({ getValue, ...rest }) => {
      return <TableCells.TextCell getValue={() => getValue()?.join(", ") || ""} {...rest} />;
    },
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    className: "w-[10%] hidden",
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
    disableSortBy: true,
  },
  spaces: {
    id: "spaces",
    Header: "Space",
    accessor: (row) => row.spaces?.filter(Boolean),
    Cell: ({ getValue, ...rest }) => {
      return <TableCells.TextCell getValue={() => getValue()?.join(", ") || ""} {...rest} />;
    },
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    className: "w-[10%] hidden",
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
    disableSortBy: true,
  },
  finishes: {
    id: "finishes",
    Header: "Finish",
    accessor: (row) => row.finishes?.filter(Boolean),
    Cell: ({ getValue, ...rest }) => {
      return <TableCells.TextCell getValue={() => getValue()?.join(", ") || ""} {...rest} />;
    },
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    className: "w-[10%] hidden",
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
    disableSortBy: true,
  },
  createdAt: {
    id: "added",
    Header: "Created",
    accessor: ({ createdAt }) => (createdAt ? new Date(createdAt) : createdAt),
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
    disableSortBy: true,
  },
  vendorURL: {
    id: "website",
    Header: "Website",
    accessor: "vendorURL",
    Cell: (originalRow) => {
      if (!originalRow.row.original.vendorURL) return null;

      return <TableCells.ButtonCell {...originalRow} />;
    },
    labelAccessor: "Link",
    hrefAccessor: "vendorURL",
    targetAccessor: "_blank",
    renderCard: TableCards.LinkRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    disableSortBy: true,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: (row) => (row?.status === "active" ? "Active" : "Inactive"),
    Cell: (originalRow) => {
      const status = originalRow.row.original.status;
      return <TableCells.StatusCell status={status === "active" ? "Active" : "Inactive"} {...originalRow} />;
    },
    statusAccessor: "status",
    titleClassName: "text-3xs font-normal",
    subtitleClassName: "text-xs font-normal",
    renderCard: TableCards.StatusRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    disableSortBy: true,
  },
  colors: {
    id: "colors",
    Header: "Color",
    accessor: (row) => row.colors?.filter(Boolean),
    Cell: ({ getValue, ...rest }) => {
      return <TableCells.TextCell getValue={() => getValue()?.join(", ") || ""} {...rest} />;
    },
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
    disableSortBy: true,
  },
  attributes: {
    id: "attributes",
    Header: "Attribute",
    accessor: (row) => row.attributes?.filter(Boolean),
    Cell: ({ getValue, ...rest }) => {
      return <TableCells.TextCell getValue={() => getValue()?.join(", ") || ""} {...rest} />;
    },
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
    disableSortBy: true,
  },
};
