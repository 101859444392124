import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";

import useToast from "@src/utils/hooks/useToast";
import { isImageFile } from "@src/utils/isMedia";
import { FILE_CONTENT_TYPES, FILE_CONTENT_TYPES_LABEL, FILE_PATHS } from "@src/features/files/utils";
import useMultiFilesUpload from "../files/hooks/useMultiFilesUpload";
import useAddDesignStyle from "./hooks/useAddDesignStyle";
import designStyleFormSchema from "./formSchema";
import designStyleUiSchema from "./uiSchema";

const AddDesignStyle = ({ onClose }) => {
  const { addDesignStyleAsync, error } = useAddDesignStyle();
  const [formSchema, setFormSchema] = useState(null);
  const { addToast } = useToast();
  const { startUpload } = useMultiFilesUpload();
  const queryClient = useQueryClient();

  const uploadFiles = async (designStyleId, type = "media", filesList) => {
    const finalFilesList = (filesList || []).map((file) => {
      const fileKey = `${FILE_PATHS[FILE_CONTENT_TYPES.designStyle]}/${designStyleId}/${uuidv4()}`;
      return {
        key: fileKey,
        contentType: FILE_CONTENT_TYPES_LABEL[FILE_CONTENT_TYPES.designStyle],
        type,
        file,
        designStylesId: designStyleId ? [designStyleId] : null,
      };
    });
    const results = await startUpload(finalFilesList);
    if (results?.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  const handleSubmit = async (formState) => {
    if (formState.parent) {
      formState.parentId = formState.parent?.value;
    }
    const data = {
      name: formState.name,
      description: formState.description,
    };

    delete formState.parent;
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const resp = await addDesignStyleAsync(data);
    if (!resp?.createDesignStyle?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Error creating Design Style");
    }
    let designMedia = [];

    if (formState?.media?.length) {
      const finalFilesList = await uploadFiles(resp.createDesignStyle?.data?.id, "media", formState.media);
      if (finalFilesList?.length) {
        designMedia = finalFilesList.map((fileInfo) => ({
          path: fileInfo.key,
          fileType: isImageFile(fileInfo.contentType) ? "image" : "video",
        }));
      } else {
        setFormSchema((prev) => ({
          ...prev,
          actionsState: {
            isLoading: false,
          },
        }));
        addToast("Error creating Design Style");
      }
    }

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: false,
      },
    }));
    queryClient.setQueryData(["designStyles", {}], (old) => {
      return {
        getAllDesignStyle: [{ ...resp.createDesignStyle.data, media: [...designMedia] }, ...old?.getAllDesignStyle],
      };
    });
    addToast("Design Style Successfully Created");
    onClose();
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    const finalSchema = {
      ...designStyleFormSchema,
    };
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, []);

  if (!formSchema) return null;

  return <Form formSchema={formSchema} uiSchema={designStyleUiSchema} isInSidebar isCreate />;
};

export default AddDesignStyle;
