import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_DT_ATTRIBUTES = gql`
  query getDTAttributes {
    getDTAttributes {
      id
      name
      type
      icon
      objectName
    }
  }
`;

export const getDTAttributes = async () => {
  return await graphQLClient.request(GET_DT_ATTRIBUTES);
};
