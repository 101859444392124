import { Button, NavCard, TableCards, TableCells } from "@buildappeal/react-component-library";
import generateInitials from "@src/utils/generateInitials";

export const STATUS_COLOR_CODES = {
  Disabled: "gray",
  Active: "green",
};

export const contactsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  name: {
    id: "name",
    Header: "Name",
    accessor: (row) => {
      return (row.firstName || "") + " " + (row.lastName || "") || row.fullName;
    },
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={`${originalRow.row.original?.firstName || ""} ${originalRow.row.original?.lastName || ""}`}
          avatarSrc={originalRow?.row?.original?.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
          initials={generateInitials(
            `${originalRow.row.original?.firstName || ""} ${originalRow.row.original?.lastName || ""}` ||
              originalRow.row?.original?.email ||
              originalRow.row.original?.type ||
              ""
          )}
          {...originalRow}
        />
      );
    },
    renderCard: ({ row: { original }, column }) => {
      return (
        <NavCard
          layout="card"
          title={`${original?.firstName || ""} ${original?.lastName || ""}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original?.avatar || ""}
          showPlaceholder
        />
      );
    },
  },
  phone: {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
    renderCard: TableCards.TextRow,
  },
  email: {
    id: "email",
    Header: "Message",
    accessor: "email",
    Cell: (originalRow) => {
      const handleClick = () => {
        originalRow?.column?.onOpenClick("send", originalRow);
      };
      return (
        <div className="p-4" onClick={(e) => e?.stopPropagation()}>
          <Button label="Email" onClick={handleClick} appearance="secondary" />
        </div>
      );
    },
    renderCard: TableCards.TextRow,
  },
  partners: {
    id: "partners",
    Header: "Partners",
    accessor: (row) => row.partners?.map((partner) => partner.companyName),
    Cell: TableCells.BadgesCell,
    colorAccessor: "gray",
    renderCard: TableCards.TextRow,
  },
  suppliers: {
    id: "suppliers",
    Header: "Suppliers",
    accessor: (row) => row.suppliers?.map((supplier) => supplier.name),
    Cell: TableCells.BadgesCell,
    colorAccessor: "gray",
    renderCard: TableCards.TextRow,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: (row) => (row?.status?.toLowerCase() === "active" ? "Active" : "Inactive"),
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          status={originalRow.row?.original?.status?.toLowerCase() === "active" ? "Active" : "Inactive"}
          {...originalRow}
        />
      );
    },
    renderCard: TableCards.TextRow,
  },
};
