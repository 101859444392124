import { useMemo } from "react";

import SendEmail from "@features/emails/components/SendEmail";
import { transformTo } from "@features/emails/utils";

const CommonSendEmail = ({ selectedRow, onClose, isOpen }) => {
  const to = useMemo(() => {
    if (selectedRow?.row?.original?.email) {
      return [transformTo(selectedRow?.row?.original)];
    }
    return [];
  }, [selectedRow]);

  return <SendEmail isOpen={isOpen} onClose={onClose} to={to} />;
};

export default CommonSendEmail;
