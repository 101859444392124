import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { addFinish } from "../../api";

const useAddFinish = (filters = {}) => {
  const queryClient = useQueryClient();

  const [error, setError] = useState();

  const addFinishMutation = useMutation(
    (input) => {
      setError(null);
      return addFinish(input);
    },
    {
      onSuccess: (data) => {
        if (!data.addFinish?.success) {
          setError(`Error: ${data.addFinish?.message}`);
        } else {
          queryClient.setQueryData(["finishes", filters], (old) => {
            if (!old) return { getFinishes: [data.addFinish.data] };
            return {
              getFinishes: [data.addFinish?.data, ...old.getFinishes],
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    addFinish: addFinishMutation.mutate,
    addFinishAsync: addFinishMutation.mutateAsync,
    isLoading: addFinishMutation.isLoading,
    isSuccess: addFinishMutation.isSuccess,
  };
};

export default useAddFinish;
