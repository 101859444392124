export const addFileDetails = (file, folderName, folderType = "product") => {
  const fileDetails = {
    name: file.name,
    file,
    id: parseInt(folderName, 10),
    folderType,
  };

  return fileDetails;
};
