import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";
import AlgoliaTabFilters from "@src/utils/components/AlgoliaComponents/TabFilters";
import { transformConsultationCurrentRefinementItems } from "./utils";

const ConsultationFilters = ({ hideTabFilters, resetToDefault }) => {
  return (
    <div className="mb-3 space-y-4">
      <AlgoliaTabFilters
        items={[
          {
            id: "scheduled",
            label: "Scheduled",
            value: "status:Scheduled",
          },
          {
            id: "completed",
            label: "Completed",
            value: "status:Completed",
          },
          { id: "cancelled", label: "Cancelled", value: "status:Cancelled" },
          { id: "all", label: "All", value: "" },
        ]}
        className={hideTabFilters ? "hidden" : ""}
        defaultSelected={hideTabFilters && resetToDefault ? "active" : null}
      />
      <div className="my-5 flex flex-wrap items-center gap-2.5">
        <DynamicFacetsList>
          <AlgoliaFacetFilter attribute="staff.fullName" label="Staff" searchable />
        </DynamicFacetsList>
      </div>
      <CurrentRefinements transformItems={transformConsultationCurrentRefinementItems} />
    </div>
  );
};

export default ConsultationFilters;
