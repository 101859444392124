import { flatten, isEmpty } from "lodash";
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { findNestedChildren, getProjectSpaceObjectOptions } from "@src/features/projects/utils";
import { fetchGroupedProjectSpaceObjects } from "../../api";
import { getGroupedProjectSpaceObjects, setGroupedProjectSpaceObjects } from "../../projectSpaceObjectsSlice";

const useGroupedProjectSpaceObjects = (filters, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;
  const dispatch = useDispatch();
  const projectSpaceObjects = useSelector(getGroupedProjectSpaceObjects);

  const { data, isLoading, refetch } = useQuery(
    ["projectSpaceObjectsByParentId", filters],
    () => fetchGroupedProjectSpaceObjects(filters),
    {
      refetchOnWindowFocus: false,
      enabled: !disableQuery,
      refetchInterval: false,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    const projectSpaceObjectsMap = data.getGroupedProjectSpaceObjectsByParent.reduce((acc, projectSpaceObjectGroup) => {
      const { parentId, projectSpaceObjects } = projectSpaceObjectGroup;
      if (!acc[projectSpaceObjectGroup.parentId]) {
        acc[parentId] = [];
      }
      acc[parentId] = projectSpaceObjects;
      return acc;
    }, {});
    dispatch(setGroupedProjectSpaceObjects(projectSpaceObjectsMap));
  }, [data, isLoading, dispatch]);

  const getObjectsByActionObjectId = (id) => {
    if (isEmpty(projectSpaceObjects) || !projectSpaceObjects[null]) {
      return [];
    }

    return projectSpaceObjects[null]
      .filter((object) => object.objectsId === id)
      .map((obj) => ({
        ...obj,
        childObjects: projectSpaceObjects[obj.id] || [],
      }));
  };

  const projectSpaceObjectsAsOptions = useMemo(() => {
    if (isEmpty(projectSpaceObjects)) return [];
    const objects = (projectSpaceObjects["null"] || []).map((parentPSO) => {
      let finalChildrenList = findNestedChildren(parentPSO, projectSpaceObjects);
      return finalChildrenList;
    });

    return flatten(objects.map((obj) => getProjectSpaceObjectOptions(obj)));
  }, [projectSpaceObjects]);

  return {
    data: projectSpaceObjects,
    getObjectsByActionObjectId: getObjectsByActionObjectId,
    options: projectSpaceObjectsAsOptions,
    isLoading,
    refetch,
  };
};

export default useGroupedProjectSpaceObjects;
