import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

import { QUERY_PARAMS } from "@features/layout/constants";
import useToast from "@utils/hooks/useToast";
import { sendEmail } from "@features/emails/api";

const useSendEmail = (
  filters = {},
  hookOptions = {
    isReply: false,
    parentEmailId: null,
    showToast: true,
  }
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleError = (error) => {
    setError(`Error: ${error}`);

    if (hookOptions.showToast) {
      addToast("Your email could not be sent, please try again.");
    }
  };

  const handleSuccess = (data) => {
    setIsSuccess(true);

    if (hookOptions.showToast) {
      const toastOptions =
        hookOptions.pageLevel === "projects"
          ? {
              actionLabel: "View",
              onActionClick: () => {
                searchParams.set(QUERY_PARAMS.Section, "emails");
                setSearchParams(searchParams);
              },
              duration: 5000,
            }
          : {};
      addToast("Email sent successfully", toastOptions);
    }

    if (hookOptions.successCallback) {
      hookOptions.successCallback();
    }

    // Opt in to refresh local cache
    if (hookOptions.updateCache) {
      if (hookOptions.isReply) {
        queryClient.setQueryData(["emails", hookOptions?.parentEmailId], (old) => {
          if (old) {
            return {
              getEmailByID: {
                ...old.getEmailByID,
                thread: [...old.getEmailByID?.thread, data.sendEmail.data],
              },
            };
          }
        });
      } else {
        queryClient.setQueryData(["emails", filters], (old) => {
          if (old) {
            return {
              getEmails: [data.sendEmail.data, ...old.getEmails],
            };
          }
        });
      }
    }
  };

  const { mutate, mutateAsync, isLoading } = useMutation(
    (input) => {
      setError("");
      setIsSuccess(false);
      return sendEmail(input);
    },
    {
      onSuccess: (data) => {
        if (!data.sendEmail.success) {
          handleError(data.sendEmail.message);
          return;
        }
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error.message);
      },
    }
  );

  return { sendEmail: mutate, sendEmailAsync: mutateAsync, isLoading, isSuccess, error };
};

export default useSendEmail;
