import React, { useState, useMemo, useEffect } from "react";
import { Button, Select, TextField, DateTimeInput, SelectProject } from "@buildappeal/react-component-library";
import useProjects from "@src/features/projects/hooks/useProjects";
import useStaff from "@src/features/staff/hooks/useStaff";
import useClients from "@src/features/clients/hooks/useClients";
import useTasksAsOptions from "@src/features/tasks/hooks/useTasksAsOptions";
import useActionsObjects from "@src/features/actionsObjects/hooks/useActionsObjects";
import { getAddressFromPlaces } from "@src/utils/common";
import { BA_USER_TYPES } from "@src/utils/constants";
import { getTaskActionObjects, statusOptions, assignedToTypeOptions } from "./utils";

const TaskForm = ({ task, onSubmit, isLoading, action }) => {
  const { options: projectsAddressAsOptions, projectsAddressAsOptionsLoading } = useProjects();
  const { options: staffAsOptions } = useStaff();
  const { options: clientsAsOptions } = useClients();
  const { tasksAsOptions } = useTasksAsOptions();
  const { options: actionsObjectsAsOptions } = useActionsObjects();

  const [formState, setFormState] = useState({
    project: task?.project?.id ? task.project : null,
    taskSpaces: task?.projectSpace ? task?.projectSpace : null,
    actionsObjects: getTaskActionObjects(task) || null,
    dueDate: task?.dueDate ? task.dueDate : "",
    assignedToUser: task?.assignedToUser || null,
    assignedByUser: task?.assignedByUser || null,
    assignedToType: task?.assignedToType || null,
    completedByUser: task?.completedByUser || null,
    status: task?.status || null,
    description: task?.description || null,
  });
  const [errors, setErrors] = useState({});

  const assignedToTypeUserPropName = formState.assignedToType === BA_USER_TYPES.CLIENT ? "clients" : "staff";

  useEffect(() => {
    if (task?.project?.id && Array.isArray(projectsAddressAsOptions)) {
      const spaces = projectsAddressAsOptions.find((item) => item.value === task?.project?.id)?.project?.spaces;
      setFormState((prev) => {
        return {
          ...prev,
          project: {
            ...prev.project,
            spaces: spaces,
          },
        };
      });
    }
  }, [projectsAddressAsOptions]);

  const finalUserOptions = useMemo(() => {
    return {
      [BA_USER_TYPES.STAFF]: staffAsOptions,
      [BA_USER_TYPES.CLIENT]: clientsAsOptions,
    };
  }, [staffAsOptions, clientsAsOptions]);

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};
    if (formState.status === "Completed" && !formState.completedByUser?.id) {
      newErrors.completedByUser = "Please select a staff member";
      return;
    }

    if (formState.status === "Completed" && !formState.completedOn) {
      newErrors.completedOn = "Please select completion date";
      return;
    }

    if (formState.productType) {
      formState.productTypeId = formState.productType.id;
      delete formState.productType;
    }
    if (formState.actionsObjects) {
      formState.actionsObjectsId = formState.actionsObjects.id;
      delete formState.actionsObjects;
    }
    if (formState.project) {
      formState.projectId = formState.project.id;
      delete formState.project;
    }
    if (formState.assignedToUser) {
      formState.assignedToUser = formState.assignedToUser.id;
    }
    if (formState.assignedByUser) {
      formState.assignedByUser = formState.assignedByUser.id;
    }
    if (formState.assignedToType) {
      formState.assignedToType = formState.assignedToType.value;
    }
    if (formState.taskSpaces) {
      formState.projectsSpacesId = formState.taskSpaces.id;
    }
    if (formState.status === "Completed") {
      formState.completedByUser = formState.completedByUser.uuid;
    }
    if (!formState.dueDate) delete formState.dueDate;
    delete formState.taskSpaces;

    if (formState.actionsObjectsId) {
      newErrors = {};
      onSubmit({
        ...formState,
        dueDate: formState.dueDate,
      });
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="px-6 py-12">
      <h2 className="mb-4 font-semibold">{action} task action</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <SelectProject
            className="mt-4"
            label="Project"
            isLoading={projectsAddressAsOptionsLoading}
            value={{ title: getAddressFromPlaces(formState.project?.places), value: formState.project?.id || "" }}
            options={projectsAddressAsOptions}
            onChange={(opt) => {
              setFormValue({ project: opt?.project });
            }}
          />
        </div>
        {formState.project && (
          <div>
            <Select
              options={formState?.project?.spaces?.map((space) => ({
                title: space.spaceNickname,
                value: space.id,
                space,
              }))}
              label="Space"
              value={{
                title: formState?.taskSpaces?.spaceNickname,
                value: formState?.taskSpaces?.id,
              }}
              onChange={(opt) => {
                setFormValue({ taskSpaces: opt?.space });
              }}
            />
          </div>
        )}
        <div>
          <Select
            label="Action Object"
            value={{
              title: formState.actionsObjects?.name
                ? `${formState.actionsObjects.id} - ${formState.actionsObjects.name}`
                : "",
              value: formState.actionsObjects?.id || "",
            }}
            options={actionsObjectsAsOptions}
            onChange={(opt) => {
              setFormValue({ actionsObjects: opt?.actionsObjects });
            }}
          />
          {errors.actionsObjects && <p className="my-2 text-sm font-semibold text-red-700">{errors.actionsObjects}</p>}
        </div>
        <div>
          <Select
            label="Parent Task"
            value={{
              title: tasksAsOptions.find((item) => item.value === formState.parent)?.title || "",
              value: formState.parent || "",
            }}
            options={tasksAsOptions}
            onChange={(opt) => {
              setFormValue({ parent: opt?.value });
            }}
          />
        </div>
        <div>
          <TextField
            value={formState.description || ""}
            label="Description"
            onChange={({ target: { value } }) => setFormValue({ description: value })}
          />
          {errors.description && <p className="my-2 text-sm font-semibold text-red-700">{errors.description}</p>}
        </div>
        <DateTimeInput
          label="Due Date"
          initialDate={task?.dueDate}
          showTimeSelect={true}
          onChange={(dateTime) => setFormValue({ dueDate: dateTime })}
        />
        <div>
          <TextField
            value={formState.estimatedLaborTime || ""}
            label="Estimated Labor Time"
            onChange={({ target: { value } }) => setFormValue({ estimatedLaborTime: parseInt(value) })}
          />
          {errors.estimatedLaborTime && (
            <p className="my-2 text-sm font-semibold text-red-700">{errors.estimatedLaborTime}</p>
          )}
        </div>
        <div>
          <Select
            options={staffAsOptions}
            label="Assign by"
            value={{
              title: formState.assignedByUser?.staff?.fullName || "",
              value: formState.assignedByUser?.staff?.id || "",
            }}
            onChange={(opt) => {
              setFormValue({ assignedByUser: opt ? { id: opt.staff?.uuid, staff: opt.staff } : null });
            }}
          />
        </div>
        <div>
          <Select
            options={assignedToTypeOptions}
            label="Assign to type"
            value={{
              title: formState.assignedToType?.title || "",
              value: formState.assignedToType?.value || "",
            }}
            onChange={(opt) => {
              setFormValue({ assignedToType: opt });
            }}
          />
        </div>
        {formState.assignedToType?.value ? (
          <div>
            <Select
              options={finalUserOptions[formState.assignedToType.value]}
              label="Assign to"
              value={{
                title: formState.assignedToUser?.[assignedToTypeUserPropName]?.fullName || "",
                value: formState.assignedToUser?.[assignedToTypeUserPropName]?.id || "",
              }}
              onChange={(opt) => {
                setFormValue({
                  assignedToUser: opt
                    ? {
                        id: opt[assignedToTypeUserPropName]?.uuid,
                        [assignedToTypeUserPropName]: opt[assignedToTypeUserPropName],
                      }
                    : null,
                });
              }}
            />
          </div>
        ) : null}

        <div>
          <Select
            options={statusOptions}
            label="Status"
            value={{
              title: formState?.status || "",
              value: formState.status || "",
            }}
            onChange={(opt) => {
              setFormValue({ status: opt?.value });
            }}
          />
        </div>
        {formState.status === "Completed" ? (
          <>
            <div>
              <DateTimeInput
                label="Complete on"
                showTimeSelect={true}
                onChange={(dateTime) => setFormValue({ completedOn: dateTime })}
              />
              {errors.completedOn && <p className="my-2 text-sm font-semibold text-red-700">{errors.completedOn}</p>}
            </div>
            <div>
              <Select
                options={staffAsOptions}
                label="Completed by"
                value={{
                  title: formState.completedByUser?.fullName || "",
                  value: formState.completedByUser?.id || "",
                }}
                onChange={(opt) => {
                  setFormValue({ completedByUser: opt?.staff });
                }}
              />
              {errors.completedByUser && (
                <p className="my-2 text-sm font-semibold text-red-700">{errors.completedByUser}</p>
              )}
            </div>
          </>
        ) : null}
        <Button label={isLoading ? "Loading ..." : action} onClick={() => null} disabled={isLoading} />
      </form>
    </div>
  );
};

export default TaskForm;
