import React, { useEffect } from "react";
import useAddTask from "./hooks/useAddTask";
import TaskForm from "./TaskForm";

const AddTask = ({ isOpen, onClose }) => {
  const { addTask, isLoading, isSuccess } = useAddTask();

  const handleCreate = (newTaskValues) => {
    addTask(newTaskValues);
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return <TaskForm onSubmit={handleCreate} isLoading={isLoading} action="Create" />;
};

export default AddTask;
