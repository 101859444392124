import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { omit } from "lodash";
import useToast from "@src/utils/hooks/useToast";
import useProjects from "../projects/hooks/useProjects";
import usePartners from "../partners/hooks/usePartners";
import bidFormSchema from "./formSchema";
import bidUiSchema from "./uiSchema";
import useAddBid from "./hooks/useAddBid";

const AddBid = ({ onClose, projectId }) => {
  const { options: projectsAddressAsOptions, isLoading: projectOptionLoading } = useProjects();
  const { options: partnersAsOptions, isLoading: partnersOptionsLoading } = usePartners();

  const { addBidAsync, error } = useAddBid(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();

  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    const data = {
      ...omit(formState, ["project", "partner", "createdAt"]),
    };
    if (formState.project) {
      data.projectId = formState.project?.value;
    }
    if (formState.partner) {
      data.partnerId = formState.partner?.value;
    }
    if (formState.status) {
      data.status = formState?.status?.value;
    }
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const addBidResp = await addBidAsync(data);
    if (addBidResp.createBid?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Bid Successfully Created");
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    if (projectOptionLoading || partnersOptionsLoading) {
      return;
    }
    const finalSchema = {
      ...bidFormSchema,
    };
    const allProperties = bidFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "project") {
          acc[key].options = projectsAddressAsOptions;
          if (projectId) {
            acc[key].initialValue = projectsAddressAsOptions.find((option) => option.value === projectId);
          }
        }
        if (key === "partner") {
          acc[key].options = partnersAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [projectsAddressAsOptions, partnersAsOptions, projectOptionLoading, partnersOptionsLoading]);

  if (projectOptionLoading || partnersOptionsLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={bidUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddBid;
