import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_EMAILS = gql`
  query getEmails($filters: EmailFilterFields!) {
    getEmails(filters: $filters) {
      id
      createdAt
      subject
      emailSender
      projectId
      html
      plainContent
      attachementInfo
      messageId
      inReplyTo
      referencesList
      fromName
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
      sender {
        firstName
        lastName
        email
        role
        avatar
      }
      recipients {
        to
        cc
        bcc
      }
      files {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        emailId
        shareUrl
      }
      thread {
        id
        createdAt
        subject
        emailSender
        projectId
        html
        plainContent
        attachementInfo
        messageId
        inReplyTo
        referencesList
        sender {
          firstName
          lastName
          email
          role
          avatar
        }
        recipients {
          to
          cc
          bcc
        }
        files {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          emailId
          shareUrl
        }
      }
      label {
        name
        color
      }
    }
  }
`;

const GET_EMAIL = gql`
  query getEmailByID($id: Int!) {
    getEmailByID(id: $id) {
      id
      createdAt
      subject
      emailSender
      projectId
      html
      plainContent
      attachementInfo
      messageId
      inReplyTo
      referencesList
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
      sender {
        firstName
        lastName
        email
        role
        avatar
      }
      recipients {
        to
        cc
        bcc
      }
      files {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        type
        emailId
        shareUrl
      }
      thread {
        id
        createdAt
        subject
        emailSender
        projectId
        html
        plainContent
        attachementInfo
        messageId
        inReplyTo
        referencesList
        sender {
          firstName
          lastName
          email
          role
          avatar
        }
        recipients {
          to
          cc
          bcc
        }
        files {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          shareUrl
        }
      }
    }
  }
`;

const SEND_EMAIL = gql`
  mutation sendEmail($input: SendEmailRequest!) {
    sendEmail(input: $input) {
      code
      message
      success
      data {
        id
        createdAt
        subject
        emailSender
        projectId
        html
        plainContent
        attachementInfo
        messageId
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
        }
        sender {
          firstName
          lastName
          email
          role
          avatar
        }
        recipients {
          to
          cc
          bcc
        }
        files {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
          emailId
          shareUrl
        }
      }
    }
  }
`;

const DELETE_EMAIL = gql`
  mutation deleteEmail($id: Int!) {
    deleteEmail(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

const UPDATE_EMAIL = gql`
  mutation updateEmail($input: UpdateEmailRequest!) {
    updateEmail(input: $input) {
      code
      message
      success
      data {
        id
      }
    }
  }
`;

const GET_EMAILS_COUNT = gql`
  query getEmailsCount($filters: EmailFilterFields!) {
    getEmailsCount(filters: $filters)
  }
`;

export const fetchEmails = async (filters = {}) => {
  return await graphQLClient.request(GET_EMAILS, { filters });
};

export const fetchEmail = async (id) => {
  return await graphQLClient.request(GET_EMAIL, { id });
};

export const sendEmail = async (input) => {
  return await graphQLClient.request(SEND_EMAIL, { input });
};

export const deleteEmail = async (id) => {
  return await graphQLClient.request(DELETE_EMAIL, { id });
};

export const updateEmail = async (input) => {
  return await graphQLClient.request(UPDATE_EMAIL, { input });
};

export const getEmailsCount = async (filters = {}) => {
  return await graphQLClient.request(GET_EMAILS_COUNT, { filters });
};
