import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  presentations: [],
  presentationItems: [],
};

export const presentationsSlice = createSlice({
  name: "presentations",
  initialState,
  reducers: {
    setPresentations: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        presentations: action.payload,
      };
    },
    setPresentationItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        presentationItems: action.payload,
      };
    },
  },
});

export const { setPresentations, setPresentationItems } = presentationsSlice.actions;

export default presentationsSlice.reducer;

export const getPresentations = (state) => state.presentations.presentations;
export const getPresentationItems = (state) => state.presentations.presentationItems;
