import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchMediaCoordinates } from "../../api";
import { getMediaCoordinatesByMediaId, setMediaCoordinatesByMediaId } from "../../mediaCoordinatesSlice";

const useMediaCoordinatesWithFilters = (filters = {}) => {
  const dispatch = useDispatch();
  const mediaCoordinates = useSelector(getMediaCoordinatesByMediaId(filters.mediaId));

  const { data, isLoading } = useQuery(["mediaCoordinates", filters], () => fetchMediaCoordinates(filters), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(
      setMediaCoordinatesByMediaId({
        mediaId: filters.mediaId,
        mediaCoordinates: data.getMediaCoordinates,
      })
    );
  }, [data, isLoading, dispatch]);

  return {
    data: mediaCoordinates || [],
    isLoading,
  };
};

export default useMediaCoordinatesWithFilters;
