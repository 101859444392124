import dayjs from "dayjs";

import MetabaseDashboard from "@src/utils/components/MetabaseDashboard";
import useUser from "@features/auth/hooks/useUser";
import useGetMetabaseDashboard from "@features/dashboard/hooks/useGetMetabaseDashboard";
import QuickView from "../layout/QuickView";
import useUiPages from "../layout/hooks/useUIPages";

let greeting;
export default function Dashboard() {
  const { user } = useUser();
  const { data: uiPages } = useUiPages();

  const { data } = useGetMetabaseDashboard(1);

  const month = dayjs().format("MMMM");
  const time = dayjs().hour();
  const dayformatted = dayjs().format("dddd");
  const day = dayjs().format("DD");

  if (time < 12) {
    greeting = "Good morning!";
  } else if (time < 18) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  return (
    <div className=" mx-auto min-h-screen  p-16">
      <QuickView uiPages={uiPages} />
      <div className="text-center">
        <div className="mb-1">
          {" "}
          {dayformatted}, {month} {day}{" "}
        </div>
        <div className="text-3xl">
          {greeting}, {user?.firstName}
        </div>
        <p className="mb-4 py-2 text-sm font-light">Here's what is happening with your projects today..</p>
      </div>
      <div className="w-full">
        <MetabaseDashboard dashboardUrl={data?.embeddingURL} title="High Level Metrics" />
      </div>
    </div>
  );
}
