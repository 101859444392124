import dayjs from "dayjs";
import { TableCells, TableFilters, TableCards } from "@buildappeal/react-component-library";
import generateInitials from "@src/utils/generateInitials";
import { BA_USER_TYPES } from "@src/utils/constants";
import { isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";

export const commonTasksColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "task",
    Header: "Task",
    accessor: (originalRow) => `${getTaskTitle(originalRow)}`,
  },
  {
    id: "startDate",
    Header: "Start Date",
    accessor: (originalRow) => (originalRow.startOn ? dayjs(originalRow.startOn).format() : null),
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  {
    id: "dueDate",
    Header: "Due Date",
    accessor: (originalRow) => (originalRow.dueDate ? dayjs(originalRow.dueDate).format() : null),
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  {
    id: "assignedTo",
    Header: "Assigned To",
    accessor: (originalRow) => {
      const { assignedToUser = null } = originalRow || {};
      const finalUser = assignedToUser?.role?.[0] ? assignedToUser[assignedToUser.role[0]] : null;
      return finalUser?.fullName || "";
    },
    Cell: (originalRow) => {
      const { assignedToUser = null } = originalRow.row?.original || {};
      const finalUser = assignedToUser?.role?.[0] ? assignedToUser[`${assignedToUser.role[0]}`] : null;
      return (
        <TableCells.UserCell
          getValue={() => finalUser?.fullName}
          column={originalRow.column}
          row={originalRow.row}
          avatarSrc={finalUser?.avatar}
          initials={generateInitials(finalUser?.fullName || finalUser?.email)}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "assignedBy",
    Header: "Assigned By",
    accessor: (originalRow) => {
      const { assignedByUser = null } = originalRow || {};
      const finalUser = assignedByUser?.role?.[0] ? assignedByUser[assignedByUser.role[0]] : null;
      return finalUser?.fullName || "";
    },
    Cell: (originalRow) => {
      const { assignedByUser = null } = originalRow.row?.original || {};
      const finalUser = assignedByUser?.role?.[0] ? assignedByUser[`${assignedByUser.role[0]}`] : null;
      return (
        <TableCells.UserCell
          getValue={() => finalUser?.fullName}
          column={originalRow.column}
          row={originalRow.row}
          avatarSrc={finalUser?.avatar}
          initials={generateInitials(finalUser?.fullName || finalUser?.email)}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "completed",
    Header: "Completed",
    accessor: (originalRow) => {
      return originalRow?.completed ? "true" : "false";
    },
    Cell: (originalRow) => <TableCells.BooleanCell getValue={() => !!originalRow.row.original.completed} />,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
];

export const columns = [...commonTasksColumns];
columns.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.project?.places?.addressLine1
      ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original.project?.places?.city}
        addressState={originalRow.row.original.project?.places?.state}
        addressZip={originalRow.row.original.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const statusOptions = [
  {
    value: "Completed",
    title: "Completed",
  },
  {
    value: "Closed",
    title: "Closed",
  },
  {
    value: "Suppressed",
    title: "Suppressed",
  },
  {
    value: "Inactive",
    title: "Inactive",
  },
  {
    value: "Active",
    title: "Active",
  },
];

export const assignedToTypeOptions = [
  {
    value: BA_USER_TYPES.CLIENT,
    title: "Client",
  },
  {
    value: BA_USER_TYPES.STAFF,
    title: "Staff",
  },
];

export const getTaskActionObjects = (task) => {
  if (task?.actionsObjects) {
    return {
      ...task.actionsObjects,
      name: `${task.actionsObjects?.action.verbPresent} ${task.actionsObjects?.object.description}`,
    };
  }
};

export const getTaskTitle = (task) => {
  return `${task.actionsObjects?.object.description}`;
};

export const loadingPlaceholderTask = {
  id: "",
  createdAt: "",
  startOn: "",
  description: "",
  status: "",
  dueDate: "",
  completed: "",
  completedByUser: {
    id: "",
    role: [""],
    staff: {
      id: "",
      avatar: "",
      fullName: "",
    },
  },
  assignedToType: "",
  completedAt: "",
  actionsObjects: {
    id: "",
    action: {
      id: "",
      verbPresent: "",
      verbPast: "",
    },
    object: {
      id: "",
      description: "",
      icon: "",
    },
  },
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    },
  },
  assignedByUser: {
    id: "",
    email: "",
    avatar: "",
    fullName: "",
  },
  assignedToUser: {
    id: "",
    email: "",
    avatar: "",
    fullName: "",
  },
  invoiceItem: "",
  projectSpace: {
    id: "",
    spaceId: "",
    name: "",
  },
};

export const transformTaskCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    "assignedToUser.fullName": "Assigned To",
    "assignedByUser.fullName": "Assigned By",
  };

  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => ({
      ...refinement,
      label: isAlgoliaAttributeValueEmpty(refinement.label)
        ? `No ${refinementAttributeToLabelMap[item.attribute]}`
        : titleCase(refinement.label),
    })),
  }));
};
