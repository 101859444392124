import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateProjects } from "../../api";

const useUpdateProjects = (filters = {}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const updateQueryClient = (data) => {
    queryClient.setQueryData(["projects", filters], (old) => {
      if (!old) return null;

      return {
        getProjects: old.getProjects?.map((project) => {
          const updatedProject = data.updateProjects?.data?.find((projectUpdated) => projectUpdated.id === project.id);

          if (updatedProject) {
            return {
              ...project,
              ...updatedProject,
            };
          }
          return project;
        }),
      };
    });

    queryClient.setQueryData(["leadProjects", { projectStages: { type: "Lead" } }], (old) => {
      if (!old) return null;

      return {
        getProjects: old.getProjects?.map((project) => {
          const updatedProject = data.updateProjects?.data?.find((projectUpdated) => projectUpdated.id === project.id);

          if (updatedProject) {
            return {
              ...project,
              ...updatedProject,
            };
          }
          return project;
        }),
      };
    });
  };

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      return updateProjects(input);
    },
    {
      onSuccess: (data, input) => {
        if (!data.updateProjects.success) {
          setError(`Error: ${data.updateProjects.message}`);
        } else {
          setIsSuccess(true);
          updateQueryClient(data, input);
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return { updateProjects: mutate, updateProjectsAsync: mutateAsync, isLoading, isSuccess, error };
};

export default useUpdateProjects;
