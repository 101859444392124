import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createInvoice } from "../../api";

const useAddInvoice = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const { mutate, isLoading, isSuccess, mutateAsync } = useMutation(
    (input) => {
      return createInvoice(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createInvoice.success) {
          setError(`Error: ${data.createInvoice?.message}`);
        } else {
          queryClient.setQueryData(["invoices", filters], (old) => {
            return { getInvoices: [data.createInvoice.data, ...(old?.getInvoices || [])] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addInvoice: mutate,
    isLoading,
    isSuccess,
    addInvoiceAsync: mutateAsync,
    error,
  };
};

export default useAddInvoice;
