import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_MATERIALS = gql`
  query getMaterials($filters: MaterialFilterFields!) {
    getMaterials(filters: $filters) {
      id
      name
      media {
        id
        name
        fileType
        path
        type
        isThumbnail
        sortOrder
      }
      documents {
        id
        name
        fileType
        path
        type
        sortOrder
      }
      parent {
        id
        name
      }
    }
  }
`;

const GET_MATERIAL = gql`
  query getMaterialById($id: Int!) {
    getMaterialById(id: $id) {
      id
      name
      media {
        id
        name
        fileType
        path
        type
        isThumbnail
        sortOrder
      }
      documents {
        id
        name
        fileType
        path
        type
        sortOrder
      }
      parent {
        id
        name
      }
    }
  }
`;

const ADD_MATERIAL = gql`
  mutation addMaterial($input: CreateMaterialRequest!) {
    addMaterial(input: $input) {
      code
      success
      message
      data {
        id
        name
        media {
          id
          name
          fileType
          path
          type
          isThumbnail
        }
        documents {
          id
          name
          fileType
          path
          type
        }
        parent {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_MATERIAL = gql`
  mutation updateMaterial($input: UpdateMaterialRequest!) {
    updateMaterial(input: $input) {
      code
      success
      message
      data {
        id
        name
        media {
          id
          name
          fileType
          path
          type
          isThumbnail
          sortOrder
        }
        documents {
          id
          name
          fileType
          path
          type
          sortOrder
        }
        parent {
          id
          name
        }
      }
    }
  }
`;

const DELETE_MATERIAL = gql`
  mutation deleteMaterial($id: Int!) {
    deleteMaterial(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

export const fetchMaterials = async (filters = {}) => {
  return await graphQLClient.request(GET_MATERIALS, { filters });
};

export const fetchMaterialById = async (id) => {
  return await graphQLClient.request(GET_MATERIAL, { id });
};

export const addMaterial = async (input = {}) => {
  return await graphQLClient.request(ADD_MATERIAL, { input });
};

export const updateMaterial = async (input = {}) => {
  return await graphQLClient.request(UPDATE_MATERIAL, { input });
};

export const deleteMaterial = async (id) => {
  return await graphQLClient.request(DELETE_MATERIAL, { id });
};
