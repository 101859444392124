const formSchema = {
  title: "Create Purchase",
  properties: {
    project: {
      type: "project",
      label: "Project",
      required: true,
    },
    supplier: {
      type: "select",
      label: "Supplier",
      required: true,
    },
    supplierQuote: {
      type: "select",
      label: "Supplier Quote",
    },
    vendorOrderNumber: {
      type: "text",
      label: "Vendor Order Number",
    },
    subTotal: {
      type: "currency",
      label: "Sub Total",
    },
    shippingTotal: {
      type: "currency",
      label: "Shipping Total",
    },
    taxTotal: {
      type: "currency",
      label: "Tax Total",
    },
    total: {
      type: "currency",
      label: "Total",
    },
    purchaseDate: {
      type: "datetime",
      label: "Purchase Date",
      required: true,
    },
    estimatedDelivery: {
      type: "datetime",
      label: "Estimated Delivery",
      required: true,
    },
    isInventory: {
      type: "checkbox",
      label: "Is Inventory",
    },
    documents: {
      type: "file",
      label: "Documents",
      acceptedFileTypes: [".pdf", ".docx", "image/jpeg", "image/png", "video/*", "image/heic", "image/webp"],
      multiple: true,
      maxFiles: 25,
      handleSelectedFiles: () => {},
      required: true,
    },
  },
};

export default formSchema;
