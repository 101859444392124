import axios from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { chunk } from "lodash";

import cloudinaryUtils from "@src/utils/api/cloudinaryApi";
import { getMultiPreSignedUrl, uploadtoS3, addFile } from "../../api";
import { getFileBlob, getFileURL, DEFAULT_UPLOAD_SIZE_LIMIT, FILE_TYPES } from "../../utils";

const useMultiFilesUpload = () => {
  const [uploadPercent, setUploadPercent] = useState(0);
  const [uploadStatus, setUploadStatus] = useState({});
  const { mutateAsync: addFileAsync } = useMutation((input) => {
    return addFile(input);
  });

  const addFileToDB = async (fileInfo) => {
    let finalFileKey = fileInfo.key;
    if (fileInfo.type === FILE_TYPES.document) {
      if (fileInfo.keyWithExt) {
        finalFileKey = fileInfo.keyWithExt;
      } else {
        const extension = fileInfo.file.name?.split(".").pop();
        finalFileKey = `${fileInfo.key}${extension ? "." + extension : ""}`;
      }
    }

    const finalFileInput = {
      name: fileInfo.file.name,
      path: fileInfo.key,
      fileType: fileInfo.file.type || fileInfo.contentType,
      contentType: fileInfo.contentType || null,
      type: fileInfo.type,
      tags: fileInfo.tags || null,
      sortOrder: fileInfo.sortOrder || null,
      description: fileInfo.description || null,
      isThumbnail: fileInfo.isThumbnail ?? false,
      isFeatured: fileInfo.isFeatured ?? false,
      projectId: fileInfo.projectId || null,
      productId: fileInfo.productId || null,
      emailId: fileInfo.emailId || null,
      purchaseId: fileInfo.purchaseId || null,
      noteId: fileInfo.notesId || null,
      designStylesId: fileInfo.designStylesId || null,
      looksId: fileInfo.looksId || null,
      supplierId: fileInfo.supplierId || null,
      spacesId: fileInfo.spacesId || null,
      projectSpacesId: fileInfo.projectSpacesId || null,
      invoiceId: fileInfo.invoiceId || null,
      contractId: fileInfo.contractId || null,
      finishes: fileInfo?.finishes || null,
      objectId: fileInfo?.objectId || null,
      materialId: fileInfo?.materialId || null,
      productVariantId: fileInfo?.productVariantId || null,
      billId: fileInfo?.billId || null,
      shareUrl:
        fileInfo?.type === FILE_TYPES.document ? `https://ba-public-files.s3.amazonaws.com/${finalFileKey}` : null,
    };
    const updateFileDataResp = await addFileAsync(finalFileInput);
    if (updateFileDataResp?.addFileInfo?.code === 200) {
      if (finalFileInput?.fileType?.includes("video")) {
        axios.get(`https://res.cloudinary.com/build-appeal/video/upload/v1/ba-media/staging/${finalFileInput?.path}`);
      }
    }
    return updateFileDataResp;
  };

  // Retrieves presigned url for each file
  // Uploads to S3 and returns the file key
  const startUpload = async (filesList, skipFileAdd = false) => {
    setUploadPercent(0);
    let updatedUploadStatus = {};
    const docFiles = [];
    filesList.forEach((fileInfo) => {
      if (fileInfo.type !== FILE_TYPES.media) {
        const extension = fileInfo.file.name?.split(".")?.pop() || "";
        docFiles.push({ ...fileInfo, key: `${fileInfo.key}${extension ? "." + extension : ""}` });
      }
      updatedUploadStatus[fileInfo.file.name] = "Started";
    });
    setUploadStatus((prevState) => ({
      ...prevState,
      ...updatedUploadStatus,
    }));
    let signedUrls = null;
    let fileSignedParams = [];

    if (docFiles.length) {
      fileSignedParams = docFiles.map((fileInfo) => {
        let finalContentType = fileInfo.file.type || fileInfo.contentType;
        if (fileInfo.file.name?.includes("dwg")) {
          finalContentType = "application/acad";
        }
        return {
          fileName: `${fileInfo.type === FILE_TYPES.secureDocument ? process.env.REACT_APP_STAGE + "/" : ""}${
            fileInfo.key
          }`,
          contentType: finalContentType,
          type: fileInfo.type,
        };
      });
      signedUrls = await getMultiPreSignedUrl(fileSignedParams);
      if (!signedUrls?.uploadUrls?.length) {
        setUploadPercent(100);
        setUploadStatus((prevState) => ({
          ...prevState,
          ...updatedUploadStatus,
        }));
        return;
      }
    }
    setUploadPercent(5);
    let uploadCount = 0;
    const uploadedFiles = [];
    let errorFileData = [];
    const percentIncrementPerFile = 95 / filesList.length;
    const filesSet = chunk(filesList, 5);
    for (let i = 0; i < filesSet.length; i++) {
      const filesInSet = filesSet[i];
      let finalRequests = [];
      const finalDBRequests = [];
      const fileDBUploadMap = {};
      // Collect all requests
      for (let j = 0; j < filesInSet.length; j++) {
        const fileInfo = filesInSet[j];
        const isMediaFile = fileInfo.type === FILE_TYPES.media;
        let formattedFile = isMediaFile
          ? fileInfo.file?.size > DEFAULT_UPLOAD_SIZE_LIMIT
            ? fileInfo.file
            : await getFileURL(fileInfo)
          : null;
        let uploadUrl = null;
        let finalContentType = null;
        if (!isMediaFile) {
          const extension = fileInfo.file.name?.split(".")?.pop() || "";
          fileInfo.keyWithExt = `${fileInfo.key}${extension ? "." + extension : ""}`;
          if (extension === "dwg") {
            fileInfo.contentType = "application/acad";
            finalContentType = "application/acad";
          }
          formattedFile = await getFileBlob(fileInfo);
          uploadUrl = signedUrls.uploadUrls.find(
            (url) =>
              url.key ===
              `${fileInfo.type === FILE_TYPES.secureDocument ? process.env.REACT_APP_STAGE + "/" : ""}${
                fileInfo.keyWithExt
              }`
          );
          if (!uploadUrl?.url) {
            setUploadPercent((i + 1) * percentIncrementPerFile + 5);
            setUploadStatus((prevState) => ({
              ...prevState,
              [fileInfo.file?.name || ""]: "Failed",
            }));
            continue;
          }
        }
        finalContentType = finalContentType && !isMediaFile ? finalContentType : formattedFile.type;
        const uploadReq = isMediaFile
          ? cloudinaryUtils.uploadCloudinaryFiles(
              { ...fileInfo, file: formattedFile },
              fileInfo.file?.type?.includes("video") || fileInfo.file?.type?.includes("audio")
            )
          : uploadtoS3(formattedFile, uploadUrl.url, finalContentType);
        finalRequests.push(uploadReq);
        if (!skipFileAdd) {
          finalDBRequests.push(addFileToDB(fileInfo, skipFileAdd));
          fileDBUploadMap[j] = fileInfo;
        }
      }
      try {
        await Promise.all(finalRequests);
        if (finalDBRequests?.length) {
          const uploadedFilesResp = await Promise.all(finalDBRequests);
          // eslint-disable-next-line no-loop-func
          uploadedFilesResp.forEach((fileResp, index) => {
            if (fileResp?.addFileInfo?.success) {
              uploadedFiles.push({
                ...fileResp?.addFileInfo?.data,
                docType: fileDBUploadMap[index]?.docType,
                productVariantId: fileDBUploadMap[index].productVariantId,
                productId: fileDBUploadMap[index].productId,
                emailId: fileDBUploadMap[index]?.emailId,
              });
            } else {
              errorFileData.push(fileDBUploadMap[index]);
            }
          });
          setUploadStatus((prevStatus) => {
            const updatedStatus = {};
            uploadedFilesResp.forEach((fileResp, index) => {
              updatedStatus[fileDBUploadMap[index]?.file?.name] =
                fileResp?.addFileInfo?.code === 200 ? "Completed" : "Failed";
            });
            return {
              ...prevStatus,
              ...updatedStatus,
            };
          });
        }
        uploadCount += finalRequests.length;
      } catch (e) {
        setUploadStatus((prevState) => {
          const updatedStatus = filesInSet.reduce((acc, file) => {
            acc[file.file?.name] = "Failed";
            return acc;
          }, {});
          return {
            ...prevState,
            ...updatedStatus,
          };
        });
        errorFileData = errorFileData.concat(filesInSet);
      } finally {
        setUploadPercent((i + 1) * percentIncrementPerFile + 5);
      }
    }
    setUploadPercent(100);
    return { uploadCount, uploadedFiles, errorFileData };
  };

  const rejectFiles = (rejectedFiles) => {
    const updatedUploadStatus = {};
    rejectedFiles.forEach((fileInfo) => {
      updatedUploadStatus[fileInfo.file.name] = "Failed";
    });
    setUploadStatus((prevState) => ({
      ...prevState,
      ...updatedUploadStatus,
    }));
  };

  return {
    startUpload,
    uploadPercent,
    uploadStatus,
    rejectFiles,
  };
};

export default useMultiFilesUpload;
