const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: ["name", "spaces"],
      isLast: true,
    },
    {
      title: "Media",
      properties: ["media"],
    },
  ],
};

export default uiSchema;
