import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createBudget } from "../../api";

const useAddBudget = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addBudgetMutation = useMutation(
    (input) => {
      return createBudget(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createBudget.success) {
          setError(`Error: ${data.createBudget?.message}`);
        } else {
          queryClient.setQueryData(["budgets", filters], (old) => {
            return { getBudgets: [data.createBudget.data, ...old?.getBudgets] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addBudget: addBudgetMutation.mutate,
    addBudgetAsync: addBudgetMutation.mutateAsync,
    addBudgetLoading: addBudgetMutation.isLoading,
    addBudgetSuccess: addBudgetMutation.isSuccess,
    error,
  };
};

export default useAddBudget;
