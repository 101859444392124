import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { fetchNotifications } from "../../api";
import { getNotifications, setNotifications } from "../../usersSlice";

const useNotifications = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);

  const { data, isLoading } = useQuery(["notifications", filters], () => fetchNotifications(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setNotifications(data.getNotifications));
  }, [data, isLoading, dispatch]);

  return {
    data: notifications,
    isLoading,
  };
};

export default useNotifications;
