import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { omit } from "lodash";
import useToast from "@src/utils/hooks/useToast";
import useBATypes from "@src/utils/hooks/useBATypes";
import useProjects from "../projects/hooks/useProjects";
import invoiceFormSchema from "./formSchema";
import invoiceUiSchema from "./uiSchema";
import useAddPurchaseOrder from "./hooks/useAddPurchaseOrder";

// Use Form component to display form
// Update formSchema after all dynamic options are fetched
// https://storybook-buildappeal.s3.amazonaws.com/main/index.html?path=/story/mdx-form--basic For details
const AddPurchaseOrder = ({ onClose, projectId }) => {
  const { options: projectsAddressAsOptions, isLoading: projectOptionLoading } = useProjects();

  const { addPurchaseOrderAsync, error } = useAddPurchaseOrder(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();
  const { options: purchaseOrderTypesAsOption, isLoading: purchaseOrderOptionsLoading } =
    useBATypes("purchaseOrderTypes");

  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    const data = {
      ...omit(formState, ["project", "purchaseOrderItems", "createdAt"]),
    };

    if (formState.project) {
      data.projectsId = formState.project?.value;
    }
    if (formState.total) {
      data.total = parseFloat(formState.total);
    }
    if (formState.status) {
      data.status = formState.status?.value;
    }
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const addPurchaseOrderResp = await addPurchaseOrderAsync(data);

    if (addPurchaseOrderResp.createPurchaseOrder?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Purchase Order Successfully Created");
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);
  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (projectOptionLoading || purchaseOrderOptionsLoading) {
      return;
    }
    const finalSchema = {
      ...invoiceFormSchema,
    };
    const allProperties = invoiceFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "project") {
          acc[key].options = projectsAddressAsOptions;
          if (projectId) {
            acc[key].initialValue = projectsAddressAsOptions.find((opt) => opt.value === projectId);
          }
        }
        if (key === "type") {
          acc[key].options = purchaseOrderTypesAsOption;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [projectsAddressAsOptions, purchaseOrderTypesAsOption, purchaseOrderOptionsLoading, projectOptionLoading]);

  if (projectOptionLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={invoiceUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddPurchaseOrder;
