import { productActions } from "@src/features/products/tableActionsMap";
import { supplierActions } from "@src/features/suppliers/tableActionsMap";
import { consultationsActions } from "@src/features/consultations/tableActionsMap";
import { contractsActions } from "@src/features/contracts/tableActionsMap";
import { emailActions } from "@src/features/emails/tableActionsMap";
import { invoiceActions } from "@src/features/invoices/tableActionsMap";
import { messageActions } from "@src/features/messages/tableActionsMap";
import { activitiesActions } from "@src/features/activities/tableActionsMap";
import { servicesActions } from "@src/features/services/tableActionsMap";
import { designStylesActions } from "@src/features/designs/tableActionsMap";
import { storyboardsActions } from "@src/features/storyboards/tableActionsMap";
import { tasksActions } from "@src/features/tasks/tableActionsMap";
import { leadsActions } from "@src/features/leads/tableActionsMap";

import { bidActions } from "@src/features/bids/tableActionsMap";
import { billActions } from "@src/features/bills/tableActionsMap";
import { budgetActions } from "@src/features/budgets/tableActionsMap";
import { changeOrderActions } from "@src/features/changeOrders/tableActionsMap";
import { proposalActions } from "@src/features/proposals/tableActionsMap";
import { purchaseOrderActions } from "@src/features/purchaseOrders/tableActionsMap";
import { purchaseActions } from "@src/features/purchases/tableActionsMap";
import { staffActions } from "@src/features/staff/tableActionsMap";
import { clientActions } from "@src/features/clients/tableActionsMap";
import { partnersActions } from "@src/features/partners/tableActionsMap";
import { contactsActions } from "@src/features/contacts/tableActionsMap";
import { noteActions } from "@src/features/notes/tableActionsMap";
import { actionsObjectsActions } from "@src/features/actionsObjects/tableActionsMap";
import { estimateActions } from "@src/features/estimates/tableActionsMap";
import { projectsActions } from "@src/features/projects/tableActionsMap";
import { peopleActions } from "@src/features/people/tableActionsMap";
import { materialActions } from "@src/features/materials/tableActionsMap";
import { finishActions } from "@src/features/finishes/tableActionsMap";
import { objectActions } from "@src/features/objects/tableActionsMap";
import { looksActions } from "@src/features/looks/tableActionsMap";

const actionsMap = {
  "/products": productActions,
  "/suppliers": supplierActions,
  "/consultations": consultationsActions,
  "/contracts": contractsActions,
  "/emails": emailActions,
  "/messages": messageActions,
  "/activities": activitiesActions,
  "/financials/invoices": invoiceActions,
  "/financials/bids": bidActions,
  "/financials/bills": billActions,
  "/financials/budgets": budgetActions,
  "/financials/change-orders": changeOrderActions,
  "/financials/proposals": proposalActions,
  "/financials/purchase-orders": purchaseOrderActions,
  "/financials/purchases": purchaseActions,
  "/financials/estimates": estimateActions,
  "/services": servicesActions,
  "/designs/styles": designStylesActions,
  "/designs/storyboards": storyboardsActions,
  "/tasks": tasksActions,
  "/staff": staffActions,
  "/leads": leadsActions,
  "/projects": projectsActions,
  "/tasks/actions": actionsObjectsActions,
  "/clients": clientActions,
  "/partners": partnersActions,
  "/contacts": contactsActions,
  "/notes": noteActions,
  "/people": peopleActions,
  "/materials": materialActions,
  "/finishes": finishActions,
  "/objects": objectActions,
  "/looks": looksActions,
};

export default actionsMap;
