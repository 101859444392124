import { TableCells } from "@buildappeal/react-component-library";
import { Link } from "react-router-dom";
export const commonStoryboardsColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  {
    id: "products",
    Header: "Products",
    accessor: "products",
    Cell: (originalRow) => {
      return originalRow.getValue().map((product) => {
        return (
          <Link
            key={product.id}
            to={`/products/${product?.id}`}
            label={product?.name}
            className="cursor-pointer"
            as={<Link to="/" />}
          >
            <TableCells.BadgeCell color={"gray"} getValue={() => product?.name} />
          </Link>
        );
      });
    },
  },
];

export const columns = [...commonStoryboardsColumns];

export const loadingPlaceholderStoryboard = {
  id: "",
  createdAt: "",
  name: "",
  products: [
    {
      id: "",
      name: "",
    },
  ],
};
