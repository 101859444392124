import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

const initialState = {
  designStyles: [],
  designStyleById: {},
};

export const designStylesSlice = createSlice({
  name: "designsStyles",
  initialState,
  reducers: {
    setDesignStyles: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        designStyles: action.payload,
        designStyleById: keyBy(action.payload, "id"),
      };
    },
    setDesignStyleDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        designStyleById: {
          ...originalState.designStyleById,
          [action.payload.id]: action.payload,
        },
      };
    },
  },
});

export const { setDesignStyles, setDesignStyleDetail } = designStylesSlice.actions;

export default designStylesSlice.reducer;

export const getDesignStyles = (state) => state.designsStyles.designStyles;
export const selectDesignStyleById = (id) => (state) => state.designsStyles.designStyleById[id];
