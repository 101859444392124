const uiSchema = {
  groups: [
    { title: "Add Contact To", properties: ["addContactTo", "partner", "supplier"] },
    {
      title: "Details",
      properties: ["firstName", "phone", "email", "isPrimary"],
    },
    {
      title: "Profile Photo",
      properties: ["avatar"],
      isLast: true,
    },
  ],
};

export default uiSchema;
