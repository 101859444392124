import generateInitials from "@src/utils/generateInitials";

export const EMAIL_FROM_TYPES = {
  CLIENT: "client",
  PARTNER: "partner",
  SUPPLIER: "supplier",
};

export const getLastEmailThreadSender = (email) => {
  if (!email?.thread?.length) {
    return [email?.sender?.email] || [];
  }
  const lastEmail = email.thread[email.thread.length - 1];
  const finalRecipients = [lastEmail.sender?.email];
  if (lastEmail.recipients?.length) {
    lastEmail.recipients.forEach((recipient) => {
      if (recipient.to?.length) {
        recipient.to.forEach((email) => {
          if (!finalRecipients.includes(email)) {
            finalRecipients.push(email);
          }
        });
      }
    });
  }
  return finalRecipients;
};

export const getEmailThreadInReplyTo = (email, sender) => {
  if (!email?.thread?.length) {
    return sender?.email || "";
  }
  const lastEmail = email.thread[email.thread.length - 1];
  return lastEmail?.inReplyTo || "";
};

export const loadingPlaceholderEmail = {
  id: "",
  createdAt: "",
  subject: "",
  emailSender: "",
  projectId: "",
  html: "",
  plainContent: "",
  attachementInfo: null,
  messageId: "",
  inReplyTo: null,
  references: null,
  project: {
    id: "",
    places: {},
  },
  sender: {},
  recipients: [
    {
      to: [],
      cc: null,
      bcc: null,
    },
  ],
};

export const generateSenderInitials = (user = {}) => {
  const { firstName, lastName, email } = user;

  if (firstName && lastName) {
    return generateInitials(firstName + " " + lastName);
  }
  if (email) {
    return generateInitials(email);
  }

  return "BA";
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      resolve(fileReader.result.split("base64,")?.[1]);
    };
    fileReader.onerror = function (error) {
      reject("Error: ", error);
    };
  });
};
export const prepareAttachments = async (files) => {
  const unresolvedFiles = files.map(async (file) => ({
    content: await getBase64(file),
    filename: file.name,
    type: file.type,
    disposition: "attachment",
  }));
  return await Promise.all(unresolvedFiles);
};

export const transformTo = (user) => {
  const final = {
    id: user.id,
    email: user.email,
    fullName: user.fullName?.trim() || user.companyName?.trim() || user.name?.trim() || user.email,
    src: user.avatar || user.logo,
    isEmailValid: true,
    projects: user.projects,
    type: user.type || user.personType,
  };

  if (Array.isArray(user.logo)) {
    final.src = user.logo?.[0];
  }

  return final;
};

export const removeStyleTag = (html) => {
  return html.replace(/(<style[\w\W]+style>)/g, "");
};

export const removeMetaTags = (html) => {
  const htmlCleaned = html.replace(/<meta\sname=".*?\/>/g, "");
  return htmlCleaned.replace(/<meta\sname=".*?>/g, "");
};

export const cleanHtml = (html) => {
  return removeMetaTags(removeStyleTag(html));
};
