import { Modal, Button } from "@buildappeal/react-component-library";

const DeleteModal = ({ isOpen, onClose, onDelete }) => {
  return (
    <Modal title="Delete Contact" isOpen={isOpen} onClose={() => onClose()}>
      <div className="p-10">
        <h2 className="mb-3 text-xl font-medium">Are you sure you want to delete this contact?</h2>
        <h4>Note: You can't revert the changes after deletion</h4>
        <Button className="mt-8" label="Yes" size="md" onClick={() => onDelete()} />
        <Button className="mt-8 ml-8" label="No" size="md" onClick={() => onClose()} />
      </div>
    </Modal>
  );
};

export default DeleteModal;
