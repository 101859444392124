export const US_STATES = [
  {
    title: "Alabama",
    value: "AL",
  },
  {
    title: "Alaska",
    value: "AK",
  },
  {
    title: "Arizona",
    value: "AZ",
  },
  {
    title: "Arkansas",
    value: "AR",
  },
  {
    title: "California",
    value: "CA",
  },
  {
    title: "Colorado",
    value: "CO",
  },
  {
    title: "Connecticut",
    value: "CT",
  },
  {
    title: "Delaware",
    value: "DE",
  },
  {
    title: "Florida",
    value: "FL",
  },
  {
    title: "Georgia",
    value: "GA",
  },
  {
    title: "Hawaii",
    value: "HI",
  },
  {
    title: "Idaho",
    value: "ID",
  },
  {
    title: "Illinois",
    value: "IL",
  },
  {
    title: "Indiana",
    value: "IN",
  },
  {
    title: "Iowa",
    value: "IA",
  },
  {
    title: "Kansas",
    value: "KS",
  },
  {
    title: "Kentucky",
    value: "KY",
  },
  {
    title: "Louisiana",
    value: "LA",
  },
  {
    title: "Maine",
    value: "ME",
  },
  {
    title: "Maryland",
    value: "MD",
  },
  {
    title: "Massachusetts",
    value: "MA",
  },
  {
    title: "Michigan",
    value: "MI",
  },
  {
    title: "Minnesota",
    value: "MN",
  },
  {
    title: "Mississippi",
    value: "MS",
  },
  {
    title: "Missouri",
    value: "MO",
  },
  {
    title: "Montana",
    value: "MT",
  },
  {
    title: "Nebraska",
    value: "NE",
  },
  {
    title: "Nevada",
    value: "NV",
  },
  {
    title: "New Hampshire",
    value: "NH",
  },
  {
    title: "New Jersey",
    value: "NJ",
  },
  {
    title: "New Mexico",
    value: "NM",
  },
  {
    title: "New York",
    value: "NY",
  },
  {
    title: "North Carolina",
    value: "NC",
  },
  {
    title: "North Dakota",
    value: "ND",
  },
  {
    title: "Ohio",
    value: "OH",
  },
  {
    title: "Oklahoma",
    value: "OK",
  },
  {
    title: "Oregon",
    value: "OR",
  },
  {
    title: "Pennsylvania",
    value: "PA",
  },
  {
    title: "Rhode Island",
    value: "RI",
  },
  {
    title: "South Carolina",
    value: "SC",
  },
  {
    title: "South Dakota",
    value: "SD",
  },
  {
    title: "Tennessee",
    value: "TN",
  },
  {
    title: "Texas",
    value: "TX",
  },
  {
    title: "Utah",
    value: "UT",
  },
  {
    title: "Vermont",
    value: "VT",
  },
  {
    title: "Virginia",
    value: "VA",
  },
  {
    title: "Washington",
    value: "WA",
  },
  {
    title: "West Virginia",
    value: "WV",
  },
  {
    title: "Wisconsin",
    value: "WI",
  },
  {
    title: "Wyoming",
    value: "WY",
  },
];

export const PLACE_STORIES = [1, 2, 3, 4, 5, 6];
export const SCOPE_ROW_STATES = {
  SCOPE: "scope",
  SCOPE_ESTIMATE: "scope_estimate",
  ESTIMATE: "estimate",
};

export const BA_USER_TYPES = {
  CLIENT: "client",
  STAFF: "staff",
  PARTNER: "partner",
  ADMIN: "admin",
};

export const LOADING_TYPES = {
  SPINNER: "spinner",
  HOUSE: "house",
};

export const FALLBACK_IMAGE_SRC =
  "https://res.cloudinary.com/build-appeal/image/upload/v1645208023/fallback-image_y4ut9f.png";

export const UNIT_TYPES = ["each", "sqft", "linear ft"];

export const STATUS_INPUT = ["Active", "Inactive"];

export const BA_FILE_TABLE_TYPES = {
  MEDIA: "media",
  DOCUMENT: "document",
  CAD: "cad",
};

export const PARTNER_DOCUMENT_TYPES = {
  COI: "CERTIFICATE_OF_INSURANCE",
  GENERAL_LIABILITY: "GENERAL_LIABILITY",
  W9: "W9",
  WORKERS_COMPENSATION: "WORKERS_COMPENSATION",
  COMPANY_LICENSE: "COMPANY_LICENSE",
};

export const AUTHORIZED_EMAILS = [
  "alex@buildappeal.com",
  "nelson@buildappeal.com",
  "cole@buildappeal.com",
  "kavita@buildappeal.com",
];

export const SAVING_STATES = {
  SAVING: "saving",
  SAVED: "saved",
  ERROR: "error",
  INITIAL: "",
};
