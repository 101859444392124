import { TableCells, NavCard, TableCards, TableFilters } from "@buildappeal/react-component-library";
import { Link } from "react-router-dom";
import { isImageFile } from "@src/utils/isMedia";

export const looksColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  image: {
    id: "image",
    Header: "image",
    accessor: "image",
    renderCard: ({ row: { original }, column, table }) => {
      const finalImg = original.media?.length
        ? original.media.find((media) => media.isThumbnail)?.path ||
          original.media.find((media) => isImageFile(media.fileType))?.path ||
          original.media?.[0]?.path
        : original.photos?.[0] || "";
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          imgTransformations={{
            sizes: "(max-width: 768px) 400px, (max-width: 1024px) 500px",
            srcSetArray: ["400", "500"],
            maxWidth: "700",
          }}
          showPlaceholder
          imageOnly
        />
      );
    },
    Cell: ({ row: { original }, column, table }) => {
      const finalImg = original.media?.length
        ? original.media.find((media) => media.isThumbnail)?.path ||
          original.media.find((media) => isImageFile(media.fileType))?.path ||
          original.media?.[0]?.path
        : original.photos?.[0] || "";
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={original.name}
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
  },
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    Cell: ({ row: { original }, column, table }) => {
      const finalImg = original.media?.length
        ? original.media.find((media) => media.isThumbnail)?.path ||
          original.media.find((media) => isImageFile(media.fileType))?.path ||
          original.media?.[0]?.path
        : original.photos?.[0] || "";
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={original.name}
          description={original.description || ""}
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.TitleRow
          getValue={() => {
            return row?.original?.name || "";
          }}
          subtitle={row.original?.description}
          row={row}
          column={column}
          table={table}
        />
      );
    },
  },
  products: {
    id: "products",
    Header: "Products",
    accessor: "products",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    Cell: (originalRow) => {
      return originalRow.row?.original?.products?.map((product) => {
        return (
          <Link
            key={product.id}
            to={`/products/${product?.id}`}
            label={product?.name}
            className="cursor-pointer"
            as={<Link to="/" />}
          >
            <TableCells.BadgeCell color={"gray"} value={product?.name} {...originalRow} />
          </Link>
        );
      });
    },
  },
};
