import { TableCells, TableFilters } from "@buildappeal/react-component-library";
import generateInitials from "@src/utils/generateInitials";

export const activitiesColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: (originalRow) =>
      originalRow.project?.places?.addressLine1
        ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
        : null,
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          value={originalRow.value}
          addressCity={originalRow.row.original.project?.places?.city}
          addressState={originalRow.row.original.project?.places?.state}
          addressZip={originalRow.row.original.project?.places?.zipCode}
          column={originalRow.column}
          row={originalRow.row}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  activityDate: {
    id: "date",
    Header: "Date",
    accessor: "activityDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
  },
  descriptionStaff: {
    id: "activity",
    Header: "Activity",
    accessor: "descriptionStaff",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  actor: {
    id: "by",
    Header: "By",
    accessor: (row) => {
      return row?.actor?.fullName;
    },
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={originalRow?.row?.original?.actor?.fullName || ""}
          avatarSrc={originalRow?.row?.original?.actor?.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
          initials={generateInitials(originalRow?.row?.original?.actor?.fullName?.trim() || "BA")}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
};
