const formSchema = {
  title: "Create Contract",
  properties: {
    project: {
      type: "project",
      label: "Project",
      required: true,
    },
    template: {
      type: "select",
      label: "Document Template",
      required: true,
    },
    recipients: {
      type: "dynamicCheckBox",
      items: [],
      required: true,
    },
  },
};

export default formSchema;
