const getFirstCharacter = (text = "") => {
  const re = /[A-Za-z]/g;
  const matches = text.match(re);
  if (!matches) return "";

  return matches[0];
};

const generateInitials = (text = "") => {
  if (!text) return "";

  return text
    .split(" ")
    .map((item) => (item.includes("@") ? getFirstCharacter(item) : item[0]))
    .join("")
    .slice(0, 2);
};

export default generateInitials;
