import { ViewButtonGroup } from "@buildappeal/react-component-library";
import { UilSort } from "@iconscout/react-unicons";
import AlgoliaSortBy from "@src/utils/components/AlgoliaComponents/SortBy";
import { productSortByOptions } from "./utils";

const ProductViewButtonGroup = () => {
  return (
    <ViewButtonGroup>
      <AlgoliaSortBy icon={UilSort} items={productSortByOptions} />
    </ViewButtonGroup>
  );
};

export default ProductViewButtonGroup;
