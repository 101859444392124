import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { fetchActivitiesByUser } from "@src/features/activities/api";
import { getActivities, setActivities } from "../../usersSlice";

const useUserActivities = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const activities = useSelector(getActivities);
  const { data, isLoading, refetch } = useQuery(
    ["activities", filters],
    () => fetchActivitiesByUser({ ...filters, disablePagination: true }),
    {
      enabled: !hookOptions.disableQuery,
    }
  );

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setActivities(data?.getActivities));
  }, [data, isLoading]);

  return {
    data: activities || [],
    isLoading,
    refetch,
  };
};

export default useUserActivities;
