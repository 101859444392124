import { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { createProduct } from "../../api";
import { setBatchedProducts } from "../../productsSlice";

const useAddProduct = () => {
  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    ({ input }) => {
      setError("");
      setIsSuccess(false);
      return createProduct(input);
    },
    {
      onSuccess: (data) => {
        if (!data?.createProduct?.success) {
          setError(`Error: ${data.createProduct.message}`);
        } else {
          dispatch(setBatchedProducts([data.createProduct.data]));
          setIsSuccess(true);
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    createProduct: mutate,
    isLoading,
    isSuccess,
    error,
    createProductAsync: mutateAsync,
  };
};

export default useAddProduct;
