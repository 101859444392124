import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_COLLECTIONS = gql`
  query getCollections($filters: CollectionFilterFields!) {
    getCollections(filters: $filters) {
      id
      name
      createdAt
      supplier {
        id
        name
      }
    }
  }
`;

export const fetchCollections = async (filters = {}) => {
  return await graphQLClient.request(GET_COLLECTIONS, { filters });
};
