import {
  TableCells,
  TableFilters,
  TableCards,
  TableHeaders,
  AvatarMultiple,
  NavCard,
  Button,
  Badge,
} from "@buildappeal/react-component-library";

import { PROPOSAL_STATUS_MAP } from "@src/features/proposals/utils";
import generateInitials from "@src/utils/generateInitials";

export const projectColumns = {
  select: {
    id: "select",
    accessor: "selected_row",
    Header: TableHeaders.CheckboxAllPageHeader,
    Cell: TableCells.CheckboxCell,
    renderCard: TableCards.CheckboxInputRow,
    disableSortBy: true,
    minWidth: "50px",
    maxWidth: "60px",
    pin: true,
  },
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
    minWidth: "80px",
  },
  project: {
    id: "places",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={original.places?.addressLine1}
          description={`${original.places?.city ? `${original.places?.city}, ` : ""} ${original.places?.state || ""} ${
            original.places?.zipCode || ""
          }`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          onArrowClick={() => column.onArrowClick?.(original)}
          showArrow={table?.options.meta?.isCollapse}
          image={original.thumbnail}
          titleClassName="line-clamp-1"
          status={table?.options.meta?.isCollapse ? original.projectStages?.stageType : null}
          showHover={table?.options.meta?.isCollapse}
          descriptionClassName="line-clamp-1"
          className="mx-1"
          showPlaceholder
        />
      );
    },
    subtitleAccessor: "sku",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={original?.thumbnail}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageOnly
          imgTransformations={{
            sizes: "(max-width: 768px) 400px, (max-width: 1024px) 500px",
            srcSetArray: ["400", "500"],
            maxWidth: "700",
          }}
        />
      );
    },
    minWidth: "300px",
    pin: true,
  },
  places: {
    id: "address",
    Header: "Address",
    accessor: (row) => row.places?.addressLine1 || "",
    className: "whitespace-nowrap",
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original.places?.city}
          addressState={originalRow.row.original.places?.state}
          addressZip={originalRow.row.original.places?.zipCode}
          {...originalRow}
        />
      );
    },
    renderCard: ({ row, column, table }) => {
      const { city, state, zipCode } = row?.original?.places;
      return (
        <TableCards.TitleRow
          getValue={() => {
            return row?.original?.places?.addressLine1 || "";
          }}
          subtitle={`${city}, ${state} ${zipCode}`}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  clients: {
    id: "clients",
    Header: "Client",
    accessor: (row) => {
      return row?.client?.fullName;
    },
    avatarAccessor: (row) => row?.client?.avatar || "",
    Cell: (originalRow) => {
      const { row, column, table } = originalRow;
      const isLoading = !!table?.options?.meta?.isLoading;

      if (!isLoading && !row?.original?.client) {
        return null;
      }
      return (
        <TableCells.UserCell
          column={column}
          row={row}
          avatarSrc={row?.original?.client?.avatar}
          initials={generateInitials(row?.original?.client?.fullName || row?.original?.client?.email)}
          {...originalRow}
          id={row?.original?.client?.id}
          path="/people"
          role="client"
          type="client"
          onQuickViewOpen={(data) => {
            column.onQuickViewOpen({ ...data, selectedItem: { ...data.selectedItem, isReducedView: true } });
          }}
        />
      );
    },
    className: "whitespace-nowrap w-[10%]",
    renderCard: ({ row, column, table }) => {
      const client = row?.original?.client;
      if (!client) return null;
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            const client = row?.original?.client;
            return [
              {
                avatar: client?.avatar,
                name: client?.fullName,
              },
            ];
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    minWidth: "250px",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  status: {
    id: "status",
    Header: "Stage",
    accessor: (row) => {
      return row.projectStatus;
    },
    Cell: ({ row: { original }, table }) => {
      if (!original.projectStageType || !original.projectStatus) return null;
      return (
        <TableCells.ProjectStatusCell
          projectStages={{
            name: original.projectStatus,
            stageType: original.projectStageType,
          }}
          table={table}
        />
      );
    },
    className: "whitespace-nowrap",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    maxWidth: "2fr",
    minWidth: "200px",
    subtitleAccessor: (row) => {
      return row.projectStatus || "";
    },
    statusAccessor: (row) => {
      return row.projectStageType === "Lead" ? "inactive" : "active";
    },
    titleClassName: "text-3xs font-normal",
    subtitleClassName: "text-xs font-normal",
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.StatusRow
          getValue={() => {
            return row?.original?.projectStatus;
          }}
          row={row}
          column={column}
          table={table}
          status={row?.original?.projectStageType}
        />
      );
    },
  },
  stageStatus: {
    id: "currentStatus",
    Header: "Status",
    accessor: ({ currentStatus }) => currentStatus?.name || "",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    minWidth: "250px",
  },
  duration: {
    id: "duration",
    Header: "Duration",
    accessor: ({ projectedStart, projectedCompletion }) => {
      if (!projectedStart || !projectedCompletion) return "";
      const start = new Date(projectedStart);
      const completion = new Date(projectedCompletion);
      const options = { month: "short", day: "numeric" };
      return `${start.toLocaleString("en-US", options)} - ${completion.toLocaleString("en-US", options)}`;
    },
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
    hideColumnHeader: true,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  spaces: {
    id: "spaces",
    Header: "Spaces",
    accessor: ({ spaces }) => spaces?.length || "",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
    hideColumnHeader: true,
    sortingFn: "alphanumeric",
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    maxWidth: "100px",
    minWidth: "100px",
  },
  owner: {
    id: "owner",
    Header: "Owner",
    accessor: ({ staff }) => staff?.map((staff) => staff?.fullName) || [],
    Cell: ({ row: { original }, table, column }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      const owners = original.staff?.filter((item) => item.isOwner) || [];

      return (
        <div className="py-4 px-[18px]">
          <AvatarMultiple
            isLoading={isLoading}
            images={
              owners?.map((staff) => ({
                src: staff?.avatar,
                title: staff?.fullName,
                alt: "Staff Image",
                initials: generateInitials(staff?.fullName),
                path: "/people",
                id: staff?.id,
                type: "staff",
              })) || []
            }
            size="sm"
            hasTooltip
            handleQuickViewOpen={(data) => {
              column.onQuickViewOpen({ ...data, selectedItem: { ...data.selectedItem, isReducedView: true } });
            }}
          />
        </div>
      );
    },
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            return (
              row?.original?.staff?.map((owner) => ({
                avatar: owner.avatar,
                name: owner.fullName,
              })) || []
            );
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    disableSortBy: true,
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    minWidth: "100px",
    maxWidth: "100px",
  },
  source: {
    id: "source",
    Header: "Source",
    accessor: "source",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  type: {
    id: "type",
    Header: "Type",
    accessor: ({ type }) => type || "",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  dateCreated: {
    id: "dateCreated",
    Header: "Created",
    accessor: ({ dateCreated }) => (dateCreated ? new Date(dateCreated) : dateCreated),
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
};

export const contractColumns = {
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  contractType: {
    id: "type",
    Header: "Type",
    accessor: "contractType",
  },
  signee: {
    id: "signee",
    Header: "Signee",
    accessor: "clients[0].fullName",
    Cell: TableCells.UserCell,
  },
  staff: {
    id: "staff",
    Header: "Staff",
    accessor: "staff[0].fullName",
    Cell: (originalRow) => {
      return <TableCells.UserCell avatarSrc={originalRow.row.original.staff?.[0]?.avatar || ""} {...originalRow} />;
    },
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: TableCells.TextCell,
    colorAccessor: "gray",
    className: "whitespace-nowrap",
  },
  lastUpdated: {
    id: "lastUpdated",
    Header: "Last Updated",
    accessor: (originalRow) => {
      const dt = new Date(originalRow.lastUpdated);
      return dt.toISOString();
    },
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
};

export const spaceColumns = {
  id: {
    id: "id",
    Header: "Id",
    accessor: "id",
  },
  spaceNickname: {
    id: "nickname",
    Header: "Nickname",
    accessor: "spaceNickname",
  },
  measurementsWidth: {
    id: "width",
    Header: "Width",
    accessor: "measurementsWidth",
  },
  measurementsLength: {
    id: "length",
    Header: "Length",
    accessor: "measurementsLength",
  },
  sqft: {
    id: "sqft",
    Header: "Sq Ft",
    accessor: "sqft",
  },
  roomNumber: {
    id: "roomNumber",
    Header: "Room Number",
    accessor: "roomNumber",
  },
  floor: {
    id: "floor",
    Header: "Floor",
    accessor: "floor",
  },
};

export const fileColumns = {
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: (originalRow) => {
      const [title, subtitle] = originalRow.getValue().split(".");
      const { path, fileType } = originalRow.row.original;
      return (
        <div className="flex">
          <TableCells.ImageCell
            getValue={() => (fileType.includes("image") ? path : "")}
            alt="fileName"
            {...originalRow}
          />
          <TableCells.TitleCell getValue={() => title || ""} subtitle={"." + subtitle} {...originalRow} />
        </div>
      );
    },
    className: "w-3/4",
  },
  uploadedAt: {
    id: "created",
    Header: "Created",
    accessor: "uploadedAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
};

export const designProposalColumns = [
  {
    id: "spaces",
    Header: "Spaces",
    accessor: ({ spaces }) => spaces,
    Cell: TableCells.TextCell,
    renderCard: ({ getValue, ...rest }) => {
      if (!getValue()) return null;
      const spaces = getValue();
      return (
        <div className="mx-8 grid grid-cols-3 border-t border-neutral-100 py-2.5">
          <TableCards.RowLabel label="Spaces" />
          <div className="col-span-2 flex flex-row flex-wrap">
            {spaces.map((sp) => (
              <Badge label={sp} color="gray" className="m-2" />
            ))}
          </div>
        </div>
      );
    },
  },
  {
    id: "dateSent",
    Header: "Sent Date",
    accessor: "dateSent",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  {
    // TODO: Update table with column
    Header: "Created By",
    accessor: "createdBy",
    renderCard: ({ getValue, ...rest }) => {
      if (!getValue()) return null;
      return <TableCards.AvatarRow getValue={getValue} {...rest} />;
    },
    avatarAccessor: "createdBy",
  },
  {
    Header: "Status",
    accessor: "status",
    renderCard: ({ getValue, ...rest }) => {
      const isActive = getValue() === PROPOSAL_STATUS_MAP.APPROVED;
      return (
        <div className="mx-8 grid grid-cols-3 border-t border-neutral-100 py-2.5">
          <TableCards.RowLabel label="Status" />
          <div className="col-span-2 flex items-start">
            <div className={`mt-1 h-[9px] w-[9px] rounded-full ${isActive ? "bg-green-500" : "bg-yellow-500"}`} />

            <div className="ml-2.5">
              <span className="text-xs font-normal">{getValue()}</span>
            </div>
          </div>
        </div>
      );
    },
    titleClassName: "text-3xs font-normal",
    subtitleClassName: "text-xs font-normal",
  },
  {
    Header: "Invoice",
    accessor: "invoice",
    renderCard: ({ getValue, row, ...rest }) => {
      const { original } = row;
      const hasInvoice = original?.invoice?.id;

      return (
        <div className="mx-8 grid grid-cols-3 border-t border-neutral-100 py-2.5">
          <TableCards.RowLabel label="Invoice" />
          <div className="col-span-2 flex items-start">
            {hasInvoice ? (
              <Button
                label="View"
                onClick={() => {
                  original?.onInvoiceClick?.(original?.invoice?.id);
                }}
                appearance="secondary"
              />
            ) : (
              "No invoice generated"
            )}
          </div>
        </div>
      );
    },
  },
  {
    Header: "Message",
    accessor: "message",
    renderCard: ({ getValue, row, ...rest }) => {
      const { original = {} } = row;
      const { message = null } = original;

      return (
        <div className="mx-8 grid grid-cols-3 border-t border-neutral-100 py-2.5">
          <TableCards.RowLabel label="Message" />
          <div className="col-span-2 flex items-start">
            {message ? (
              <Button
                label="View"
                onClick={() => {
                  original?.onMessageView?.(original?.id);
                }}
                appearance="secondary"
              />
            ) : (
              "-"
            )}
          </div>
        </div>
      );
    },
  },
];
