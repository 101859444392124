import { ConfirmationToast } from "@buildappeal/react-component-library";
import noop from "lodash/noop";

const DeleteFileToast = ({ show, loading, handleClose = noop, onDelete = noop, message }) => {
  return (
    <ConfirmationToast
      show={show && !loading}
      onCancel={handleClose}
      label="Are you sure you want to delete this file?"
      actionLabel="Yes"
      onActionClick={onDelete}
      message={message}
    />
  );
};

export default DeleteFileToast;
