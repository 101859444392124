import {
  upsertEstimateSelectionObject,
  updateESOProductQuantity,
  deleteEstimateSelectionObject,
} from "@src/features/estimateSelections/api";

const REQUEST_MAP = {
  upsertEstimateSelectionObject,
  updateESOProductQuantity,
  deleteEstimateSelectionObject,
};

export default REQUEST_MAP;
