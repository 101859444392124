import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchSuppliers } from "../../api";
import { setSuppliers, getSuppliers } from "../../suppliersSlice";

const useSuppliers = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const suppliers = useSelector(getSuppliers);

  const { data, isLoading } = useQuery(["suppliers", filters], () => fetchSuppliers(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  const suppliersAsOptions = useMemo(() => {
    if (!suppliers?.length) {
      return [];
    }
    return suppliers
      .map((supplierEntry) => {
        return {
          value: supplierEntry.id,
          title: supplierEntry.name,
          supplier: supplierEntry,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [suppliers]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setSuppliers(data.getSuppliers));
  }, [data, isLoading, dispatch]);

  return {
    suppliers,
    isLoading,
    suppliersAsOptions,
  };
};

export default useSuppliers;
