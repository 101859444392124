import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: [],
  invoiceItems: [],
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        invoices: action.payload,
      };
    },
    setInvoiceItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        invoiceItems: action.payload,
      };
    },
  },
});

export const { setInvoices, setInvoiceItems } = invoicesSlice.actions;

export default invoicesSlice.reducer;

export const getInvoices = (state) => state.invoices.invoices;
export const getInvoiceItems = (state) => state.invoices.invoiceItems;
