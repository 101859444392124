import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  notifications: [],
  activities: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        users: action.payload,
      };
    },
    setNotifications: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        notifications: action.payload,
      };
    },
    setActivities: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        activities: action.payload,
      };
    },
  },
});

export const { setUsers, setNotifications, setActivities } = usersSlice.actions;

export default usersSlice.reducer;

export const getUsers = (state) => state.users.users;
export const getNotifications = (state) => state.users.notifications;
export const getActivities = (state) => state.users.activities;
export const getUserById = (id) => (state) => state.users.users.find((user) => user.id === id);
