import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PROJECT_ESTIMATES = gql`
  query getEstimates($filters: EstimateFilterFields!) {
    getEstimates(filters: $filters) {
      id
      status
      version
      proposal {
        id
      }
      actionsProjectSpaces {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        projectsSpacesId
        actionsSpaces {
          action {
            id
            verbPresent
          }
          space {
            id
            name
          }
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          actionsProjectsSpacesId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
      projectActions {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        actionObject {
          id
          action {
            verbPresent
          }
          object {
            description
          }
          baseLaborUnitType
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          projectsActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
      projectSpacesActions {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        projectSpace {
          id
          spaceNickname
          name
        }
        actionObject {
          id
          action {
            verbPresent
          }
          object {
            description
          }
          baseLaborUnitType
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          projectsSpacesActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
      projectSpaceObjectsActions {
        id
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        projectSpaceObject {
          name
          nickname
          projectSpaces {
            id
            name
          }
        }
        psoParentPath
        actionObject {
          id
          action {
            verbPresent
          }
          object {
            description
          }
          baseLaborUnitType
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          psoActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
    }
  }
`;

const GET_ALL_ESTIMATES = gql`
  query getEstimates($filters: EstimateFilterFields!) {
    getEstimates(filters: $filters) {
      id
      status
      version
      totalPrice
      project {
        id
        places {
          addressLine1
          state
          city
          zipCode
        }
        projectStages {
          id
          name
          stageType
        }
      }
      proposal {
        id
      }
      actionsProjectSpaces {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        projectsSpacesId
        actionProducts {
          id
          actionsProjectsSpacesId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
        }
      }
      projectActions {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        actionProducts {
          id
          projectsActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
        }
      }
      projectSpacesActions {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        actionProducts {
          id
          projectsSpacesActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
        }
      }
      projectSpaceObjectsActions {
        id
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        psoParentPath
        actionProducts {
          id
          psoActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
        }
      }
    }
  }
`;

const GET_ESTIMATE = gql`
  query getEstimateByID($id: Int!) {
    getEstimateByID(id: $id) {
      id
      status
      version
      project {
        id
        places {
          addressLine1
          state
          city
          zipCode
        }
        projectStages {
          id
          name
          stageType
        }
      }
      actionsProjectSpaces {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        projectsSpacesId
        actionsSpaces {
          action {
            id
            verbPresent
          }
          space {
            id
            name
          }
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          actionsProjectsSpacesId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
      projectActions {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        actionObject {
          id
          action {
            verbPresent
          }
          object {
            description
          }
          baseLaborUnitType
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          projectsActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
      projectSpacesActions {
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        projectSpace {
          id
          spaceNickname
          name
        }
        actionObject {
          id
          action {
            verbPresent
          }
          object {
            description
          }
          baseLaborUnitType
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          projectsSpacesActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
      projectSpaceObjectsActions {
        id
        unitCost
        unitQuantity
        extendedCost
        profit
        marginPercentage
        totalPrice
        estimateId
        projectSpaceObject {
          name
          nickname
          projectSpaces {
            id
            name
          }
        }
        psoParentPath
        actionObject {
          id
          action {
            verbPresent
          }
          object {
            description
          }
          baseLaborUnitType
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
              name
              dataType
            }
            options {
              name
              additionalCost
            }
          }
        }
        services {
          id
          name
          parentId
          unitType
          baseLaborCost
          permit
          code
        }
        actionProducts {
          id
          psoActionsId
          productsId
          unitCost
          unitQuantity
          extendedCost
          marginPercentage
          profit
          totalPrice
          product {
            id
            name
            unit
            attributes {
              id
              attribute {
                id
                name
                dataType
                parentAttributeId
                sortBy
              }
              options {
                name
                additionalCost
              }
            }
          }
          actionsProductsAttributes {
            id
            value
            attributeItem {
              id
              attribute {
                id
                name
                dataType
              }
              options {
                name
                additionalCost
              }
            }
          }
        }
      }
    }
  }
`;

const GET_LATEST_ESTIMATE = gql`
  query getEstimate($filters: EstimateFilterFields!) {
    getEstimate(filters: $filters) {
      id
      version
      status
    }
  }
`;

const CREATE_ESTIMATE = gql`
  mutation createEstimate($input: EstimateRequest!) {
    createEstimate(input: $input) {
      code
      success
      message
      data {
        id
        version
        status
      }
    }
  }
`;

const UPDATE_ESTIMATE = gql`
  mutation updateEstimate($input: EstimateRequest!) {
    updateEstimate(input: $input) {
      code
      success
      message
      data {
        id
        version
        status
      }
    }
  }
`;

export const fetchAllEstimates = async (filters = {}) => {
  return await graphQLClient.request(GET_ALL_ESTIMATES, { filters });
};

export const fetchProjectEstimates = async (filters = {}) => {
  return await graphQLClient.request(GET_PROJECT_ESTIMATES, { filters });
};
export const fetchLatestEstimate = async (filters) => {
  if (!filters?.projectId) {
    return null;
  }
  return await graphQLClient.request(GET_LATEST_ESTIMATE, { filters });
};

export const createEstimate = async (input) => {
  return await graphQLClient.request(CREATE_ESTIMATE, { input });
};

export const updateEstimate = async (input) => {
  return await graphQLClient.request(UPDATE_ESTIMATE, { input });
};

export const fetchEstimate = async (id) => {
  return await graphQLClient.request(GET_ESTIMATE, { id });
};
