const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: [
        "project",
        "invoiceTypes",
        "total",
        "dueDate",
        "title",
        "invoiceCode",
        "invoicePrefix",
        "invoiceNumber",
        "xeroInvoiceID",
        "invoiceDate",
      ],
    },
  ],
};

export default uiSchema;
