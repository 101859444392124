import { Button, Select, Sidebar } from "@buildappeal/react-component-library";
import noop from "lodash/noop";
import { useEffect, useState } from "react";

import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";
import useServices from "../services/hooks/useServices";
import useMarkets from "../markets/hooks/useMarkets";
import useDeletePartners from "./hooks/useDeletePartners";

const PartnersMultiSelect = ({
  editing = false,
  deleting = false,
  loading = false,
  setLoading = noop,
  setNotificationMessage = noop,
  onEditSuccess = noop,
  onDeleteSuccess = noop,
  onError = noop,
  selectedItems = [],
  onClose = noop,
}) => {
  const { deletePartnersAsync } = useDeletePartners();
  const { options: servicesAsOptions, isLoading: servicesLoading } = useServices();
  const { options: marketsAsOptions, isLoading: marketsLoading } = useMarkets();
  const { setActionsState } = useGeneralActionsUpdater();

  const [formState, setFormState] = useState({});
  const [showEdit, setShowEdit] = useState(false);

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const handleDeletePartners = async () => {
    if (loading) return;
    setLoading(true);
    setNotificationMessage("Deleting...");
    try {
      const data = await deletePartnersAsync(selectedItems.map((partner) => ({ id: partner.id })));

      if (!data.deletePartners?.success) {
        setNotificationMessage(`Failed to delete partners. Try again!`, 2000);
        onError();
        return;
      }
      onDeleteSuccess();
    } catch (err) {
      setNotificationMessage(`Failed to delete partners. Try again!`, 2000);
      onError();
    }
  };

  const handleSave = async () => {
    if (loading) return;
    let groupDetails = {
      serviceIds: [],
      marketIds: [],
    };
    setLoading(true);

    const serviceIds = formState.services?.map((service) => {
      groupDetails.serviceIds.push(service.value);
      return service.value;
    });
    const marketIds = formState.markets?.map((market) => {
      groupDetails.marketIds.push(market.value);
      return market.value;
    });

    let groupTitle = "";
    Object.keys(groupDetails).forEach((key) => {
      if (key === "contentType") {
        groupTitle += groupDetails[key];
      }

      if (key !== "contentType" && groupDetails[key].length > 0) {
        groupTitle += groupDetails[key].join(", ");
      }
    });

    const partnersInput = selectedItems.map((partner) => {
      const input = {
        id: partner.id,
      };

      if (serviceIds) {
        input.serviceIds = serviceIds;
      }

      if (marketIds) {
        input.marketIds = marketIds;
      }

      return { input, name: partner.companyName, id: partner.id, groupTitle };
    });

    setActionsState({
      isOpen: true,
      currentAction: "updateBulkItems",
      triggeredBy: "partnersMultiSelect",
      actionProps: {
        itemsInput: partnersInput,
        onSuccess: onEditSuccess,
      },
    });
    setLoading(false);
    setShowEdit(false);
    onClose?.();
  };

  useEffect(() => {
    if (!deleting) return;
    handleDeletePartners();
  }, [deleting]);

  useEffect(() => {
    if (!editing) return;
    setShowEdit(true);
  }, [editing]);

  const handleSidebarClose = () => {
    setShowEdit(false);
    onClose();
  };

  return (
    <Sidebar isOpen={showEdit} onClose={handleSidebarClose} classNameContainer="!max-w-lg">
      <div className="flex h-full w-full flex-col space-y-4 px-6 py-10">
        <h3 className="text-xl">{`Edit ${selectedItems.length} Partner${selectedItems.length > 1 ? "s" : ""}`}</h3>
        <div className="mt-6 flex flex-1 flex-col gap-4">
          <Select
            label="Services"
            value={formState.services || []}
            options={servicesAsOptions}
            onChange={(value) => {
              setFormValue({ services: value });
            }}
            isLoading={servicesLoading}
            multiple
          />

          <Select
            label="Markets"
            value={formState.markets || []}
            options={marketsAsOptions}
            onChange={(value) => {
              setFormValue({ markets: value });
            }}
            isLoading={marketsLoading}
            multiple
          />
        </div>
        <div className="flex w-full">
          <Button
            isLoading={loading}
            label={loading ? "Saving..." : "Save"}
            className="w-full !rounded-sm"
            onClick={handleSave}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default PartnersMultiSelect;
