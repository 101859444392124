import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_BUDGETS = gql`
  query getBudgets($filters: BudgetFilterFields!) {
    getBudgets(filters: $filters) {
      id
      createdAt
      daysToComplete
      estimatedTotal
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
    }
  }
`;

const GET_PROJECT_BUDGETS = gql`
  query getBudgets($filters: BudgetFilterFields!) {
    getBudgets(filters: $filters) {
      id
      createdAt
      daysToComplete
      estimatedTotal
      status
    }
  }
`;

const GET_BUDGET = gql`
  query getBudgetByID($id: Int!) {
    getBudgetByID(id: $id) {
      id
      createdAt
      daysToComplete
      estimatedTotal
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
    }
  }
`;

const GET_BUDGET_ITEMS = gql`
  query getBudgetItems($filters: BudgetItemFilterFields!) {
    getBudgetItems(filters: $filters) {
      id
      createdAt
      description
      estimatedAmount
      budget {
        id
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
        }
      }
    }
  }
`;
const CREATE_BUDGET = gql`
  mutation createBudget($input: CreateBudgetRequest!) {
    createBudget(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        daysToComplete
        estimatedTotal
        status
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
        }
      }
    }
  }
`;

const UPDATE_BUDGET = gql`
  mutation updateBudget($input: UpdateBudgetRequest!) {
    updateBudget(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        daysToComplete
        estimatedTotal
        status
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
        }
      }
    }
  }
`;

const DELETE_BUDGET = gql`
  mutation deleteBudget($id: Int!) {
    deleteBudget(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

const CREATE_BUDGET_ITEM = gql`
  mutation createBudgetItem($input: CreateBudgetItemRequest!) {
    createBudgetItem(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        estimatedAmount
        budget {
          id
          createdAt
          daysToComplete
          estimatedTotal
          status
          project {
            id
            places {
              id
              addressLine1
              addressLine2
              city
              state
              zipCode
            }
          }
        }
      }
    }
  }
`;
const UPDATE_BUDGET_ITEM = gql`
  mutation updateBudgetItem($input: UpdateBudgetItemRequest!) {
    updateBudgetItem(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        estimatedAmount
        budget {
          id
          createdAt
          daysToComplete
          estimatedTotal
          status
          project {
            id
            places {
              id
              addressLine1
              addressLine2
              city
              state
              zipCode
            }
          }
        }
      }
    }
  }
`;
const DELETE_BUDGET_ITEM = gql`
  mutation deleteBudgetItem($id: Int!) {
    deleteBudgetItem(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;
export const fetchBudgets = async (filters = {}) => {
  return await graphQLClient.request(GET_BUDGETS, { filters });
};

export const createBudget = async (input) => {
  return await graphQLClient.request(CREATE_BUDGET, { input });
};

export const updateBudget = async (input) => {
  return await graphQLClient.request(UPDATE_BUDGET, { input });
};

export const deleteBudget = async (id) => {
  return await graphQLClient.request(DELETE_BUDGET, { id });
};

export const fetchBudgetItems = async (filters = {}) => {
  return await graphQLClient.request(GET_BUDGET_ITEMS, { filters });
};

export const createBudgetItem = async (input) => {
  return await graphQLClient.request(CREATE_BUDGET_ITEM, { input });
};

export const updateBudgetItem = async (input) => {
  return await graphQLClient.request(UPDATE_BUDGET_ITEM, { input });
};

export const deleteBudgetItem = async (id) => {
  return await graphQLClient.request(DELETE_BUDGET_ITEM, { id });
};

export const fetchBudget = async (id) => {
  return await graphQLClient.request(GET_BUDGET, { id });
};

export const fetchProjectBudgets = async (filters = {}) => {
  return await graphQLClient.request(GET_PROJECT_BUDGETS, { filters });
};
