import { TableCells } from "@buildappeal/react-component-library";
import { Link } from "react-router-dom";
export const storyboardsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
  },
  products: {
    id: "products",
    Header: "Products",
    accessor: (row) => row.products.map((product) => product.name)[0],
    Cell: (originalRow) => {
      return originalRow.row?.original?.products?.map((product) => {
        return (
          <Link
            key={product.id}
            to={`/products/${product?.id}`}
            label={product?.name}
            className="cursor-pointer"
            as={<Link to="/" />}
          >
            <TableCells.BadgeCell color={"gray"} value={product?.name} {...originalRow} />
          </Link>
        );
      });
    },
  },
};
