import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createActionsObjects } from "../../api";

const useActionsObjectsAdd = (filters = {}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      setIsSuccess(false);
      return createActionsObjects(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createActionObject.success) {
          setError(`Error: ${data.createActionObject.message || "Failed to create Action Object"}`);
        } else {
          setIsSuccess(true);
          const { data: newActionObject } = data.createActionObject;
          queryClient.setQueryData(["actionsObjects", filters, false.toString()], (old) => {
            return {
              getActionsObjects: [newActionObject, ...(old?.getActionsObjects || [])],
            };
          });
          setIsSuccess(true);
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    addActionsObjects: mutate,
    isLoading,
    isSuccess,
    error,
    addActionsObjectsAsync: mutateAsync,
  };
};

export default useActionsObjectsAdd;
