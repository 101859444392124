import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import sortBy from "lodash/sortBy";
import { fetchMessages } from "../../api";
import { setMessages, getMessages } from "../../messageSlice";

const useMessages = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const messages = useSelector(getMessages);
  const { data, isLoading } = useQuery(["messages", filters], () => fetchMessages(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions?.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(
      setMessages({
        messages: sortBy(data.getMessages, "id"),
      })
    );
  }, [data, isLoading, dispatch]);

  return {
    data: messages,
    isLoading,
  };
};

export default useMessages;
