import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_TASKS = gql`
  query getTasks($filters: TaskFilterFields!) {
    getTasks(filters: $filters) {
      skip
      take
      totalCount
      tasks {
        id
        createdAt
        startOn
        description
        status
        dueDate
        completed
        completedByUser {
          id
          role
          staff {
            id
            avatar
            fullName
          }
        }
        assignedToType
        completedAt
        actionsObjects {
          id
          action {
            id
            verbPresent
            verbPast
          }
          object {
            id
            description
            icon
          }
        }
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          thumbnail {
            id
            path
          }
        }
        assignedByUser {
          id
          role
          staff {
            id
            email
            avatar
            fullName
          }
          clients {
            id
            email
            fullName
            avatar
          }
        }
        assignedToUser {
          id
          role
          staff {
            id
            email
            avatar
            fullName
          }
          clients {
            id
            email
            fullName
            avatar
          }
        }
        invoiceItem {
          id
        }
        projectSpace {
          id
          spaceId
          name
        }
        files {
          id
          name
          path
          fileType
          type
        }
      }
    }
  }
`;

const GET_TASK_BY_ID = gql`
  query getTaskByID($id: Int!) {
    getTaskByID(id: $id) {
      id
      createdAt
      startOn
      description
      status
      dueDate
      completed
      completedByUser {
        id
        role
        staff {
          id
          avatar
          fullName
        }
      }
      assignedToType
      completedAt
      actionsObjects {
        id
        action {
          id
          verbPresent
          verbPast
        }
        object {
          id
          description
          icon
        }
      }
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
      assignedByUser {
        id
        role
        staff {
          id
          email
          avatar
          fullName
        }
        clients {
          id
          email
          fullName
          avatar
        }
      }
      assignedToUser {
        id
        role
        staff {
          id
          email
          avatar
          fullName
        }
        clients {
          id
          email
          fullName
          avatar
        }
      }
      invoiceItem {
        id
      }
      projectSpace {
        id
        spaceId
        name
      }
    }
  }
`;

const CREATE_TASK = gql`
  mutation createTask($input: CreateTaskRequest!) {
    createTask(input: $input) {
      code
      message
      success
      data {
        id
        createdAt
        description
        startOn
        status
        dueDate
        completed
        completedByUser {
          id
          role
          staff {
            id
            avatar
            fullName
          }
        }
        completedAt
        assignedToType
        actionsObjects {
          id
          action {
            id
            verbPresent
            verbPast
          }
          object {
            id
            description
            icon
          }
        }
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
        }
        assignedByUser {
          id
          role
          staff {
            id
            email
            avatar
            fullName
          }
          clients {
            id
            email
            fullName
            avatar
          }
        }
        assignedToUser {
          id
          role
          staff {
            id
            email
            avatar
            fullName
          }
          clients {
            id
            email
            fullName
            avatar
          }
        }
        invoiceItem {
          id
        }
        projectSpace {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation updateTask($input: UpdateTaskRequest!) {
    updateTask(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        startOn
        status
        dueDate
        completed
        completedByUser {
          id
          role
          staff {
            id
            avatar
            fullName
          }
        }
        completedAt
        assignedToType
        actionsObjects {
          id
          action {
            id
            verbPresent
            verbPast
          }
          object {
            id
            description
            icon
          }
        }
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
        }
        assignedByUser {
          id
          role
          staff {
            id
            email
            avatar
            fullName
          }
          clients {
            id
            email
            fullName
            avatar
          }
        }
        assignedToUser {
          id
          role
          staff {
            id
            email
            avatar
            fullName
          }
          clients {
            id
            email
            fullName
            avatar
          }
        }
        invoiceItem {
          id
        }
        projectSpace {
          id
          name
        }
      }
    }
  }
`;

export const fetchTasks = async (filters = {}) => {
  return await graphQLClient.request(GET_TASKS, { filters });
};

export const fetchTaskByID = async (id) => {
  return await graphQLClient.request(GET_TASK_BY_ID, { id });
};

export const createTask = async (input) => {
  return await graphQLClient.request(CREATE_TASK, { input });
};

export const updateTask = async (input) => {
  return await graphQLClient.request(UPDATE_TASK, { input });
};
