import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteContact } from "../../api";

const useContactDelete = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      setIsSuccess(false);
      return deleteContact(input);
    },
    {
      onSuccess: (data) => {
        if (!data.deleteContact.success) {
          setError(`Error: ${data.deleteContact.message}`);
        } else {
          setIsSuccess(true);
          queryClient.invalidateQueries("contacts");
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return { deleteContact: mutate, isLoading, isSuccess, error, deleteContactAsync: mutateAsync };
};

export default useContactDelete;
