import { FilterRadio, ViewButton } from "@buildappeal/react-component-library";
import { useSortBy } from "react-instantsearch-hooks-web";

const AlgoliaSortBy = ({ icon, label = "Sort", items, className }) => {
  const { currentRefinement, options, refine } = useSortBy({
    items,
  });

  return (
    <ViewButton
      className={className}
      label={label}
      leftIcon={icon}
      wrapper={<FilterRadio />}
      options={options}
      title="Sort By"
      saveOnSelect
      hideFooter
      value={currentRefinement}
      onSave={refine}
    />
  );
};

export default AlgoliaSortBy;
