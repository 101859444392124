const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: ["name", "sku", "vendorURL"],
    },
    {
      title: "Product Types",
      properties: ["objects"],
    },
    {
      title: "Suppliers",
      properties: ["suppliers", "brand"],
    },
    {
      title: "Description",
      properties: ["description"],
    },
    {
      title: "Dimensions",
      properties: ["dimensions"],
    },
    {
      title: "Pricing",
      properties: ["cost", "unit", "isTaxExempt", "isRefundable"],
    },
    {
      title: "Availability",
      properties: ["leadTimeDays", "isStocked"],
    },
    {
      title: "Design Style",
      properties: ["designStyles"],
    },
    {
      title: "Materials",
      properties: ["materials"],
    },
    {
      title: "Media",
      properties: ["media"],
    },
    {
      title: "Documents",
      properties: ["documents"],
      isLast: true,
    },
  ],
};

export default uiSchema;
