import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_CHANGE_ORDERS = gql`
  query getChangeOrders($filters: ChangeOrderFilterFields!) {
    getChangeOrders(filters: $filters) {
      id
      coDate
      dueDate
      coPrefix
      coNumber
      description
      coCode
      changeOrderTypes
      total
      status
      project {
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_PROJECT_CHANGE_ORDERS = gql`
  query getChangeOrders($filters: ChangeOrderFilterFields!) {
    getChangeOrders(filters: $filters) {
      id
      coDate
      dueDate
      coPrefix
      coNumber
      description
      coCode
      changeOrderTypes
      total
      status
      project {
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_CHANGE_ORDER = gql`
  query getChangeOrderByID($id: Int!) {
    getChangeOrderByID(id: $id) {
      id
      coDate
      dueDate
      coPrefix
      coNumber
      description
      coCode
      changeOrderTypes
      total
      status
      project {
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_CHANGE_ORDER_ITEMS = gql`
  query getChangeOrderItems($filters: ChangeOrderItemFilterFields!) {
    getChangeOrderItems(filters: $filters) {
      id
      description
      quantity
      subTotal
      total
      changeOrder {
        id
        project {
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
      }
    }
  }
`;

const CREATE_CHANGE_ORDER = gql`
  mutation createChangeOrder($input: CreateChangeOrderRequest!) {
    createChangeOrder(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        coCode
        coPrefix
        coNumber
        changeOrderTypes
        description
        coDate
        dueDate
        total
        total
        status
        project {
          id
          places {
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        changeOrderItems {
          id
          createdAt
          description
          quantity
          subTotal
        }
      }
    }
  }
`;

const UPDATE_CHANGE_ORDER = gql`
  mutation updateChangeOrder($input: UpdateChangeOrderRequest!) {
    updateChangeOrder(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        coCode
        coPrefix
        coNumber
        changeOrderTypes
        description
        coDate
        dueDate
        total
        total
        status
        project {
          id
          places {
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        changeOrderItems {
          id
          createdAt
          description
          quantity
          subTotal
        }
      }
    }
  }
`;

export const fetchChangeOrders = async (filters = {}) => {
  return await graphQLClient.request(GET_CHANGE_ORDERS, { filters });
};

export const fetchChangeOrderItems = async (filters = {}) => {
  return await graphQLClient.request(GET_CHANGE_ORDER_ITEMS, { filters });
};

export const createChangeOrder = async (input) => {
  return await graphQLClient.request(CREATE_CHANGE_ORDER, { input });
};

export const updateChangeOrder = async (input) => {
  return await graphQLClient.request(UPDATE_CHANGE_ORDER, { input });
};

export const fetchChangeOrder = async (id) => {
  return await graphQLClient.request(GET_CHANGE_ORDER, { id });
};

export const fetchProjectChangeOrders = async (filters = {}) => {
  return await graphQLClient.request(GET_PROJECT_CHANGE_ORDERS, { filters });
};
