import React, { useEffect } from "react";
import useContactEdit from "./hooks/useContactEdit";
import ContactForm from "./ContactForm";

const ContactEdit = ({ contact, onClose }) => {
  const { editContact, isLoading, isSuccess } = useContactEdit();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  const handleEdit = (data) => {
    const finalValidData = {
      id: contact.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      partnerId: data.partner?.id,
      supplierId: data.supplier?.id,
    };
    editContact(finalValidData);
  };

  return (
    <ContactForm
      key={contact?.id}
      contact={contact}
      onSubmit={handleEdit}
      isLoading={isLoading}
      action="Edit"
      onClose={onClose}
    />
  );
};

export default ContactEdit;
