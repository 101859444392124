const formSchema = {
  title: "Create Lead",
  properties: {
    firstName: {
      type: "person",
      required: true,
    },
    email: {
      type: "email",
      label: "Email",
      required: true,
    },
    phone: {
      type: "phone",
      label: "Phone Number",
      defaultCountry: "US",
      maxLength: 14,
      required: true,
    },
    rooms: {
      type: "selectMultiple",
      label: "Spaces",
    },
    address: {
      type: "address",
      required: true,
    },
    clientBudgetLow: {
      type: "currency",
      label: "Budget Low",
    },
    clientBudgetHigh: {
      type: "currency",
      label: "Budget High",
    },
    leadSource: {
      type: "select",
      label: "Source",
    },
    type: {
      type: "select",
      label: "Type",
    },
    staff: {
      type: "people",
      label: "Project Owner",
    },
  },
};

export default formSchema;
