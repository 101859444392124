import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_DESIGN_STYLES = gql`
  query getAllDesignStyle($filters: DesignStyleFilterFields!) {
    getAllDesignStyle(filters: $filters) {
      id
      createdAt
      description
      images
      name
      products {
        id
        createdAt
        name
        brand
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        contentType
        type
      }
    }
  }
`;

const GET_DESIGN_STYLE = gql`
  query getDesignStyleByID($id: Int!) {
    getDesignStyleByID(id: $id) {
      id
      createdAt
      description
      images
      name
      products {
        id
        createdAt
        name
        brand
      }
      media {
        id
        path
        name
        fileType
        sortOrder
        isThumbnail
        contentType
        type
      }
    }
  }
`;

const CREATE_DESIGN_STYLE = gql`
  mutation createDesignStyle($input: CreateDesignStyleRequest!) {
    createDesignStyle(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        images
        name
        products {
          id
          createdAt
          name
          brand
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
        }
      }
    }
  }
`;

const UPDATE_DESIGN_STYLE = gql`
  mutation updateDesignStyle($input: UpdateDesignStyleRequest!) {
    updateDesignStyle(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        images
        name
        products {
          id
          createdAt
          name
          brand
        }
        media {
          id
          path
          name
          fileType
          sortOrder
          isThumbnail
          type
        }
      }
    }
  }
`;

const DELETE_DESIGN_STYLE = gql`
  mutation deleteDesignStyle($id: Int!) {
    deleteDesignStyle(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;
export const fetchDesignStyles = async (filters = {}) => {
  return await graphQLClient.request(GET_DESIGN_STYLES, { filters });
};

export const createDesignStyle = async (input) => {
  return await graphQLClient.request(CREATE_DESIGN_STYLE, { input });
};

export const updateDesignStyle = async (input) => {
  return await graphQLClient.request(UPDATE_DESIGN_STYLE, { input });
};

export const deleteDesignStyle = async (id) => {
  return await graphQLClient.request(DELETE_DESIGN_STYLE, { id });
};

export const fetchDesignStyleById = async (id) => {
  return await graphQLClient.request(GET_DESIGN_STYLE, { id });
};
