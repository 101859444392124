import Skeleton from "react-loading-skeleton";

import ActivityLoading from "./ActivityLoading";

const ActivityViewLoading = ({ showLatest }) => {
  return (
    <div className="py-14" data-testid="Loading">
      {!showLatest && (
        <>
          <div>
            <Skeleton className="h-3 !w-20" />
          </div>
          <div className="flex gap-x-2 py-5">
            <Skeleton className="h-3 !w-24" />
            <Skeleton className="h-3 !w-24" />
            <Skeleton className="h-3 !w-24" />
            <Skeleton className="h-3 !w-24" />
            <Skeleton className="h-3 !w-24" />
          </div>
        </>
      )}
      <div className="my-2 space-y-5">
        <div>
          <Skeleton className="mb-3 h-3 !w-16" />
          <ActivityLoading />
          <ActivityLoading />
          <ActivityLoading />
        </div>
        <div>
          <Skeleton className="mb-3 h-3 !w-16" />
          <ActivityLoading />
          <ActivityLoading />
        </div>
        <Skeleton className="mb-3 h-3 !w-16" />
      </div>
    </div>
  );
};

export default ActivityViewLoading;
