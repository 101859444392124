import { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Notice, ProgressBar, Select, TextField } from "@buildappeal/react-component-library";

import { getProjectSpacesAsOptions } from "../projects/utils";
import { addSpaceToProject } from "./api";
import useSpaceObjectAdd from "./hooks/useSpaceObjectAdd";
import useSpaces from "./hooks/useSpaces";
const initialFormState = {
  space: undefined,
  measurementsWidth: "",
  measurementsLength: "",
  sqft: "",
  spaceNickname: "",
  floor: "",
  parentSpace: undefined,
};

export default function SingleSpace({ selectedProject, onSpaceObjectAddSuccess, onClose, onSuccess }) {
  const [formState, setFormState] = useState({ ...initialFormState });
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { options: spacesAsOptions } = useSpaces();
  const queryClient = useQueryClient();

  const projectSpacesOptions = useMemo(() => getProjectSpacesAsOptions(selectedProject.spaces), [selectedProject]);
  const { addObjectToProjectSpace } = useSpaceObjectAdd();

  const addObjectsToCreatedSpace = async (createdSpace, spaceLocation) => {
    try {
      const newObjects = await addObjectToProjectSpace(createdSpace, spaceLocation);
      onSpaceObjectAddSuccess({ newObjects, spaceId: createdSpace.id });
    } catch (err) {
      console.log("");
    }
  };

  const addSpaceToProjectMutation = useMutation(
    ({ data }) => {
      setLoading(true);
      return addSpaceToProject(data);
    },
    {
      onSuccess: async (data) => {
        if (!data.addSpaceToProject.success) {
          showErrMsgNotification();
          return;
        }
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
        queryClient.setQueryData(["projects", selectedProject.id], (old) => {
          if (old) {
            return {
              getProjectByID: {
                ...old.getProjectByID,
                spaces: old.getProjectByID.spaces
                  ? [data.addSpaceToProject.data, ...old.getProjectByID.spaces]
                  : [data.addSpaceToProject.data],
              },
            };
          }
        });
      },
    }
  );

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  //   const handleOnClose = () => {
  //     setFormState({ ...initialFormState });
  //     setShowErrorMessage(false);
  //     setShowSuccessMessage(false);
  //     // handleClose();
  //   };

  const showErrMsg = (msg) => {
    setErrMsg(msg);
    if (msg) {
      showErrMsgNotification();
    }
  };

  const showErrMsgNotification = () => {
    setShowErrorMessage(true);
    setTimeout(() => {
      setShowErrorMessage(false);
    }, 2000);
  };

  const isFormValid = () => {
    if (!formState?.space) {
      showErrMsg("Space is Mandatory");
      return false;
    }
    showErrMsg("");
    return true;
  };

  const onSubmit = async () => {
    if (!isFormValid()) {
      return;
    }

    const finalValues = Object.keys(formState).reduce((acc, key) => {
      if (formState[key] !== "") {
        acc[key] = formState[key];
      }
      return acc;
    }, {});

    const data = {
      ...finalValues,
      name: finalValues.space.name,
      space: finalValues.space.id,
      project: selectedProject.id,
      parentSpace: finalValues.parentSpace?.id,
    };

    let createdProjectSpace;
    let isProjectSpaceCreationSuccess = false;
    try {
      const mutationResult = await addSpaceToProjectMutation.mutateAsync({ data, space: finalValues.space });
      const { data: newProjectSpace } = mutationResult.addSpaceToProject;
      createdProjectSpace = newProjectSpace;
      isProjectSpaceCreationSuccess = true;
    } catch (err) {
      console.error("@@error ", err.message);
      showErrMsgNotification();
    }

    if (createdProjectSpace) {
      try {
        await addObjectsToCreatedSpace(createdProjectSpace, finalValues.space.location);
      } catch (err) {
        console.error("@@error adding objects to project space", err.message);
        showErrMsg("unable to add object to created project space");
      }
    }
    setLoading(false);
    if (isProjectSpaceCreationSuccess) {
      setFormState({ ...initialFormState });
      onSuccess?.();
      onClose?.();
    }
  };
  return (
    <div className="flex flex-col px-2 pt-8">
      {loading && <ProgressBar min={0} max={100} now={90} className="my-4" />}
      {!loading && showSuccessMessage && <Notice type="success" message="Space successfully Added" />}
      {!loading && showErrorMessage && (
        <Notice type="critical" message={errMsg || "Adding Space to Project failed. Please try again!"} />
      )}
      <Select
        label="Space"
        className="mt-4"
        disabled={loading}
        options={spacesAsOptions}
        value={{ title: formState.space?.name || "", value: formState.space?.id }}
        onChange={(opt) => {
          setFormValue({ space: opt?.space });
        }}
      />
      <Select
        label="Parent Space"
        className="mt-4"
        disabled={loading}
        options={projectSpacesOptions}
        value={{
          title: formState.parentSpace?.spaceNickname,
          value: formState.parentSpace?.id,
        }}
        onChange={(opt) => {
          setFormValue({ parentSpace: opt?.projectSpace });
        }}
      />

      <TextField
        label="Width (ft)"
        type="number"
        className="mt-4"
        value={formState.measurementsWidth}
        onChange={(e) => setFormValue({ measurementsWidth: e.target.value })}
      />
      <TextField
        label="Length (ft)"
        type="number"
        className="mt-4"
        value={formState.measurementsLength}
        onChange={(e) => setFormValue({ measurementsLength: e.target.value })}
      />
      <TextField
        label="Sq Ft"
        type="number"
        className="mt-4"
        value={formState.sqft}
        onChange={({ target: { value } }) => setFormValue({ sqft: value ? parseInt(value, 10) : value })}
      />
      <TextField
        label="Floor"
        type="number"
        className="mt-4"
        value={formState.floor}
        onChange={({ target: { value } }) => setFormValue({ floor: value ? parseInt(value, 10) : value })}
      />
      <TextField
        label="Nickname"
        className="mt-4"
        value={formState.spaceNickname}
        onChange={(e) => setFormValue({ spaceNickname: e.target.value })}
      />
      <TextField
        label="Budget"
        className="mt-4"
        leadingAddOn={`$`}
        value={formState.budget}
        onChange={({ target: { value } }) => setFormValue({ budget: value ? parseInt(value, 10) : value })}
      />
      <Button
        disabled={loading}
        className="mt-8 mb-5 w-full !rounded-sm"
        label="Add Space"
        size="sm"
        onClick={onSubmit}
      />
    </div>
  );
}
