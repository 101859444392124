import { TableCards, TableCells, TableFilters, NavCard, Button } from "@buildappeal/react-component-library";

export const supplierColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    renderCard: TableCards.TextRow,
  },
  logo: {
    id: "logo",
    Header: "logo",
    accessor: "image",
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      const finalImg = original.image;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageContainerClassName="h-64 flex items-center justify-center bg-neutral-100"
          imageClassName="w-32 h-32 object-cover rounded-lg"
          imageOnly
        />
      );
    },
  },
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: ({ row: { original }, column, table }) => {
      const finalImg = original.image;
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={original.name}
          image={finalImg}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          showPlaceholder
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TitleRow,
    minWidth: "400px",
  },
  website: {
    id: "website",
    Header: "Website",
    accessor: "website",
    Cell: (props) => {
      return props?.getValue() ? <TableCells.ButtonCell {...props} /> : null;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    className: "w-[60%]",
    renderCard: TableCards.LinkRow,
    labelAccessor: "Link",
    hrefAccessor: "website",
    targetAccessor: "_blank",
  },
  categories: {
    id: "category",
    Header: "Category",
    accessor: "categories",
    Cell: TableCells.BadgesCell,
    Filter: TableFilters.ArraySelectColumnFilter,
    filter: TableFilters.filterArraySelect,
    className: "w-[15%]",
    renderCard: TableCards.TextRow,
    renderMethod: (value) => value?.join(", ") || "",
    hasFilterSearch: true,
    minWidth: "400px",
  },
  addressLine1: {
    id: "addressLine1",
    Header: "AddressLine",
    accessor: "addressLine1",
    Cell: TableCells.TextCell,
    className: "w-[10%]",
    renderCard: TableCards.TextRow,
  },
  email: {
    id: "email",
    Header: "Message",
    accessor: "email",
    Cell: (originalRow) => {
      const handleClick = () => {
        originalRow?.column?.onOpenClick("send", originalRow);
      };
      const isLoading = !!originalRow?.instance?.options?.meta?.isLoading;

      return originalRow?.row?.original?.email || isLoading ? (
        <div className="p-4" onClick={(e) => e?.stopPropagation()}>
          <Button isDataLoading={isLoading} label="Email" onClick={handleClick} appearance="secondary" />
        </div>
      ) : (
        <div />
      );
    },
    renderCard: TableCards.TextRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  phone: {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
    className: "w-[10%]",
    renderCard: TableCards.TextRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  zipCode: {
    id: "zipCode",
    Header: "ZipCode",
    accessor: "zipCode",
    Cell: TableCells.TextCell,
    className: "w-[10%]",
    renderCard: TableCards.TextRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
};
