import React, { useState, useEffect, useMemo } from "react";
import { UilLayerGroup as GroupByIcon, UilSort, UilFileBlank, UilLayers } from "@iconscout/react-unicons";
import {
  ViewControl,
  Dropdown,
  IconButton,
  Radio,
  ViewButtonGroup,
  ViewButton,
  FilterRadio,
} from "@buildappeal/react-component-library";
import { useSearchParams } from "react-router-dom";

import { QUERY_PARAMS } from "@src/features/layout/constants";
import { VIEW_TYPES } from "@features/layout/constants";
import AlgoliaAutoComplete from "@src/utils/components/AlgoliaComponents/AutoCompleteSearchBox";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaSelectFilter from "@src/utils/components/AlgoliaComponents/SelectFilter";
import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import AlgoliaSortBy from "@src/utils/components/AlgoliaComponents/SortBy";
import AlgoliaTabFilters from "@src/utils/components/AlgoliaComponents/TabFilters";
import { fileSortByOptions, FILES_QUERY_SUGGESTION_INDEX, transformCurrentRefinementItems } from "../utils";

const FilesFilters = ({ showGrid, onChangeLayout, projectId, showSpaceFilter, showAddedByFilter, filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [groupBySelected, selectGroupBy] = useState("none");

  useEffect(() => {
    const groupByParam = searchParams.get(QUERY_PARAMS.GroupBy) || "none";

    if (groupByParam !== groupBySelected) {
      selectGroupBy(groupByParam);
    }
  }, [searchParams]);

  const radioOptions = [
    { title: "Date Added", value: "added" },
    { title: "Space", value: "space" },
    { title: "Content", value: "content" },
  ];

  const handleSelectGroupBy = (groupBySelected) => {
    if (groupBySelected === "none") {
      searchParams.delete(QUERY_PARAMS.GroupBy);
      searchParams.delete(QUERY_PARAMS.Collapsed);
      searchParams.delete(QUERY_PARAMS.Expanded);
    } else {
      searchParams.set(QUERY_PARAMS.GroupBy, groupBySelected);
    }
    setSearchParams(searchParams);
    selectGroupBy(groupBySelected);
  };

  const handleAdditionalAction = (action) => {
    if (action === "collapse") {
      searchParams.delete(QUERY_PARAMS.Expanded);
      searchParams.set(QUERY_PARAMS.Collapsed, true);
    }

    if (action === "expand") {
      searchParams.delete(QUERY_PARAMS.Collapsed);
      searchParams.set(QUERY_PARAMS.Expanded, true);
    }
    setSearchParams(searchParams);
  };

  const tabFilterItems = useMemo(() => {
    const items = [
      {
        id: "all",
        label: "All",
        value: "",
      },
      {
        id: "projects",
        label: "Projects",
        value: "NOT project:EMPTY",
      },
      { id: "products", label: "Products", value: "NOT product:EMPTY" },
      {
        id: "waiting_room",
        label: "Waiting Room",
        value:
          "product:EMPTY AND project:EMPTY AND contentType:EMPTY AND tags:EMPTY AND spaces:EMPTY AND productTypes:EMPTY AND designStyles:EMPTY AND finishes:EMPTY",
      },
    ];
    if (projectId) {
      items.splice(1, 1);
      items.pop();
    }

    return items;
  }, [projectId]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-3">
        <AlgoliaAutoComplete
          id="files"
          className="flex-1"
          placeholder="Search Files"
          querySuggestionIndex={FILES_QUERY_SUGGESTION_INDEX}
        />
        <ViewButtonGroup>
          <AlgoliaSelectFilter icon={UilFileBlank} attribute="groupedFileType" label="Type" />
          <ViewButton
            label="Group"
            leftIcon={UilLayers}
            wrapper={<FilterRadio />}
            options={[
              { title: "None", value: "none" },
              { title: "Date", value: "added" },
            ]}
            title="Group by"
            additionalActions={[
              {
                label: "Collapse all",
                onClick: () => handleAdditionalAction("collapse"),
                disabled: groupBySelected === "none",
              },
              {
                label: "Expand all",
                onClick: () => handleAdditionalAction("expand"),
                disabled: groupBySelected === "none",
              },
            ]}
            saveOnSelect
            hideFooter
            onSave={handleSelectGroupBy}
            value={groupBySelected}
          />
          <AlgoliaSortBy icon={UilSort} items={fileSortByOptions} />
        </ViewButtonGroup>

        <div className="flex items-center gap-x-2.5">
          {[VIEW_TYPES.Table, VIEW_TYPES.Card].map((layoutItem) => (
            <ViewControl
              key={layoutItem}
              type={layoutItem}
              isActive={(showGrid && layoutItem === VIEW_TYPES.Card) || (!showGrid && layoutItem === VIEW_TYPES.Table)}
              onClick={() => onChangeLayout(layoutItem === VIEW_TYPES.Card)}
            />
          ))}
          {!!projectId && (
            <Dropdown MenuButton={<IconButton icon={GroupByIcon} className="h-9 w-9" />}>
              <div className="w-[16.9375rem] p-6">
                <div className="space-y-4">
                  <span className="text-2xs font-medium uppercase text-neutral-500">Group By</span>
                  {radioOptions.map((option) => (
                    <Radio
                      label={option.title}
                      value={option.value}
                      onChange={() => handleSelectGroupBy(option.value)}
                      checked={option.value === groupBySelected}
                      isLabelSmall
                    />
                  ))}
                </div>
              </div>
            </Dropdown>
          )}
        </div>
      </div>

      <AlgoliaTabFilters items={tabFilterItems} defaultFilters={filters} />
      <div className="flex flex-wrap gap-2">
        <DynamicFacetsList>
          <AlgoliaFacetFilter attribute="contentType" label="Content" searchable />
          {showSpaceFilter && <AlgoliaFacetFilter attribute="spaces" label="Space" searchable />}
          <AlgoliaFacetFilter attribute="tags" label="Tags" searchable />
          <AlgoliaFacetFilter attribute="designStyles" label="Design Style" searchable />
          <AlgoliaFacetFilter attribute="finishes" label="Finish" searchable />
          <AlgoliaFacetFilter attribute="productTypes" label="Product Type" searchable />
          {showAddedByFilter && <AlgoliaFacetFilter attribute="addedBy" label="Added By" searchable />}
          <AlgoliaFacetFilter attribute="project.places.city" label="City" searchable />
          <AlgoliaFacetFilter attribute="project.fullAddress" label="Project" searchable />
          <AlgoliaFacetFilter attribute="size" label="Size" searchable />
        </DynamicFacetsList>
      </div>

      <CurrentRefinements transformItems={transformCurrentRefinementItems} />
    </div>
  );
};

export default FilesFilters;
