import { useState } from "react";
import { useMutation } from "react-query";
import { deleteMediaCoordinate } from "../../api";

const useDeleteMediaCoordinate = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    ({ id }) => {
      setError("");
      setIsSuccess(false);
      return deleteMediaCoordinate(id);
    },
    {
      onSuccess: (data) => {
        if (!data?.deleteMediaCoordinate?.success) {
          setError(`Error: ${data.deleteMediaCoordinate.message}`);
        } else {
          setIsSuccess(true);
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    deleteMediaCoordinate: mutate,
    deleteMediaCoordinateAsync: mutateAsync,
    isLoading,
    isSuccess,
    error,
  };
};

export default useDeleteMediaCoordinate;
