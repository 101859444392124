import React, { useMemo } from "react";
import { ActivityView, EmptyState, Link } from "@buildappeal/react-component-library";
import { Link as RouterLink } from "react-router-dom";

import { transformActivity } from "@features/activities/utils";
import useAlgoliaInfiniteHits from "@src/utils/hooks/useAlgoliaInfiniteHits";
import AlgoliaWrapper from "@src/utils/components/AlgoliaWrapper";
import AlgoliaInifiteHits from "@src/utils/components/AlgoliaComponents/InfiniteHits";
import AlgoliaAutoComplete from "@src/utils/components/AlgoliaComponents/AutoCompleteSearchBox";
import ActivityViewLoading from "./ActivityViewLoading";
import ActivityFilters from "./ActivityFilters";

const CommonActivityFeeds = ({
  className,
  showProjectFilter = true,
  hideEmptyState = false,
  hideTabFilters = false,
  hideTitle,
  showLatest = false,
  projectId,
  projectLink = "",
  pageLevel = "",
  projectSpace,
  actorUid,
  person,
  partnerId,
  algoliaInstanceId = "activities",
}) => {
  const { hits: data, isLoading } = useAlgoliaInfiniteHits(algoliaInstanceId);
  const filters = useMemo(() => {
    return projectId
      ? `project.id:${projectId}${projectSpace ? ` AND projectSpaceIds:${projectSpace.id}` : ""}`
      : actorUid
      ? `actor.uuid:${actorUid}`
      : person
      ? `actor.id:${person.id} AND actor.role:${person.type}`
      : partnerId
      ? `actor.id:${partnerId} AND actor.role:partner`
      : projectSpace
      ? `projectSpaceIds:${projectSpace.id}`
      : "";
  }, [projectId, actorUid, projectSpace, person, partnerId]);

  const activities = useMemo(() => {
    return data?.map((activity) => transformActivity(activity, pageLevel));
  }, [data]);

  const renderEmptyState = () =>
    hideEmptyState ? null : (
      <div className="flex flex-col items-center justify-center py-20">
        <EmptyState title="No activities" className="py-2" />
      </div>
    );

  return (
    <div className={className}>
      <div className="pt-10">
        {!hideTitle && <h2 className="mb-3 text-2xl">Activity</h2>}
        <div className="flex flex-col gap-3">
          <AlgoliaAutoComplete id="activities" className="flex-1" placeholder="Search Activity" />
          <ActivityFilters showProjectFilter={showProjectFilter} filters={filters} hideTabFilters={hideTabFilters} />
        </div>
      </div>
      {isLoading ? (
        <ActivityViewLoading showLatest={showLatest} />
      ) : (
        <>
          {showLatest && (
            <div className="flex items-center justify-between pt-10">
              <h3 className="text-md dark:text-white">Latest Activity</h3>
              <Link
                as={
                  <RouterLink
                    to={projectLink ? `${projectLink}&tab=activity` : `/projects?id=${projectId}&tab=activity`}
                  ></RouterLink>
                }
                className="dark:text-neutral-400"
                label="View all"
              />
            </div>
          )}

          <div layout="partial" className="pt-5 pb-16">
            <AlgoliaInifiteHits cacheId={algoliaInstanceId} emptyStateComponent={renderEmptyState}>
              <ActivityView data={activities} sort="desc" typeOfContent="activities" hideEditIcon />
            </AlgoliaInifiteHits>
          </div>
        </>
      )}
    </div>
  );
};

export default AlgoliaWrapper(CommonActivityFeeds, { indexName: "activities" });
