import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UilBookMedical as DuplicateIcon, UilTrashAlt as DeleteIcon } from "@iconscout/react-unicons";
import { NavCard, SpaceModule } from "@buildappeal/react-component-library";

import { QUERY_PARAMS } from "@src/features/layout/constants";
import ToastAnimated from "@src/utils/components/ToastAnimated/ToastAnimated";
import useToast from "@src/utils/hooks/useToast";
import { formatLengthAndWidthToSqft } from "@src/utils/common";
import ConfirmationModal from "@src/utils/components/ConfirmationModal";
import useAddSpaceToProject from "@src/features/spaces/hooks/useAddSpaceToProject";
import useDeleteProjectSpace from "@src/features/spaces/hooks/useDeleteProjectSpace";

const SpaceItem = ({ projectId, space, view, className, onSuccess }) => {
  const [params, setParams] = useSearchParams();

  const projectSpaceId = parseInt(params.get(QUERY_PARAMS.Space), 10);
  const sanitizedSpaceName = space.name ? space.name.replace("/", " ") : "";
  const spaceAltImage = `https://res.cloudinary.com/build-appeal/image/upload/v1656611706/ba-media/spaceIcons/${(
    sanitizedSpaceName || ""
  )
    .split(" ")
    .join("_")}.svg`;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateToast, setShowDuplicateToast] = useState(false);

  const { addToast } = useToast();
  const { addSpaceToProjectAsync } = useAddSpaceToProject(projectId);
  const { deleteProjectSpaceAsync, isLoading: deleteIsLoading } = useDeleteProjectSpace(projectId);

  const handleDeleteProjectSpace = async () => {
    const data = {
      id: space.id,
      projectId: projectId,
    };
    const deleteResp = await deleteProjectSpaceAsync(data);

    setShowDeleteModal(false);
    if (deleteResp.deleteProjectSpace.success) {
      onSuccess?.();
      addToast("Space deleted successfully");
      params.delete(QUERY_PARAMS.Space);
      params.delete(QUERY_PARAMS.Tab);
      setParams(params);
    } else {
      addToast("Error deleting space");
    }
  };

  const handleAddProjectSpace = async () => {
    const { id, spaceId, parentSpaceId, sortOrder, thumbnail, spaceNickname, ...input } = space;

    setShowDuplicateToast(true);

    const addResp = await addSpaceToProjectAsync({
      ...input,
      space: spaceId,
      parentSpace: parentSpaceId,
      project: projectId,
    });

    setShowDuplicateToast(false);

    if (addResp.addSpaceToProject.success) {
      addToast("Space duplicated successfully");
    } else {
      addToast("Error duplicating space");
    }
  };

  const handleClickSpace = (space) => {
    if (space) {
      params.set(QUERY_PARAMS.Space, space.id);
      params.set(QUERY_PARAMS.Id, projectId);
      params.set(QUERY_PARAMS.ShowCustomLeft, true);
      params.delete(QUERY_PARAMS.Section);
      setParams(params);
    }
  };

  return (
    <div className={className}>
      {view === "list" && (
        <NavCard
          title={space.spaceNickname || space.name}
          image={spaceAltImage}
          labels={[formatLengthAndWidthToSqft(space?.measurementsLength, space?.measurementsWidth, space?.sqft)]}
          onClick={() => handleClickSpace(space)}
          isActive={projectSpaceId === space.id}
          actions={[
            {
              id: "duplicate",
              label: "Duplicate",
              icon: DuplicateIcon,
              onClick: handleAddProjectSpace,
            },
            {
              id: "delete",
              label: "Delete Space",
              icon: DeleteIcon,
              onClick: () => setShowDeleteModal(true),
            },
          ]}
          showHover
          showPlaceholder
        />
      )}
      {view === "grid" && (
        <SpaceModule
          name={space.name}
          nickname={space.spaceNickname}
          onClick={() => handleClickSpace(space)}
          actions={[
            {
              id: "duplicate",
              label: "Duplicate",
              icon: DuplicateIcon,
              onClick: handleAddProjectSpace,
            },
            {
              id: "delete",
              label: "Delete Space",
              icon: DeleteIcon,
              onClick: () => setShowDeleteModal(true),
            },
          ]}
        />
      )}
      <ConfirmationModal
        title="Delete Space"
        message="Are you sure you want to delete this space?"
        info="This action cannot be undone. All associated data and files will be deleted. Be sure you want to proceed before deleting."
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={() => {
          handleDeleteProjectSpace();
        }}
        isLoading={deleteIsLoading}
      />
      {showDuplicateToast && <ToastAnimated description="Duplicating Space ..." alwaysVisible />}
    </div>
  );
};

export default SpaceItem;
