import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createOrUpdateNotification } from "../../api";

const useCreateOrUpdateNotification = (filters) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const upsertNotification = useMutation(
    (input) => {
      return createOrUpdateNotification(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createOrUpdateNotification.success) {
          setError(`Error: ${data.createOrUpdateNotification?.message}`);
        } else {
          queryClient.setQueryData(["notification", filters], (old) => {
            return {
              getNotifications: [data.createOrUpdateNotification.data],
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    upsertNotification: upsertNotification.mutate,
    upsertNotificationLoading: upsertNotification.isLoading,
    upsertNotificationSuccess: upsertNotification.isSuccess,
    error,
  };
};

export default useCreateOrUpdateNotification;
