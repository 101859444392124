import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  consultations: [],
  consultationItems: [],
};

export const consultationsSlice = createSlice({
  name: "consultations",
  initialState,
  reducers: {
    setConsultations: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        consultations: action.payload,
      };
    },
    setConsultationItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        consultationItems: action.payload,
      };
    },
  },
});

export const { setConsultations, setConsultationItems } = consultationsSlice.actions;

export default consultationsSlice.reducer;

export const getConsultations = (state) => state.consultations.consultations;
export const getConsultationItems = (state) => state.consultations.consultationItems;
