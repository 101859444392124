import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchActionsObjects, fetchActionsObjectsWithProducts } from "../api";
import { setActionsObjects, getActionsObjects, getActionsObjectsById } from "../actionsObjectsSlice";

const useActionsObjects = (filters = {}, hookOptions = {}) => {
  const { withObjectProducts = false, disableQuery = false } = hookOptions;
  const dispatch = useDispatch();
  const actionsObjects = useSelector(getActionsObjects);
  const actionsObjectsById = useSelector(getActionsObjectsById);
  const { data, isLoading, refetch, isFetching } = useQuery(
    ["actionsObjects", filters, withObjectProducts.toString()],
    () => {
      if (withObjectProducts) {
        return fetchActionsObjectsWithProducts(filters);
      }
      return fetchActionsObjects(filters);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !disableQuery,
    }
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    dispatch(setActionsObjects(data?.getActionsObjects));
  }, [data, dispatch]);

  const actionsObjectsAsOptions = useMemo(() => {
    if (!actionsObjects?.length) {
      return [];
    }
    return actionsObjects
      .map((actionObject) => {
        return {
          value: actionObject.id,
          title: `${actionObject.id} - ${actionObject.action.verbPresent} ${actionObject.object.description}`,
          actionsObjects: {
            ...actionObject,
            name: `${actionObject.action.verbPresent} ${actionObject.object.description}`,
          },
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [actionsObjects]);

  const actionsObjectsIdsGroupedByParent = useMemo(() => {
    if (!actionsObjects?.length) {
      return [];
    }
    return actionsObjects
      .filter((actionObject) => actionObject.parent === null)
      .map((actionObject) => {
        return {
          id: actionObject.id,
          children: actionsObjects
            .map((child) => {
              if (child.parent === actionObject.id) {
                return child.id;
              }
              return null;
            })
            .filter(Boolean),
        };
      });
  }, [actionsObjects]);

  return {
    data: actionsObjects,
    isLoading,
    refetch,
    isFetching,
    options: actionsObjectsAsOptions,
    dataById: actionsObjectsById,
    dataGroupedByParent: actionsObjectsIdsGroupedByParent,
  };
};

export default useActionsObjects;
