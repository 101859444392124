import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_STORYBOARDS = gql`
  query getStoryboards($filters: StoryboardFilterFields!) {
    getStoryboards(filters: $filters) {
      id
      createdAt
      name
      products {
        id
        name
      }
    }
  }
`;

const CREATE_STORYBOARD = gql`
  mutation createStoryboard($input: CreateStoryboardRequest!) {
    createStoryboard(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        name
        products {
          id
          name
        }
      }
    }
  }
`;
const UPDATE_STORYBOARD = gql`
  mutation UpdateStoryboard($input: UpdateStoryboardRequest!) {
    updateStoryboard(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        name
        products {
          id
          name
        }
      }
    }
  }
`;

export const fetchStoryboards = async (filters = {}) => {
  return await graphQLClient.request(GET_STORYBOARDS, { filters });
};

export const createStoryboard = async (input) => {
  return await graphQLClient.request(CREATE_STORYBOARD, { input });
};

export const updateStoryboard = async (input) => {
  return await graphQLClient.request(UPDATE_STORYBOARD, { input });
};
