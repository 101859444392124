import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_COLORS = gql`
  query getColors($filters: ColorFilterFields!) {
    getColors(filters: $filters) {
      id
      name
      colorValue
      icon
      parentId
      parent {
        id
        name
        colorValue
        icon
      }
    }
  }
`;

export const getColors = async (filters) => {
  return await graphQLClient.request(GET_COLORS, { filters });
};
