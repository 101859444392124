import AddStaff from "../staff/AddStaff";
import AddClient from "../clients/AddClient";
import AddContact from "../contacts/AddContact";
import CommonSendEmail from "./CommonSendEmail";

export const peopleActions = {
  add: {
    id: "add",
    items: {
      "add staff": {
        id: "add-staff",
        label: "Create Staff",
        sidebar: AddStaff,
      },
      "add client": {
        id: "add-client",
        label: "Create Client",
        sidebar: AddClient,
      },
      "add contact": {
        id: "add-contact",
        label: "Create Contact",
        sidebar: AddContact,
      },
    },
  },
  send: {
    id: "send",
    label: "Send Message",
    component: CommonSendEmail,
    container: "collapsedExpand",
  },
};
