import { NavCard, TableCells, TableCards, TableFilters } from "@buildappeal/react-component-library";
import generateInitials from "@src/utils/generateInitials";

export const noteColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      return (
        <NavCard
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city}, ${original.project?.places?.state} ${original.project?.places?.zipCode}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column }) => {
      return (
        <NavCard
          layout="card"
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city}, ${original.project?.places?.state} ${original.project?.places?.zipCode}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  createdAt: {
    id: "date",
    Header: "Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  message: {
    id: "message",
    Header: "Message",
    accessor: "message",
    Cell: TableCells.TextCell,
  },
  noteType: {
    id: "type",
    Header: "Type",
    accessor: "noteType",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  staff: {
    id: "by",
    Header: "By (Staff)",
    accessor: (row) => row.staff?.fullName,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    Cell: (originalRow) => {
      const { getValue, column } = originalRow;
      return (
        <TableCells.UserCell
          value={getValue()}
          column={column}
          row={originalRow.row}
          avatarSrc={originalRow.row.original?.staff?.avatar}
          initials={generateInitials(getValue() || originalRow.row.original?.email)}
          {...originalRow}
          id={originalRow.row.original?.staff?.id}
          type="staff"
          path="/people"
          onQuickViewOpen={column.onQuickViewOpen}
        />
      );
    },
  },
};
