import { useEffect, useState } from "react";
import useLooks from "@src/features/looks/hooks/useLooks";
import useBatchedEmails from "@src/features/emails/hooks/useBatchedEmails";
import useInvoices from "@src/features/invoices/hooks/useInvoices";
import useContracts from "@src/features/contracts/hooks/useContracts";
import useMessages from "@src/features/messages/hooks/useMessages";
import useActivities from "@src/features/activities/hooks/useActivities";
import useBills from "@src/features/bills/hooks/useBills";
import useBids from "@src/features/bids/hooks/useBids";
import useBudgets from "@src/features/budgets/hooks/useBudgets";
import useChangeOrders from "@src/features/changeOrders/hooks/useChangeOrders";
import useProposals from "@src/features/proposals/hooks/useProposals";
import usePurchaseOrders from "@src/features/purchaseOrders/hooks/usePurchaseOrders";
import usePurchases from "@src/features/purchases/hooks/usePurchases";
import useServices from "@src/features/services/hooks/useServices";
import useStoryboards from "@src/features/storyboards/hooks/useStoryboards";
import useStaff from "@src/features/staff/hooks/useStaff";
import useMarkets from "@src/features/markets/hooks/useMarkets";
import useActionsObjects from "@src/features/actionsObjects/hooks/useActionsObjects";
import useClients from "@src/features/clients/hooks/useClients";
import useContacts from "@src/features/contacts/hooks/useContacts";
import useNotes from "@src/features/notes/hooks/useNotes";
import useAllEstimates from "@src/features/estimates/hooks/useAllEstimates";
import useMaterials from "@src/features/materials/hooks/useMaterials";
import useFinishes from "@src/features/finishes/hooks/useFinishes";
import useObjects from "@src/features/objects/hooks/useObjects";

const usePageData = (currentPath, filters = {}) => {
  const { data: estimates, isLoading: estimatesLoading } = useAllEstimates(
    {},
    {
      disableQuery: currentPath !== "/financials/estimates",
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: emails,
    isLoading: emailsLoading,
    isBatchedFetchLoading,
    totalCount,
  } = useBatchedEmails(
    {},
    {
      disableQuery: currentPath !== "/emails",
      refetchOnWindowFocus: false,
    }
  );

  const { data: contracts, isLoading: contractsLoading } = useContracts(
    {},
    {
      disableQuery: currentPath !== "/contracts",
      refetchOnWindowFocus: false,
    }
  );

  const { data: messages, isLoading: messagesLoading } = useMessages(
    {},
    {
      disableQuery: currentPath !== "/messages",
      refetchOnWindowFocus: false,
    }
  );

  const { data: activities, isLoading: activitiesLoading } = useActivities(
    {},
    {
      disableQuery: currentPath !== "/activities",
      refetchOnWindowFocus: false,
    }
  );

  const { data: invoices, isLoading: invoicesLoading } = useInvoices(
    {},
    {
      disableQuery: currentPath !== "/financials/invoices",
      refetchOnWindowFocus: false,
    }
  );
  const { data: services, isLoading: servicesLoading } = useServices(
    {},
    {
      disableQuery: currentPath !== "/services",
      refetchOnWindowFocus: false,
    }
  );

  const { data: bills, isLoading: billsLoading } = useBills(
    {},
    {
      disableQuery: currentPath !== "/financials/bills",
      refetchOnWindowFocus: false,
    }
  );
  const { data: bids, isLoading: bidsLoading } = useBids(
    {},
    {
      disableQuery: currentPath !== "/financials/bids",
      refetchOnWindowFocus: false,
    }
  );
  const { data: budgets, isLoading: budgetsLoading } = useBudgets(
    {},
    {
      disableQuery: currentPath !== "/financials/budgets",
      refetchOnWindowFocus: false,
    }
  );
  const { data: changeOrders, isLoading: changeOrdersLoading } = useChangeOrders(
    {},
    {
      disableQuery: currentPath !== "/financials/change-orders",
      refetchOnWindowFocus: false,
    }
  );
  const { data: proposals, isLoading: proposalsLoading } = useProposals(
    {},
    {
      disableQuery: currentPath !== "/financials/proposals",
      refetchOnWindowFocus: false,
    }
  );
  const { data: purchaseOrders, isLoading: purchaseOrdersLoading } = usePurchaseOrders(
    {},
    {
      disableQuery: currentPath !== "/financials/purchase-orders",
      refetchOnWindowFocus: false,
    }
  );
  const { data: purchases, isLoading: purchasesLoading } = usePurchases(
    {},
    {
      disableQuery: currentPath !== "/financials/purchases",
      refetchOnWindowFocus: false,
    }
  );

  const { data: storyboards, isLoading: storyboardLoading } = useStoryboards(
    {},
    {
      disableQuery: currentPath !== "/designs/storyboards",
      refetchOnWindowFocus: false,
    }
  );

  const { data: staffs, isLoading: staffsLoading } = useStaff(
    {},
    {
      disableQuery: currentPath !== "/staff",
      refetchOnWindowFocus: false,
    }
  );

  const { data: markets, isLoading: marketsLoading } = useMarkets(
    {},
    {
      disableQuery: currentPath !== "/markets",
      refetchOnWindowFocus: false,
    }
  );

  const { data: actionsObjects, isLoading: actionsObjectsLoading } = useActionsObjects(
    {},
    {
      disableQuery: currentPath !== "/tasks/actions",
      refetchOnWindowFocus: false,
    }
  );

  const { data: clients, isLoading: clientsLoading } = useClients(
    {},
    {
      disableQuery: currentPath !== "/clients",
      refetchOnWindowFocus: false,
    }
  );

  const { data: contacts, isLoading: contactsLoading } = useContacts(
    {},
    {
      disableQuery: currentPath !== "/contacts",
      refetchOnWindowFocus: false,
    }
  );

  const { data: notes, isLoading: notesLoading } = useNotes(
    {},
    {
      disableQuery: currentPath !== "/notes",
      refetchOnWindowFocus: false,
    }
  );

  const { data: materials, isLoading: materialsLoading } = useMaterials(
    {},
    {
      disableQuery: currentPath !== "/materials",
    }
  );

  const { data: finishes, isLoading: finishesLoading } = useFinishes(
    {},
    {
      disableQuery: currentPath !== "/finishes",
      refetchOnWindowFocus: false,
    }
  );

  const { data: objects, isLoading: objectsLoading } = useObjects(
    {},
    {
      disableQuery: currentPath !== "/objects",
      refetchOnWindowFocus: false,
    }
  );

  const { data: looks, isLoading: looksLoading } = useLooks(
    {},
    {
      disableQuery: currentPath !== "/looks",
      refetchOnWindowFocus: false,
    }
  );

  const [finalData, setFinalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalRecordCount, setTotalRecordCount] = useState(0);

  useEffect(() => {
    if (currentPath === "/financials/estimates") {
      setFinalData(estimates);
      setLoading(estimatesLoading);
    }
  }, [currentPath, estimates, estimatesLoading]);

  useEffect(() => {
    if (currentPath === "/emails") {
      setFinalData(emails);
      setLoading(emailsLoading);
      setTotalRecordCount(totalCount);
    }
  }, [currentPath, emails, emailsLoading, totalCount]);

  useEffect(() => {
    if (currentPath === "/contracts") {
      setFinalData(contracts);
      setLoading(contractsLoading);
    }
  }, [currentPath, contracts, contractsLoading]);

  useEffect(() => {
    if (currentPath === "/messages") {
      setFinalData(messages);
      setLoading(messagesLoading);
    }
  }, [currentPath, messages, messagesLoading]);

  useEffect(() => {
    if (currentPath === "/activities") {
      setFinalData(activities);
      setLoading(activitiesLoading);
    }
  }, [currentPath, activities, activitiesLoading]);

  useEffect(() => {
    if (currentPath === "/financials/invoices") {
      setFinalData(invoices);
      setLoading(invoicesLoading);
    }
  }, [currentPath, invoices, invoicesLoading]);

  useEffect(() => {
    if (currentPath === "/financials/bids") {
      setFinalData(bids);
      setLoading(bidsLoading);
    }
  }, [currentPath, bids, bidsLoading]);

  useEffect(() => {
    if (currentPath === "/financials/bills") {
      setFinalData(bills);
      setLoading(billsLoading);
    }
  }, [currentPath, bills, billsLoading]);

  useEffect(() => {
    if (currentPath === "/financials/budgets") {
      setFinalData(budgets);
      setLoading(budgetsLoading);
    }
  }, [currentPath, budgets, budgetsLoading]);

  useEffect(() => {
    if (currentPath === "/financials/change-orders") {
      setFinalData(changeOrders);
      setLoading(changeOrdersLoading);
    }
  }, [currentPath, changeOrders, changeOrdersLoading]);

  useEffect(() => {
    if (currentPath === "/financials/proposals") {
      setFinalData(proposals);
      setLoading(proposalsLoading);
    }
  }, [currentPath, proposals, proposalsLoading]);

  useEffect(() => {
    if (currentPath === "/financials/purchase-orders") {
      setFinalData(purchaseOrders);
      setLoading(purchaseOrdersLoading);
    }
  }, [currentPath, purchaseOrders, purchaseOrdersLoading]);

  useEffect(() => {
    if (currentPath === "/financials/purchases") {
      setFinalData(purchases);
      setLoading(purchasesLoading);
    }
  }, [currentPath, purchases, purchasesLoading]);

  useEffect(() => {
    if (currentPath === "/services") {
      setFinalData(services);
      setLoading(servicesLoading);
    }
  }, [currentPath, services, servicesLoading]);

  useEffect(() => {
    if (currentPath === "/designs/storyboards") {
      setFinalData(storyboards);
      setLoading(storyboardLoading);
    }
  }, [currentPath, storyboards, storyboardLoading]);

  useEffect(() => {
    if (currentPath === "/staff") {
      setFinalData(staffs);
      setLoading(staffsLoading);
    }
  }, [currentPath, staffs, staffsLoading]);

  useEffect(() => {
    if (currentPath === "/markets") {
      setFinalData(markets);
      setLoading(marketsLoading);
    }
  }, [currentPath, markets, marketsLoading]);

  useEffect(() => {
    if (currentPath === "/tasks/actions") {
      setFinalData(actionsObjects);
      setLoading(actionsObjectsLoading);
    }
  }, [currentPath, actionsObjects, actionsObjectsLoading]);

  useEffect(() => {
    if (currentPath === "/clients") {
      setFinalData(clients);
      setLoading(clientsLoading);
    }
  }, [currentPath, clients, clientsLoading]);

  useEffect(() => {
    if (currentPath === "/contacts") {
      setFinalData(contacts);
      setLoading(contactsLoading);
    }
  }, [currentPath, contacts, contactsLoading]);

  useEffect(() => {
    if (currentPath === "/notes") {
      setFinalData(notes);
      setLoading(notesLoading);
    }
  }, [currentPath, notes, notesLoading]);

  useEffect(() => {
    if (currentPath === "/materials") {
      setFinalData(materials);
      setLoading(materialsLoading);
    }
  }, [currentPath, materials, materialsLoading]);

  useEffect(() => {
    if (currentPath === "/finishes") {
      setFinalData(finishes);
      setLoading(finishesLoading);
    }
  }, [currentPath, finishes, finishesLoading]);

  useEffect(() => {
    if (currentPath === "/objects") {
      setFinalData(objects);
      setLoading(objectsLoading);
    }
  }, [currentPath, objects, objectsLoading]);

  useEffect(() => {
    if (currentPath === "/looks") {
      setFinalData(looks);
      setLoading(looksLoading);
    }
  }, [currentPath, looks, looksLoading]);

  return {
    data: finalData,
    isLoading: loading,
    isBatchedFetchLoading,
    totalRecordCount,
  };
};

export default usePageData;
