import { TableCells, TableCards, NavCard } from "@buildappeal/react-component-library";
import { getAddressFromPlaces, isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";

export const supplierQuotesColumns = [
  {
    id: "project",
    Header: "Project",
    accessor: (row) => {
      return row.project?.places?.addressLine1;
    },
    Cell: ({ row: { original }, column, table }) => {
      return (
        <NavCard
          title={original.quoteNumber}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  {
    id: "created",
    Header: "Created",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: false,
  },
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];

export const loadingPlaceholderSupplier = {
  id: "",
  name: "",
  email: null,
  addressLine1: null,
  city: null,
  zipCode: null,
  state: null,
  labelColor: null,
  website: null,
  logo: [""],
  phone: null,
  categories: [],
  markets: [],
  contacts: [],
};

export const loadingPlaceholderSupplierQuote = {
  id: 1,
  total: 1000,
  project: {
    id: 7,
    places: {
      id: 7,
      addressLine1: "15408 Condesa Dr",
      city: "Whittier",
      zipCode: "90603",
      state: "CA",
    },
  },
  quoteNumber: "DCA1000",
  createdAt: "2022-03-17T22:05:55.000Z",
  supplierId: 1,
};

export const transformSupplierCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    status: "Status",
    categories: "Category",
  };

  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => ({
      ...refinement,
      label: isAlgoliaAttributeValueEmpty(refinement.label)
        ? `No ${refinementAttributeToLabelMap[item.attribute]}`
        : titleCase(refinement.label),
    })),
  }));
};
