import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  objects: [],
  objectsById: {},
};

export const objectsSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {
    setObjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        objects: action.payload,
      };
    },
    setObjectDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        objectsById: {
          ...originalState.objectsById,
          [action.payload.id]: action.payload,
        },
      };
    },
  },
});

export const { setObjects } = objectsSlice.actions;

export default objectsSlice.reducer;

export const getObjects = (state) => state.objects.objects;
export const selectObjectById = (id) => (state) => state.objects.objectsById[id];
