import { Link } from "react-router-dom";
import { AvatarMultiple, NavCard, TableCards, TableCells, TableFilters } from "@buildappeal/react-component-library";

import generateInitials from "@utils/generateInitials";
import { getProjectLink } from "@utils/common";
import { CONSULTATION_STATUSES } from "./utils";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Scheduled: "bg-orange-500",
  Unscheduled: "bg-gray-500",
  Completed: "bg-green-500",
  Cancelled: "bg-red-500",
};

export const consultationsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      const projectLink = getProjectLink(original?.project);
      return (
        <Link to={`${projectLink}&showCustomLeft=true&consultationId=${original.id}`}>
          <div onClick={(e) => e.stopPropagation()}>
            <NavCard
              isLoading={isLoading}
              title={original.project?.places?.addressLine1}
              description={`${original.project?.places?.city ? original.project?.places?.city + "," : ""} ${
                original.project?.places?.state || ""
              } ${original.project?.places?.zipCode || ""}`}
              onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
              image={original.project?.thumbnail?.path}
              titleClassName="line-clamp-2"
              showPlaceholder
            />
          </div>
        </Link>
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={original?.project?.thumbnail?.path}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageOnly
        />
      );
    },
    maxWidth: "2.5fr",
  },
  address: {
    id: "address",
    Header: "Address",
    accessor: (row) => row.project?.places?.addressLine1 || "",
    subtitleAccessor: (row) => {
      const { city, state, zipCode } = row?.project?.places;
      return `${city}, ${state} ${zipCode}`;
    },
    className: "whitespace-nowrap",
    renderCard: ({ row, column, table }) => {
      const { city, state, zipCode } = row?.original?.project?.places;
      return (
        <TableCards.TitleRow
          getValue={() => {
            return row?.original?.project?.places?.addressLine1 || "";
          }}
          subtitle={`${city}, ${state} ${zipCode}`}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  clients: {
    id: "client",
    Header: "Client",
    accessor: (row) => {
      return row?.client?.fullName;
    },
    avatarAccessor: (row) => row?.client?.avatar || "",
    Cell: (originalRow) => {
      const { row, column, table } = originalRow;
      const isLoading = !!table?.options?.meta?.isLoading;

      if (!isLoading && !row?.original?.client) {
        return null;
      }
      return (
        <TableCells.UserCell
          column={column}
          row={row}
          avatarSrc={row?.original?.client?.avatar}
          initials={generateInitials(row?.original?.client?.fullName || row?.original?.client?.email)}
          {...originalRow}
          id={row?.original?.client?.id}
          path="/people"
          onQuickViewOpen={column.onQuickViewOpen}
          role="client"
          type="client"
        />
      );
    },
    className: "whitespace-nowrap w-[10%]",
    renderCard: ({ row, column, table }) => {
      const client = row?.original?.client;
      if (!client) return null;
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            const client = row?.original?.client;
            return [
              {
                avatar: client?.avatar,
                name: client?.fullName,
              },
            ];
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  staff: {
    id: "staff",
    Header: "Staff",
    accessor: (row) => {
      return row?.staff?.[0]?.fullName;
    },
    avatarAccessor: (row) => row?.staff?.[0]?.avatar || "",
    Cell: ({ row: { original }, table, column }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <div className="py-4">
          <AvatarMultiple
            isLoading={isLoading}
            images={
              original.staff?.map((staff) => ({
                src: staff?.avatar,
                title: staff?.fullName,
                alt: staff?.fullName,
                initials: generateInitials(staff?.fullName),
                role: "staff",
                path: "/people",
                id: staff?.id,
                type: "staff",
              })) || []
            }
            size="sm"
            hasTooltip
            handleQuickViewOpen={column.onQuickViewOpen}
          />
        </div>
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.AvatarMultipleRow
          getValue={() => {
            return (
              row?.original?.staff?.map((owner) => ({
                avatar: owner.avatar,
                name: owner.fullName,
              })) || []
            );
          }}
          row={row}
          column={column}
          table={table}
        />
      );
    },
  },
  calendlyLink: {
    id: "calendlyLink",
    Header: "Calendly Link",
    accessor: "calendlyLink",
    Cell: (originalRow) => {
      return originalRow.row?.original?.status !== CONSULTATION_STATUSES.CANCELLED ? (
        <TableCells.ButtonCell value={originalRow.value} {...originalRow} />
      ) : null;
    },
    labelAccessor: "Link",
    hrefAccessor: "calendlyLink",
    targetAccessor: "_blank",
    renderCard: TableCards.LinkRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  consultationDate: {
    id: "consultationDate",
    Header: "Date",
    accessor: ({ consultationDate }) => (consultationDate ? new Date(consultationDate) : consultationDate),
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  createdAt: {
    id: "createdAt",
    Header: "Created At",
    accessor: ({ createdAt }) => (createdAt ? new Date(createdAt) : createdAt),
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          show
          status={originalRow.getValue()}
          circleColor={
            originalRow.getValue() ? STATUS_COLOR_CODES[originalRow.getValue()] || "bg-primary-500" : "bg-primary-500"
          }
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TextRow,
  },
  type: {
    id: "calendlyEventName",
    Header: "Type",
    accessor: "calendlyEventName",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
    minWidth: "250px",
  },
};
