const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: [
        "project",
        "type",
        "total",
        "dueDate",
        "title",
        "poCode",
        "poPrefix",
        "poNumber",
        "xeroInvoiceID",
        "poDate",
        "status",
      ],
    },
  ],
};

export default uiSchema;
