import React, { useMemo, useEffect } from "react";
import { ConfirmationToast } from "@buildappeal/react-component-library";
import noop from "lodash/noop";
import { useState } from "react";
import tableMultiSelectMap from "./allTableMultiSelect";

const TableMultiSelectActions = ({ items = [], onClose = noop, currentPath }) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isVariantCreate, setIsVariantCreate] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showVariantCreate, setShowVariantCreate] = useState(false);
  const multiSelectActionsProps = tableMultiSelectMap[currentPath];

  const showNotificationMessageToast = (msg, timer = null) => {
    setNotificationMessage(msg);

    if (timer) {
      setTimeout(() => {
        setNotificationMessage("");
      }, timer);
    }
  };

  const showSuccessNotificationToast = (msg) => {
    showNotificationMessageToast(msg, 2000);
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  const onDeleteActionClick = () => {
    if (loading) return;
    setDeleting(true);
  };
  const onEditActionClick = () => {
    if (loading) return;
    setEditing(true);
  };
  const onEditSuccess = () => {
    setEditing(false);
    setLoading(false);
  };
  const onVariantCreateActionClick = () => {
    if (loading) return;
    setIsVariantCreate(true);
  };
  const onDeleteSuccess = () => {
    setDeleting(false);
    setLoading(false);
    showSuccessNotificationToast(`${multiSelectActionsProps?.name}s deleted Successfully`);
  };
  const onVariantCreateSuccess = () => {
    setIsVariantCreate(false);
    setLoading(false);
  };

  const onError = () => {
    setLoading(false);
    setDeleting(false);
    setEditing(false);
  };

  const MultiSelectActionItem = multiSelectActionsProps
    ? React.createElement(multiSelectActionsProps.renderItem, {
        loading,
        onEditSuccess,
        onDeleteSuccess,
        setNotificationMessage: showNotificationMessageToast,
        editing,
        deleting,
        setLoading,
        selectedItems: items,
        onClose,
        onError,
        isVariantCreate,
        onVariantCreateSuccess,
      })
    : null;

  const count = items.length;

  useEffect(() => {
    if (currentPath !== "/products" || items?.length <= 1) return;
    let isItemVariant = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].isVariant) {
        isItemVariant = true;
        break;
      }
    }

    if (!isItemVariant) {
      setShowVariantCreate(true);
    } else {
      setShowVariantCreate(false);
    }
  }, [items, currentPath]);

  const variantsProps = useMemo(() => {
    return currentPath === "/products" && showVariantCreate
      ? {
          tertiaryActionLabel: "Create Variant",
          onTertiaryActionClick: onVariantCreateActionClick,
        }
      : {};
  }, [currentPath, showVariantCreate]);

  if (!count || !multiSelectActionsProps) {
    return null;
  }

  return (
    <>
      <ConfirmationToast
        show={!editing && count && !loading && !notificationMessage && !isVariantCreate}
        label={`${count} ${multiSelectActionsProps.name}${count > 1 ? "s" : ""} selected`}
        onCancel={onClose}
        actionLabel="Edit"
        onActionClick={multiSelectActionsProps.showEditAction ? onEditActionClick : null}
        message={notificationMessage}
        secondaryActionlabel={multiSelectActionsProps.showDeleteAction ? "Delete" : null}
        onSecondaryActionClick={multiSelectActionsProps.showDeleteAction ? onDeleteActionClick : null}
        {...variantsProps}
      />
      {MultiSelectActionItem}
    </>
  );
};

export default TableMultiSelectActions;
