import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  insightsByProjectId: [],
};

export const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setProjectInsights: (state, action) => {
      const originalState = original(state);

      const { id, insights } = action.payload;
      return {
        ...originalState,
        insightsByProjectId: {
          ...originalState.insightsByProjectId,
          [id]: { ...insights },
        },
      };
    },
  },
});

export const { setProjectInsights } = insightsSlice.actions;

export default insightsSlice.reducer;

export const selectProjectInsights = (id) => (state) => state.insights.insightsByProjectId[id];
