import { useEffect, useRef } from "react";

const isBrowser = typeof window !== "undefined" && window.document;

export default function usePlacesWidget(props) {
  const {
    ref,
    onPlaceSelected,
    inputAutocompleteValue = "autocomplete-field",
    options: {
      types = ["address"],
      componentRestrictions,
      fields = ["address_components", "place_id", "formatted_address"],
      bounds = new window.google.maps.Circle({
        center: { lat: 34.052235, lng: -118.243683 },
        radius: 480000,
      }).getBounds(),
      ...options
    } = {},
  } = props;
  const inputRef = useRef(null);
  const event = useRef(null);
  const autocompleteRef = useRef(null);
  const observerHack = useRef(null);

  useEffect(() => {
    const config = {
      ...options,
      fields,
      types,
      bounds,
    };

    if (componentRestrictions) {
      config.componentRestrictions = componentRestrictions;
    }

    if (autocompleteRef.current || !inputRef.current || !isBrowser) return;

    if (ref && !ref.current) ref.current = inputRef.current;

    const handleAutoComplete = () => {
      if (typeof window.google === "undefined")
        return console.error("Google has not been found. Make sure your provide apiKey prop.");

      if (!window.google.maps.places) return console.error("Google maps places API must be loaded.");

      if (!(inputRef.current instanceof HTMLInputElement)) return console.error("Input ref must be HTMLInputElement.");

      autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, config);

      event.current = autocompleteRef.current.addListener("place_changed", () => {
        if (onPlaceSelected && autocompleteRef && autocompleteRef.current) {
          onPlaceSelected(
            formatAddressForPlace(autocompleteRef.current.getPlace()),
            inputRef.current,
            autocompleteRef.current
          );
        }
      });
    };
    handleAutoComplete();
    return () => {
      if (event.current) event.current.remove();
      autocompleteRef.current = null;
    };
  }, [inputRef.current]);

  // Autofill workaround adapted from https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-window.google-maps-api-v3/49161445#49161445
  useEffect(() => {
    if (isBrowser && window.MutationObserver && inputRef.current && inputRef.current instanceof HTMLInputElement) {
      observerHack.current = new MutationObserver(() => {
        observerHack.current.disconnect();

        if (inputRef.current) {
          inputRef.current.autocomplete = inputAutocompleteValue;
        }
      });
      observerHack.current.observe(inputRef.current, {
        attributes: true,
        attributeFilter: ["autocomplete"],
      });
    }
  }, [inputAutocompleteValue]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setFields(fields);
    }
  }, [fields]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setBounds(bounds);
    }
  }, [bounds]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setComponentRestrictions(componentRestrictions);
    }
  }, [componentRestrictions]);

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setOptions(options);
    }
  }, [options]);

  return {
    ref: inputRef,
    autocompleteRef,
  };
}

const formatAddressForPlace = (place) => {
  const addressComponents = place.address_components;
  let address = "";
  let city = "";
  let state = "";
  let zipCode = "";
  let country = "";

  for (const component of addressComponents) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        address = `${component.long_name} ${address}`;
        break;
      }
      case "route": {
        address += component.long_name;
        break;
      }
      case "postal_code": {
        zipCode = component.long_name;
        break;
      }
      case "locality": {
        city = component.long_name;
        break;
      }
      case "administrative_area_level_1": {
        state = component.long_name;
        break;
      }
      case "country":
        country = component.long_name;
        break;
      default:
        break;
    }
  }

  return {
    address,
    city,
    state,
    country,
    zipCode,
  };
};
