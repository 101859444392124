import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createChangeOrder } from "../../api";

const useAddChangeOrder = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addChangeOrderMutation = useMutation(
    (input) => {
      return createChangeOrder(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createChangeOrder.success) {
          setError(`Error: ${data.createChangeOrder?.message}`);
        } else {
          queryClient.setQueryData(["changeOrders", filters], (old) => {
            return { getChangeOrders: [data.createChangeOrder.data, ...old?.getChangeOrders] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addChangeOrder: addChangeOrderMutation.mutate,
    addChangeOrderAsync: addChangeOrderMutation.mutateAsync,
    addChangeOrderLoading: addChangeOrderMutation.isLoading,
    addChangeOrderSuccess: addChangeOrderMutation.isSuccess,
    error,
  };
};

export default useAddChangeOrder;
