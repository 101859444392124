import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updatePartners } from "../../api";

const useUpdatePartners = (filters = {}) => {
  const queryClient = useQueryClient();

  const [error, setError] = useState();

  const updatePartnersMutation = useMutation(
    (input) => {
      setError(null);
      return updatePartners(input);
    },
    {
      onSuccess: (data) => {
        if (!data.updatePartners?.success) {
          setError(`Error: ${data.updatePartners?.message}`);
        } else {
          queryClient.setQueryData("partners", (old) => {
            if (!old) return { getPartners: [data.updatePartners.data] };
            return {
              getPartners: old.getPartners?.map((partner) => {
                const updatedPartner = data.updatePartners?.data?.find((p) => p.id === partner.id);
                if (updatedPartner) {
                  return {
                    ...partner,
                    ...updatedPartner,
                  };
                }
                return partner;
              }),
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    updatePartners: updatePartnersMutation.mutate,
    updatePartnersAsync: updatePartnersMutation.mutateAsync,
    updatePartnersLoading: updatePartnersMutation.isLoading,
    updatePartnersSuccess: updatePartnersMutation.isSuccess,
  };
};

export default useUpdatePartners;
