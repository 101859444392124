import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";
import AlgoliaTabFilters from "@src/utils/components/AlgoliaComponents/TabFilters";
import { QUERY_PARAMS } from "../layout/constants";
import { transformProjectCurrentRefinementItems } from "../projects/utils";

const tabItems = [
  {
    id: "active",
    label: "Active",
    value: "projectStageType:Lead AND stageGroup:Active AND isTest:false",
  },
  {
    id: "archived",
    label: "Archived",
    value: "projectStageType:Lead AND stageGroup:Archived AND isTest:false",
  },
  { id: "test", label: "Test", value: "projectStageType:Lead AND isTest:true" },
  { id: "all", label: "All", value: "projectStageType:Lead AND isTest:false" },
];

const LeadFilters = ({ hideTabFilters, resetToDefault }) => {
  const [searchParams] = useSearchParams();

  const defaultTabSelected = useMemo(() => {
    const tabParam = searchParams.get(QUERY_PARAMS.Tab);
    return tabParam ? (tabItems.some((item) => item.id === tabParam) ? tabParam : null) : null;
  }, [searchParams]);

  return (
    <div className="mb-3 space-y-4">
      <AlgoliaTabFilters
        items={tabItems}
        className={hideTabFilters ? "hidden" : ""}
        defaultSelected={hideTabFilters && resetToDefault ? "active" : defaultTabSelected}
      />

      <div className="flex flex-wrap items-center gap-2.5">
        <DynamicFacetsList>
          <AlgoliaFacetFilter attribute="type" label="Type" />
          <AlgoliaFacetFilter attribute="projectStatus" label="Stage" />
          <AlgoliaFacetFilter attribute="currentStatus.name" label="Status" />
          <AlgoliaFacetFilter attribute="source" label="Source" />
          <AlgoliaFacetFilter attribute="staff.fullName" label="Owner" searchable />
        </DynamicFacetsList>
      </div>
      <CurrentRefinements transformItems={transformProjectCurrentRefinementItems} />
    </div>
  );
};

export default LeadFilters;
