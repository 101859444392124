import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getUserUri, eventPageUrl } from "@utils/api/calendlyApi";

const useCalendlyEvents = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { data, isLoading: scheduleLoading } = useQuery("schedule", () => getUserUri({}), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!data?.data || scheduleLoading) {
      return;
    }
    const schedule = data?.data;
    let orginsationUrl = schedule?.resource?.current_organization;

    eventPageUrl(orginsationUrl).then((res) => {
      const result = res?.data?.collection;
      const finalResults = result?.filter(
        (event) => !!event?.active && event?.scheduling_url?.includes("https://calendly.com/buildappeal/")
      );
      setEvents(finalResults);
      setIsLoading(false);
    });
  }, [data, scheduleLoading]);

  const eventOptions = useMemo(() => {
    if (!events?.length) {
      return [];
    }
    return events.map((event) => ({
      title: event.name,
      value: event.uri,
      event,
    }));
  }, [events]);

  return {
    data: events,
    organizationURL: data?.data?.resource?.current_organization,
    options: eventOptions,
    isLoading,
  };
};

export default useCalendlyEvents;
