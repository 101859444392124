import { ImageAnnotate, Modal } from "@buildappeal/react-component-library";
import noop from "lodash/noop";
import { useEffect, useState } from "react";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import ToastAnimated from "@src/utils/components/ToastAnimated/ToastAnimated";
import { generateFileLink } from "../files/utils";
import useMediaCoordinatesWithFilters from "./hooks/useMediaCoordinatesWithFilters";
import useCreateMediaCoordinate from "./hooks/useCreateMediaCoordinate";
import useDeleteMediaCoordinate from "./hooks/useDeleteMediaCoordinate";

const MediaCoordinatesModal = ({ show, onClose = noop, file, options = [] }) => {
  const [url, setUrl] = useState("");
  const [annotations, setAnnotations] = useState([]);
  const [currAnnotation, setCurrAnnotation] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const { data: mediaCoordinates, isLoading } = useMediaCoordinatesWithFilters({ mediaId: file.id });
  const { createMediaCoordinateAsync } = useCreateMediaCoordinate();
  const { deleteMediaCoordinateAsync } = useDeleteMediaCoordinate();

  const showNotificationToast = (msg, timer = null) => {
    setToastMessage(msg);
    setShowToast(true);
    if (timer) {
      setTimeout(() => {
        setShowToast(false);
        setToastMessage("");
      }, timer);
    }
  };

  const onSubmit = async (newAnnotation) => {
    showNotificationToast("Saving...");
    try {
      const data = await createMediaCoordinateAsync({
        input: {
          mediaId: file.id,
          projectSpaceObjectId: newAnnotation.data.projectSpaceObject.id,
          xCoordinate: newAnnotation.geometry.x,
          yCoordinate: newAnnotation.geometry.y,
        },
      });
      if (data?.createMediaCoordinate?.success) {
        setAnnotations((prev) => [
          ...prev,
          {
            ...newAnnotation,
            data: {
              ...newAnnotation.data,
              mediaCoordinate: data.createMediaCoordinate.data,
            },
          },
        ]);
        showNotificationToast("Saved successfully", 2000);
      } else {
        showNotificationToast("Failed to save coordinate, Try again!", 2000);
      }
    } catch (err) {
      console.log("error saving coordinates: ", err.message);
      showNotificationToast("Failed to save coordinate, Try again!", 2000);
    }
  };

  const onDeleteAnnotation = async (annotation) => {
    if (!annotation.data.mediaCoordinate) return;

    showNotificationToast("Deleting...");
    try {
      const data = await deleteMediaCoordinateAsync({ id: annotation.data.mediaCoordinate.id });
      if (data?.deleteMediaCoordinate?.success) {
        setAnnotations((prev) => prev.filter((ann) => ann.data.id !== annotation.data.id));
        showNotificationToast("Deleted successfully", 2000);
      } else {
        showNotificationToast("Failed to delete coordinate, Try again!", 2000);
      }
    } catch (err) {
      console.log("error deleting coordinate: ", err.message);
      showNotificationToast("Failed to delete coordinate, Try again!", 2000);
    }
  };

  useEffect(() => {
    generateFileLink(file).then((url) => setUrl(url));
  }, [file]);

  useEffect(() => {
    if (isLoading || !mediaCoordinates || !mediaCoordinates.length) return;

    setAnnotations(
      mediaCoordinates.map((mediaCoordinate) => ({
        geometry: {
          x: mediaCoordinate.xCoordinate,
          y: mediaCoordinate.yCoordinate,
        },
        data: {
          id: `${mediaCoordinate.id}`,
          title: mediaCoordinate.projectSpaceObject.nickname,
          value: mediaCoordinate.projectSpaceObject.id,
          mediaCoordinate,
        },
      }))
    );
  }, [mediaCoordinates, isLoading]);

  return (
    <Modal
      isOpen={show}
      fullScreen
      onClose={onClose}
      title=" "
      titleClassName="h-6"
      classNameBg="inline-flex flex-col bg-white overflow-y-hidden relative"
    >
      <>
        {!url || isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
          </div>
        ) : (
          <div className="flex flex-1 justify-center overflow-y-hidden p-4">
            <ImageAnnotate
              imgUrl={url}
              containerClassName="h-full w-fit"
              className="h-full w-auto"
              onChange={setCurrAnnotation}
              onSubmit={onSubmit}
              value={currAnnotation}
              annotations={annotations}
              options={[...options]}
              onDeleteAnnotation={onDeleteAnnotation}
            />
          </div>
        )}
        {showToast && toastMessage && (
          <ToastAnimated description={toastMessage} alwaysVisible className=" bottom-1/3" />
        )}
      </>
    </Modal>
  );
};

export default MediaCoordinatesModal;
