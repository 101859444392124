import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../../api";
import { setClients, getClients, getTypeClients } from "../../clientsSlice";

const useClients = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const clients = useSelector(getClients);
  const typeClients = useSelector(getTypeClients);
  const { data, isLoading } = useQuery("clients", () => fetchClients({}), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(
      setClients({
        clients: data.getClients,
        typeClients: data.getClients?.filter((client) => client.type === "Client"),
      })
    );
  }, [data, isLoading, dispatch]);

  const clientsAsOptions = useMemo(() => {
    if (!clients?.length) {
      return [];
    }

    return clients
      .map((clientEntry) => {
        return {
          value: clientEntry.id,
          title: clientEntry.fullName,
          client: clientEntry,
        };
      })
      .sort((a, b) => a.title?.localeCompare(b.title));
  }, [clients]);

  return {
    data: clients,
    isLoading,
    options: clientsAsOptions,
    dataTypeClients: typeClients,
  };
};

export default useClients;
