import { useMemo } from "react";
import { useQuery } from "react-query";
import { getDTAttributes } from "../../api";

const useDTAttributes = () => {
  const { data, isLoading } = useQuery(["dtAttributes"], () => getDTAttributes(), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchInterval: Infinity,
  });

  const dtAttributOptions = useMemo(() => {
    if (!data?.getDTAttributes?.length) {
      return [];
    }
    return data.getDTAttributes.map((item) => ({
      attribute: item,
      value: item.id,
      title: `${item.objectName} - ${item.type}: ${item.name}`,
    }));
  }, [data]);

  return {
    data: data?.getDTAttributes,
    isLoading,
    options: dtAttributOptions,
  };
};

export default useDTAttributes;
