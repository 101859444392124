import React from "react";

import GenActionUploadFiles from "@src/features/files/common/GenActionUploadFiles";
import GenActionUpdateBulkItems from "@src/utils/components/GeneralActions/GenActionUpdateBulkItems";
import GeneralConsultationEventTracker from "@features/consultations/components/GeneralConsultationEventTracker";
import UploadProductFolders from "@features/products/UploadProductFolders";
import { useGeneralActionsState, useGeneralActionsUpdater } from "../../providers/generalActions";
import GenActionRequestQueue from "./GenActionRequestQueue";
import GenActionRequestsTracker from "./GenActionRequestsTracker";

const GeneralActions = () => {
  const actionsState = useGeneralActionsState();
  const { setActionsState } = useGeneralActionsUpdater();

  return (
    <div>
      {actionsState.currentAction === "uploadFiles" && (
        <GenActionUploadFiles
          isOpen={actionsState.isOpen}
          onClose={(updatedState) => {
            setActionsState({
              ...actionsState,
              ...updatedState,
            });
          }}
          triggeredBy={actionsState.triggeredBy}
          hideProjectDetails={actionsState.hideProjectDetails}
          {...(actionsState.actionProps || {})}
        />
      )}
      {actionsState.currentAction === "updateBulkItems" && (
        <GenActionUpdateBulkItems
          isOpen={actionsState.isOpen}
          onClose={(updatedState) => {
            setActionsState({
              ...actionsState,
              ...updatedState,
            });
          }}
          triggeredBy={actionsState.triggeredBy}
          numberBatchToSend={actionsState.numberBatchToSend}
          {...(actionsState.actionProps || {})}
        />
      )}
      {actionsState.currentAction === "productBulkImageUpload" && (
        <UploadProductFolders
          isOpen={actionsState.isOpen}
          onClose={(updatedState) => {
            setActionsState({
              ...actionsState,
              ...updatedState,
            });
          }}
        />
      )}
      {actionsState.currentAction === "requestsTracker" && (
        <GenActionRequestsTracker
          isOpen={actionsState.isOpen}
          triggeredBy={actionsState.triggeredBy}
          onClose={(updatedState) => {
            setActionsState({
              ...actionsState,
              ...updatedState,
            });
          }}
          {...(actionsState.actionProps || {})}
        />
      )}
      <GenActionRequestQueue displaySave={actionsState?.requestQueueShowSave} />
      <GeneralConsultationEventTracker
        currentAction={actionsState?.currentAction}
        {...(actionsState.actionProps || {})}
      />
    </div>
  );
};

export default GeneralActions;
