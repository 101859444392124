import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../../api";
import { setContacts, getContacts } from "../../contactsSlice";

const useContacts = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const contacts = useSelector(getContacts);

  const { data, isLoading } = useQuery(["contacts", filters], () => fetchContacts(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  const contactsAsOptions = useMemo(() => {
    if (!contacts?.length) {
      return [];
    }
    return contacts.map((contact) => {
      return {
        value: contact.id,
        title: `${contact.firstName || ""} ${contact.lastName || ""}`.trim(),
        contact,
      };
    });
  }, [contacts]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setContacts(data.getContacts));
  }, [data, isLoading, dispatch]);

  return {
    data: contacts,
    isLoading,
    options: contactsAsOptions,
  };
};

export default useContacts;
