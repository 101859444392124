import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import sortBy from "lodash/sortBy";
import { getAddressFromPlaces } from "@src/utils/common";
import { fetchProjects } from "../../api";
import { selectProjects, setProjects, selectProjectsById } from "../../projectsSlice";

const useProjects = (filters = {}, hookOptions = { disableQuery: false, refetchOnMount: false }) => {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const projectsById = useSelector(selectProjectsById);

  const { data, isLoading } = useQuery(["projects", filters], () => fetchProjects(filters), {
    refetchOnWindowFocus: false,
    refetchOnMount: !!hookOptions?.refetchOnMount,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    const finalProjects = data.getProjects.filter((project) => project?.projectStages?.stageType !== "Lead");
    dispatch(setProjects(sortBy(finalProjects, "id")));
  }, [data, isLoading]);

  const projectsAddressAsOptions = useMemo(() => {
    if (!data?.getProjects?.length) {
      return [];
    }
    return data.getProjects.map((project) => {
      return {
        value: project.id,
        title: getAddressFromPlaces(project?.places),
        project,
      };
    });
  }, [data?.getProjects]);

  return {
    data: projects,
    isLoading,
    dataById: projectsById,
    options: projectsAddressAsOptions,
  };
};

export default useProjects;
