import React from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import noop from "lodash/noop";
import { Button, AvatarMultiple } from "@buildappeal/react-component-library";

import generateInitials from "@src/utils/generateInitials";
import { CONSULTATION_STATUSES } from "../utils";
import { renderConsultationStatus, getConsultationMenu } from "./utils";

const ConsultationCard = React.memo(
  ({ consultation, onLaunchConsultation = noop, isLast = false, isPast = false, onRescheduleCancelAction }) => {
    return (
      <div className={clsx("flex justify-between py-4", isLast ? "" : "border-b border-b-neutral-100")}>
        <div className="flex-start flex w-full gap-4">
          {consultation.staff?.length > 0 && (
            <AvatarMultiple
              images={consultation.staff?.map((item) => ({
                ...item,
                src: item.avatar,
                initials: generateInitials(item.fullName),
                title: item.fullName,
                role: "staff",
              }))}
              size="sm"
              hasTooltip
            />
          )}
          <div className="w-full space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="text-sm">{dayjs(consultation.consultationDate).format("MMM D")}</span>
                <span className="h-1 w-1 rounded-full bg-neutral-500 hover:text-neutral-800 hover:underline" />
                <span className="text-sm">{dayjs(consultation.consultationDate).format("h:mm a")}</span>
              </div>
              <div className="flex items-center gap-2">
                {getConsultationMenu(
                  consultation.status,
                  consultation,
                  onLaunchConsultation,
                  isPast,
                  onRescheduleCancelAction
                )}
                {consultation.status === CONSULTATION_STATUSES.SCHEDULED && !isPast ? (
                  <Button onClick={onLaunchConsultation} label="Start" />
                ) : consultation.status === CONSULTATION_STATUSES.COMPLETED ? (
                  <Button appearance="tertiary" onClick={onLaunchConsultation} label="View" />
                ) : consultation.status !== CONSULTATION_STATUSES.CANCELLED ? (
                  <Button onClick={onLaunchConsultation} label="Open" />
                ) : null}
              </div>
            </div>
            {renderConsultationStatus(consultation.status, isPast, consultation.calendlyEventName)}
          </div>
        </div>
      </div>
    );
  }
);

ConsultationCard.displayName = "ConsultationCard";

export default ConsultationCard;
