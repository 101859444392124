import PaymentSummary from "./PaymentSummary";
import CardLineItem from "./CardLineItem";

const OrderSummary = ({ className, spaces = {}, total, orderSession, handleSendToClient }) => {
  return (
    <>
      <PaymentSummary
        total={total}
        stripeLink={orderSession?.stripePaymentLink}
        handleSendToClient={handleSendToClient}
      />
      <section className={className}>
        <div>
          {Object.keys(spaces).map((projectSpaceName) => {
            const proposalItems = spaces[projectSpaceName];
            const total = proposalItems.reduce((acc, item) => acc + item.cost, 0);
            const finalSpaceName = proposalItems?.[0]?.projectSpace?.spaces?.name;
            return (
              <CardLineItem
                key={projectSpaceName}
                title={`${projectSpaceName} Designs`}
                total={total}
                items={proposalItems.map((pItem) => ({
                  subTitle: pItem.name,
                }))}
                spaceName={finalSpaceName}
              />
            );
          })}
        </div>
      </section>
    </>
  );
};

export default OrderSummary;
