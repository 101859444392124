import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchChangeOrders } from "../../api";
import { setChangeOrders, getChangeOrders } from "../../changeOrdersSlice";

const useChangeOrders = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const changeOrders = useSelector(getChangeOrders);

  const { data, isLoading } = useQuery(["changeOrders", filters], () => fetchChangeOrders(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setChangeOrders(data.getChangeOrders));
  }, [data, isLoading, dispatch]);

  return {
    data: changeOrders,
    isLoading,
  };
};

export default useChangeOrders;
