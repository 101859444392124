import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PROJECTS = gql`
  query getProjects($filters: ProjectsFilterFields!) {
    getProjects(filters: $filters) {
      id
      color
      projectedStart
      projectedCompletion
      accessDifficulty
      lockboxCode
      dateCreated
      leadSourceLink
      clients {
        id
        fullName
        email
        avatar
      }
      staff {
        id
        fullName
        email
        avatar
        isOwner
      }
      spaces {
        id
        name
        thumbnail {
          id
          path
        }
        measurementsWidth
        measurementsLength
        sqft
        roomNumber
        spaceNickname
        floor
        spaceId
        permitRequired
        isLikeForLike
        parentSpaceId
        sortOrder
        budget
        name
      }
      projectStages {
        id
        name
        stageType
        order
      }
      places {
        id
        addressLine1
        city
        state
        zipCode
        latitude
        longitude
        stories
      }
      thumbnail {
        id
        path
      }
      leadSource
      type
      designStyles {
        id
        name
      }
    }
  }
`;

const GET_PROJECT_BY_ID = gql`
  query getProjectById($projectId: Int!) {
    getProjectByID(id: $projectId) {
      id
      color
      accessDifficulty
      lockboxCode
      dateCreated
      projectedStart
      projectedCompletion
      leadSource
      leadSourceLink
      type
      mktTitle
      mktDescription
      houzzPortfolioLink
      siteParking
      places {
        id
        addressLine1
        city
        state
        zipCode
        latitude
        longitude
        stories
        propertyType
      }
      clients {
        id
        fullName
        firstName
        lastName
        avatar
        phone
        email
        clientBudgetLow
        clientBudgetHigh
        isPrimaryContact
        createdAt
        leadInterestLevel
        leadDealStatus
        uuid
      }
      staff {
        id
        fullName
        avatar
        title
        isOwner
        email
        firstName
        lastName
        role
      }
      partners {
        id
        companyName
        avatar
        email
      }
      thumbnail {
        id
        path
      }
      designStyles {
        id
        name
      }
      scopeType
    }
  }
`;

const ADD_TEAM_MEMBER_TO_PROJECT = gql`
  mutation addStaffToProject($input: AddStaffToProjectRequest!) {
    addStaffToProject(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        avatar
        email
        isOwner
        role
      }
    }
  }
`;

const ADD_STAFFS_TO_PROJECT = gql`
  mutation addStaffsToProject($input: [AddStaffToProjectRequest]!) {
    addStaffsToProject(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        avatar
        email
        isOwner
        role
      }
    }
  }
`;

const UPDATE_STAFF_PROJECT = gql`
  mutation updateStaffProject($input: UpdateStaffProjectRequest!) {
    updateStaffProject(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        avatar
        email
        isOwner
        role
      }
    }
  }
`;

const ADD_PARTNER_TO_PROJECT = gql`
  mutation addPartnerToProject($input: AddPartnerToProjectRequest!) {
    addPartnerToProject(input: $input) {
      code
      success
      message
      data {
        id
        companyName
        avatar
        email
      }
    }
  }
`;

const ADD_PARTNERS_TO_PROJECT = gql`
  mutation addPartnersToProject($input: [AddPartnerToProjectRequest]!) {
    addPartnersToProject(input: $input) {
      code
      success
      message
      data {
        id
        companyName
        avatar
        email
      }
    }
  }
`;

const ADD_CLIENT_TO_PROJECT = gql`
  mutation addClientToProject($input: AddClientToProjectRequest!) {
    addClientToProject(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        avatar
        email
        isPrimaryContact
      }
    }
  }
`;

const ADD_CLIENTS_TO_PROJECT = gql`
  mutation addClientsToProject($input: [AddClientToProjectRequest]!) {
    addClientsToProject(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        avatar
        email
        isPrimaryContact
      }
    }
  }
`;

const UPDATE_CLIENT_PROJECT = gql`
  mutation updateClientProject($input: UpdateClientProjectRequest!) {
    updateClientProject(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        avatar
        email
        isPrimaryContact
      }
    }
  }
`;

const CREATE_PROJECT = gql`
  mutation createProject($input: CreateProjectRequest!) {
    createProject(input: $input) {
      code
      success
      message
      data {
        id
        color
        projectedStart
        projectedCompletion
        accessDifficulty
        lockboxCode
        dateCreated
        clients {
          id
          fullName
          email
          avatar
        }
        staff {
          id
          fullName
          email
          avatar
          isOwner
        }
        spaces {
          id
          name
          thumbnail {
            id
            path
          }
          measurementsWidth
          measurementsLength
          sqft
          roomNumber
          spaceNickname
          floor
          spaceId
          permitRequired
          isLikeForLike
          parentSpaceId
          sortOrder
          budget
        }
        projectStages {
          id
          name
          stageType
          order
        }
        places {
          id
          addressLine1
          city
          state
          zipCode
          latitude
          longitude
          stories
        }
        thumbnail {
          id
          path
        }
        leadSource
        leadSourceLink
        type
        designStyles {
          id
          name
        }
      }
    }
  }
`;

const GET_SPACES = gql`
  query getSpaces($filters: SpaceFilterFields!) {
    getSpaces(filters: $filters) {
      id
      name
      location
      sortOrder
    }
  }
`;

const GET_PROJECT_SPACES = gql`
  query getProjectSpaces($projectId: Int!) {
    getProjectSpaces(projectId: $projectId) {
      id
      projectId
      spaces {
        id
        measurementsWidth
        measurementsLength
        sqft
        roomNumber
        spaceNickname
        floor
        spaceId
        permitRequired
        isLikeForLike
        parentSpaceId
        sortOrder
        budget
        thumbnail {
          id
          path
        }
        name
        spaceType {
          id
          type
        }
        scopeType
      }
    }
  }
`;

const DELETE_PROJECT_STAFF = gql`
  mutation deleteProjectStaff($input: DeleteProjectStaffRequest!) {
    deleteProjectStaff(input: $input) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

const DELETE_PROJECT_PARTNER = gql`
  mutation deleteProjectPartner($input: DeleteProjectPartnerRequest!) {
    deleteProjectPartner(input: $input) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

const DELETE_PROJECT_ClIENT = gql`
  mutation deleteProjectClient($input: DeleteProjectClientRequest!) {
    deleteProjectClient(input: $input) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

const GET_FILES_PROJECT_SPACE_OBJECTS = gql`
  query getFilesProjectSpaceObjectsByRelatonalFilter($filters: FilesProjectSpaceObjectRelationalFilterFields!) {
    getFilesProjectSpaceObjectsByRelatonalFilter(filters: $filters) {
      id
      files {
        id
        name
        path
        type
        shareUrl
      }
      projectSpaceId
      projectSpaceObjectId
      object {
        id
      }
      projectSpaceObject {
        id
        width
        height
        depth
        unitType
        unitQuantity
        name
      }
    }
  }
`;

const GET_PROJECT_ADJACENT_SPACES = gql`
  query getProjectSpaceAdjacentSpaces($filters: ProjectAdjacentSpacesFilterFields!) {
    getProjectSpaceAdjacentSpaces(filters: $filters) {
      id
      spaceId
      name
      position
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation updateProject($input: UpdateProjectRequest!) {
    updateProject(input: $input) {
      code
      success
      message
      data {
        id
        color
        accessDifficulty
        lockboxCode
        dateCreated
        projectedStart
        projectedCompletion
        leadSource
        leadSourceLink
        type
        mktTitle
        mktDescription
        houzzPortfolioLink
        siteParking
        designStyles {
          id
          name
        }
        projectStages {
          id
          name
          stageType
          order
          currentStatusId
        }
        scopeType
      }
    }
  }
`;

const UPDATE_PROJECTS = gql`
  mutation updateProjects($input: [UpdateProjectRequest]!) {
    updateProjects(input: $input) {
      code
      success
      message
      data {
        id
        type
        projectStages {
          id
          name
          stageType
          order
        }
      }
    }
  }
`;

const UPDATE_PROJECT_PLACE = gql`
  mutation updatePlace($input: UpdatePlaceRequest!) {
    updatePlace(input: $input) {
      code
      success
      message
      data {
        id
        addressLine1
        city
        state
        zipCode
        latitude
        longitude
        stories
        propertyType
      }
    }
  }
`;

const GET_FILES_PROJECT_SPACE = gql`
  query getFilesProjectSpaces($filters: FilesProjectSpaceFilterFields!) {
    getFilesProjectSpaces(filters: $filters) {
      id
      createdAt
      projectSpaceId
      files {
        contentType
        id
        path
        name
        fileType
        type
        shareUrl
      }
    }
  }
`;

const CREATE_MULTI_FILES_PROJECT_SPACE = gql`
  mutation createMultiFilesProjectSpace($input: [FilesProjectSpaceRequest]!) {
    createMultiFilesProjectSpace(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        projectSpaceId
        files {
          contentType
          id
          path
          name
          fileType
          type
          shareUrl
        }
      }
    }
  }
`;

const DELETE_MULTI_FILES_PROJECT_SPACE = gql`
  mutation deleteMultiFilesProjectSpace($input: [FilesProjectSpaceRequest]!) {
    deleteMultiFilesProjectSpace(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        projectSpaceId
        files {
          contentType
          id
          path
          name
          fileType
          type
          shareUrl
        }
      }
    }
  }
`;

const GET_PROJECT_STAGE = gql`
  query getProjectStageByProject($projectId: Int!) {
    getProjectStageByProject(projectId: $projectId) {
      id
      name
      stageType
      currentStatusId
    }
  }
`;

export const fetchProjectStage = async (projectId) => {
  return await graphQLClient.request(GET_PROJECT_STAGE, { projectId });
};

export const fetchProjects = async (filters) => {
  return await graphQLClient.request(GET_PROJECTS, { filters });
};

export const fetchProjectById = async (projectId) => {
  return await graphQLClient.request(GET_PROJECT_BY_ID, { projectId });
};

export const addStaffToProject = async (input) => {
  return await graphQLClient.request(ADD_TEAM_MEMBER_TO_PROJECT, { input });
};

export const addStaffsToProject = async (input) => {
  return await graphQLClient.request(ADD_STAFFS_TO_PROJECT, { input });
};

export const updateStaffProject = async (input) => {
  return await graphQLClient.request(UPDATE_STAFF_PROJECT, { input });
};

export const addPartnerToProject = async (input) => {
  return await graphQLClient.request(ADD_PARTNER_TO_PROJECT, { input });
};

export const addPartnersToProject = async (input) => {
  return await graphQLClient.request(ADD_PARTNERS_TO_PROJECT, { input });
};

export const addClientToProject = async (input) => {
  return await graphQLClient.request(ADD_CLIENT_TO_PROJECT, { input });
};

export const addClientsToProject = async (input) => {
  return await graphQLClient.request(ADD_CLIENTS_TO_PROJECT, { input });
};

export const updateClientProject = async (input) => {
  return await graphQLClient.request(UPDATE_CLIENT_PROJECT, { input });
};

export const createProject = async (input = {}) => {
  return await graphQLClient.request(CREATE_PROJECT, { input });
};

export const fetchSpaces = async () => {
  const filters = {};

  return await graphQLClient.request(GET_SPACES, { filters });
};

export const deleteProjectStaff = async (input) => {
  return await graphQLClient.request(DELETE_PROJECT_STAFF, { input });
};

export const deleteProjectPartner = async (input) => {
  return await graphQLClient.request(DELETE_PROJECT_PARTNER, { input });
};

export const deleteProjectClient = async (input) => {
  return await graphQLClient.request(DELETE_PROJECT_ClIENT, { input });
};

export const fetchFilesProjectSpaceObjectsByRelatonalFilter = async (filters) => {
  return await graphQLClient.request(GET_FILES_PROJECT_SPACE_OBJECTS, { filters });
};

export const fetchProjectSpaceAdjacentSpaces = async (filters) => {
  return await graphQLClient.request(GET_PROJECT_ADJACENT_SPACES, { filters });
};

export const updateProject = async (input) => {
  return await graphQLClient.request(UPDATE_PROJECT, { input });
};

export const updateProjects = async (input) => {
  return await graphQLClient.request(UPDATE_PROJECTS, { input });
};

export const getProjectSpaces = (projectId) => {
  return graphQLClient.request(GET_PROJECT_SPACES, { projectId });
};

export const updateProjectPlace = async (input) => {
  return await graphQLClient.request(UPDATE_PROJECT_PLACE, { input });
};

export const fetchProjectSpaceFiles = async (filters = {}) => {
  return await graphQLClient.request(GET_FILES_PROJECT_SPACE, { filters });
};

export const addMultipleProjectSpaceFiles = async (input) => {
  return await graphQLClient.request(CREATE_MULTI_FILES_PROJECT_SPACE, { input });
};

export const deleteMultipleProjectSpaceFiles = async (input) => {
  return await graphQLClient.request(DELETE_MULTI_FILES_PROJECT_SPACE, { input });
};
