import { useQuery } from "react-query";
import { fetchAllUIPages } from "@src/features/layout/api";

const useUIPages = () => {
  const { data, isLoading } = useQuery("uiPages", fetchAllUIPages, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchInterval: Infinity,
  });

  return {
    data: data?.getUIPages,
    isLoading,
  };
};

export default useUIPages;
