import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import useToast from "@src/utils/hooks/useToast";
import { updateFile } from "../../api";
import { getFiles } from "../../filesSlice";

const useUpdateFile = ({
  projectId = null,
  fileType,
  spaceId,
  createdBy,
  contentType,
  skipQueryUpdate = false,
  projectSpacesId,
  ids = null,
  noSpaces = false,
  noContentType = false,
  noTags = false,
  noFileType = false,
  noCreatedBy = false,
  noDesignStyle = false,
  noFinish = false,
  noProductType = false,
  tags = [],
  designStylesId = [],
  finishesId = [],
  productTypesId = [],
} = {}) => {
  const files = useSelector(getFiles);
  const queryClient = useQueryClient();
  const [error, setError] = useState();
  const { addToast } = useToast();
  const updateFileMutation = useMutation(
    (input) => {
      return updateFile(input);
    },
    {
      onSuccess: (data) => {
        if (!data?.updateFileInfo.success) {
          setError(`Error: ${data?.updateFileInfo?.message}`);
        } else {
          if (skipQueryUpdate) {
            return;
          }

          queryClient.setQueryData(
            [
              "files",
              projectId,
              fileType,
              spaceId,
              createdBy,
              contentType,
              projectSpacesId,
              ids,
              noSpaces,
              noContentType,
              noTags,
              noFileType,
              noCreatedBy,
              noDesignStyle,
              noFinish,
              noProductType,
              tags,
              designStylesId,
              finishesId,
              productTypesId,
            ],
            (old) => {
              if (!old) return null;
              return {
                ...old,
                pages: old.pages.map((page) => {
                  return {
                    getFilesInfo: {
                      ...page.getFilesInfo,
                      files: page.getFilesInfo?.files?.map((file) => {
                        if (file.id === data?.updateFileInfo?.data?.id) {
                          return {
                            ...file,
                            ...data?.updateFileInfo.data,
                          };
                        }
                        return file;
                      }),
                    },
                  };
                }),
              };
            }
          );
          addToast("File info successfully updated");
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    files,
    error,
    updateFile: updateFileMutation.mutate,
    updateFileAsync: updateFileMutation.mutateAsync,
    updateFileLoading: updateFileMutation.isLoading,
    updateFileSuccess: updateFileMutation.isSuccess,
  };
};

export default useUpdateFile;
