import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { v4 as uuidv4 } from "uuid";
import useToast from "@src/utils/hooks/useToast";
import useObjects from "@src/features/objects/hooks/useObjects";
import useMultiFilesUpload from "@src/features/files/hooks/useMultiFilesUpload";
import useContacts from "../contacts/hooks/useContacts";
import { FILE_TYPES } from "../files/utils";
import { formSchema as suppliersFormSchema } from "./formSchema";
import { uiSchema as suppliersUiSchema } from "./uiSchema";
import useAddSupplier from "./hooks/useAddSupplier";
import useUpdateSupplier from "./hooks/useUpdateSupplier";

const AddActionObject = ({ onClose }) => {
  const { options: categoryOptions, isLoading: categoriesLoading } = useObjects();
  const { options: contactsAsOptions, isLoading: contactsLoading } = useContacts();
  const { createSupplierAsync, error: createSupplierError } = useAddSupplier();
  const { updateSupplierAsync } = useUpdateSupplier();
  const { addToast } = useToast();
  const { startUpload } = useMultiFilesUpload();

  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    const supplierData = {
      name: formState.name,
      addressLine1: formState.addressLine1,
      addressLine2: formState.addressLine2,
      website: formState.website,
      email: formState.email,
      logo: [],
      phone: formState.phone,
      city: formState.city,
      state: formState.state?.value,
      zipCode: formState.zipCode,
      contactFirstName: !formState.checkBoxVal && formState.contactFirstName ? formState.contactFirstName : undefined,
      contactLastName: !formState.checkBoxVal && formState.contactLastName ? formState.contactLastName : undefined,
      contactEmail: !formState.checkBoxVal && formState.contactEmail ? formState.contactEmail : undefined,
      contactPhone: !formState.checkBoxVal && formState.contactPhone ? formState.contactPhone : undefined,
      contactId: formState.checkBoxVal ? formState.contact?.value : null,
      categoryIds: formState?.categories?.map((cat) => cat.value),
    };

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const createResp = await createSupplierAsync(supplierData);
    if (createResp.createSupplier?.success) {
      let logo = [];
      if (formState.logo?.length) {
        const finalFilesList = await uploadFiles(createResp.createSupplier.data.id, formState.logo);
        if (finalFilesList?.length) {
          logo = finalFilesList.map((fileInfo) => fileInfo.key);
          await updateSupplierAsync({ id: createResp.createSupplier.data.id, logo });
          setFormSchema((prev) => ({
            ...prev,
            actionsState: {
              isLoading: false,
            },
          }));
          addToast("Supplier Successfully Created");
          onClose();
        }
      } else {
        setFormSchema((prev) => ({
          ...prev,
          actionsState: {
            isLoading: false,
          },
        }));
        addToast("Supplier Successfully Created");
        onClose();
      }
    }
  };

  const uploadFiles = async (supplierId, files) => {
    const finalFilesList = (files || []).map((file) => {
      const fileKey = `suppliers/${supplierId}/${uuidv4()}`;
      return {
        key: fileKey,
        type: FILE_TYPES.media,
        file,
      };
    });
    const results = await startUpload(finalFilesList, true);
    if (results.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  useEffect(() => {
    if (createSupplierError) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!createSupplierError,
          errorMsg: createSupplierError,
        },
      }));
    }
  }, [createSupplierError]);
  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (categoriesLoading || contactsLoading) {
      return;
    }
    const finalSchema = {
      ...suppliersFormSchema,
    };
    const allProperties = suppliersFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "categories") {
          acc[key].options = categoryOptions;
        }
        if (key === "checkBoxVal") {
          acc[key].formCondition.true.contact.options = contactsAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [categoryOptions, contactsAsOptions]);

  if (categoriesLoading || contactsLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={suppliersUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddActionObject;
