import React, { createContext, useContext, useState } from "react";

const GeneralActionsStateContext = createContext();
const GeneralActionsUpdaterContext = createContext();

export const GeneralActionsProvider = ({ children }) => {
  const [actionsState, setActionsState] = useState({
    isOpen: false,
    currentAction: null,
    triggeredBy: null,
    actionProps: null,
    hideProjectDetails: false, // Controls which add details form is displayed after upload
    requestQueueShowSave: false,
  });

  const addProjectToActionProps = (newProjectActionState) => {
    const updatedState = {
      ...newProjectActionState,
      hideProjectDetails:
        newProjectActionState.triggeredBy !== "project" ||
        (!!actionsState.actionProps?.item?.id && !!newProjectActionState.actionProps?.item?.id),
    };

    setActionsState(updatedState);
  };

  return (
    <GeneralActionsStateContext.Provider value={actionsState}>
      <GeneralActionsUpdaterContext.Provider value={{ setActionsState, addProjectToActionProps }}>
        {children}
      </GeneralActionsUpdaterContext.Provider>
    </GeneralActionsStateContext.Provider>
  );
};

export const useGeneralActionsState = () => {
  const actionsState = useContext(GeneralActionsStateContext);

  if (typeof actionsState === "undefined") {
    throw new Error("useGeneralActionsState must be used within a GeneralActionsProvider");
  }

  return actionsState;
};

export const useGeneralActionsUpdater = () => {
  const actionsUpdater = useContext(GeneralActionsUpdaterContext);

  if (typeof actionsUpdater === "undefined") {
    throw new Error("useGeneralActionsUpdater must be used within a GeneralActionsProvider");
  }

  return actionsUpdater;
};
