import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_STAFF = gql`
  query getStaff($filters: StaffFilterFields!) {
    getStaff(filters: $filters) {
      id
      fullName
      firstName
      lastName
      email
      avatar
      title
      phone
      status
      uuid
    }
  }
`;

const GET_STAFF_BY_ID = gql`
  query getStaffByID($id: Int!) {
    getStaffByID(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      avatar
      title
      phone
      status
      uuid
    }
  }
`;

const CREATE_STAFF = gql`
  mutation createStaff($input: CreateStaffRequest!) {
    createStaff(input: $input) {
      code
      success
      message
      data {
        id
        uuid
        fullName
        status
        firstName
        lastName
        title
        email
        phone
        avatar
        yearsExperience
        xeroContactID
      }
    }
  }
`;

const UPDATE_STAFF = gql`
  mutation updateStaff($input: UpdateStaffRequest!) {
    updateStaff(input: $input) {
      code
      success
      message
      data {
        id
        uuid
        fullName
        status
        firstName
        lastName
        title
        email
        phone
        avatar
        yearsExperience
        xeroContactID
      }
    }
  }
`;
export const fetchStaff = async (filters) => {
  return await graphQLClient.request(GET_STAFF, { filters });
};

export const fetchStaffDetail = async (id) => {
  return await graphQLClient.request(GET_STAFF_BY_ID, { id });
};

export const createStaff = async (input = {}) => {
  return await graphQLClient.request(CREATE_STAFF, { input });
};

export const updateStaff = async (input = {}) => {
  return await graphQLClient.request(UPDATE_STAFF, { input });
};
