const uiSchema = {
  groups: [
    {
      title: "Details",
      properties: ["template", "project"],
    },
    {
      title: "Recipients",
      properties: ["recipients"],
      isLast: true,
    },
  ],
};

export default uiSchema;
