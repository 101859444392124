import { useEffect, useMemo, useState } from "react";
import noop from "lodash/noop";
import pluralize from "pluralize";
import { Button, Select, Sidebar } from "@buildappeal/react-component-library";

import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";
import useBATypes from "@utils/hooks/useBATypes";
import useDesignStyles from "@features/designs/hooks/useDesignStyles";
import useProjectStages from "@features/actionsObjects/hooks/useProjectStages";
import { ProjectTypes } from "@features/projects/view/utils";

const ProjectsMultiSelect = ({
  editing = false,
  loading = false,
  setLoading = noop,
  onEditSuccess = noop,
  selectedItems = [],
  onClose = noop,
}) => {
  const { baTypes: leadSources, isLoading: leadSourcesLoading } = useBATypes("leadSource");
  const { options: designStylesAsOptions, designStylesLoading } = useDesignStyles();
  const { options: projectStagesAsOptions, isLoading: projectStagesLoading } = useProjectStages();
  const { setActionsState } = useGeneralActionsUpdater();

  const [formState, setFormState] = useState({});
  const [showEdit, setShowEdit] = useState(false);

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const stageStatusesAsOptions = useMemo(() => {
    if (!projectStagesAsOptions.length || !formState.projectStages?.value) return [];

    return (
      projectStagesAsOptions
        .find((x) => x.value === formState.projectStages?.value)
        ?.stage?.projectStageStatuses?.map((status) => {
          return {
            title: status.name,
            value: status.id,
            status,
          };
        }) || []
    );
  }, [projectStagesAsOptions, formState.projectStages]);

  const handleSave = async () => {
    if (loading) return;
    let groupDetails = {
      designStyleIds: [],
    };
    setLoading(true);

    const designStyleIds = formState.designStyles?.map((service) => {
      groupDetails.designStyleIds.push(service.value);
      return service.value;
    });

    let groupTitle = "";
    Object.keys(groupDetails).forEach((key) => {
      if (groupDetails[key].length > 0) {
        groupTitle += groupDetails[key].join(", ");
      }
    });

    const projectsInput = selectedItems.map((project) => {
      const input = {
        id: project.id,
      };

      if (formState.type) {
        input.type = formState.type?.value;
      }
      if (formState.projectStages) {
        input.projectStages = {
          currentStageId: formState.projectStages?.value,
          currentStatusId: formState.projectStageStatus?.value,
        };
      }
      if (designStyleIds?.length > 0) {
        input.designStyles = designStyleIds;
      }
      if (formState.leadSource) {
        input.leadSource = formState.leadSource?.value;
      }

      return { input, name: project.places?.addressLine1, id: project.id, groupTitle };
    });

    setActionsState({
      isOpen: true,
      currentAction: "updateBulkItems",
      triggeredBy: "projectsMultiSelect",
      numberBatchToSend: 5,
      actionProps: {
        itemsInput: projectsInput,
        onSuccess: onEditSuccess,
      },
    });
    setLoading(false);
    setShowEdit(false);
    onClose?.();
  };

  useEffect(() => {
    if (!editing) return;
    setShowEdit(true);
  }, [editing]);

  const handleSidebarClose = () => {
    setShowEdit(false);
    onClose();
  };

  return (
    <Sidebar isOpen={showEdit} onClose={handleSidebarClose} classNameContainer="!max-w-lg">
      <div className="flex h-full w-full flex-col space-y-4 px-6 py-10">
        <h3 className="text-xl">{`Edit ${selectedItems.length} ${pluralize("project", selectedItems.length)}`}</h3>
        <div className="mt-6 flex flex-1 flex-col gap-4">
          <Select
            label="Project Type"
            value={formState.type}
            options={ProjectTypes.map((opt) => ({
              title: opt,
              value: opt,
            }))}
            onChange={(value) => {
              setFormValue({ type: value });
            }}
          />
          <Select
            label="Project Stage"
            value={formState.projectStages}
            options={projectStagesAsOptions?.sort((a, b) => a.title.localeCompare(b.title))}
            onChange={(value) => {
              setFormValue({ projectStages: value });
            }}
            isLoading={projectStagesLoading}
          />
          {!!stageStatusesAsOptions?.length && (
            <Select
              label="Status"
              options={stageStatusesAsOptions?.sort((a, b) => a.title.localeCompare(b.title))}
              value={formState.projectStageStatus}
              onChange={(value) => {
                setFormValue({ projectStageStatus: value });
              }}
            />
          )}
          <Select
            label="Design Styles"
            value={formState.designStyles || []}
            options={designStylesAsOptions?.sort((a, b) => a.title.localeCompare(b.title))}
            onChange={(value) => {
              setFormValue({ designStyles: value });
            }}
            multiple
            isLoading={designStylesLoading}
          />
          <Select
            label="Lead Source"
            value={formState.leadSource}
            options={leadSources
              .map((opt) => ({
                title: opt,
                value: opt,
              }))
              ?.sort((a, b) => a.title.localeCompare(b.title))}
            onChange={(value) => {
              setFormValue({ leadSource: value });
            }}
            isLoading={leadSourcesLoading}
          />
        </div>
        <div className="flex w-full">
          <Button
            isLoading={loading}
            label={loading ? "Saving..." : "Save"}
            className="w-full !rounded-sm"
            onClick={handleSave}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default ProjectsMultiSelect;
