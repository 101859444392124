import React, { Fragment } from "react";
import { useDynamicWidgets } from "react-instantsearch-hooks-web";

function isReactElement(element) {
  return typeof element === "object" && element.props;
}

function getFacetAttribute(element) {
  if (!isReactElement(element)) {
    return undefined;
  }

  if (element.props.attribute) {
    return element.props.attribute;
  }

  if (Array.isArray(element.props.attributes)) {
    return element.props.attributes[0];
  }

  if (element.props.children) {
    invariant(
      React.Children.count(element.props.children) === 1,
      `<DynamicWidgets> only supports a single component in nested components. Make sure to not render multiple children in a parent component.
  Example of an unsupported scenario:
  \`\`\`
  <DynamicWidgets>
    <MyComponent>
      <RefinementList attribute="brand" />
      <Menu attribute="categories" />
    </MyComponent>
  </DynamicWidgets>
  \`\`\`
  `
    );

    return getFacetAttribute(React.Children.only(element.props.children));
  }

  return undefined;
}
/**
 * Throws an error if the condition is not met.
 *
 * The error is exhaustive in development, and becomes generic in production.
 *
 * This is used to make development a better experience to provide guidance as
 * to where the error comes from.
 */
export function invariant(condition, message) {
  if (condition) {
    return;
  }

  if (process.env.REACT_APP_STAGE !== "staging") {
    throw new Error("Invariant failed");
  }
  throw new Error(`[InstantSearch] ${typeof message === "function" ? message() : message}`);
}

function FallbackComponent() {
  return null;
}

const DynamicFacetsList = ({ children }) => {
  const { attributesToRender } = useDynamicWidgets();
  const filters = new Map();

  React.Children.forEach(children, (child) => {
    if (!child) {
      return;
    }
    const attribute = getFacetAttribute(child);

    invariant(
      attribute !== undefined,
      `<DynamicWidgets> only supports InstantSearch widgets with an \`attribute\` or \`attributes\` prop.`
    );

    filters.set(attribute, child);
  });

  return (
    <>
      {attributesToRender.map((attribute) => (
        <Fragment key={attribute}>{filters.get(attribute) || <FallbackComponent attribute={attribute} />}</Fragment>
      ))}
    </>
  );
};

export default DynamicFacetsList;
