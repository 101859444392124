import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { formatUSD } from "@src/utils/common";
import { createBudget, deleteBudget, fetchBudgets, updateBudget } from "../../api";
import { setBudgets, getBudgets } from "../../budgetsSlice";

const useBudgets = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const budgets = useSelector(getBudgets);
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const { data, isLoading } = useQuery(["budgets", filters], () => fetchBudgets(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  const addBudgetMutation = useMutation(
    (input) => {
      return createBudget(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createBudget.success) {
          setError(`Error: ${data.createBudget?.message}`);
        } else {
          queryClient.setQueryData("budgets", (old) => {
            return { getBudgets: [data.createBudget.data, ...old.getBudgets] };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );
  const editBudgetMutation = useMutation(
    (input) => {
      return updateBudget(input);
    },
    {
      onSuccess: (data) => {
        if (!data.updateBudget.success) {
          setError(`Error: ${data.updateBudget?.message}`);
        } else {
          queryClient.setQueryData("budgets", (old) => {
            return {
              getBudgets: old.getBudgets.map((budget) => {
                if (budget.id === data.updateBudget.data.id) {
                  return data.updateBudget.data;
                }
                return budget;
              }),
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  const deleteBudgetMutation = useMutation(
    (input) => {
      return deleteBudget(input);
    },
    {
      onSuccess: (data) => {
        if (!data.deleteBudget.success) {
          setError(`Error: ${data.deleteBudget?.message}`);
        } else {
          queryClient.setQueryData("budgets", (old) => {
            return {
              getBudgets: old.getBudgets.filter((budgets) => {
                return budgets.id !== data.deleteBudget.data.id;
              }),
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );
  const formatter = formatUSD.format;
  const budgetsAsOptions = useMemo(() => {
    if (!budgets?.length) {
      return [];
    }

    return budgets.map((budget) => {
      return {
        title: `${budget?.id} - ${formatter(budget?.estimatedTotal)}`,
        value: budget.id,
      };
    });
  }, [budgets]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setBudgets(data.getBudgets));
  }, [data, isLoading, dispatch]);

  return {
    data: budgets,
    isLoading,
    budgetsLoading: isLoading,
    options: budgetsAsOptions,
    addBudget: addBudgetMutation.mutate,
    addBudgetLoading: addBudgetMutation.isLoading,
    addBudgetSuccess: addBudgetMutation.isSuccess,
    error,
    editBudget: editBudgetMutation.mutate,
    editBudgetLoading: editBudgetMutation.isLoading,
    editBudgetSuccess: editBudgetMutation.isSuccess,
    deleteBudget: deleteBudgetMutation.mutate,
    deleteBudgetLoading: deleteBudgetMutation.isLoading,
    deleteBudgetSuccess: deleteBudgetMutation.isSuccess,
  };
};

export default useBudgets;
