import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectSpaceObjects: [],
  projectSpaceObjectsByParentId: {},
};

export const projectSpaceObjectsSlice = createSlice({
  name: "projectSpaceObjects",
  initialState,
  reducers: {
    setProjectSpaceObjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projectSpaceObjects: action.payload,
      };
    },
    setGroupedProjectSpaceObjects: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projectSpaceObjectsByParentId: action.payload,
      };
    },
    setGroupedProjectSpaceObjectsProductsTasks: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        projectSpaceObjectsProductsTasks: action.payload,
      };
    },
  },
});

export const { setProjectSpaceObjects, setGroupedProjectSpaceObjects, setGroupedProjectSpaceObjectsProductsTasks } =
  projectSpaceObjectsSlice.actions;

export default projectSpaceObjectsSlice.reducer;

export const getProjectSpaceObjects = (state) => state.projectSpaceObjects.projectSpaceObjects;

export const getGroupedProjectSpaceObjects = (state) => state.projectSpaceObjects.projectSpaceObjectsByParentId;
export const getGroupedProjectSpaceObjectsProductsTasks = (state) =>
  state.projectSpaceObjects.projectSpaceObjectsProductsTasks;
