import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaff } from "../../api";
import { setStaff, getStaff } from "../../staffSlice";

const useStaff = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const staff = useSelector(getStaff);

  const { data, isLoading } = useQuery(["staff", filters], () => fetchStaff(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setStaff(data.getStaff));
  }, [data, isLoading, dispatch]);

  const staffAsOptions = useMemo(() => {
    if (!staff?.length) {
      return [];
    }
    return staff
      .map((staffEntry) => {
        return {
          value: staffEntry.id,
          title: staffEntry.fullName,
          avatar: staffEntry.avatar,
          email: staffEntry.email,
          staff: staffEntry,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [staff]);

  return {
    data: staff,
    isLoading,
    options: staffAsOptions,
  };
};

export default useStaff;
