import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partners: [],
  partner: null,
};

export const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    setPartners: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        partners: action.payload,
      };
    },
    setPartner: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        partner: action.payload,
      };
    },
  },
});

export const { setPartners, setPartner } = partnersSlice.actions;

export default partnersSlice.reducer;

export const getPartners = (state) => state.partners.partners;
export const getPartner = (state) => state.partners.partner;
