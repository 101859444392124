import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import flatMap from "lodash/flatMap";
import { fetchActivities } from "../../api";

const useActivities = (filters = {}, hookOptions = { disableQuery: false }, pageCount = 20) => {
  const fetchActivitiesQuery = ({ pageParam }) => {
    return fetchActivities({ ...filters, take: pageCount, cursor: pageParam });
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useInfiniteQuery(
    ["activities", filters],
    fetchActivitiesQuery,
    {
      getNextPageParam: (lastPage) => {
        const lastPageCount = lastPage?.getActivities?.length;
        return lastPageCount > 0 ? lastPage.getActivities[lastPageCount - 1].id : undefined;
      },
      refetchOnWindowFocus: false,
      enabled: !hookOptions?.disableQuery,
    }
  );

  const activities = useMemo(
    () => (!data?.pages ? [] : flatMap(data.pages, (page) => page.getActivities)),
    [data?.pages]
  );
  return {
    data: activities,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    fetchNextPage,
  };
};

export default useActivities;
