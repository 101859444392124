const formSchema = {
  title: "Create Finish",
  properties: {
    name: {
      type: "text",
      label: "Finish Name",
      required: true,
    },
    type: {
      type: "text",
      label: "Type",
    },
    parent: {
      type: "select",
      label: "Parent",
    },
  },
};

export default formSchema;
