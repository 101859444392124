/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { omit } from "lodash";
import useToast from "@src/utils/hooks/useToast";
import { handleIntValues } from "@src/utils/common";
import useServices from "../services/hooks/useServices";
import actionsObjectsFormSchema from "./formSchema";
import actionsObjectsUiSchema from "./uiSchema";
import useActionsObjectsAdd from "./hooks/useActionsObjectsAdd";
import useActionsObjects from "./hooks/useActionsObjects";
import useActions from "./hooks/useActions";
import useObjects from "./hooks/useObjects";
import useProjectStages from "./hooks/useProjectStages";
// Use Form component to display form
// Update formSchema after all dynamic options are fetched
// https://storybook-buildappeal.s3.amazonaws.com/main/index.html?path=/story/mdx-form--basic For details
const AddActionObject = ({ onClose }) => {
  const { options: actionsObjectsAsOptions, isLoading: actionsObjectsOptionsLoading } = useActionsObjects();
  const { options: actionsAsOptions, dataById: actionsById, isLoading: actionsOptionsLoading } = useActions();
  const { options: objectsAsOptions, dataById: objectsById, isLoading: objectsOptionsLoading } = useObjects();
  const { options: projectStagesAsOptions, isLoading: projectStagesLoading } = useProjectStages();
  const { options: servicesAsOptions, isLoading: isLoadingServices } = useServices();
  const { addActionsObjectsAsync, error } = useActionsObjectsAdd(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();

  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    const data = omit(formState, ["invoice", "action", "object", "services", "stage", "parent"]);
    if (formState.invoiceItem) {
      data.invoiceItemId = formState.invoiceItem.id;
    }
    if (formState.action) {
      data.actionId = formState.action?.value;
    }
    if (formState.action) {
      data.objectId = formState.object?.value;
    }
    if (formState.services) {
      data.serviceIds = formState.services.map((item) => item.value || item.id);
    }

    if (formState.stage) {
      data.stageId = formState.stage.value;
    }
    if (formState.baseLaborUnitType) {
      data.baseLaborUnitType = formState.baseLaborUnitType?.value;
    }
    if (formState.parent) {
      data.parent = formState.parent?.value;
    }
    data.points = handleIntValues(data?.points);
    data.baseLaborCost = handleIntValues(data?.baseLaborCost);
    data.baseLaborTime = handleIntValues(data?.baseLaborTime);

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const res = await addActionsObjectsAsync(data);
    if (res.createActionObject?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Action Object Successfully Created");
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);
  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (
      actionsObjectsOptionsLoading ||
      actionsOptionsLoading ||
      objectsOptionsLoading ||
      projectStagesLoading ||
      isLoadingServices
    ) {
      return;
    }
    const finalSchema = {
      ...actionsObjectsFormSchema,
    };
    const allProperties = actionsObjectsFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "action") {
          acc[key].options = actionsAsOptions;
        }
        if (key === "object") {
          acc[key].options = objectsAsOptions;
        }
        if (key === "parent") {
          acc[key].options = actionsObjectsAsOptions;
        }
        if (key === "stage") {
          acc[key].options = projectStagesAsOptions;
        }
        if (key === "services") {
          acc[key].options = servicesAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [actionsAsOptions, objectsAsOptions, actionsObjectsAsOptions, projectStagesAsOptions]);

  if (
    actionsObjectsOptionsLoading ||
    actionsOptionsLoading ||
    objectsOptionsLoading ||
    projectStagesLoading ||
    isLoadingServices
  ) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={actionsObjectsUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddActionObject;
