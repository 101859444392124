import React, { useMemo } from "react";
import { Table } from "@buildappeal/react-component-library";
import { columns } from "@features/files/utils";

const FilesTable = ({
  files,
  onSelect,
  selectedFile,
  handleContextMenu,
  selectedMultipleFiles,
  setSelectedMultipleFiles,
}) => {
  const selectedMultipleFilesRows = useMemo(() => {
    const selectedFilesIndexes = [];
    selectedMultipleFiles.forEach((file) => {
      selectedFilesIndexes.push(files.findIndex((f) => f.id === file.id));
    });
    return selectedFilesIndexes;
  }, [selectedMultipleFiles]);

  const handleTableRowSelection = (rowsSelected) => {
    if (selectedFile) {
      onSelect(null);
    }
    const selectedFiles = rowsSelected.map((fileIndex) => files[fileIndex]);
    setSelectedMultipleFiles(selectedFiles);
  };

  return (
    <Table
      columns={columns}
      data={files}
      getRowProps={(row) => ({
        onClick: () => {
          if (!selectedMultipleFiles.length) {
            onSelect(row.original);
          }
        },
        onContextMenu: (event) => {
          handleContextMenu(event, row.original);
        },
        className: selectedFile && selectedFile.id === row?.original?.id ? "bg-neutral-50" : "",
      })}
      stickyTop={-40}
      hideHeader
      selectedRows={selectedMultipleFilesRows}
      onRowSelection={handleTableRowSelection}
      hidePagination
      pageProps={{
        pageSize: files?.length ?? 1,
      }}
    />
  );
};

export default FilesTable;
