import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { H } from "highlight.run";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn, selectCurrentUser, setCurrentUser } from "../../authSlice";
import { getCurrentUser } from "../../api";

// TODO: Remove after we get the user correctly
const MOCK_USER = {
  name: "Alex Del Toro",
  avatar: "https://picsum.photos/100/100",
};

const useUser = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const currentUser = useSelector(selectCurrentUser);

  const { refetch: getCurrentUserDetails } = useQuery("currentUser", getCurrentUser, {
    refetchOnWindowFocus: false,
    enabled: false,
    refetchInterval: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      const { getCurrentUserDetails } = data;
      if (isEmpty(getCurrentUserDetails[getCurrentUserDetails.role])) {
        return;
      }
      const userDetails = {
        ...getCurrentUserDetails[getCurrentUserDetails.role],
        uuid: getCurrentUserDetails.uuid,
        avatar: getCurrentUserDetails[getCurrentUserDetails.role].avatar,
        role: getCurrentUserDetails?.role?.[0],
      };
      window.analytics?.identify?.(userDetails.id, {
        email: getCurrentUserDetails.email,
        name: (userDetails?.firstName || "") + (userDetails?.lastName || ""),
      });
      H.identify(getCurrentUserDetails.email, {
        id: userDetails.id,
        highlightDisplayName: (userDetails?.firstName || "") + (userDetails?.lastName || ""),
      });
      dispatch(setCurrentUser(userDetails));
    },
    onError: () => {
      if (isLoggedIn) {
        dispatch(setCurrentUser(MOCK_USER));
      }
    },
  });

  useEffect(() => {
    if (isLoggedIn) {
      getCurrentUserDetails();
    } else {
      dispatch(setCurrentUser(null));
    }
  }, [isLoggedIn]);

  return {
    user: currentUser,
    isLoggedIn,
  };
};

export default useUser;
