import React, { useEffect, useState, useMemo } from "react";
import { Button, TextField, Select, Checkbox } from "@buildappeal/react-component-library";
import { isEmpty } from "lodash";
import usePartners from "../partners/hooks/usePartners";
import useSuppliers from "../suppliers/hooks/useSuppliers";
import useContactDelete from "./hooks/useContactDelete";
import DeleteModal from "./DeleteModal";

const ContactForm = ({ contact, onSubmit, isLoading, action, onClose, test }) => {
  const { options: partnersAsOptions } = usePartners();
  const { suppliersAsOptions } = useSuppliers({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { deleteContact, isLoading: isDeleteLoading, isSuccess } = useContactDelete();
  const [formState, setFormState] = useState({
    partner: contact?.partner || null,
    supplier: contact?.supplier || null,
    isPrimary: contact?.isPrimary || false,
    firstName: contact?.firstName || null,
    lastName: contact?.lastName || null,
    phone: contact?.phone || null,
    email: contact?.email || null,
  });

  const notLinkedPartners = useMemo(() => {
    if (!partnersAsOptions?.length) {
      return [];
    }
    const partnersIds = contact?.partners.map((entry) => entry.id) || [];
    return partnersAsOptions.filter((partner) => !partnersIds?.includes(partner?.value));
  }, [partnersAsOptions, contact]);

  const notLinkedSuppliers = useMemo(() => {
    if (!suppliersAsOptions?.length) {
      return [];
    }
    const suppliersIds = contact?.suppliers.map((entry) => entry.id) || [];
    return suppliersAsOptions.filter((supplier) => !suppliersIds?.includes(supplier?.value));
  }, [suppliersAsOptions, contact]);

  const [errors, setErrors] = useState({});
  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const onDelete = () => {
    handleCloseModal();
    const data = {
      id: contact.id,
    };
    deleteContact(data);
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};
    setErrors({});

    if (!formState.firstName) {
      newErrors.firstName = "First name is required";
    }
    if (!formState.lastName) {
      newErrors.lastName = "Last name is required";
    }
    if (!formState.email) {
      newErrors.email = "Email is required";
    }
    if (!formState.phone) {
      newErrors.phone = "Phone number is required";
    }

    if (isEmpty(newErrors)) {
      onSubmit(formState);
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="px-6 py-12">
      <h2 className="mb-4 font-semibold">{action} Contact</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <Select
            label="Partner"
            options={action === "Edit" ? notLinkedPartners : partnersAsOptions}
            value={{
              title: formState.partner?.companyName,
              value: formState.partner?.id || "",
            }}
            onChange={(opt) => {
              setFormValue({ partner: opt?.partner });
            }}
          />
        </div>
        <div>
          <Select
            label="Supplier"
            options={action === "Edit" ? notLinkedSuppliers : suppliersAsOptions}
            value={{
              title: formState.supplier?.name,
              value: formState.supplier?.id || "",
            }}
            onChange={(opt) => {
              setFormValue({ supplier: opt?.supplier });
            }}
          />
        </div>

        <div>
          <TextField
            value={formState.firstName || ""}
            label="First name"
            onChange={({ target: { value } }) => setFormValue({ firstName: value })}
          />
          {errors.firstName && <p className="mb-4 font-medium text-red-800">{errors.firstName}</p>}
        </div>
        <div>
          <TextField
            value={formState.lastName || ""}
            label="Last name"
            onChange={({ target: { value } }) => setFormValue({ lastName: value })}
          />
          {errors.lastName && <p className="mb-4 font-medium text-red-800">{errors.lastName}</p>}
        </div>
        <div>
          <TextField
            value={formState.email || ""}
            label="Email"
            onChange={({ target: { value } }) => setFormValue({ email: value })}
          />
          {errors.email && <p className="mb-4 font-medium text-red-800">{errors.email}</p>}
        </div>
        <div>
          <TextField
            value={formState.phone || ""}
            label="Phone"
            onChange={({ target: { value } }) => setFormValue({ phone: value })}
          />
          {errors.phone && <p className="mb-4 font-medium text-red-800">{errors.phone}</p>}
        </div>
        {action !== "Edit" && (
          <div>
            <Checkbox
              value={formState.isPrimary}
              label="Primary Contact"
              isLabelSmall={true}
              descriptor="set as primary contact"
              onChange={(opt) => setFormValue({ isPrimary: opt.target.checked })}
              checked={formState.isPrimary}
            />
          </div>
        )}
        <Button label={isLoading ? "Loading ..." : action} onClick={() => null} disabled={isLoading} />
        {action === "Edit" && (
          <Button
            type="button"
            className="mt-8 ml-8"
            disabled={isDeleteLoading}
            label={isDeleteLoading ? "Loading ..." : "Delete Contact"}
            appearance="secondary"
            onClick={() => setShowDeleteModal(true)}
          />
        )}
      </form>
      <DeleteModal isOpen={showDeleteModal} onClose={handleCloseModal} onDelete={onDelete} />
    </div>
  );
};

export default ContactForm;
