import dayjs from "dayjs";
import capitalize from "lodash/capitalize";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { isImageFile } from "@src/utils/isMedia";

export const formatUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const getAddressFromPlaces = (places) => {
  if (!isEmpty(places)) {
    const { addressLine1, zipCode, state, city } = places;
    return (
      addressLine1 + (city ? `, ${city},` : "") + (state ? ` ${state}` : "") + (zipCode ? ` ${zipCode}` : "") || ""
    );
  }
  return "";
};

export const getPlaceAddressStr = ({ addressLine1, addressLine2, city, state, zipCode }) =>
  [addressLine1, addressLine2, city, state, zipCode].filter((str) => !!str).join(", ");

export const formatLengthAndWidthToSqft = (length, width, sqft) => {
  let res = "";
  if (length && width) {
    res = `${length}' x ${width}'`;
  }
  if (sqft) {
    res = `${res} ${!!res ? "-" : ""} ${sqft} sq ft`;
  }
  return res;
};

export const formatInputDate = (date) => {
  if (date) {
    return new Date(date)?.toISOString()?.split("T")?.[0];
  }
  return "";
};

export const copy = (value) => {
  return navigator.clipboard.writeText(value);
};

export const formatCurrency = (number) => {
  if (!number) {
    return "";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  return formatter.format(number);
};

export const formatDecimal = (number) => {
  const toFixed = number % 1 > 0 ? 2 : 0;

  return Number.parseFloat(number)
    .toFixed(toFixed)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const handleIntValues = (value) => {
  if (!value || isNaN(value)) {
    return undefined;
  }
  return parseFloat(value);
};

export const handleStatusInput = (status) => {
  if (!status) return undefined;
  if (status?.toLowerCase() === "active") {
    return true;
  }
  return false;
};

export const phoneCallClick = (e, phone) => {
  e.preventDefault();
  if (phone) {
    const linkElement = document.createElement("a");
    linkElement.href = `tel:+${phone}`;
    linkElement.click();
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.slice(-10).match(/^(\d{3})(\d{3})(\d{3}|\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const formatActivityDescriptionDate = (str = "") => {
  const s = str.split(" ");
  if (!s[s.length - 1] || !dayjs(s[s.length - 1]).isValid()) return str;
  s[s.length - 1] = dayjs(s[s.length - 1]).format("MMM DD, YYYY @ h:mma");
  return s.join(" ");
};

export const checkIfImageExists = (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      resolve(true);
    } else {
      img.onload = () => {
        resolve(true);
      };

      img.onerror = () => {
        resolve(false);
      };
    }
  });
};

export const getThumbnailFromMedia = (media = []) => {
  if (!media?.length) {
    return null;
  }
  return (
    media?.find((media) => media.isThumbnail)?.path ||
    media?.find((media) => isImageFile(media.fileType))?.path ||
    media?.[0]?.path
  );
};

export const areIdsInArrayEqual = (array1, array2) => {
  if (array1?.length !== array2?.length) {
    return false;
  }
  const array1Ids = array1.map((item) => item.id);
  const array2Ids = array2.map((item) => item.id);

  return array1Ids.every((id) => array2Ids.includes(id));
};

export const getProjectLink = (project) => {
  if (!project) return "";
  const { projectStages, isTest } = project;

  const tabParam = isTest
    ? "test"
    : projectStages?.stageGroup === "Archived"
    ? "archived"
    : projectStages?.stageGroup === "Active"
    ? "active"
    : null;
  if (projectStages?.stageType === "Lead") {
    return `/leads?id=${project.id}${tabParam ? `&tab=${tabParam}` : ""}`;
  }
  return `/projects?id=${project.id}${tabParam ? `&tab=${tabParam}` : ""}`;
};

// Only returns a link for /leads and /projects pages
export const getProjectLinkFromPathname = (projectId) => {
  if (!projectId || !window.location.pathname) return "";
  if (window.location.pathname.includes("/leads")) {
    return `/leads?id=${projectId}`;
  }
  return window.location.pathname.includes("/projects") ? `/projects?id=${projectId}` : "";
};

export const isAlgoliaAttributeValueEmpty = (val) => val === "EMPTY";

/**
 * Convert seconds to HH:MM:SS
 * If seconds exceeds 24 hours, hours will be greater than 24 (30:05:10)
 *
 * @param {number} seconds
 * @returns {string}
 */
export const getFormattedDuration = (seconds) => {
  const SECONDS_PER_DAY = 86400;
  const HOURS_PER_DAY = 24;

  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
  const hmsStr = hms.replace(/^(\d+)/, (h) => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, "0"));

  return seconds > 3600 ? hmsStr : hmsStr.substring(3);
};

export const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const ButtonAppearance = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  NEUTRAL: "neutral",
  ACCENT: "accent",
};

export const isHTMLStringEmpty = (htmlString) => {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  const content = div.innerText.trim();

  if (content === "") {
    return true;
  }
  return false;
};

export const titleCase = (str) => map(str.split(" "), capitalize).join(" ");

export const convertNumberToTime = (numSecs) => {
  const secNum = parseInt(numSecs, 10);
  const hours = Math.floor(secNum / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((secNum - hours * 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (secNum - hours * 3600 - minutes * 60).toString().padStart(2, "0");

  if (hours === "00") {
    return `${minutes}:${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
};

const capitalizeString = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const prettifyString = (s) => {
  // replace dashes with spaces
  const prettyTitle = s.replace(/-/g, " ");

  return capitalizeString(prettyTitle);
};

export const getPageTitle = (firstTitle = "", currentLocation) => {
  if (currentLocation?.pathname?.length > 0) {
    const routeParts = currentLocation?.pathname?.split("/");
    const pageSection = routeParts[routeParts.length - 1];
    const pageTitle = `${prettifyString(firstTitle)} ${prettifyString(pageSection)} | Build Appeal`;
    return prettifyString(pageSection).length > 0 ? pageTitle.trim() : "Build Appeal";
  } else {
    return "Build Appeal";
  }
};
