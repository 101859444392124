import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  UilLinkAlt as CopyLinkIcon,
  UilImport as ImportIcon,
  UilTrashAlt as BinIcon,
  UilPen as EditIcon,
  UilApps as GridIcon,
  UilImage as ThumbnailIcon,
} from "@iconscout/react-unicons";
import noop from "lodash/noop";

import isMediaFile from "@src/utils/isMedia";
import { copy } from "@utils/common";
import FileCard from "./components/FileCard";
import { getFileType, getFileLink } from "./utils";

const FileCardItem = ({
  file,
  selectFile = noop,
  selectedFile,
  selectedMultipleFiles = [],
  onDownload = noop,
  onDelete = noop,
  onRename = noop,
  onEditAltText = noop,
  onSetThumbnail = noop,
  onMultiSelect = noop,
  hideThumbnailAction = false,
}) => {
  const { isPdf, isImage, isVideo, isAudio } = getFileType(file?.fileType);
  const fileLink = getFileLink({ fileId: file?.id, fileType: file?.type, fileName: file?.name });

  const actions = [
    {
      id: "copy_link",
      label: "Copy link",
      wrapper: <div />,
      icon: CopyLinkIcon,
      onClick: () => copy(fileLink),
    },
    {
      id: "edit",
      label: "Edit",
      wrapper: <div />,
      icon: EditIcon,
      onClick: () => selectFile(file),
    },
    {
      id: "multi_select",
      label: "Multi-Select",
      wrapper: <div />,
      icon: GridIcon,
      onClick: () => onMultiSelect(file),
    },
    {
      id: "download",
      label: "Download",
      wrapper: <div />,
      icon: ImportIcon,
      onClick: () => onDownload({ fileToDownload: file }),
    },
    {
      id: "rename",
      label: "Rename",
      wrapper: <div />,
      icon: EditIcon,
      onClick: (e) => {
        e.stopPropagation();
        onRename(file);
      },
    },
    {
      id: "edit_alt_text",
      label: "Edit Alt Text",
      wrapper: <div />,
      icon: EditIcon,
      onClick: (e) => {
        e.stopPropagation();
        onEditAltText(file);
      },
    },
    {
      id: "delete",
      label: "Remove",
      wrapper: <div />,
      icon: BinIcon,
      onClick: () => onDelete(file),
    },
  ];

  if (isMediaFile(file?.fileType) && !hideThumbnailAction) {
    actions.splice(2, 0, {
      id: "set_thumbnail",
      label: "Set as thumbnail",
      wrapper: <div />,
      icon: ThumbnailIcon,
      onClick: () => onSetThumbnail(file),
    });
  }

  const isMultiSelected = useMemo(() => {
    if (!selectedMultipleFiles.length) return false;

    return selectedMultipleFiles.some((f) => f.id === file.id);
  }, [selectedMultipleFiles, file]);

  const src = useMemo(() => {
    if (isPdf || isAudio || isImage) return file.path;
    if (isVideo) return `${file.path.split(".")[0]}.jpg`;
    return "https://via.placeholder.com/728x300.png?text=Document";
  }, [file]);

  const handleClick = () => {
    if (file?.id === selectedFile?.id) {
      selectFile(file, false, true);
      return;
    }
    selectFile(file);
  };

  return (
    <FileCard
      src={src}
      fileName={file?.name}
      fileType={file?.fileType}
      metadata={file?.metadata}
      onClick={() => handleClick()}
      isActive={file?.id === selectedFile?.id}
      imgTransformations={{
        width: 450,
        format: "jpg",
      }}
      actions={!selectedMultipleFiles.length ? actions : null}
      isMultiSelected={isMultiSelected}
    />
  );
};

FileCardItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object,
  selectFile: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selectedFile: PropTypes.object,
  hideThumbnailAction: PropTypes.bool,
};

export default FileCardItem;
