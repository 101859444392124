import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { fetchTasks } from "../../api";
import { setAllTasksOptions, getAllTaskOptions } from "../../tasksSlice";

const useTasksAsOptions = () => {
  const dispatch = useDispatch();
  const tasksAsOptions = useSelector(getAllTaskOptions);

  const { data, isLoading } = useQuery("allTasks", () => fetchTasks({ take: 150 }), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const allTaskOptions =
      data?.getTasks?.tasks.length > 0
        ? data.getTasks.tasks.map((task) => ({
            title: `${task.id} - ${task.actionsObjects?.action?.verbPresent} ${task.actionsObjects?.object?.description}`,
            value: task.id,
          }))
        : [];

    dispatch(setAllTasksOptions(allTaskOptions));
  }, [data?.getTasks?.tasks, isLoading, dispatch]);

  return {
    tasksAsOptionsLoading: isLoading,
    tasksAsOptions,
  };
};

export default useTasksAsOptions;
