import { TableCards, TableCells, TableFilters, NavCard } from "@buildappeal/react-component-library";
import { isImageFile } from "@src/utils/isMedia";

export const objectColumns = {
  select: {
    id: "select",
    accessor: "selected_row",
    Cell: TableCells.CheckboxCell,
    renderCard: TableCards.CheckboxInputRow,
    disableSortBy: true,
    minWidth: "50px",
    maxWidth: "50px",
  },
  description: {
    id: "description",
    Header: "Name",
    accessor: "description",
    Cell: ({ row: { original }, column, instance }) => {
      const finalImg = original.media?.length
        ? original.media.find((media) => media.isThumbnail)?.path ||
          original.media.find((media) => isImageFile(media.fileType))?.path
        : original.photos?.[0] || "";
      const isLoading = !!instance?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={original?.description}
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          titleClassName="line-clamp-1"
          descriptionClassName="line-clamp-1"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, instance }) => {
      const finalImg = original.media?.length
        ? original.media.find((media) => media.isThumbnail)?.path ||
          original.media.find((media) => isImageFile(media.fileType))?.path
        : original.photos?.[0] || "";
      const isLoading = !!instance?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={original?.description}
          layout="card"
          image={finalImg}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageOnly
        />
      );
    },
    maxWidth: "3fr",
  },
  icon: {
    id: "icon",
    Header: "Icon",
    accessor: "icon",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  type: {
    id: "type",
    Header: "Type",
    accessor: "objectType",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  parent: {
    id: "parent",
    Header: "Parent",
    accessor: (row) => row?.parent?.description || "",
    className: "whitespace-nowrap",
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.TitleRow
          getValue={() => {
            return row?.original?.parent?.description || "";
          }}
          row={row}
          column={column}
          table={table}
          className="line-clamp-2"
        />
      );
    },
  },
  id: {
    id: "id",
    Header: "ID",
    accessor: (row) => row.id?.toString(),
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    renderCard: TableCards.TextRow,
    maxWidth: "80px",
  },
};
