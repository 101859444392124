import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";
import { keyBy, sortBy, merge, cloneDeep } from "lodash";

import { moveVariantsToProducts } from "./utils";

const initialState = {
  products: [],
  productTypes: [],
  productsById: {},
  productsWithAttributes: [],
  productsBatched: [],
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        products: action.payload.filter((product) => product.isActive),
      };
    },
    setProductTypes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        productTypes: action.payload,
      };
    },
    setProductDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        productsById: {
          ...originalState.productsById,
          [action.payload.id]: action.payload,
        },
      };
    },
    setBatchedProducts: (state, action) => {
      const originalState = original(state);
      const updatedProductSet = moveVariantsToProducts(action.payload);
      const updatedProductsById = keyBy(updatedProductSet, "id");
      return {
        ...originalState,
        productsBatched: [
          ...(originalState.productsBatched || []),
          ...(updatedProductSet || []).filter((product) => product.isActive),
        ],
        productsById: {
          ...(originalState.productsById || {}),
          ...updatedProductsById,
        },
      };
    },
    updateBatchedProducts: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        productsBatched: originalState.productsBatched.map((product) => {
          if (product.id === action.payload.id) {
            return action.payload;
          }
          return product;
        }),
        productsById: {
          ...originalState.productsById,
          [action.payload.id]: action.payload,
        },
      };
    },
    updateMultiBatchedProducts: (state, action) => {
      // action.payload: Array<{ ...productProps }>
      // The products passed to this will be in the original API response state
      const originalState = original(state);
      const productsWithVariantSet = moveVariantsToProducts(action.payload);
      const updatedProductsById = (productsWithVariantSet || []).reduce((acc, product) => {
        acc[product.id] = {
          ...product,
        };
        return acc;
      }, {});
      return {
        ...originalState,
        productsBatched: originalState.productsBatched.map((product) => {
          if (updatedProductsById[product.id]) {
            return updatedProductsById[product.id];
          }
          return product;
        }),
        productsById: {
          ...originalState.productsById,
          ...updatedProductsById,
        },
      };
    },
    updateBatchProductsProp: (state, action) => {
      // action.payload: Object<{ fieldName: string, updatedValues : {[productId]:  Array<{ ...newProps }> } }>
      const originalState = original(state);
      const { fieldName, updatedValues } = action.payload;
      const updatedProductsById = {};

      Object.keys(updatedValues).forEach((productId) => {
        const idsInFields = (originalState.productsById[productId][fieldName] || []).map((item) => item.id);
        const newItems = [];
        const existingItems = [];
        updatedValues[productId].forEach((item) => {
          if (idsInFields.includes(item.id)) {
            existingItems.push(item);
          } else {
            newItems.push(item);
          }
        });

        const existingItemIds = existingItems.map((item) => item.id);
        let finalList = existingItemIds?.length
          ? originalState.productsById[productId][fieldName].map((fieldItem) => {
              if (existingItemIds.includes(fieldItem.id)) {
                return existingItems.find((item) => item.id === fieldItem.id);
              }
              return fieldItem;
            })
          : [...originalState.productsById[productId][fieldName]];
        finalList = newItems?.length ? [...newItems, ...finalList] : finalList;
        if (fieldName === "media") {
          finalList = sortBy(finalList, "sortOrder");
        }
        updatedProductsById[productId] = {
          ...originalState.productsById[productId],
          [fieldName]: finalList,
        };
      });

      return {
        ...originalState,
        productsBatched: originalState.productsBatched.map((product) => {
          if (updatedValues?.[product.id]?.length) {
            return {
              ...product,
              [fieldName]: merge(cloneDeep(product[fieldName]) || [], cloneDeep(updatedValues[product.id])),
            };
          }
          return product;
        }),
        productsById: {
          ...originalState.productsById,
          ...updatedProductsById,
        },
      };
    },
    updateBatchProductsWithNewVariants: (state, action) => {
      const originalState = original(state);
      const { newProduct, deletedIds } = action.payload;
      const productsWithVariantSet = moveVariantsToProducts([newProduct]);
      const updatedProductsById = (productsWithVariantSet || []).reduce((acc, product) => {
        acc[product.id] = {
          ...product,
        };
        return acc;
      }, {});
      let productIndexToSplice = 0;
      const existingVariantIds = [];
      const finalBatchProducts = originalState.productsBatched
        .map((product, index) => {
          if (deletedIds.includes(product.id)) {
            return null;
          }
          if (updatedProductsById[product.id]) {
            if (product.id === newProduct.id) {
              productIndexToSplice = index;
            } else {
              existingVariantIds.push(product.id);
            }
            return updatedProductsById[product.id];
          }
          return product;
        })
        .filter(Boolean);
      if (productIndexToSplice) {
        finalBatchProducts.splice(
          productIndexToSplice,
          0,
          ...productsWithVariantSet.filter((prod) => prod.id !== newProduct.id && !existingVariantIds.includes(prod.id))
        );
      }
      return {
        ...originalState,
        productsBatched: finalBatchProducts,
        productsById: {
          ...originalState.productsById,
          ...updatedProductsById,
        },
      };
    },
    deleteBatchedProducts: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        productsBatched: originalState.productsBatched.filter((product) => !action.payload.includes(product.id)),
      };
    },
  },
});

export const {
  setProducts,
  setProductTypes,
  setProductDetail,
  setBatchedProducts,
  updateBatchedProducts,
  deleteBatchedProducts,
  updateMultiBatchedProducts,
  updateBatchProductsProp,
  updateBatchProductsWithNewVariants,
} = productsSlice.actions;

export default productsSlice.reducer;

export const getProducts = (state) => state.products.products;
export const getProductTypes = (state) => state.products.productTypes;
export const getBatchedProducts = (state) => state.products.productsBatched;
export const selectProductById = (id) => (state) => state.products.productsById[id];
