import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: [],
  contactsById: {},
};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        contacts: action.payload,
      };
    },
    setDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        contactsById: {
          ...originalState.contactsById,
          [action.payload.id]: action.payload,
        },
      };
    },
  },
});

export const { setContacts, setDetail } = contactsSlice.actions;

export default contactsSlice.reducer;

export const getContacts = (state) => state.contacts.contacts;
export const selectById = (id) => (state) => state.contacts.contactsById[id];
