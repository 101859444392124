import { TableCells, TableFilters, TableCards, NavCard } from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Pending: "bg-orange-500",
  Paid: "bg-green-500",
  Draft: "bg-primary-500",
};

export const commonPurchaseOrdersColumns = [
  {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      return (
        <NavCard
          title={original.title}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          show
          status={originalRow.getValue()}
          circleColor={originalRow.getValue() ? STATUS_COLOR_CODES[originalRow.getValue()] || "bg-primary-500" : ""}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "poDate",
    Header: "Issued",
    accessor: "poDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "dueDate",
    Header: "Due",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];

export const commonPurchaseOrderItemsColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "purchaseOrderId",
    Header: "Purchase Order ID",
    accessor: "purchaseOrder.id",
    Cell: TableCells.TextCell,
  },
  {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  {
    id: "subTotal",
    Header: "Subtotal",
    accessor: "subTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];

export const columnsPurchaseOrderItems = [...commonPurchaseOrderItemsColumns];
columnsPurchaseOrderItems.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.purchaseOrder?.project?.places?.addressLine1
      ? `${originalRow.purchaseOrder?.project?.places?.addressLine1} ${
          originalRow.purchaseOrder?.project?.places?.addressLine2 || ""
        }`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original?.purchaseOrder?.project?.places?.city}
        addressState={originalRow.row.original?.purchaseOrder?.project?.places?.state}
        addressZip={originalRow.row.original?.purchaseOrder?.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const PurchaseOrderStatusOptions = ["Draft", "Pending", "Paid", "Processing Payment", "Overdue", "Cancelled"];

export const loadingPlaceholderPurchaseOrder = {
  id: "",
  poDate: "",
  title: "",
  poCode: "",
  type: "",
  total: "",
  status: "",
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
  },
};
