import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { omit } from "lodash";
import useToast from "@src/utils/hooks/useToast";
import finishFormSchema from "./formSchema";
import finishUiSchema from "./uiSchema";
import useAddFinish from "./hooks/useAddFinish";
import useFinishes from "./hooks/useFinishes";

const AddFinish = ({ onClose }) => {
  const { options: finishesAsOptions, isLoading: finishesOptionLoading } = useFinishes();

  const { addFinishAsync, error } = useAddFinish();
  const { addToast } = useToast();

  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    const data = {
      ...omit(formState, ["parent"]),
    };

    if (formState.parent) {
      data.parentId = formState.parent?.value;
    }
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const addFinishResp = await addFinishAsync(data);
    if (addFinishResp?.addFinish?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Finish Successfully Created");
      onClose?.();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    if (finishesOptionLoading) {
      return;
    }
    const finalSchema = {
      ...finishFormSchema,
    };
    const allProperties = finishFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "parent") {
          acc[key].options = finishesAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [finishesOptionLoading]);

  if (finishesOptionLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={finishUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddFinish;
