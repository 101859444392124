import { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { Checkbox, Dropdown, FilterButton, FilterPopover, SearchInput } from "@buildappeal/react-component-library";
import { useClearRefinements } from "react-instantsearch-hooks-web";
import { useRefinementList } from "react-instantsearch-hooks-web";
import { isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";

const AlgoliaFacetFilter = ({
  label,
  searchable,
  attribute,
  operator,
  limit = 1000,
  sortBy,
  escapeFacetValues,
  transformItems,
}) => {
  const [inputValue, setInputValue] = useState("");
  const _transformItems = useCallback(
    (items) =>
      items.map((item) => ({
        ...item,
        label: isAlgoliaAttributeValueEmpty(item.label) ? `No ${label}` : titleCase(item.label),
      })),
    [label]
  );

  const transformItemsFnToUse = useMemo(() => (transformItems ? transformItems : _transformItems), [transformItems]);

  const { items, refine, searchForItems, isFromSearch } = useRefinementList({
    attribute,
    operator,
    limit,
    sortBy,
    escapeFacetValues,
    transformItems: transformItemsFnToUse,
  });

  const { canRefine: canClearFilters, refine: clearFilters } = useClearRefinements({
    includedAttributes: [attribute],
  });

  const setQuery = (newQuery) => {
    setInputValue(newQuery);
    searchForItems(newQuery);
  };

  const onRefine = (item) => () => {
    refine(item.value);
    setQuery("");
  };

  const onChange = (event) => {
    setQuery(event.currentTarget.value);
  };

  const onReset = () => {
    setQuery("");
  };

  const handleClear = useCallback(() => {
    if (canClearFilters) {
      clearFilters();
    }
  }, [canClearFilters, clearFilters]);

  const selectedFilterValues = useMemo(() => items.filter((item) => item.isRefined), [items]);

  const noResults = searchable && isFromSearch && items.length === 0;

  if (!noResults && items.length === 0) {
    return null;
  }

  return (
    <Dropdown
      MenuButton={
        <FilterButton
          option="select"
          label={label}
          additionalText={""}
          active={selectedFilterValues.length > 0}
          textClassName="max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap"
        />
      }
    >
      <FilterPopover onClear={handleClear} filtersApplied={selectedFilterValues.length > 0}>
        <div
          className="space-y-3 pb-6"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          {searchable && (
            <SearchInput
              placeholder={`Search for ${label}...`}
              value={inputValue}
              onChange={onChange}
              onClear={onReset}
            />
          )}

          {noResults ? (
            <div className="mt-4 flex h-full w-full items-center justify-center">
              <span className="text-neutral-700">No Results</span>
            </div>
          ) : (
            <>
              {items.map((item, index) => (
                <div
                  className={clsx(
                    `flex w-full items-center justify-between gap-2`,
                    index === 0 && isAlgoliaAttributeValueEmpty(item.value)
                      ? "mb-4 border-b border-neutral-100 pb-4"
                      : ""
                  )}
                  key={item.value}
                >
                  <Checkbox label={item.label} checked={item.isRefined} onChange={onRefine(item)} isLabelSmall />
                  <span className="text-sm text-neutral-700">{item.count}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </FilterPopover>
    </Dropdown>
  );
};

export default AlgoliaFacetFilter;
