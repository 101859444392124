import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "@buildappeal/react-component-library";

import useToast from "@src/utils/hooks/useToast";
import { getProjectLink } from "@src/utils/common";
import useClients from "../clients/hooks/useClients";
import useAddClient from "../clients/hooks/useAddClient";
import useStaff from "../staff/hooks/useStaff";
import useCreateProject from "./hooks/useCreateProject";
import projectFormSchema from "./formSchema";
import uiSchema from "./uiSchema";

const AddProject = ({ onClose }) => {
  const navigate = useNavigate();
  const [formSchema, setFormSchema] = useState({});
  const { addToast } = useToast();

  const { options: clientsAsOptions, isLoading: clientsLoading } = useClients();
  const { options: staffAsOptions, isLoading: staffOptionLoading } = useStaff();

  const { createProjectAsync } = useCreateProject({ filters: {} });
  const { createClientAsync } = useAddClient({});

  const handleSubmit = async (formState) => {
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));

    let data = null;
    const projectInput = {
      addressLine1: formState.addressLine1,
      addressLine2: formState.addressLine2,
      city: formState.city,
      state: formState.state?.value,
      zipcode: formState.zipCode,
      color: formState.color,
      type: formState.type?.value,
      accessDifficulty: formState.accessDifficulty?.value,
      projectPlaceStories: formState.floors?.value,
      staff: formState?.staff ? [formState.staff.email] : null,
    };

    if (formState.contact) {
      const input = {
        ...projectInput,
        clients: [formState.linkedContact?.client?.email],
      };
      data = await createProjectAsync(input);
    } else {
      const { firstName, lastName, email, phone } = formState;
      const clientInput = {
        firstName,
        lastName,
        email,
        phone,
        type: "Client",
      };
      const newClient = await createClientAsync(clientInput);

      if (newClient?.createClient?.success) {
        data = await createProjectAsync({
          ...projectInput,
          clients: [newClient.createClient.data.email],
        });
      }
    }

    if (data?.createProject?.success) {
      navigate(`${getProjectLink(data.createProject.data)}`);
      addToast("Project successfully created.");
      onClose();
    }
  };

  useEffect(() => {
    const finalSchema = {
      ...projectFormSchema,
    };
    const allProperties = projectFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "contact") {
          acc[key].formCondition.true.linkedContact.options = clientsAsOptions;
          acc[key].formCondition.true.linkedContact.isLoading = clientsLoading;
        }
        if (key === "staff") {
          acc[key].options = staffAsOptions;
          acc[key].isLoading = staffOptionLoading;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [clientsLoading, clientsAsOptions, staffAsOptions, staffOptionLoading]);

  return <Form formSchema={formSchema} uiSchema={uiSchema} isInSidebar isCreate />;
};
export default AddProject;
