import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { ViewControl, CentralColUI, Modal, Sidebar, Button } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import clsx from "clsx";

import loadingDataGenerator from "@src/utils/loadingDataGenerator";
import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";
import useUser from "@features/auth/hooks/useUser";
import AlgoliaWrapper from "@src/utils/components/AlgoliaWrapper";
import useAlgoliaInfiniteHits from "@src/utils/hooks/useAlgoliaInfiniteHits";
import AlgoliaInifiteHits from "@src/utils/components/AlgoliaComponents/InfiniteHits";
import AlgoliaAutoComplete from "@src/utils/components/AlgoliaComponents/AutoCompleteSearchBox";
import usePageData from "./hooks/usePageData";
import useUiPages from "./hooks/useUIPages";
import useStagesData from "./hooks/useStagesData";
import columnsMap from "./allTableColumns";
import allLoadingPlaceholder from "./allLoadingPlaceholder";
import actionsMap from "./allTableActions";
import leftUisMap from "./allLeftUIs";
import externalFiltersMap from "./allExternalFilters";
import { getTableColumnsForPage, tableColumnVisibilityProps, variantIdRegex, initExternalFilters } from "./utils";
import { VIEW_TYPES, QUERY_PARAMS, QUERY_PARAMS_FILTERS } from "./constants";
import CentralUIGenerator from "./CentralUIGenerator";
import LeftColUI from "./components/LeftColUI";
import { useQuickViewUpdater } from "./contexts/QuickViewContext";
import QuickView from "./QuickView";
import TableMultiSelectActions from "./TableMultiSelectActions";
import boardViewDataUtilsMap from "./allBoardViewDataUtils";
import dataQueryIdAccessorMap from "./allDataQueryIdAccessors";
import dataQueryIdFiltersMap from "./allDataQueryIdFilters";
import allPagesAlgoliaConfigMap from "./allPagesAlgoliaConfig";
import viewButtonGroupMap from "./allViewButtonGroup";

/**
 * Master layout manages the entire page display
 * STEPS TO ADD FEATURE TO MASTER LAYOUT
 *  1. Files in features folder
 *      1.1 tableColumnsMap.js
 *      1.2 tableActionsMap.js
 *      1.3  view folder with all TAB components
 *          1.3.1 index.jsx (pick from products feature)
 *          1.3.2 A component for each available tab (default should have OVERVIEW file with editable details)
 *      1.4  hooks folder - should hold all CRUD hooks for feature
 *      1.5  AddFeatureName.jsx (should use Form component) (all CRUD files should have action name before feature name)
 *  2. Changes in  allTableActions.js and allTableColumns.js
 *      2.1 Import tableColumnsMap.js to allTableColumns.js
 *      2.2 Import tableActionsMap.js to allTableActions.js
 *      2.3 Add get hook to ./hooks/usePageData
 *  REFER PRODUCTS FEATURE FOR EXAMPLE AND MORE DETAILS
 * QUICK VIEW FEATURE
 * If you want to add quick view to your feature, you need to add the following:
 * 1. Import useQuickViewUpdater from "./contexts/QuickViewContext";
 * 2. Call useQuickViewUpdater in your component
 *    Example:
 *    const quickViewOpen = useQuickViewUpdater();
 * 3. Call function with these parameters:
 *    3.1 isOpen: boolean
 *    3.2 path: string
 *    3.3 selectedItem: { id: number }
 *    Example:
 *    quickViewOpen({
        isOpen: true,
        path: "/partners",
        selectedItem: {
          id: partner.id,
        },
      }),
 * ALGOLIA AS DATA SOURCE
 * If you want to migrate a page to use Algolia: you need to add the following:
 * 1. Add the index config to allPagesAlgoliaConfig.js file.
 * 2. Create Filters Component using ALgoliaComponents in utils/components. Refer to ProjectFilters.js for example.
 * 3. Add the mapping of filter component to allExternalFIlters.js
 * 4. Delete filters value from ui_table in table
 * 5. remove the usage for the same from usePageData.js hook and update the tableColumns map and loadingPlaceholderMap to match algolia index data schema.
 * 6. Add transformCurrentRefinementItems function to transform how the selected filter to be displayed refer transformProjectCurrentRefinementItems function in projects/utils.js
 * 7. To Add ViewButtonGroup refer to FilesFilters and ProductViewButtonGroup.jsx to create new one and add to allViewButtonGroup.js
 */

const MasterLayout = ({ data, pageDataLoading, totalCount, isBatchedFetchLoading, setFilters, filters }) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: uiPages, isLoading } = useUiPages();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [leftColumnProps, setLeftColumnProps] = useState({
    fields: [],
    data: [],
  });
  const { data: boardStages = [] } = useStagesData(currentPath);

  const isAlgoliaDataSource = useMemo(() => !!allPagesAlgoliaConfigMap[currentPath], [currentPath]);

  const [centralUIProps, setCentralUIProps] = useState({
    tabs: [],
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [highlightItem, setHighlightItem] = useState(null);
  const [selectedMultipleItems, setSelectedMultipleItems] = useState([]);
  const [tableActions, setTableActions] = useState(null);
  const [selectedActionRow, selectActionRow] = useState(null);
  const [isConsultationMode, setIsConsultationMode] = useState(false);
  const [addUI, setAddUI] = useState({
    isOpen: false,
    component: null,
  });
  const [showCustomLeftUI, setShowCustomLeftUI] = useState(
    searchParams.get(QUERY_PARAMS.Space) ||
      searchParams.get(QUERY_PARAMS.Section) ||
      searchParams.get(QUERY_PARAMS.ShowCustomLeft) === "true"
      ? true
      : false
  );
  const [isExpanded, expand] = useState(() => {
    return searchParams.get(QUERY_PARAMS.FullView) === "true";
  });
  const [isLeftColCollapsed, setIsLeftColCollapsed] = useState(() => {
    return searchParams.get(QUERY_PARAMS.FullView) === "true";
  });
  const [activeView, setActiveView] = useState(VIEW_TYPES.Table);
  const onQuickViewOpen = useQuickViewUpdater();
  const { setActionsState } = useGeneralActionsUpdater();
  const { user } = useUser();

  const pageUIProps = useMemo(() => uiPages?.find((page) => page.path === currentPath), [currentPath, uiPages]);
  const dataQueryIdAccessorFn = dataQueryIdAccessorMap[currentPath] ?? dataQueryIdAccessorMap.default;
  const dataQueryIdFilterFn = dataQueryIdFiltersMap[currentPath] ?? dataQueryIdFiltersMap.default;
  const selectedAction = tableActions?.[selectedActionRow?.actionId];
  const externalFilters = useMemo(() => {
    const externalFiltersByPath = externalFiltersMap[currentPath];
    return externalFiltersByPath;
  }, [currentPath]);
  const viewButtonGroup = useMemo(() => viewButtonGroupMap[currentPath], [currentPath]);

  const customLeftUILayout = leftUisMap[currentPath]
    ? React.createElement(leftUisMap[currentPath], {
        onBack: () => setShowCustomLeftUI(false),
        row: selectedItem,
        isConsultationMode,
      })
    : null;

  const onLeftColumnRowClicked = (row) => {
    if (activeView === VIEW_TYPES.Map && !selectedItem) {
      setActiveItem(row);
      return;
    }
    const rowId = dataQueryIdAccessorFn(row);
    setSelectedItem(row);
    setIsLeftColCollapsed(true);
    if (searchParams.get(QUERY_PARAMS.Id) !== rowId) {
      searchParams.set(QUERY_PARAMS.Id, rowId);
      setSearchParams(searchParams);
    }
  };

  const handleSelectMapItem = (selectedItem) => {
    const params = new URLSearchParams();
    params.set("id", selectedItem?.id);
    if (selectedItem && searchParams.get(QUERY_PARAMS.View)) {
      params.set(QUERY_PARAMS.View, searchParams.get(QUERY_PARAMS.View));
    }
    setSearchParams(params);
    setSelectedItem(selectedItem);
  };

  const onBackClicked = () => {
    const params = new URLSearchParams();
    const viewQueryParam = searchParams.get(QUERY_PARAMS.View);

    setShowCustomLeftUI(false);
    expand(false);
    if (viewQueryParam === VIEW_TYPES.Map && selectedItem) {
      setActiveView(VIEW_TYPES.Map);
      params.delete(QUERY_PARAMS.Id);
    } else {
      setIsLeftColCollapsed(false);
    }
    if (viewQueryParam && selectedItem) {
      params.set(QUERY_PARAMS.View, viewQueryParam);
    }
    setSearchParams(params);
    setSelectedItem(null);
    setActiveItem(null);
  };

  const onArrowClick = (row) => {
    const params = new URLSearchParams();
    const rowId = dataQueryIdAccessorFn(row);
    params.set(QUERY_PARAMS.Id, rowId);
    params.set(QUERY_PARAMS.ShowCustomLeft, true);
    setSearchParams(params);
    setSelectedItem(row);
    setShowCustomLeftUI(true);
  };

  const onOpenClick = useCallback(
    (actionId, row) => {
      selectActionRow({
        actionId,
        row,
      });
    },
    [selectedActionRow]
  );

  const onCloseContainer = useCallback(() => {
    setAddUI({
      isOpen: false,
      component: null,
    });
  }, [setAddUI]);

  const onProductBulkImageUploadClick = () => {
    setActionsState({
      isOpen: true,
      currentAction: "productBulkImageUpload",
    });
  };

  const handleChangeLayout = (selectedLayout) => {
    const params = new URLSearchParams();
    if (selectedLayout !== VIEW_TYPES.Table) {
      params.set(QUERY_PARAMS.View, selectedLayout);
    } else {
      params.delete(QUERY_PARAMS.View);
    }
    setSearchParams(params);
    setActiveView(selectedLayout);
  };

  const handleClearFilters = () => {
    const searchParamsEntries = searchParams.entries();

    for (const [key] of searchParamsEntries) {
      if (key.includes(QUERY_PARAMS_FILTERS.ExternalFilter)) {
        searchParams.delete(key);
      }
    }

    setSearchParams(searchParams);
    setFilters({});
  };

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setCurrentPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    const viewParam = searchParams.get(QUERY_PARAMS.View);
    if (searchParams.get(QUERY_PARAMS.ShowCustomLeft) === "true") {
      setShowCustomLeftUI(true);
    }
    if (searchParams.get(QUERY_PARAMS.Id)) {
      let qpId = searchParams.get(QUERY_PARAMS.Id);
      if (!variantIdRegex.test(qpId)) {
        qpId = parseInt(searchParams.get(QUERY_PARAMS.Id), 10);
        qpId = Number.isNaN(qpId) ? searchParams.get(QUERY_PARAMS.Id) : qpId;
      }

      const selectedItemId = selectedItem ? dataQueryIdAccessorFn(selectedItem) : null;
      if (!selectedItemId || selectedItemId !== qpId) {
        const dataItem = data?.find((item) => dataQueryIdFilterFn(qpId, item));
        if (dataItem) {
          setSelectedItem({
            id: qpId,
            ...dataItem,
          });
          setIsLeftColCollapsed(true);
        }
      }
    }
    if (selectedItem?.id && !searchParams.get(QUERY_PARAMS.Id)) {
      setShowCustomLeftUI(false);
      setSelectedItem(null);
      setIsLeftColCollapsed(false);
      expand(false);
    }
    if (viewParam && viewParam !== activeView) {
      setActiveView(viewParam);
    }
    if (!viewParam && activeView !== VIEW_TYPES.Table) {
      setActiveView(VIEW_TYPES.Table);
    }
    if (viewParam === VIEW_TYPES.Map) {
      setIsLeftColCollapsed(true);
    }
  }, [searchParams, data]);

  useEffect(() => {
    if (isExpanded) {
      searchParams.set(QUERY_PARAMS.FullView, "true");
    } else {
      searchParams.delete(QUERY_PARAMS.FullView);
    }
    setSearchParams(searchParams);
  }, [isExpanded]);

  useEffect(() => {
    if (isLoading || !currentPath || !uiPages?.length) {
      return;
    }
    if (isEmpty(pageUIProps)) {
      return;
    }
    const leftColumnProps = {
      hasRightClick: !!pageUIProps.uiLeftColumn?.hasRightClick,
      hasCreate: !!pageUIProps.uiLeftColumn?.hasCreate,
      rowMenuActions: pageUIProps.uiLeftColumn?.rowMenuActions || [],
    };

    if (pageUIProps.uiLeftColumn) {
      const tableColumns = columnsMap[currentPath];
      const tableActions = actionsMap[currentPath];

      const fakeData = allLoadingPlaceholder[currentPath];
      const {
        uiLeftColumn: { uiTable },
      } = pageUIProps;
      leftColumnProps.fields = uiTable?.fields?.length
        ? getTableColumnsForPage(
            uiTable.fields,
            uiTable.filters,
            uiTable.rowMenuActions,
            tableColumns,
            tableActions,
            onOpenClick,
            onArrowClick,
            onQuickViewOpen
          )
        : [];
      leftColumnProps.cardFields = uiTable?.fields?.length
        ? getTableColumnsForPage(
            uiTable.cardFields,
            uiTable.filters,
            uiTable.cardActions,
            tableColumns,
            tableActions,
            onOpenClick,
            onArrowClick
          )
        : [];
      leftColumnProps.data = !pageDataLoading
        ? data
        : loadingDataGenerator(
            leftColumnProps.fields?.map((field) => (typeof field?.accessor === "string" ? field?.accessor : field?.id)),
            fakeData
          );
      leftColumnProps.onRowHover = setHighlightItem;
      leftColumnProps.boardViewDataTransform = boardViewDataUtilsMap[currentPath];
      leftColumnProps.hideFilters = activeView === VIEW_TYPES.Board && !isAlgoliaDataSource;
      leftColumnProps.layout = uiTable?.layout;
      leftColumnProps.onMultiSelect = setSelectedMultipleItems;
      leftColumnProps.allowMultiSelect = !!(uiTable?.fields && uiTable.fields.includes("select"));
      leftColumnProps.dataQueryIdAccessorFn = dataQueryIdAccessorFn;
      leftColumnProps.dataQueryIdFilterFn = dataQueryIdFilterFn;
      leftColumnProps.showAllStages = uiTable.showAllStages;

      if (uiTable?.layout?.length > 0 && !activeView) {
        setActiveView(uiTable.layout.includes(VIEW_TYPES.Table) ? VIEW_TYPES.Table : uiTable.layout[0]);
      }

      if (!!pageUIProps.uiLeftColumn?.hasCreate) {
        const createActions = Object.values(actionsMap[currentPath]?.add?.items || {});
        leftColumnProps.onCreate =
          createActions?.length === 0
            ? () =>
                setAddUI({
                  isOpen: true,
                  component: actionsMap[currentPath]?.add?.sidebar || actionsMap[currentPath]?.add?.component,
                  container: actionsMap[currentPath]?.add?.container || "sidebar",
                })
            : null;
        leftColumnProps.createActions = createActions?.map((item) => ({
          label: item.label,
          onClick: () => {
            if (item.sidebar) {
              setAddUI({ isOpen: true, component: item.sidebar, container: "sidebar" });
            } else if (item.inline) {
              setAddUI({ isOpen: true, component: item.inline, container: "inline" });
            }
          },
        }));
      }

      if (totalCount && currentPath === "/products") {
        leftColumnProps.totalCount = totalCount;
        leftColumnProps.overrideTotalCount = true;
      }

      if (isAlgoliaDataSource) {
        leftColumnProps.manualPagination = true;
        leftColumnProps.totalCount = totalCount;
        leftColumnProps.hidePagination = true;
        leftColumnProps.overrideTotalCount = true;
        leftColumnProps.pageProps = {
          pageSize: totalCount,
        };
        leftColumnProps.search = getSearchComponent({
          placeholder: `Search ${pageUIProps?.title}`,
          id: pageUIProps?.title,
        });
      }

      setTableActions(tableActions);
    }

    if (pageUIProps.uiCentral) {
      const centralProps = {
        tabs: pageUIProps.uiCentral?.tabs || [],
        hasDelete: !!pageUIProps.uiCentral?.hasDelete,
        data: data,
      };

      if (pageUIProps.uiCentral?.productsTable) {
        const productTableColumns = columnsMap["/products"];
        const productTableActions = actionsMap["/products"];
        const productsTable = pageUIProps.uiCentral?.productsTable;
        centralProps.productFields = productsTable?.fields?.length
          ? getTableColumnsForPage(
              productsTable.fields,
              productsTable.filters,
              productsTable.rowMenuActions,
              productTableColumns,
              productTableActions
            )
          : [];
      }

      setCentralUIProps(centralProps);
    }

    setLeftColumnProps(leftColumnProps);
  }, [currentPath, uiPages, data, pageUIProps, totalCount, activeView, pageDataLoading]);

  const viewControls = (
    <div>
      {leftColumnProps?.layout?.length > 0 && (
        <div className="mr-1 flex space-x-1">
          {leftColumnProps?.layout.map((viewItem) => (
            <ViewControl
              key={viewItem}
              type={viewItem}
              isActive={activeView === viewItem}
              onClick={() => handleChangeLayout(viewItem)}
            />
          ))}
        </div>
      )}
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center" data-testid="loading">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  return (
    <div className="relative flex w-full flex-row">
      {/* Temporarily added till feature is finalised */}
      {currentPath === "/products" &&
      ["alex@buildappeal.com", "kavita@buildappeal.com", "nelson@buildappeal.com"].includes(user?.email) &&
      !isLeftColCollapsed ? (
        <Button
          onClick={onProductBulkImageUploadClick}
          appearance="secondary"
          label="Bulk Images"
          className="absolute top-8 right-52"
          size="sm"
        />
      ) : null}

      <div
        className={clsx(
          isLeftColCollapsed
            ? "absolute left-0 z-[2] h-[calc(100vh-54px)] w-[375px] overflow-y-auto border-r dark:border-r-midnightborder"
            : "w-full",
          "bg-white p-8 transition-width duration-300 ease-in-out dark:bg-midnight",
          isExpanded && "hidden"
        )}
      >
        {isAlgoliaDataSource ? (
          <AlgoliaInifiteHits cacheId={allPagesAlgoliaConfigMap[currentPath].indexName}>
            <LeftColUI
              isLoading={isLoading || pageDataLoading}
              isCollapsed={isLeftColCollapsed}
              title={pageUIProps?.title}
              selectedItem={selectedItem}
              selectedMultipleItems={selectedMultipleItems}
              isCustom={showCustomLeftUI}
              customLayout={customLeftUILayout}
              tableColumnVisibility={tableColumnVisibilityProps(currentPath)}
              className={showCustomLeftUI ? "!p-0" : ""}
              createComponent={
                addUI.isOpen && addUI.container === "inline" && addUI.component
                  ? React.createElement(addUI.component, {
                      onClose: onCloseContainer,
                    })
                  : null
              }
              isHide={isExpanded}
              showLabelSpinner={currentPath === "/products" && isBatchedFetchLoading}
              overrideTotalCount={currentPath === "/products" && !!totalCount}
              viewControls={viewControls}
              activeView={activeView}
              onRowClick={onLeftColumnRowClicked}
              hasExternalFiltersApplied={Object.values(filters).length > 0}
              onClearExternalFilters={handleClearFilters}
              externalFilters={externalFilters}
              viewButtonGroup={viewButtonGroup}
              boardStages={boardStages}
              {...leftColumnProps}
            />
          </AlgoliaInifiteHits>
        ) : (
          <LeftColUI
            isLoading={isLoading || pageDataLoading}
            isCollapsed={isLeftColCollapsed}
            title={pageUIProps?.title}
            selectedItem={selectedItem}
            selectedMultipleItems={selectedMultipleItems}
            isCustom={showCustomLeftUI}
            customLayout={customLeftUILayout}
            tableColumnVisibility={tableColumnVisibilityProps(currentPath)}
            className={showCustomLeftUI ? "!p-0" : ""}
            createComponent={
              addUI.isOpen && addUI.container === "inline" && addUI.component
                ? React.createElement(addUI.component, {
                    onClose: onCloseContainer,
                  })
                : null
            }
            isHide={isExpanded}
            showLabelSpinner={currentPath === "/emails" && isBatchedFetchLoading}
            overrideTotalCount={currentPath === "/emails" && !!totalCount}
            viewControls={viewControls}
            activeView={activeView}
            onRowClick={onLeftColumnRowClicked}
            hasExternalFiltersApplied={Object.values(filters).length > 0}
            onClearExternalFilters={handleClearFilters}
            externalFilters={externalFilters}
            boardStages={boardStages}
            {...leftColumnProps}
          />
        )}
      </div>

      <CentralColUI isVisible={isLeftColCollapsed} isExpanded={isExpanded}>
        <CentralUIGenerator
          currentPath={currentPath}
          tabs={centralUIProps?.tabs || []}
          productFields={centralUIProps?.productFields || []}
          onBackClicked={onBackClicked}
          selectedItem={selectedItem}
          activeItem={activeItem}
          highlightItem={highlightItem}
          isExpanded={isExpanded}
          onExpand={expand}
          hasDelete={centralUIProps?.hasDelete}
          data={centralUIProps?.data}
          activeView={activeView}
          onMapItemClick={handleSelectMapItem}
          onMapMarkerClick={onLeftColumnRowClicked}
          onConsultationModeEnabled={(showConsultation) => setIsConsultationMode(showConsultation)}
          activeSearchParams={true}
        />
      </CentralColUI>

      <QuickView uiPages={uiPages} />

      {selectedAction?.modal && (
        <Modal
          title={selectedAction?.label}
          onClose={() => selectActionRow(null)}
          isOpen={!!selectedActionRow?.actionId}
        >
          {!!selectedActionRow
            ? React.createElement(tableActions[selectedActionRow?.actionId]?.modal, {
                selectedRow: selectedActionRow?.row,
                onClose: () => selectActionRow(null),
              })
            : null}
        </Modal>
      )}
      {selectedAction?.container === "collapsedExpand" &&
        selectedAction?.component &&
        React.createElement(selectedAction.component, {
          isOpen: !!selectedActionRow?.actionId,
          onClose: () => selectActionRow(null),
          selectedRow: selectedActionRow?.row,
        })}
      {addUI?.container === "sidebar" && (
        <Sidebar isOpen={addUI.isOpen} onClose={onCloseContainer} closeOnOutsideClick={false}>
          {addUI.component ? (
            React.createElement(addUI.component, {
              onClose: onCloseContainer,
            })
          ) : (
            <div />
          )}
        </Sidebar>
      )}
      {addUI?.container === "collapsedExpand" &&
        addUI?.component &&
        React.createElement(addUI.component, {
          isOpen: addUI.isOpen,
          onClose: onCloseContainer,
        })}
      {!!selectedMultipleItems.length && (
        <TableMultiSelectActions
          items={selectedMultipleItems}
          onClose={() => setSelectedMultipleItems([])}
          currentPath={currentPath}
        />
      )}
    </div>
  );
};

const getSearchComponent = ({ placeholder, id }) => {
  return <AlgoliaAutoComplete id={id} className="flex-1" placeholder={placeholder} />;
};

const MasterLayoutAlgoliaDataSource = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [filters, setFilters] = useState(() => initExternalFilters(searchParams));
  const pageConfig = allPagesAlgoliaConfigMap[location.pathname];
  const { hits, isLoading, totalCount: totalHitsCount } = useAlgoliaInfiniteHits(pageConfig.indexName);
  return (
    <MasterLayout
      data={hits}
      pageDataLoading={isLoading}
      totalCount={totalHitsCount}
      filters={filters}
      setFilters={setFilters}
    />
  );
};

const MasterLayoutBackedDataSource = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState(() => initExternalFilters(searchParams));
  const { data, isLoading, isBatchedFetchLoading, totalRecordCount } = usePageData(location.pathname, filters);

  return (
    <MasterLayout
      data={data}
      pageDataLoading={isLoading}
      totalCount={totalRecordCount}
      isBatchedFetchLoading={isBatchedFetchLoading}
      filters={filters}
      setFilters={setFilters}
    />
  );
};
const AlgoliaProviderComponent = () => {
  const location = useLocation();
  const pageConfig = allPagesAlgoliaConfigMap[location.pathname];

  let AlgoliaComp = useMemo(
    () => (pageConfig ? AlgoliaWrapper(MasterLayoutAlgoliaDataSource, pageConfig) : null),
    [pageConfig]
  );

  if (AlgoliaComp) {
    return <AlgoliaComp />;
  }

  return <MasterLayoutBackedDataSource />;
};

const MasterLayoutWithProvider = () => <AlgoliaProviderComponent />;

export default MasterLayoutWithProvider;
