import { TableCells } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Signed: "green",
  Draft: "gray",
  pending: "orange",
  active: "green",
};

export const columns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "name",
    Header: "Name",
    accessor: (originalRow) => `${originalRow.fullName}, ${originalRow.email}`,
    emailAccessor: "email",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          getValue={() => originalRow.getValue().split(",")[0] ?? ""}
          avatarSrc={originalRow.row.original.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
          initials={generateInitials(originalRow.getValue() || originalRow.row.original.email)}
        />
      );
    },
  },
  {
    id: "title",
    Header: "Title",
    accessor: "title",
    Cell: TableCells.TextCell,
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
    Cell: TableCells.TextCell,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
  },
];

export const loadingPlaceholderStaff = {
  id: "",
  fullName: "",
  firstName: "",
  lastName: "",
  email: "",
  avatar: null,
  title: "",
  phone: "",
  status: "",
  uuid: "",
};
