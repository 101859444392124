import React, { useEffect, useState } from "react";

import { CONSULTATION_ACTION_MODES } from "@features/consultations/utils";
import useConsultations from "@features/consultations/hooks/useConsultations";
import useUpdateConsultation from "@features/consultations/hooks/useUpdateConsultation";
import useAddActivity from "@features/activities/hooks/useAddActivity";
import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";
import useUser from "@features/auth/hooks/useUser";

const GeneralConsultationEventTracker = ({ mode, consultation, projectId, currentAction }) => {
  const [projectIdToFetch, setProjectIdToFetch] = useState(null);
  const [consultationStateUpdate, setConsultationStateUpdate] = useState(null);
  const { setActionsState } = useGeneralActionsUpdater();
  const {
    data: consultationsData,
    refetch,
    isLoading,
    isFetching,
  } = useConsultations(
    {
      projectIds: projectIdToFetch ? [projectIdToFetch] : undefined,
    },
    {
      diableQuery: !projectIdToFetch,
    }
  );
  const { updateConsultation } = useUpdateConsultation();
  const { addNewActivity } = useAddActivity();
  const { user } = useUser();

  useEffect(() => {
    if (
      !projectId ||
      (!!projectIdToFetch && projectId === projectIdToFetch) ||
      ![CONSULTATION_ACTION_MODES.CANCEL, CONSULTATION_ACTION_MODES.RESCHEDULE].includes(mode) ||
      !consultation?.id ||
      currentAction !== "consultationEventTrack"
    ) {
      return;
    }
    setProjectIdToFetch(projectId);
    setConsultationStateUpdate({ mode, consultation });
  }, [projectId, mode, consultation]);

  useEffect(() => {
    if (!projectIdToFetch || consultationsData?.[0]?.project?.id !== projectIdToFetch || !consultation?.id) {
      return;
    }

    setTimeout(() => {
      refetch();
    }, 1500);
  }, [projectIdToFetch]);

  useEffect(() => {
    if (!consultationStateUpdate?.mode || !consultationsData?.length || isLoading || isFetching) {
      return;
    }
    const { mode, consultation: originalConsultation } = consultationStateUpdate;
    const updatedConsulation = consultationsData.find((c) => c.id === originalConsultation.id);
    if (!updatedConsulation) {
      return;
    }
    let activityInput = {};
    if (mode === CONSULTATION_ACTION_MODES.CANCEL && updatedConsulation.status === "Cancelled") {
      activityInput = {
        activityDate: new Date(),
        activityType: "eventCancelled",
        actor: user.uuid,
        projectId: projectIdToFetch,
        eventPayload: {
          consultationId: updatedConsulation.id,
          staffName: `${user.firstName} ${user.lastName}`,
          eventName: updatedConsulation.calendlyEventName,
        },
      };
    } else if (
      mode === CONSULTATION_ACTION_MODES.RESCHEDULE &&
      updatedConsulation.calendlyEventUri !== originalConsultation.calendlyEventUri
    ) {
      activityInput = {
        activityDate: new Date(),
        activityType: "eventRescheduled",
        actor: user.uuid,
        projectId: projectIdToFetch,
        eventPayload: {
          consultationId: updatedConsulation.id,
          staffName: `${user.firstName} ${user.lastName}`,
          eventName: updatedConsulation.calendlyEventName,
          eventDate: updatedConsulation.consultationDate,
        },
      };
    }
    if (activityInput.activityType) {
      addNewActivity(activityInput);
      updateConsultation({
        id: updatedConsulation.id,
        updatedBy: user.uuid,
        projectId: projectIdToFetch,
      });
    }
    setConsultationStateUpdate(null);
    setProjectIdToFetch(null);
    setActionsState((prev) => ({
      ...prev,
      currentAction: "",
      triggeredBy: "",
      actionProps: {},
    }));
  }, [consultationStateUpdate, consultationsData, isLoading, isFetching]);

  return <div />;
};

export default GeneralConsultationEventTracker;
