import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createPurchaseOrder } from "../../api";

const useAddPurchaseOrder = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addPurchaseOrderMutation = useMutation(
    (input) => {
      return createPurchaseOrder(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createPurchaseOrder.success) {
          setError(`Error: ${data.createPurchaseOrder?.message}`);
        } else {
          queryClient.setQueryData(["purchaseOrders", filters], (old) => {
            return { getPurchaseOrders: [data.createPurchaseOrder.data, ...old?.getPurchaseOrders] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addPurchaseOrder: addPurchaseOrderMutation.mutate,
    addPurchaseOrderAsync: addPurchaseOrderMutation.mutateAsync,
    addPurchaseOrderLoading: addPurchaseOrderMutation.isLoading,
    addPurchaseOrderSuccess: addPurchaseOrderMutation.isSuccess,
    error,
  };
};

export default useAddPurchaseOrder;
