import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  TextField,
  Textarea,
  Toggle,
  Select,
  Section,
  FileUpload,
  CloudinaryImage,
} from "@buildappeal/react-component-library";
import { v4 as uuidv4 } from "uuid";

import useSuppliers from "@features/suppliers/hooks/useSuppliers";
import { FILE_CONTENT_TYPES_LABEL, FILE_CONTENT_TYPES, FILE_PATHS, FILE_TYPES } from "@src/features/files/utils";
import useMultiFilesUpload from "../files/hooks/useMultiFilesUpload";
import useDesignStyles from "../designs/hooks/useDesignStyles";
import { updateProduct } from "./api";
import useProductTypes from "./hooks/useProductTypes";
import { PRODUCT_PER_UNITS } from "./utils";

const EditProduct = ({ selectedRow: product }) => {
  const { productTypeOptions } = useProductTypes();
  const { options: designStylesAsOptions, designStylesLoading } = useDesignStyles();
  const { suppliersAsOptions, isLoading: suppliersLoading } = useSuppliers();
  const { startUpload } = useMultiFilesUpload();
  const queryClient = useQueryClient();

  const [files, setFiles] = useState(null);
  const [uploadResults, setUploadResults] = useState(null);
  const [formState, setFormState] = useState({
    ...product,
    productObject: product?.type,
    designStyles: (product?.designStyles || []).map((style) => style.id),
  });

  useEffect(() => {
    setFormState({
      ...product,
      productObject: product?.type,
    });
  }, [product]);

  const uploadFiles = async () => {
    const finalFilesList = (files || []).map((file) => {
      const fileKey = `${FILE_PATHS[FILE_CONTENT_TYPES.product]}/${product?.id}/${uuidv4()}`;
      return {
        key: fileKey,
        contentType: FILE_CONTENT_TYPES_LABEL[FILE_CONTENT_TYPES.product],
        type: FILE_TYPES.media,
        file,
        productId: product?.id,
      };
    });
    const results = await startUpload(finalFilesList);
    setUploadResults(results);
    if (results?.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  const updateProductFiles = async () => {
    let media = [];
    if (files?.length) {
      const finalFilesList = await uploadFiles(product?.id);
      if (finalFilesList?.length) {
        media = finalFilesList.map((fileInfo) => ({
          path: fileInfo.key,
          fileType: fileInfo.contentType,
        }));
      }
    }
    // TODO: Handle success notification display
    queryClient.setQueryData(["products", {}], (old) => {
      return {
        getProducts: old.getProducts.map((item) => {
          if (item.id === product?.id) {
            return { ...item, media: [...item.media, ...media] };
          }
          return item;
        }),
      };
    });
  };

  const updateProductMutation = useMutation(
    (formInput) => {
      return updateProduct(formInput);
    },
    {
      onSuccess: (data) => {
        if (!data?.updateProduct?.success) {
          return;
        }
        // TODO: Handle success notification display
        queryClient.setQueryData(["products", {}], (old) => {
          return {
            getProducts: old.getProducts.map((product) => {
              if (product?.id === data.updateProduct.data.id) {
                return data.updateProduct.data;
              }
              return product;
            }),
          };
        });
      },
    }
  );

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const isFormValid = () => {
    if (!formState?.name?.length) {
      return false;
    }
    if (!formState?.sku?.length) {
      return false;
    }
    return true;
  };

  const onSubmit = (formField) => {
    // TODO: Add futher validation
    if (!isFormValid()) {
      return;
    }
    const finalFormField = {
      id: formState.id,
      [formField]: formState[formField],
    };
    if (formField === "suppliers") {
      finalFormField.suppliers = formState.suppliers.map((supplier) => supplier.value);
    }
    if (formField === "productObject") {
      finalFormField.productObjectsId = formState[formField].id;
      delete finalFormField.productObject;
    }
    updateProductMutation.mutate(finalFormField);
  };

  const onCancel = (formField) => {
    setFormValue({
      [formField]: product[formField],
    });
  };

  return (
    <div className="p-10">
      <p className="mt-0 text-xl font-medium">Details</p>
      <Section
        value={formState.name}
        label="Product Name"
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("name") : onCancel("name");
        }}
        showAll
      >
        <TextField
          label="Product Name"
          value={formState.name}
          onChange={({ target: { value } }) => setFormValue({ name: value })}
          className="mt-2"
          required
        />
      </Section>
      <Section
        value={formState.sku}
        label="SKU"
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("sku") : onCancel("sku");
        }}
        showAll
      >
        <TextField
          label="SKU"
          value={formState.sku}
          onChange={({ target: { value } }) => setFormValue({ sku: value })}
          className="mt-2"
          required
        />
      </Section>
      <Section
        value={formState.productObject?.description || ""}
        label="Product Type"
        onAction={(actionType) => {
          actionType === "save"
            ? onSubmit("productObject")
            : setFormValue({
                productObject: product?.type,
              });
        }}
        showAll
      >
        <Select
          className="z-40 mt-4"
          label="Product Types"
          value={{
            title: formState.productObject?.description || "",
            value: formState.productObject?.description || "",
          }}
          options={productTypeOptions}
          onChange={(opt) => {
            setFormValue({ productObject: opt?.productType });
          }}
        />
      </Section>
      <Section
        value={formState.suppliers?.[0]?.name || ""}
        label="Suppliers"
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("suppliers") : onCancel("suppliers");
        }}
        showAll
      >
        <Select
          label="Suppliers"
          isLoading={suppliersLoading}
          value={formState.suppliers}
          options={suppliersAsOptions}
          onChange={(value) => {
            setFormValue({ suppliers: value });
          }}
          className="mt-4"
          multiple
        />
      </Section>
      <Section
        value={formState.designStyles?.map((style) => style.name)?.join(", ") || ""}
        label="Design Style"
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("designStyles") : onCancel("designStyles");
        }}
        showAll
      >
        <Select
          label="Design Style"
          isLoading={designStylesLoading}
          value={(formState?.designStyles || []).map((style) => style.id)}
          options={designStylesAsOptions}
          onChange={(values) => {
            setFormValue({ designStyles: values?.map((val) => val.value) });
          }}
          className="mt-4"
          multiple
        />
      </Section>
      <Section
        value={formState.unit || ""}
        label="Price per"
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("unit") : onCancel("unit");
        }}
        showAll
      >
        <Select
          className="z-40 mt-4"
          label="Price per"
          value={{ title: formState.unit || "", value: formState.unit || "" }}
          options={PRODUCT_PER_UNITS}
          onChange={(opt) => {
            setFormValue({ unit: opt?.value });
          }}
        />
      </Section>
      <Section
        label="Dimensions"
        value={formState.dimensions}
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("dimensions") : onCancel("dimensions");
        }}
        showAll
      >
        <TextField
          label="Dimensions (inches)"
          value={formState.dimensions || ""}
          onChange={({ target: { value } }) => setFormValue({ dimensions: value })}
          className="mt-4"
        />
      </Section>
      <Section
        label="URL"
        value={formState.vendorURL}
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("vendorURL") : onCancel("vendorURL");
        }}
        showAll
      >
        <TextField
          label="URL"
          value={formState.vendorURL}
          onChange={({ target: { value } }) => setFormValue({ vendorURL: value })}
          className="mt-4"
        />
      </Section>
      <Section
        label="Description"
        value={formState.description}
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("description") : onCancel("description");
        }}
        showAll
      >
        <Textarea
          label="Description"
          value={formState.description}
          onChange={({ target: { value } }) => setFormValue({ description: value })}
          className="mt-4"
        />
      </Section>
      <Section
        label="Files"
        onAction={(actionType) => {
          actionType === "save" ? updateProductFiles() : setFiles(null);
        }}
        showAll
        value={
          <div className="flex flex-row flex-wrap items-center gap-x-1 gap-y-2">
            {product?.media?.map((file) => (
              <CloudinaryImage
                imgSrc={file.path}
                className="h-[75px] w-[75px] rounded border border-neutral-300 object-cover"
                transformations={{
                  width: 95,
                  height: 95,
                }}
              />
            ))}
          </div>
        }
      >
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row flex-wrap items-center gap-x-1 gap-y-2">
            {product?.media?.map((file) => (
              <CloudinaryImage
                imgSrc={file.path}
                className="h-32 w-32 rounded border border-neutral-300 object-cover"
                transformations={{
                  width: 50,
                  heigth: 50,
                }}
              />
            ))}
          </div>
          <FileUpload
            handleSelectedFiles={(files) => {
              return setFiles(files);
            }}
            acceptedFileTypes={["image/jpeg", "image/png", ".pdf", ".docx", "video/*", "image/heic", "image/webp"]}
            multiple
            maxFiles={25}
            uploadResults={uploadResults}
          />
        </div>
      </Section>

      <p className="mt-14 text-xl font-medium">Availability</p>
      <Section
        label="Lead time"
        value={formState.leadTimeDays}
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("leadTimeDays") : onCancel("leadTimeDays");
        }}
        showAll
      >
        <TextField
          type="number"
          label="Lead Time (days)"
          value={formState.leadTimeDays}
          onChange={({ target: { value } }) => setFormValue({ leadTimeDays: parseInt(value, 10) })}
          className="mt-4"
        />
      </Section>
      <Section
        label="In Stock"
        value={formState.isStocked ? "Yes" : "No"}
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("isStocked") : onCancel("isStocked");
        }}
        showAll
      >
        <Toggle
          label="In Stock"
          checked={formState.isStocked}
          onChange={(newValue) => setFormValue({ isStocked: newValue })}
          position="left"
          className="mt-4"
        />
      </Section>
      <p className="mt-14 text-xl font-medium">Price</p>
      <Section
        label="Price"
        value={formState.cost}
        onAction={(actionType) => {
          actionType === "save" ? onSubmit("cost") : onCancel("cost");
        }}
        showAll
      >
        <TextField
          type="number"
          label="Price (USD)"
          value={formState.cost}
          onChange={({ target: { value } }) => setFormValue({ cost: parseFloat(value) })}
          className="mt-4"
        />
      </Section>
    </div>
  );
};

export default EditProduct;
