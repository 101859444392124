import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_SPACES = gql`
  query getSpaces($filters: SpaceFilterFields!) {
    getSpaces(filters: $filters) {
      id
      name
      location
      sortOrder
    }
  }
`;

const GET_SPACE_TYPES = gql`
  query getSpaceTypes($spaceId: Int!) {
    getSpaceTypes(spaceId: $spaceId) {
      id
      type
      icon
    }
  }
`;

const ADD_SPACE_TO_PROJECT = gql`
  mutation addSpaceToProject($input: AddSpaceToProjectRequest!) {
    addSpaceToProject(input: $input) {
      code
      success
      message
      data {
        id
        name
        measurementsWidth
        measurementsLength
        roomNumber
        spaceNickname
        sqft
        floor
        spaceId
        permitRequired
        isLikeForLike
        parentSpaceId
        budget
      }
    }
  }
`;

const BULK_ADD_SPACES_TO_PROJECT = gql`
  mutation bulkAddProjectSpaces($input: [AddSpaceToProjectRequest]!) {
    bulkAddProjectSpaces(input: $input) {
      code
      success
      message
      data {
        id
        name
        sortOrder
        spaceId
        spaceNickname
      }
    }
  }
`;

const UPDATE_PROJECT_SPACE = gql`
  mutation updateProjectSpace($input: UpdateProjectSpaceRequest!) {
    updateProjectSpace(input: $input) {
      code
      success
      message
      data {
        id
        measurementsWidth
        measurementsLength
        roomNumber
        spaceNickname
        sqft
        spaceId
        floor
        permitRequired
        isLikeForLike
        parentSpaceId
        budget
        spaceType {
          id
          type
        }
        scopeType
      }
    }
  }
`;

const DELETE_PROJECT_SPACE = gql`
  mutation deleteProjectSpace($input: DeleteProjectSpaceRequest!) {
    deleteProjectSpace(input: $input) {
      code
      success
      message
      data {
        id
        measurementsWidth
        measurementsLength
        roomNumber
        spaceNickname
        sqft
        floor
        spaceId
        permitRequired
        isLikeForLike
        parentSpaceId
        budget
      }
    }
  }
`;

export const fetchSpaces = async (filters = {}) => {
  return await graphQLClient.request(GET_SPACES, { filters });
};

export const fetchSpaceTypes = async (spaceId) => {
  return await graphQLClient.request(GET_SPACE_TYPES, { spaceId });
};

export const addSpaceToProject = async (input = {}) => {
  return await graphQLClient.request(ADD_SPACE_TO_PROJECT, { input });
};

export const bulkAddSpaceToProject = async (input = {}) => {
  return await graphQLClient.request(BULK_ADD_SPACES_TO_PROJECT, { input });
};

export const updateProjectSpace = async (input = {}) => {
  return await graphQLClient.request(UPDATE_PROJECT_SPACE, { input });
};

export const deleteProjectSpace = async (input = {}) => {
  return await graphQLClient.request(DELETE_PROJECT_SPACE, { input });
};
