import CommonSendEmail from "../people/CommonSendEmail";
import AddPartner from "./AddPartner";

export const partnersActions = {
  add: {
    id: "add",
    label: "Create Partner",
    sidebar: AddPartner,
  },
  send: {
    id: "send",
    label: "Send Message",
    component: CommonSendEmail,
    container: "collapsedExpand",
  },
};
