import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_NOTES = gql`
  query getNotes($filters: NoteFilterFields!) {
    getNotes(filters: $filters) {
      id
      createdAt
      noteType
      title
      message
      project {
        id
        thumbnail {
          id
          path
        }
        spaces {
          id
          location
          spaceNickname
        }
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        projectStages {
          id
          name
          stageType
        }
      }
      staff {
        id
        avatar
        fullName
        email
      }
      projectSpaces {
        id
        location
        spaceNickname
        name
      }
      media {
        id
        path
        shareUrl
        fileType
      }
      consultation {
        id
        consultationDate
      }
      createdBy {
        id
        avatar
        fullName
        role
      }
      partners {
        id
        companyName
      }
      suppliers {
        id
        name
      }
      contacts {
        id
        fullName
        firstName
        lastName
        suppliers {
          id
          name
        }
        partners {
          id
          companyName
        }
      }
    }
  }
`;

const GET_NOTE_BY_ID = gql`
  query getNoteByID($id: Int!) {
    getNoteByID(id: $id) {
      id
      createdAt
      noteType
      message
      project {
        id
        spaces {
          id
          location
          spaceNickname
        }
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        projectStages {
          id
          name
          stageType
        }
      }
      staff {
        id
        avatar
        fullName
        email
      }
      projectSpaces {
        id
        location
        spaceNickname
      }
      createdBy {
        id
        avatar
        fullName
        role
      }
    }
  }
`;

const CREATE_NOTE = gql`
  mutation createNote($input: CreateNoteRequest!) {
    createNote(input: $input) {
      success
      message
      data {
        id
        createdAt
        noteType
        title
        message
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          spaces {
            id
            location
            spaceNickname
          }
          projectStages {
            id
            name
            stageType
          }
        }
        staff {
          id
          avatar
          fullName
          email
        }
        projectSpaces {
          id
          spaceNickname
          location
        }
        media {
          id
          path
        }
        createdBy {
          id
          avatar
          fullName
          role
        }
      }
    }
  }
`;

const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateNoteRequest!) {
    updateNote(input: $input) {
      success
      message
      data {
        id
        createdAt
        noteType
        title
        message
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        staff {
          id
          avatar
          fullName
          email
        }
        projectSpaces {
          id
          spaceNickname
          location
        }
        createdBy {
          id
          avatar
          fullName
          role
        }
      }
    }
  }
`;

const DELETE_NOTE = gql`
  mutation deleteNote($input: DeleteNoteRequest!) {
    deleteNote(input: $input) {
      success
      message
      data {
        id
        createdAt
        noteType
        message
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        staff {
          id
          avatar
          fullName
          email
        }
      }
    }
  }
`;

const UPDATE_OR_CREATE_MANY_NOTES = gql`
  mutation updateOrCreateManyNotes($input: [UpdateOrCreateRequest]!) {
    updateOrCreateManyNotes(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        noteType
        title
        message
        project {
          id
          spaces {
            id
            location
            spaceNickname
          }
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        staff {
          id
          avatar
          fullName
          email
        }
        projectSpaces {
          id
          location
          spaceNickname
        }
        createdBy {
          id
          avatar
          fullName
          role
        }
      }
    }
  }
`;

export const createNote = async (input = {}) => {
  return await graphQLClient.request(CREATE_NOTE, { input });
};

export const deleteNote = async (input = {}) => {
  return await graphQLClient.request(DELETE_NOTE, { input });
};

export const updateNote = async (input = {}) => {
  return await graphQLClient.request(UPDATE_NOTE, { input });
};

export const fetchNotes = async (filters = {}) => {
  return await graphQLClient.request(GET_NOTES, { filters });
};

export const fetchNote = async (id) => {
  return await graphQLClient.request(GET_NOTE_BY_ID, { id });
};

export const updateOrCreateManyNotes = async (input) => {
  return await graphQLClient.request(UPDATE_OR_CREATE_MANY_NOTES, { input });
};
