import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingSpinner } from "@buildappeal/react-component-library";
import { selectIsLoggedIn, setIsLoggedIn, setUserEmail } from "@src/features/auth/authSlice";
import { verifyToken } from "./api";

const AuthCheck = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const verifyTokenRequest = useMutation(verifyToken, {
    onSuccess: async (email) => {
      dispatch(setUserEmail(email));
      dispatch(setIsLoggedIn(true));
      if (location?.pathname === "/login") {
        navigate("/");
      }
    },
    onError: () => {
      if (
        location?.pathname !== "/login" &&
        location?.pathname !== "/login/callback" &&
        location?.pathname !== "/404" &&
        !location?.pathname.startsWith("/files/") &&
        !isLoggedIn
      ) {
        dispatch(setIsLoggedIn(false));
        dispatch(setUserEmail(""));
        navigate("/login");
      }
    },
  });

  useEffect(() => {
    verifyTokenRequest.mutate();
  }, [location.pathname]);

  if (
    location?.pathname !== "/login" &&
    location?.pathname !== "/login/callback" &&
    location?.pathname !== "/404" &&
    !location?.pathname.startsWith("/files/") &&
    ((verifyTokenRequest.isLoading && !isLoggedIn) || !isLoggedIn)
  ) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="w-52">
          <LoadingSpinner />
        </div>
      </div>
    );
  }
  // React is smart enough to not rerender children if they haven't changed
  return children;
};

export default AuthCheck;
