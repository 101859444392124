import { useQuery } from "react-query";

import { getEmailsCount } from "../../api";

const useEmailsCount = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const { data, isLoading } = useQuery(["emailsCount", filters], () => getEmailsCount(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  return {
    data: data?.getEmailsCount,
    isLoading,
  };
};

export default useEmailsCount;
