import React, { useState } from "react";

import useToast from "@src/utils/hooks/useToast";
import DeleteFileToast from "@features/files/DeleteFileToast";

const FileDelete = ({ onDelete, onDeleteSuccess, onClose, isOpen }) => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      setMessage("Deleting file...");
      await onDelete?.();
      onDeleteSuccess();
      addToast("File deleted successfully");
    } catch (error) {
      addToast("File name delete failed");
    } finally {
      setIsLoading(false);
      onClose();
      setMessage("");
    }
  };

  return (
    <DeleteFileToast
      show={isOpen}
      loading={isLoading}
      handleClose={onClose}
      onDelete={handleDelete}
      message={message}
    />
  );
};

export default FileDelete;
