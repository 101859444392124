import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createService } from "../../api";

const useAddService = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      return createService(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createService.success) {
          setError(`Error: ${data.createService.message || "Failed to create Service"}`);
        } else {
          setIsSuccess(true);
          queryClient.invalidateQueries("services");
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    addService: mutate,
    isLoading,
    isSuccess,
    error,
    addServiceAsync: mutateAsync,
  };
};

export default useAddService;
