import React, { useMemo, useState, useEffect } from "react";
import clsx from "clsx";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import {
  UilEllipsisV as MenuIcon,
  UilLink as LinkIcon,
  UilDownloadAlt as DownloadIcon,
  UilTrashAlt as DeleteIcon,
  UilPen as EditIcon,
  UilExpandArrowsAlt,
} from "@iconscout/react-unicons";
import { Document, Page, pdfjs } from "react-pdf";
import { Link } from "react-router-dom";

import {
  Dropdown,
  Avatar,
  IconButton,
  Video,
  Section,
  Select,
  Button,
  Toggle,
  DateTime,
  TitleEdit,
  Audio,
} from "@buildappeal/react-component-library";
import CloudinaryImage from "@utils/components/CloudinaryImage";
import { copy, formatBytes, getPlaceAddressStr } from "@utils/common";
import { getFileType, getFileLink } from "@features/files/utils";
import FileInfoLoading from "../../common/FileInfoLoading";

// https://github.com/wojtekmaj/react-pdf/issues/52#issuecomment-1061497022
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const FileInfo = ({
  fileId = "",
  src = "",
  fileName = "",
  fileType = "images",
  description = "",
  link = "",
  size = "",
  created,
  by,
  productTypes,
  onDownload = noop,
  onDelete = noop,
  onRename = noop,
  onCoordinatesEdit = noop,
  onSaveFileInfo = noop,
  onEditAltText = noop,
  tags = [],
  tagOptions = [],
  spaceOptions = [],
  contentTypeOptions = [],
  designStyleOptions = [],
  finishOptions = [],
  productTypeOptions = [],
  videoProps,
  project,
  spaces,
  designStyles,
  product,
  contentType,
  finishes,
  metadata,
  isFeatured,
  showGallery = noop,
  showCoordinatesSection = false,
  imageVisible = true,
  isShowGalleryOnContainerClick = true,
  editable = true,
  imgTransformations = {},
  isSaving = false,
  mRef,
  titleEditMode = true,
  onTitleChange = noop,
  projectLink = "",
  loadingData = true,
  onProjectViewFiles = noop,
}) => {
  const [copyButtonLabel, setCopyButtonLabel] = useState("Copy");

  const [formState, setFormState] = useState({
    fileTags: tags?.map((tag) => ({ value: tag, title: tag })) || [],
    fileDescription: description || null,
    draftContentType: contentType || null,
    draftImageFeatured: isFeatured,
    draftSpaces: (spaces || []).map((space) => ({
      ...space,
      value: space?.id,
      title: space?.spaceNickname || space?.name,
      space,
    })),
    draftDesignStyles: (designStyles || []).map((designStyle) => ({
      ...designStyle,
      title: designStyle.name,
      value: designStyle.id,
    })),
    draftFinishes: (finishes || []).map((finish) => ({
      ...finish,
      value: finish?.id,
      title: finish?.name,
      finish,
    })),
    draftProductTypes: (productTypes || []).map((productType) => ({
      ...productType,
      value: productType?.id,
      title: productType?.description,
      productType,
    })),
  });

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const { isPdf, isImage, isVideo, isAudio, isOther } = getFileType(fileType);
  const fileLink = getFileLink({ fileId, fileType, fileName });
  const menuItems = useMemo(() => {
    const items = [
      {
        label: "Copy link",
        icon: LinkIcon,
        onClick: () => copy(fileLink),
      },
      {
        label: "Download",
        icon: DownloadIcon,
        onClick: onDownload,
      },
      {
        label: "Delete",
        icon: DeleteIcon,
        onClick: (e) => {
          e.stopPropagation();
          onDelete();
        },
      },
      {
        label: "Rename",
        icon: EditIcon,
        onClick: (e) => {
          e.stopPropagation();
          onRename();
        },
      },
      {
        label: "Edit Alt Text",
        icon: EditIcon,
        onClick: (e) => {
          e.stopPropagation();
          onEditAltText();
        },
      },
    ];
    return items;
  }, [link, onDownload]);

  const handleSave = async () => {
    return await onSaveFileInfo?.(formState);
  };

  useEffect(() => {
    setFormValue({ draftContentType: contentType });
  }, [contentType]);

  useEffect(() => {
    setFormValue({ isFeatured });
  }, [isFeatured]);

  useEffect(() => {
    setFormValue({ fileTags: tags?.map((tag) => ({ value: tag, title: tag })) || [] });
  }, [tags.toString()]);

  useEffect(() => {
    if (!spaces) {
      return;
    }
    setFormValue({
      draftSpaces: spaces.map((space) => ({
        ...space,
        value: space?.id,
        title: space?.spaceNickname || space?.name,
        space,
      })),
    });
  }, [spaces]);

  useEffect(() => {
    if (!designStyles) {
      return;
    }
    setFormValue({
      draftDesignStyles: designStyles.map((designStyle) => ({
        ...designStyle,
        title: designStyle.name,
        value: designStyle.id,
      })),
    });
  }, [designStyles]);

  useEffect(() => {
    setFormValue({
      draftProject: {
        ...project,
        value: project?.id,
        title: `${project?.places?.addressLine1}, ${project?.places?.city}, ${project?.places?.state}, ${project?.places?.zipCode}`,
      },
    });
  }, [project]);

  useEffect(() => {
    setFormValue({
      draftProduct: {
        ...product,
        value: product?.id,
        title: product?.name,
      },
    });
  }, [product]);

  useEffect(() => {
    if (!productTypes) {
      return;
    }
    setFormValue({
      draftProductTypes: productTypes.map((productType) => ({
        ...productType,
        title: productType.description,
        value: productType.id,
      })),
    });
  }, [productTypes]);

  useEffect(() => {
    if (copyButtonLabel === "Copied!") {
      const timer = setTimeout(() => {
        setCopyButtonLabel("Copy");
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [copyButtonLabel]);

  return (
    <section className="" ref={mRef} data-testid="FileInfo">
      {imageVisible && (
        <header
          className="relative flex h-[302px] w-full cursor-pointer items-center justify-center overflow-hidden bg-white dark:bg-midnight dark:text-neutral-400"
          onClick={(e) => {
            e.stopPropagation();
            isShowGalleryOnContainerClick && showGallery?.(true);
          }}
        >
          {isImage && !!src && (
            <CloudinaryImage
              imgSrc={src}
              alt="File image"
              transformations={{
                ...imgTransformations,
                width: 434,
                height: 302,
              }}
            />
          )}

          {isVideo && !!src && <Video videoPath={src} className="h-full w-full" {...videoProps} />}

          {isPdf && !!src && (
            <Document file={src} onLoadError={console.error}>
              <Page pageNumber={1} height={302} scale={1.0} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
          )}

          {isAudio && !!src && <Audio path={src} className="p-4" />}

          {isOther && (
            <div className="flex h-full w-full cursor-default items-center justify-center text-center text-xl font-medium text-neutral-800 dark:text-neutral-400">
              No Preview Available
            </div>
          )}

          <div className="absolute bottom-3 right-2">
            {/* <Badge size="xs" label={fileType?.toUpperCase()} color="overlay" /> */}
            <IconButton
              className="ml-2"
              icon={UilExpandArrowsAlt}
              onClick={(e) => {
                e.stopPropagation();
                isPdf ? window.open(src, "_blank").focus() : showGallery?.(true);
              }}
            />
          </div>
        </header>
      )}
      <div
        className={clsx(
          "flex flex-col divide-y divide-neutral-100 px-8 pb-16  dark:divide-midnightborder",
          imageVisible ? "pt-3" : "pt-8"
        )}
      >
        <div className="flex items-start justify-between py-6">
          <div className="mr-2 flex flex-col space-y-1">
            <TitleEdit
              title={fileName}
              handleTitleChange={onTitleChange}
              className="break-all text-lg dark:text-neutral-200"
              editMode={titleEditMode}
            />
            <span className="text-xs text-neutral-600 dark:text-neutral-400">{size}</span>
          </div>
          {editable && (
            <Dropdown MenuButton={<IconButton icon={MenuIcon} size="md" />} items={menuItems} classNamePortal="!z-20" />
          )}
        </div>
        <div className="py-6">
          <span className="mb-1 text-md font-normal dark:text-neutral-400">Details</span>
          <div className="my-3 flex items-center text-xs">
            <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">Created</span>
            <DateTime className="basis-8/12 dark:text-neutral-400" value={`${created}`} />
          </div>
          <div className="my-3 flex items-center text-xs">
            <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">Added By</span>
            <div className="flex basis-8/12 items-center">
              <Avatar src={by?.avatar} initials={by?.initials} size="xs" />
              <span className="ml-2  text-neutral-800 dark:text-neutral-400">{by?.name}</span>
            </div>
          </div>
          {metadata && metadata.width && metadata.height ? (
            <div className="my-3 flex items-center text-xs">
              <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">Dimensions</span>
              <span className="basis-8/12">{`${metadata.width}x${metadata.height}`}</span>
            </div>
          ) : null}
          {metadata && metadata.bytes ? (
            <div className="my-3 flex items-center text-xs">
              <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">Size</span>
              <span className="basis-8/12">{formatBytes(metadata.bytes)}</span>
            </div>
          ) : null}
          {project && (
            <div className="my-3 flex items-center text-xs">
              <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">Project</span>
              <span className="basis-8/12 space-x-2">
                <Link
                  to={projectLink ? projectLink : `/projects?id=${project?.id}`}
                  className="underline dark:text-neutral-400"
                >
                  {getPlaceAddressStr(project.places || {})}
                </Link>
                <Button
                  appearance="tertiary"
                  size="xs"
                  label="View All Files"
                  className="ml-auto flex"
                  onClick={() => onProjectViewFiles(project)}
                />
              </span>
            </div>
          )}
          {product && (
            <div className="my-3 flex items-center text-xs">
              <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">Product</span>
              <span className="basis-8/12">
                <Link className="underline dark:text-neutral-400" to={`/products?id=${product?.id}`}>
                  {product?.name}
                </Link>
              </span>
            </div>
          )}
          <div className="my-3 flex flex-row items-center text-xs">
            <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">File URL</span>
            <span className="a basis-3/6 overflow-hidden text-ellipsis whitespace-nowrap text-neutral-800 dark:text-neutral-400">
              {fileLink}
            </span>
            <Button
              appearance="tertiary"
              size="xs"
              label={copyButtonLabel}
              className="ml-auto flex"
              onClick={() => {
                copy(fileLink).then(() => setCopyButtonLabel("Copied!"));
              }}
            />
          </div>
          <div className="my-3 flex items-center text-xs">
            <span className="basis-4/12 text-neutral-600 dark:text-neutral-400">File ID</span>
            <span className="basis-8/12">{fileId}</span>
          </div>
        </div>
        {loadingData ? (
          <FileInfoLoading
            spaceOptions={spaceOptions}
            isImage={isImage}
            src={src}
            showCoordinatesSection={showCoordinatesSection}
            finishOptions={finishOptions}
            productTypeOptions={productTypeOptions}
          />
        ) : (
          <>
            {" "}
            {spaceOptions?.length > 0 && (
              <div className="py-2">
                <Select
                  label="Spaces"
                  value={formState.draftSpaces}
                  options={spaceOptions}
                  onChange={(newSpaces) => {
                    return setFormValue({
                      draftSpaces: newSpaces,
                    });
                  }}
                  showButton
                  multiple
                />
              </div>
            )}
            <div className="py-2">
              <div className="relative">
                <Select
                  label="Tags"
                  options={tagOptions}
                  value={formState.fileTags}
                  onChange={(newTags) => setFormValue({ fileTags: newTags })}
                  multiple
                />
              </div>
            </div>
            {isImage && src && showCoordinatesSection ? (
              <div className="py-2">
                <Section label="Coordinates" onEdit={onCoordinatesEdit} isLast showAll />
              </div>
            ) : null}
            <div className="py-2">
              <div>
                <Select
                  label="Content Type"
                  value={{
                    title: formState.draftContentType || "",
                    value: formState.draftContentType || "",
                  }}
                  options={contentTypeOptions?.map((option) => ({
                    title: option,
                    value: option,
                  }))}
                  onChange={(opt) => {
                    return setFormValue({ draftContentType: opt?.value });
                  }}
                  sortBy="az"
                />
              </div>
            </div>
            {isImage && src ? (
              <div className="py-2">
                <Select
                  label="Design Style"
                  value={formState.draftDesignStyles}
                  options={designStyleOptions}
                  onChange={(newDesignStyles) => setFormValue({ draftDesignStyles: newDesignStyles })}
                  showButton
                  multiple
                />
              </div>
            ) : null}
            {finishOptions?.length > 0 ? (
              <div className="py-2">
                <Select
                  label="Finish"
                  value={formState.draftFinishes}
                  options={finishOptions}
                  onChange={(newDraftFinishes) => setFormValue({ draftFinishes: newDraftFinishes })}
                  showButton
                  multiple
                />
              </div>
            ) : null}
            {productTypeOptions?.length > 0 ? (
              <div className="py-2">
                <Select
                  label="Product Type"
                  value={formState.draftProductTypes}
                  options={productTypeOptions}
                  onChange={(newDraftProductTypes) => setFormValue({ draftProductTypes: newDraftProductTypes })}
                  multiple
                />
              </div>
            ) : null}
            {isImage && (
              <div className="py-2">
                <Toggle
                  label="Featured"
                  checked={formState.draftImageFeatured}
                  onChange={(newVal) => {
                    setFormValue({
                      draftImageFeatured: newVal,
                    });
                  }}
                />
              </div>
            )}
            <div className="flex w-full py-2">
              <Button isLoading={isSaving} label={"Save"} className="w-full !rounded-sm" onClick={handleSave} />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

FileInfo.propTypes = {
  fileId: PropTypes.string,
  imageVisible: PropTypes.bool,
  src: PropTypes.string,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.string,
  isFeatured: PropTypes.bool,
  created: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  by: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    initials: PropTypes.string,
  }),
  onDownload: PropTypes.func,
  onRename: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveFileInfo: PropTypes.func,
  onEditAltText: PropTypes.func,
  showGallery: PropTypes.func,
  mRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line
  videoProps: PropTypes.object, // eslint-disable-line
  onClickProjectLink: PropTypes.func,
  projectLinkLabel: PropTypes.string,
  isProjectFilePage: PropTypes.bool,
  isShowGalleryOnContainerClick: PropTypes.bool,
  editable: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  metadata: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    bytes: PropTypes.number,
  }),
  spaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      location: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  spaceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    places: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
  }),
  projectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onCoordinatesEdit: PropTypes.func,
  showCoordinatesSection: PropTypes.bool,
  isSaving: PropTypes.bool,
  contentType: PropTypes.string,
  contentTypeOptions: PropTypes.arrayOf(PropTypes.string),
  designStyles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  designStyleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  tagOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    })
  ),
  imgTransformations: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    responsive: PropTypes.bool,
    lazyload: PropTypes.bool,
  }),
  finishes: PropTypes.array, // eslint-disable-line
  finishOptions: PropTypes.array, // eslint-disable-line
  productTypes: PropTypes.array, // eslint-disable-line
  productTypeOptions: PropTypes.array, // eslint-disable-line
  titleEditMode: PropTypes.bool,
  onTitleChange: PropTypes.func,
  projectLink: PropTypes.string,
  loadingData: PropTypes.bool,
};

export default FileInfo;
