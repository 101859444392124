import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  proposals: [],
  proposalItems: [],
  proposalTemplates: [],
};

export const proposalsSlice = createSlice({
  name: "proposals",
  initialState,
  reducers: {
    setProposals: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        proposals: action.payload,
      };
    },
    setProposalItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        proposalItems: action.payload,
      };
    },
    setProposalTemplates: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        proposalTemplates: action.payload,
      };
    },
  },
});

export const { setProposals, setProposalItems, setProposalTemplates } = proposalsSlice.actions;

export default proposalsSlice.reducer;

export const getProposals = (state) => state.proposals.proposals;
export const getProposalItems = (state) => state.proposals.proposalItems;
export const getProposalTemplates = (state) => state.proposals.proposalTemplates;
