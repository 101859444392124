import React, { createContext, useContext, useState } from "react";

const QuickViewStateContext = createContext();
const QuickViewUpdaterContext = createContext();

export const QuickViewProvider = ({ children }) => {
  const [quickView, setQuickView] = useState({
    isOpen: false,
    path: "",
    selectedItem: null,
  });

  return (
    <QuickViewStateContext.Provider value={quickView}>
      <QuickViewUpdaterContext.Provider value={setQuickView}>{children}</QuickViewUpdaterContext.Provider>
    </QuickViewStateContext.Provider>
  );
};

export const useQuickViewState = () => {
  const quickViewState = useContext(QuickViewStateContext);

  if (typeof quickViewState === "undefined") {
    throw new Error("useQuickViewState must be used within a QuickViewProvider");
  }

  return quickViewState;
};

export const useQuickViewUpdater = () => {
  const quickViewUpdater = useContext(QuickViewUpdaterContext);

  if (typeof quickViewUpdater === "undefined") {
    throw new Error("useQuickViewUpdater must be used within a QuickViewProvider");
  }

  return quickViewUpdater;
};
