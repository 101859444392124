import dayjs from "dayjs";
import { TableCells } from "@buildappeal/react-component-library";

import generateInitials from "@utils/generateInitials";
import { getProjectLink, getAddressFromPlaces, isAlgoliaAttributeValueEmpty, titleCase } from "@utils/common";
import { PAGE_LEVEL } from "@features/layout/constants";

export const commonActivitiesColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "date",
    Header: "Date",
    accessor: "activityDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
  },
  {
    id: "activity",
    Header: "Activity",
    accessor: "descriptionStaff",
  },
  {
    id: "by",
    Header: "By",
    accessor: "actor",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          name={originalRow.getValue()?.fullName || ""}
          avatarSrc={originalRow.getValue()?.avatar || ""}
          initials={generateInitials(originalRow.getValue()?.fullName?.trim() || "BA")}
          {...originalRow}
        />
      );
    },
  },
];

export const columns = [...commonActivitiesColumns];
columns.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.project?.places?.addressLine1
      ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original.project?.places?.city}
        addressState={originalRow.row.original.project?.places?.state}
        addressZip={originalRow.row.original.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const generateLabel = (eventName, activity) => {
  switch (eventName) {
    case "consultationScheduled":
      return (
        activity.descriptionStaff.split("for ")[0] +
        "for " +
        dayjs(activity.descriptionStaff.split("for ")[1]).format("h:mm a - dddd, MMM DD, YYYY")
      );
    case "consultationCompleted":
      return `Added Consultation Note: ${dayjs(activity.activityDate).format("MMM DD")}`;
    case "receivedNote":
    case "addedNote":
      return activity.payload?.title || "Added Note";
    case "completedQuiz":
      if (activity.actor) {
        return `Completed Quiz`;
      }
      return activity.descriptionStaff;
    case "staffAdded":
      if (activity.payload?.name) {
        return `${activity.payload?.name} was added to project`;
      }
      return activity.descriptionStaff;
    default:
      return activity.descriptionStaff;
  }
};

export const generateContent = (eventName, activity) => {
  switch (eventName) {
    case "receivedNote":
    case "addedNote":
      return activity.payload?.message;
    case "consultationCompleted":
      return activity.descriptionStaff;
    default:
      return "";
  }
};

export const getMedia = (eventName, activity) => {
  switch (eventName) {
    case "submittedPhotos":
      return activity.payload?.newFiles || [];
    case "receivedNote":
    case "addedNote":
      return (
        activity.payload?.media?.filter((file) => file.fileType?.includes("pdf") || file.fileType?.includes("image")) ||
        []
      );
    default:
      return [];
  }
};

export const getLink = (eventName, activity) => {
  switch (eventName) {
    case "sentEmail":
      return activity.payload?.id ? `/emails?id=${activity.payload?.id}` : null;
    case "receivedNote":
    case "addedNote":
      if (activity.project) {
        return `${getProjectLink(activity.project)}&tab=notes&showCustomLeft=true`;
      }
      return null;
    default:
      return null;
  }
};

export const getActor = (eventName, activity) => {
  switch (eventName) {
    case "receivedNote":
      return activity.payload?.createdBy;
    default:
      return activity.actor;
  }
};

export const transformActivity = (activity, pageLevel) => ({
  ...activity,
  actor: getActor(activity?.activityType?.eventName, activity),
  subTitle:
    activity?.project?.places && pageLevel !== PAGE_LEVEL.projects
      ? getAddressFromPlaces(activity?.project?.places)
      : null,
  subTitleLink: activity?.project?.id && pageLevel !== PAGE_LEVEL.projects ? getProjectLink(activity.project) : null,
  date: activity.activityDate,
  title: generateLabel(activity?.activityType?.eventName, activity),
  content: generateContent(activity?.activityType?.eventName, activity),
  media: getMedia(activity?.activityType?.eventName, activity),
  linkLabel: "View",
  link: getLink(activity?.activityType?.eventName, activity),
  call: activity?.activityType?.eventName === "phoneCallAdded" ? activity.payload : null,
  sms: activity?.activityType?.eventName === "phoneSmsAdded" ? activity.payload : null,
  isLead: activity?.project?.projectStages?.stageType === "Lead",
  isProject: activity?.project && activity?.project?.projectStages?.stageType !== "Lead",
  showIconIndicator: pageLevel !== PAGE_LEVEL.projects,
});

export const transformActivityCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    "activityType.eventTitle": "Type",
    "project.fullAddress": "Project",
  };
  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => {
      if (refinement.attribute === "activityDate") {
        return {
          ...refinement,
          label: refinement.operator.includes(">")
            ? `From: ${dayjs(new Date(refinement.value)).format("MMM D, YYYY")}`
            : `To: ${dayjs(new Date(refinement.value)).format("MMM D, YYYY")}`,
        };
      }

      return {
        ...refinement,
        label: isAlgoliaAttributeValueEmpty(refinement.label)
          ? `No ${refinementAttributeToLabelMap[item.attribute]}`
          : titleCase(refinement.label),
      };
    }),
  }));
};
