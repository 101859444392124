// NOTE: These are some examples to use external filters
import ActivityFilters from "../activities/common/ActivityFilters";
import ConsultationFilters from "../consultations/ConsultationFilters";
import DesignStyleFilters from "../designs/DesignStyleFilters";
import LeadFilters from "../leads/LeadFilters";
import NoteFilters from "../notes/NoteFilters";
import PartnerFilters from "../partners/PartnerFIlters";
import PeopleFilters from "../people/PeopleFilters";
import ProductFilters from "../products/ProductFilters";
import ProjectFilters from "../projects/ProjectFilters";
import SupplierFilters from "../suppliers/SupplierFilters";
import TaskFilters from "../tasks/TaskFilters";

const externalFiltersMap = {
  "/projects": ProjectFilters,
  "/leads": LeadFilters,
  "/people": PeopleFilters,
  "/partners": PartnerFilters,
  "/suppliers": SupplierFilters,
  "/products": ProductFilters,
  "/consultations": ConsultationFilters,
  "/designs/styles": DesignStyleFilters,
  "/tasks": TaskFilters,
  "/notes": NoteFilters,
  "/feed": ActivityFilters,
};

export default externalFiltersMap;
