import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import useAlgoliaInfiniteHits from "@src/utils/hooks/useAlgoliaInfiniteHits";

const loader = () => <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />;
const AlgoliaInifiteHits = ({
  emptyStateComponent: EmptyState,
  children,
  loadingIconComponent: LoadingIcon = loader,
  cacheId,
}) => {
  const { isLoading, isEmpty, isLastPage, fetchNextPage, isFetchingNextPage } = useAlgoliaInfiniteHits(cacheId);
  const [infiniteScrollRef, inView] = useInView();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isLastPage) {
      fetchNextPage();
    }
  }, [inView]);

  if (!isLoading && isEmpty && EmptyState) {
    return <EmptyState />;
  }
  return (
    <div className="flex h-full w-full flex-col">
      {children}
      <div ref={infiniteScrollRef}></div>
      <div className="flex w-full items-center justify-center">{isFetchingNextPage ? <LoadingIcon /> : null}</div>
    </div>
  );
};

export default AlgoliaInifiteHits;
