import { SelectDatetime } from "@buildappeal/react-component-library";
import { useState } from "react";
import { useRange } from "react-instantsearch-hooks-web";

export const AlgoliaDateRangeFilter = ({ attribute }) => {
  const {
    range: { min, max },
    start: [minValue, maxValue],
    refine,
  } = useRange({ attribute });
  const values = {
    min: minValue !== -Infinity && minValue !== min ? minValue : undefined,
    max: maxValue !== Infinity && maxValue !== max ? maxValue : undefined,
  };
  const [prevValues, setPrevValues] = useState(values);

  const [{ fromVal, toVal }, setRange] = useState({
    fromVal: values.min,
    toVal: values.max,
  });

  if (values.min !== prevValues.min || values.max !== prevValues.max) {
    setRange({ fromVal: values.min, toVal: values.max });
    setPrevValues(values);
  }

  const onRefine = ({ from, to }) => {
    if (from) {
      const date = new Date(from).getTime();
      setRange({ fromVal: date, toVal: toVal });
      refine([date, toVal]);
    }

    if (to) {
      const date = new Date(to).getTime();
      setRange({ fromVal, toVal: date });
      refine([fromVal, date]);
    }
  };

  const onClear = (date) => {
    if (date === "from") {
      setRange({ fromVal: undefined, toVal });
      refine([undefined, toVal]);
    }
    if (date === "to") {
      setRange({ fromVal, to: undefined });
      refine([fromVal, undefined]);
    }
  };

  return (
    <>
      <SelectDatetime
        value={fromVal ? new Date(fromVal) : null}
        onSave={(value) => onRefine({ from: value })}
        onClear={() => onClear("from")}
        label="From"
      />
      <SelectDatetime
        value={toVal ? new Date(toVal) : null}
        onSave={(value) => onRefine({ to: value })}
        onClear={() => onClear("to")}
        label="To"
      />
    </>
  );
};

export default AlgoliaDateRangeFilter;
