import { gql } from "graphql-request";
import axios from "axios";
import graphQLClient from "@src/utils/graphQLClient";
import { getCookie } from "@src/utils/universalCookie";

const GET_CLIENTS = gql`
  query getClients($filters: ClientFilterFields!) {
    getClients(filters: $filters) {
      id
      fullName
      firstName
      lastName
      email
      avatar
      phone
      type
      lastContact
      createdAt
      lastLogin
      clientBudgetLow
      clientBudgetHigh
      lastLogin
      projects {
        id
        spaces {
          id
          name
        }
      }
      staff {
        avatar
        fullName
        email
      }
      rooms {
        id
        name
      }
      leadStage {
        id
        name
      }
      leadStageId
      places {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }
`;

const GET_CLIENT = gql`
  query getClientByID($id: Int!) {
    getClientByID(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      avatar
      phone
      type
      uuid
      linkedinUrl
      instagramUrl
      pinterestUrl
      facebookUrl
      twitterUrl
      tiktokUrl
      lastContact
      createdAt
      clientBudgetLow
      clientBudgetHigh
      actorType
      projects {
        id
        spaces {
          id
          name
        }
      }
      rooms {
        id
        name
      }
      leadStage {
        id
        name
      }
      leadStageId
      places {
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
  }
`;

const CREATE_CLIENT = gql`
  mutation createClient($input: CreateClientInput!) {
    createClient(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        firstName
        lastName
        email
        avatar
        phone
        type
        uuid
        lastContact
        createdAt
        lastLogin
        clientBudgetLow
        clientBudgetHigh
        lastLogin
        projects {
          id
          spaces {
            id
            name
          }
        }
        staff {
          avatar
          fullName
          email
        }
        rooms {
          id
          name
        }
        leadStage {
          id
          name
        }
        leadStageId
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
      }
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation updateClient($input: UpdateClientRequest!) {
    updateClient(input: $input) {
      code
      success
      message
      data {
        id
        fullName
        firstName
        lastName
        email
        phone
        avatar
        type
        uuid
        lastContact
        leadStage {
          id
          name
        }
        leadStageId
        clientBudgetLow
        clientBudgetHigh
        leadInterestLevel
        leadDealStatus
        actorType
      }
    }
  }
`;

const GET_LEAD_STAGES = gql`
  query getLeadStages($filters: LeadStageFilter!) {
    getLeadStages(filters: $filters) {
      id
      name
    }
  }
`;
export const fetchClients = async (filters = {}) => {
  return await graphQLClient.request(GET_CLIENTS, { filters });
};

export const fetchClientById = async (id) => {
  return await graphQLClient.request(GET_CLIENT, { id });
};

export const createClient = async (input = {}) => {
  return await graphQLClient.request(CREATE_CLIENT, { input });
};

export const updateClient = async (input = {}) => {
  return await graphQLClient.request(UPDATE_CLIENT, { input });
};

export const fetchLeadStages = async (filters = {}) => {
  return await graphQLClient.request(GET_LEAD_STAGES, { filters });
};

export const createStripeCustomer = async (customer = {}) => {
  const URL = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/create-customer`;
  return await axios({
    method: "post",
    headers: {
      Authorization: getCookie("ba_session"),
    },
    url: URL,
    data: customer,
  });
};
