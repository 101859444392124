import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchProposals } from "../../api";
import { setProposals, getProposals } from "../../proposalsSlice";

const useProposals = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const proposals = useSelector(getProposals);

  const { data, isLoading, refetch } = useQuery(["proposals", filters], () => fetchProposals(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setProposals(data.getProposals));
  }, [data, isLoading, dispatch]);

  return {
    data: proposals,
    isLoading,
    refetch,
  };
};

export default useProposals;
