import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchContractDocumentTemplates } from "../../api";
import { setContractDocumentTemplates, getContractDocumentTemplates } from "../../contractsSlice";

const useContractDocumentTemplates = () => {
  const dispatch = useDispatch();
  const contractDocumentTemplates = useSelector(getContractDocumentTemplates);

  const { data, isLoading } = useQuery("contractDocumentTemplates", fetchContractDocumentTemplates, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setContractDocumentTemplates(data.getContractDocumentTemplates));
  }, [data, isLoading, dispatch]);

  const contractDocumentTemplatesAsOptions = useMemo(() => {
    if (!data?.getContractDocumentTemplates?.length) {
      return [];
    }
    return data?.getContractDocumentTemplates.map((template) => {
      return {
        value: template.id,
        title: template.name,
        item: template,
      };
    });
  }, [data]);

  return {
    data: contractDocumentTemplates,
    isLoading,
    options: contractDocumentTemplatesAsOptions,
  };
};

export default useContractDocumentTemplates;
