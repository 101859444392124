const formSchema = {
  title: "Create Look",
  properties: {
    name: {
      type: "text",
      label: "Name",
      required: true,
    },
    media: {
      type: "file",
      acceptedFileTypes: ["image/jpeg", "image/png", "video/*", "image/heic", "image/webp"],
      multiple: true,
      maxFiles: 25,
      appearance: "button",
      handleSelectedFiles: () => {},
    },
    spaces: {
      type: "selectMultiple",
      label: "Spaces",
    },
  },
};

export default formSchema;
