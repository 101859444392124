import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staff: [],
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setStaff: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        staff: action.payload,
      };
    },
  },
});

export const { setStaff } = staffSlice.actions;

export default staffSlice.reducer;

export const getStaff = (state) => state.staff.staff;
