import CommonSendEmail from "../people/CommonSendEmail";
import AddClient from "./AddClient";

export const clientActions = {
  add: {
    id: "add",
    label: "Create Client",
    sidebar: AddClient,
  },
  send: {
    id: "send",
    label: "Send Message",
    component: CommonSendEmail,
    container: "collapsedExpand",
  },
};
