import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { deleteProducts } from "../../api";
import { deleteBatchedProducts } from "../../productsSlice";

const useDeleteProducts = (filters = {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [error, setError] = useState();
  const deleteProductsMutation = useMutation(
    (input) => {
      setError(null);
      return deleteProducts(input);
    },
    {
      onSuccess: (data) => {
        if (!data.deleteProducts?.success) {
          setError(`Error: ${data.deleteProducts?.message}`);
        } else {
          const deletedProductIds = data?.deleteProducts?.deletedIds;
          if (!deletedProductIds) {
            return;
          }
          queryClient.invalidateQueries(["productsCount", {}]);
          dispatch(deleteBatchedProducts(deletedProductIds));
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    deleteProducts: deleteProductsMutation.mutate,
    deleteProductsAsync: deleteProductsMutation.mutateAsync,
    deleteProductsLoading: deleteProductsMutation.isLoading,
    deleteProductsSuccess: deleteProductsMutation.isSuccess,
  };
};

export default useDeleteProducts;
