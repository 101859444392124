export const SPACE_LOCATIONS = {
  INTERIOR: "Interior",
  EXTERIOR: "Exterior",
};

export const WALL_OBJECT_ID = 141;
export const FLOOR_OBJECT_ID = 74;
export const CEILING_OBJECT_ID = 52;

export const getAdjacentSpacesMap = (adjacentSpaces) => {
  return adjacentSpaces.reduce((acc, adjacentSpace) => {
    acc[adjacentSpace.projectSpace.position] = {
      ...adjacentSpace,
      id: adjacentSpace.projectSpace.id,
      position: adjacentSpace.projectSpace.position,
    };
    return acc;
  }, {});
};

export const getSpaceIconUrl = (spaceName) => {
  const sanitizedSpaceName = spaceName ? spaceName.replace("/", " ") : "";
  const spaceImage = `https://res.cloudinary.com/build-appeal/image/upload/v1656611706/ba-media/spaceIcons/${(
    sanitizedSpaceName || ""
  )
    .split(" ")
    .join("_")}.svg`;

  return spaceImage;
};
