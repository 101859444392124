import PropTypes from "prop-types";
import { SpaceIcon } from "@buildappeal/react-component-library";

import { formatCurrency } from "@src/utils/common";

const CardLineItem = ({ title, total, items = [], showDetails = false, spaceName }) => {
  return (
    <div className="flex items-start gap-x-6 border-b border-neutral-200 py-6">
      {spaceName && (
        <div data-cy="space-option" className="text-center">
          <div className="flex h-[6rem] w-[6rem] shrink-0 items-center justify-center rounded-full border border-neutral-300 bg-[#F5F1E4]">
            <SpaceIcon space={spaceName} iconClassName="!h-10 !w-10" />
          </div>
        </div>
      )}

      <div className="w-full text-primary-700">
        <div className="flex items-center justify-between text-md font-medium">
          <strong>{title}</strong>
          <strong>{formatCurrency(total)}</strong>
        </div>
        <div className="space-y-0.5">
          {items.map((item, index) => (
            <div className="flex items-center justify-between text-sm text-neutral-500" key={index}>
              <span>{item.subTitle}</span>
              {showDetails && <span>{formatCurrency(item.subTotal)}</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CardLineItem.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subTitle: PropTypes.string,
      subTotal: PropTypes.number,
    })
  ),
  showDetails: PropTypes.bool,
};

export default CardLineItem;
