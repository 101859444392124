import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_ACTIVITIES = gql`
  query getActivities($filters: ActivityFilterFields!) {
    getActivities(filters: $filters) {
      id
      activityDate
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        projectStages {
          id
          name
          stageType
        }
        thumbnail {
          id
          path
        }
      }
      descriptionStaff
      descriptionClient
      link
      linkLabel
      media {
        path
      }
      actor {
        id
        avatar
        fullName
        uuid
        role
      }
      activityType {
        id
        eventName
        category
        eventTitle
      }
      payload
    }
  }
`;

export const NEW_ACTIVITY = gql`
  mutation createActivity($input: CreateActivityRequest!) {
    createActivity(input: $input) {
      code
      success
      message
      data {
        id
        activityDate
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        descriptionStaff
        descriptionClient
        actor {
          avatar
          fullName
          role
        }
      }
    }
  }
`;

export const NEW_ACTIVITIES = gql`
  mutation createActivities($input: [CreateActivityRequest]!) {
    createActivities(input: $input) {
      code
      success
      message
      data {
        id
        activityDate
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        descriptionStaff
        descriptionClient
        actor {
          avatar
          fullName
          role
        }
      }
    }
  }
`;

const GET_ACTIVITY_FILTERS = gql`
  query getActivityFilters {
    getActivityFilters {
      activityTypes {
        id
        eventTitle
      }
      projects {
        id
        thumbnail {
          path
        }
        places {
          addressLine1
          city
          state
          zipCode
        }
      }
    }
  }
`;

export const fetchActivities = async (filters = {}) => {
  return await graphQLClient.request(GET_ACTIVITIES, { filters });
};

export const fetchActivitiesByProject = async (filters) => {
  return await graphQLClient.request(GET_ACTIVITIES, { filters });
};

export const fetchActivitiesByUser = async ({ actorUid, peopleFilters, disablePagination }) => {
  return await graphQLClient.request(GET_ACTIVITIES, { filters: { actorUid, peopleFilters, disablePagination } });
};

export const newActivity = async (input = {}) => {
  return await graphQLClient.request(NEW_ACTIVITY, { input });
};

export const newActivities = async (input = {}) => {
  return await graphQLClient.request(NEW_ACTIVITIES, { input });
};

export const fetchActivityFilters = async () => {
  return await graphQLClient.request(GET_ACTIVITY_FILTERS);
};
