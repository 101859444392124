import { Table, FullWidthContainer } from "@buildappeal/react-component-library";
import { useSelector } from "react-redux";
import loadingDataGenerator from "@src/utils/loadingDataGenerator";
import { commonTasksColumns, loadingPlaceholderTask } from "../tasks/utils";
import useTasks from "../tasks/hooks/useTasks";
import { getPartner } from "./partnersSlice";

const PartnerTasks = () => {
  const partner = useSelector(getPartner);
  const { data: tasks, isLoading } = useTasks(
    { assignedToUser: [partner?.user?.id] },
    { disableQuery: !partner?.user?.id }
  );

  return (
    <FullWidthContainer className="py-10">
      <Table
        label="task"
        columns={commonTasksColumns}
        data={isLoading ? loadingDataGenerator([], loadingPlaceholderTask) : tasks}
        stickyTop={53}
        isLoading={isLoading}
      />
    </FullWidthContainer>
  );
};

export default PartnerTasks;
