import React, { useMemo } from "react";
import { Sidebar } from "@buildappeal/react-component-library";

import CentralUIGenerator from "./CentralUIGenerator";
import { useQuickViewState, useQuickViewUpdater } from "./contexts/QuickViewContext";

const QuickView = ({ uiPages }) => {
  const quickView = useQuickViewState();
  const setQuickView = useQuickViewUpdater();

  const tabs = useMemo(() => {
    const pageUIQuickView = uiPages?.find((page) => page.path === quickView.path);

    return pageUIQuickView?.uiCentral?.tabs || [];
  }, [quickView.path, uiPages]);

  return (
    <Sidebar
      isOpen={quickView.isOpen}
      onClose={() =>
        setQuickView((prev) => ({
          ...prev,
          isOpen: false,
        }))
      }
      classNameContainer="!max-w-[892px]"
      classNameHeader="min-h-[54px]"
      closeOnOutsideClick={false}
    >
      <div className="px-14 py-8">
        <CentralUIGenerator
          currentPath={quickView.path}
          tabs={tabs}
          onBackClicked={() =>
            setQuickView((prev) => ({
              ...prev,
              isOpen: false,
            }))
          }
          selectedItem={quickView.selectedItem}
          activeSearchParams={false}
          isInSidebar
        />
      </div>
    </Sidebar>
  );
};

export default QuickView;
