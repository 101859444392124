import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useToast from "@src/utils/hooks/useToast";
import { updateSupplier } from "../../api";

const useUpdateSupplier = () => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const { addToast } = useToast();

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      return updateSupplier(input);
    },
    {
      onSuccess: (data) => {
        if (!data.updateSupplier.success) {
          addToast("Supplier failed to update.");
          setError("Failed to create supplier");
        } else {
          setIsSuccess(true);
          queryClient.setQueryData(["suppliers", {}], (old) => {
            if (!old) return { getSuppliers: [data.updateSupplier.data] };
            return {
              getSuppliers: old.getSuppliers.map((supplier) => {
                if (supplier.id === data.updateSupplier.data.id) {
                  return {
                    ...supplier,
                    ...data.updateSupplier.data,
                  };
                }
                return supplier;
              }),
            };
          });
          queryClient.setQueryData(["suppliers", data.updateSupplier.data.id], (old) => {
            if (!old) return { getSupplier: [data.updateSupplier.data] };
            return {
              getSupplierByID: {
                ...old,
                ...data.updateSupplier.data,
              },
            };
          });
          addToast("Supplier successfully updated.");
        }
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  return {
    updateSupplier: mutate,
    isLoading,
    isSuccess,
    error,
    updateSupplierAsync: mutateAsync,
  };
};

export default useUpdateSupplier;
