import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchContracts } from "../../api";
import { setContracts, selectContractsById } from "../../contractsSlice";

const useContracts = (filters = {}, hookOptions = { disableQuery: false }) => {
  const dispatch = useDispatch();
  const contractsById = useSelector(selectContractsById);

  const { data, isLoading, refetch } = useQuery(["contracts", filters], () => fetchContracts(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setContracts(data.getContracts));
  }, [data, isLoading, dispatch]);

  return {
    data: data?.getContracts,
    isLoading,
    refetch,
    dataContractsById: contractsById,
  };
};

export default useContracts;
