import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createSupplier } from "../../api";

const useAddSupplier = () => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      return createSupplier(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createSupplier.success) {
          setError(`${data?.createSupplier?.message || "Failed to create supplier"}`);
        } else {
          setIsSuccess(true);
          queryClient.setQueryData(["suppliers", {}], (old) => {
            if (!old) return { getSuppliers: [data.createSupplier.data] };
            return { getSuppliers: [data.createSupplier.data, ...old.getSuppliers] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    createSupplier: mutate,
    isLoading,
    isSuccess,
    error,
    createSupplierAsync: mutateAsync,
  };
};

export default useAddSupplier;
