import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_MESSAGES = gql`
  query getMessages($filters: MessageFilterFields!) {
    getMessages(filters: $filters) {
      id
      message
      sender {
        id
        fullName
        avatar
      }
      recipient {
        id
        fullName
        avatar
      }
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
      createdAt
      messagesThreadId
    }
  }
`;

export const fetchMessages = async (filters = {}) => {
  return await graphQLClient.request(GET_MESSAGES, { filters });
};
