import { Form } from "@buildappeal/react-component-library";
import React, { useEffect, useState } from "react";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import useToast from "@src/utils/hooks/useToast";
import useSuppliers from "../suppliers/hooks/useSuppliers";
import usePartners from "../partners/hooks/usePartners";
import contactFormSchema from "./formSchema";
import contactUiSchema from "./uiSchema";
import useContactAdd from "./hooks/useContactAdd";
import { contactAssignedTo } from "./utils";

const AddContact = ({ onClose }) => {
  const { addContactAsync, error } = useContactAdd();

  const { options: partnersAsOptions, isLoading: partnerLoading } = usePartners();
  const { suppliersAsOptions, isLoading: supplierLoading } = useSuppliers({});
  const { addToast } = useToast();
  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    const finalValidData = {
      firstName: formState?.firstName,
      lastName: formState?.lastName,
      email: formState?.email,
      phone: formState?.phone,
      isPrimary: formState.isPrimary,
      partnerId: formState?.partner?.value,
      supplierId: formState?.supplier?.value,
    };

    if (formState?.avatar?.length) {
      const avatarFile = await handleFileUpload(formState.avatar[0]);
      finalValidData.avatar = avatarFile;
    }

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const addContactResp = await addContactAsync(finalValidData);
    if (addContactResp.createContact?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Contact Successfully Created");
      onClose();
    }
  };

  const handleFileUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        const fileData = {
          file: binaryStr.split("base64,")[1],
          filename: file.name,
          type: file.type,
        };
        resolve(fileData);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);
  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (supplierLoading || partnerLoading) {
      return;
    }
    const finalSchema = {
      ...contactFormSchema,
    };
    const allProperties = contactFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "addContactTo") {
          acc[key].formCondition[contactAssignedTo.SUPPLIER].supplier.options = suppliersAsOptions;

          acc[key].formCondition[contactAssignedTo.PARTNER].partner.options = partnersAsOptions?.map((x) => {
            return {
              title: x.title,
              value: x.value,
              logo: x?.partner?.logo,
              company: {
                logo: x?.partner?.logo,
                name: x?.title,
              },
            };
          });
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [suppliersAsOptions, partnersAsOptions]);

  if (supplierLoading || partnerLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={contactUiSchema} isInSidebar isCreate />
    </>
  );
};
export default AddContact;
