import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import useToast from "@src/utils/hooks/useToast";
import { BA_FILE_TABLE_TYPES } from "@src/utils/constants";
import isMediaFile from "@src/utils/isMedia";
import useProjects from "../projects/hooks/useProjects";
import useSuppliersQuotes from "../suppliersQuotes/hooks/useSuppliersQuotes";
import useSuppliers from "../suppliers/hooks/useSuppliers";
import useMultiFilesUpload from "../files/hooks/useMultiFilesUpload";
import { FILE_CONTENT_TYPES, FILE_CONTENT_TYPES_LABEL, FILE_PATHS } from "../files/utils";
import purchaseFormSchema from "./formSchema";
import purchaseUiSchema from "./uiSchema";
import useAddPurchase from "./hooks/useAddPurchase";
import { handleFloatValues } from "./utils";
// Use Form component to display form
// Update formSchema after all dynamic options are fetched
// https://storybook-buildappeal.s3.amazonaws.com/main/index.html?path=/story/mdx-form--basic For details
const AddPurchase = ({ onClose, projectId }) => {
  const { options: projectsAddressAsOptions, isLoading: projectOptionLoading } = useProjects();
  const { suppliersQuotesAsOptions, suppliersQuotesLoading } = useSuppliersQuotes({});
  const { suppliersAsOptions, isLoading: suppliersOptionLoading } = useSuppliers();
  const { addPurchaseAsync, error } = useAddPurchase(); // Always use hooks for CRUD ops, create them if necessary
  const { startUpload } = useMultiFilesUpload();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const [formSchema, setFormSchema] = useState(null);

  const uploadFiles = async (purchaseId, projectId, filesList) => {
    const finalFilesList = (filesList || []).map((file) => {
      const fileKey = `${FILE_PATHS[FILE_CONTENT_TYPES.project]}/${projectId}/${uuidv4()}`;
      return {
        key: fileKey,
        contentType: FILE_CONTENT_TYPES_LABEL[FILE_CONTENT_TYPES.purchase],
        type: isMediaFile(file.type) ? BA_FILE_TABLE_TYPES.MEDIA : BA_FILE_TABLE_TYPES.DOCUMENT,
        file,
        projectId,
        purchaseId,
      };
    });
    const results = await startUpload(finalFilesList);
    if (results?.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  const handleSubmit = async (formState) => {
    const data = {
      projectId: formState.project?.value,
      suppliersQuoteId: formState.supplierQuote?.value,
      supplierId: formState.supplier?.value,
      vendorOrderNumber: formState.vendorOrderNumber,
      subTotal: handleFloatValues(formState.subTotal),
      shippingTotal: handleFloatValues(formState.shippingTotal),
      taxTotal: handleFloatValues(formState.taxTotal),
      total: handleFloatValues(formState.total),
      estimatedDelivery: formState.estimatedDelivery,
      purchaseDate: formState.purchaseDate,
      isInventory: formState.isInventory,
    };
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const addPurchaseResp = await addPurchaseAsync(data);

    if (addPurchaseResp.createPurchase?.success) {
      let documents = [];

      if (formState?.documents?.length) {
        const purchaseId = addPurchaseResp.createPurchase.data.id;
        const projectId = addPurchaseResp.createPurchase.data.project?.id;
        const finalFilesList = await uploadFiles(purchaseId, projectId, formState.documents);
        if (finalFilesList?.length) {
          documents = finalFilesList.map((fileInfo) => ({
            path: fileInfo.key,
          }));
        }
      }
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Purchase Successfully Created");
      queryClient.setQueryData(["purchases", {}], (old) => {
        if (!old?.getPurchases) {
          return {
            getPurchases: [{ ...addPurchaseResp.createPurchase.data, documents: [...documents] }],
          };
        }
        let purchase = old?.getPurchases.find((p) => p.id === addPurchaseResp.createPurchase.data.id);
        if (!purchase) {
          purchase = { ...addPurchaseResp.createPurchase.data, documents: [...documents] };
          return {
            getPurchases: [purchase, ...old?.getPurchases],
          };
        } else {
          return {
            getPurchases: old?.getPurchases.map((p) => {
              if (p.id === purchase.id) {
                return { ...p, documents: [...documents] };
              }

              return p;
            }),
          };
        }
      });
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (projectOptionLoading || suppliersQuotesLoading || suppliersOptionLoading) {
      return;
    }
    const finalSchema = {
      ...purchaseFormSchema,
    };
    const allProperties = purchaseFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "project") {
          acc[key].options = projectsAddressAsOptions;
          if (projectId) {
            acc[key].initialValue = projectsAddressAsOptions.find((option) => option.value === projectId);
          }
        }
        if (key === "supplierQuote") {
          acc[key].options = suppliersQuotesAsOptions;
        }
        if (key === "supplier") {
          acc[key].options = suppliersAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [projectsAddressAsOptions, suppliersQuotesAsOptions, suppliersAsOptions]);

  if (projectOptionLoading || suppliersQuotesLoading || suppliersOptionLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={purchaseUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddPurchase;
