const formSchema = {
  title: "Create Storyboard",
  properties: {
    name: {
      type: "text",
      label: "Name",
      required: true,
    },
    products: {
      type: "selectMultiple",
      label: "Products",
    },
  },
};

export default formSchema;
