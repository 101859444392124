import React, { useState, useEffect } from "react";
import { Modal, Button } from "@buildappeal/react-component-library";
import { isEmpty, sortBy } from "lodash";
import { v4 as uuidv4 } from "uuid";

import LexoRank from "@src/utils/LexoRank";
import useProducts from "@features/products/hooks/useProducts";
import isMediaFile from "@src/utils/isMedia";
import FolderUpload from "@src/utils/components/FolderUpload";
import useMultiFilesUpload from "@features/files/hooks/useMultiFilesUpload";
import UploadFileWindow from "@features/files/common/UploadFileWindow";
import { FILE_TYPES } from "@features/files/utils";

const UploadProductFolders = ({ isOpen, onClose }) => {
  const { startUpload, uploadStatus } = useMultiFilesUpload();
  const { data: products, refetch } = useProducts();

  const [files, setFiles] = useState();
  const [uploadingQueue, setUploadingQueue] = useState([]);
  const [isCurrentFilesSetUpload, setIsCurrentFilesSetUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allUploaded, setIsAllUploaded] = useState(false);

  const processFilesForUpload = () => {
    const filesByProduct = {};
    (files || []).forEach((fileItem) => {
      if (!filesByProduct[fileItem.id]) {
        filesByProduct[fileItem.id] = [];
      }
      filesByProduct[fileItem.id].push(fileItem);
    });

    let finalFilesList = [];
    Object.keys(filesByProduct || {}).forEach((fileItemKey) => {
      const filesInProduct = filesByProduct[fileItemKey];
      let rank = LexoRank.getNewRank([], 0, 0);
      const productToBeUpdated = products.find((product) => +product.id === +fileItemKey);
      if (productToBeUpdated?.media.length) {
        const sortedMediaFiles = sortBy(productToBeUpdated.media, "sortOrder");
        rank = LexoRank.from(sortedMediaFiles[sortedMediaFiles.length - 1].sortOrder);
        rank = rank.increment();
      }
      filesInProduct.forEach((fileItem) => {
        const fileKey = `products/${fileItem?.id}/${uuidv4()}`;

        let sortOrder = null;
        if (isMediaFile(fileItem.file.type)) {
          sortOrder = rank.toString();
          rank = rank.increment();
        }
        finalFilesList.push({
          key: fileKey,
          contentType: "Product",
          type: isMediaFile(fileItem.file.type) ? FILE_TYPES.media : FILE_TYPES.document,
          file: fileItem.file,
          productId: fileItem?.id || null,
          sortOrder,
        });
      });
    });

    return finalFilesList;
  };

  const onUploadClick = async () => {
    setIsUploading(true);
    setIsCurrentFilesSetUpload(true);
    onClose({
      isOpen: false,
    });
    const finalFiles = files?.length ? files : [];
    setUploadingQueue((prevQ) => [...(prevQ || []), ...finalFiles]);
    const finalFilesList = processFilesForUpload(finalFiles);
    setFiles([]);

    setIsCurrentFilesSetUpload(false);
    const response = await startUpload(finalFilesList);
    if (!response.uploadedFiles?.length) {
      setIsUploading(false);
      console.log("@@ response failed ", response);
    }
  };

  const handleSelectedFiles = (filesList = []) => {
    setFiles((prevState) => [...(prevState || []), ...filesList]);
  };

  const onUploadFinishedOrClose = () => {
    setIsUploading(false);
    refetch();
    setIsModalOpen(true);
  };

  const resetAction = (skipOnSuccess = false) => {
    setIsModalOpen(false);
    onClose({
      isOpen: false,
      currentAction: null,
      triggeredBy: null,
      actionProps: null,
    });
    if (!skipOnSuccess) {
      refetch();
    }
  };

  useEffect(() => {
    if (isOpen !== isModalOpen) {
      setIsModalOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (allUploaded) {
      refetch();
    }
  }, [allUploaded]);

  return (
    <>
      {isUploading && !isEmpty(uploadStatus) ? (
        <UploadFileWindow
          totalFilesCount={uploadingQueue?.length}
          fileUploadStatus={uploadStatus}
          onDone={onUploadFinishedOrClose}
          onCloseClick={resetAction}
          triggeredBy="products"
          onAllUploaded={setIsAllUploaded}
        />
      ) : null}
      <Modal isOpen={isOpen} onClose={() => resetAction(true)}>
        <div className="flex flex-col justify-center p-10">
          <FolderUpload handleSelectedFiles={handleSelectedFiles} isDisabled={isCurrentFilesSetUpload} />
          {isUploading && !allUploaded && (
            <div className="px-6 py-6 text-center text-sm text-accent-700">
              Your selected files are uploading, track progress in the window below. You can add more files here or
              close this window.
            </div>
          )}
          <Button onClick={onUploadClick} appearance="primary" label="Upload files" className="mt-5" />
        </div>
      </Modal>
    </>
  );
};

export default UploadProductFolders;
