import { Form } from "@buildappeal/react-component-library";
import React, { useEffect, useState } from "react";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import useToast from "@src/utils/hooks/useToast";
import useProducts from "../products/hooks/useProducts";
import useAddStoryboard from "./hooks/useAddStoryboard";
import storyboardFormSchema from "./formSchema";
import storyboardUiSchema from "./uiSchema";

const AddStoryboard = ({ onClose }) => {
  const { options: productsAsOptions, isLoading: isProductsOptionLoading } = useProducts();
  const { addStoryboardAsync, error } = useAddStoryboard(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();

  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    const finalFormState = {
      ...formState,
    };
    finalFormState.productsIds = finalFormState.products.map((product) => product.value);
    delete finalFormState.products;

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const data = await addStoryboardAsync(finalFormState);
    if (data?.createStoryboard?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      onClose();
      addToast("Storyboard Successfully Created");
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);
  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (isProductsOptionLoading) {
      return;
    }
    const finalSchema = {
      ...storyboardFormSchema,
    };
    const allProperties = storyboardFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "products") {
          acc[key].options = productsAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [productsAsOptions]);

  if (isProductsOptionLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return <Form formSchema={formSchema} uiSchema={storyboardUiSchema} isInSidebar isCreate />;
};

export default AddStoryboard;
