import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { truncate, noop } from "lodash";
import {
  UilImage as ThumbnailIcon,
  UilMultiply as CloseIcon,
  UilAngleUp as UpIcon,
  UilAngleDown as DownIcon,
} from "@iconscout/react-unicons";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { UisCheckCircle as CheckIcon, UisTimesCircle as FailIcon } from "@iconscout/react-unicons-solid";
import { Tooltip } from "@buildappeal/react-component-library";

import ConfirmationModal from "@src/utils/components/ConfirmationModal";

const UploadFileWindow = ({
  fileUploadStatus,
  totalFilesCount,
  onDone,
  onCloseClick,
  triggeredBy,
  onAllUploaded = noop,
}) => {
  const fileNames = Object.keys(fileUploadStatus || {});

  const uploadCount = Object.values(fileUploadStatus)?.filter((status) => status !== "Failed")?.length;

  const [fullUploadStatus, setFullUploadStatus] = useState(fileUploadStatus);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isViewUp, setIsViewUp] = useState(true);
  const [uploadState, setUploadState] = useState({
    isUploading: true,
    filesUploading: totalFilesCount,
  });

  const getFileStatus = (fileName) => {
    if (!fullUploadStatus[fileName]) {
      return null;
    }
    const fileStatus = fullUploadStatus[fileName];
    if (fileStatus === "Failed") {
      const extensionPattern = /\.[0-9a-z]+$/i;
      const fileType = fileName.match(extensionPattern)?.[0];

      return (
        <Tooltip message={`File type ${fileType} not accepted`} className="z-50">
          <FailIcon className="h-6 w-6 text-red-700" aria-hidden="true" />
        </Tooltip>
      );
    }
    if (fileStatus === "Completed") {
      return <CheckIcon className="h-6 w-6 text-green-700" aria-hidden="true" />;
    }
    return <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-neutral-900" />;
  };

  useEffect(() => {
    let updatedFilesUploading = [];
    let updatedFileUploadStatus = { ...fullUploadStatus };
    fileNames.forEach((fileName) => {
      if (fileUploadStatus[fileName] === "Started") {
        updatedFilesUploading.push(fileName);
      }
      updatedFileUploadStatus[fileName] = fileUploadStatus[fileName];
    });

    setFullUploadStatus(() => ({ ...updatedFileUploadStatus }));
    setUploadState({
      isUploading: updatedFilesUploading.length > 0,
      filesUploading: updatedFilesUploading.length,
    });
    if (updatedFilesUploading.length === 0) {
      onAllUploaded(true);
    }
  }, [fileUploadStatus]);

  return (
    <>
      <ConfirmationModal
        isOpen={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
        title="Cancel operation"
        message="Closing this window  will remove all files from the upload queue and you will no longer be able to track progress or add further details. Upload of files will not be cancelled."
        onDelete={onCloseClick}
      />

      <div
        className={clsx("text-x flex-flex-col fixed left-10 z-[49] min-h-[320px] w-[416px] drop-shadow-2xl", {
          "bottom-0": isViewUp,
          "bottom-[-264px]": !isViewUp,
        })}
      >
        <div className="flex h-[64px] flex-row items-center justify-between rounded-t-lg bg-neutral-900  px-5 text-neutral-50">
          <div className="text-xs">
            {!uploadState.isUploading && !uploadState.filesUploading && fileNames?.length ? (
              <span className="flex items-center">
                {" "}
                <CheckIcon className="mr-2 h-5 w-5 text-neutral-50" aria-hidden="true" />
                {`Uploaded ${uploadCount} files.`}
              </span>
            ) : (
              <span className="flex items-center">
                <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-neutral-50" />{" "}
                {`Uploading ${uploadState?.filesUploading} items.`}
              </span>
            )}
          </div>
          <div className="flex flex-row items-center">
            {isViewUp ? (
              <DownIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(false)} />
            ) : (
              <UpIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(true)} />
            )}
            <CloseIcon
              className="h-4 w-4"
              onClick={() => {
                if (!uploadState.isUploading && !uploadState.filesUploading && fileNames?.length) {
                  onCloseClick();
                  return;
                }
                setIsCancelOpen(true);
              }}
            />
          </div>
        </div>
        {isViewUp ? (
          <div className="text-primary divide-y-1 flex h-[308px] flex-col overflow-y-auto bg-white px-5">
            {!uploadState?.isUploading && triggeredBy === "project" && uploadCount > 0 ? (
              <div
                onClick={() => onDone()}
                className="flex min-h-[50px] cursor-pointer flex-row items-center justify-between text-sm"
              >
                Add details to files
              </div>
            ) : null}
            {Object.keys(fullUploadStatus || {}).map((fileName) => {
              return (
                <div key={fileName} className="flex min-h-[56px] flex-row items-center justify-between">
                  <div className="flex flex-row">
                    <span className="mr-3">
                      <ThumbnailIcon width={20} className="text-accent-700" />
                    </span>
                    <span className="text-sm">{truncate(fileName, { length: 34 })}</span>
                  </div>
                  {getFileStatus(fileName)}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default UploadFileWindow;
