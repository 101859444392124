import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { v4 as uuidv4 } from "uuid";
import useToast from "@src/utils/hooks/useToast";
import useMultiFilesUpload from "@src/features/files/hooks/useMultiFilesUpload";
import isMediaFile from "@src/utils/isMedia";
import { BA_FILE_TABLE_TYPES, PARTNER_DOCUMENT_TYPES } from "@src/utils/constants";
import useContacts from "../contacts/hooks/useContacts";
import useServices from "../services/hooks/useServices";
import { formSchema as partnersFormSchema } from "./formSchema";
import { uiSchema as partnersUiSchema } from "./uiSchema";
import useAddPartner from "./hooks/useAddPartner";
import useUpdatePartner from "./hooks/useUpdatePartner";

const AddActionObject = ({ onClose }) => {
  const { options: servicesAsOptions, isLoading: servicesLoading } = useServices();
  const { options: contactsAsOptions, isLoading: contactsLoading } = useContacts();
  const { createPartnerAsync } = useAddPartner();
  const { updatePartnerAsync } = useUpdatePartner();
  const { addToast } = useToast();
  const { startUpload } = useMultiFilesUpload();
  const [formSchema, setFormSchema] = useState(null);

  const handleSubmit = async (formState) => {
    let { coi, generalLiability, workersComp, w9, license } = formState;

    const documentsToUpload = [];

    if (coi.length) {
      coi = coi[0];
      documentsToUpload.push({
        docType: PARTNER_DOCUMENT_TYPES.COI,
        type: BA_FILE_TABLE_TYPES.DOCUMENT,
        file: coi,
      });
    }
    if (generalLiability.length) {
      generalLiability = generalLiability[0];
      documentsToUpload.push({
        docType: PARTNER_DOCUMENT_TYPES.GENERAL_LIABILITY,
        type: BA_FILE_TABLE_TYPES.DOCUMENT,
        file: generalLiability,
      });
    }
    if (workersComp.length) {
      workersComp = workersComp[0];
      documentsToUpload.push({
        docType: PARTNER_DOCUMENT_TYPES.WORKERS_COMPENSATION,
        type: BA_FILE_TABLE_TYPES.DOCUMENT,
        file: workersComp,
      });
    }
    if (w9.length) {
      w9 = w9[0];
      documentsToUpload.push({
        docType: PARTNER_DOCUMENT_TYPES.W9,
        type: BA_FILE_TABLE_TYPES.DOCUMENT,
        file: w9,
      });
    }

    if (license.length) {
      license = license[0];
      documentsToUpload.push({
        docType: PARTNER_DOCUMENT_TYPES.COMPANY_LICENSE,
        type: BA_FILE_TABLE_TYPES.DOCUMENT,
        file: license,
      });
    }

    const partnerData = {
      companyName: formState.companyName,
      addressLine1: formState.addressLine1,
      einNumber: formState.einNumber,
      website: formState.website,
      email: formState.email,
      logo: null,
      officePhone: formState.officePhone,
      city: formState.city,
      state: formState.state?.value,
      zipCode: formState.zipCode,
      contact: formState.checkBoxVal
        ? null
        : {
            firstName: formState.contactFirstName,
            lastName: formState.contactLastName,
            email: formState.contactEmail,
            phone: formState.contactPhone,
          },
      contactId: formState.checkBoxVal ? formState.contact?.value : null,
      serviceIds: formState?.services?.map((service) => service.value),
    };

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const createResp = await createPartnerAsync(partnerData);
    if (!createResp.createPartner?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Failed to create partner.");
      return;
    }

    if (documentsToUpload?.length) {
      const keyedList = documentsToUpload.map((doc) => ({
        ...doc,
        key: `partner-documents/${createResp.createPartner.data.id}/${uuidv4()}`,
        type: "document",
      }));
      const uploadResults = await startUpload(keyedList, false);
      const formatedDoc = uploadResults?.uploadedFiles.map((doc) => ({
        documentId: doc.id,
        title: doc.name,
        documentType: doc?.docType,
      }));
      if (uploadResults.uploadCount > 0) {
        await updatePartnerAsync({
          id: createResp.createPartner.data.id,
          partnerDocuments: formatedDoc,
          hasW9: formState?.w9.length ? true : false,
          hasCoi: formState?.coi.length ? true : false,
          hasWorkersComp: formState?.workersComp.length ? true : false,
          hasGeneralLiability: formState?.generalLiability.length ? true : false,
          hasLicense: formState?.license.length ? true : false,
        });
      }
    }

    if (formState.logo?.length) {
      let logo = [];
      const uploadResults = await uploadFiles(createResp.createPartner.data.id, formState.logo);
      const finalFilesList = uploadResults?.finalFilesList;
      if (finalFilesList?.length) {
        logo = finalFilesList.map((fileInfo) => fileInfo.key);
        await updatePartnerAsync({ id: createResp.createPartner.data.id, logo: logo[0] || "" });
        setFormSchema((prev) => ({
          ...prev,
          actionsState: {
            isLoading: false,
          },
        }));
        addToast("Partner Successfully Created");
        onClose();
      }
    } else {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Partner Successfully Created");
      onClose();
    }
  };

  const uploadFiles = async (partnerId, files, skipFileAdd = true) => {
    const finalFilesList = (files || []).map((file) => {
      const fileKey = `partners/${partnerId}/${uuidv4()}`;
      return {
        key: fileKey,
        type: isMediaFile(file.type) ? BA_FILE_TABLE_TYPES.MEDIA : BA_FILE_TABLE_TYPES.DOCUMENT,
        file,
      };
    });
    const results = await startUpload(finalFilesList, skipFileAdd);
    return { ...results, finalFilesList };
  };

  useEffect(() => {
    if (servicesLoading || contactsLoading) {
      return;
    }
    const finalSchema = {
      ...partnersFormSchema,
    };
    const allProperties = partnersFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "services") {
          acc[key].options = servicesAsOptions;
        }
        if (key === "checkBoxVal") {
          acc[key].formCondition.true.contact.options = contactsAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [servicesAsOptions, contactsAsOptions]);

  if (servicesLoading || contactsLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={partnersUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddActionObject;
