import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PURCHASES = gql`
  query getPurchases($filters: PurchaseFilterFields!) {
    getPurchases(filters: $filters) {
      id
      name
      vendorOrderNumber
      subTotal
      shippingTotal
      taxTotal
      total
      estimatedDelivery
      isInventory
      purchaseDate
      supplier {
        id
        name
      }
      documents {
        id
        uploadedAt
        name
        path
        fileType
        type
      }
      project {
        id
        places {
          id
          addressLine1
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      purchaseItems {
        id
        quantity
        internalCost
        extendedCost
        clientPrice
        isTaxable
        isOrdered
        hasArrived
        hasIssues
        arrivedOn
        total
        product {
          id
          name
          description
          sku
          media {
            id
            path
            name
            fileType
            sortOrder
            isThumbnail
            type
          }
          photos
          suppliers {
            id
            name
          }
        }
        deliveryGroup {
          id
          createdAt
          trackingNumber
          estimatedDeliveryAt
          name
        }
      }
      suppliersQuotes {
        id
        createdAt
        total
        shipping
      }
    }
  }
`;

const GET_PURCHASE = gql`
  query getPurchaseByID($id: Int!) {
    getPurchaseByID(id: $id) {
      id
      vendorOrderNumber
      subTotal
      shippingTotal
      taxTotal
      total
      estimatedDelivery
      isInventory
      purchaseDate
      supplier {
        id
        name
      }
      documents {
        id
        uploadedAt
        name
        path
        fileType
        type
      }
      project {
        id
        places {
          id
          addressLine1
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      purchaseItems {
        id
        quantity
        extendedCost
        clientPrice
        isTaxable
        isOrdered
        hasArrived
        purchase {
          id
          vendorOrderNumber
          subTotal
          shippingTotal
          taxTotal
          total
          estimatedDelivery
          isInventory
        }
      }
      suppliersQuotes {
        id
        createdAt
        total
        shipping
      }
    }
  }
`;

const CREATE_PURCHASE = gql`
  mutation createPurchase($input: PurchaseRequest!) {
    createPurchase(input: $input) {
      code
      success
      message
      data {
        id
        name
        vendorOrderNumber
        subTotal
        shippingTotal
        taxTotal
        total
        estimatedDelivery
        isInventory
        purchaseDate
        supplier {
          id
          name
        }
        documents {
          id
          uploadedAt
          name
          path
          fileType
          type
        }
        project {
          id
          places {
            id
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        purchaseItems {
          id
          quantity
          internalCost
          extendedCost
          clientPrice
          isTaxable
          isOrdered
          hasArrived
          total
          product {
            id
            name
            description
            sku
            media {
              id
              path
              name
              fileType
              sortOrder
              isThumbnail
              type
            }
            photos
            suppliers {
              id
              name
            }
          }
        }
        suppliersQuotes {
          id
          createdAt
          total
          shipping
        }
      }
    }
  }
`;

const UPDATE_PURCHASE = gql`
  mutation updatePurchase($input: PurchaseRequest!) {
    updatePurchase(input: $input) {
      code
      success
      message
      data {
        id
        name
        vendorOrderNumber
        subTotal
        shippingTotal
        taxTotal
        total
        estimatedDelivery
        isInventory
        purchaseDate
        supplier {
          id
          name
        }
        documents {
          id
          uploadedAt
          name
          path
          fileType
          type
        }
        project {
          id
          places {
            id
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        purchaseItems {
          id
          quantity
          internalCost
          extendedCost
          clientPrice
          isTaxable
          isOrdered
          hasArrived
          total
          product {
            id
            name
            description
            sku
            media {
              id
              path
              name
              fileType
              sortOrder
              isThumbnail
              type
            }
            photos
            suppliers {
              id
              name
            }
          }
        }
        suppliersQuotes {
          id
          createdAt
          total
          shipping
        }
      }
    }
  }
`;

const DELETE_PURCHASE = gql`
  mutation deletePurchase($id: Int!) {
    deletePurchase(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;
const GET_PURCHASE_ITEMS = gql`
  query getPurchaseItems($filters: PurchaseItemFilterFields!) {
    getPurchaseItems(filters: $filters) {
      id
      quantity
      internalCost
      extendedCost
      clientPrice
      isTaxable
      isOrdered
      hasArrived
      product {
        id
        name
      }
      purchase {
        id
        vendorOrderNumber
        subTotal
        shippingTotal
        taxTotal
        total
        estimatedDelivery
        isInventory
        purchaseDate
        supplier {
          id
          name
        }
        documents {
          id
          name
          path
          fileType
        }
        project {
          id
          places {
            id
            addressLine1
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        suppliersQuotes {
          id
          total
          shipping
        }
      }
    }
  }
`;
const CREATE_PURCHASE_ITEM = gql`
  mutation createPurchaseItem($input: PurchaseItemRequest!) {
    createPurchaseItem(input: $input) {
      code
      success
      message
      data {
        id
        quantity
        internalCost
        extendedCost
        clientPrice
        isTaxable
        isOrdered
        hasArrived
        product {
          id
          name
        }
        purchase {
          id
          vendorOrderNumber
          subTotal
          shippingTotal
          taxTotal
          total
          estimatedDelivery
          isInventory
          purchaseDate
          supplier {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
          }
          project {
            id
            places {
              id
              addressLine1
              city
              state
              zipCode
            }
            projectStages {
              id
              name
              stageType
            }
          }
          suppliersQuotes {
            id
            total
            shipping
          }
        }
      }
    }
  }
`;
const UPDATE_PURCHASE_ITEM = gql`
  mutation updatePurchaseItem($input: PurchaseItemRequest!) {
    updatePurchaseItem(input: $input) {
      code
      success
      message
      data {
        id
        quantity
        internalCost
        extendedCost
        clientPrice
        isTaxable
        isOrdered
        hasArrived
        product {
          id
          name
        }
        purchase {
          id
          vendorOrderNumber
          subTotal
          shippingTotal
          taxTotal
          total
          estimatedDelivery
          isInventory
          purchaseDate
          supplier {
            id
            name
          }
          documents {
            id
            name
            path
            fileType
          }
          project {
            id
            places {
              id
              addressLine1
              city
              state
              zipCode
            }
            projectStages {
              id
              name
              stageType
            }
          }
          suppliersQuotes {
            id
            total
            shipping
          }
        }
      }
    }
  }
`;
const DELETE_PURCHASE_ITEM = gql`
  mutation deletePurchaseItem($id: Int!) {
    deletePurchaseItem(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;
export const fetchPurchases = async (filters = {}) => {
  return await graphQLClient.request(GET_PURCHASES, { filters });
};

export const createPurchase = async (input) => {
  return await graphQLClient.request(CREATE_PURCHASE, { input });
};

export const updatePurchase = async (input) => {
  return await graphQLClient.request(UPDATE_PURCHASE, { input });
};

export const deletePurchase = async (id) => {
  return await graphQLClient.request(DELETE_PURCHASE, { id });
};

export const fetchPurchaseItems = async (filters = {}) => {
  return await graphQLClient.request(GET_PURCHASE_ITEMS, { filters });
};

export const createPurchaseItem = async (input) => {
  return await graphQLClient.request(CREATE_PURCHASE_ITEM, { input });
};

export const updatePurchaseItem = async (input) => {
  return await graphQLClient.request(UPDATE_PURCHASE_ITEM, { input });
};

export const deletePurchaseItem = async (id) => {
  return await graphQLClient.request(DELETE_PURCHASE_ITEM, { id });
};

export const fetchPurchase = async (id) => {
  return await graphQLClient.request(GET_PURCHASE, { id });
};
