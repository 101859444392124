import React, { useEffect, useState } from "react";
import { Modal, Button, TextField } from "@buildappeal/react-component-library";

const FileRenameModal = ({ isOpen, onClose, onRename, fileName }) => {
  const [fileNameInput, setFileNameInput] = useState(fileName);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setFileNameInput(fileName);
  }, [fileName]);

  const handleRename = async () => {
    try {
      setIsLoading(true);
      await onRename?.("name", fileNameInput);
      onClose();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Rename file">
      <div className="p-10">
        <TextField value={fileNameInput} onChange={(e) => setFileNameInput(e.target.value)} label="File name" />

        {error && <p className="my-3 text-xs text-red-700">{error}</p>}
        <div className="mt-7 flex items-center space-x-2">
          <Button
            label={isLoading ? "Loading ..." : "Save"}
            onClick={() => handleRename(fileNameInput)}
            disabled={isLoading}
          />
          <Button label="Cancel" onClick={() => onClose()} appearance="tertiary" />
        </div>
      </div>
    </Modal>
  );
};

export default FileRenameModal;
