import { useMemo } from "react";
import { useQuery } from "react-query";
import { fetchDesignStyles } from "../../api";

const useDesignStyles = (filters = {}, hookOptions = { disableQuery: false }) => {
  const { data, isLoading, refetch } = useQuery(["designStyles", filters], () => fetchDesignStyles(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const designStylesAsOptions = useMemo(() => {
    if (!data?.getAllDesignStyle?.length) {
      return [];
    }

    return data?.getAllDesignStyle
      .map((designStyle) => {
        return {
          title: designStyle.name,
          value: designStyle.id,
          designStyle,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [data?.getAllDesignStyle]);

  return {
    refetch,
    data: data?.getAllDesignStyle,
    isLoading,
    designStylesLoading: isLoading,
    options: designStylesAsOptions,
  };
};

export default useDesignStyles;
