import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_OBJECTS = gql`
  query getObjects($filters: ObjectFilterFields!) {
    getObjects(filters: $filters) {
      id
      createdAt
      description
      icon
      objectType
      media {
        id
        name
        fileType
        path
        type
        isThumbnail
        sortOrder
      }
      documents {
        id
        name
        fileType
        path
        type
      }
      parent {
        id
        description
      }
    }
  }
`;

const GET_DT_OBJECTS = gql`
  query getObjects($filters: ObjectFilterFields!) {
    getObjects(filters: $filters) {
      id
      createdAt
      description
      icon
      objectType
      attributesOrder
      spaceSortOrder {
        spaceId
        sortOrder
      }
      media {
        id
        name
        fileType
        path
        type
        isThumbnail
        sortOrder
      }
      documents {
        id
        name
        fileType
        path
        type
      }
      parent {
        id
        description
        sortOrder
      }
      attributesv2 {
        id
        name
        icon
        type
      }
      materials {
        id
        name
        icon
        media {
          id
          name
          fileType
          path
          type
          isThumbnail
          sortOrder
        }
      }
      finishes {
        id
        name
        icon
        media {
          id
          name
          fileType
          path
          type
        }
      }
      colors {
        id
        name
        icon
        colorValue
        parentId
      }
      products {
        id
        name
      }
    }
  }
`;

const GET_OBJECT_BY_ID = gql`
  query getObjectByID($id: Int!) {
    getObjectByID(id: $id) {
      id
      createdAt
      description
      icon
      objectType
      media {
        id
        name
        fileType
        path
        type
        isThumbnail
        sortOrder
      }
      documents {
        id
        name
        fileType
        path
        type
      }
      parent {
        id
        description
      }
    }
  }
`;

const ADD_OBJECT = gql`
  mutation addObject($input: CreateObjectRequest!) {
    addObject(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        icon
        objectType
        media {
          id
          name
          fileType
          path
          type
          isThumbnail
          sortOrder
        }
        documents {
          id
          name
          fileType
          path
          type
        }
        parent {
          id
          description
        }
      }
    }
  }
`;

const UPDATE_OBJECT = gql`
  mutation updateObject($input: UpdateObjectRequest!) {
    updateObject(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        icon
        objectType
        media {
          id
          name
          fileType
          path
          type
          isThumbnail
          sortOrder
        }
        documents {
          id
          name
          fileType
          path
          type
        }
        parent {
          id
          description
        }
      }
    }
  }
`;
const DELETE_OBJECT = gql`
  mutation deleteObject($id: Int!) {
    deleteObject(id: $id) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

export const fetchObjects = async (filters = {}) => {
  return await graphQLClient.request(GET_OBJECTS, { filters });
};

export const fetchObjectById = async (id) => {
  return await graphQLClient.request(GET_OBJECT_BY_ID, { id });
};

export const addObject = async (input = {}) => {
  return await graphQLClient.request(ADD_OBJECT, { input });
};

export const updateObject = async (input = {}) => {
  return await graphQLClient.request(UPDATE_OBJECT, { input });
};

export const deleteObject = async (id) => {
  return await graphQLClient.request(DELETE_OBJECT, { id });
};

export const fetchDTObjects = async (filters = {}) => {
  return await graphQLClient.request(GET_DT_OBJECTS, { filters });
};
