import { LayoutGroup } from "@buildappeal/react-component-library";
import { useEffect, useState } from "react";
import FileCardItem from "../FileCardItem";

const GroupedFileCards = ({
  title,
  files,
  className,
  handleContextMenu,
  allGroupCollapsed,
  allGroupExpanded,
  ...rest
}) => {
  const [open, setOpen] = useState(allGroupExpanded);

  useEffect(() => {
    if (allGroupCollapsed) {
      setOpen(false);
    }

    if (allGroupExpanded) {
      setOpen(true);
    }
  }, [allGroupCollapsed, allGroupExpanded]);
  return (
    <div className="border-b border-neutral-100 py-4">
      <LayoutGroup title={title} isOpen={open} onExpand={setOpen}>
        <div className={className}>
          {files.map((file) => (
            <div
              onContextMenu={(event) => {
                handleContextMenu(event, file);
              }}
              key={file?.id}
              className="file-item"
              data-key={file?.id}
            >
              <FileCardItem key={file.id} file={file} {...rest} />
            </div>
          ))}
        </div>
      </LayoutGroup>
    </div>
  );
};

export default GroupedFileCards;
