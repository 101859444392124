import { useMemo } from "react";
import { useQuery } from "react-query";
import { fetchPartners } from "../../api";

const usePartners = (filters = {}, hookOptions = { disableQuery: false }) => {
  const { data, isLoading } = useQuery(["partners", filters], () => fetchPartners(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  const partnersAsOptions = useMemo(() => {
    if (!data?.getPartners?.length) {
      return [];
    }

    return data?.getPartners
      ?.map((partnerEntry) => {
        return {
          value: partnerEntry.id,
          title: partnerEntry.companyName,
          partner: partnerEntry,
        };
      })
      ?.sort((a, b) => a?.title?.localeCompare(b?.title));
  }, [data]);

  return {
    data: data?.getPartners,
    isLoading,
    options: partnersAsOptions,
  };
};

export default usePartners;
