import { TableCells, TableFilters, NavCard, TableCards } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const STATUS_COLOR_CODES = {
  "Awaiting Approval": "bg-orange-500",
  Approved: "bg-green-500",
  Draft: "bg-gray-500",
};

export const proposalColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={`Proposal #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          title={`Proposal #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  createdAt: {
    id: "created",
    Header: "Created Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  description: {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  dateSent: {
    id: "dateSent",
    Header: "Sent Date",
    accessor: "dateSent",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  dueDate: {
    id: "dueDate",
    Header: "Due Date",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  dateAccepted: {
    id: "dateAccepted",
    Header: "Accepted Date",
    accessor: "dateAccepted",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: ({ row: { original }, table, getValue }) => {
      return (
        <TableCells.StatusCell
          status={getValue()}
          circleColor={STATUS_COLOR_CODES[getValue()] || "bg-gray-500"}
          show
          table={table}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TextRow,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: ({ row: { original }, table, getValue }) => {
      const { proposalItems = [] } = original;
      const total = proposalItems.reduce((acc, item) => acc + item.cost, 0);
      return <TableCells.CurrencyCell getValue={() => total || ""} table={table} />;
    },
    renderCard: TableCards.PriceRow,
  },
};
