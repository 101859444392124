import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { updateProducts } from "../../api";
import { updateMultiBatchedProducts } from "../../productsSlice";

const useUpdateProducts = (filters = {}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [error, setError] = useState();

  const updateProductsMutation = useMutation(
    (input) => {
      setError(null);
      return updateProducts(input);
    },
    {
      onSuccess: (data) => {
        if (!data.updateProducts?.success) {
          setError(`Error: ${data.updateProducts?.message}`);
        } else {
          queryClient.setQueryData(["products", filters], (old) => {
            if (!old) return { getProducts: [...(data.updateProducts.data || [])] };
            return {
              getProducts: old.getProducts?.map((product) => {
                const updatedProduct = data.updateProducts?.data?.find((p) => p.id === product.id);
                if (updatedProduct) {
                  return {
                    ...product,
                    ...updatedProduct,
                  };
                }
                return product;
              }),
            };
          });
          dispatch(updateMultiBatchedProducts(data.updateProducts?.data));
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    updateProducts: updateProductsMutation.mutate,
    updateProductsAsync: updateProductsMutation.mutateAsync,
    updateProductsLoading: updateProductsMutation.isLoading,
    updateProductsSuccess: updateProductsMutation.isSuccess,
  };
};

export default useUpdateProducts;
