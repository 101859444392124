import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  estimateSelectionsByProjectId: {},
  estimateSelections: [],
  selectionLoadingState: null,
  /**  FIFO queue of updates to be sent to the Backend
   *   @type { Array<{
   *    updateType: OneOf["upsertEstimateSelectionObject", "updateProductQuantity"];
   *    input: Object<upsertEstimateSelectionObject> | Object<updateProductQuantity>
   *    }>}
   * */
  updateActionsQueue: [],
};

export const estimateSelectionsSlice = createSlice({
  name: "estimateSelections",
  initialState,
  reducers: {
    setEstimateSelections: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        estimateSelections: action.payload,
      };
    },
    updateEstimateSelections: (state, action) => {
      // action.payload: Array< {...<estimate_selections_props} >
      const originalState = original(state);
      const { estimateSelectionsByProjectId } = originalState;
      const updatedEstimateSelections = { ...(estimateSelectionsByProjectId || {}) };

      action.payload.forEach((estimateSelection) => {
        const { projectId } = estimateSelection;
        updatedEstimateSelections[projectId] = estimateSelection;
      });
      return {
        ...originalState,
        estimateSelectionsByProjectId: {
          ...updatedEstimateSelections,
        },
      };
    },
    updateEstimateSelectionsObject: (state, action) => {
      // action.payload: Object<{ projectId: Int, estimateSelectionsObject: {...<estimate_selections_objects_props} }>
      const originalState = original(state);
      const { estimateSelectionsByProjectId } = originalState;
      const { projectId, estimateSelectionsObject } = action.payload;
      const updatedESByProjectId = { ...(estimateSelectionsByProjectId?.[projectId] || {}) };
      const isExistingObject = updatedESByProjectId.objects?.find((obj) => obj.id === estimateSelectionsObject.id);
      if (isExistingObject) {
        updatedESByProjectId.objects = updatedESByProjectId.objects.map((obj) => {
          if (obj.id === estimateSelectionsObject?.id) {
            return estimateSelectionsObject;
          }
          return obj;
        });
      } else {
        updatedESByProjectId.objects = [...(updatedESByProjectId.objects || []), estimateSelectionsObject];
      }

      return {
        ...originalState,
        estimateSelectionsByProjectId: {
          ...updatedESByProjectId,
          [projectId]: {
            ...updatedESByProjectId,
          },
        },
      };
    },
    updateESObjectProductQty: (state, action) => {
      // action.payload = Object<{ projectId: Int, estimateSelectionsObjectId: String, objectId: Int, qty: Int }>
      const originalState = original(state);
      const { estimateSelectionsByProjectId } = originalState;
      const { projectId, estimateSelectionsObjectId, quantity } = action.payload;
      const updatedESByProjectId = { ...(estimateSelectionsByProjectId?.[projectId] || {}) };

      updatedESByProjectId.objects = updatedESByProjectId.objects.map((obj) => {
        if (obj.id === estimateSelectionsObjectId) {
          return { ...obj, products: { ...obj.products, quantity } };
        }
        return obj;
      });
      return {
        ...originalState,
        estimateSelectionsByProjectId: {
          ...updatedESByProjectId,
          [projectId]: {
            ...updatedESByProjectId,
          },
        },
      };
    },
    removeEstimateSelectionObject: (state, action) => {
      // action.payload = {id: BigInt, projectId: Int}
      const originalState = original(state);
      const { estimateSelectionsByProjectId } = originalState;
      const { id, projectId } = action.payload;
      const updatedESByProjectId = { ...(estimateSelectionsByProjectId?.[projectId] || {}) };
      updatedESByProjectId.objects = updatedESByProjectId.objects.filter((obj) => obj.id !== id);
      return {
        ...originalState,
        estimateSelectionsByProjectId: {
          ...updatedESByProjectId,
          [projectId]: {
            ...updatedESByProjectId,
          },
        },
      };
    },
    pushToUpdateQueue: (state, action) => {
      const originalState = original(state);
      const { updateActionsQueue } = originalState;
      return {
        ...originalState,
        updateActionsQueue: [...updateActionsQueue, action.payload],
      };
    },
  },
});

export const {
  setEstimateSelections,
  updateEstimateSelections,
  updateEstimateSelectionsObject,
  updateESObjectProductQty,
  removeEstimateSelectionObject,
  pushToUpdateQueue,
} = estimateSelectionsSlice.actions;

export default estimateSelectionsSlice.reducer;

export const getEstimateSelections = (state) => state.estimateSelections.estimateSelectionsByProjectId;
export const getEstimateSelectionsForProjectId = (projectId) => (state) =>
  state.estimateSelections.estimateSelectionsByProjectId?.[projectId] || null;
