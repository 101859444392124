import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createPurchase } from "../../api";

const useAddPurchase = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addPurchaseMutation = useMutation(
    (input) => {
      return createPurchase(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createPurchase.success) {
          setError(`Error: ${data.createPurchase?.message}`);
        } else {
          queryClient.setQueryData(["purchases", filters], (old) => {
            return { getPurchases: [data.createPurchase.data, ...old?.getPurchases] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addPurchase: addPurchaseMutation.mutate,
    addPurchaseAsync: addPurchaseMutation.mutateAsync,
    addPurchaseLoading: addPurchaseMutation.isLoading,
    addPurchaseSuccess: addPurchaseMutation.isSuccess,
    error,
  };
};

export default useAddPurchase;
