import { TableCells, TableCards, NavCard } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const commonColumnsPurchases = [
  {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={`Purchase #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          title={`Purchase #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "2fr",
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  {
    id: "purchaseDate",
    Header: "Purchase Date",
    accessor: "purchaseDate",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  {
    id: "estimatedDelivery",
    Header: "Estimated Delivery",
    accessor: "estimatedDelivery",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
  {
    id: "vendorOrderNumber",
    Header: "Vendor Order Number",
    accessor: "vendorOrderNumber",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
];

export const columns = [...commonColumnsPurchases];

export const commonColumnsPurchaseItems = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "purchaseId",
    Header: "Purchase ID",
    accessor: (row) => row.purchase?.id,
    Cell: TableCells.TextCell,
  },
  {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  {
    id: "product",
    Header: "Product",
    accessor: (row) => row.product?.name,
    Cell: TableCells.TextCell,
  },
  {
    id: "costPerItem",
    Header: "Cost Per Item",
    accessor: "costPerItem",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "extendedCost",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];
export const columnsPurchaseItems = [...commonColumnsPurchaseItems];
columnsPurchaseItems.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.purchase?.project?.places?.addressLine1
      ? `${originalRow.purchase?.project?.places?.addressLine1} ${
          originalRow.purchase?.project?.places?.addressLine2 || ""
        }`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original?.purchase?.project?.places?.city}
        addressState={originalRow.row.original?.purchase?.project?.places?.state}
        addressZip={originalRow.row.original?.purchase?.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const handleFloatValues = (value) => {
  if (!value || isNaN(value)) {
    return undefined;
  }
  return parseFloat(value);
};

export const loadingPlaceholderPurchase = {
  id: "",
  name: null,
  vendorOrderNumber: "",
  subTotal: "",
  shippingTotal: "",
  taxTotal: "",
  total: "",
  estimatedDelivery: "",
  isInventory: "",
  documents: [],
  project: {
    id: "",
    places: {
      id: "",
      addressLine1: "",
      city: "",
      state: "",
      zipCode: "",
    },
    thumbnail: {
      id: "",
      path: "",
    },
  },
  purchaseItems: [],
  suppliersQuotes: "",
};
