import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_SERVICES = gql`
  query getServices($filters: ServiceFilterFields!) {
    getServices(filters: $filters) {
      id
      name
      parentId
      unitType
      baseLaborCost
      permit
      code
      spaces {
        id
        spaceId
        baseCost
      }
      markets {
        id
        name
      }
      parent {
        id
        name
      }
    }
  }
`;

const GET_SERVICE_BY_ID = gql`
  query getServiceByID($id: Int!) {
    getServiceByID(id: $id) {
      id
      name
      parentId
      unitType
      baseLaborCost
      permit
      code
      spaces {
        id
        spaceId
        baseCost
      }
      markets {
        id
        name
      }
      parent {
        id
        name
      }
      partners {
        id
        status
        email
        mobilePhone
        officePhone
        avatar
        addressLine1
        city
        state
        zipCode
        companyName
        officePhone
        email
        status
      }
    }
  }
`;

const CREATE_SERVICE = gql`
  mutation createService($input: CreateServiceInput!) {
    createService(input: $input) {
      code
      success
      message
      data {
        id
        name
        parentId
        createdAt
        parent {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_SERVICE = gql`
  mutation updateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      code
      success
      message
      data {
        id
        name
        parentId
        createdAt
        parent {
          id
          name
        }
      }
    }
  }
`;

const DELETE_SERVICE = gql`
  mutation deleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

export const fetchServices = async (filters) => {
  return await graphQLClient.request(GET_SERVICES, { filters });
};

export const fetchService = async (id) => {
  return await graphQLClient.request(GET_SERVICE_BY_ID, { id });
};

export const createService = async (input) => {
  return await graphQLClient.request(CREATE_SERVICE, { input });
};

export const updateService = async (input) => {
  return await graphQLClient.request(UPDATE_SERVICE, { input });
};

export const deleteService = async (input) => {
  return await graphQLClient.request(DELETE_SERVICE, { input });
};
