import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createPartner } from "../../api";

const useAddPartner = () => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      return createPartner(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createPartner.success) {
          setError(`${data?.createPartner?.message || "Failed to create partner"}`);
        } else {
          setIsSuccess(true);
          queryClient.setQueryData("partners", (old) => {
            if (!old) return { getPartners: [data.createPartner.data] };
            return { getPartners: [data.createPartner.data, ...old.getPartners] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    createPartner: mutate,
    isLoading,
    isSuccess,
    error,
    createPartnerAsync: mutateAsync,
  };
};

export default useAddPartner;
