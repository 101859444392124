import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";
import AlgoliaTabFilters from "@src/utils/components/AlgoliaComponents/TabFilters";
import { transformProductCurrentRefinementItems } from "./utils";

const ProductFilters = ({ hideTabFilters, resetToDefault }) => {
  return (
    <div className="mb-3 space-y-4">
      <AlgoliaTabFilters
        items={[
          {
            id: "base",
            label: "Base",
            value: "isVariant:false",
          },
          {
            id: "variants",
            label: "Variants",
            value: "isVariant:true",
          },
          { id: "all", label: "All", value: "" },
        ]}
        className={hideTabFilters ? "hidden" : ""}
        defaultSelected={hideTabFilters && resetToDefault ? "base" : null}
      />
      <div className="my-5 flex flex-wrap items-center gap-2.5">
        <DynamicFacetsList>
          <AlgoliaFacetFilter attribute="objects" label="Type" searchable />
          <AlgoliaFacetFilter attribute="suppliers.name" label="Supplier" searchable />
          <AlgoliaFacetFilter attribute="attributes" label="Attribute" searchable />
          <AlgoliaFacetFilter attribute="colors" label="Color" searchable />
          <AlgoliaFacetFilter attribute="designStyles" label="Design Style" searchable />
          <AlgoliaFacetFilter attribute="finishes" label="Finish" searchable />
          <AlgoliaFacetFilter attribute="materials" label="Material" searchable />
          <AlgoliaFacetFilter attribute="spaces" label="Space" searchable />
          <AlgoliaFacetFilter attribute="brand" label="Brand" searchable />
        </DynamicFacetsList>
      </div>
      <CurrentRefinements transformItems={transformProductCurrentRefinementItems} />
    </div>
  );
};

export default ProductFilters;
