import { TableCells, TableFilters, TableCards } from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Pending: "orange",
  "In Progress": "orange",
  Draft: "gray",
  Paid: "green",
};

export const commonColumnsBudgets = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "estimatedTotal",
    Header: "Estimated Amount",
    accessor: "estimatedTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  {
    id: "daysToComplete",
    Header: "Days to Complete",
    accessor: "daysToComplete",
    Cell: TableCells.TextCell,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
];

export const columns = [...commonColumnsBudgets];
columns.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.project?.places?.addressLine1
      ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original.project?.places?.city}
        addressState={originalRow.row.original.project?.places?.state}
        addressZip={originalRow.row.original.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const commonColumnsBudgetItems = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "budgetId",
    Header: "Budget ID",
    accessor: "budget.id",
    Cell: TableCells.TextCell,
  },
  {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  {
    id: "estimatedAmount",
    Header: "Estimated Amount",
    accessor: "estimatedAmount",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];
export const columnsBudgetItems = [...commonColumnsBudgetItems];
columnsBudgetItems.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.budget?.project?.places?.addressLine1
      ? `${originalRow.budget?.project?.places?.addressLine1} ${
          originalRow.budget?.project?.places?.addressLine2 || ""
        }`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original?.budget?.project?.places?.city}
        addressState={originalRow.row.original?.budget?.project?.places?.state}
        addressZip={originalRow.row.original?.budget?.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const BudgetStatusOptions = ["Draft", "Pending", "In Progress", "Cancelled"];

export const loadingPlaceholderBudget = {
  id: "",
  createdAt: "",
  daysToComplete: "",
  estimatedTotal: "",
  status: "",
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
    thumbnail: {
      id: "",
      path: "",
    },
  },
};
