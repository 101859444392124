import React from "react";

import { initialState, ToastContext, toastReducer } from "./ToastContext";

const ToastProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(toastReducer, initialState);

  return <ToastContext.Provider value={{ state, dispatch }}>{children}</ToastContext.Provider>;
};

export default ToastProvider;
