import { useEffect, useState } from "react";
import { TableCells } from "@buildappeal/react-component-library";
import { updateFilesWithURL } from "../files/utils";

const TaskMediaCell = ({ row, column, isLoading, files }) => {
  const [filesWithUrl, setFilesWithUrl] = useState([]);

  useEffect(() => {
    if (files?.length > 0) {
      updateFilesWithURL(files).then((filesWithUrlUpdated) => {
        setFilesWithUrl(filesWithUrlUpdated);
      });
    }
  }, [files]);

  return (
    <TableCells.ImageListCell
      imgList={filesWithUrl}
      fallbackMessage=""
      row={row}
      column={column}
      isLoading={isLoading}
    />
  );
};

export default TaskMediaCell;
