import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import useToast from "@src/utils/hooks/useToast";
import { updateThumbnail } from "../../api";
import { getFiles } from "../../filesSlice";

const useUpdateThumbnail = ({
  projectId,
  fileType,
  spaceId,
  createdBy,
  contentType,
  skipQueryUpdate = false,
  projectSpacesId,
  ids,
  noSpaces = false,
} = {}) => {
  const files = useSelector(getFiles);
  const queryClient = useQueryClient();
  const [error, setError] = useState();
  const { addToast } = useToast();
  const updateThumbnailMutation = useMutation(
    (input) => {
      return updateThumbnail(input);
    },
    {
      onSuccess: (data) => {
        if (!data?.updateThumbnail.success) {
          setError(`Error: ${data?.updateThumbnail?.message}`);
        } else {
          if (skipQueryUpdate) {
            return;
          }
          queryClient.setQueryData(
            ["files", projectId, fileType, spaceId, createdBy, contentType, projectSpacesId, ids, noSpaces],
            (old) => {
              return {
                ...old,
                pages: old.pages.map((page) => {
                  return {
                    getFilesInfo: {
                      ...page.getFilesInfo,
                      files: page.getFilesInfo?.files?.map((file) => {
                        if (file.id === data?.updateThumbnail?.data?.id) {
                          return {
                            ...file,
                            ...data?.updateThumbnail.data,
                          };
                        }
                        return file;
                      }),
                    },
                  };
                }),
              };
            }
          );
          addToast("thumbnail successfully updated");
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    files,
    error,
    updateThumbnail: updateThumbnailMutation.mutate,
    updateThumbnailAsync: updateThumbnailMutation.mutateAsync,
    updateFileLoading: updateThumbnailMutation.isLoading,
    updateFileSuccess: updateThumbnailMutation.isSuccess,
  };
};

export default useUpdateThumbnail;
