import { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";

import useProjectStages from "@src/features/actionsObjects/hooks/useProjectStages";

const useStagesData = (currentPath) => {
  const { data, isLoading } = useProjectStages(
    {},
    {
      disableQuery: currentPath !== "/projects" && currentPath !== "/leads",
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchInterval: Infinity,
    }
  );

  const [finalData, setFinalData] = useState(null);

  useEffect(() => {
    if (!data) return;

    if (currentPath === "/leads") {
      const finalData =
        orderBy(data, "order")
          ?.filter((item) => item.stageType === "Lead")
          ?.map((item) => item.name) || [];
      setFinalData(finalData);
    }
    if (currentPath === "/projects") {
      const finalData =
        orderBy(data, "order")
          ?.filter((item) => item.stageType !== "Lead")
          ?.map((item) => item.name) || [];
      setFinalData(finalData);
    }
  }, [currentPath, data]);

  return {
    data: finalData,
    isLoading,
  };
};

export default useStagesData;
