import { useMemo, useState, useEffect } from "react";
import { Button, SelectSimple, TextField } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";

import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";

const CreateProductVariant = ({ selectedItems = [], onSuccess }) => {
  const [selectedParentProduct, setSelectedParentProduct] = useState(selectedItems?.[0]);
  const [baseProductName, setBaseProductName] = useState("");
  const [variantInputs, setVariantInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { setActionsState } = useGeneralActionsUpdater();

  const finalProductOptions = useMemo(() => {
    if (!selectedItems.length) return [];
    return selectedItems.map((item) => ({
      value: item.id,
      title: `${item.id} - ${item.name}`,
      product: item,
    }));
  }, [selectedItems]);

  useEffect(() => {
    if (!selectedItems.length || Object.keys(variantInputs || {}).length) {
      return;
    }
    const newVariantInputs = {};
    selectedItems.forEach((item) => {
      newVariantInputs[item.id] = { name: "", variantProduct: item };
    });
    setVariantInputs(newVariantInputs);
  }, [selectedItems]);

  useEffect(() => {
    if (selectedParentProduct?.id && selectedParentProduct?.name !== baseProductName) {
      setBaseProductName(selectedParentProduct.name);
    }
  }, [selectedParentProduct]);

  const handleVariantCreate = async () => {
    setIsLoading(true);
    const finalVariantInput = Object.keys(variantInputs)
      .map((itemId) => {
        const item = variantInputs[itemId];
        if (!item.name) return null;

        return {
          name: item.name,
          variantProductId: item.variantProduct?.id,
          cost: item.variantProduct?.cost,
          vendorURL: item.variantProduct?.vendorURL || selectedParentProduct?.vendorURL,
          sku: item.variantProduct?.sku,
          description: item.variantProduct?.description,
          baseProductId: selectedParentProduct.id,
          baseProductName,
        };
      })
      .filter(Boolean);
    const finalItemInputs = [];
    finalVariantInput.forEach((input) => {
      finalItemInputs.push({
        input: input,
        id: input.name,
        name: baseProductName + " variant " + input.name,
      });
    });
    setActionsState({
      isOpen: true,
      currentAction: "requestsTracker",
      triggeredBy: "productVariantsCreate",
      actionProps: {
        itemsInput: finalItemInputs,
        onSuccess,
      },
    });
    setIsLoading(false);
    onSuccess();
  };

  return (
    <div className="position-relative flex w-full flex-col p-10">
      {isLoading ? (
        <div className="pt-15 absolute top-0 left-0 z-10 flex h-full w-full justify-center bg-slate-100 bg-opacity-60 pt-40">
          <SpinnerIcon role="status" className="mr-2 mt-[200px] h-10 w-10 animate-spin fill-primary-700" />
        </div>
      ) : null}
      <SelectSimple
        label="Select Parent Product"
        option={{
          title: selectedParentProduct?.name || "",
          value: selectedParentProduct?.id || "",
        }}
        options={finalProductOptions}
        onChange={(opt) => {
          setSelectedParentProduct(opt?.product);
        }}
      />
      <TextField
        label="Updated Base Product Name"
        className="mt-4"
        value={baseProductName || ""}
        onChange={({ target: { value } }) => setBaseProductName(value)}
      />
      {selectedItems?.map((item, index) => {
        return (
          <div className="flex w-full flex-col">
            <span>Variant Details {index + 1}</span>
            <TextField
              label="Variant Name"
              className="mt-4"
              value={variantInputs?.[item.id]?.name || ""}
              onChange={({ target: { value } }) =>
                setVariantInputs((prev) => ({ ...prev, [item.id]: { ...prev[item.id], name: value } }))
              }
            />
            <SelectSimple
              label="Select Variant Product"
              className="mt-2"
              option={{
                title: variantInputs?.[item.id]?.variantProduct?.name || "",
                value: variantInputs?.[item.id]?.variantProduct?.id || "",
              }}
              options={finalProductOptions}
              onChange={(opt) => {
                setVariantInputs((prev) => ({
                  ...prev,
                  [item.id]: { ...prev[item.id], variantProduct: opt?.product },
                }));
              }}
            />
          </div>
        );
      })}
      <Button className="mt-10" label="Create Variants" onClick={handleVariantCreate} loading={isLoading} />
    </div>
  );
};

export default CreateProductVariant;
