import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { UilMultiply as CloseIcon } from "@iconscout/react-unicons";
import { Button, CurrencyField, IconButton, Select, TextField, URLField } from "@buildappeal/react-component-library";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useToast from "@src/utils/hooks/useToast";
import useSuppliers from "@src/features/suppliers/hooks/useSuppliers";
import useProductTypes from "./hooks/useProductTypes";
import { setBatchedProducts } from "./productsSlice";
import { createProduct } from "./api";

const initialForm = {
  name: "",
  sku: "",
  vendorURL: "",
  cost: "",
  objects: [],
  suppliers: [],
};

const AddProductInline = ({ onClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { options: productTypeOptions } = useProductTypes();
  const { suppliersAsOptions } = useSuppliers();
  const { addToast } = useToast();

  const [formState, setFormState] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(false);

  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));
  };

  const isFormValid = () => {
    if (!formState?.name?.length) {
      return false;
    }
    if (!formState?.sku?.length) {
      return false;
    }
    return true;
  };

  const { mutate } = useMutation(
    (formState) => {
      return createProduct(formState);
    },
    {
      onSuccess: async (data) => {
        if (!data?.createProduct?.success) {
          return;
        }

        queryClient.setQueryData(["products", {}], (old) => {
          return {
            getProducts: [data.createProduct.data, ...(old?.getProducts || [])],
          };
        });
        dispatch(setBatchedProducts([data.createProduct.data]));
        setFormState(initialForm);

        searchParams.set("sorting__added", "desc");
        setSearchParams(searchParams);

        addToast("Product Successfully Created");
        setIsLoading(false);
      },
    }
  );

  const onSubmit = async () => {
    if (!isFormValid()) {
      return;
    }
    const finalFormState = {
      ...formState,
      suppliers: formState.suppliers?.map((supplier) => supplier.title),
      cost: parseFloat(formState.cost),
      objects: formState.objects?.map((object) => object.value),
    };

    setIsLoading(true);
    mutate(finalFormState);
  };

  return (
    <div className="flex items-center gap-x-3">
      <IconButton color="white" icon={CloseIcon} onClick={onClose} />
      <div className="grid grid-cols-6 gap-3">
        <TextField
          label="Name"
          value={formState.name}
          onChange={({ target: { value } }) => setFormValue({ name: value })}
        />
        <TextField
          label="SKU"
          value={formState.sku}
          onChange={({ target: { value } }) => setFormValue({ sku: value })}
        />
        <URLField
          value={formState.vendorURL}
          onChange={({ target: { value } }) => setFormValue({ vendorURL: value })}
        />
        <CurrencyField
          label="Price"
          value={formState.cost}
          onChange={({ target: { value } }) => setFormValue({ cost: value })}
        />
        <Select
          label="Type"
          value={formState.objects}
          options={productTypeOptions}
          onChange={(value) => {
            setFormValue({ objects: value });
          }}
          renderResult={() => null}
          multiple
        />
        <Select
          label="Supplier"
          value={formState.suppliers}
          options={suppliersAsOptions}
          onChange={(value) => {
            setFormValue({ suppliers: value });
          }}
          renderResult={() => null}
          multiple
        />
      </div>
      <Button label="Create" onClick={onSubmit} size="sm" isLoading={isLoading} className="w-[115px] flex-shrink-0" />
    </div>
  );
};

export default AddProductInline;
