import { loadingPlaceholderProduct } from "@src/features/products/utils";
import { loadingPlaceholderBid } from "../bids/utils";
import { loadingPlaceholderBill } from "../bills/utils";
import { loadingPlaceholderBudget } from "../budgets/utils";
import { loadingPlaceholderChangeOrder } from "../changeOrders/utils";
import { loadingPlaceholderClient } from "../clients/utils";
import { loadingPlaceholderConsultation } from "../consultations/utils";
import { loadingPlaceholderContact } from "../contacts/utils";
import { loadingPlaceholderContract } from "../contracts/utils";
import { loadingPlaceholderDesign } from "../designs/utils";
import { loadingPlaceholderEmail } from "../emails/utils";
import { loadingPlaceholderInvoice } from "../invoices/utils";
import { loadingPlaceholderLook } from "../looks/utils";
import { loadingPlaceholderMessage } from "../messages/utils";
import { loadingPlaceholderPartners } from "../partners/utils";
import { loadingPlaceholderProject } from "../projects/utils";

import { loadingPlaceholderProposal } from "../proposals/utils";
import { loadingPlaceholderPurchaseOrder } from "../purchaseOrders/utils";
import { loadingPlaceholderPurchase } from "../purchases/utils";
import { loadingPlaceholderService } from "../services/utils";
import { loadingPlaceholderStaff } from "../staff/utils";
import { loadingPlaceholderStoryboard } from "../storyboards/utils";
import { loadingPlaceholderSupplier } from "../suppliers/utils";
import { loadingPlaceholderTask } from "../tasks/utils";

const loadingPlaceholderDataMap = {
  "/products": loadingPlaceholderProduct,
  "/projects": loadingPlaceholderProject,
  "/leads": loadingPlaceholderProject,
  "/consultations": loadingPlaceholderConsultation,
  "/messages": loadingPlaceholderMessage,
  "/suppliers": loadingPlaceholderSupplier,
  "/emails": loadingPlaceholderEmail,
  "/financials/invoices": loadingPlaceholderInvoice,
  "/financials/bids": loadingPlaceholderBid,
  "/financials/bills": loadingPlaceholderBill,
  "/financials/budgets": loadingPlaceholderBudget,
  "/financials/purchase-orders": loadingPlaceholderPurchaseOrder,
  "financials/change-orders": loadingPlaceholderChangeOrder,
  "/financials/purchases": loadingPlaceholderPurchase,
  "financials/proposals": loadingPlaceholderProposal,
  "/partners": loadingPlaceholderPartners,
  "/tasks": loadingPlaceholderTask,
  "/contracts": loadingPlaceholderContract,
  "/services": loadingPlaceholderService,
  "/designs/styles": loadingPlaceholderDesign,
  "/designs/storyboards": loadingPlaceholderStoryboard,
  "/staff": loadingPlaceholderStaff,
  "/clients": loadingPlaceholderClient,
  "/contacts": loadingPlaceholderContact,
  "/looks": loadingPlaceholderLook,
};

export default loadingPlaceholderDataMap;
