import CommonSendEmail from "../people/CommonSendEmail";
import AddStaff from "./AddStaff";

export const staffActions = {
  add: {
    id: "add",
    label: "Create Staff",
    sidebar: AddStaff,
  },
  send: {
    id: "send",
    label: "Send Message",
    component: CommonSendEmail,
    container: "collapsedExpand",
  },
};
