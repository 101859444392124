import { isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";

export const PRODUCT_PER_UNITS = [
  { title: "each", value: "each" },
  { title: "sqft", value: "sqft" },
  { title: "linear ft", value: "linear ft" },
  { title: "box", value: "box" },
  { title: "yards", value: "yards" },
  { title: "feet", value: "feet" },
  { title: "inches", value: "inches" },
];

export const ProductImagePosition = {
  TOP_RIGHT: "top right",
  BOTTOM_RIGHT: "bottom right",
};

export const transformDesignStyles = (designStyle) => ({
  ...designStyle,
  value: designStyle.id,
  title: designStyle.name,
});

export const transformMaterials = (material) => ({
  ...material,
  value: material.id,
  title: material.name,
});

export const transformFinishes = (finish) => ({
  ...finish,
  value: finish.id,
  title: finish.name,
});

export const transformCollections = (collection) => ({
  ...collection,
  value: collection.id,
  title: collection.name,
});

export const transformColor = (color) => ({
  ...color,
  value: color.id,
  title: color.name,
});

export const transformObjects = (object) => ({
  ...object,
  value: object.id,
  title: object.description,
});

export const transformDTAttributes = (dtattribute) => ({
  ...dtattribute,
  value: dtattribute.id,
  title: `${dtattribute.objectName} - ${dtattribute.type}: (${dtattribute.name})`,
});

export const mapFormInputToProductFields = (input) => {
  const width = parseFloat(input.width || undefined);
  const height = parseFloat(input.height || undefined);
  const depth = parseFloat(input.depth || undefined);
  const cost = parseFloat(input.cost || undefined);
  const leadTimeDays = parseFloat(input.leadTimeDays || undefined);

  return {
    ...input,
    width,
    height,
    depth,
    cost,
    leadTimeDays,
  };
};

export const queryKeyMap = {
  designStyle: "designStyles",
  look: "looks",
  product: "products",
  finishes: "finishes",
  materials: "materials",
  objects: "objects",
};
export const queryResponseMap = {
  designStyle: "getAllDesignStyle",
  look: "getAllLook",
  product: "getProducts",
  finishes: "getFinishes",
  materials: "getMaterials",
  objects: "getObjects",
};

export const queryDetailResponseMap = {
  designStyle: "getDesignStyleByID",
  look: "getLookByID",
  product: "getProductByID",
  finishes: "getFinishesById",
  materials: "getMaterialById",
  objects: "getObjectByID",
};

export const loadingPlaceholderProduct = {
  id: "",
  name: "",
  description: null,
  leadTimeDays: null,
  cost: "",
  sku: "",
  brand: "",
  status: "",
  isStocked: false,
  dimensions: null,
  finish: null,
  color: "",
  isTaxExempt: false,
  isRefundable: false,
  vendorURL: "",
  unit: null,
  suppliers: [
    {
      name: "",
    },
  ],
  objects: [],
  media: [
    {
      id: "",
      path: "",
      name: "",
      fileType: "",
      sortOrder: "",
      type: "",
      isThumbnail: "",
    },
    {
      id: "",
      path: "",
      name: "",
      fileType: "",
      sortOrder: "",
      type: "",
      isThumbnail: "",
    },
  ],
  createdAt: "",
  designStyles: null,
  materials: [],
};

export const getFinalColumns = (filters, columns) => {
  const finalColumns = [];
  columns.forEach((column) => {
    const tempColumn = { ...column };
    if (!filters.includes(column.id)) {
      delete tempColumn.filter;
      delete tempColumn.Filter;
    }
    finalColumns.push(tempColumn);
  });
  return finalColumns;
};

export const moveVariantsToProducts = (products) => {
  let updatedProducts = [];
  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    if (product.variants?.length) {
      updatedProducts.push(product);
      const variants = product.variants;
      for (let j = 0; j < variants.length; j++) {
        const variant = variants[j];
        let variantProduct = {
          ...product,
          ...variant,
          vendorURL: variant.vendorURL || product.vendorURL,
          name: `${product.name} - ${variant.name}`,
          variantId: variant.id, // used in file upload
          id: `v${variant.id}`, // To ensure url is unique
          productId: product.id, // used in file upload
          isVariant: true,
          variantName: variant.name,
          productName: product.name,
        };
        updatedProducts.push(variantProduct);
      }
    } else {
      updatedProducts.push(product);
    }
  }
  return updatedProducts;
};

export const getAddRemoveIds = (newEntriesIds = [], originalEntries = [], isAddOnly = false) => {
  const idsInProduct = originalEntries?.map((item) => item.id);
  if (!idsInProduct?.length) return { addIds: newEntriesIds, removeIds: [] };

  const addProductIds = (newEntriesIds || [])
    .map((itemId) => {
      if (!idsInProduct.includes(itemId)) {
        return itemId;
      }
      return null;
    })
    .filter(Boolean);
  const removeProductIds = (originalEntries || [])
    .map((item) => {
      if (!newEntriesIds.includes(item.id)) {
        return item.id;
      }
      return null;
    })
    .filter(Boolean);

  return {
    addIds: addProductIds,
    removeIds: isAddOnly ? [] : removeProductIds,
  };
};

export const transformProductCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    objects: "Type",
    "suppliers.name": "Supplier",
    finishes: "Finish",
    attributes: "Attribute",
    materials: "Material",
    colors: "Color",
    designStyles: "Design Style",
    spaces: "Space",
    brand: "Brand",
  };

  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => ({
      ...refinement,
      label: isAlgoliaAttributeValueEmpty(refinement.label)
        ? `No ${refinementAttributeToLabelMap[item.attribute]}`
        : titleCase(refinement.label),
    })),
  }));
};

export const productSortByOptions = [
  { title: "A-Z (Name)", label: "A-Z (Name)", value: "products" },
  { title: "Z-A (Name)", label: "Z-A (Name)", value: "products_name_desc" },
  { title: "Created Date", label: "Created Date", value: "products_createdAt_desc" },
];
