import CurrentRefinements from "@src/utils/components/AlgoliaComponents/CurrentRefinements";
import AlgoliaDateRangeFilter from "@src/utils/components/AlgoliaComponents/DateRangeFilter";
import DynamicFacetsList from "@src/utils/components/AlgoliaComponents/DynamicFacetsList";
import AlgoliaFacetFilter from "@src/utils/components/AlgoliaComponents/FacetFilter";

import { transformNoteCurrentRefinementItems } from "./utils";

const NoteFilters = ({ showProjectFilter }) => {
  return (
    <div className="space-y-4">
      <div className="my-5 flex flex-wrap items-center gap-2.5">
        <DynamicFacetsList>
          <AlgoliaFacetFilter attribute="noteType" label="Type" />
          {showProjectFilter && <AlgoliaFacetFilter attribute="project.fullAddress" label="Project" />}
          <AlgoliaDateRangeFilter attribute="createdAt" />
        </DynamicFacetsList>
      </div>
      <CurrentRefinements transformItems={transformNoteCurrentRefinementItems} />
    </div>
  );
};

export default NoteFilters;
