const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: [
        "project",
        "changeOrderTypes",
        "total",
        "dueDate",
        "coCode",
        "coPrefix",
        "coNumber",
        "description",
        "coDate",
        "status",
      ],
    },
  ],
};

export default uiSchema;
