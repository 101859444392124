import { TableCells, TableFilters, TableCards, NavCard } from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Pending: "bg-orange-500",
  Unscheduled: "bg-primary-500",
  Approved: "bg-green-500",
  TBD: "bg-primary-500",
};

export const commonChangeOrdersColumns = [
  {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      return (
        <NavCard
          title={original.description}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          show
          status={originalRow.getValue()}
          circleColor={originalRow.getValue() ? STATUS_COLOR_CODES[originalRow.getValue()] || "bg-primary-500" : ""}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "date",
    Header: "Issued",
    accessor: "coDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "dueDate",
    Header: "Due",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
  },
];

export const commonChangeOrderItemsColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "changeOrderId",
    Header: "Change Order ID",
    accessor: "changeOrder.id",
    Cell: TableCells.TextCell,
  },
  {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  {
    id: "subTotal",
    Header: "Subtotal",
    accessor: "subTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];

export const columnsChangeOrderItems = [...commonChangeOrderItemsColumns];
columnsChangeOrderItems.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.changeOrder?.project?.places?.addressLine1
      ? `${originalRow.changeOrder?.project?.places?.addressLine1} ${
          originalRow.changeOrder?.project?.places?.addressLine2 || ""
        }`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original?.changeOrder?.project?.places?.city}
        addressState={originalRow.row.original?.changeOrder?.project?.places?.state}
        addressZip={originalRow.row.original?.changeOrder?.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const ChangeOrderStatusOptions = ["Draft", "Pending", "Approved", "Rejected", "Awating Approval"];

export const loadingPlaceholderChangeOrder = {
  id: "",
  coDate: "",
  description: "",
  coCode: "",
  changeOrderTypes: "",
  total: "",
  status: "",
  project: {
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
    thumbnail: {
      id: "",
      path: "",
    },
  },
};
