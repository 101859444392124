import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_BIDS = gql`
  query getBids($filters: BidFilterFields!) {
    getBids(filters: $filters) {
      id
      createdAt
      dueDate
      willBid
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
      partner {
        id
        companyName
      }
    }
  }
`;

const GET_PROJECT_BIDS = gql`
  query getBids($filters: BidFilterFields!) {
    getBids(filters: $filters) {
      id
      createdAt
      dueDate
      willBid
      status
      partner {
        id
        companyName
      }
    }
  }
`;

const GET_BID = gql`
  query getBidByID($id: Int!) {
    getBidByID(id: $id) {
      id
      createdAt
      dueDate
      willBid
      status
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
      }
      partner {
        id
        companyName
      }
    }
  }
`;

const CREATE_BID = gql`
  mutation createBid($input: CreateBidRequest!) {
    createBid(input: $input) {
      success
      code
      message
      data {
        id
        createdAt
        status
        dueDate
        willBid
        project {
          id
          places {
            id
            addressLine1
            city
            state
            zipCode
          }
        }
        partner {
          id
          companyName
        }
      }
    }
  }
`;

const UPDATE_BID = gql`
  mutation updateBid($input: UpdateBidRequest!) {
    updateBid(input: $input) {
      success
      code
      message
      data {
        id
        createdAt
        status
        dueDate
        willBid
        project {
          id
          places {
            id
            addressLine1
            city
            state
            zipCode
          }
        }
        partner {
          id
          companyName
        }
      }
    }
  }
`;
export const fetchBids = async (filters = {}) => {
  return await graphQLClient.request(GET_BIDS, { filters });
};

export const createBid = async (input) => {
  return await graphQLClient.request(CREATE_BID, { input });
};

export const updateBid = async (input) => {
  return await graphQLClient.request(UPDATE_BID, { input });
};

export const fetchBid = async (id) => {
  return await graphQLClient.request(GET_BID, { id });
};

export const fetchProjectBids = async (filters = {}) => {
  return await graphQLClient.request(GET_PROJECT_BIDS, { filters });
};
