import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_CONTACTS = gql`
  query getContacts($filters: ContactFilterFields!) {
    getContacts(filters: $filters) {
      id
      fullName
      firstName
      lastName
      phone
      email
      avatar
      isPrimary
      createdAt
      partners {
        id
        companyName
      }
      suppliers {
        id
        name
      }
      status
    }
  }
`;

const GET_CONTACT = gql`
  query getContactByID($id: Int!) {
    getContactByID(id: $id) {
      id
      fullName
      firstName
      lastName
      phone
      email
      avatar
      isPrimary
      linkedinUrl
      instagramUrl
      pinterestUrl
      facebookUrl
      twitterUrl
      tiktokUrl
      createdAt
      partners {
        id
        companyName
        avatar
        logo
      }
      suppliers {
        id
        name
        logo
      }
      status
    }
  }
`;

const CREATE_CONTACT = gql`
  mutation createContact($input: CreateContactInput!) {
    createContact(input: $input) {
      success
      message
      data {
        id
        fullName
        firstName
        lastName
        phone
        email
        avatar
        isPrimary
        createdAt
        partners {
          id
          companyName
          avatar
          logo
        }
        suppliers {
          id
          name
          logo
        }
        status
      }
    }
  }
`;

const UPDATE_CONTACT = gql`
  mutation updateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      success
      message
      data {
        id
        fullName
        firstName
        lastName
        phone
        email
        avatar
        isPrimary
        createdAt
        partners {
          id
          companyName
          avatar
          logo
        }
        suppliers {
          id
          name
          logo
        }
      }
    }
  }
`;

const DELETE_CONTACT = gql`
  mutation deleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input) {
      success
      message
      data {
        id
        fullName
        firstName
        lastName
        phone
        email
        createdAt
      }
    }
  }
`;

export const createContact = async (input = {}) => {
  return await graphQLClient.request(CREATE_CONTACT, { input });
};

export const fetchContactById = async (id) => {
  return await graphQLClient.request(GET_CONTACT, { id });
};

export const deleteContact = async (input = {}) => {
  return await graphQLClient.request(DELETE_CONTACT, { input });
};

export const updateContact = async (input = {}) => {
  return await graphQLClient.request(UPDATE_CONTACT, { input });
};

export const fetchContacts = async (filters = {}) => {
  return await graphQLClient.request(GET_CONTACTS, { filters });
};
