import { useState } from "react";
import { useMutation } from "react-query";
import { createMediaCoordinate } from "../../api";

const useCreateMediaCoordinate = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    ({ input }) => {
      setError("");
      setIsSuccess(false);
      return createMediaCoordinate(input);
    },
    {
      onSuccess: (data) => {
        if (!data?.createMediaCoordinate?.success) {
          setError(`Error: ${data.createMediaCoordinate.message}`);
        } else {
          setIsSuccess(true);
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    createMediaCoordinate: mutate,
    createMediaCoordinateAsync: mutateAsync,
    isLoading,
    isSuccess,
    error,
  };
};

export default useCreateMediaCoordinate;
