import {
  Button,
  EmailField,
  FileUpload,
  Notice,
  PersonField,
  PhoneField,
  ProgressBar,
  SectionGroup,
  Select,
  SelectPeople,
  SelectProject,
  TextField,
  Toggle,
} from "@buildappeal/react-component-library";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getAddressFromPlaces } from "@src/utils/common";
import { US_STATES } from "@src/utils/constants";
import usePlacesWidget from "@src/utils/hooks/usePlacesWidget";
import { createProject, fetchSpaces } from "../projects/api";
import useProjects from "../projects/hooks/useProjects";
import useStaff from "../staff/hooks/useStaff";
import { createClient } from "./api";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  clientBudgetLow: "",
  clientBudgetHigh: "",
  leadOwner: "",
  avatar: {
    file: null,
    filename: null,
    type: null,
  },
  rooms: [],
  address: "",
  city: "",
  state: "",
  zipCode: "",
  project: null,
};

const AddClient = ({ onClose }) => {
  const queryClient = useQueryClient();
  const { options: staffAsOptions, isLoading: staffLoading } = useStaff();
  const [formState, setFormState] = useState({ ...initialFormState });
  const [loading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const timerRef = useRef();
  const [hasExistingProject, setHasExistingProject] = useState(true);

  const { options: projectsAddressAsOptions, isLoading: projectsAddressAsOptionsLoading } = useProjects();
  const { data, isLoadingSpaces } = useQuery("spaces", fetchSpaces, {
    refetchOnWindowFocus: false,
  });

  const spaces = data?.getSpaces;

  const onPlaceSelected = (place) => {
    const { address, city, state, zipCode } = place;
    onChangeHandler({
      address,
      city,
      state: US_STATES.find((s) => s.title === state)?.value,
      zipCode,
    });
  };

  const { ref: addressRef } = usePlacesWidget({
    onPlaceSelected,
  });
  const isFormValid = () => {
    if (!formState?.email?.length) {
      showErrMsg("Email is Mandatory");
      return false;
    }

    if (!hasExistingProject && !formState?.rooms) {
      showErrMsg("Rooms is Mandatory");
      return false;
    }

    if (!hasExistingProject && !formState?.address?.length) {
      showErrMsg("Address is Mandatory");
      return false;
    }

    if (!hasExistingProject && !formState?.city?.length) {
      showErrMsg("City is Mandatory");
      return false;
    }

    if (!hasExistingProject && !formState?.state?.length) {
      showErrMsg("Please select state");
      return false;
    }

    if (!hasExistingProject && !formState?.zipCode?.length) {
      showErrMsg("Zip code is Mandatory");
      return false;
    }

    showErrMsg("");
    return true;
  };

  const showErrMsg = (msg) => {
    setErrMsg(msg);
    if (msg) {
      showErrMsgNotification();
    }
  };
  const showErrMsgNotification = () => {
    setShowErrorMessage(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setShowErrorMessage(false);
    }, 2000);
  };

  const createClientMutation = useMutation(
    (data) => {
      setIsLoading(true);
      const clientData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        leadOwner: data.leadOwner,
        type: "Client",
        avatar: data.avatar,
      };
      if (hasExistingProject) {
        clientData.projectId = data?.project?.id;
      }
      return createClient(clientData);
    },
    {
      onSuccess: (data) => {
        if (!data.createClient.success) {
          showErrMsgNotification();
          return;
        }

        setShowSuccessMessage(true);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
        setFormState((prev) => ({ ...initialFormState }));

        queryClient.setQueryData("clients", (old) => {
          if (!old) return { getClients: [data.createClient.data] };
          return { getClients: [data.createClient.data, ...old.getClients] };
        });
        if (!hasExistingProject) {
          createProjectMutation.mutate({ ...formState });
        } else {
          onClose?.();
        }
      },
      onError: () => {
        showErrMsgNotification();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );

  const createProjectMutation = useMutation(
    (data) => {
      const projectData = {
        name: data.address,
        addressLine1: data.address,
        city: data.city,
        state: data.state,
        zipcode: data.zipCode,
        clients: [data.email],
        spacesIds: data?.rooms?.map((room) => +room?.value),
        leadSource: "Manual",
      };

      return createProject(projectData);
    },
    {
      onSuccess: (data) => {
        if (!data.createProject.success) {
          showErrMsgNotification();
          return;
        }

        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
        setFormState({ ...initialFormState });
        onClose?.();
      },
      onError: () => {
        showErrMsgNotification();
      },
      onSettled: () => {
        setIsLoading(false);
      },
    }
  );
  const onSubmit = () => {
    if (!isFormValid()) {
      return;
    }
    const data = {
      ...formState,
    };
    delete data.leadOwner;
    if (formState?.leadOwner?.value) {
      data.leadOwner = formState?.leadOwner?.value;
    }
    createClientMutation.mutate(data);
  };

  const onChangeHandler = (obj) => {
    setFormState((prev) => {
      return {
        ...prev,
        ...obj,
      };
    });
  };

  const handleOnChange = async (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCancel = (field) => {
    setFormState((prev) => ({
      ...prev,
      [field]: {
        file: null,
        filename: null,
        type: null,
      },
    }));
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;

      handleOnChange("avatar", {
        file: binaryStr.split("base64,")[1],
        filename: file.name,
        type: file.type,
      });
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  return (
    <div className="mt-16 flex w-full flex-col justify-center pl-10 pr-14">
      <div className="w-full">
        {loading && <ProgressBar min={0} max={100} now={90} className="my-4" />}
        {!loading && showSuccessMessage && <Notice type="success" message="Client successfully created" />}
        {!loading && showErrorMessage && (
          <Notice type="critical" message={errMsg || "Client creation failed. Please try again!"} />
        )}
        <SectionGroup className="my-4" title="Create Client">
          <p className="mt-8 text-md font-normal text-neutral-800">Details</p>
          <PersonField
            value={{
              firstName: formState.firstName,
              lastName: formState.lastName,
            }}
            onChange={({ target: { value } }, fieldName) => onChangeHandler({ [fieldName]: value })}
            className="mt-5"
          />
          <PhoneField
            label="Phone Number"
            value={formState.phone}
            onChange={(value) => onChangeHandler({ phone: value })}
            className="mt-3"
            defaultCountry="US"
            maxLength={14}
          />
          <EmailField
            label="Email"
            value={formState.email}
            onChange={({ target: { value } }) => onChangeHandler({ email: value })}
            className="mt-3"
            required
          />
          <p className="mt-12 text-md font-normal text-neutral-800">Lead Details</p>
          <SelectPeople
            label="Lead Owner"
            className="mt-3"
            options={staffAsOptions}
            value={{
              title: formState.leadOwner?.title || "",
              value: formState.leadOwner?.value || "",
            }}
            onChange={(opt) => {
              onChangeHandler({ leadOwner: opt });
            }}
            isLoading={staffLoading}
          />

          <p className="mt-12 text-md font-normal text-neutral-800">Profile Photo</p>
          <FileUpload
            handleSelectedFiles={(files) => {
              files.length > 0 ? handleFileUpload(files[0]) : handleCancel("avatar");
            }}
            acceptedFileTypes={["image/jpeg", "image/png", "image/heic", "image/webp"]}
            multiple={false}
            appearance="button"
            buttonLabel="Add photo"
            maxFiles={1}
            maxFileSize={1000000}
            className="mt-8"
          />
          <div className="mt-16 flex w-full content-end items-end justify-end">
            <Toggle
              label=" "
              descriptor="Add to an existing project"
              position="left"
              checked={hasExistingProject}
              onChange={() => {
                setHasExistingProject((prev) => !prev);
              }}
            />
          </div>

          {hasExistingProject ? (
            <SelectProject
              label="Project"
              className="mt-8"
              isLoading={projectsAddressAsOptionsLoading}
              options={projectsAddressAsOptions}
              value={{ title: getAddressFromPlaces(formState.project?.places), value: formState.project?.id || "" }}
              onChange={(opt) => {
                onChangeHandler({ project: opt?.project });
              }}
            />
          ) : (
            spaces && (
              <>
                <TextField
                  inputRef={addressRef}
                  label="Address"
                  value={formState.address}
                  onChange={({ target: { value } }) => onChangeHandler({ address: value })}
                  className="mt-4"
                />
                <TextField
                  label="City"
                  value={formState.city}
                  onChange={({ target: { value } }) => onChangeHandler({ city: value })}
                  className="mt-4"
                />
                <Select
                  label="State"
                  value={{
                    title: US_STATES.find((state) => state.value === formState.state)?.title ?? "",
                    value: formState.state ?? "",
                  }}
                  options={US_STATES}
                  onChange={(opt) => onChangeHandler({ state: opt?.value })}
                  className="mt-4"
                />
                <TextField
                  label="ZIP Code"
                  type="number"
                  value={formState.zipCode}
                  onChange={({ target: { value } }) => onChangeHandler({ zipCode: value.toString() })}
                  className="mt-6"
                />
                <Select
                  label="Spaces"
                  isLoading={isLoadingSpaces}
                  value={formState.rooms}
                  options={spaces?.map((item) => ({
                    title: item.name,
                    value: item.id.toString(),
                  }))}
                  onChange={(value) => onChangeHandler({ rooms: value })}
                  className="mt-8"
                  multiple
                />
              </>
            )
          )}
          <Button
            className="mt-8 w-full"
            disabled={loading}
            appearance="primary"
            label="Create Client"
            size="sm"
            onClick={onSubmit}
          />
        </SectionGroup>
      </div>
    </div>
  );
};
export default AddClient;
