import { Avatar } from "@buildappeal/react-component-library";

import generateInitials from "@src/utils/generateInitials";
import ConsultationCard from "./ConsultationCard";

const AddConsultationSuccess = ({ consultation, guests = [], onLaunchConsultation }) => {
  return (
    <div className="flex flex-col px-10">
      <div className="mt-5 text-xl">Consultation successfully scheduled!</div>
      <div className="mb-5 text-sm">All guests have been sent an invitation via email.</div>
      <ConsultationCard key={consultation.id} consultation={consultation} onLaunchConsultation={onLaunchConsultation} />
      <div className="mt-10 border-b border-b-neutral-100 pb-5 text-md">
        Guests {guests.length ? `(${guests.length})` : ""}
      </div>
      {guests.map((guest) => {
        const finalUser = guest.type === "Client" ? guest.client : guest.staff;
        return (
          <div className="flex w-full items-center justify-between border-b border-neutral-200 py-4 last:!border-none dark:border-midnightborder">
            <div className="flex items-center space-x-6">
              <Avatar src={finalUser.avatar} initials={generateInitials(finalUser.fullName)} />
              <div className="flex flex-col">
                <span className="text-md font-light">{finalUser.fullName}</span>
                <span className="text-sm font-light">{guest.type}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AddConsultationSuccess;
