import React, { useState } from "react";
import PropTypes from "prop-types";

import { Document, Page, pdfjs } from "react-pdf";

// https://github.com/wojtekmaj/react-pdf/issues/52#issuecomment-1061497022
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfDocument = ({ mRef, file }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  return (
    <div data-testid="PdfDocument" ref={mRef}>
      <Document
        className="h-full cursor-pointer"
        file={file}
        onClick={() => window.open(file, "_blank").focus()}
        onLoadError={console.error}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </div>
  );
};

PdfDocument.propTypes = {
  file: PropTypes.string.isRequired,
  mRef: PropTypes.shape({ current: PropTypes.any }),
};

export default PdfDocument;
