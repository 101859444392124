// Returns true to skip uploading new files
export const areItemsAlreadyInQueue = (uploadQueue, newFiles, checkGroupTitle = false) => {
  if (!newFiles.length) return true;
  if (!uploadQueue?.length) return false;

  let allMatch = true;
  for (let i = 0; i < newFiles.length; i++) {
    const incomingFile = newFiles[i];

    const matchedFile = uploadQueue.find((uploadingFile) => {
      if (checkGroupTitle) {
        return incomingFile.id === uploadingFile.id && incomingFile.groupTitle === uploadingFile.groupTitle;
      }
      return incomingFile.id === uploadingFile.id;
    });
    if (!matchedFile) {
      allMatch = false;
      break;
    }
  }
  return allMatch;
};

export const actionResponseKeyMap = {
  filesMultiSelect: "updateFilesInfo",
  productsMultiSelect: "updateProducts",
  partnersMultiSelect: "updatePartners",
  productVariantsCreate: "createProductVariants",
  projectsMultiSelect: "updateProjects",
};

export const areRequestsAlreadyInQueue = (uploadQueue, newRequests) => {
  if (!newRequests.length) return true;
  if (!uploadQueue?.length) return false;

  let allMatch = true;
  for (let i = 0; i < newRequests.length; i++) {
    const incomingRequest = newRequests[i];

    const matchedRequest = uploadQueue.find((uploadingRequest) => {
      return incomingRequest.id === uploadingRequest.id;
    });
    if (!matchedRequest) {
      allMatch = false;
      break;
    }
  }
  return allMatch;
};
