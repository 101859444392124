import React, { useState } from "react";
import PropTypes from "prop-types";
import toArray from "rc-util/lib/Children/toArray";
import { UilToggleOff as ToggleIcon, UilPlus as AddIcon } from "@iconscout/react-unicons";
import { Button, IconButton, Dropdown, SpaceIcon, Tooltip } from "@buildappeal/react-component-library";

import ApplyProductItem from "./ApplyProductItem";
import { ApplyProductType, ApplyProductOption } from "./utils";

const ApplyProduct = ({ children, spaceName, hideHeader, onAdd, type, onToggleAll, mRef, spaceNickname }) => {
  const options = Object.values(ApplyProductOption).map((item) => ({
    label: item,
    value: item,
    onClick: () => onAdd(item),
  }));

  const [toggleState, setToggleState] = useState(false);

  return (
    <div data-testid="ApplyProduct" ref={mRef} className="w-full border border-neutral-200 p-6">
      {!hideHeader && (
        <div className="mb-3 flex items-center justify-between">
          <div className="flex items-center gap-x-4">
            <SpaceIcon space={spaceName} iconClassName="!h-10 !w-10" />
            <span>{spaceNickname}</span>
          </div>
          {type === ApplyProductType.SqFt && (
            <Tooltip message={`${!toggleState ? "Apply" : "Remove"} all services`}>
              <IconButton
                icon={ToggleIcon}
                onClick={() => {
                  setToggleState((prev) => !prev);
                  onToggleAll(!toggleState);
                }}
              />
            </Tooltip>
          )}
        </div>
      )}
      <div className="space-y-3">
        {toArray(children).map((child, index) =>
          React.cloneElement(child, {
            ...child.props,
            key: index,
            type,
          })
        )}
      </div>
      {onAdd && (
        <Dropdown
          MenuButton={<Button label="Add" icon={AddIcon} appearance="tertiary" className="mt-3" />}
          items={options}
        />
      )}
    </div>
  );
};

ApplyProduct.Item = ApplyProductItem;

ApplyProduct.propTypes = {
  children: PropTypes.node,
  spaceName: PropTypes.string,
  hideHeader: PropTypes.bool,
  onAdd: PropTypes.func,
  type: PropTypes.oneOf(Object.values(ApplyProductType)),
  onToggleAll: PropTypes.func,
  mRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
};

export default ApplyProduct;
