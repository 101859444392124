import { useState } from "react";
import { useMutation } from "react-query";
import { newActivity } from "../../api";

const useAddActivity = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      setIsSuccess(false);
      return newActivity(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createActivity.success) {
          setError(`Error: ${data.createActivity.message}`);
        } else {
          setIsSuccess(true);
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    addNewActivity: mutate,
    isLoading,
    isSuccess,
    error,
    addNewActivityAsync: mutateAsync,
  };
};

export default useAddActivity;
