import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PROJECT_SPACE_OBJECTS = gql`
  query getProjectSpaceObjects($filters: ProjectSpaceObjectFilterFields!) {
    getProjectSpaceObjects(filters: $filters) {
      id
      nickname
      objectsId
      projectSpaceId
      height
      width
      depth
      parentId
      sortOrder
      measurementUnit
      measurementUnitType
      isProposalActive
      attributes {
        id
        value
        attributeItem {
          id
          attribute {
            name
            dataType
          }
        }
      }
      object {
        parentRelationProperties
        icon
        description
        color
        measurementUnitType
        attributes {
          id
          attribute {
            id
            name
            dataType
            parentAttributeId
            sortBy
          }
          options {
            name
            additionalCost
          }
        }
      }
      parent {
        id
        nickname
        objectsId
        object {
          description
        }
        projectSpaceId
        height
        width
        depth
        sortOrder
      }
      projectSpaceObjectAdjacents {
        id
        position
        projectSpaceObjectAdjacentId
      }
    }
  }
`;

const GET_GROUPED_PROJECT_SPACE_OBJECTS = gql`
  query getGroupedProjectSpaceObjectsByParent($filters: ProjectSpaceObjectFilterFields!) {
    getGroupedProjectSpaceObjectsByParent(filters: $filters) {
      parentId
      projectSpaceObjects {
        id
        nickname
        objectsId
        projectSpaceId
        height
        width
        depth
        parentId
        sortOrder
        measurementUnit
        measurementUnitType
        parentRelationValues
        isProposalActive
        attributes {
          id
          value
          attributeItem {
            id
            attribute {
              name
              dataType
            }
          }
        }
        object {
          parentRelationProperties
          icon
          description
          colors {
            id
            name
            icon
            colorValue
          }
          measurementUnitType
          attributes {
            id
            attribute {
              id
              name
              dataType
              parentAttributeId
              sortBy
            }
            options {
              name
              additionalCost
            }
          }
        }
        parent {
          id
          nickname
          objectsId
          projectSpaceId
          height
          width
          object {
            description
          }
          depth
          sortOrder
        }
        projectSpaceObjectAdjacents {
          id
          position
          projectSpaceObjectAdjacentId
        }
      }
    }
  }
`;

export const GET_PROJECT_SPACE_OBJECT_ACTIONS = gql`
  query getProjectSpaceObjectActions($projectSpaceObjectIds: [Int]!) {
    getProjectSpaceObjectActions(projectSpaceObjectIds: $projectSpaceObjectIds) {
      id
      name
      projectSpaceObjectActions {
        id
        estimateId
        action {
          id
          verbPresent
        }
        actionAttributes {
          id
          value
          attributeItem {
            id
            attribute {
              id
            }
          }
        }
      }
    }
  }
`;

const UPDATE_PROJECT_SPACE_OBJECTS = gql`
  mutation updateProjectSpaceObjects($input: [ProjectSpaceObjectRequest]!) {
    updateProjectSpaceObjects(input: $input) {
      code
      success
      message
      data {
        id
        objectsId
        projectSpaceId
        nickname
        parentId
        height
        width
        depth
        parentId
        sortOrder
        measurementUnit
        measurementUnitType
        parentRelationValues
        isProposalActive
        attributes {
          id
          value
          attributeItem {
            id
            attribute {
              name
              dataType
            }
          }
        }
        object {
          parentRelationProperties
          icon
          description
          color
          measurementUnitType
          attributes {
            id
            attribute {
              id
              name
              dataType
              parentAttributeId
              sortBy
            }
            options {
              name
              additionalCost
            }
          }
        }
        parent {
          id
          nickname
          objectsId
          projectSpaceId
          object {
            description
          }
          height
          width
          depth
          sortOrder
        }
        projectSpaceObjectAdjacents {
          id
          position
          projectSpaceObjectAdjacentId
        }
      }
    }
  }
`;

const CREATE_MULTI_PROJECT_SPACE_OBJECTS = gql`
  mutation createMultiProjectSpaceObjects($input: [ProjectSpaceObjectRequest]!) {
    createMultiProjectSpaceObjects(input: $input) {
      code
      success
      message
      data {
        id
        nickname
        parentId
        objectsId
        projectSpaceId
        createdAt
        sortOrder
        measurementUnit
        measurementUnitType
        parentRelationValues
        isProposalActive
        attributes {
          id
          value
          attributeItem {
            id
            attribute {
              name
              dataType
            }
          }
        }
        object {
          parentRelationProperties
          icon
          description
          colors {
            id
            name
            icon
            colorValue
          }
          measurementUnitType
          attributes {
            id
            attribute {
              id
              name
              dataType
              parentAttributeId
              sortBy
            }
            options {
              name
              additionalCost
            }
          }
        }
        parent {
          id
          nickname
          objectsId
          object {
            description
          }
          projectSpaceId
          height
          width
          depth
        }
        projectSpaceObjectAdjacents {
          id
          position
          projectSpaceObjectAdjacentId
        }
      }
    }
  }
`;

const GET_FILES_PROJECT_SPACE_OBJECTS = gql`
  query getFilesProjectSpaceObjects($filters: FilesProjectSpaceObjectFilterFields!) {
    getFilesProjectSpaceObjects(filters: $filters) {
      id
      files {
        id
        path
        shareUrl
      }
      projectSpaceId
      projectSpaceObjectId
    }
  }
`;

const GET_FILES_PROJECT_SPACE_OBJECTS_BY_ID = gql`
  query getFilesProjectSpaceObjectsById($id: Int!) {
    getFilesProjectSpaceObjectsById(id: $id) {
      id
      files {
        id
        path
        name
        fileType
        type
        shareUrl
      }
      projectSpaceObjectId
    }
  }
`;

const CREATE_FILES_PROJECT_SPACE_OBJECTS = gql`
  mutation createFilesProjectSpaceObjects($input: [CreateFilesProjectSpaceObjectRequest]!) {
    createFilesProjectSpaceObjects(input: $input) {
      code
      success
      message
      data {
        id
        projectSpaceId
        projectSpaceObjectId
        object {
          id
          parentRelationProperties
          icon
          description
          color
          measurementUnitType
        }
        files {
          id
          path
          shareUrl
        }
      }
    }
  }
`;

const DELETE_FILES_PROJECT_SPACE_OBJECTS = gql`
  mutation deleteFilesProjectSpaceObjects($input: [BigInt]!) {
    deleteFilesProjectSpaceObjects(input: $input) {
      code
      success
      message
    }
  }
`;

const DELETE_PROJECT_SPACE_OBJECTS = gql`
  mutation deleteProjectSpaceObjects($input: [ProjectSpaceObjectRequest]!) {
    deleteProjectSpaceObjects(input: $input) {
      code
      success
      message
      data {
        id
        objectsId
        projectSpaceId
        nickname
        parentId
        height
        width
        depth
        parentId
        sortOrder
        parentRelationValues
        attributes {
          id
          value
          attributeItem {
            id
            attribute {
              name
              dataType
            }
          }
        }
        object {
          parentRelationProperties
          icon
          description
          color
          measurementUnitType
          attributes {
            id
            attribute {
              id
              name
              dataType
              parentAttributeId
              sortBy
            }
            options {
              name
              additionalCost
            }
          }
        }
        parent {
          id
          nickname
          objectsId
          projectSpaceId
          height
          width
          depth
          object {
            description
          }
          sortOrder
        }
        projectSpaceObjectAdjacents {
          id
          position
          projectSpaceObjectAdjacentId
        }
      }
    }
  }
`;

const UPSERT_PROJECT_SPACE_OBJECT_ATTRIBUTES = gql`
  mutation upsertProjectSpaceObjectAttributes($input: [ProjectSpaceObjectAttributeRequest]!) {
    upsertProjectSpaceObjectAttributes(input: $input) {
      code
      success
      message
      data {
        id
        nickname
        parentId
        objectsId
        projectSpaceId
        createdAt
        sortOrder
        parentRelationValues
        isProposalActive
        attributes {
          id
          value
          attributeItem {
            id
            attribute {
              name
              dataType
            }
          }
        }
        object {
          parentRelationProperties
          icon
          description
          color
          measurementUnitType
          attributes {
            id
            attribute {
              id
              name
              dataType
              parentAttributeId
              sortBy
            }
            options {
              name
              additionalCost
            }
          }
        }
        parent {
          id
          nickname
          objectsId
          object {
            description
          }
          projectSpaceId
          height
          width
          depth
        }
        projectSpaceObjectAdjacents {
          id
          position
          projectSpaceObjectAdjacentId
        }
      }
    }
  }
`;

const CREATE_PROJECT_SPACE_OBJECT_ACTION = gql`
  mutation createProjectSpaceObjectAction($input: ProjectSpaceObjectActionRequest!) {
    createProjectSpaceObjectAction(input: $input) {
      code
      success
      message
    }
  }
`;

const DELETE_PROJECT_SPACE_OBJECT_ACTION = gql`
  mutation deleteProjectSpaceObjectAction($id: Int!) {
    deleteProjectSpaceObjectAction(id: $id) {
      code
      success
      message
    }
  }
`;

const UPSERT_PROJECT_SPACE_OBJECT_ACTION_ATTRIBUTES = gql`
  mutation upsertProjectSpaceObjectActionAttributes($input: ProjectSpaceObjectActionAttributeRequest!) {
    upsertProjectSpaceObjectActionAttributes(input: $input) {
      code
      success
      message
    }
  }
`;

const UPSERT_PROJECT_SPACE_OBJECT_ADJACENTS = gql`
  mutation upsertProjectSpaceObjectAdjacents($input: [ProjectSpaceObjectAdjacentRequest]!) {
    upsertProjectSpaceObjectAdjacents(input: $input) {
      code
      success
      message
      data {
        id
        nickname
        parentId
        objectsId
        projectSpaceId
        createdAt
        measurementUnit
        measurementUnitType
        sortOrder
        parentRelationValues
        isProposalActive
        attributes {
          id
          value
          attributeItem {
            id
            attribute {
              name
              dataType
            }
          }
        }
        object {
          parentRelationProperties
          icon
          description
          color
          measurementUnitType
          attributes {
            id
            attribute {
              id
              name
              dataType
              parentAttributeId
              sortBy
            }
            options {
              name
              additionalCost
            }
          }
        }
        parent {
          id
          nickname
          objectsId
          object {
            description
          }
          projectSpaceId
          height
          width
          depth
        }
        projectSpaceObjectAdjacents {
          id
          position
          projectSpaceObjectAdjacentId
        }
      }
    }
  }
`;

export const fetchProjectSpaceObjects = async (filters = {}) => {
  return await graphQLClient.request(GET_PROJECT_SPACE_OBJECTS, { filters });
};

export const fetchGroupedProjectSpaceObjects = async (filters = {}) => {
  return await graphQLClient.request(GET_GROUPED_PROJECT_SPACE_OBJECTS, { filters });
};

export const updateProjectSpaceObjects = async (input) => {
  return await graphQLClient.request(UPDATE_PROJECT_SPACE_OBJECTS, { input });
};

export const createFilesProjectSpaceObjects = async (input) => {
  return await graphQLClient.request(CREATE_FILES_PROJECT_SPACE_OBJECTS, { input });
};

export const fetchFilesProjectSpaceObjects = async (filters = {}) => {
  return await graphQLClient.request(GET_FILES_PROJECT_SPACE_OBJECTS, { filters });
};

export const fetchFilesProjectSpaceObjectsById = async (id) => {
  return await graphQLClient.request(GET_FILES_PROJECT_SPACE_OBJECTS_BY_ID, { id });
};

export const deleteFilesProjectSpaceObjects = async (input) => {
  return await graphQLClient.request(DELETE_FILES_PROJECT_SPACE_OBJECTS, { input });
};

export const deleteProjectSpaceObjects = async (input) => {
  return await graphQLClient.request(DELETE_PROJECT_SPACE_OBJECTS, { input });
};

export const createMultiProjectSpaceObject = async (input = {}) => {
  return await graphQLClient.request(CREATE_MULTI_PROJECT_SPACE_OBJECTS, { input });
};

export const upsertProjectSpaceObjectAttributes = async (input) => {
  return await graphQLClient.request(UPSERT_PROJECT_SPACE_OBJECT_ATTRIBUTES, { input });
};

export const createProjectSpaceObjectAction = async (input) => {
  return await graphQLClient.request(CREATE_PROJECT_SPACE_OBJECT_ACTION, { input });
};

export const deleteProjectSpaceObjectAction = async (id) => {
  return await graphQLClient.request(DELETE_PROJECT_SPACE_OBJECT_ACTION, { id });
};

export const upsertProjectSpaceObjectActionAttributes = async (input) => {
  return await graphQLClient.request(UPSERT_PROJECT_SPACE_OBJECT_ACTION_ATTRIBUTES, { input });
};

export const fetchProjectSpaceObjectActions = async (projectSpaceObjectIds) => {
  return await graphQLClient.request(GET_PROJECT_SPACE_OBJECT_ACTIONS, { projectSpaceObjectIds });
};

export const upsertProjectSpaceObjectAdjacents = async (input) => {
  return await graphQLClient.request(UPSERT_PROJECT_SPACE_OBJECT_ADJACENTS, { input });
};
