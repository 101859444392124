import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchaseOrders } from "../../api";
import { setPurchaseOrders, getPurchaseOrders } from "../../purchaseOrdersSlice";

const usePurchaseOrders = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const purchaseOrders = useSelector(getPurchaseOrders);

  const { data, isLoading } = useQuery(["purchaseOrders", filters], () => fetchPurchaseOrders(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setPurchaseOrders(data.getPurchaseOrders));
  }, [data, isLoading, dispatch]);

  return {
    data: purchaseOrders,
    isLoading,
  };
};

export default usePurchaseOrders;
