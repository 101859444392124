import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mediaCoordinates: [],
  mediaCoordinatesByMediaId: {},
};

export const mediaCoordinatesSlice = createSlice({
  name: "mediaCoordinates",
  initialState,
  reducers: {
    setMediaCoordinates: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        mediaCoordinates: action.payload,
      };
    },
    setMediaCoordinatesByMediaId: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        mediaCoordinatesByMediaId: {
          ...originalState.mediaCoordinatesByMediaId,
          [action.payload.mediaId]: action.payload.mediaCoordinates,
        },
      };
    },
  },
});

export const { setMediaCoordinates, setMediaCoordinatesByMediaId } = mediaCoordinatesSlice.actions;

export default mediaCoordinatesSlice.reducer;

export const getMediaCoordinates = (state) => state.mediaCoordinates.mediaCoordinates;
export const getMediaCoordinatesByMediaId = (id) => (state) => state.mediaCoordinates.mediaCoordinatesByMediaId[id];
