import { Button, TableCells } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Signed: "green",
  Draft: "gray",
  pending: "orange",
  active: "green",
};

export const staffsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  fullName: {
    id: "name",
    Header: "Name",
    accessor: (originalRow) => `${originalRow.fullName}`,
    emailAccessor: "email",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={originalRow?.row?.original?.fullName ?? ""}
          avatarSrc={originalRow?.row?.original?.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
          initials={generateInitials(originalRow.value || originalRow.row.original.email)}
          {...originalRow}
        />
      );
    },
  },
  title: {
    id: "title",
    Header: "Title",
    accessor: "title",
    Cell: TableCells.TextCell,
  },
  phone: {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.TextCell,
  },
  email: {
    id: "email",
    Header: "Message",
    accessor: "email",
    Cell: (originalRow) => {
      const handleClick = () => {
        originalRow?.column?.onOpenClick("send", originalRow);
      };
      return originalRow?.row?.original?.email ? (
        <div className="p-4" onClick={(e) => e?.stopPropagation()}>
          <Button label="Email" onClick={handleClick} appearance="secondary" />
        </div>
      ) : (
        <div />
      );
    },
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: (row) => (row?.status === "active" ? "Active" : "Inactive"),
    Cell: (originalRow) => {
      const status = originalRow.row.original.status;
      return <TableCells.StatusCell status={status === "active" ? "Active" : "Inactive"} {...originalRow} />;
    },
  },
};
