import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useConfigure } from "react-instantsearch-hooks-web";

const AlgoliaTabFilters = ({ items = [], defaultSelected, defaultFilters, className }) => {
  const [selected, setSelected] = useState(items.find((item) => item.id === defaultSelected) || items[0]);

  useEffect(() => {
    if (!defaultSelected) return;
    const defaultItem = items.find((item) => item.id === defaultSelected) || items[0];
    if (defaultItem && selected.id !== defaultItem.id) {
      setSelected(defaultItem);
    }
  }, [defaultSelected]);

  const finalFilters = useMemo(() => {
    return defaultFilters ? `${defaultFilters}${selected.value ? ` AND ${selected.value}` : ""}` : selected.value;
  }, [selected, defaultFilters]);

  useConfigure({
    filters: finalFilters,
    page: 0,
  });

  return (
    <div
      className={clsx(
        "flex space-x-6 border-b border-b-neutral-200 bg-white dark:border-b-neutral-600 dark:bg-midnight",
        className
      )}
    >
      {items.map((item) => (
        <div
          key={item.id}
          className={clsx(
            "-mb-[1px] cursor-pointer border-b-4 border-transparent py-1.5 text-sm hover:text-neutral-800 dark:hover:text-neutral-400",
            selected?.id === item.id
              ? "border-b-4 border-b-accent-700 font-medium text-neutral-800 dark:text-white"
              : "text-neutral-500 dark:text-neutral-400"
          )}
          onClick={() => setSelected(item)}
        >
          <div className="rounded-md p-1.5 hover:bg-neutral-50 dark:hover:bg-neutral-700">{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default AlgoliaTabFilters;
