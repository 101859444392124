import { FilterRadio, ViewButton } from "@buildappeal/react-component-library";
import { useMenu } from "react-instantsearch-hooks-web";
import { useCallback, useMemo } from "react";
import { isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";

const AlgoliaSelectFilter = ({ icon, className, attribute, transformItems, label, showAll }) => {
  const _transformItems = useCallback(
    (items) =>
      items.map((item) => ({
        ...item,
        label: isAlgoliaAttributeValueEmpty(item.label) ? `No ${label}` : titleCase(item.label),
      })),
    [label]
  );

  const transformItemsFnToUse = useMemo(() => (transformItems ? transformItems : _transformItems), [transformItems]);

  const { items, refine } = useMenu({ attribute, transformItems: transformItemsFnToUse });
  const selectedFilter = useMemo(() => items.find((item) => item.isRefined) || { label: "All", value: "all" }, [items]);
  const options = useMemo(
    () => [
      { title: "All", value: "all" },
      ...items.map((item) => ({ ...item, title: item.label, metadata: item.count })),
    ],
    [items]
  );

  const onChangeFilter = (value) => {
    refine(value === "all" ? "" : value);
  };

  return (
    <ViewButton
      title={label}
      leftIcon={icon}
      className={className}
      wrapper={<FilterRadio />}
      options={options}
      label={selectedFilter.label}
      saveOnSelect
      hideFooter
      value={selectedFilter.value}
      onSave={onChangeFilter}
    />
  );
};

export default AlgoliaSelectFilter;
