import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { fill, limitPad } from "@cloudinary/url-gen/actions/resize";
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";

export const cloudinaryClient = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_NAME || "build-appeal",
  },
});

const CloudinaryImage = ({
  imgSrc = "",
  transformations = {},
  className = "",
  mRef = null,
  showFallback = false,
  altText = "",
  ...rest
}) => {
  const [externalUrl, setExternalUrl] = useState("");
  const [cldImage, setCldImg] = useState(null);
  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    if (!imgSrc) {
      return;
    }

    if (imgSrc.startsWith("http") || imgSrc.startsWith("blob")) {
      setExternalUrl(imgSrc);
      return;
    } else {
      setExternalUrl("");
    }

    const finalCloudinaryPath = `ba-media/${(process.env.REACT_APP_STAGE || "staging").toLowerCase()}/` + imgSrc;
    const cldImageVal = cloudinaryClient.image(finalCloudinaryPath);

    let finalFill = null;
    const updatedPlugins = [];
    const finalFormat = transformations?.format || "avif";
    cldImageVal.format(finalFormat).quality(transformations?.quality || "80");

    if (transformations?.height && transformations.width) {
      finalFill = fill().height(transformations.height).width(transformations.width);
    } else if (transformations?.height) {
      finalFill = fill().height(transformations.height);
    } else if (transformations?.width) {
      finalFill = limitPad().width(transformations.width);
    }
    if (transformations?.lazyload) {
      updatedPlugins.push(lazyload());
    }
    if (transformations?.placeholder) {
      const finalPlaceholderMode = transformations?.placeholder || "blur";
      updatedPlugins.push(placeholder({ mode: finalPlaceholderMode }));
    }
    if (finalFill) {
      cldImageVal.resize(finalFill);
      cldImageVal.effect("e_trim");
    }
    if (showFallback) {
      cldImageVal.delivery(defaultImage("fallback-image_y4ut9f.png"));
    }
    setPlugins(updatedPlugins);
    setCldImg(cldImageVal);
  }, [imgSrc]);

  if (externalUrl) {
    return <img alt={altText} src={externalUrl} className={className} ref={mRef} {...rest} />;
  }

  if (!cldImage) {
    return null;
  }

  return <AdvancedImage cldImg={cldImage} className={className} plugins={plugins} {...rest} />;
};

CloudinaryImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  transformations: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    responsive: PropTypes.bool,
    lazyload: PropTypes.bool,
    format: PropTypes.string,
    gravity: PropTypes.string,
    placeholder: PropTypes.string,
    sizes: PropTypes.string,
    srcSetArray: PropTypes.array,
    maxWidth: PropTypes.string,
  }),
  showFallback: PropTypes.bool,
  className: PropTypes.string,
  mRef: PropTypes.shape({ current: PropTypes.any }),
};

CloudinaryImage.displayName = "CloudinaryImage";

export default CloudinaryImage;
