import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  files: [],
  fileTypes: [],
  fileContentTypes: [],
  fileSpaces: [],
  fileUsers: [],
  fileInfoTags: [],
  fileInfoDesignStyles: [],
  fileInfoFinishes: [],
  fileInfoProductTypes: [],
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFiles: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        files: action.payload,
      };
    },
    setFileTypes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileTypes: action.payload,
      };
    },
    setFileContentTypes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileContentTypes: action.payload,
      };
    },
    setFileSpaces: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileSpaces: action.payload,
      };
    },
    setFileUsers: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileUsers: action.payload,
      };
    },
    setFileInfoTags: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileInfoTags: action.payload,
      };
    },
    setFileInfoDesignStyles: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileInfoDesignStyles: action.payload,
      };
    },
    setFileInfoFinishes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileInfoFinishes: action.payload,
      };
    },
    setFileInfoProductTypes: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        fileInfoProductTypes: action.payload,
      };
    },
  },
});

export const {
  setFiles,
  setFileTypes,
  setFileSpaces,
  setFileUsers,
  setFileContentTypes,
  setFileInfoTags,
  setFileInfoDesignStyles,
  setFileInfoFinishes,
  setFileInfoProductTypes,
} = filesSlice.actions;

export default filesSlice.reducer;

export const getFiles = (state) => state.files.files;
export const getFileTypes = (state) => state.files.fileTypes;
export const getFileContentTypes = (state) => state.files.fileContentTypes;
export const getFileSpaces = (state) => state.files.fileSpaces;
export const getFileUsers = (state) => state.files.fileUsers;
export const getFileInfoTags = (state) => state.files.fileInfoTags;
export const getFileInfoDesignStyles = (state) => state.files.fileInfoDesignStyles;
export const getFileInfoFinishes = (state) => state.files.fileInfoFinishes;
export const getFileInfoProductTypes = (state) => state.files.fileInfoProductTypes;
