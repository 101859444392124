import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import useToast from "@src/utils/hooks/useToast";
import { isImageFile } from "@src/utils/isMedia";
import { FILE_CONTENT_TYPES, FILE_CONTENT_TYPES_LABEL, FILE_PATHS, FILE_TYPES } from "@src/features/files/utils";
import useMultiFilesUpload from "../files/hooks/useMultiFilesUpload";
import useSpaces from "../spaces/hooks/useSpaces";
import useAddLook from "./hooks/useAddLook";
import lookFormSchema from "./formSchema";
import lookUiSchema from "./uiSchema";

const AddLook = ({ onClose }) => {
  const { addLookAsync, error } = useAddLook();
  const [formSchema, setFormSchema] = useState(null);
  const { addToast } = useToast();
  const { startUpload } = useMultiFilesUpload();
  const queryClient = useQueryClient();
  const { options: spacesAsOptions, isLoading: spacesLoading } = useSpaces();

  const uploadFiles = async (lookId, type = FILE_TYPES.media, filesList) => {
    const finalFilesList = (filesList || []).map((file) => {
      const fileKey = `${FILE_PATHS[FILE_CONTENT_TYPES.look]}/${lookId}/${uuidv4()}`;
      return {
        key: fileKey,
        contentType: FILE_CONTENT_TYPES_LABEL[FILE_CONTENT_TYPES.look],
        type,
        file,
        looksId: lookId ? [lookId] : null,
      };
    });
    const results = await startUpload(finalFilesList);
    if (results?.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  const handleSubmit = async (formState) => {
    if (formState.parent) {
      formState.parentId = formState.parent?.value;
    }
    const data = {
      name: formState.name,
      spaceIds: formState.spaces?.map(({ value }) => value),
    };

    delete formState.parent;
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const resp = await addLookAsync(data);
    if (!resp?.createLook?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Error creating d");
    }
    let lookMedia = [];

    if (formState?.media?.length) {
      const finalFilesList = await uploadFiles(resp.createLook?.data?.id, FILE_TYPES.media, formState.media);
      if (finalFilesList?.length) {
        lookMedia = finalFilesList.map((fileInfo) => ({
          path: fileInfo.key,
          fileType: isImageFile(fileInfo.contentType) ? "image" : "video",
        }));
      } else {
        setFormSchema((prev) => ({
          ...prev,
          actionsState: {
            isLoading: false,
          },
        }));
        addToast("Error creating Look");
      }
    }

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: false,
      },
    }));
    queryClient.setQueryData(["looks", {}], (old) => {
      return {
        getAllLook: [{ ...resp.createLook.data, media: [...lookMedia] }, ...old?.getAllLook],
      };
    });
    addToast("Look Successfully Created");
    onClose();
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    const finalSchema = {
      ...lookFormSchema,
    };
    const allProperties = lookFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "spaces") {
          acc[key].options = spacesAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;

    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [spacesAsOptions]);

  if (spacesLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return <Form formSchema={formSchema} uiSchema={lookUiSchema} isInSidebar isCreate />;
};

export default AddLook;
