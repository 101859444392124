import { TableCells, TableFilters } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Signed: "green",
  Draft: "gray",
  pending: "orange",
  active: "green",
};

export const columns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "name",
    Header: "User",
    accessor: (originalRow) => `${originalRow.type}`,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          getValue={() => originalRow.getValue().split(",")[0] ?? ""}
          avatarSrc={originalRow.row.original.avatar || ""}
          row={originalRow.row}
          column={originalRow.column}
          initials={generateInitials(originalRow.getValue() || originalRow.row.original.email)}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
    Cell: TableCells.TextCell,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
  },
];

export const typeClassNames = {
  staff: "black",
  partner: "yellow",
  supplier: "pink",
  client: "light-gray",
};

export const getPeopleSubtitle = (personType, partners, suppliers) => {
  const type = personType?.toLowerCase();
  if (!type || type === "client") {
    return "";
  }
  if (type === "staff") {
    return "BuildAppeal";
  }

  if (partners?.length) {
    return partners[0].companyName;
  }

  if (suppliers?.length) {
    return suppliers[0].name;
  }

  return "";
};

export const getPeopleReducedDescription = (type, partners, suppliers) => {
  if (type !== "Contact") {
    return type;
  }

  if (partners?.length) {
    return `Partner at ${partners[0].companyName}`;
  }
  if (suppliers?.length) {
    return `Supplier at ${suppliers[0].name}`;
  }

  return "Contact";
};

export const getPeopleBadgeType = (type, partners, suppliers) => {
  if (type !== "contact") {
    return type;
  }

  if (partners?.length) {
    return `partner`;
  }
  if (suppliers?.length) {
    return `supplier`;
  }

  return "contact";
};

export const getFullName = ({ fullName, firstName, lastName }) => {
  if (fullName) return fullName;

  if (firstName || lastName) {
    return `${firstName} ${lastName}`.trim();
  }

  return "";
};

export const PEOPLE_TYPE = {
  client: "client",
  staff: "staff",
  contact: "contact",
};
