import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  estimates: [],
  estimateItems: [],
};

export const estimatesSlice = createSlice({
  name: "estimates",
  initialState,
  reducers: {
    setEstimates: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        estimates: action.payload,
      };
    },
    setEstimate: (state, action) => {
      const originalState = original(state);
      const { estimates } = originalState;
      return {
        ...originalState,
        estimates: estimates?.length
          ? estimates.map((estimate) => {
              if (estimate.id === action.payload.id) {
                return action.payload;
              }
              return estimate;
            })
          : [action.payload],
      };
    },
  },
});

export const { setEstimates, setEstimate } = estimatesSlice.actions;

export default estimatesSlice.reducer;

export const getEstimates = (state) => state.estimates.estimates;
