import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_SUPPLIERS_QUOTES = gql`
  query getSuppliersQuotes($filters: SupplierQuoteFilterFields!) {
    getSuppliersQuotes(filters: $filters) {
      id
      total
      subTotal
      shipping
      quoteNumber
      documents {
        id
        name
      }
      project {
        id
        places {
          addressLine1
          city
          state
          zipCode
        }
      }
      supplier {
        id
        name
        addressLine1
        city
        zipCode
        state
        labelColor
      }
    }
  }
`;

export const fetchSuppliersQuotes = async (filters = {}) => {
  return await graphQLClient.request(GET_SUPPLIERS_QUOTES, { filters });
};
