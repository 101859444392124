import React, { useMemo } from "react";
import { createElement, Fragment, useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import clsx from "clsx";
import { autocomplete } from "@algolia/autocomplete-js";
import { useSearchBox } from "react-instantsearch-hooks-web";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import { algoliaSearchClient } from "@src/utils/algolia";
import "./algoliaAutoCompleteStyles.css";

const AlgoliaAutoComplete = ({
  className,
  placeholder = "Search",
  queryHook,
  querySuggestionIndex,
  detachedMediaQuery = "none",
  openOnFocus = true,
  id,
  ...autocompleteProps
}) => {
  const autocompleteContainer = useRef(null);
  const { refine, query } = useSearchBox({ queryHook });
  const [uiState, setUiState] = useState({ query });

  const plugins = useMemo(() => {
    const plugins = [];
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: `AlgoliaAutocompleteSearchBox_${id}`,
      limit: 7,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            setUiState({ query: item.label });
          },
        };
      },
    });

    plugins.push(recentSearches);

    if (querySuggestionIndex) {
      const querySuggestions = createQuerySuggestionsPlugin({
        searchClient: algoliaSearchClient,
        indexName: querySuggestionIndex,
        getSearchParams() {
          return recentSearches.data?.getAlgoliaSearchParams();
        },
        transformSource({ source }) {
          return {
            ...source,
            sourceId: "querySuggestionsPlugin",
            onSelect({ item }) {
              setUiState({ query: item.query });
            },
            getItems(params) {
              if (!params.state.query) {
                return [];
              }

              return source.getItems(params);
            },
          };
        },
      });

      plugins.push(querySuggestions);
    }

    return plugins;
  }, []);

  useEffect(() => {
    refine(uiState.query);
  }, [uiState]);

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return;
    }

    const autoCompleteInstance = autocomplete({
      ...autocompleteProps,
      placeholder,
      detachedMediaQuery,
      openOnFocus,
      container: autocompleteContainer.current,
      initialState: { query },
      onReset() {
        setUiState({ query: "" });
      },
      onSubmit({ state }) {
        setUiState({ query: state.query });
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setUiState({
            query: state.query,
          });
        }
      },
      renderer: { createElement, Fragment, render },
      plugins,
    });

    return () => {
      autoCompleteInstance.destroy();
    };
  }, []);
  return <div className={clsx("min-w-[100px]", className)} ref={autocompleteContainer} />;
};

export default AlgoliaAutoComplete;
