/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import sortBy from "lodash/sortBy";
import { UilPlus as AddIcon } from "@iconscout/react-unicons";
import noop from "lodash/noop";

import { QUERY_PARAMS_FILTERS } from "./constants";

const generateNavItem = (path, baseRoute, currentRoute) => ({
  wrapper: <Link to={`${baseRoute}/${path}`} />,
  isActive: `${baseRoute}/${path}` === currentRoute,
});

export const projectNavItems = (baseRoute, currentRoute, spaces = [], spacesAddActionClick = noop) => {
  const spacesSorted = sortBy(spaces, "sortOrder");

  return [
    {
      id: 0,
      label: "Overview",
      wrapper: <Link to={`${baseRoute}`} />,
      isActive: `${baseRoute}` === currentRoute,
    },
    {
      id: 1,
      label: "Master Board View",
      ...generateNavItem("board", baseRoute, currentRoute),
    },
    {
      id: 2,
      label: "Spaces",
      wrapper: <Link to={`${baseRoute}/spaces`} />,
      isLink: true,
      subItems: [
        ...(spacesSorted?.map((space) => ({
          id: space.id,
          label: space.spaceNickname,
          wrapper: <Link to={`${baseRoute}/spaces/${space.id}/overview`} />,
          isActive: currentRoute.startsWith(`${baseRoute}/${space.id}/`),
        })) || []),
        {
          id: "add_space",
          label: "Add Space",
          wrapper: <div />,
          icon: AddIcon,
          className: "border-t border-neutral-100",
          onClick: spacesAddActionClick,
        },
      ],
    },
    {
      id: 3,
      label: "Files",
      ...generateNavItem("files", baseRoute, currentRoute),
    },
    {
      id: 4,
      label: "Tasks",
      ...generateNavItem("tasks", baseRoute, currentRoute),
    },
    {
      id: 5,
      label: "Activity",
      ...generateNavItem("activities", baseRoute, currentRoute),
    },
    {
      id: 6,
      label: "Insights",
      ...generateNavItem("insights", baseRoute, currentRoute),
    },
    {
      id: 7,
      label: "Notes",
      rightDivider: true,
      ...generateNavItem("notes", baseRoute, currentRoute),
    },
    {
      id: 8,
      label: "Financials",
      isActive: currentRoute.includes(`${baseRoute}/financials`),
      wrapper: <Link to={`${baseRoute}/financials`} />,
      isLink: true,
      subItems: [
        {
          id: 2,
          label: "Estimates",
          ...generateNavItem("financials/estimates", baseRoute, currentRoute),
        },
        {
          id: 7,
          label: "Proposals",
          ...generateNavItem("financials/proposals", baseRoute, currentRoute),
        },
        {
          id: 0,
          label: "Invoices",
          ...generateNavItem("financials/invoices", baseRoute, currentRoute),
        },
        {
          id: 4,
          label: "Budgets",
          ...generateNavItem("financials/budgets", baseRoute, currentRoute),
        },
        {
          id: 8,
          label: "Purchases",
          ...generateNavItem("financials/purchases", baseRoute, currentRoute),
        },
        {
          id: 1,
          label: "Bills",
          ...generateNavItem("financials/bills", baseRoute, currentRoute),
        },

        {
          id: 5,
          label: "Purchase Orders",
          ...generateNavItem("financials/purchase-orders", baseRoute, currentRoute),
        },
        {
          id: 6,
          label: "Change Orders",
          ...generateNavItem("financials/change-orders", baseRoute, currentRoute),
        },
      ],
    },
    {
      id: 9,
      label: "Settings",
      wrapper: <Link to={`${baseRoute}/settings`} />,
      isLink: true,
      subItems: [
        {
          id: 0,
          label: "General",
          ...generateNavItem("settings", baseRoute, currentRoute),
        },
        {
          id: 1,
          label: "Team",
          ...generateNavItem("settings/team", baseRoute, currentRoute),
        },
        {
          id: 2,
          label: "Spaces",
          ...generateNavItem("settings/spaces", baseRoute, currentRoute),
        },
        {
          id: 3,
          label: "Notifications",
          ...generateNavItem("settings/notifications", baseRoute, currentRoute),
        },
        {
          id: 4,
          label: "Permissions",
          ...generateNavItem("settings/permissions", baseRoute, currentRoute),
        },
      ],
    },
    {
      id: 10,
      label: "More",
      subItems: [
        {
          id: 0,
          label: "Messages",
          ...generateNavItem("messages", baseRoute, currentRoute),
        },
        {
          id: 1,
          label: "Products",
          ...generateNavItem("products", baseRoute, currentRoute),
        },
        {
          id: 2,
          label: "Contracts",
          ...generateNavItem("contracts", baseRoute, currentRoute),
        },
        {
          id: 3,
          label: "Suppliers",
          ...generateNavItem("suppliers", baseRoute, currentRoute),
        },
        {
          id: 4,
          label: "Emails",
          ...generateNavItem("emails", baseRoute, currentRoute),
        },
        {
          id: 5,
          label: "Presentations",
          ...generateNavItem("designs/presentations", baseRoute, currentRoute),
        },
        {
          id: 6,
          label: "Storyboards",
          ...generateNavItem("designs/storyboards", baseRoute, currentRoute),
        },
        {
          id: 7,
          label: "Deliveries",
          ...generateNavItem("deliveries", baseRoute, currentRoute),
        },
        {
          id: 8,
          label: "Actions",
          ...generateNavItem("actions", baseRoute, currentRoute),
        },
        {
          id: 9,
          label: "Looks",
          ...generateNavItem("looks", baseRoute, currentRoute),
        },
      ],
      isLink: true,
      wrapper: <span />,
    },
  ];
};

export const navUserItems = ({ id, role }) => [
  {
    id: 1,
    label: "Profile",
    wrapper: <div />,
    quickViewProps: {
      path: "/people",
      data: {
        id,
        type: role?.toLowerCase(),
      },
    },
  },
  { id: 2, label: "Logout", wrapper: <Link to="/logout" /> },
];

const moreItemsStaff = [
  {
    id: 5,
    label: "Products",
    wrapper: <Link to={`/products`} />,
  },
  {
    id: 18,
    label: "Partners",
    wrapper: <Link to={`/partners`} />,
  },
  {
    id: 14,
    label: "People",
    wrapper: <Link to="/people" />,
  },
  {
    id: 6,
    label: "Suppliers",
    wrapper: <Link to={`/suppliers`} />,
  },
  {
    id: 21,
    label: "Tasks",
    wrapper: <Link to="/tasks" />,
  },
  {
    id: 13,
    label: "Design Styles",
    wrapper: <Link to="/designs/styles?view=card" />,
  },
  {
    id: 20,
    label: "Looks",
    wrapper: <Link to="/looks?view=card" />,
  },
  {
    id: 7,
    label: "Emails",
    wrapper: <Link to={`/emails`} />,
  },
  {
    id: 12,
    label: "Financials",
    wrapper: <Link to="/financials/invoices" />,
  },
];

export const menuItems = [
  {
    id: 1,
    label: "Projects",
    wrapper: <Link to="/projects" />,
  },
  {
    id: 2,
    label: "Leads",
    wrapper: <Link to="/leads" />,
  },
  {
    id: 3,
    label: "Events",
    wrapper: <Link to="/consultations" />,
  },
  {
    id: 4,
    label: "Files",
    wrapper: <Link to="/files" />,
  },
  {
    id: 5,
    label: "Activity",
    wrapper: <Link to="/feed" />,
  },
  {
    id: 6,
    label: "Notes",
    wrapper: <Link to="/notes" />,
  },
  {
    id: 7,
    label: "More",
    subItems: moreItemsStaff,
    isLink: true,
    wrapper: <span />,
  },
];

export const linkWrapper = (item) => ({
  ...item,
  wrapper: <a href={item.wrapper?.props?.to}>{item.wrapper}</a>,
});

export const linkMapper = (items) => {
  return items.map((item) => {
    if (item.items) {
      return {
        ...item,
        items: linkMapper(item.items),
      };
    }
    return linkWrapper(item);
  });
};

export const fallbackMenuItems = menuItems.map((item) => {
  if (item.subItems) {
    return {
      ...item,
      subItems: linkMapper(item.subItems),
    };
  }
  return linkWrapper(item);
});

export const errorHandler = (error, info) => {
  if (process.env.REACT_APP_STAGE !== "production") {
    console.log("@@ error occured", error, info);
  }
};

export const getTableColumnsForPage = (
  fields,
  filters,
  rowMenuActions,
  tableColumnsMap,
  tableActionsMap,
  onOpenClick,
  onArrowClick,
  onQuickViewOpen
) => {
  const columns = [];
  const actions = [];

  tableActionsMap &&
    rowMenuActions.forEach((action, index) => {
      if (tableActionsMap[action]) {
        actions.push(tableActionsMap[action]);
      }
    });

  fields.forEach((field, index) => {
    if (tableColumnsMap[field]) {
      const finalColumn = { ...tableColumnsMap[field] };
      if (tableActionsMap) {
        finalColumn["actions"] = actions;
        finalColumn["onOpenClick"] = onOpenClick;
      }

      if (onArrowClick) finalColumn["onArrowClick"] = onArrowClick;
      if (onQuickViewOpen) finalColumn["onQuickViewOpen"] = onQuickViewOpen;

      if (!filters.includes(field)) {
        delete finalColumn.filter;
        delete finalColumn.Filter;
      }
      columns.push(finalColumn);
    }
  });
  return columns;
};

// Use this function to hide table columns on the master layout based on the current path.
export const tableColumnVisibilityProps = (currentPath) => {
  const tableColumnVisibility = {};

  if (currentPath === "/products") {
    tableColumnVisibility.materials = false;
    tableColumnVisibility.finishes = false;
    tableColumnVisibility.spaces = false;
    tableColumnVisibility.designStyles = false;
    tableColumnVisibility.colors = false;
    tableColumnVisibility.attributes = false;
  }

  if (currentPath === "/partners") {
    tableColumnVisibility.markets = false;
  }

  return tableColumnVisibility;
};

export const toggleHtmlClass = (className, isAdd) => {
  if (isAdd) {
    document.documentElement.classList.add(className);
    return;
  }
  document.documentElement.classList.remove(className);
};

export const variantIdRegex = /^.*[a-z]$/;

export const initExternalFilters = (searchParams) => {
  const {
    ExternalFilterString,
    ExternalFilterBoolean,
    ExternalFilterNumber,
    ExternalFilterArrayNumber,
    ExternalFilterArrayString,
  } = QUERY_PARAMS_FILTERS;

  const filters = {};
  const searchParamsEntries = searchParams.entries();

  for (const [key, value] of searchParamsEntries) {
    const keyName = key.slice(7);

    if (key.includes(ExternalFilterString)) {
      filters[keyName] = value;
    } else if (key.includes(ExternalFilterBoolean)) {
      filters[keyName] = value === "true";
    } else if (key.includes(ExternalFilterNumber)) {
      filters[keyName] = Number(value);
    } else if (key.includes(ExternalFilterArrayNumber)) {
      if (filters[keyName]) {
        filters[keyName].push(Number(value));
      } else {
        filters[keyName] = [Number(value)];
      }
    } else if (key.includes(ExternalFilterArrayString)) {
      if (filters[keyName]) {
        filters[keyName].push(value);
      } else {
        filters[keyName] = [value];
      }
    }
  }
  return filters;
};
