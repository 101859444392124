export const quoteUISchema = {
  groups: [
    {
      title: "Basics",
      properties: ["project", "total", "quoteNumber"],
    },
    {
      title: "Documents",
      properties: ["document"],
      isLast: true,
    },
  ],
};

export const contactUiSchema = {
  groups: [
    {
      title: "Details",
      properties: ["firstName", "phone", "email", "isPrimary"],
    },
    {
      title: "Profile Photo",
      properties: ["avatar"],
      isLast: true,
    },
  ],
};

export const uiSchema = {
  groups: [
    {
      title: "Business",
      properties: ["name", "email", "phone", "website", "categories"],
    },
    {
      title: "Location",
      properties: ["address"],
    },
    {
      title: "Logo",
      properties: ["logo"],
    },
    {
      title: "Contact",
      properties: ["contactFirstName", "contactLastName", "contactEmail", "contactPhone", "checkBoxVal", "contact"],
    },
  ],
};
