import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { addMaterial } from "../../api";

const useAddMaterial = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addMaterialMutation = useMutation(
    (input) => {
      setError(null);
      return addMaterial(input);
    },
    {
      onSuccess: (data) => {
        if (!data.addMaterial?.success) {
          setError(`Error: ${data.addMaterial?.message}`);
        } else {
          queryClient.setQueryData(["materials", filters], (old) => {
            if (!old) return { getMaterials: [data.addMaterial.data] };
            return {
              getMaterials: [data.addMaterial?.data, ...old.getMaterials],
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    addMaterial: addMaterialMutation.mutate,
    addMaterialAsync: addMaterialMutation.mutateAsync,
    isLoading: addMaterialMutation.isLoading,
    isSuccess: addMaterialMutation.isSuccess,
  };
};

export default useAddMaterial;
