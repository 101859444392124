import { Modal, Button } from "@buildappeal/react-component-library";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onDelete,
  title,
  message,
  info,
  isLoading = false,
  hideDelete = false,
  button1Label = "",
}) => {
  return (
    <Modal title={title} isOpen={isOpen} onClose={() => onClose()}>
      <>
        <div className="m-10">
          <h4 className="mb-3 text-xl font-light text-neutral-800">{message}</h4>
          <span className="text-md font-light text-primary-800">{info}</span>
        </div>
        <div className="border-greey-200 mt-20 flex justify-end border-x border-y p-6">
          <Button
            className="mr-2"
            appearance="secondary"
            label={button1Label || "No, Cancel"}
            size="sm"
            onClick={() => onClose()}
          />
          {!hideDelete ? (
            <Button
              className="bg-red-700"
              label="Yes, Delete"
              size="sm"
              onClick={() => onDelete()}
              isLoading={isLoading}
            />
          ) : null}
        </div>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
