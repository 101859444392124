const allPagesAlgoliaConfigMap = {
  "/projects": { indexName: "projects" },
  "/leads": { indexName: "projects" },
  "/people": { indexName: "people" },
  "/partners": { indexName: "companies" },
  "/suppliers": { indexName: "companies" },
  "/products": { indexName: "products" },
  "/consultations": { indexName: "consultations" },
  "/designs/styles": { indexName: "designStyles" },
  "/tasks": { indexName: "tasks" },
  "/notes": { indexName: "notes" },
  "/feed": { indexName: "feed" },
};

export default allPagesAlgoliaConfigMap;
