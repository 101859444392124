import isEqual from "react-fast-compare";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInfiniteHits, useInstantSearch } from "react-instantsearch-hooks-web";
import { omitBy } from "lodash";
import { isAlgoliaAttributeValueEmpty } from "../common";

function getStateWithoutPage(state) {
  const { page, ...rest } = state || {};
  return rest;
}

function getInMemoryCache(cacheId) {
  let cachedHits = {};
  let cachedState = {};
  return {
    read({ state }) {
      return isEqual(cachedState[cacheId], getStateWithoutPage(state)) ? cachedHits[cacheId] : null;
    },
    write({ state, hits }) {
      cachedState[cacheId] = getStateWithoutPage(state);
      cachedHits[cacheId] = hits;
    },
    clear() {
      cachedHits[cacheId] = undefined;
      cachedState[cacheId] = undefined;
    },
    deleteHits(ids) {
      Object.keys(cachedHits[cacheId]).forEach((key) => {
        cachedHits[cacheId][key] = cachedHits[cacheId][key].filter((hit) => !ids.includes(hit.id));
      });
    },
    addHits(hits) {
      if (!cachedHits[cacheId] || !cachedHits[cacheId][0]) {
        cachedHits[cacheId] = {
          0: [...hits],
        };
      } else {
        hits.forEach((hit) => {
          const isHitInCache = cachedHits[cacheId][0].some((h) => h.id === hit.id);
          if (!isHitInCache) {
            cachedHits[cacheId][0].unshift(hit);
          }
        });
      }
    },
  };
}

const getCache = (cacheId) => getInMemoryCache(cacheId);

const useAlgoliaInfiniteHits = (cacheId) => {
  const cache = useMemo(() => getCache(cacheId), [cacheId]);
  const { hits, showMore, isLastPage, results } = useInfiniteHits({
    cache,
  });
  const { results: searchResults, refresh } = useInstantSearch();
  const [isFetchingNextPage, setIsFetchingnextPage] = useState(false);

  const noResults = useMemo(
    () => !searchResults.__isArtificial && searchResults.nbHits === 0,
    [searchResults.__isArtificial, searchResults.nbHits]
  );

  useEffect(() => {
    setIsFetchingnextPage(false);
  }, [results.page]);

  useEffect(() => {
    if (hits.length >= searchResults.nbHits) {
      setIsFetchingnextPage(false);
    }
  }, [hits.length, searchResults.nbHits]);

  const isLoading = useMemo(
    () => !!searchResults.__isArtificial && searchResults.nbHits === 0,
    [searchResults.__isArtificial, searchResults.nbHits]
  );
  const finalHits = useMemo(() => {
    return hits.map((hit) => omitBy(hit, (val) => isAlgoliaAttributeValueEmpty(val)));
  }, [hits, hits.length]);

  const fetchNextPage = useCallback(() => {
    if (!isLastPage) {
      setIsFetchingnextPage(true);
      showMore?.();
    }
  }, [showMore, isLastPage]);

  const refreshResults = (hits = [], action) => {
    if (hits.length && action === "delete") {
      cache.deleteHits(hits.map((hit) => hit.id));
    }
    if (hits.length && action === "add") {
      cache.addHits(hits);
    }
    setTimeout(() => {
      refresh();
    }, [5000]);
  };

  return {
    isLoading,
    isEmpty: noResults,
    isFetchingNextPage,
    fetchNextPage,
    isLastPage,
    hits: finalHits,
    totalCount: results.nbHits,
    results,
    refreshResults,
  };
};

export default useAlgoliaInfiniteHits;
