import { UilEllipsisV as MenuIcon } from "@iconscout/react-unicons";
import { TableCells, TableFilters, TableCards, Button, NavCard } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const peopleColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  avatar: {
    id: "avatar",
    Header: "avatar",
    emailAccessor: "personType",
    accessor: (originalRow) => `${originalRow?.firstName || ""} ${originalRow?.lastName || ""}`,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={`${originalRow.row.original?.firstName || ""} ${originalRow.row.original?.lastName || ""}`}
          avatarSrc={originalRow?.row?.original?.avatar || ""}
          row={originalRow.row?.original}
          column={originalRow.column}
          initials={generateInitials(
            `${originalRow.row.original?.firstName || ""} ${originalRow.row.original?.lastName || ""}` ||
              originalRow.row?.original?.email ||
              originalRow.row.original?.personType ||
              ""
          )}
          {...originalRow}
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          image={original?.avatar}
          actions={column.actions || []}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
          imageContainerClassName="h-64 flex items-center justify-center bg-neutral-100"
          imageClassName="w-32 h-32 object-cover rounded-full"
          imageOnly
        />
      );
    },
  },
  user: {
    id: "name",
    Header: "Name",
    accessor: (originalRow) => `${originalRow?.firstName || ""} ${originalRow?.lastName || ""}`,
    emailAccessor: "personType",
    Cell: ({ row: { original }, column, table, ...rest }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={original?.fullName ? original?.fullName : `${original?.firstName || ""} ${original?.lastName || ""}`}
          description={original?.description}
          avatar={original?.avatar || ""}
          row={original}
          column={column}
          initials={generateInitials(
            `${original?.firstName || ""} ${original?.lastName || ""}` || original?.email || original?.personType || ""
          )}
          {...rest}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: ({ row, column, table }) => {
      return (
        <TableCards.TitleRow
          getValue={() => {
            return `${row.original?.firstName || ""} ${row.original?.lastName || ""}`;
          }}
          subtitle={row.original?.personType}
          row={row}
          column={column}
          table={table}
        />
      );
    },
    maxWidth: "3fr",
  },
  type: {
    id: "type",
    Header: "Type",
    accessor: "personType",
    Cell: TableCells.TextCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    className: "w-[10%] hidden",
    renderCard: TableCards.TextRow,
  },
  phone: {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
    renderCard: TableCards.TextRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  email: {
    id: "email",
    Header: "Email",
    accessor: "email",
    Cell: (originalRow) => {
      const handleClick = () => {
        originalRow?.column?.onOpenClick("send", originalRow);
      };
      const isLoading = !!originalRow?.table?.options?.meta?.isLoading;

      return originalRow?.row?.original?.email || isLoading ? (
        <div className="p-4" onClick={(e) => e?.stopPropagation()}>
          <Button isDataLoading={isLoading} label="Message" onClick={handleClick} appearance="secondary" />
        </div>
      ) : (
        <div />
      );
    },
    renderCard: TableCards.LinkRow,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: (row) => (row?.status === "active" ? "Active" : "Inactive"),
    Cell: (originalRow) => {
      const status = originalRow.row.original.status;
      return <TableCells.StatusCell status={status === "active" ? "Active" : "Inactive"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    statusAccessor: "status",
    renderCard: TableCards.StatusRow,
  },
  action: {
    id: "action",
    Header: "Action",
    accessor: "action",
    renderCard: TableCards.TextRow,
    Cell: (originalRow) => {
      const options = [
        {
          id: `edit`,
          label: "Edit",
          wrapper: <div />,
          onClick: () => console.log("Edit"),
        },
        {
          id: `view`,
          label: "View",
          wrapper: <div />,
          onClick: () => console.log("View"),
        },
        {
          id: `delete`,
          label: "Delete",
          wrapper: <div />,
          onClick: () => console.log("Delete"),
        },
      ];
      return <TableCells.IconButtonCell icon={MenuIcon} options={options} {...originalRow} />;
    },
  },
};
