import React, { useEffect, Suspense } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { QuickViewProvider } from "./features/layout/contexts/QuickViewContext";
import ToastProvider from "./utils/providers/toast/ToastProvider";
import usePageTitle from "./utils/hooks/usePageTitle";
import AuthCheck from "./features/auth/AuthCheck";
import Error404Page from "./features/layout/Error404Page";
import Files from "./features/files/Files";
import Layout from "./features/layout/Layout";
import Leads from "./features/leads/Leads";
import Login from "./features/auth/Login";
import Logout from "./features/auth/Logout";
import Search from "./features/search/Search";
import Dashboard from "./features/dashboard/Dashboard";
import Loading from "./utils/components/Loading";

import GeneralActions from "./utils/components/GeneralActions";
import { GeneralActionsProvider } from "./utils/providers/generalActions";
import { SupabaseProvider } from "./utils/providers/SupabaseProvider";
import { LOADING_TYPES } from "./utils/constants";
import lazyWithRetry from "./utils/LazyWithRetry";

const Activities = lazyWithRetry(() => import("./features/activities/Activities"));
const ActivityFeeds = lazyWithRetry(() => import("./features/activities/ActivityFeeds"));
const Budgets = lazyWithRetry(() => import("./features/budgets/Budgets"));
const BudgetItems = lazyWithRetry(() => import("./features/budgets/BudgetItems"));
const Bills = lazyWithRetry(() => import("./features/bills/Bills"));
const BillItems = lazyWithRetry(() => import("./features/bills/BillItems"));
const Bids = lazyWithRetry(() => import("./features/bids/Bids"));
const ChangeOrders = lazyWithRetry(() => import("./features/changeOrders/ChangeOrders"));
const ChangeOrderItems = lazyWithRetry(() => import("./features/changeOrders/ChangeOrderItems"));
const Contracts = lazyWithRetry(() => import("./features/contracts/Contracts"));
const Estimates = lazyWithRetry(() => import("./features/estimates/Estimates"));
const Invoices = lazyWithRetry(() => import("./features/invoices/Invoices"));
const InvoiceItems = lazyWithRetry(() => import("./features/invoices/InvoiceItems"));
const Notes = lazyWithRetry(() => import("./features/notes/Notes"));
const Partner = lazyWithRetry(() => import("./features/partners/Partner"));
const Partners = lazyWithRetry(() => import("./features/partners/Partners"));
const Presentations = lazyWithRetry(() => import("./features/presentations/Presentations"));
const PurchaseOrders = lazyWithRetry(() => import("./features/purchaseOrders/PurchaseOrders"));
const PurchaseOrderItems = lazyWithRetry(() => import("./features/purchaseOrders/PurchaseOrderItems"));
const Proposals = lazyWithRetry(() => import("./features/proposals/Proposals"));
const Tasks = lazyWithRetry(() => import("./features/tasks/Tasks"));
const ActionsObjects = lazyWithRetry(() => import("./features/actionsObjects/ActionsObjects"));
const ActionsObjectsMatrix = lazyWithRetry(() => import("./features/actionsObjects/ActionsObjectsMatrix"));
const Storyboards = lazyWithRetry(() => import("./features/storyboards/Storyboards"));
const Purchases = lazyWithRetry(() => import("./features/purchases/Purchases"));
const PurchaseItems = lazyWithRetry(() => import("./features/purchases/PurchaseItems"));
const DesignStyle = lazyWithRetry(() => import("./features/designs/DesignStyles"));
const Suppliers = lazyWithRetry(() => import("./features/suppliers/Suppliers"));
const Messages = lazyWithRetry(() => import("./features/messages/Messages"));
const Consultations = lazyWithRetry(() => import("./features/consultations/Consultations"));
const Product = lazyWithRetry(() => import("./features/products/Product"));
const Products = lazyWithRetry(() => import("./features/products/Products"));
const Services = lazyWithRetry(() => import("./features/services/Services"));
const ServicePartners = lazyWithRetry(() => import("./features/services/ServicePartners"));
const ServiceMarkets = lazyWithRetry(() => import("./features/services/ServiceMarkets"));
const MarketPartners = lazyWithRetry(() => import("./features/markets/MarketPartners"));
const MarketServices = lazyWithRetry(() => import("./features/markets/MarketServices"));
const MarketSuppliers = lazyWithRetry(() => import("./features/markets/MarketSuppliers"));
const Markets = lazyWithRetry(() => import("./features/markets/Markets"));
const Materials = lazyWithRetry(() => import("./features/materials/Materials"));
const Finishes = lazyWithRetry(() => import("./features/finishes/Finishes"));

const Contacts = lazyWithRetry(() => import("./features/contacts/Contacts"));
const Financials = lazyWithRetry(() => import("./features/financials/Financials"));
const Emails = lazyWithRetry(() => import("./features/emails/Emails"));
const ActionsObjectsStages = lazyWithRetry(() => import("./features/actionsObjectsStages/ActionsObjectsStages"));
const Looks = lazyWithRetry(() => import("./features/looks/Looks"));
const Objects = lazyWithRetry(() => import("./features/objects/Objects"));
const CSVUpdater = lazyWithRetry(() => import("./utils/components/CSVUpdater"));
const Clients = lazyWithRetry(() => import("./features/clients/Clients"));
const Users = lazyWithRetry(() => import("./features/users/Users"));
const User = lazyWithRetry(() => import("./features/users/User"));
const Staff = lazyWithRetry(() => import("./features/staff/Staff"));
const Lead = lazyWithRetry(() => import("./features/leads/Lead"));
const File = lazyWithRetry(() => import("./features/files/File"));
const MyTasks = lazyWithRetry(() => import("./features/tasks/MyTasks"));
const Settings = lazyWithRetry(() => import("./features/settings/Settings"));
const People = lazyWithRetry(() => import("./features/people/People"));
const Projects = lazyWithRetry(() => import("./features/projects/Projects"));

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const App = () => {
  const location = useLocation();
  const pageTitle = usePageTitle();
  let prevPath = null;

  useEffect(() => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
      window.analytics.page();
    }
  }, [location]);

  return (
    <div className="app">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <GeneralActionsProvider>
        <QuickViewProvider>
          <ToastProvider>
            <AuthCheck>
              <SupabaseProvider>
                <Suspense fallback={<Loading type={LOADING_TYPES.HOUSE} isFullScreen />}>
                  <Routes>
                    <Route path="/" element={<Layout />}>
                      <Route index element={<Dashboard />} />
                      <Route path="projects" element={<Projects />} />
                      <Route path="emails" element={<Emails />} />
                      <Route path="looks" exact element={<Looks />} />
                      {/* Global Settings Nav */}
                      <Route path="settings" element={<Settings />}>
                        <Route index element={<h1>General</h1>} />
                        <Route path="notifications" element={<h1>Notifications</h1>} />
                        <Route path="permissions" element={<h1>Permissions</h1>} />
                        <Route path="margins" element={<h1>Margins</h1>} />
                      </Route>

                      {/* Global Users Nav */}
                      <Route path="users" element={<Users />} />
                      <Route path="staff" element={<Staff />} />
                      <Route path="clients" element={<Clients />} />
                      <Route path="partners" element={<Partners />} />
                      <Route path="contacts" element={<Contacts />} />
                      <Route path="people" element={<People />} />

                      {/* Global Markets Nav */}
                      <Route path="markets" element={<Markets />}>
                        <Route index element={<Markets />} />
                        <Route path="partners" element={<MarketPartners />} />
                        <Route path="services" element={<MarketServices />} />
                        <Route path="suppliers" element={<MarketSuppliers />} />
                      </Route>

                      <Route path="tasks" element={<Tasks />} />
                      <Route path="my-tasks" element={<MyTasks />} />
                      <Route path="tasks/actions" element={<ActionsObjects />} />
                      <Route path="tasks/actions/matrix" element={<ActionsObjectsMatrix />} />
                      <Route path="leads" element={<Leads />} />
                      <Route path="leads/:leadId" element={<Lead />} />
                      <Route path="messages" element={<Messages />} />
                      <Route path="financials" element={<Financials />}>
                        <Route path="estimates" element={<Estimates />} />
                        <Route path="invoices" element={<Invoices />} />
                        <Route path="invoices/items" element={<InvoiceItems />} />
                        <Route path="bids" element={<Bids />} />
                        <Route path="bills" element={<Bills />} />
                        <Route path="bills/items" element={<BillItems />} />
                        <Route path="change-orders" element={<ChangeOrders />} />
                        <Route path="change-orders/items" element={<ChangeOrderItems />} />
                        <Route path="purchase-orders" element={<PurchaseOrders />} />
                        <Route path="purchase-orders/items" element={<PurchaseOrderItems />} />
                        <Route path="budgets" element={<Budgets />} />
                        <Route path="budgets/items" element={<BudgetItems />} />
                        <Route path="proposals" element={<Proposals />} />
                        <Route path="purchases" element={<Purchases />} />
                        <Route path="purchases/items" element={<PurchaseItems />} />
                      </Route>
                      <Route path="products/:productId" exact element={<Product />} />
                      <Route path="products" exact element={<Products />} />
                      <Route path="consultations" element={<Consultations />} />
                      <Route path="contracts" element={<Contracts />} />

                      <Route path="finishes" exact element={<Finishes />} />
                      <Route path="objects" exact element={<Objects />} />

                      {/* Global Designs Nav */}
                      <Route path="designs">
                        <Route path="styles" element={<DesignStyle />} />
                        <Route path="presentations" element={<Presentations />} />
                        <Route path="storyboards" element={<Storyboards />} />
                      </Route>
                      {/* Global Services Nav */}
                      <Route path="services" element={<Services />}>
                        <Route index element={<Services />} />
                        <Route path="partners" element={<ServicePartners />} />
                        <Route path="markets" element={<ServiceMarkets />} />
                      </Route>
                      <Route path="search" element={<Search />} />
                      <Route path="files" element={<Files />} />
                      <Route path="partners/:partnerId" element={<Partner />} />
                      <Route path="users/:userId" element={<User />} />
                      <Route path="notes" element={<Notes />} />
                      <Route path="activities" element={<Activities />} />
                      <Route path="feed" element={<ActivityFeeds />} />
                      <Route path="suppliers" element={<Suppliers />} />
                      <Route path="*" element={<Error404Page />} />
                      <Route path="/actionsobjects/stages" element={<ActionsObjectsStages />} />
                      <Route path="materials" element={<Materials />} />
                      <Route path="/csv-update" element={<CSVUpdater />} />
                    </Route>
                    <Route path="/files/:fileType/:fileId" element={<File />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/callback" element={<Login callback />} />
                    <Route path="/logout" element={<Logout />} />
                  </Routes>
                  <GeneralActions />
                </Suspense>
              </SupabaseProvider>
            </AuthCheck>
          </ToastProvider>
        </QuickViewProvider>
      </GeneralActionsProvider>
    </div>
  );
};

export default App;
