import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { UilAngleLeft, UilPlus as AddIcon } from "@iconscout/react-unicons";
import { IconButton, NavCard, Button, CloudinaryImage } from "@buildappeal/react-component-library";
import clsx from "clsx";
import sortBy from "lodash/sortBy";

import { FALLBACK_IMAGE_SRC } from "@utils/constants";
import { QUERY_PARAMS } from "@features/layout/constants";
import AddProjectSpace from "@features/spaces/AddProjectSpace";
import useProjectDetail from "@features/projects/hooks/useProjectDetail";
import SpaceItem from "@features/projects/view/SpaceItem";
import { ProjectSectionsNavItems } from "@features/projects/view/utils";

const ProjectLeftUICustom = ({ onBack, row, isConsultationMode, ...rest }) => {
  const { data: project } = useProjectDetail(row?.id);
  const [isOpenModalAddProjectSpace, showModalAddProjectSpace] = useState(false);
  const [params, setParams] = useSearchParams();

  const handleClickSpace = (space) => {
    if (space) {
      params.set(QUERY_PARAMS.Space, space.id);
      params.set(QUERY_PARAMS.Id, row?.id);
      params.delete(QUERY_PARAMS.Section);
      setParams(params);
    } else {
      params.set(QUERY_PARAMS.Id, row?.id);
      params.delete(QUERY_PARAMS.Space);
      params.delete(QUERY_PARAMS.Section);
      setParams(params);
    }
  };

  const handleBack = () => {
    params.delete(QUERY_PARAMS.Space);
    params.delete(QUERY_PARAMS.ShowCustomLeft);
    setParams(params);
    onBack();
  };
  const handleNavItemClick = (navItem) => {
    params.set(QUERY_PARAMS.Id, row?.id);
    params.set(QUERY_PARAMS.Section, navItem.id);
    params.delete(QUERY_PARAMS.Space);
    setParams(params);
  };

  const projectSpaceId = parseInt(params.get(QUERY_PARAMS.Space), 10);
  const projectSection = params.get(QUERY_PARAMS.Section);

  useEffect(() => {
    const spaceProject = project?.spaces?.find((space) => space.id === projectSpaceId);

    if (project && !spaceProject) {
      params.delete(QUERY_PARAMS.Space);
      setParams(params);
    }
  }, [project, projectSpaceId]);

  const projectSpacesSorted = useMemo(() => {
    if (!project?.spaces) return [];

    return sortBy(project.spaces, ["sortOrder", "spaceNickname"]);
  }, [project?.spaces]);

  return (
    <div className="h-full">
      <div className={clsx(isConsultationMode ? "" : "max-h-[calc(100vh_-_224px)] overflow-y-auto")}>
        <div className="mb-4 divide-y dark:divide-midnightborder">
          <div className="pb-2">
            <div
              className={clsx(
                "group flex cursor-pointer items-center space-x-5 rounded-lg p-3 hover:bg-neutral-50 active:bg-neutral-200 dark:bg-midnight",
                !projectSpaceId && !projectSection ? "bg-neutral-100" : ""
              )}
              onClick={() => handleClickSpace()}
            >
              {isConsultationMode ? (
                <CloudinaryImage
                  imgSrc={project?.thumbnail?.path || FALLBACK_IMAGE_SRC}
                  className={"h-10 w-10 rounded"}
                />
              ) : (
                <IconButton className="shrink-0" icon={UilAngleLeft} onClick={handleBack} />
              )}
              <div className="ml-2 flex flex-col">
                <span className="text-sm font-medium">{project?.places?.addressLine1}</span>
                <span className="text-xs text-neutral-500">
                  {project?.places?.city}, {project?.places?.state} {project?.places?.zipCode}
                </span>
              </div>
            </div>
          </div>

          {projectSpacesSorted.length > 0 &&
            projectSpacesSorted.map((space) => (
              <SpaceItem key={space.id} space={space} projectId={project?.id} view="list" className="py-2" />
            ))}
        </div>

        <Button
          label="Add Space"
          icon={AddIcon}
          appearance="tertiary"
          onClick={() => showModalAddProjectSpace(true)}
          className="mb-2"
        />
      </div>

      <div className="px-8 pt-4">
        {!isConsultationMode &&
          ProjectSectionsNavItems.map((navItem) => (
            <NavCard
              className="h-[48px] py-0"
              key={navItem.id}
              title={navItem.label}
              showHover
              onClick={() => handleNavItemClick(navItem)}
              isActive={projectSection === navItem.id}
            />
          ))}
      </div>

      <AddProjectSpace
        isOpen={isOpenModalAddProjectSpace}
        onClose={() => showModalAddProjectSpace(false)}
        selectedProject={project}
      />
    </div>
  );
};

export default ProjectLeftUICustom;
