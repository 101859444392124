import { transformConsultationBoardViewData } from "../consultations/utils";
import { transformProjectBoardViewData } from "../projects/utils";

const boardViewDataUtilsMap = {
  "/projects": transformProjectBoardViewData,
  "/leads": transformProjectBoardViewData,
  "/consultations": transformConsultationBoardViewData,
};

export default boardViewDataUtilsMap;
