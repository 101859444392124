export const ApplyProductType = {
  SqFt: "sqFt",
  Picker: "picker",
};

export const ApplyProductOption = {
  Wall: "Wall",
  Ceiling: "Ceiling",
  Floor: "Floor",
};
