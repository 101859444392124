import { Sidebar, Tabs } from "@buildappeal/react-component-library";
import noop from "lodash/noop";

import AddMultipleSpace from "./AddMultipleSpace";
import SingleSpace from "./AddSingleSpace";

const AddProjectSpace = ({
  isOpen,
  onClose,
  selectedProject,
  onSpaceObjectAddSuccess = noop,
  onSuccess,
  showToast = true,
  className,
}) => {
  const handleOnClose = () => {
    // setFormState({ ...initialFormState });
    // setShowErrorMessage(false);
    // setShowSuccessMessage(false);
    onClose();
  };

  const tabs = [
    {
      id: 0,
      label: "Multiple Spaces",
      content: (
        <AddMultipleSpace
          isOpen={isOpen}
          onClose={onClose}
          selectedProject={selectedProject}
          handleOnClose={handleOnClose}
          onSuccess={onSuccess}
          showToast={showToast}
        />
      ),
    },
    {
      id: 1,
      label: "Single Space",
      content: (
        <SingleSpace
          isOpen={isOpen}
          onClose={onClose}
          selectedProject={selectedProject}
          onSpaceObjectAddSuccess={onSpaceObjectAddSuccess}
          handleOnClose={handleOnClose}
          onSuccess={onSuccess}
          showToast={showToast}
        />
      ),
    },
  ];

  return (
    <Sidebar isOpen={isOpen} onClose={handleOnClose} className={className}>
      <div className="flex h-full w-full flex-col justify-center px-10 pt-8">
        <h3 className="text-xl ">Add Space</h3>
        <Tabs tabs={tabs} classNameHeader="sticky top-0 z-[9]" className="flex flex-1 flex-col" />
      </div>
    </Sidebar>
  );
};
export default AddProjectSpace;
