import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createContact } from "../../api";

const useContactAdd = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      setIsSuccess(false);
      return createContact(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createContact.success) {
          setError(`Error: ${data.createContact.message}`);
        } else {
          setIsSuccess(true);
          queryClient.invalidateQueries("contacts");
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    addContact: mutate,
    isLoading,
    isSuccess,
    error,
    addContactAsync: mutateAsync,
  };
};

export default useContactAdd;
