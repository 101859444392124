import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { deleteFiles } from "../../api";
import { getFiles } from "../../filesSlice";

const useDeleteFiles = ({
  projectId,
  skipQueryUpdate = false,
  fileType,
  spaceId,
  createdBy,
  contentType,
  projectSpacesId,
  ids,
  noSpaces = false,
  noContentType = false,
  noTags = false,
  noFileType = false,
  noCreatedBy = false,
  noDesignStyle = false,
  noFinish = false,
  noProductType = false,
} = {}) => {
  const files = useSelector(getFiles);
  const queryClient = useQueryClient();
  const [error, setError] = useState();
  const deleteFilesMutation = useMutation(
    (input) => {
      setError(null);
      return deleteFiles(input);
    },
    {
      onSuccess: (data) => {
        if (!data?.deleteFilesInfo.success) {
          setError(`Error: ${data?.deleteFilesInfo?.message}`);
        } else {
          if (skipQueryUpdate) {
            return;
          }
          queryClient.setQueryData(
            [
              "files",
              projectId,
              fileType,
              spaceId,
              createdBy,
              contentType,
              projectSpacesId,
              ids,
              noSpaces,
              noContentType,
              noTags,
              noFileType,
              noCreatedBy,
              noDesignStyle,
              noFinish,
              noProductType,
            ],
            (old) => {
              const deletedFilesIds = data?.deleteFilesInfo?.deletedIds;
              return {
                ...old,
                pages: [
                  {
                    getFilesInfo: {
                      ...old?.pages?.[0].getFilesInfo,
                      files: old?.pages?.[0].getFilesInfo?.files?.filter((file) => {
                        return !deletedFilesIds.includes(file.id);
                      }),
                      totalCount: old?.pages?.[0].getFilesInfo.totalCount - deletedFilesIds.length,
                    },
                  },
                ],
              };
            }
          );
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    files,
    error,
    deleteFiles: deleteFilesMutation.mutate,
    deleteFilesAsync: deleteFilesMutation.mutateAsync,
    deleteFilesLoading: deleteFilesMutation.isLoading,
    deleteFilesSuccess: deleteFilesMutation.isSuccess,
  };
};

export default useDeleteFiles;
