import React from "react";
import axios from "axios";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import fileDownload from "js-file-download";
import { NavCard, TableCells } from "@buildappeal/react-component-library";
import JSZip from "jszip";

import { getPreSignedUrl } from "@features/files/api";
import { copy, isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";

export const columns = [
  {
    id: "select",
    accessor: "selected_row",
    Cell: TableCells.CheckboxCell,
    disableSortBy: true,
    minWidth: "50px",
    maxWidth: "50px",
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: (originalRow) => {
      const [title, subtitle] = originalRow.getValue().split(".");
      const { path, fileType } = originalRow.row.original;
      return (
        <NavCard
          image={fileType.includes("image") ? path : ""}
          title={title}
          description={`.${subtitle}`}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  {
    id: "fileType",
    Header: "File Type",
    accessor: "fileType",
    Cell: TableCells.TextCell,
  },
  {
    id: "size",
    Header: "Size",
    accessor: (row) => (isNaN(row.height) ? "" : row.height && row.width ? `${row.width}x${row.height}` : ""),
    Cell: TableCells.TextCell,
  },
  {
    id: "project",
    Header: "Project",
    accessor: (originalRow) =>
      originalRow.project?.places?.addressLine1
        ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
        : null,
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original.project?.places?.city}
          addressState={originalRow.row.original.project?.places?.state}
          addressZip={originalRow.row.original.project?.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  {
    id: "space",
    Header: "Space",
    accessor: (row) => (Array.isArray(row.spaces) ? row.spaces.join(", ") : ""),
    Cell: TableCells.TextCell,
  },
  {
    id: "product",
    Header: "Product",
    accessor: (row) => row.product?.name,
    Cell: TableCells.TextCell,
  },
  {
    id: "date",
    Header: "Created",
    accessor: "uploadedAt",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
  },
];

export const fileTypeOptions = [
  {
    title: "Product Media (photo, video, etc.)",
    value: "product",
    type: "media",
  },
  {
    title: "Project Document (PDF, .docx, etc.)",
    value: "projectDocument",
    type: "document",
  },
  {
    title: "Project Media (photo, video, etc.)",
    value: "projectMedia",
    type: "media",
  },
  {
    title: "Spaces Media (photo, video, etc.)",
    value: "spaces",
    type: "media",
  },
  {
    title: "Other Media (photo, video, etc.)",
    value: "generalMedia",
    type: "media",
  },
  {
    title: "Other Document (PDF, .docx, etc.)",
    value: "generalDocument",
    type: "document",
  },
];

export const projectDocumentOptions = [
  {
    title: "Contract",
    value: "contracts",
  },
  {
    title: "Invoice",
    value: "invoices",
  },
  {
    title: "Bill",
    value: "bills",
  },
];

export const filePaths = {
  product: {
    document: `ba-public-files/${process.env.REACT_APP_STAGE}/products/`,
    media: `ba-media-files/${process.env.REACT_APP_STAGE}/products/`,
  },
  email: {
    document: `ba-public-files/${process.env.REACT_APP_STAGE}/emails/`,
    media: `ba-media-files/${process.env.REACT_APP_STAGE}/emails/`,
  },
  project: {
    document: `ba-public-files/${process.env.REACT_APP_STAGE}/projects/`,
    media: `ba-media-files/${process.env.REACT_APP_STAGE}/projects/`,
  },
  general: {
    document: `ba-public-files/${process.env.REACT_APP_STAGE}/general/`,
    media: `ba-media-files/${process.env.REACT_APP_STAGE}/general/`,
  },
};

export const projectFileTypeOptions = [
  {
    title: "Project Document (PDF, .docx, etc.)",
    value: "document",
  },
  {
    title: "Project Media (photo, video, etc.)",
    value: "media",
  },
];

export const FileTypeAllowed = ["media", "document"];

export const FILE_CATEGORIES = {
  PRODUCT: "product",
  EMAIL: "email",
  PROJECT: "project",
  OTHER: "other",
};

export const FILE_CATEGORIES_ITEMS = [
  {
    id: FILE_CATEGORIES.PROJECT,
    label: "Project",
    isActive: false,
  },
  {
    id: FILE_CATEGORIES.PRODUCT,
    label: "Product",
    isActive: false,
  },
  {
    id: FILE_CATEGORIES.OTHER,
    label: "Other",
    isActive: false,
  },
];

const handleClick = (url, filename) => {
  try {
    axios
      .get(url, {
        responseType: "blob",
        withCredentials: false,
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  } catch (error) {
    console.error(error);
  }
};

const WATERMARK_TRANSFORMATION = "l_ba_watermark/e_overlay,fl_layer_apply,g_south_east,x_50,y_50";

const FILE_BASE_URL = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_NAME}`;

const getMediaUrl = (file, withWatermark) => {
  const isImage = file?.fileType?.includes("image");
  const type = isImage ? "image" : "video";
  const folder = `ba-media/${(process.env.REACT_APP_STAGE || "staging").toLowerCase()}`;

  if (withWatermark && isImage) {
    return `${FILE_BASE_URL}/${type}/upload/${WATERMARK_TRANSFORMATION}/${folder}/${file.path}`;
  }

  return `${FILE_BASE_URL}/${type}/upload/${folder}/${file.path}`;
};

export const downloadFile = async (file) => {
  try {
    const fileKey = file?.path;
    const isImage = file?.fileType?.includes("image");
    const isVideo = file?.fileType?.includes("video");
    const isAudio = file?.fileType?.includes("audio");
    const isMedia = isImage || isVideo || isAudio;

    if (fileKey.startsWith("https://")) {
      handleClick(fileKey, file?.name);
    } else if (isMedia) {
      const url = getMediaUrl(file, true);
      handleClick(url, file?.name);
    } else {
      const d = await getPreSignedUrl(`${process.env.REACT_APP_STAGE}/${fileKey}`, file?.fileType, "document", "GET");
      handleClick(d.signedUrl, file?.name);
    }
  } catch (error) {
    console.error(error);
  }
};

export const downloadFiles = async ({ files = [], projectName = "", onSuccess, onError }) => {
  const today = dayjs().format("YYYY-MM-DD");
  const projectNameFile = projectName?.replace(/[^a-zA-Z0-9]/g, "_");
  const folderName = projectName
    ? `BuildAppeal_${projectNameFile}_${today}_${files.length}`
    : `BuildAppeal_${today}_${files.length}`;
  const filename = `${folderName}.zip`;
  const zip = new JSZip();
  const folder = zip.folder(folderName);

  const urls = await Promise.all(
    files.map(async (file) => {
      const fileKey = file?.path;

      if (fileKey.startsWith("http")) {
        return fileKey;
      }

      return await generateFileLink(file, true);
    })
  );

  urls.forEach((url) => {
    const blob = fetch(url).then(function (response) {
      if (response.status === 200) {
        return Promise.resolve(response.blob());
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    });
    const name = files.find((file) => url.includes(file.path))?.name || url.substring(url.lastIndexOf("/"));

    if (name && blob) {
      folder.file(name, blob);
    }
  });

  zip
    .generateAsync({ type: "blob" })
    .then((blob) => fileDownload(blob, filename))
    .then(onSuccess)
    .catch(onError);
};

export const generateFileLink = async (file, withWatermark = false) => {
  const { isPdf, isMedia } = getFileType(file?.fileType);

  if (isMedia) {
    const url = getMediaUrl(file, withWatermark);
    return url;
  }
  if (file?.shareUrl) {
    return file.shareUrl;
  }
  if (isPdf) {
    const { signedUrl } = await getPreSignedUrl(
      `${process.env.REACT_APP_STAGE}/${file?.path}`,
      file?.fileType,
      "document",
      "GET"
    );
    return signedUrl;
  }
};

export const copyFileLink = (file, onSuccess) => {
  const { isPdf, isMedia } = getFileType(file?.fileType);

  if (isPdf && file.url.startsWith("https")) {
    copy(file.url);
    onSuccess();
    return;
  }

  if (isMedia) {
    const url = getMediaUrl(file);
    copy(url);
    onSuccess();
    return;
  }

  if (isPdf) {
    getPreSignedUrl(`${process.env.REACT_APP_STAGE}/${file?.path}`, file?.fileType, "document", "GET").then((res) => {
      copy(res.signedUrl);
      onSuccess();
    });
  }
};

export const getFileKey = (triggeredBy, item) => {
  let fileKey = "";
  switch (triggeredBy) {
    case "project":
      fileKey = `projects/${item.id}/${uuidv4()}`;
      break;
    default:
      fileKey = `general/${uuidv4()}`;
      break;
  }
  return fileKey;
};

export const updateFilesWithURL = async (files) => {
  return await Promise.all(
    files?.map(async (file) => {
      const { isPdf, isImage, isVideo } = getFileType(file?.fileType);
      const urlGenerated = await generateFileLink(file);
      const path = isPdf ? urlGenerated : file?.path;
      const previewPath = isImage
        ? file?.path
        : isVideo
        ? `${file.path.split(".")[0]}.jpg`
        : isPdf
        ? urlGenerated
        : urlGenerated;

      return {
        ...file,
        url: urlGenerated,
        dateFormatted: dayjs(file.uploadedAt).format("LL"),
        path,
        previewPath,
      };
    })
  );
};

export const getThumbnailPath = (file) => {
  const { isVideo } = getFileType(file?.fileType);
  return isVideo ? `${file.path.split(".")[0]}.jpg` : file?.path;
};

export const FILE_TYPES = {
  media: "media",
  document: "document",
  secureDocument: "secureDocument",
};

export const FILE_CONTENT_TYPES = {
  product: "product",
  email: "email",
  designStyle: "designStyle",
  finish: "finish",
  note: "note",
  material: "materials",
  look: "look",
  objects: "objects",
  project: "project",
  bill: "bill",
  purchase: "purchase",
};

export const FILE_PATHS = {
  product: "products",
  email: "emails",
  general: "general",
  designStyle: "designStyles",
  finish: "finishes",
  note: "notes",
  material: "materials",
  look: "looks",
  objects: "objects",
  project: "projects",
};

export const FILE_CONTENT_TYPES_LABEL = {
  product: "Product",
  email: "Email",
  designStyle: "Inspiration",
  material: "materials",
  look: "Look",
  objects: "Object",
  clientSubmitted: "Client Submitted",
  bill: "Bill",
  purchase: "Purchase",
};

export const getFileBlob = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const blobData = new Blob([new Uint8Array(reader.result)], { type: file.file?.type || file.contentType });
      resolve(blobData);
    };
    reader.onerror = reject;
    const finalFile = file?.file?.file || file?.file;
    reader.readAsArrayBuffer(finalFile);
  });
};

export const getFileURL = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file?.file);
  });
};

export const DEFAULT_UPLOAD_SIZE_LIMIT = 60000000;

export const FILES_QUERY_SUGGESTION_INDEX = "files_query_suggestions";

export const transformCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    spaces: "Space",
    contentType: "Content",
    groupedFileType: "Type",
    tags: "Tags",
    designStyles: "Design Style",
    finishes: "Finish",
    productTypes: "Product Type",
    addedBy: "Added By",
    size: "Size",
    "project.places.city": "City",
    "project.places.addressLine1": "Address",
    "project.fullAddress": "Project",
  };

  return items
    .map((item) => ({
      ...item,
      refinements: item.refinements.map((refinement) => ({
        ...refinement,
        label: isAlgoliaAttributeValueEmpty(refinement.label)
          ? `No ${refinementAttributeToLabelMap[item.attribute]}`
          : titleCase(refinement.label),
      })),
    }))
    .filter((item) => !["groupedFileType"].includes(item.attribute));
};

export const fileSortByOptions = [
  { title: "Created Date", label: "Created Date", value: "files" },
  { title: "Updated Date", label: "Updated Date", value: "files_updatedAt_desc" },
];

export const getFileType = (fileType) => {
  const isPdf = fileType?.includes("pdf");
  const isImage = fileType?.includes("image");
  const isVideo = fileType?.includes("video");
  const isAudio = fileType?.includes("audio");
  const isOther = !isPdf && !isImage && !isVideo && !isAudio;
  const isMedia = isImage || isVideo || isAudio;

  return { isPdf, isImage, isVideo, isAudio, isOther, isMedia };
};

export const FILES_QUERY_PARAMS = {
  FILE_ID: "fileId",
  SHOW_GALLERY: "showGallery",
  FILE_TYPE: "fileType",
  FILE_NAME: "fileName",
};

export const getFileLink = ({ fileId, fileType, fileName }) =>
  `${window.origin}/files?${FILES_QUERY_PARAMS.SHOW_GALLERY}=true&${FILES_QUERY_PARAMS.FILE_ID}=${fileId}&${FILES_QUERY_PARAMS.FILE_NAME}=${fileName}&${FILES_QUERY_PARAMS.FILE_TYPE}=${fileType}`;
