import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { addSpaceToProject } from "../api";

const useAddSpaceToProject = (projectId) => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      return addSpaceToProject(input);
    },
    {
      onSuccess: (data) => {
        if (!data.addSpaceToProject.success) {
          setError(`Error: ${data.addSpaceToProject.message}`);
          return;
        }

        queryClient.setQueryData(["projects", projectId], (old) => {
          if (old) {
            return {
              getProjectByID: {
                ...old.getProjectByID,
                spaces: old.getProjectByID.spaces
                  ? [data.addSpaceToProject.data, ...old.getProjectByID.spaces]
                  : [data.addSpaceToProject.data],
              },
            };
          }
        });
        setIsSuccess(true);
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return { addSpaceToProject: mutate, addSpaceToProjectAsync: mutateAsync, isLoading, isSuccess, error };
};

export default useAddSpaceToProject;
