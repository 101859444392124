import { TableCells, TableFilters } from "@buildappeal/react-component-library";
import { isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";
import PartnerActivity from "./PartnerActivity";
import PartnerSettings from "./PartnerSettings";
import PartnerTasks from "./PartnerTasks";

export const STATUS_COLOR_CODES = {
  Disabled: "gray",
  Active: "green",
};

export const columns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "companyName",
    Header: "Company Name",
    accessor: "companyName",
    Cell: TableCells.TextCell,
  },
  {
    id: "services",
    Header: "Services",
    accessor: (originalRow) => {
      return originalRow?.services?.map((service) => service.name)?.join(", ") || "";
    },
    Cell: (props) => (
      <TableCells.BadgesCell items={props.row.original.services?.map((service) => service.name)} {...props} />
    ),
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "email",
    Header: "Company Email",
    accessor: "email",
    Cell: TableCells.TextCell,
  },
  {
    id: "officePhone",
    Header: "Company Phone",
    accessor: "officePhone",
    Cell: TableCells.PhoneNumberCell,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
];

export const userTabs = [
  {
    id: 0,
    label: "Activity",
    content: <PartnerActivity />,
  },
  {
    id: 1,
    label: "Tasks",
    content: <PartnerTasks />,
  },
  {
    id: 2,
    label: "Settings",
    content: <PartnerSettings />,
  },
];

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const loadingPlaceholderPartners = {
  id: "",
  email: "",
  image: "",
  companyPhone: "",
  companyName: "",
  services: [],
  user: null,
  markets: [],
  status: "",
};

export const transformService = (service) => ({ value: service?.id, title: service?.name, service });

export const transformPartnerCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    status: "Status",
  };

  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => ({
      ...refinement,
      label: isAlgoliaAttributeValueEmpty(refinement.label)
        ? `No ${refinementAttributeToLabelMap[item.attribute]}`
        : titleCase(refinement.label),
    })),
  }));
};
