import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { addObject } from "../../api";

const useAddObject = (filters = {}) => {
  const queryClient = useQueryClient();

  const [error, setError] = useState();

  const addObjectMutation = useMutation(
    (input) => {
      setError(null);
      return addObject(input);
    },
    {
      onSuccess: (data) => {
        if (!data.addObject?.success) {
          setError(`Error: ${data.addObject?.message}`);
        } else {
          queryClient.setQueryData(["objects", filters], (old) => {
            if (!old) return { getObjects: [data.addObject.data] };
            return {
              getObjects: [data.addObject?.data, ...old.getObjects],
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    addObject: addObjectMutation.mutate,
    addObjectAsync: addObjectMutation.mutateAsync,
    isLoading: addObjectMutation.isLoading,
    isSuccess: addObjectMutation.isSuccess,
  };
};

export default useAddObject;
