import { useEffect, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import useToast from "@src/utils/hooks/useToast";

import ToastAnimated from "../ToastAnimated/ToastAnimated";

const Toasts = () => {
  const clearToastTimeoutRefs = useRef({});
  const {
    state: { toasts = [] },
    removeToast,
  } = useToast();

  useEffect(() => {
    if (!toasts.length) return;
    toasts.forEach((toast) => {
      if (!clearToastTimeoutRefs.current?.[toast.id]) {
        clearToastTimeoutRefs.current[toast.id] = setTimeout(() => {
          removeToast(toast.id);
        }, toast.duration || 3000);
      }
    });
    return () => {
      Object.keys(clearToastTimeoutRefs.current || {}).forEach((toastId) =>
        clearTimeout(clearToastTimeoutRefs.current[toastId])
      );
    };
  }, [toasts]);

  if (toasts == null) return null;

  return (
    <AnimatePresence exitBeforeEnter>
      {toasts?.map((toast) => (
        <ToastAnimated {...toast} key={toast.id} onClose={() => removeToast(toast.id)} />
      ))}
    </AnimatePresence>
  );
};

export default Toasts;
