import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  spaces: [],
};

export const spacesSlice = createSlice({
  name: "spaces",
  initialState,
  reducers: {
    setSpaces: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        spaces: action.payload,
      };
    },
  },
});

export const { setSpaces } = spacesSlice.actions;

export default spacesSlice.reducer;

export const getSpaces = (state) => state.spaces.spaces;
