import { useState } from "react";
import cx from "clsx";
import { Button, Chip } from "@buildappeal/react-component-library";

import Totals from "./Totals";

const PAYMENT_OPTIONS = {
  half: "half",
  full: "full",
};

const PaymentSummary = ({ className, total, stripeLink, handleSendToClient }) => {
  const [activePayment, setActivePayment] = useState(PAYMENT_OPTIONS.full);

  const buttons = [
    // {
    //   id: 1,
    //   label: "Pay a 50% deposit",
    //   type: activePayment === PAYMENT_OPTIONS.half ? "active" : "inactive",
    //   onClick: () => setActivePayment(PAYMENT_OPTIONS.half),
    // },
    {
      id: 2,
      label: "Pay in full",
      type: activePayment === PAYMENT_OPTIONS.full ? "active" : "inactive",
      onClick: () => setActivePayment(PAYMENT_OPTIONS.full),
    },
  ];

  return (
    <div className={className}>
      <div className="text-md">Your order has been created.</div>
      <div className="mt-3 flex flex-row">
        {buttons?.map((button) => (
          <Chip key={button.id} appearance="black" className="mr-2" {...button} />
        ))}
      </div>
      <div className="mt-5">
        <Totals label="Subtotal" value={total} showBorder />
        <Totals label="Total" value={total} size="lg" showBorder={activePayment === PAYMENT_OPTIONS.half} />
        {activePayment === PAYMENT_OPTIONS.half && (
          <Totals label="Due today" value={total / 2} size="lg" classNameValue="!text-green-600" />
        )}
      </div>
      <div className={cx("flex w-full gap-x-4 p-2", className)}>
        <Button
          key="send-client"
          className="flex-1"
          appearance="primary"
          onClick={handleSendToClient}
          label="Send to Client"
        />
        <Button
          key="pay-now"
          className="flex-1"
          appearance="secondary"
          onClick={() => {
            window.open(stripeLink, "_blank").focus();
          }}
          label="Make payment for Client"
        />
      </div>
    </div>
  );
};

export default PaymentSummary;
