const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: ["project", "estimatedTotal", "daysToComplete", "status"],
    },
  ],
};

export default uiSchema;
