import { UNIT_TYPES } from "@src/utils/constants";

const formSchema = {
  title: "Create Actions Object",
  properties: {
    action: {
      type: "select",
      label: "Action",
      required: true,
    },
    object: {
      type: "select",
      label: "Object",
      required: true,
    },
    parent: {
      type: "select",
      label: "Parent",
    },
    services: {
      type: "selectMultiple",
      label: "Services",
    },
    stage: {
      type: "select",
      label: "Stage",
    },
    isMilestone: {
      type: "checkbox",
      label: "Is Milestone",
    },
    isCriticalPath: {
      type: "checkbox",
      label: "Critical",
    },
    baseLaborTime: {
      type: "text",
      label: "Base labor Time",
    },
    baseLaborCost: {
      type: "text",
      label: "Base Labor Cost",
    },
    baseLaborUnitType: {
      type: "select",
      label: "Labor Unit Type",
      options: UNIT_TYPES.map((item) => ({
        title: item,
        value: item,
      })),
    },
    points: {
      type: "text",
      label: "Points",
    },
  },
};

export default formSchema;
