import AlgoliaTabFilters from "@src/utils/components/AlgoliaComponents/TabFilters";

const PeopleFilters = ({ hideTabFilters }) => {
  return (
    <div className="mb-3 space-y-4">
      <AlgoliaTabFilters
        items={[
          { id: "all", label: "All", value: "" },
          { id: "clients", label: "Clients", value: "personType:client" },
          { id: "staff", label: "Staff", value: "personType:staff" },
          { id: "contacts", label: "Contacts", value: "personType:contact" },
        ]}
        className={hideTabFilters ? "hidden" : ""}
      />
    </div>
  );
};

export default PeopleFilters;
