import CommonSendEmail from "../people/CommonSendEmail";
import EditSupplier from "./EditSupplier";
import AddSupplier from "./AddSupplier";

export const supplierActions = {
  add: {
    id: "add",
    label: "Create product",
    sidebar: AddSupplier,
  },
  edit: {
    id: "edit",
    label: "Edit product",
    modal: EditSupplier,
  },
  delete: {
    id: "delete",
    label: "Delete product",
    modal: () => <div className="p-10">Delete product form</div>,
  },
  "add media": {
    id: "add media",
    label: "Add media",
    modal: () => <div className="p-10">Add media form</div>,
  },
  "add note": {
    id: "add note",
    label: "Add note",
    modal: () => <div className="p-10">Add note form</div>,
  },
  "add document": {
    id: "add document",
    label: "Add document",
    modal: () => <div className="p-10">Add document form</div>,
  },
  "set thumbnail": {
    id: "set thumbnail",
    label: "Set thumbnail",
    modal: () => <div className="p-10">Set thumbnail form</div>,
  },
  send: {
    id: "send",
    label: "Send Message",
    component: CommonSendEmail,
    container: "collapsedExpand",
  },
};
