import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import dayjs from "dayjs";
import pluralize from "pluralize";
import orderBy from "lodash/orderBy";
import { isMobile } from "react-device-detect";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import Selecto from "react-selecto";
import {
  UilPlus as AddIcon,
  UilApps as GridIcon,
  UilLinkAlt as LinkIcon,
  UilImport as ImportIcon,
  UilTrashAlt as BinIcon,
  UilPen as EditIcon,
  UilImage as ThumbnailIcon,
  UilFolderOpen as FileIcon,
} from "@iconscout/react-unicons";
import {
  Button,
  Modal,
  FileUploadOverlay,
  EmptyState,
  ProjectMediaViewer,
  Tooltip,
  IconButton,
} from "@buildappeal/react-component-library";
import { useQueryClient } from "react-query";
import { useInstantSearch } from "react-instantsearch-hooks-web";

import SidebarLayout from "@features/layout/components/SidebarLayout";
import Loading from "@src/utils/components/Loading";
import ContextMenu from "@features/files/ContextMenu";
import useToast from "@src/utils/hooks/useToast";
import useBATypes from "@src/utils/hooks/useBATypes";
import useProjects from "@features/projects/hooks/useProjects";
import useUpdateFile from "@src/features/files/hooks/useUpdateFile";
import useDeleteFile from "@src/features/files/hooks/useDeleteFile";
import { copy, getPlaceAddressStr } from "@src/utils/common";
import useUploadFilesOverlay from "@features/files/hooks/useUploadFilesOverlay";
import FileRenameModal from "@features/files/components/FileRenameModal";
import FileEditAltTextModal from "@features/files/components/FileEditAltTextModal";
import FileDelete from "@features/files/components/FileDelete";
import FileMultiSelectAction from "@features/files/FileMultiSelectAction";
import useGroupedProjectSpaceObjects from "@features/projectSpaceObjects/hooks/useGroupedProjectSpaceObjects";
import useSpaces from "@features/spaces/hooks/useSpaces";
import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";
import useDesignStyles from "@src/features/designs/hooks/useDesignStyles";
import AlgoliaWrapper from "@src/utils/components/AlgoliaWrapper";
import useAlgoliaInfiniteHits from "@src/utils/hooks/useAlgoliaInfiniteHits";
import AlgoliaInifiteHits from "@src/utils/components/AlgoliaComponents/InfiniteHits";
import { downloadFile, generateFileLink, getFileType, FILES_QUERY_PARAMS, getFileLink } from "../utils";
import useUpdateThumbnail from "../hooks/useUpdateThumbnail";
import MediaCoordinatesModal from "../../mediaCoordinates/MediaCoordinatesModal";
import useFileDetail from "../hooks/useFileDetail";
import FileModal from "../components/FileModal";
import FilesTable from "./FilesTable";
import FilesCards from "./FilesCards";
import FilesFilters from "./FilesFilters";
import FileDetail from "./FileDetail";

const CommonFiles = ({
  project,
  person,
  isFullWidth = false,
  showSpaceFilter,
  projectSpace,
  showProjectViewer = false,
  hideThumbnailAction = false,
  contentClassName = "",
  onExpand,
  showAddedByFilter = true,
  algoliaInstanceId = "files",
  projectId,
}) => {
  const queryClient = useQueryClient();
  const filters = useMemo(() => {
    return project
      ? `project.id:${projectId}` + (projectSpace ? ` AND projectSpaces:${projectSpace.id}` : "")
      : person
      ? `addedByUserId:${person.uuid}`
      : "";
  }, [project, person, projectId]);

  const { hits, isLoading, totalCount, refreshResults } = useAlgoliaInfiniteHits(algoliaInstanceId);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setIndexUiState } = useInstantSearch();

  const [selectedFile, selectFile] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [showGallery, setShowGallery] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMultipleFiles, setSelectedMultipleFiles] = useState([]);
  const [showCoordinatesEdit, setShowCoordinatesEdit] = useState(false);
  const [filesWithUrl, setFilesWithUrls] = useState([]);
  const [ui, setUi] = useState({
    showEditAltTextModal: false,
    showRenameModal: false,
    openContextMenu: {
      show: false,
      row: null,
      offsetX: "",
      offsetY: "",
    },
  });

  const {
    data: fileDetail,
    isLoading: isFileDetailLoading,
    refetch,
  } = useFileDetail(parseInt(selectedFile?.id), {
    disableQuery: !selectedFile?.id,
  });
  const { updateFileAsync } = useUpdateFile();
  const { updateThumbnailAsync } = useUpdateThumbnail({ skipQueryUpdate: true });
  const { addProjectToActionProps } = useGeneralActionsUpdater();

  const { addToast } = useToast();
  const { deleteFileAsync } = useDeleteFile();
  const { baTypes: contentTypes } = useBATypes("contentTypes");
  const { data: projects } = useProjects();
  const { options: spacesAsOptions } = useSpaces();
  const { options: designStylesAsOptions } = useDesignStyles();

  const projectSpacesAsOptions = useMemo(() => {
    if (project) {
      const spacesSorted = orderBy(project?.spaces, "sortOrder");
      return spacesSorted?.map((space) => ({
        value: space.id,
        title: space.spaceNickname || space.name,
        space,
      }));
    } else {
      return projects
        .find((project) => project.id === selectedFile?.project?.id)
        ?.spaces?.map((space) => {
          return {
            value: space.id,
            title: space.spaceNickname || space.name,
            space,
          };
        });
    }
  }, [project, selectedFile?.project?.id, projects]);

  const { options: projectSpaceObjectsAsOptions } = useGroupedProjectSpaceObjects(
    {
      projectSpaceIds: projectSpacesAsOptions
        ? projectSpacesAsOptions.map((projectSpace) => projectSpace.space.id)
        : [],
    },
    {
      disableQuery: !projectSpacesAsOptions,
    }
  );

  const clearContextMenu = { show: false, row: null, offsetX: "", offsetY: "" };

  const selectedFileIndex = useMemo(() => {
    return filesWithUrl.findIndex((file) => file.id === selectedFile?.id);
  }, [selectedFile]);

  const updateFiles = async () => {
    const newFiles = await Promise.all(
      hits?.map(async (file) => {
        const urlGenerated = await generateFileLink(file);
        const { isPdf } = getFileType(file?.fileType);

        return {
          ...file,
          url: urlGenerated,
          dateFormatted: dayjs(file.uploadedAt).format("LL"),
          path: isPdf ? urlGenerated : file.path,
        };
      })
    );
    return newFiles;
  };

  const refetchSearchResults = (files, action) => {
    refreshResults(files, action);
  };

  const handleShowFileGallery = (galleryStatus) => {
    if (!selectedFile) {
      return;
    }
    if (galleryStatus) {
      searchParams.set(FILES_QUERY_PARAMS.SHOW_GALLERY, true);
      searchParams.set(FILES_QUERY_PARAMS.FILE_ID, selectedFile.id);
      searchParams.set(FILES_QUERY_PARAMS.FILE_TYPE, selectedFile.type);
      searchParams.set(FILES_QUERY_PARAMS.FILE_NAME, selectedFile.name);
    } else {
      searchParams.delete(FILES_QUERY_PARAMS.SHOW_GALLERY);
      searchParams.delete(FILES_QUERY_PARAMS.FILE_ID);
      searchParams.delete(FILES_QUERY_PARAMS.FILE_TYPE);
      searchParams.delete(FILES_QUERY_PARAMS.FILE_NAME);
    }
    setSearchParams(searchParams);
  };

  const setSelectedFileWithDetail = async () => {
    const urlGenerated = await generateFileLink(fileDetail);
    const { isPdf } = getFileType(fileDetail?.fileType);
    const updatedSelectedFile = {
      ...selectedFile,
      ...fileDetail,
      url: urlGenerated,
      dateFormatted: dayjs(fileDetail.uploadedAt).format("LL"),
      path: isPdf ? urlGenerated : fileDetail.path,
      isDBData: true,
    };
    selectFile(updatedSelectedFile);
  };

  const handleProjectViewFiles = (project) => {
    setIndexUiState((prev) => ({
      ...prev,
      refinementList: {
        ...prev.refinementList,
        "project.fullAddress": [getPlaceAddressStr(project.places || {})],
      },
      page: 0,
    }));
  };

  useEffect(() => {
    let isSubscribed = true;
    const updateFileData = async () => {
      const newFiles = await updateFiles();
      if (isSubscribed) {
        setFilesWithUrls(newFiles);
      }
    };
    updateFileData(isSubscribed).catch(console.error);
    return () => (isSubscribed = false);
  }, [hits]);

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    if (searchParams.get(FILES_QUERY_PARAMS.SHOW_GALLERY) === "true" && !showGallery) {
      setShowGallery(true);
      return;
    }
    if (!searchParams.get(FILES_QUERY_PARAMS.SHOW_GALLERY) && showGallery) {
      setShowGallery(false);
    }
    if (
      selectedFile.id !== searchParams.get(FILES_QUERY_PARAMS.FILE_ID) &&
      searchParams.get(FILES_QUERY_PARAMS.SHOW_GALLERY) === "true"
    ) {
      handleShowFileGallery(true);
    }
  }, [searchParams, selectedFile]);

  useEffect(() => {
    if (
      !searchParams.get(FILES_QUERY_PARAMS.FILE_ID) ||
      selectedFile?.id === searchParams.get(FILES_QUERY_PARAMS.FILE_ID) ||
      !filesWithUrl.length
    ) {
      return;
    }
    const selectedQPFileId = searchParams.get(FILES_QUERY_PARAMS.FILE_ID);
    let updatedSelectedFile = filesWithUrl.find((file) => file.id === selectedQPFileId);
    if (!updatedSelectedFile?.id) {
      updatedSelectedFile = {
        id: selectedQPFileId,
        type: searchParams.get(FILES_QUERY_PARAMS.FILE_TYPE),
        name: searchParams.get(FILES_QUERY_PARAMS.FILE_NAME),
      };
    }

    selectFile(updatedSelectedFile);
    setShowSidebar(true);
  }, [searchParams, filesWithUrl]);

  useEffect(() => {
    if (!selectedFile?.id || !fileDetail?.id || (selectedFile?.id === fileDetail?.id && selectedFile?.isDBData)) {
      return;
    }

    setSelectedFileWithDetail();
  }, [selectedFile, fileDetail]);

  const onFileUploadSuccess = (result) => {
    const files = result.uploadedFiles;
    refetchSearchResults(files, "add");
  };

  const { onOverlayEnter, onOverlayExit, handleAcceptedFiles, handleRejectedFiles, ...uploadOverlayProps } =
    useUploadFilesOverlay({
      fileKeyPrefix: project ? `projects/${project?.id}` : "general",
      onSuccess: onFileUploadSuccess,
    });

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    if (selectedMultipleFiles) {
      setSelectedMultipleFiles([]);
    }
    selectFile(row);
    setUi((ui) => ({
      ...ui,
      openContextMenu: {
        show: true,
        row,
        offsetX: event.clientX,
        offsetY: event.clientY,
      },
    }));
  };

  const closeContext = () => {
    setUi((ui) => ({ ...ui, openContextMenu: clearContextMenu }));
  };

  const onFileUpdateSuccess = (thumbnail) => {
    queryClient.setQueryData("projects", (old) => {
      return {
        getProjects: old?.getProjects?.map((item) => {
          if (item.id === project?.id) {
            if (projectSpace) {
              return {
                ...item,
                spaces: item.spaces.map((space) => (space.id === projectSpace.id ? { ...space, thumbnail } : space)),
              };
            }
            return {
              ...item,
              thumbnail,
            };
          }
          return item;
        }),
      };
    });
    queryClient.setQueryData(["projects", project.id], (old) => {
      return {
        getProjectByID: { ...old?.getProjectByID, thumbnail },
      };
    });
  };

  const handleSetThumbnailAction = async (file) => {
    const currentThumbnail = projectSpace ? projectSpace?.thumbnail : project?.thumbnail;
    const input = {
      newFileId: file.id,
      oldFileId: currentThumbnail.id !== file.id ? currentThumbnail.id : undefined,
    };
    if (projectSpace) {
      input.projectSpaceId = projectSpace.id;
    } else {
      input.projectId = project.id;
    }
    try {
      const result = await updateThumbnailAsync(input);
      if (result?.updateThumbnail.success) {
        onFileUpdateSuccess(file);
        addToast("Thumbnail updated");
      } else {
        addToast("Thumbnail updation failed");
      }
    } catch (error) {
      addToast("Thumbnail updation failed");
    }
  };

  const handlePrevious = () => {
    selectFile((selectedFile) => {
      const index = filesWithUrl.findIndex((item) => {
        return item.id === selectedFile?.id;
      });
      if (index > 0) {
        return filesWithUrl[index - 1];
      }
      return selectedFile;
    });
  };

  const handleNext = () => {
    selectFile((selectedFile) => {
      const index = filesWithUrl.findIndex((item) => item.id === selectedFile?.id);
      if (index < filesWithUrl.length - 1) {
        return filesWithUrl[index + 1];
      }
      return selectedFile;
    });
  };

  const handleSelectFile = (fileToSelect, multiselect = false, isDoubleClick = false) => {
    if (ui.showDeleteToast) {
      setUi({ ...ui, showDeleteToast: false });
    }

    const file = fileToSelect || selectedFile;
    if (selectedMultipleFiles.length || multiselect) {
      const selectedFiles = [...selectedMultipleFiles];
      const filePresentIndex = selectedFiles.findIndex((f) => f.id === file.id);
      if (filePresentIndex === -1) {
        setSelectedMultipleFiles([...selectedFiles, file]);
      } else {
        selectedFiles.splice(filePresentIndex, 1);
        setSelectedMultipleFiles(selectedFiles);
      }
    } else {
      selectFile(file);
      setShowSidebar(true);
      onExpand?.(true);
    }
    if (isDoubleClick) {
      handleShowFileGallery(true);
    }
  };

  const handleAddFilesBtnClick = () => {
    addProjectToActionProps({
      isOpen: true,
      currentAction: "uploadFiles",
      triggeredBy: project ? "project" : "files",
      actionProps: {
        item: project,
        onSuccess: onFileUploadSuccess,
        additionalFields: projectSpace
          ? {
              spacesId: projectSpace ? [projectSpace.spaceId] : [],
              projectSpacesId: projectSpace ? [projectSpace.id] : undefined,
            }
          : undefined,
      },
    });
  };

  const onUpdateFieldField = (field, newValue) => {
    return updateFileAsync({
      id: selectedFile.id,
      [field]: newValue,
    });
  };

  const onDeleteFile = () => {
    return deleteFileAsync(selectedFile?.id);
  };

  const onHandleDownload = async ({ fileToDownload }) => {
    try {
      const file = fileToDownload || selectedFile;
      await downloadFile(file);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCopyAction = () => {
    if (!selectedFile?.id) return;
    const fileLink = getFileLink({
      fileId: selectedFile?.id,
      fileType: selectedFile?.type,
      fileName: selectedFile?.name,
    });
    copy(fileLink);
  };
  const handleDeleteAction = (file) => {
    selectFile(file);
    setUi((ui) => ({ ...ui, showDeleteToast: true }));
  };

  const handleRenameAction = (file) => {
    selectFile(file);
    setUi((ui) => ({ ...ui, showRenameModal: true }));
  };

  const handleEditAltTextAction = (file) => {
    selectFile(file);
    setUi((ui) => ({ ...ui, showEditAltTextModal: true }));
  };

  const handleMultiSelectAction = (file) => {
    if (selectedFile) {
      selectFile(null);
    }
    setShowSidebar(false);
    handleSelectFile(file, true);
  };

  const handleOverlayAcceptedFiles = (files) => {
    handleAcceptedFiles(files, {
      itemData: project,
      onSuccess: onFileUploadSuccess,
      projectId: project ? project.id : null,
      contentType: project ? "project" : "files",
    });
  };

  const handleOverlayRejectedFiles = (files) => {
    handleRejectedFiles(files, {
      contentType: project ? "project" : "files",
    });
  };

  const renderEmptyState = () => (
    <EmptyState title="No files" className="py-10">
      <Button
        className="dark:border-neutral-300 dark:bg-transparent dark:text-neutral-400"
        label="Add File"
        appearance="secondary"
        icon={AddIcon}
        onClick={() => handleAddFilesBtnClick()}
      />
    </EmptyState>
  );

  const renderLoader = () => <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />;

  return (
    <div className="relative mx-auto h-full max-w-[1760px]">
      <SidebarLayout
        isOpen={showSidebar && !isMobile}
        Sidebar={
          <FileDetail
            selectedFile={selectedFile}
            onRename={handleRenameAction}
            onEditAltText={handleEditAltTextAction}
            onDownload={onHandleDownload}
            onDelete={handleDeleteAction}
            setShowGallery={handleShowFileGallery}
            setShowCoordinatesEdit={setShowCoordinatesEdit}
            project={project}
            imageVisible
            isFileDetailLoading={isFileDetailLoading}
            refetchFileDetail={refetch}
            onProjectViewFiles={handleProjectViewFiles}
          />
        }
        onClose={() => {
          selectFile(null);
          setShowSidebar(false);
        }}
        onDragEnter={onOverlayEnter}
        containerClassName={clsx(isFullWidth && "!px-0", showSidebar && "!pr-12")}
        contentClassName={contentClassName}
      >
        <div className="pb-10">
          <div className="mb-6 flex items-center justify-between">
            <div className="flex items-center gap-x-3">
              <span className="text-xl font-normal">{isLoading ? "Files" : pluralize("File", totalCount, true)}</span>
              {project?.houzzPortfolioLink && (
                <Tooltip message="Houzz Portfolio">
                  <a href={project.houzzPortfolioLink} target="_blank" rel="noreferrer">
                    <IconButton icon={FileIcon} />
                  </a>
                </Tooltip>
              )}
            </div>
            <Button label="Add File" icon={AddIcon} onClick={() => handleAddFilesBtnClick()} appearance="secondary" />
          </div>

          <FilesFilters
            showGrid={showGrid}
            onChangeLayout={setShowGrid}
            projectId={project?.id}
            showSpaceFilter={showSpaceFilter}
            showAddedByFilter={showAddedByFilter}
            filters={filters}
          />
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <AlgoliaInifiteHits
            cacheId={algoliaInstanceId}
            emptyStateComponent={renderEmptyState}
            loadingIconComponent={renderLoader}
          >
            <div>
              {showGrid ? (
                <FilesCards
                  files={filesWithUrl}
                  handleContextMenu={handleContextMenu}
                  showSidebar={showSidebar}
                  selectFile={handleSelectFile}
                  selectedFile={selectedFile}
                  selectedMultipleFiles={selectedMultipleFiles}
                  onDownload={onHandleDownload}
                  onDelete={handleDeleteAction}
                  onRename={handleRenameAction}
                  onEditAltText={handleEditAltTextAction}
                  onMultiSelect={handleMultiSelectAction}
                  onSetThumbnail={handleSetThumbnailAction}
                  hideThumbnailAction={hideThumbnailAction}
                />
              ) : (
                <FilesTable
                  files={filesWithUrl}
                  onSelect={(selectedFile) => {
                    selectFile(selectedFile);
                    setShowSidebar(true);
                  }}
                  selectedFile={selectedFile}
                  handleContextMenu={handleContextMenu}
                  selectedMultipleFiles={selectedMultipleFiles}
                  setSelectedMultipleFiles={setSelectedMultipleFiles}
                />
              )}

              <ContextMenu
                key={ui.openContextMenu.row && ui.openContextMenu.row.id}
                openContext={ui.openContextMenu}
                onCloseContext={closeContext}
              >
                <div className="focus-outline-none rounded-xl bg-white shadow-lg" onClick={closeContext}>
                  <div className="w-[234px] py-3 px-2 text-neutral-600">
                    <div
                      className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                      onClick={handleCopyAction}
                    >
                      <span className="mr-3">
                        <LinkIcon width={20} />
                      </span>{" "}
                      Copy link
                    </div>
                    <div
                      className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                      onClick={() => handleMultiSelectAction()}
                    >
                      <span className="mr-3">
                        <GridIcon width={20} />
                      </span>{" "}
                      Multi-Select
                    </div>
                    <div
                      className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                      onClick={() => setShowSidebar(true)}
                    >
                      <span className="mr-3">
                        <EditIcon width={20} />
                      </span>{" "}
                      Edit
                    </div>
                    <div
                      className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                      onClick={onHandleDownload}
                    >
                      <span className="mr-3">
                        <ImportIcon width={20} />
                      </span>{" "}
                      Download
                    </div>
                    <button
                      className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                      onClick={() => setUi({ ...ui, showDeleteToast: true })}
                    >
                      <span className="mr-3">
                        <BinIcon width={20} />
                      </span>{" "}
                      Delete
                    </button>
                    <button
                      className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                      onClick={() => setUi({ ...ui, showRenameModal: true })}
                    >
                      <span className="mr-3">
                        <EditIcon width={20} />
                      </span>{" "}
                      Rename
                    </button>
                    <button
                      className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                      onClick={() => setUi((ui) => ({ ...ui, showEditAltTextModal: true }))}
                    >
                      <span className="mr-3">
                        <EditIcon width={20} />
                      </span>{" "}
                      Edit Alt Text
                    </button>
                    {!hideThumbnailAction && (
                      <button
                        className="item-center flex w-[100%] cursor-pointer py-2.5 px-4 text-sm font-normal hover:bg-neutral-200"
                        onClick={() => handleSetThumbnailAction(selectedFile)}
                      >
                        <span className="mr-3">
                          <ThumbnailIcon width={20} />
                        </span>{" "}
                        Set as thumbnail
                      </button>
                    )}
                  </div>
                </div>
              </ContextMenu>
            </div>
          </AlgoliaInifiteHits>
        )}

        {!showProjectViewer && showGallery && selectedFile?.name && (
          <FileModal
            {...selectedFile}
            onDownload={onHandleDownload}
            src={
              selectedFile?.fileType?.includes("video")
                ? selectedFile?.path?.split(".")?.[0]
                : selectedFile?.fileType?.includes("pdf")
                ? selectedFile?.url
                : selectedFile?.path
            }
            fileName={selectedFile?.name || ""}
            isOpen={showGallery}
            onClose={() => handleShowFileGallery(false)}
            onPrevious={selectedFileIndex > 0 ? handlePrevious : null}
            onNext={selectedFileIndex === filesWithUrl?.length - 1 ? null : handleNext}
            videoProps={{ width: "1.0", height: "1.0" }}
            onEdit={() => {
              setShowEditModal(true);
            }}
            link={selectedFile?.url}
            imgTransformations={{
              width: 2000,
              format: "jpg",
            }}
            isLoading={isFileDetailLoading && !selectedFile?.url}
          />
        )}

        {showProjectViewer && showGallery && (
          <ProjectMediaViewer
            files={filesWithUrl}
            isOpen={showGallery}
            onBack={() => handleShowFileGallery(false)}
            groupBy="dateFormatted"
            onDelete={(fileId) => {
              const fileSelected = filesWithUrl?.find((file) => file.id === fileId);
              handleDeleteAction(fileSelected);
            }}
            onEdit={(fileId) => {
              const fileSelected = filesWithUrl?.find((file) => file.id === fileId);
              setShowEditModal(true);
              selectFile(fileSelected);
            }}
            activeFileId={selectedFile?.id}
          />
        )}

        <Selecto
          container="files-container"
          selectableTargets={[".file-item"]}
          selectByClick={false}
          selectFromInside={true}
          continueSelect={false}
          continueSelectWithoutDeselect={true}
          toggleContinueSelect={["shift"]}
          toggleContinueSelectWithoutDeselect={[["ctrl"], ["meta"]]}
          ratio={0}
          hitRate={10}
          onSelect={(e) => {
            const selectedFiles = e.selected.map((elem) =>
              filesWithUrl.find((file) => file.id === elem.getAttribute("data-key"))
            );
            if (selectedFile) {
              selectFile(null);
              setShowSidebar(false);
            }
            setSelectedMultipleFiles(selectedFiles);
          }}
        />

        <FileDelete
          isOpen={ui.showDeleteToast}
          onClose={() => setUi({ ...ui, showDeleteToast: false })}
          onDeleteSuccess={() => {
            refetchSearchResults([selectedFile], "delete");
            setShowSidebar(false);
            selectFile(null);
          }}
          onDelete={onDeleteFile}
        />

        <FileRenameModal
          key={`modal-rename-${selectedFile?.id}`}
          isOpen={ui.showRenameModal}
          onClose={() =>
            setUi((ui) => ({
              ...ui,
              openContextMenu: clearContextMenu,
              showRenameModal: false,
            }))
          }
          onRename={onUpdateFieldField}
          fileName={selectedFile?.name || ""}
        />

        <FileEditAltTextModal
          key={`modal-edit-alt-text-${selectedFile?.id}`}
          isOpen={ui.showEditAltTextModal}
          onClose={() =>
            setUi((ui) => ({
              ...ui,
              openContextMenu: clearContextMenu,
              showEditAltTextModal: false,
            }))
          }
          altText={selectedFile?.altText || ""}
          onEdit={onUpdateFieldField}
        />

        <FileMultiSelectAction
          selectedFiles={selectedMultipleFiles}
          onClose={() => {
            setSelectedMultipleFiles([]);
          }}
          projectId={project?.id}
          projectName={project?.places?.addressLine1}
          spaceOptions={project ? projectSpacesAsOptions : spacesAsOptions}
          designStyleOptions={designStylesAsOptions}
          contentTypeOptions={contentTypes}
          onEditActionSuccess={(files) => {
            refetchSearchResults(files, "edit");
          }}
          onDeleteActionSuccess={(files) => {
            refetchSearchResults(files, "delete");
          }}
        />

        <FileUploadOverlay
          className="z-30"
          acceptedFileTypes={[
            "image/jpeg",
            "image/png",
            ".pdf",
            ".docx",
            "video/*",
            "image/heic",
            "image/webp",
            ".dwg",
          ]}
          maxFiles={50}
          multiple
          onExit={onOverlayExit}
          handleAcceptedFiles={handleOverlayAcceptedFiles}
          handleRejectedFiles={handleOverlayRejectedFiles}
          {...uploadOverlayProps}
        />

        {showCoordinatesEdit && (
          <MediaCoordinatesModal
            show={showCoordinatesEdit}
            onClose={() => setShowCoordinatesEdit(false)}
            file={selectedFile}
            options={projectSpaceObjectsAsOptions}
          />
        )}

        <Modal isOpen={showEditModal} onClose={() => setShowEditModal(false)} title="File Info">
          <FileDetail
            selectedFile={selectedFile}
            imageVisible={false}
            projectSpace={projectSpace}
            project={project}
            onRename={handleRenameAction}
            onEditAltText={handleEditAltTextAction}
            onDownload={onHandleDownload}
            onDelete={handleDeleteAction}
            isFileDetailLoading={isFileDetailLoading}
            refetchFileDetail={refetch}
            onProjectViewFiles={handleProjectViewFiles}
          />
        </Modal>
      </SidebarLayout>
    </div>
  );
};

export default AlgoliaWrapper(CommonFiles, { indexName: "files" });
