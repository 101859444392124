import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PARTNERS = gql`
  query getPartners($filters: PartnerFilterFields!) {
    getPartners(filters: $filters) {
      id
      email
      avatar
      officePhone
      companyName
      website
      logo
      latitude
      longitude
      einNumber
      services {
        id
        name
      }
      user {
        id
      }
      markets {
        id
        name
      }
      partnerDocuments {
        id
        title
        path
        documentType
        documentId
      }
      isActive
    }
  }
`;

const GET_PARTNER = gql`
  query getPartnerByID($id: Int!) {
    getPartnerByID(id: $id) {
      id
      email
      avatar
      officePhone
      companyName
      website
      logo
      addressLine1
      addressLine2
      city
      state
      zipCode
      latitude
      longitude
      linkedinUrl
      instagramUrl
      pinterestUrl
      facebookUrl
      twitterUrl
      tiktokUrl
      einNumber
      services {
        id
        name
      }
      user {
        id
      }
      markets {
        id
        name
      }
      contacts {
        id
        fullName
        firstName
        lastName
        phone
        avatar
        email
        isPrimary
      }
      partnerDocuments {
        id
        title
        path
        documentType
        documentId
      }
      isActive
    }
  }
`;

const UPDATE_PARTNER = gql`
  mutation updatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      code
      success
      message
      data {
        id
        email
        mobilePhone
        officePhone
        avatar
        addressLine1
        addressLine2
        website
        logo
        city
        state
        zipCode
        companyName
        einNumber
        latitude
        longitude
        services {
          id
          name
        }
        markets {
          id
          name
        }
        partnerDocuments {
          id
          title
          path
          documentType
          documentId
        }
        isActive
      }
    }
  }
`;

const CREATE_PARTNER = gql`
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      code
      success
      message
      data {
        id
        email
        mobilePhone
        officePhone
        website
        einNumber
        logo
        avatar
        addressLine1
        addressLine2
        city
        state
        zipCode
        latitude
        longitude
        companyName
        services {
          id
          name
        }
        partnerDocuments {
          id
          title
          path
          documentType
          documentId
        }
        isActive
      }
    }
  }
`;

const DELETE_PARTNER_DOCUMENT = gql`
  mutation deleteDocumentsFromPartner($input: DeletePartnerDocumentInput!) {
    deleteDocumentsFromPartner(input: $input) {
      code
      success
      message
      data {
        id
        title
        documentType
      }
    }
  }
`;

const DELETE_PARTNERS = gql`
  mutation deletePartners($input: [DeletePartnerRequest]!) {
    deletePartners(input: $input) {
      code
      success
      message
      deletedIds
    }
  }
`;

const UPDATE_PARTNERS = gql`
  mutation updatePartners($input: [UpdatePartnerInput]!) {
    updatePartners(input: $input) {
      code
      success
      message
      data {
        id
        email
        avatar
        officePhone
        companyName
        website
        logo
        addressLine1
        addressLine2
        city
        state
        zipCode
        latitude
        longitude
        einNumber
        services {
          id
          name
        }
        user {
          id
        }
        contacts {
          id
          fullName
          firstName
          lastName
          phone
          avatar
          email
          isPrimary
        }
        partnerDocuments {
          id
          title
          path
          documentType
          documentId
        }
        isActive
      }
    }
  }
`;

export const fetchPartners = async (filters = {}) => {
  return await graphQLClient.request(GET_PARTNERS, { filters });
};

export const fetchPartner = async (id) => {
  return await graphQLClient.request(GET_PARTNER, { id });
};

export const createPartner = async (input = {}) => {
  return await graphQLClient.request(CREATE_PARTNER, { input });
};

export const updatePartner = async (input = {}) => {
  return await graphQLClient.request(UPDATE_PARTNER, { input });
};

export const deletePartnerDocument = async (input = {}) => {
  return await graphQLClient.request(DELETE_PARTNER_DOCUMENT, { input });
};

export const deletePartners = async (input = []) => {
  return await graphQLClient.request(DELETE_PARTNERS, { input });
};

export const updatePartners = async (input = []) => {
  return await graphQLClient.request(UPDATE_PARTNERS, { input });
};
