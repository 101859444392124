import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskOptions: [],
  taskItems: [],
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setAllTasksOptions: (state, action) => {
      const originalState = original(state);

      return {
        ...originalState,
        taskOptions: action.payload,
      };
    },
    setTaskItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        taskItems: action.payload,
      };
    },
  },
});

export const { setAllTasksOptions, setTaskItems } = tasksSlice.actions;

export default tasksSlice.reducer;

export const getAllTaskOptions = (state) => state.tasks.taskOptions;
export const getTaskItems = (state) => state.tasks.taskItems;
