import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createContract } from "../../api";

const useAddContract = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addContractMutation = useMutation(
    (input) => {
      return createContract(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createContract.success) {
          setError(`Error: ${data.createContract?.message}`);
        } else {
          queryClient.setQueryData(["contracts", filters], (old) => {
            return { getContracts: [data.createContract.data, ...old?.getContracts] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message);
      },
    }
  );

  return {
    addContract: addContractMutation.mutate,
    addContractAsync: addContractMutation.mutateAsync,
    addContractLoading: addContractMutation.isLoading,
    addContractSuccess: addContractMutation.isSuccess,
    error,
  };
};

export default useAddContract;
