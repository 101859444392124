const uiSchema = {
  groups: [
    {
      title: "Basics",
      properties: [
        "action",
        "object",
        "parent",
        "services",
        "stage",
        "isMilestone",
        "isCriticalPath",
        "baseLaborTime",
        "baseLaborCost",
        "baseLaborUnitType",
        "points",
      ],
    },
  ],
};

export default uiSchema;
