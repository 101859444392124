import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { updateFiles } from "../../api";
import { getFiles } from "../../filesSlice";

const useUpdateFiles = ({
  projectId,
  skipQueryUpdate = false,
  fileType,
  spaceId,
  createdBy,
  contentType,
  projectSpacesId,
  ids,
  noSpaces = false,
  noContentType = false,
  noTags = false,
  noFileType = false,
  noCreatedBy = false,
  noDesignStyle = false,
  noFinish = false,
  noProductType = false,
} = {}) => {
  const files = useSelector(getFiles);
  const queryClient = useQueryClient();
  const [error, setError] = useState();
  const updateFilesMutation = useMutation(
    (input) => {
      setError(null);
      return updateFiles(input);
    },
    {
      onSuccess: (data) => {
        if (!data?.updateFilesInfo.success) {
          setError(`Error: ${data?.updateFilesInfo?.message}`);
        } else {
          if (skipQueryUpdate) {
            return;
          }
          queryClient.setQueryData(
            [
              "files",
              projectId,
              fileType,
              spaceId,
              createdBy,
              contentType,
              projectSpacesId,
              ids,
              noSpaces,
              noContentType,
              noTags,
              noFileType,
              noCreatedBy,
              noDesignStyle,
              noFinish,
              noProductType,
            ],
            (old) => {
              return {
                ...old,
                pages: [
                  {
                    getFilesInfo: {
                      ...old?.pages?.[0].getFilesInfo,
                      files: old?.pages?.[0].getFilesInfo?.files?.map((file) => {
                        const updatedFile = data?.updateFilesInfo?.data?.find(
                          (updatedFile) => updatedFile.id === file.id
                        );
                        if (updatedFile) {
                          return {
                            ...file,
                            ...updatedFile,
                          };
                        }
                        return file;
                      }),
                    },
                  },
                ],
              };
            }
          );
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    files,
    error,
    updateFiles: updateFilesMutation.mutate,
    updateFilesAsync: updateFilesMutation.mutateAsync,
    updateFilesLoading: updateFilesMutation.isLoading,
    updateFilesSuccess: updateFilesMutation.isSuccess,
  };
};

export default useUpdateFiles;
