import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bills: [],
  billItems: [],
};

export const billsSlice = createSlice({
  name: "bills",
  initialState,
  reducers: {
    setBills: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        bills: action.payload,
      };
    },
    setBillItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        billItems: action.payload,
      };
    },
  },
});

export const { setBills, setBillItems } = billsSlice.actions;

export default billsSlice.reducer;

export const getBills = (state) => state.bills.bills;
export const getBillItems = (state) => state.bills.billItems;
