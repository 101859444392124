import { useMemo } from "react";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import dayjs from "dayjs";

import { QUERY_PARAMS } from "@src/features/layout/constants";
import FileCardItem from "../FileCardItem";
import GroupedFileCards from "./GroupedFileCards";

const FilesCards = ({ files, handleContextMenu, showSidebar, ...restProps }) => {
  const [searchParams] = useSearchParams();

  const groupBySelected = searchParams.get(QUERY_PARAMS.GroupBy);
  const allGroupCollapsed = searchParams.get(QUERY_PARAMS.Collapsed) === "true";
  const allGroupExpanded = searchParams.get(QUERY_PARAMS.Expanded) === "true";

  const filesGroupBy = useMemo(() => {
    if (!groupBySelected) {
      return files;
    }

    if (groupBySelected === "space") {
      return groupBy(files, (item) => item.spaces?.[0] || "Unassigned");
    }

    if (groupBySelected === "added") {
      const filesSorted = sortBy(files, (item) => dayjs(item.createdAt).unix());

      return groupBy(filesSorted, (item) => dayjs(item.uploadedAt).format("MMM D, YYYY"));
    }

    if (groupBySelected === "content") {
      return groupBy(files, (item) => item.contentType || "Unassigned");
    }
  }, [files, groupBySelected]);

  if (!groupBySelected) {
    return (
      <div
        className={clsx(
          "files-container grid grid-cols-2 gap-1 transition-all sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
          showSidebar && "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        )}
      >
        {files.map((file) => (
          <div
            onContextMenu={(event) => {
              handleContextMenu(event, file);
            }}
            key={file?.id}
            className="file-item"
            data-key={file?.id}
          >
            <FileCardItem key={file.id} file={file} {...restProps} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      {Object.keys(filesGroupBy).map((key) => {
        return (
          <GroupedFileCards
            key={key}
            title={key}
            files={filesGroupBy[key]}
            handleContextMenu={handleContextMenu}
            className={clsx(
              "files-container grid grid-cols-2 gap-1 transition-all sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
              showSidebar && "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
            )}
            {...restProps}
            allGroupCollapsed={allGroupCollapsed}
            allGroupExpanded={allGroupExpanded}
          />
        );
      })}
    </div>
  );
};

export default FilesCards;
