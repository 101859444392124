import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { bulkAddSpaceToProject } from "../api";

const useAddSpacesToProject = (projectId) => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      return bulkAddSpaceToProject(input);
    },
    {
      onSuccess: (data) => {
        if (!data.bulkAddProjectSpaces.success) {
          setError(`Error: ${data.bulkAddProjectSpaces.message}`);
          return;
        }

        queryClient.setQueryData(["projects", projectId], (old) => {
          if (old) {
            return {
              getProjectByID: {
                ...old.getProjectByID,
                spaces: old.getProjectByID.spaces
                  ? [...data.bulkAddProjectSpaces.data, ...old.getProjectByID.spaces]
                  : [...data.bulkAddProjectSpaces.data],
              },
            };
          }
        });
        setIsSuccess(true);
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return { addSpacesToProject: mutate, addSpacesToProjectAsync: mutateAsync, isLoading, isSuccess, error };
};

export default useAddSpacesToProject;
