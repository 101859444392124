import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateConsultation } from "../../api";

const useUpdateConsultation = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const { mutate, mutateAsync, isLoading } = useMutation(
    (input) => {
      return updateConsultation(input);
    },
    {
      onSuccess: (data) => {
        if (!data.updateConsultation.success) {
          setError(`Error: ${data.updateConsultation?.message}`);
        } else {
          queryClient.setQueryData(["consultations", filters], (old) => {
            if (!old?.getConsultations) {
              return { getConsultations: [data.updateConsultation.data] };
            }
            return {
              getConsultations: old?.getConsultations?.map((consultation) => {
                if (consultation.id === data.updateConsultation.data.id) {
                  return {
                    ...consultation,
                    ...data.updateConsultation.data,
                  };
                }
                return consultation;
              }),
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    updateConsultationAsync: mutateAsync,
    isLoading,
    updateConsultation: mutate,
  };
};

export default useUpdateConsultation;
