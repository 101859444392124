import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { v4 as uuidv4 } from "uuid";

// Use useToast and addToast to show a toast
import useDesignStyles from "@features/designs/hooks/useDesignStyles";
import useMaterials from "@features/materials/hooks/useMaterials";
import useToast from "@src/utils/hooks/useToast";
import useSuppliers from "../suppliers/hooks/useSuppliers";
import useMultiFilesUpload from "../files/hooks/useMultiFilesUpload";
import { FILE_CONTENT_TYPES, FILE_PATHS, FILE_CONTENT_TYPES_LABEL, FILE_TYPES } from "../files/utils";
import useProductTypes from "./hooks/useProductTypes";
import productFormSchema from "./formSchema";
import productUiSchema from "./uiSchema";
import { mapFormInputToProductFields } from "./utils";
import useAddProduct from "./hooks/useAddProduct";

// Use Form component to display form
// Update formSchema after all dynamic options are fetched
// https://storybook-buildappeal.s3.amazonaws.com/main/index.html?path=/story/mdx-form--basic For details
const AddProduct = ({ onClose }) => {
  const queryClient = useQueryClient();
  const { startUpload } = useMultiFilesUpload();
  const { options: productTypeOptions, isLoading: productTypeLoading } = useProductTypes();
  const { suppliersAsOptions, isLoading: suppliersLoading } = useSuppliers();
  const { options: materialsAsOptions, isLoading: materialsLoading } = useMaterials();
  const { options: designStylesAsOptions, designStylesLoading } = useDesignStyles();
  const { createProductAsync, error } = useAddProduct(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();

  const [formSchema, setFormSchema] = useState(null);

  const uploadFiles = async (productId, type, filesList) => {
    const finalFilesList = (filesList || []).map((file) => {
      const fileKey = `${FILE_PATHS[FILE_CONTENT_TYPES.product]}/${productId}/${uuidv4()}`;
      return {
        key: fileKey,
        contentType: FILE_CONTENT_TYPES_LABEL[FILE_CONTENT_TYPES.product],
        type,
        file,
        productId,
      };
    });
    const results = await startUpload(finalFilesList);
    if (results?.uploadCount !== finalFilesList.length) {
      return null;
    }
    return finalFilesList;
  };

  const suppliersOptions = useMemo(() => {
    if (!suppliersAsOptions?.length) {
      return [];
    }
    return suppliersAsOptions
      .map((option) => {
        return {
          ...option,
          value: option.title,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [suppliersAsOptions]);

  const handleSubmit = async (formState) => {
    const finalFormState = {
      ...formState,
      width: parseFloat(formState.width),
      height: parseFloat(formState.height),
      depth: parseFloat(formState.depth),
      suppliers: formState?.suppliers?.value && [formState.suppliers?.value?.toString()],
      materials: formState.materials?.map((material) => material.value),
      designStyles: formState.designStyles?.map((designStyle) => designStyle.value),
      objects: formState.objects?.map((designStyle) => designStyle.value),
    };
    if (formState.unit) {
      finalFormState.unit = formState.unit?.value;
    }
    delete finalFormState.media;
    delete finalFormState.documents;
    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const data = await createProductAsync({ input: mapFormInputToProductFields(finalFormState) });
    if (data?.createProduct?.success) {
      let productsMedia = [];
      let productDocuments = [];

      if (formState?.media?.length) {
        const finalFilesList = await uploadFiles(data.createProduct.data.id, FILE_TYPES.media, formState.media);
        if (finalFilesList?.length) {
          productsMedia = finalFilesList.map((fileInfo) => ({
            path: fileInfo.key,
            fileType: fileInfo.contentType,
          }));
        }
      }
      if (formState?.documents?.length) {
        const finalFilesList = await uploadFiles(data.createProduct.data.id, FILE_TYPES.document, formState.documents);
        if (finalFilesList?.length) {
          productDocuments = finalFilesList.map((fileInfo) => ({
            path: fileInfo.key,
          }));
        }
      }
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Product Successfully Created");
      queryClient.setQueryData(["products", {}], (old) => {
        return {
          getProducts: [
            { ...data.createProduct.data, media: [...productsMedia], documents: [...productDocuments] },
            ...old?.getProducts,
          ],
        };
      });
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);
  // Attach all dynamic options and callbacks to formSchema in the same effect if possible
  // For files you can directly use the returned formState from the Form component
  // It will send selected files with the property name
  useEffect(() => {
    if (productTypeLoading || suppliersLoading || materialsLoading || designStylesLoading) {
      return;
    }
    const finalSchema = {
      ...productFormSchema,
    };
    const allProperties = productFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "objects") {
          acc[key].options = productTypeOptions;
        }
        if (key === "suppliers") {
          acc[key].options = suppliersOptions;
        }
        if (key === "designStyles") {
          acc[key].options = designStylesAsOptions;
        }
        if (key === "materials") {
          acc[key].options = materialsAsOptions;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [
    productTypeOptions,
    productTypeLoading,
    suppliersLoading,
    suppliersAsOptions,
    materialsLoading,
    materialsAsOptions,
    designStylesLoading,
    designStylesAsOptions,
  ]);
  if (productTypeLoading || materialsLoading || suppliersLoading || designStylesLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return <Form formSchema={formSchema} uiSchema={productUiSchema} isInSidebar isCreate />;
};

export default AddProduct;
