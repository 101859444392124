import { NavCard, TableCells, TableFilters } from "@buildappeal/react-component-library";
import { STATUS_COLOR_CODES } from "../consultations/tableColumnsMap";

export const marketsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: TableCells.TextCell,
    renderCard: ({ row: { original }, column }) => {
      return (
        <NavCard
          layout="card"
          title={original.name}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
        />
      );
    },
  },
  zipCodes: {
    id: "zipcodes",
    Header: "Zip Code Counts",
    accessor: (row) => row.zipCodes?.length,
    Cell: TableCells.TextCell,
  },
};

export const marketPartnersColumn = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "partner",
    Header: "Partner",
    accessor: "companyName",
    Cell: (originalRow) => {
      const { original } = originalRow.row;
      return (
        <div className="flex">
          <TableCells.TitleCell
            getValue={() => original?.companyName}
            subtitle={original?.fullName}
            row={originalRow.row}
            column={originalRow.column}
            {...originalRow}
          />
        </div>
      );
    },
  },
  {
    id: "email",
    Header: "Company Email",
    accessor: "email",
    Cell: TableCells.TextCell,
  },
  {
    id: "officePhone",
    Header: "Company Phone",
    accessor: "officePhone",
    Cell: TableCells.PhoneNumberCell,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      const { getValue } = originalRow;
      return (
        <TableCells.BadgeCell color={STATUS_COLOR_CODES[getValue()] || "gray"} value={getValue()} {...originalRow} />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
];
