import { TableCells, TableFilters, NavCard, TableCards } from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Pending: "orange",
  Unscheduled: "gray",
  Accepted: "green",
  TBD: "gray",
};

export const bidColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city}, ${original.project?.places?.state} ${original.project?.places?.zipCode}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city}, ${original.project?.places?.state} ${original.project?.places?.zipCode}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  partner: {
    id: "company",
    Header: "Partner",
    accessor: (row) => row.partner?.companyName,
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  willBid: {
    id: "willBid",
    Header: "Will Bid",
    accessor: (originalRow) => !!originalRow.willBid,
    Cell: TableCells.BooleanCell,
    renderCard: TableCards.TextRow,
  },
  createdAt: {
    id: "createdAt",
    Header: "Created Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  dueDate: {
    id: "dueDate",
    Header: "Due Date",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    renderCard: TableCards.TextRow,
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
};
