import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clients: [],
  leadStages: [],
  typeClients: [],
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        clients: action.payload.clients,
        typeClients: action.payload.typeClients,
      };
    },
    setTypeClients: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        clients: action.payload,
      };
    },
    setLeadStages: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        leadStages: action.payload,
      };
    },
  },
});

export const { setClients, setLeadStages } = clientsSlice.actions;

export default clientsSlice.reducer;

export const getClients = (state) => state.clients.clients;
export const getTypeClients = (state) => state.clients.typeClients;
export const getLeadStages = (state) => state.clients.leadStages;
