import { PRODUCT_PER_UNITS } from "./utils";

const formSchema = {
  title: "Create Product",
  properties: {
    name: {
      type: "text",
      label: "Product Name",
      required: true,
    },
    sku: {
      type: "text",
      label: "SKU",
      required: true,
    },
    objects: {
      type: "selectMultiple",
      label: "Product Type",
      multiple: true,
    },
    vendorURL: {
      type: "url",
      label: "URL",
      validate: {
        isURL: true,
      },
    },
    suppliers: {
      type: "supplier",
      label: "Supplier",
    },
    brand: {
      type: "text",
      label: "Brand",
    },
    description: {
      type: "textarea",
      label: "Description",
    },
    dimensions: {
      type: "dimensions",
      tabs: ["WxHxD"],
    },
    cost: {
      type: "currency",
      label: "Price",
    },
    unit: {
      type: "select",
      label: "Unit",
      options: PRODUCT_PER_UNITS,
    },
    leadTimeDays: {
      type: "text",
      label: "Lead Time (days)",
    },
    isStocked: {
      type: "toggle",
      label: "In Stock",
      position: "left",
      isLabelSmall: true,
    },
    designStyles: {
      type: "selectMultiple",
      label: "Design Styles",
    },
    materials: {
      type: "selectMultiple",
      label: "Materials",
    },
    media: {
      type: "file",
      acceptedFileTypes: ["image/jpeg", "image/png", "video/*", "image/heic", "image/webp"],
      multiple: true,
      maxFiles: 100,
      appearance: "button",
      handleSelectedFiles: () => {}, // Pass empty handler as no action is needed with Form component
    },
    documents: {
      type: "file",
      acceptedFileTypes: [".pdf", ".docx"],
      multiple: true,
      maxFiles: 100,
      appearance: "button",
      handleSelectedFiles: () => {},
    },
  },
  defaultValues: {
    unit: {
      title: "each",
      value: "each",
    },
  },
};

export default formSchema;
