import { TableCells, TableFilters, TableCards, NavCard } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Pending: "bg-orange-500",
  Draft: "bg-gray-500",
  Paid: "bg-green-500",
  "Processing Payment": "bg-orange-500",
};

export const commonBillsColumns = [
  {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={`Bill #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },

  {
    id: "billDate",
    Header: "Issued",
    accessor: "billDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "dueDate",
    Header: "Due Date",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          show
          status={originalRow.getValue()}
          circleColor={originalRow.getValue() ? STATUS_COLOR_CODES[originalRow.getValue()] || "bg-primary-500" : ""}
          {...originalRow}
        />
      );
    },

    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
];

export const commonBillItemsColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "billId",
    Header: "Bill ID",
    accessor: "bill.id",
    Cell: TableCells.TextCell,
  },
  {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  {
    id: "subTotal",
    Header: "Subtotal",
    accessor: "subTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];

export const columnsBillItems = [...commonBillItemsColumns];
columnsBillItems.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.bill?.project?.places?.addressLine1
      ? `${originalRow.bill?.project?.places?.addressLine1} ${
          originalRow.bill?.project?.places?.addressLine2 || ""
        }`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original?.bill?.project?.places?.city}
        addressState={originalRow.row.original?.bill?.project?.places?.state}
        addressZip={originalRow.row.original?.bill?.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const BillStatusOptions = ["New", "Draft", "Pending", "Paid", "Processing Payment", "Overdue", "Cancelled"];

export const loadingPlaceholderBill = {
  id: "",
  billCode: "",
  billDate: "",
  dueDate: "",
  total: "",
  status: "",
  project: {},
  billItems: [],
};
