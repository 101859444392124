import ProductsMultiSelect from "../products/ProductsMultiSelect";
import PartnersMultiSelect from "../partners/PartnerMultiSelect";
import ProjectsMultiSelect from "../projects/ProjectsMultiSelect";

const tableMultiSelectMap = {
  "/products": {
    name: "Product",
    showEditAction: true,
    showDeleteAction: true,
    renderItem: ProductsMultiSelect,
  },
  "/partners": {
    name: "Partner",
    showEditAction: true,
    showDeleteAction: true,
    renderItem: PartnersMultiSelect,
  },
  "/projects": {
    name: "Project",
    showEditAction: true,
    showDeleteAction: false,
    renderItem: ProjectsMultiSelect,
  },
  "/leads": {
    name: "Project",
    showEditAction: true,
    showDeleteAction: false,
    renderItem: ProjectsMultiSelect,
  },
};

export default tableMultiSelectMap;
