export const VIEW_TYPES = {
  Table: "table",
  Card: "card",
  Map: "map",
  GroupBy: "groupBy",
  Board: "board",
};

export const QUERY_PARAMS = {
  ShowCustomLeft: "showCustomLeft",
  ShowDesignTool: "showDesignTool",
  FullView: "fullView",
  View: "view",
  Id: "id",
  Space: "space",
  Section: "section",
  Tab: "tab",
  Module: "module",
  Search: "q",
  ConsultationId: "consultationId",
  ProposalId: "proposalId",
  OpenIn: "openIn",
  FileType: "fileType",
  NoFileType: "noFileType",
  SpaceId: "spaceId",
  ContentType: "contentType",
  AddedBy: "addedBy",
  NoCreatedBy: "noCreatedBy",
  ProjectSpaceId: "projectSpaceId",
  GroupBy: "groupBy",
  Tag: "tag",
  DesignStylesId: "designStylesId",
  NoDesignStyle: "noDesignStyle",
  FinishesId: "finishesId",
  NoFinish: "noFinish",
  ProductTypesId: "productTypesId",
  NoProductType: "noProductType",
  NoTags: "noTags",
  NoSpaces: "noSpaces",
  NoContentType: "noContentType",
  Object: "object",
  SelectQuantity: "selectQuantity",
  Questions: "questions",
  Expanded: "expanded",
  Collapsed: "collapsed",
};

export const QUERY_PARAMS_FILTERS = {
  ExternalFilter: "ef_",
  ExternalFilterString: "ef_st__",
  ExternalFilterBoolean: "ef_bo__",
  ExternalFilterNumber: "ef_nu__",
  ExternalFilterArrayNumber: "ef_an__",
  ExternalFilterArrayString: "ef_as__",
};

export const PAGE_LEVEL = {
  notes: "notes",
  activities: "activities",
  projects: "projects",
};
