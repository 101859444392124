import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_PROPOSALS = gql`
  query getProposals($filters: ProposalFilterFields!) {
    getProposals(filters: $filters) {
      id
      createdAt
      description
      dateSent
      dateAccepted
      status
      dueDate
      type
      proposalItems {
        id
        projectSpaceId
        serviceId
        name
        cost
        projectSpace {
          id
          spaceNickname
          spaces {
            id
            name
          }
        }
      }
      orderSession {
        id
        stripePaymentLink
      }
      invoice {
        id
        status
      }
      project {
        id
        places {
          id
          addressLine1
          addressLine2
          city
          zipCode
          state
        }
        thumbnail {
          id
          path
        }
        staff {
          id
          firstName
          lastName
          avatar
          title
          signature
        }
        projectStages {
          id
          name
          stageType
        }
      }
    }
  }
`;

const GET_PROPOSAL = gql`
  query getProposalByID($id: Int!) {
    getProposalByID(id: $id) {
      id
      createdAt
      description
      dateSent
      status
      type
      proposalItems {
        id
        projectSpaceId
        serviceId
        name
        cost
        projectSpace {
          id
          spaceNickname
          spaces {
            id
            name
          }
        }
      }
      orderSession {
        id
        stripePaymentLink
      }
      project {
        id
        places {
          id
          addressLine1
          addressLine2
          city
          zipCode
          state
        }
        projectStages {
          id
          name
          stageType
        }
      }
      invoice {
        id
        status
      }
    }
  }
`;

export const GET_PROPOSAL_TEMPLATES = gql`
  query getProposalTemplates {
    getProposalTemplates {
      id
      name
      createdAt
      components
    }
  }
`;

const CREATE_PROPOSAL = gql`
  mutation createProposal($input: CreateProposalRequest!) {
    createProposal(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        status
        type
        proposalItems {
          id
          projectSpaceId
          serviceId
          name
          cost
          projectSpace {
            id
            spaceNickname
            spaces {
              id
              name
            }
          }
        }
        orderSession {
          id
          stripePaymentLink
        }
      }
    }
  }
`;

const UPDATE_PROPOSAL = gql`
  mutation updateProposal($input: UpdateProposalRequest!) {
    updateProposal(input: $input) {
      code
      success
      message
      data {
        id
        createdAt
        description
        status
        dueDate
        type
      }
    }
  }
`;

export const fetchProposals = async (filters = {}) => {
  return await graphQLClient.request(GET_PROPOSALS, { filters });
};

export const fetchProposalTemplates = async () => {
  return await graphQLClient.request(GET_PROPOSAL_TEMPLATES);
};

export const createProposal = async (input = {}) => {
  return await graphQLClient.request(CREATE_PROPOSAL, { input });
};

export const updateProposal = async (input = {}) => {
  return await graphQLClient.request(UPDATE_PROPOSAL, { input });
};

export const fetchProposal = async (id) => {
  return await graphQLClient.request(GET_PROPOSAL, { id });
};
