import { TableCells, TableFilters, TableCards, NavCard } from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Pending: "bg-orange-500",
  Unscheduled: "gray",
  Sent: "bg-green-500",
  Issued: "bg-green-500",
  TBD: "bg-primary-500",
};
export const commonColumnsInvoices = [
  {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      return (
        <NavCard
          title={original.title}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          show
          status={originalRow.getValue()}
          circleColor={originalRow.getValue() ? STATUS_COLOR_CODES[originalRow.getValue()] || "bg-primary-500" : ""}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
  {
    id: "invoiceDate",
    Header: "Issued",
    accessor: "invoiceDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "dueDate",
    Header: "Due",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
  },
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
  },
];

export const commonColumnsInvoiceItems = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "invoiceId",
    Header: "Invoice ID",
    accessor: "invoice.id",
    Cell: TableCells.TextCell,
  },
  {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  {
    id: "subTotal",
    Header: "Subtotal",
    accessor: "subTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
];
export const columnsInvoiceItems = [...commonColumnsInvoiceItems];
columnsInvoiceItems.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.invoice?.project?.places?.addressLine1
      ? `${originalRow.invoice?.project?.places?.addressLine1} ${
          originalRow.invoice?.project?.places?.addressLine2 || ""
        }`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original?.invoice?.project?.places?.city}
        addressState={originalRow.row.original?.invoice?.project?.places?.state}
        addressZip={originalRow.row.original?.invoice?.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const INVOICE_STATUS_MAP = {
  DRAFT: "Draft",
  SENT: "Sent",
  PROCESSING_PAYMENT: "Processing Payment",
  PAYMENT_FAILED: "Payment Failed",
  PAID: "Paid",
  CANCELLED: "Cancelled",
};

export const INVOICE_STATUS_COLOR_CODES = {
  Draft: "gray",
  Sent: "green",
  "Processing Payment": "orange",
  Paid: "green",
  Cancelled: "red",
  "Payment Failed": "red",
};

export const loadingPlaceholderInvoice = {
  id: "",
  title: "",
  invoiceCode: "",
  invoiceTypes: "",
  invoiceDate: "",
  dueDate: "",
  total: "",
  status: "",
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
  },
};
