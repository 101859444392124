import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createClient } from "../../api";

export const useAddClient = ({ onSuccessCallback }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading, mutateAsync } = useMutation(
    (data) => {
      return createClient(data);
    },
    {
      onSuccess: (data) => {
        if (!data.createClient.success) {
          setError(`Error: ${data.addPartnerToProject.message}`);
          return;
        }

        queryClient.setQueryData("clients", (old) => {
          if (!old) return { getClients: [data.createClient.data] };
          return { getClients: [data.createClient.data, ...old.getClients] };
        });
        setIsSuccess(true);
        onSuccessCallback?.();
      },
      onError: () => {
        setError(`Error: ${error.message}`);
      },
      onSettled: () => {
        setIsSuccess(false);
      },
    }
  );

  return { createClientMutation: mutate, createClientAsync: mutateAsync, isLoading, isSuccess, error };
};

export default useAddClient;
