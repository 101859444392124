export const proposalStatusOptions = ["Draft", "Pending", "Awaiting Approval", "Cancelled"];

export const PROPOSAL_STATUS_MAP = {
  DRAFT: "Draft",
  FINALISED: "Finalised",
  AWAITING_APPROVAL: "Awaiting Approval",
  CANCELLED: "Cancelled",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  AWAITING_PAYMENT: "Awaiting Payment",
};

export const PROPOSAL_STATUS_BADGE_COLOR_MAP = {
  Draft: "gray",
  Finalised: "green",
  "Awaiting Approval": "orange",
  Cancelled: "red",
  Approved: "green",
  Rejected: "red",
};

export const getProposalSnapShotSummaryTotal = (projectEstimates, summaryType = "space") => {
  let summary = [];
  let total = 0;
  let estimateItems = [];
  const {
    projectActions = [],
    projectSpaceObjectsActions = [],
    projectSpacesActions = [],
    actionsProjectSpaces = [],
  } = projectEstimates;
  let spaces = {
    Other: 0,
  };
  let servicesMap = {};
  if (summaryType === "space") {
    (projectActions || []).forEach((projAction) => {
      const { services = [], actionObject } = projAction;
      const serviceName = services[0]?.name;
      spaces.Other += projAction.unitCost * (projAction.unitQuantity || 1);
      total += projAction.unitCost * (projAction.unitQuantity || 1);
      estimateItems.push({
        title: `${serviceName || ""} - ${actionObject.action?.verbPresent || ""} ${
          actionObject.object?.description || ""
        }`,
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
        total: projAction.unitCost * (projAction.unitQuantity || 1),
      });
      if (projAction.actionProducts?.length) {
        projAction.actionProducts.forEach((actionProduct) => {
          spaces.Other += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          total += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          estimateItems.push({
            title: `${serviceName || ""} - ${actionProduct.product?.name || ""}`,
            description:
              "It is a long established fact that a reader will be distracted by the readable content of a page.",
            total: actionProduct.unitCost * (actionProduct.unitQuantity || 1),
          });
        });
      }
      if (services.length) {
        if (!(servicesMap[services[0].name] >= 0)) {
          servicesMap[services[0].name] = 0;
        }
        servicesMap[services[0].name] += projAction.unitCost * (projAction.unitQuantity || 1);
      }
    });
    (actionsProjectSpaces || []).forEach((actionProjSpace) => {
      const { services = [], actionsSpaces } = actionProjSpace;
      const { space } = actionsSpaces;
      const serviceName = services?.[0]?.name;
      if (!(spaces[space.name] >= 0)) {
        spaces[space.name] = 0;
      }
      spaces[space.name] += actionProjSpace.unitCost * (actionProjSpace.unitQuantity || 1);
      total += actionProjSpace.unitCost * (actionProjSpace.unitQuantity || 1);
      estimateItems.push({
        title: `${serviceName || ""} - ${actionsSpaces.action?.verbPresent || ""} ${actionsSpaces.space?.name || ""}`,
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
        total: actionProjSpace.unitCost * (actionProjSpace.unitQuantity || 1),
      });
      if (actionProjSpace.actionProducts?.length) {
        actionProjSpace.actionProducts.forEach((actionProduct) => {
          spaces[space.name] += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          total += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          estimateItems.push({
            title: `${serviceName || ""} - ${actionProduct.product?.name || ""}`,
            description:
              "It is a long established fact that a reader will be distracted by the readable content of a page.",
            total: actionProduct.unitCost * (actionProduct.unitQuantity || 1),
          });
        });
      }
      if (services?.length) {
        if (!(servicesMap[services[0].name] >= 0)) {
          servicesMap[services[0].name] = 0;
        }
        servicesMap[services[0].name] += actionProjSpace.unitCost * actionProjSpace.unitQuantity;
      }
    });
    (projectSpaceObjectsActions || []).forEach((projSpaceObjAction) => {
      const { projectSpaces, services, actionObject } = projSpaceObjAction.projectSpaceObject;
      const serviceName = services?.[0]?.name;
      if (!(spaces[projectSpaces.name] >= 0)) {
        spaces[projectSpaces.name] = 0;
      }
      spaces[projectSpaces.name] += projSpaceObjAction.unitCost * (projSpaceObjAction.unitQuantity || 1);
      total += projSpaceObjAction.unitCost * (projSpaceObjAction.unitQuantity || 1);
      estimateItems.push({
        title: `${serviceName || ""} - ${actionObject?.action?.verbPresent || ""} ${
          actionObject?.object?.description || ""
        }`,
        description:
          "There are many variations of passages of Lorem Ipsum, but the majority have suffered alteration in some form.",
        total: projSpaceObjAction.unitCost * (projSpaceObjAction.unitQuantity || 1),
      });
      if (projSpaceObjAction.actionProducts?.length) {
        projSpaceObjAction.actionProducts.forEach((actionProduct) => {
          spaces[projectSpaces.name] += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          total += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          estimateItems.push({
            title: `${serviceName || ""} - ${actionProduct.product?.name || ""}`,
            description:
              "It is a long established fact that a reader will be distracted by the readable content of a page.",
            total: actionProduct.unitCost * (actionProduct.unitQuantity || 1),
          });
        });
      }
    });
    (projectSpacesActions || []).forEach((projSpaceAction) => {
      const { projectSpace, services, actionObject } = projSpaceAction;
      const serviceName = services?.[0]?.name;
      if (!(spaces[projectSpace.name] >= 0)) {
        spaces[projectSpace.name] = 0;
      }
      spaces[projectSpace.name] += projSpaceAction.unitCost * (projSpaceAction.unitQuantity || 1);
      total += projSpaceAction.unitCost * (projSpaceAction.unitQuantity || 1);
      estimateItems.push({
        title: `${serviceName || ""} - ${actionObject?.action?.verbPresent || ""} ${
          actionObject?.object?.description || ""
        }`,
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.",
        total: projSpaceAction.unitCost * (projSpaceAction.unitQuantity || 1),
      });
      if (projSpaceAction.actionProducts?.length) {
        projSpaceAction.actionProducts.forEach((actionProduct) => {
          spaces[projectSpace.name] += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          total += actionProduct.unitCost * (actionProduct.unitQuantity || 1);
          estimateItems.push({
            title: `${serviceName || ""} - ${actionProduct.product?.name || ""}`,
            description:
              "It is a long established fact that a reader will be distracted by the readable content of a page.",
            total: actionProduct.unitCost * (actionProduct.unitQuantity || 1),
          });
        });
      }
    });
    summary = Object.keys(spaces).map((spaceName) => {
      return {
        spaceName,
        total: spaces[spaceName],
      };
    });
  }
  return [summary, total, estimateItems];
};

export const loadingPlaceholderProposal = {
  id: "",
  createdAt: "",
  description: "",
  dateSent: "",
  dateAccepted: "",
  status: "",
  dueDate: "",
  clientTemplateView: {
    type: "",
    totals: {
      unitCost: "",
      baseUnitCost: "",
      marginPercentage: null,
      profit: "",
      extendedCost: "",
      totalPrice: "",
      labor: "",
      product: "",
      profitPercentage: "",
      unitQuantity: "",
    },
  },
  proposalTemplate: {
    id: "",
    name: "",
  },
  snapshot: {
    projectName: "",
    projectEstimates: "",
    tableItems: "",
  },
  invoice: {
    id: "",
    status: "",
    pandaDocUUID: null,
  },
  project: {
    id: "",
    places: {
      id: "",
      addressLine1: "",
      addressLine2: null,
      city: "",
      zipCode: "",
      state: "",
    },
    thumbnail: null,
    staff: null,
  },
};

export const getDesignPackageList = (tableItems) => {
  return tableItems.map((pckVal) => {
    const { aoSpacesPackageItems } = pckVal;
    let total = 0;
    let spacesList = [];
    let pushedSpaces = [];
    (aoSpacesPackageItems || []).forEach((aospItem) => {
      total += aospItem.additionalCost || 0;
      const { space } = aospItem;
      if (!pushedSpaces.includes(space.name)) {
        pushedSpaces.push(space.name);
        spacesList.push({
          title: space.name,
        });
      }
    });
    return {
      id: pckVal.id,
      name: pckVal.name,
      total,
      spaces: spacesList,
      aoSpacesPackageItems,
    };
  });
};
