import { useMemo } from "react";
import { groupBy } from "lodash";

import useUpdateProposal from "../hooks/useUpdateProposal";
import OrderSummary from "./OrderSummary";

const ProposalCreatedView = ({ proposal }) => {
  const { proposalItems, orderSession } = proposal;
  const { editProposalAsync } = useUpdateProposal();

  const total = useMemo(() => {
    if (!proposalItems?.length) return 0;
    return proposalItems.reduce((acc, item) => acc + item.cost, 0);
  }, [proposalItems]);

  const spaces = useMemo(() => {
    if (!proposalItems?.length) return {};
    const groupedSpaces = groupBy(proposalItems, "projectSpace.spaceNickname");
    return groupedSpaces;
  }, [proposalItems]);

  const handleSendToClient = async () => {
    await editProposalAsync({ id: proposal.id, status: "Sent", dateSent: new Date().toISOString() });
  };

  if (!Object.keys(spaces)?.length) return null;

  return (
    <OrderSummary total={total} spaces={spaces} orderSession={orderSession} handleSendToClient={handleSendToClient} />
  );
};

export default ProposalCreatedView;
