import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CloudinaryImage as CloudinaryImageConstructor } from "@cloudinary/url-gen";
import { Document, Page, pdfjs } from "react-pdf";
import { AdvancedImage } from "@cloudinary/react";
import { UilEllipsisV as MenuIcon } from "@iconscout/react-unicons";
import { UisCheckCircle as CheckIcon, UisPlay as PlayIcon } from "@iconscout/react-unicons-solid";
import { Badge, IconButton, Dropdown, Icon } from "@buildappeal/react-component-library";

import CloudinaryImage from "@utils/components/CloudinaryImage";
import { getFormattedDuration, convertNumberToTime } from "@utils/common";
import { getFileType } from "@features/files/utils";

// https://github.com/wojtekmaj/react-pdf/issues/52#issuecomment-1061497022
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const FileCard = ({
  src,
  fileName,
  fileType,
  isActive = false,
  isMultiSelected = false,
  isPreview = false,
  onClick,
  mRef,
  imgTransformations = {},
  className = "",
  classNameContainer = "",
  actions = [],
  metadata,
}) => {
  const { isPdf, isVideo, isAudio, isImage, isOther } = getFileType(fileType);
  const [isShowing, setIsShowing] = useState(false);
  const timeout = useRef(null);

  const onMouseEnter = () => {
    clearTimeout(timeout.current);
    setIsShowing(true);
  };

  const onMouseLeave = () => {
    timeout.current = setTimeout(() => setIsShowing(false), 50);
  };

  const videoThumbnail = isVideo
    ? new CloudinaryImageConstructor(`ba-media/${(process.env.REACT_APP_STAGE || "staging").toLowerCase()}/${src}`, {
        cloudName: process.env.REACT_APP_CLOUDINARY_NAME || "build-appeal",
      }).setAssetType("video")
    : null;

  if (!src) return null;

  return (
    <div
      className={clsx(
        "group relative cursor-pointer break-inside-avoid-column rounded-xl border",
        fileName && "px-2 py-2 pb-6 active:border-primary-700 active:bg-primary-100",
        isActive ? "border-primary-700" : isMultiSelected ? "border-2 border-accent-700" : "border-transparent",
        classNameContainer
      )}
      ref={mRef}
      data-testid="FileCard"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={clsx(
          "relative aspect-[16/10] w-full overflow-hidden rounded-lg",
          isPdf && "flex justify-center bg-neutral-700",
          className
        )}
      >
        {actions?.length > 0 && isShowing && (
          <div className="absolute top-2 right-2 z-[2]">
            <Dropdown
              MenuButton={
                <IconButton
                  icon={MenuIcon}
                  color="black"
                  className="bg-neutral-transparent"
                  onClick={(e) => e.stopPropagation()}
                />
              }
              items={actions}
            />
          </div>
        )}
        <div className="absolute top-0 left-0 right-0 bottom-0 z-[1] bg-opacity-0 transition group-hover:bg-black group-hover:bg-opacity-20"></div>

        {(isImage || isOther) && !isPreview && (
          <CloudinaryImage
            imgSrc={src}
            alt="File image"
            className="h-full w-full object-cover"
            transformations={{ ...imgTransformations, format: "jpg" }}
          />
        )}

        {isImage && isPreview && (
          <img src={src} alt="File" className="h-full w-full object-cover" transformations={imgTransformations} />
        )}

        {isPdf && (
          <Document file={src} onLoadError={console.error}>
            <Page pageNumber={1} height={150} scale={1.0} renderTextLayer={false} renderAnnotationLayer={false} />
          </Document>
        )}

        {isVideo && (
          <>
            <AdvancedImage cldImg={videoThumbnail} className="h-full w-full object-cover" />
            {metadata && metadata.duration ? (
              <div className="absolute bottom-3 right-2 z-[2]">
                <Badge
                  size="xs"
                  label={getFormattedDuration(metadata.duration)}
                  className=" !bg-black text-3xs !text-white opacity-70"
                />
              </div>
            ) : null}
          </>
        )}

        {isAudio && (
          <div className="flex h-full flex-col items-center justify-center">
            <Icon icon={PlayIcon} className="mb-5 text-accent-700" />
            {metadata && metadata.duration ? <span>{convertNumberToTime(metadata.duration)}</span> : null}
          </div>
        )}
      </div>
      {fileName && (
        <span className="mt-3 block overflow-hidden text-ellipsis whitespace-nowrap text-xs">{fileName}</span>
      )}
      {isMultiSelected ? (
        <div className="absolute top-[-4px] right-[-8px] z-[2] h-6 w-6 rounded-full bg-white">
          <CheckIcon className=" fill-accent-700" />
        </div>
      ) : null}
    </div>
  );
};

FileCard.propTypes = {
  src: PropTypes.string.isRequired,
  fileType: PropTypes.string,
  fileName: PropTypes.string,
  metadata: PropTypes.object, // eslint-disable-line
  isActive: PropTypes.bool,
  isMultiSelected: PropTypes.bool,
  isPreview: PropTypes.bool,
  onClick: PropTypes.func,
  mRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line
  imgTransformations: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    responsive: PropTypes.bool,
    lazyload: PropTypes.bool,
  }),
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      wrapper: PropTypes.element,
      onClick: PropTypes.func,
      icon: PropTypes.func,
      avatar: PropTypes.string,
    })
  ),
};

export default FileCard;
