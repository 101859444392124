import { useQuery } from "react-query";
import { fetchEmails } from "../../api";

const useEmails = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const { data, isLoading, refetch } = useQuery(["emails", filters], () => fetchEmails(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
    staleTime: Infinity,
    refetchInterval: Infinity,
  });

  return {
    data: data?.getEmails,
    isLoading,
    refetch,
  };
};

export default useEmails;
