import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createBid } from "../../api";

const useAddBid = (filters = {}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState();

  const addBidMutation = useMutation(
    (input) => {
      return createBid(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createBid.success) {
          setError(`Error: ${data.createBid?.message || "Failed to create Bid"}`);
        } else {
          queryClient.setQueryData(["bids", filters], (old) => {
            return { getBids: [data.createBid.data, ...old?.getBids] };
          });
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    addBid: addBidMutation.mutate,
    addBidAsync: addBidMutation.mutateAsync,
    addBidLoading: addBidMutation.isLoading,
    addBidSuccess: addBidMutation.isSuccess,
    error,
  };
};

export default useAddBid;
