import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_CONTRACTS = gql`
  query getContracts($filters: FilterContractRequest) {
    getContracts(filters: $filters) {
      id
      name
      contractType
      status {
        name
        color
      }
      lastUpdated
      document_id
      total
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        thumbnail {
          id
          path
        }
        projectStages {
          id
          name
          stageType
        }
      }
      clients {
        id
        fullName
        avatar
      }
      staff {
        id
        fullName
        avatar
      }
      creator {
        id
        clients {
          id
          fullName
          avatar
        }
        staff {
          id
          fullName
          avatar
        }
      }
    }
  }
`;

const GET_CONTRACT = gql`
  query getContractByID($id: Int!) {
    getContractByID(id: $id) {
      id
      name
      contractType
      status {
        name
        color
      }
      lastUpdated
      document_id
      project {
        id
        places {
          addressLine1
          addressLine2
          city
          state
          zipCode
        }
        projectStages {
          id
          name
          stageType
        }
      }
      clients {
        id
        fullName
        avatar
      }
      staff {
        id
        fullName
        avatar
      }
    }
  }
`;

const GET_CONTRACT_DOCUMENT_TEMPLATES = gql`
  query getContractDocumentTemplates {
    getContractDocumentTemplates {
      id
      name
      date_created
      date_modified
      version
    }
  }
`;

const UPDATE_CONTRACT = gql`
  mutation updateContract($input: UpdateContractRequest!) {
    updateContract(input: $input) {
      code
      success
      message
      data {
        id
        name
        contractType
        status {
          name
          color
        }
        lastUpdated
        document_id
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        clients {
          id
          fullName
          avatar
          email
        }
        staff {
          id
          fullName
          avatar
          email
        }
      }
    }
  }
`;

const CREATE_CONTRACT = gql`
  mutation createContract($input: CreateContractRequest!) {
    createContract(input: $input) {
      code
      success
      message
      data {
        id
        name
        status {
          name
          color
        }
        contractType
        document_id
        project {
          id
          places {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          projectStages {
            id
            name
            stageType
          }
        }
        clients {
          id
        }
        staff {
          id
        }
        lastUpdated
        createdAt
      }
    }
  }
`;

const SEND_CONTRACT = gql`
  mutation SendContract($input: SendContractRequest!) {
    sendContract(input: $input) {
      code
      success
      message
      data {
        id
        name
        status {
          name
          color
        }
        contractType
        document_id
        project {
          id
        }
        clients {
          id
        }
        staff {
          id
        }
        lastUpdated
        createdAt
      }
    }
  }
`;

export const fetchContracts = async (filters) => {
  return await graphQLClient.request(GET_CONTRACTS, { filters });
};

export const fetchContract = async (id) => {
  return await graphQLClient.request(GET_CONTRACT, { id });
};

export const fetchContractDocumentTemplates = async () => {
  return await graphQLClient.request(GET_CONTRACT_DOCUMENT_TEMPLATES);
};

export const updateContract = async (input = {}) => {
  return await graphQLClient.request(UPDATE_CONTRACT, { input });
};

export const createContract = async (input = {}) => {
  return await graphQLClient.request(CREATE_CONTRACT, { input });
};

export const sendContract = async (input = {}) => {
  return await graphQLClient.request(SEND_CONTRACT, { input });
};
