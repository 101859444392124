import { TableCells, TableCards, NavCard } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Pending: "orange",
  Unscheduled: "gray",
  Approved: "green",
  TBD: "gray",
};

export const estimatesColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          title={`Estimate #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
  },
  createdAt: {
    id: "createdDate",
    Header: "Created Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "totalPrice",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: ({ row: { original }, table }) => {
      return (
        <TableCells.StatusCell
          status={original.status === "active" ? "Active" : "Inactive"}
          circleColor={original.status === "active" ? "bg-green-500" : "bg-red-500"}
          show
          table={table}
        />
      );
    },
  },
};
