import React, { useEffect, useMemo, useState } from "react";
import { Button, Select, ChipGroup } from "@buildappeal/react-component-library";

import { isEmpty } from "lodash";
import noop from "lodash/noop";
import useProjects from "@features/projects/hooks/useProjects";
import useProducts from "@features/products/hooks/useProducts";
import useInvoices from "@features/invoices/hooks/useInvoices";

import { getAddressFromPlaces } from "@src/utils/common";
import useBATypes from "@src/utils/hooks/useBATypes";
import isMediaFile from "@src/utils/isMedia";
import { FILE_CATEGORIES, FILE_CATEGORIES_ITEMS, projectDocumentOptions, FILE_TYPES } from "./utils";
import useUpdateFiles from "./hooks/useUpdateFiles";

const UploadFileForm = ({ onSuccess = noop, dragUploadedResults }) => {
  const { options: projectsAddressAsOptions } = useProjects();
  const { options: productsAsOptions } = useProducts(undefined, {
    disableQuery: isEmpty(dragUploadedResults),
  });
  const { options: invoicesAsOptions } = useInvoices(undefined, {
    disableQuery: isEmpty(dragUploadedResults),
  });
  const { baTypes: contentTypes } = useBATypes("contentTypes");
  const { updateFilesAsync } = useUpdateFiles({});

  const [formState, setFormState] = useState({});
  const [uploadResults, setUploadResults] = useState(null);
  const [errors, setErrors] = useState({
    fileType: "",
    product: "",
    document: "",
    spaces: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileCategory, setSelectedFileCategory] = useState();

  const uploadedFilesByType = useMemo(() => {
    if (!uploadResults || uploadResults.uploadCount === 0) {
      return {
        media: [],
        documents: [],
      };
    }

    return {
      media: uploadResults.uploadedFiles.filter((file) => isMediaFile(file.fileType)),
      documents: uploadResults.uploadedFiles.filter((file) => !isMediaFile(file.fileType)),
    };
  }, [uploadResults]);

  const onlyMediaUploaded = uploadedFilesByType.media.length && !uploadedFilesByType.documents.length;
  const onlyDocumentsUploaded = !uploadedFilesByType.media.length && uploadedFilesByType.documents.length;

  const categoryItems = useMemo(() => {
    if (onlyDocumentsUploaded) {
      return FILE_CATEGORIES_ITEMS.filter((items) => items.id !== FILE_CATEGORIES.PRODUCT).map((item) => ({
        ...item,
        isActive: selectedFileCategory === item.id,
      }));
    }
    if (onlyMediaUploaded) {
      return FILE_CATEGORIES_ITEMS.map((item) => ({
        ...item,
        isActive: selectedFileCategory === item.id,
      }));
    }
    return FILE_CATEGORIES_ITEMS.filter((item) => item.id === FILE_CATEGORIES.OTHER).map((item) => ({
      ...item,
      isActive: selectedFileCategory === item.id,
    }));
  }, [onlyMediaUploaded, onlyDocumentsUploaded, selectedFileCategory]);

  useEffect(() => {
    if (dragUploadedResults) {
      setUploadResults(dragUploadedResults);
    }
  }, [dragUploadedResults]);
  const setFormValue = (valueObject) => {
    setFormState((oldState) => ({
      ...oldState,
      ...valueObject,
    }));

    if (valueObject.fileType) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        fileType: "",
      }));
    }
    if (valueObject.product) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        product: "",
      }));
    }
    if (valueObject.documentType) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        document: "",
      }));
    }
    if (valueObject.spaces) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        spaces: "",
      }));
    }
    if (valueObject.files) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        files: "",
      }));
    }
  };

  const handleClose = () => {
    setFormState({});
    setErrors({});
    setIsLoading(false);
    setSelectedFileCategory(null);
    onSuccess();
  };

  const handleChangeCategory = (selected) => {
    setSelectedFileCategory(selected.id);
  };

  const onUpdateFileInfo = async () => {
    let finalInput = [
      ...uploadedFilesByType.media.map((file) => ({
        id: file.id,
        type: FILE_TYPES.media,
      })),
      ...uploadedFilesByType.documents.map((file) => ({
        id: file.id,
        type: FILE_TYPES.document,
      })),
    ];

    if (selectedFileCategory === FILE_CATEGORIES.PROJECT) {
      finalInput = finalInput.map((file) => ({
        ...file,
        projectId: formState.project?.id || null,
        invoiceId: formState.invoice?.id || null,
        contractId: formState.contract?.id || null,
        spacesId: formState.spaces?.spaceId ? [formState.spaces?.spaceId] : null,
        projectSpacesId: formState.spaces?.id ? [formState.spaces?.id] : null,
        contentType: formState.contentType,
      }));
    }

    if (selectedFileCategory === FILE_CATEGORIES.PRODUCT) {
      finalInput = finalInput.map((file) => ({
        ...file,
        productId: formState.product?.id || null,
      }));
    }
    try {
      setIsLoading(true);
      const result = await updateFilesAsync(finalInput);
      setIsLoading(false);
      const isSuccess = result.updateFilesInfo.success;
      if (isSuccess) {
        onSuccess(uploadResults?.uploadedFiles);
        handleClose();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {errors.files && <p className="my-2 px-6 text-sm font-semibold text-red-700">{errors.files}</p>}
      {uploadResults && (
        <>
          <div className="flex min-h-[500px] w-full flex-1 flex-col overflow-y-auto px-6 pt-8 pb-6">
            <span className="text-lg text-neutral-800">
              Add {uploadResults.uploadCount} {uploadResults.uploadCount > 1 ? "files" : "file"} to:{" "}
            </span>
            <ChipGroup className="mt-6" items={categoryItems} onClick={handleChangeCategory} />
            {selectedFileCategory === FILE_CATEGORIES.PROJECT ? (
              <ProjectFields
                selectedProject={formState.project}
                selectedProjectSpace={formState.spaces}
                selectedDocumentType={formState.documentType}
                selectedInvoice={formState.invoice}
                projectsAddressAsOptions={projectsAddressAsOptions}
                invoicesAsOptions={invoicesAsOptions}
                updateField={setFormValue}
                errors={errors}
                onlyMediaUploaded={onlyMediaUploaded}
                onlyDocumentsUploaded={onlyDocumentsUploaded}
                selectedContentType={formState.contentType}
                contentTypes={contentTypes}
              />
            ) : selectedFileCategory === FILE_CATEGORIES.PRODUCT ? (
              <ProductFields
                selectedProduct={formState.product}
                productsAsOptions={productsAsOptions}
                productError={errors.product}
                updateField={setFormValue}
              />
            ) : null}
          </div>
          {selectedFileCategory === FILE_CATEGORIES.PROJECT && formState.project?.id && (
            <div className="w-full border border-t border-neutral-200 px-6 py-6">
              <Button
                label={isLoading ? "Loading ..." : "Save"}
                onClick={onUpdateFileInfo}
                disabled={isLoading}
                className="w-full rounded-[4px]"
              />
            </div>
          )}
          {selectedFileCategory === FILE_CATEGORIES.PRODUCT && formState.product?.id && (
            <div className="w-full border border-t border-neutral-200 px-6 py-6">
              <Button
                label={isLoading ? "Loading ..." : "Save"}
                onClick={onUpdateFileInfo}
                disabled={isLoading}
                className="w-full rounded-[4px]"
              />
            </div>
          )}
          {selectedFileCategory === FILE_CATEGORIES.OTHER && (
            <div className="w-full border border-t border-neutral-200 px-6 py-6">
              <Button label={"Save"} onClick={handleClose} className="w-full rounded-[4px]" />
            </div>
          )}
        </>
      )}
    </>
  );
};

const ProjectFields = React.memo(
  ({
    selectedProject,
    selectedProjectSpace,
    selectedContentType,
    selectedDocumentType,
    selectedInvoice,
    projectsAddressAsOptions,
    invoicesAsOptions,
    updateField = noop,
    errors,
    onlyMediaUploaded = false,
    onlyDocumentsUploaded = false,
    contentTypes = [],
  }) => {
    return (
      <div className="mt-4 flex flex-col space-y-2">
        <div>
          <Select
            label="Select project"
            value={{
              title: getAddressFromPlaces(selectedProject?.places),
              value: selectedProject?.id || "",
            }}
            options={projectsAddressAsOptions}
            onChange={(opt) => {
              updateField({ project: opt?.project });
            }}
          />
          {errors.action && <p className="my-2 text-sm font-semibold text-red-700">{errors.action}</p>}
        </div>
        {selectedProject && onlyMediaUploaded ? (
          <>
            <div>
              <Select
                label="Select space (optional)"
                value={{
                  title: selectedProjectSpace?.title || "",
                  value: selectedProjectSpace?.value || "",
                }}
                options={selectedProject?.spaces.map((space) => ({
                  title: space.spaceNickname,
                  value: space.id,
                  ...space,
                  space,
                }))}
                onChange={(opt) => {
                  updateField({ spaces: opt });
                }}
              />
              {errors.spaces && <p className="my-2 text-sm font-semibold text-red-700">{errors.spaces}</p>}
            </div>
            <div>
              <Select
                label="Content type"
                value={{
                  title: selectedContentType || "",
                  value: selectedContentType || "",
                }}
                options={contentTypes?.map((content) => ({
                  title: content,
                  value: content,
                }))}
                onChange={(opt) => {
                  updateField({ contentType: opt?.value });
                }}
              />
            </div>
          </>
        ) : null}
        {selectedProject && onlyDocumentsUploaded ? (
          <>
            <div>
              <div>
                <Select
                  label="Select document type"
                  value={{
                    title: selectedDocumentType?.title || "",
                    value: selectedDocumentType?.value || "",
                  }}
                  options={projectDocumentOptions}
                  onChange={(opt) => {
                    updateField({ documentType: opt });
                  }}
                />
                {errors.document && <p className="my-2 text-sm font-semibold text-red-700">{errors.document}</p>}
              </div>
            </div>
            {selectedDocumentType && selectedDocumentType.value === "invoices" && (
              <div>
                <Select
                  label="Select invoice"
                  value={{
                    title: selectedInvoice?.title || "",
                    value: selectedInvoice?.id || "",
                  }}
                  options={invoicesAsOptions}
                  onChange={(opt) => {
                    updateField({ invoice: opt?.invoice });
                  }}
                />
                {errors.action && <p className="my-2 text-sm font-semibold text-red-700">{errors.action}</p>}
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  }
);

const ProductFields = React.memo(({ selectedProduct, productsAsOptions = [], updateField, productError }) => {
  return (
    <div className="mt-4 flex flex-col space-y-2">
      <div>
        {" "}
        <Select
          label="Select product"
          value={{
            title: selectedProduct?.name || "",
            value: selectedProduct?.id || "",
          }}
          options={productsAsOptions}
          onChange={(opt) => {
            updateField({ product: opt?.product });
          }}
        />
        {productError && <p className="my-2 text-sm font-semibold text-red-700">{productError}</p>}
      </div>
    </div>
  );
});
export default UploadFileForm;
