import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CoreContainer } from "@buildappeal/react-component-library";
import { SearchResultPage, SearchPage, SearchUtils } from "@buildappeal/react-component-library/";
import { algoliaSearchIndices, algoliaSearchClient } from "../../utils/algolia";
import useSearch from "./hooks/useSearch";

const Search = () => {
  const location = useLocation();
  const initialSearch = new URLSearchParams(location.search).get("s");
  const { onBackClick, onSearchResultClick, gotoSearchResultPage, onSearchSuccess } = useSearch();
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (initialSearch) {
      const queries = SearchUtils.generateQueries(initialSearch, algoliaSearchIndices);
      SearchUtils.runSearch(algoliaSearchClient, queries, {
        onSuccess: (searchResults) => {
          setSearchResults(searchResults);
        },
      });
    }
  }, [initialSearch]);

  return (
    <CoreContainer className="py-10">
      {initialSearch ? (
        <SearchResultPage
          filterKey="index"
          search={initialSearch}
          searchResults={searchResults}
          onSearchResultClick={onSearchResultClick}
        />
      ) : (
        <div className="px-3">
          <SearchPage
            onBackClick={onBackClick}
            onSearchResultClick={onSearchResultClick}
            gotoSearchResultPage={gotoSearchResultPage}
            algoliaIndices={algoliaSearchIndices}
            algoliaSearchClient={algoliaSearchClient}
            onSearchSuccess={onSearchSuccess}
          />
        </div>
      )}
    </CoreContainer>
  );
};

export default Search;
