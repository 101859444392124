import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leads: [],
  leadsById: {},
  questions: [],
};

export const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setLeads: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        leads: action.payload,
      };
    },
    setLeadDetail: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        leadsById: {
          ...originalState.leadsById,
          [action.payload.id]: action.payload,
        },
      };
    },
    setLeadQuestions: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        questions: action.payload,
      };
    },
    updateQuestion: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        questions: [...originalState.questions].map((question) => {
          return {
            ...question,
            leadQuestions: question.leadQuestions.map((leadQuestion) => {
              const answer = action.payload.find((answerItem) => leadQuestion.id === answerItem.leadQuestionId);

              if (answer) {
                return {
                  ...leadQuestion,
                  answer,
                };
              }

              return leadQuestion;
            }),
          };
        }),
      };
    },
  },
});

export const { setLeads, setLeadDetail, setLeadQuestions, updateQuestion } = leadsSlice.actions;

export default leadsSlice.reducer;

export const getLeads = (state) => state.leads.leads;
export const selectLeadById = (id) => (state) => state.leads.leadsById[id];
export const selectLeadQuestions = (state) => state.leads.questions;
