import { useMemo } from "react";
import { useQuery } from "react-query";
import { fetchLooks } from "../../api";

const useLooks = (filters = {}, hookOptions = { disableQuery: false }) => {
  const { data, isLoading, refetch } = useQuery(["looks", filters], () => fetchLooks(filters), {
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  const looksAsOptions = useMemo(() => {
    if (!data?.getAllLook?.length) {
      return [];
    }

    return data?.getAllLook
      .map((look) => {
        return {
          title: look.name,
          value: look.id,
          look,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [data]);

  return {
    refetch,
    data: data?.getAllLook,
    isLoading,
    options: looksAsOptions,
  };
};

export default useLooks;
