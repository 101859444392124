import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchObjects } from "../../api";
import { setObjects, getObjects } from "../../objectsSlice";

const useObjects = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;
  const dispatch = useDispatch();
  const objects = useSelector(getObjects);

  const { data, isLoading } = useQuery(["objects", filters], () => fetchObjects(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const objectsAsOptions = useMemo(() => {
    if (!objects?.length) {
      return [];
    }
    return objects
      .map((object) => {
        return {
          value: object.id,
          title: object.description,
          object,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [objects]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setObjects(data.getObjects));
  }, [data, isLoading, dispatch]);

  return {
    data: objects,
    isLoading,
    options: objectsAsOptions,
  };
};

export default useObjects;
