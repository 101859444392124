import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templates: [],
  objectTemplates: [],
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        templates: action.payload,
      };
    },
    setObjectTemplates: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        objectTemplates: action.payload,
      };
    },
  },
});

export const { setTemplates, setObjectTemplates } = templatesSlice.actions;

export default templatesSlice.reducer;

export const getTemplates = (state) => state.templates.templates;
export const getObjectTemplates = (state) => state.templates.objectTemplates;
