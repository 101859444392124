import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { truncate, chunk } from "lodash";
import {
  UilMultiply as CloseIcon,
  UilAngleUp as UpIcon,
  UilAngleDown as DownIcon,
  UilBox as BoxIcon,
} from "@iconscout/react-unicons";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { UisCheckCircle as CheckIcon, UisTimesCircle as FailIcon } from "@iconscout/react-unicons-solid";

import ConfirmationModal from "@src/utils/components/ConfirmationModal";
import useAddProductVariants from "@src/features/products/hooks/useAddProductVariants";
import { actionResponseKeyMap, areRequestsAlreadyInQueue } from "./utils";

/**
 *
 *  General request tracker
 *  Uses hooks to make requests, and hence supports client state update (React Query / Redux cache)
 *  Update actionResponseKeyMap with the response field name to be used for success / failure
 *  The tracker batches and runs requests in sets of 5
 *  Accepts itemsInput in format
 *   {
 *    id: "unique-id", // Not related to our data generate this using the input props to create a unique id, helps dedupe requests
 *    input: Object<requestInput>,  // This is the actual request input
 *    name: String, // This is the name of the request, used for display in the tracker
 *   }
 */
const GenActionRequestsTracker = ({ itemsInput, onSuccess, onClose, triggeredBy }) => {
  const [updateQueue, setUpdateQueue] = useState([]);
  const [fullUpdateStatus, setFullUpdateStatus] = useState({});
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isViewUp, setIsViewUp] = useState(true);
  const [updateState, setUpdateState] = useState({});

  const { createProductVariantsAsync } = useAddProductVariants();

  const getFinalRequest = async (input) => {
    switch (triggeredBy) {
      case "productVariantsCreate":
        return createProductVariantsAsync(input);
      default:
        return () => {};
    }
  };

  const updateItems = async () => {
    if (!updateQueue.length) return;

    const requestsToRun = [...itemsInput];
    const newUpdateStatus = {};
    requestsToRun.forEach((item) => {
      newUpdateStatus[item.name] = {
        status: "Saving",
        name: item.name,
      };
    });
    setFullUpdateStatus((prevState) => ({ ...prevState, ...newUpdateStatus }));

    const requestsSet = chunk(requestsToRun, 5);
    for (let i = 0; i < requestsSet.length; i++) {
      const currentSet = requestsSet[i];
      const inputs = currentSet.map((item) => item.input);
      let newUpdateStatus = {};
      try {
        const updateStatus = await getFinalRequest(inputs);
        if (updateStatus[actionResponseKeyMap[triggeredBy]].success) {
          onSuccess?.(updateStatus[actionResponseKeyMap[triggeredBy]].data);
          currentSet.forEach((item) => {
            newUpdateStatus = {
              ...newUpdateStatus,
              [item.name]: {
                status: "Completed",
                name: item.name,
              },
            };
          });
          setFullUpdateStatus((prevState) => {
            return { ...prevState, ...newUpdateStatus };
          });
        } else {
          currentSet.forEach((item) => {
            newUpdateStatus = {
              ...newUpdateStatus,
              [item.name]: {
                status: "Failed",
                name: item.name,
              },
            };
          });
          setFullUpdateStatus((prevState) => {
            return { ...prevState, ...newUpdateStatus };
          });
        }
      } catch (err) {
        console.log("@@ Error ", err);
        currentSet.forEach((item) => {
          newUpdateStatus = {
            ...newUpdateStatus,
            [item.name]: {
              status: "Failed",
              name: item.name,
            },
          };
        });
        setFullUpdateStatus((prevState) => {
          return { ...prevState, ...newUpdateStatus };
        });
      }
    }
  };

  const getReqStatus = (itemNameKey) => {
    if (!fullUpdateStatus?.[itemNameKey]) {
      return null;
    }
    const itemStatus = fullUpdateStatus[itemNameKey];
    if (itemStatus.status === "Failed") {
      return <FailIcon className="h-6 w-6 text-red-700" aria-hidden="true" />;
    }
    if (itemStatus.status === "Completed") {
      return <CheckIcon className="h-6 w-6 text-green-700" aria-hidden="true" />;
    }
    return <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-neutral-900" />;
  };

  const onCloseClick = () => {
    setUpdateQueue([]);
    onClose({
      isOpen: false,
      currentAction: null,
      triggeredBy: null,
      actionProps: null,
    });
  };

  useEffect(() => {
    if (!updateQueue.length) return;
    let updatingItems = 0;
    Object.keys(fullUpdateStatus || {}).forEach((groupKey) => {
      const itemsList = fullUpdateStatus[groupKey];
      Object.keys(itemsList).forEach((itemNameKey) => {
        if (itemsList[itemNameKey].status === "Saving") {
          updatingItems++;
        }
      });
    });
    setUpdateState((prevState) => ({
      isUpdating: !!updatingItems,
      updatingItems,
    }));
  }, [fullUpdateStatus]);

  useEffect(() => {
    if (!itemsInput?.length) {
      return;
    }
    let reqInQueue = areRequestsAlreadyInQueue(updateQueue, itemsInput);
    if (!reqInQueue) {
      setUpdateQueue((prevQ) => [...prevQ, ...itemsInput]);
    }
  }, [itemsInput, updateQueue]);

  useEffect(() => {
    if (!updateQueue?.length) {
      return;
    }
    updateItems();
  }, [updateQueue]);

  return (
    <>
      <ConfirmationModal
        isOpen={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
        onDelete={onCloseClick}
        title="Cancel operation"
        message="Closing this window  will remove all files from the update queue and you will no longer be able to track progress or add further details. Update of files will not be cancelled."
      />

      <div
        className={clsx(
          "text-x flex-flex-col fixed left-10 z-[9999999999999] min-h-[320px] w-[416px] drop-shadow-2xl",
          {
            "bottom-0": isViewUp,
            "bottom-[-264px]": !isViewUp,
          }
        )}
      >
        <div className="flex h-[64px] flex-row items-center justify-between rounded-t-lg bg-neutral-900  px-5 text-neutral-50">
          <div className="items-center text-xs">
            {!updateState?.isUpdating && !updateState?.updatingItems && updateQueue?.length ? (
              <span className="flex items-center">
                {" "}
                <CheckIcon className="h-6 w-6 text-neutral-50" aria-hidden="true" />
                {`Updated ${updateQueue.length} items.`}
              </span>
            ) : (
              <span className="flex items-center">
                <SpinnerIcon role="status" className="mr-2 h-5 w-5 animate-spin fill-neutral-50" />{" "}
                {`Updating ${updateState?.updatingItems} items.`}
              </span>
            )}
          </div>
          <div className="flex flex-row items-center">
            {isViewUp ? (
              <DownIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(false)} />
            ) : (
              <UpIcon className="mr-2 h-6 w-6" onClick={() => setIsViewUp(true)} />
            )}
            <CloseIcon
              className="h-4 w-4"
              onClick={() => {
                if (!updateState?.isUpdating && !updateState?.updatingItems && updateQueue?.length) {
                  onCloseClick();
                  return;
                }
                setIsCancelOpen(true);
              }}
            />
          </div>
        </div>
        {isViewUp ? (
          <div className="text-primary divide-y-1 flex h-[308px] flex-col overflow-y-auto bg-white">
            {Object.keys(fullUpdateStatus || {}).map((itemName) => {
              const itemStatus = fullUpdateStatus[itemName];
              return (
                <div key={itemName} className="flex min-h-[56px] flex-row items-center justify-between px-5">
                  <div className="flex flex-row">
                    <span className="mr-3">
                      <BoxIcon width={20} className="text-accent-700" />
                    </span>
                    <span className="text-sm">{truncate(itemStatus.name, { length: 34 })}</span>
                  </div>
                  {getReqStatus(itemName)}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default GenActionRequestsTracker;
