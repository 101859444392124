const formSchema = {
  title: "Create Object",
  properties: {
    description: {
      type: "text",
      label: "Name",
      required: true,
    },
    icon: {
      type: "text",
      label: "Icon",
    },
    objectType: {
      type: "select",
      label: "Type",
    },
    parent: {
      type: "select",
      label: "Parent",
    },
  },
};

export default formSchema;
