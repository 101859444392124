import { useCallback, useEffect, useState } from "react";
import { LoadingSpinner, SigninForm } from "@buildappeal/react-component-library";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStytch } from "@stytch/stytch-react";

import useQueryParams from "@src/utils/hooks/useQueryParams";
import { setIsLoggedIn, setUserEmail } from "@src/features/auth/authSlice";
import { internalLogin } from "./api";

const Login = ({ callback }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const stytchClient = useStytch();

  const [showEmailSent, setShowEmailSent] = useState(false);

  const sendMagicLink = useMutation(
    ({ email }) => {
      return stytchClient.magicLinks.email.loginOrCreate(email, {
        login_magic_link_url: `${window.location.origin}/login/callback`,
        login_expiration_minutes: 10080,
        signup_magic_link_url: `${window.location.origin}/login/callback`,
        signup_expiration_minutes: 10080,
      });
    },
    {
      onSuccess: async (sentStatus) => {
        if (sentStatus?.status_code === 200) {
          setShowEmailSent(true);
        }
      },
      onError: (error) => {
        setShowEmailSent(false);
        console.error(error);
      },
    }
  );

  const loginAction = useMutation((args) => internalLogin(args), {
    onSuccess: async (loginResponse) => {
      dispatch(setIsLoggedIn(true));
      dispatch(setUserEmail(loginResponse.email));
      if (loginResponse.status === "redirect") {
        navigate("/", { replace: true });
      }
    },
    onError: (error) => {
      // TODO: Handle failed login
      console.error(error);
    },
  });

  const loginCheck = useCallback(async () => {
    if (callback) {
      try {
        return loginAction.mutate({ token: queryParams.get("token"), provider: queryParams.get("provider") });
      } catch (e) {
        console.error(e);
        return;
      }
    }
  }, []);

  useEffect(() => {
    loginCheck();
  }, []);

  const handleSubmit = (email) => {
    sendMagicLink.mutate({ email });
  };
  const handleSubmitProvider = (provider) => {
    window.open(
      stytchClient.oauth.google.getUrl({
        login_redirect_url: `${window.location.origin}/login/callback?provider=google`,
        signup_redirect_url: `${window.location.origin}/login/callback?provider=google`,
      }),
      "_blank"
    );
  };

  if (callback) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="w-52">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen">
      {showEmailSent ? (
        <div className="flex h-full min-h-full w-full items-center justify-center bg-primary-700 py-12 px-4 text-center text-md text-white sm:px-6 lg:px-8">
          An email has been sent to you, please follow the link to login. You may close this window.
        </div>
      ) : (
        <SigninForm onSubmit={handleSubmit} onSubmitProvider={handleSubmitProvider} />
      )}
    </div>
  );
};

export default Login;
