import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_ACTIONS_OBJECTS = gql`
  query getActionsObjects($filters: ActionObjectFilterFields!) {
    getActionsObjects(filters: $filters) {
      id
      action {
        id
        verbPresent
      }
      object {
        id
        description
      }
      name
      isMilestone
      isCriticalPath
      baseLaborTime
      baseLaborUnitType
      baseLaborCost
      points
      parent
      isActive
      attributes {
        id
        attribute {
          id
          name
          dataType
        }
        options {
          name
          additionalCost
        }
      }
      invoiceItem {
        id
      }
      services {
        id
        name
      }
      actionObjectEntity
      stages {
        id
        name
        stageType
        stageStatus
        order
      }
    }
  }
`;

const GET_ACTIONS_OBJECTS_PRODUCTS = gql`
  query getActionsObjects($filters: ActionObjectFilterFields!) {
    getActionsObjects(filters: $filters) {
      id
      isActive
      action {
        id
        verbPresent
      }
      object {
        id
        description
        products {
          id
          name
          description
          leadTimeDays
          cost
          sku
          brand
          isStocked
          dimensions
          finish
          color
          isTaxExempt
          isRefundable
          vendorURL
          unit
          type {
            id
            description
          }
          media {
            id
            path
            name
            fileType
            type
          }
          createdAt
        }
      }
      name
      isMilestone
      isCriticalPath
      baseLaborTime
      baseLaborUnitType
      baseLaborCost
      points
      parent
      attributes {
        id
        attribute {
          id
          name
          dataType
        }
        options {
          name
          additionalCost
        }
      }
      invoiceItem {
        id
      }
      services {
        id
        name
      }
      actionObjectEntity
      stages {
        id
        name
        stageType
        stageStatus
        order
      }
    }
  }
`;

const GET_ACTIONS = gql`
  query getActions($filters: ActionFilterFields!) {
    getActions(filters: $filters) {
      id
      verbPresent
    }
  }
`;

const GET_OBJECTS = gql`
  query getObjects($filters: ObjectFilterFields!) {
    getObjects(filters: $filters) {
      id
      description
      objectType
    }
  }
`;

const UPDATE_ACTIONS_OBJECTS = gql`
  mutation updateActionObject($input: UpdateActionObjectRequest!) {
    updateActionObject(input: $input) {
      code
      success
      message
      data {
        id
        action {
          id
          verbPresent
        }
        object {
          id
          description
        }
        name
        isActive
        isMilestone
        isCriticalPath
        baseLaborTime
        baseLaborUnitType
        baseLaborCost
        points
        parent
        attributes {
          id
          attribute {
            id
            name
            dataType
          }
          options {
            name
            additionalCost
          }
        }
        invoiceItem {
          id
        }
        services {
          id
          name
        }
        actionObjectEntity
        stages {
          id
          name
          stageType
          stageStatus
          order
        }
      }
    }
  }
`;

const CREATE_ACTIONS_OBJECTS = gql`
  mutation createActionObject($input: CreateActionObjectRequest!) {
    createActionObject(input: $input) {
      code
      success
      message
      data {
        id
        action {
          id
          verbPresent
        }
        object {
          id
          description
        }
        name
        isMilestone
        isCriticalPath
        baseLaborTime
        baseLaborUnitType
        baseLaborCost
        points
        parent
        attributes {
          id
          attribute {
            id
            name
            dataType
          }
          options {
            name
            additionalCost
          }
        }
        invoiceItem {
          id
        }
        services {
          id
          name
        }
        actionObjectEntity
        stages {
          id
          name
          stageType
          stageStatus
          order
        }
      }
    }
  }
`;

const DELETE_ACTIONS_OBJECTS = gql`
  mutation deleteActionObject($input: DeleteActionObjectRequest!) {
    deleteActionObject(input: $input) {
      code
      success
      message
      data {
        id
      }
    }
  }
`;

const GET_ACTION_OBJECT = gql`
  query getActionObject($filters: ActionObjectFilterFields!) {
    getActionObject(filters: $filters) {
      id
      action {
        id
        verbPresent
      }
      object {
        id
        description
      }
      name
      isMilestone
      isCriticalPath
      baseLaborTime
      baseLaborUnitType
      baseLaborCost
      points
      parent
      attributes {
        id
        attribute {
          id
          name
          dataType
        }
        options {
          name
          additionalCost
        }
      }
      invoiceItem {
        id
      }
      services {
        id
        name
      }
      actionObjectEntity
      stages {
        id
        name
        stageType
        stageStatus
        order
      }
    }
  }
`;

const GET_PROJECT_STAGES = gql`
  query getProjectStages {
    getProjectStages {
      id
      name
      stageType
      stageStatus
      order
      projectStageStatuses {
        id
        name
        color
      }
    }
  }
`;
export const fetchActionsObjects = async (filters = {}) => {
  return await graphQLClient.request(GET_ACTIONS_OBJECTS, { filters });
};

export const fetchActionsObjectsWithProducts = async (filters = {}) => {
  return await graphQLClient.request(GET_ACTIONS_OBJECTS_PRODUCTS, { filters });
};

export const fetchActions = async (filters = {}) => {
  return await graphQLClient.request(GET_ACTIONS, { filters });
};

export const fetchObjects = async (filters = {}) => {
  return await graphQLClient.request(GET_OBJECTS, { filters });
};

export const updateActionsObjects = async (input) => {
  return await graphQLClient.request(UPDATE_ACTIONS_OBJECTS, { input });
};

export const createActionsObjects = async (input) => {
  return await graphQLClient.request(CREATE_ACTIONS_OBJECTS, { input });
};

export const deleteActionsObjects = async (input) => {
  return await graphQLClient.request(DELETE_ACTIONS_OBJECTS, { input });
};

export const fetchActionObject = async (filters = {}) => {
  return await graphQLClient.request(GET_ACTION_OBJECT, { filters });
};

export const fetchProjectStages = async (filters = {}) => {
  return await graphQLClient.request(GET_PROJECT_STAGES, { filters });
};
