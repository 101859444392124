export const ProjectSections = {
  INSIGHTS: "insights",
  FINANCIALS: "financials",
  EMAILS: "emails",
};

export const ProjectSectionsNavItems = Object.values(ProjectSections).map((section) => ({
  id: section,
  label: section
    .split("-")
    .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
    .join(" "),
}));

export const ProjectTypes = ["Design", "Design + Build", "Build", "Staging"].sort((a, b) => a.localeCompare(b));

const getAOSpacePackageItem = (aoSpacesPackageItem) => {
  if (!aoSpacesPackageItem) return null;
  const { aoSpaces } = aoSpacesPackageItem;
  return {
    id: aoSpacesPackageItem.id,
    description: `${aoSpaces?.actionsObjects?.action?.verbPresent} ${aoSpaces?.actionsObjects?.object?.description}`,
    additionalCost: aoSpaces.additionalCost,
    space: aoSpaces.space,
  };
};
export const convertDesignPackagesToLineItems = (packages) => {
  const convertedPackages = [];
  packages.forEach((packageVal) => {
    const { aoSpacesPackageItems, ...rest } = packageVal;
    const finalItem = {
      ...rest,
      aoSpacesPackageItems: aoSpacesPackageItems.map(getAOSpacePackageItem),
    };
    convertedPackages.push(finalItem);
  });
  return convertedPackages;
};

export const STATUS_COLOR_CODES = {
  Lead: "bg-orange-500",
  Planning: "bg-blue-500",
  Design: "bg-yellow-500",
  Procurement: "bg-gray-500",
  Build: "bg-green-500",
  Completed: "bg-green-500",
  General: "bg-indigo-500",
  Staging: "bg-violet-500",
  Archive: "bg-gray-300",
};
