import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchResults: [],
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchResults: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        searchResults: action.payload,
      };
    },
  },
});

export const { setSearchResults } = searchSlice.actions;

export default searchSlice.reducer;

export const getSearchResults = (state) => state.search.searchResults;
