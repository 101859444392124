const formSchema = {
  title: "Create Change Order",
  properties: {
    project: {
      type: "project",
      label: "Project",
      required: true,
    },
    changeOrderTypes: {
      type: "select",
      label: "Type",
    },
    total: {
      type: "currency",
      label: "Total",
      required: true,
    },
    dueDate: {
      type: "datetime",
      label: "Due Date",
    },
  },
};

export default formSchema;
