export const quoteFormSchema = {
  title: "Create Quote",
  properties: {
    project: {
      type: "project",
      label: "Project",
    },
    total: {
      type: "number",
      label: "Total",
      required: true,
      leadingAddOn: "$",
    },
    quoteNumber: {
      type: "text",
      label: "Quote Number",
    },
    document: {
      type: "file",
      acceptedFileTypes: [".pdf", ".docx"],
      multiple: false,
      maxFiles: 25,
      appearance: "button",
      handleSelectedFiles: () => {},
    },
  },
};

export const formSchema = {
  title: "Create Supplier",
  properties: {
    name: {
      type: "text",
      label: "Company Name",
      required: true,
    },
    email: {
      type: "email",
      label: "Email",
      required: true,
    },
    phone: {
      type: "phone",
      label: "Phone",
      defaultCountry: "US",
      maxLength: 14,
      required: true,
    },
    website: {
      type: "url",
      label: "Website",
      validate: {
        isURL: true,
      },
    },
    categories: {
      type: "selectMultiple",
      label: "Categories",
    },
    address: {
      type: "address",
      label: "Address",
    },
    logo: {
      type: "file",
      label: "Logo",
      maxFiles: 1,
      multiple: false,
      handleSelectedFiles: (files) => {
        console.log(files);
      },
    },
    checkBoxVal: {
      type: "checkbox",
      isConditional: true,
      formCondition: {
        true: {
          contact: {
            type: "select",
            label: "Select Contact",
          },
        },
        false: {
          contactFirstName: {
            type: "text",
            label: "Contact First Name",
          },
          contactLastName: {
            type: "text",
            label: "Contact Last Name",
          },
          contactEmail: {
            type: "email",
            label: "Contact Email",
          },
          contactPhone: {
            type: "phone",
            label: "Contact Phone",
            defaultCountry: "US",
            maxLength: 14,
          },
        },
      },
      label: "Already has a Contact",
    },
  },
};

export const contactFormSchema = {
  title: "Create Contact",
  properties: {
    firstName: {
      type: "person",
    },
    email: {
      type: "email",
      label: "Email",
    },
    phone: {
      type: "phone",
      label: "Phone",
      defaultCountry: "US",
      maxLength: 14,
    },
    isPrimary: {
      type: "checkbox",
      label: "Primary Contact",
    },
    avatar: {
      type: "file",
      buttonLabel: "Add Photo",
      acceptedFileTypes: ["image/jpeg", "image/png", "image/heic", "image/webp"],
      multiple: false,
      appearance: "button",
      maxFiles: 1,
      maxFileSize: 1000000,
      className: "mt-8",
      handleSelectedFiles: () => {},
    },
  },
};
