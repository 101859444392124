import { useQuery } from "react-query";
import { fetchConsultations } from "../../api";

const useConsultations = (filters = {}, hookOptions = { disableQuery: false }) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    ["consultations", filters],
    () => fetchConsultations(filters),
    {
      refetchOnWindowFocus: false,
      enabled: !hookOptions?.disableQuery,
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchInterval: false,
    }
  );

  return {
    data: data?.getConsultations,
    isLoading,
    refetch,
    isFetching,
  };
};

export default useConsultations;
