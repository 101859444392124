import { useState } from "react";
import { useMutation } from "react-query";
import { createClient } from "../../../clients/api";

const useAddLead = () => {
  const [error, setError] = useState(null);

  const { mutate, isLoading, mutateAsync, isSuccess } = useMutation(
    (input) => {
      return createClient(input);
    },
    {
      onSuccess: (data) => {
        if (!data.createClient.success) {
          setError(data.createClient?.message || "Failed to create Lead");
        }
      },
      onError: (e) => {
        setError(e?.response?.errors?.[0]?.message || e?.message);
      },
    }
  );

  return {
    createLead: mutate,
    isLoading,
    error,
    createLeadAsync: mutateAsync,
    isSuccess,
  };
};

export default useAddLead;
