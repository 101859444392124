import { contactAssignedTo } from "./utils";

const formSchema = {
  title: "Create Contact",
  properties: {
    addContactTo: {
      type: "selectChip",
      appearance: "black",
      options: Object.values(contactAssignedTo).map((opt) => ({
        title: opt,
        value: opt,
      })),
      isConditional: true,
      initialCondition: contactAssignedTo.SUPPLIER,
      formCondition: {
        [contactAssignedTo.PARTNER]: {
          partner: {
            type: "company",
            label: "Partner Company",
          },
        },
        [contactAssignedTo.SUPPLIER]: {
          supplier: {
            type: "supplier",
            label: "Supplier Company",
          },
        },
      },
    },
    firstName: {
      type: "person",
    },
    email: {
      type: "email",
      label: "Email",
    },
    phone: {
      type: "phone",
      label: "Phone",
      defaultCountry: "US",
      maxLength: 14,
    },
    isPrimary: {
      type: "checkbox",
      label: "Primary Contact",
    },
    avatar: {
      type: "file",
      buttonLabel: "Add Photo",
      acceptedFileTypes: ["image/jpeg", "image/png", "image/heic", "image/webp"],
      multiple: false,
      appearance: "button",
      maxFiles: 1,
      maxFileSize: 1000000,
      className: "mt-8",
      handleSelectedFiles: () => {},
    },
  },
};

export default formSchema;
