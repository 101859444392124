import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateContact } from "../../api";

const useContactEdit = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const onSuccess = (data) => {
    if (!data.updateContact.success) {
      setError(`Error: ${data.updateContact.message}`);
    }
    setIsSuccess(true);

    const contactUpdated = data.updateContact.data;

    queryClient.setQueryData(["contacts", {}], (old) => {
      return {
        getContacts: old?.getContacts?.map((contact) => {
          if (contact.id === contactUpdated.id) {
            return {
              ...contact,
              ...contactUpdated,
            };
          }
          return contact;
        }),
      };
    });

    queryClient.setQueryData(["people", {}], (old) => {
      return {
        getPeople: old?.getPeople?.map((person) => {
          if (person.id === contactUpdated.id) {
            return {
              ...person,
              ...contactUpdated,
            };
          }
          return person;
        }),
      };
    });

    queryClient.setQueryData(["peopleDetail", { id: contactUpdated.id, type: "contact" }], (old) => {
      return {
        getPeopleByID: {
          ...(old?.getPeopleByID || {}),
          ...contactUpdated,
        },
      };
    });
  };

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      setError("");
      setIsSuccess(false);
      return updateContact(input);
    },
    {
      onSuccess,
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return { editContact: mutate, isLoading, isSuccess, error, editContactAsync: mutateAsync };
};

export default useContactEdit;
