import { useQuery } from "react-query";

import { fetchTasks } from "../../api";

const useTasks = (filters = {}, hookOptions = { disableQuery: false }) => {
  const { data, isLoading, refetch } = useQuery(["tasks", filters], () => fetchTasks(filters), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !hookOptions.disableQuery,
  });

  return {
    refetch,
    data: data?.getTasks?.tasks,
    isLoading,
    totalCount: data?.getTasks?.totalCount,
  };
};

export default useTasks;
