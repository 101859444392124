import { useMemo } from "react";
import { useQuery } from "react-query";
import sortBy from "lodash/sortBy";

import { fetchCollections } from "../../api";

const useCollections = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const { data, isLoading } = useQuery(["collections", filters], () => fetchCollections(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const collectionsAsOptions = useMemo(() => {
    if (!data?.getCollections?.length) {
      return [];
    }
    return sortBy(
      data?.getCollections.map((collection) => {
        return {
          value: collection.id,
          title: collection.name,
          collection,
        };
      }),
      "title"
    );
  }, [data]);
  return {
    data: data?.getCollections,
    isLoading,
    options: collectionsAsOptions,
  };
};

export default useCollections;
