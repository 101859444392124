import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_MARKETS = gql`
  query getMarkets($filters: MarketFilterFields!) {
    getMarkets(filters: $filters) {
      id
      name
      createdAt
      partners {
        id
        companyName
      }
      services {
        id
        name
      }
      suppliers {
        id
        name
      }
      zipCodes
    }
  }
`;

const GET_MARKET_BY_ID = gql`
  query getMarketByID($id: Int!) {
    getMarketByID(id: $id) {
      id
      name
      createdAt
      partners {
        id
        companyName
        email
        avatar
        addressLine1
        addressLine2
        city
        state
        zipCode
        status
        mobilePhone
        officePhone
      }
      services {
        id
        name
        parent {
          id
          name
        }
      }
      suppliers {
        id
        name
        email
        addressLine1
        city
        zipCode
        state
        labelColor
        website
        logo
        phone
      }
      zipCodes
    }
  }
`;

export const fetchMarkets = async (filters) => {
  return await graphQLClient.request(GET_MARKETS, { filters });
};

export const fetchMarket = async (id) => {
  return await graphQLClient.request(GET_MARKET_BY_ID, { id });
};
