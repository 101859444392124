import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deletePartners } from "../../api";

const useDeletePartners = (filters = {}) => {
  const queryClient = useQueryClient();

  const [error, setError] = useState();
  const deletePartnersMutation = useMutation(
    (input) => {
      setError(null);
      return deletePartners(input);
    },
    {
      onSuccess: (data) => {
        if (!data.deletePartners?.success) {
          setError(`Error: ${data.deletePartners?.message}`);
        } else {
          const deletedPartnerIds = data?.deletePartners?.deletedIds;
          if (!deletedPartnerIds) {
            return;
          }
          queryClient.setQueryData("partners", (old) => {
            if (!old) return { getPartners: [data.updatePartner.data] };
            return {
              getPartners: old.getPartners.filter((partner) => !deletedPartnerIds.includes(partner.id)),
            };
          });
        }
      },
      onError: (error) => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    error,
    deletePartners: deletePartnersMutation.mutate,
    deletePartnersAsync: deletePartnersMutation.mutateAsync,
    deletePartnersLoading: deletePartnersMutation.isLoading,
    deletePartnersSuccess: deletePartnersMutation.isSuccess,
  };
};

export default useDeletePartners;
