import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchBids } from "../../api";
import { setBids, getBids } from "../../bidsSlice";

const useBids = (filters = {}, hookOptions = { disableQuery: false }) => {
  const { disableQuery = false } = hookOptions;

  const dispatch = useDispatch();
  const bids = useSelector(getBids);

  const { data, isLoading } = useQuery(["bids", filters], () => fetchBids(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setBids(data.getBids));
  }, [data, isLoading, dispatch]);

  return {
    data: bids,
    isLoading,
  };
};

export default useBids;
