import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectById } from "../../api";
import { setProjectDetail, selectProjectById } from "../../projectsSlice";

export const useProjectDetail = (id, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;
  const dispatch = useDispatch();
  const project = useSelector(selectProjectById(id));
  const { data, isLoading, refetch } = useQuery(["projects", id], () => fetchProjectById(id), {
    refetchOnWindowFocus: false,
    enabled: !!id && !disableQuery,
    staleTime: Infinity,
    refetchInterval: false,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(
      setProjectDetail({
        ...data?.getProjectByID,
        staff: data?.getProjectByID?.staff?.sort((a, b) => b.isOwner - a.isOwner),
        clients: data?.getProjectByID?.clients?.sort((a, b) => b.isPrimaryContact - a.isPrimaryContact),
      })
    );
  }, [data, isLoading]);

  return {
    data: project,
    isLoading,
    refetch,
  };
};

export default useProjectDetail;
