import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { noop } from "lodash";

import isMediaFile from "@src/utils/isMedia";
import { useGeneralActionsUpdater } from "@src/utils/providers/generalActions";
import { FILE_CONTENT_TYPES_LABEL, FILE_PATHS, FILE_TYPES } from "@src/features/files/utils";

const useUploadFilesOverlay = () => {
  const [show, setShow] = useState(false);
  const { addProjectToActionProps } = useGeneralActionsUpdater();

  const onOverlayEnter = useCallback(() => {
    setShow(true);
  }, [setShow]);

  const onOverlayExit = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const handleRejectedFiles = async (rejectedFiles, additionalData) => {
    addProjectToActionProps({
      isOpen: true,
      currentAction: "uploadFiles",
      triggeredBy: additionalData.contentType,
      hideProjectDetails: true,
      actionProps: {
        isOverlayDrag: true,
        onSuccess: noop,
        dragRejectedFiles: rejectedFiles,
      },
    });
    setShow(false);
  };

  const handleAcceptedFiles = async (files, additionalData = {}) => {
    if (!files.length) {
      return;
    }

    addProjectToActionProps({
      isOpen: true,
      currentAction: "uploadFiles",
      triggeredBy: additionalData.contentType,
      hideProjectDetails: true,
      actionProps: {
        item: additionalData.itemData,
        onSuccess: additionalData.onSuccess,
        processFiles: ["project", "files"].includes(additionalData.contentType)
          ? null
          : (filesList) => {
              return (filesList || []).map(({ file, sortOrder, isThumbnail = false, isProductVariant }) => {
                const fileKey = `${FILE_PATHS[additionalData.contentType] || additionalData.contentType}/${
                  additionalData.itemData?.isVariant ? additionalData.itemData?.productId : additionalData.itemData?.id
                }/${uuidv4()}`;
                return {
                  key: fileKey,
                  file,
                  sortOrder,
                  isThumbnail,
                  type: isMediaFile(file.type) ? FILE_TYPES.media : FILE_TYPES.document,
                  ...additionalData,
                  contentType: FILE_CONTENT_TYPES_LABEL[additionalData.contentType] || additionalData.contentType,
                };
              });
            },
        isOverlayDrag: true, // This prop is needed to start upload without modal
        dragFiles: files,
      },
    });
    setShow(false);
  };

  return {
    show,
    loading: false,
    loadingPercent: 0,
    onOverlayEnter,
    onOverlayExit,
    handleAcceptedFiles,
    handleRejectedFiles,
  };
};

export default useUploadFilesOverlay;
