import { TableCells, TableCards } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const columns = [
  {
    id: "id",
    Header: "Id",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "date",
    Header: "Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  {
    id: "project",
    Header: "Project",
    accessor: (originalRow) =>
      originalRow.project?.places?.addressLine1
        ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
        : null,
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original.project?.places?.city}
          addressState={originalRow.row.original.project?.places?.state}
          addressZip={originalRow.row.original.project?.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  {
    id: "threadId",
    Header: "Thread ID",
    accessor: "messagesThreadId",
    Cell: TableCells.TextCell,
  },
  {
    id: "message",
    Header: "Message",
    accessor: "message",
    Cell: TableCells.TextCell,
  },
  {
    id: "sender",
    Header: "Sender",
    accessor: (row) => row.sender.fullName,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          avatarSrc={originalRow.row.original.sender?.avatar || ""}
          initials={generateInitials(originalRow.value)}
          {...originalRow}
        />
      );
    },
  },
  {
    id: "recipient",
    Header: "Recipient",
    accessor: (row) => row.recipient.fullName,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          avatarSrc={originalRow.row.original?.recipient?.avatar || ""}
          initials={generateInitials(originalRow.value)}
          {...originalRow}
        />
      );
    },
  },
];

export const loadingPlaceholderMessage = {
  id: "",
  message: "",
  sender: {
    id: 1,
    fullName: "",
    avatar: "",
  },
  recipient: {
    id: "",
    fullName: "",
    avatar: "",
  },
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
  },
  createdAt: "",
  messagesThreadId: "",
};
