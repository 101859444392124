import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activities: [],
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    setActivities: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        activities: action.payload,
      };
    },
  },
});

export const { setActivities } = activitiesSlice.actions;

export default activitiesSlice.reducer;

export const getActivities = (state) => state.activities.activities;
