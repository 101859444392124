import React from "react";
import iFrameResize from "iframe-resizer";

const MetabaseDashboard = ({ dashboardUrl, title, containerClassName }) => {
  return (
    <div className={containerClassName || ""}>
      <iframe
        title={title}
        src={dashboardUrl}
        style={{ width: "100%" }}
        onLoad={() => iFrameResize.iframeResize({}, this)}
      />
    </div>
  );
};

export default MetabaseDashboard;
