import { createClient } from "@supabase/supabase-js";
import { Provider } from "react-supabase";

// client connected to your database
const client = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

export default client;

// React component to wrap your app
export function SupabaseProvider({ children }) {
  return <Provider value={client}>{children}</Provider>;
}
