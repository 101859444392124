import { TableCells, TableFilters, NavCard, TableCards } from "@buildappeal/react-component-library";
import { getAddressFromPlaces } from "@src/utils/common";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-500",
  Pending: "bg-orange-500",
  Unscheduled: "gray",
  Sent: "bg-green-500",
  Issued: "bg-green-500",
  TBD: "bg-primary-500",
};

export const invoiceColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      return (
        <NavCard
          isLoading={isLoading}
          title={`Invoice #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          descriptionClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      return (
        <NavCard
          isLoading={isLoading}
          layout="card"
          title={`Invoice #${original.id}`}
          description={getAddressFromPlaces(original.project?.places)}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  title: {
    id: "title",
    Header: "Title",
    accessor: "title",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  invoiceCode: {
    id: "invoiceCode",
    Header: "Invoice Code",
    accessor: "invoiceCode",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  invoiceTypes: {
    id: "invoiceTypes",
    Header: "Type",
    accessor: "invoiceTypes",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  invoiceDate: {
    id: "invoiceDate",
    Header: "Issued",
    accessor: "invoiceDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
    renderCard: TableCards.DateRow,
  },
  dueDate: {
    id: "dueDate",
    Header: "Due",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: false,
    renderCard: TableCards.DateRow,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  status: {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.StatusCell
          show
          status={originalRow.getValue()}
          circleColor={originalRow.getValue() ? STATUS_COLOR_CODES[originalRow.getValue()] || "bg-primary-500" : ""}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    renderCard: TableCards.TextRow,
  },
};

export const invoiceItemsColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: (originalRow) =>
      originalRow.invoice?.project?.places?.addressLine1
        ? `${originalRow.invoice?.project?.places?.addressLine1} ${
            originalRow.invoice?.project?.places?.addressLine2 || ""
          }`.trim()
        : null,
    Cell: (originalRow) => {
      return (
        <TableCells.AddressCell
          addressCity={originalRow.row.original?.invoice?.project?.places?.city}
          addressState={originalRow.row.original?.invoice?.project?.places?.state}
          addressZip={originalRow.row.original?.invoice?.project?.places?.zipCode}
          {...originalRow}
        />
      );
    },
  },
  invoice: {
    id: "invoiceId",
    Header: "Invoice ID",
    accessor: "invoice.id",
    Cell: TableCells.TextCell,
  },
  description: {
    id: "description",
    Header: "Description",
    accessor: "description",
    Cell: TableCells.TextCell,
  },
  quantity: {
    id: "quantity",
    Header: "Quantity",
    accessor: "quantity",
    Cell: TableCells.TextCell,
  },
  subTotal: {
    id: "subTotal",
    Header: "Subtotal",
    accessor: "subTotal",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
  total: {
    id: "total",
    Header: "Total",
    accessor: "total",
    Cell: TableCells.CurrencyCell,
    renderCard: TableCards.PriceRow,
  },
};
