import { NavCard, TableCells, TableCards } from "@buildappeal/react-component-library";
import { truncate } from "lodash";
import Skeleton from "react-loading-skeleton";
import { generateSenderInitials } from "./utils";

export const emailColumns = {
  subject: {
    id: "subject",
    Header: "Email",
    accessor: "subject",
    minWidth: "60%",
    Cell: ({ row, table, column }) => {
      const isLoading = !!table?.options?.meta?.isLoading;
      const isCollapsed = !!table?.options?.meta?.isCollapse;
      const { firstName, lastName, email } = row?.original?.sender || {};
      const name =
        firstName || lastName
          ? `${firstName || ""}  ${lastName || ""}`
          : row?.original?.fromName
          ? `${row.original.fromName} <${email || ""}>`
          : `${email || ""}`;
      return isLoading ? (
        <>
          <Skeleton width={80} />
          <Skeleton width={140} />
        </>
      ) : (
        <TableCells.EmailCell
          avatar={row?.original.sender?.avatar}
          senderName={`${name}${!!row.original?.thread?.length ? "  (" + row.original.thread.length + ")" : ""}`}
          initials={generateSenderInitials(row.original.sender)}
          subject={row.original.subject}
          content={truncate(row.original.plainContent, { length: 70 })}
          createdAt={row.original.createdAt}
          showTime={isCollapsed}
          isCollapsed={isCollapsed}
          showClientBadge={row.original.sender?.role === "client"}
          avatarRole={row.original.sender?.role}
          label={row.original.label?.name}
          labelColor={row.original.label?.color}
        />
      );
    },
  },
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      const isLoading = !!table?.options?.meta?.isLoading;

      if (!original?.project && !isLoading) {
        return "";
      }
      return (
        <NavCard
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city || ""}, ${original.project?.places?.state || ""} ${
            original.project?.places?.zipCode || ""
          }`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
          isLoading={isLoading}
        />
      );
    },
    renderCard: ({ row: { original }, column }) => {
      return (
        <NavCard
          layout="card"
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city || ""}, ${original.project?.places?.state || ""} ${
            original.project?.places?.zipCode || ""
          }`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    minWidth: "300px",
    maxWidth: "350px",
  },
  emailSender: {
    id: "emailSender",
    Header: "emailSender",
    accessor: "emailSender",
    Cell: TableCells.TextCell,
  },
  inReplyTo: {
    id: "inReplyTo",
    Header: "inReplyTo",
    accessor: "inReplyTo",
    Cell: TableCells.TextCell,
  },
  plainContent: {
    id: "plainContent",
    Header: "plainContent",
    accessor: "plainContent",
    Cell: TableCells.TextCell,
  },
  attachementInfo: {
    id: "attachementInfo",
    Header: "attachementInfo",
    accessor: "attachementInfo",
    Cell: TableCells.TextCell,
  },
  createdAt: {
    id: "created",
    Header: "Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
};
