import { useMemo } from "react";
import { useQuery } from "react-query";
import sortBy from "lodash/sortBy";

import { fetchFinishes } from "../../api";

const useFinishes = (filters = {}, hookOptions = {}) => {
  const { disableQuery = false } = hookOptions;

  const { data, isLoading } = useQuery(["finishes", filters], () => fetchFinishes(filters), {
    refetchOnWindowFocus: false,
    enabled: !disableQuery,
    refetchInterval: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const finishesAsOptions = useMemo(() => {
    if (!data?.getFinishes?.length) {
      return [];
    }
    return sortBy(
      data?.getFinishes.map((finish) => {
        return {
          value: finish.id,
          title: finish.name,
          finish,
          item: finish,
        };
      }),
      "title"
    );
  }, [data]);
  return {
    data: data?.getFinishes,
    isLoading,
    options: finishesAsOptions,
  };
};

export default useFinishes;
