import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import useToast from "@utils/hooks/useToast";
import { createConsultation } from "@features/consultations/api";

export const useAddConsultation = (hookOptions = {}) => {
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { isLoading, mutateAsync } = useMutation(
    (data) => {
      return createConsultation(data);
    },
    {
      onSuccess: (data) => {
        if (!data.createConsultation.success) {
          return;
        }
        setIsSuccess(true);
        queryClient.setQueryData(["consultations", {}], (old) => {
          if (!old) return { getConsultations: [data.createConsultation.data] };
          return { getConsultations: [data.createConsultation.data, ...old.getConsultations] };
        });

        addToast("Event scheduled successfully!");
        hookOptions?.onSuccess?.();
      },
      onError: () => {
        setError("Something went wrong");
      },
    }
  );

  return { createConsultationAsync: mutateAsync, isLoading, isSuccess, error };
};

export default useAddConsultation;
