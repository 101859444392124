import React, { useEffect, useState } from "react";
import { Form } from "@buildappeal/react-component-library";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";
import { omit } from "lodash";
import useToast from "@src/utils/hooks/useToast";
import useBATypes from "@src/utils/hooks/useBATypes";
import useProjects from "../projects/hooks/useProjects";
import invoiceFormSchema from "./formSchema";
import invoiceUiSchema from "./uiSchema";
import useAddInvoice from "./hooks/useAddInvoice";
// Use Form component to display form
// Update formSchema after all dynamic options are fetched
// https://storybook-buildappeal.s3.amazonaws.com/main/index.html?path=/story/mdx-form--basic For details
const AddInvoice = ({ onClose, projectId }) => {
  const { options: projectsAddressAsOptions, isLoading: projectOptionLoading } = useProjects();

  const { addInvoiceAsync, isSuccess: addInvoiceSuccess, error } = useAddInvoice(); // Always use hooks for CRUD ops, create them if necessary
  const { addToast } = useToast();

  const { options: invoiceTypesAsOption, isLoading: invoiceTypesLoading } = useBATypes("invoiceTypes");
  const [formSchema, setFormSchema] = useState(null);

  useEffect(() => {
    if (addInvoiceSuccess) {
      addToast("Invoice Successfully Created");
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      onClose();
    }
  }, [addInvoiceSuccess]);

  const handleSubmit = async (formState) => {
    const data = {
      ...omit(formState, ["project", "document", "invoiceItems", "createdAt"]),
    };

    if (formState.xeroInvoiceID) {
      data.xeroInvoiceID = parseInt(formState.xeroInvoiceID);
    }
    if (projectId) {
      data.projectId = projectId;
    } else if (formState.project) {
      data.projectId = formState.project.id || formState?.project?.project?.id;
    }
    if (formState.document) {
      data.documentId = formState.document.id;
      delete data.document;
    }
    if (formState.total) {
      data.total = parseFloat(formState.total);
    }
    if (formState?.invoiceTypes) {
      data.invoiceTypes = formState.invoiceTypes?.value;
    }

    setFormSchema((prev) => ({
      ...prev,
      actionsState: {
        isLoading: true,
      },
    }));
    const res = await addInvoiceAsync(data);
    if (res.createInvoice?.success) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
        },
      }));
      addToast("Invoice Successfully Created");
      onClose();
    }
  };

  useEffect(() => {
    if (error) {
      setFormSchema((prev) => ({
        ...prev,
        actionsState: {
          isLoading: false,
          isError: !!error,
          errorMsg: error,
        },
      }));
    }
  }, [error]);

  useEffect(() => {
    if (projectOptionLoading || invoiceTypesLoading) {
      return;
    }
    const finalSchema = {
      ...invoiceFormSchema,
    };
    const allProperties = invoiceFormSchema.properties;
    const finalProperties = Object.keys(finalSchema.properties).reduce((acc, key) => {
      if (allProperties[key].type !== "internal") {
        acc[key] = { ...allProperties[key] };
        if (key === "project") {
          acc[key].options = projectsAddressAsOptions;
        }
        if (key === "invoiceTypes") {
          acc[key].options = invoiceTypesAsOption;
        }
      }
      return acc;
    }, {});

    finalSchema.properties = finalProperties;
    finalSchema.actions = {
      onSubmit: (formState) => handleSubmit(formState),
    };
    setFormSchema(finalSchema);
  }, [projectsAddressAsOptions, invoiceTypesAsOption, projectOptionLoading, invoiceTypesLoading]);

  if (projectOptionLoading) {
    return (
      <div className="flex h-[calc(100vh_-_4rem)] w-full items-center justify-center">
        <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" />
      </div>
    );
  }

  if (!formSchema) return null;

  return (
    <>
      <Form formSchema={formSchema} uiSchema={invoiceUiSchema} isInSidebar isCreate />
    </>
  );
};

export default AddInvoice;
