import { productColumns } from "@src/features/products/tableColumnsMap";
import { supplierColumns } from "@src/features/suppliers/tableColumnsMap";
import { consultationsColumns } from "@src/features/consultations/tableColumnsMap";
import { emailColumns } from "@src/features/emails/tableColumnsMap";
import { projectColumns } from "@features/projects/tableColumnsMap";
import { invoiceColumns } from "@features/invoices/tableColumnsMap";
import { contractColumns } from "@src/features/contracts/tableColumnsMap";
import { messagesColumns } from "@src/features/messages/tableColumnsMap";
import { activitiesColumns } from "@src/features/activities/tableColumnsMap";
import { servicesColumns } from "@src/features/services/tableColumnsMap";
import { designStylesColumns } from "@src/features/designs/tableColumnsMap";
import { storyboardsColumns } from "@src/features/storyboards/tableColumnsMap";
import { tasksColumns } from "@src/features/tasks/tableColumnsMap";

import { bidColumns } from "@src/features/bids/tableColumnsMap";
import { billColumns } from "@src/features/bills/tableColumnsMap";
import { budgetColumns } from "@src/features/budgets/tableColumnsMap";
import { changeOrderColumns } from "@src/features/changeOrders/tableColumnsMap";
import { proposalColumns } from "@src/features/proposals/tableColumnsMap";
import { purchaseOrderColumns } from "@src/features/purchaseOrders/tableColumnsMap";
import { purchaseColumns } from "@src/features/purchases/tableColumnsMap";
import { staffsColumns } from "@src/features/staff/tableColumnsMap";
import { marketsColumns } from "@src/features/markets/tableColumnsMap";
import { commonActionsColumns } from "@src/features/actionsObjects/tableColumnsMap";
import { clientsColumns } from "@src/features/clients/tableColumnsMap";
import { partnersColumns } from "@src/features/partners/tableColumnsMap";
import { contactsColumns } from "@src/features/contacts/tableColumnsMap";
import { noteColumns } from "@src/features/notes/tableColumnsMap";
import { peopleColumns } from "@src/features/people/tableColumnsMap";
import { estimatesColumns } from "@src/features/estimates/tableColumnsMap";
import { materialColumns } from "@src/features/materials/tableColumnsMap";
import { finishColumns } from "@src/features/finishes/tableColumnsMap";
import { objectColumns } from "@src/features/objects/tableColumnsMap";
import { looksColumns } from "@src/features/looks/tableColumnsMap";

const columnsMap = {
  "/products": productColumns,
  "/suppliers": supplierColumns,
  "/consultations": consultationsColumns,
  "/emails": emailColumns,
  "/projects": projectColumns,
  "/contracts": contractColumns,
  "/messages": messagesColumns,
  "/activities": activitiesColumns,
  "/financials/invoices": invoiceColumns,
  "/financials/bids": bidColumns,
  "/financials/bills": billColumns,
  "/financials/budgets": budgetColumns,
  "/financials/change-orders": changeOrderColumns,
  "/financials/proposals": proposalColumns,
  "/financials/purchase-orders": purchaseOrderColumns,
  "/financials/purchases": purchaseColumns,
  "/financials/estimates": estimatesColumns,
  "/services": servicesColumns,
  "/designs/styles": designStylesColumns,
  "/designs/storyboards": storyboardsColumns,
  "/tasks": tasksColumns,
  "/staff": staffsColumns,
  "/markets": marketsColumns,
  "/leads": projectColumns,
  "/tasks/actions": commonActionsColumns,
  "/clients": clientsColumns,
  "/partners": partnersColumns,
  "/contacts": contactsColumns,
  "/notes": noteColumns,
  "/people": peopleColumns,
  "/materials": materialColumns,
  "/finishes": finishColumns,
  "/objects": objectColumns,
  "/looks": looksColumns,
};

export default columnsMap;
