import React, { useMemo, useRef, useEffect, useState } from "react";
import orderBy from "lodash/orderBy";
import { useNavigate } from "react-router-dom";

import generateInitials from "@src/utils/generateInitials";
import { getProjectLink } from "@src/utils/common";
import useToast from "@src/utils/hooks/useToast";
import useBATypes from "@src/utils/hooks/useBATypes";
import useFinishes from "@src/features/finishes/hooks/useFinishes";
import useProjects from "@features/projects/hooks/useProjects";
import useUpdateFile from "@src/features/files/hooks/useUpdateFile";
import useGroupedProjectSpaceObjects from "@features/projectSpaceObjects/hooks/useGroupedProjectSpaceObjects";
import useSpaces from "@features/spaces/hooks/useSpaces";
import useDesignStyles from "@src/features/designs/hooks/useDesignStyles";
import useProductTypes from "@src/features/products/hooks/useProductTypes";
import FileInfo from "../components/FileInfo";
import { getFileType } from "../utils";

const FileDetail = ({
  selectedFile,
  onRename,
  onEditAltText,
  onDownload,
  onDelete,
  setShowGallery,
  setShowCoordinatesEdit,
  project,
  imageVisible,
  setIsFileDetailLoading,
  refetchFileDetail,
  isFileDetailLoading,
  onProjectViewFiles,
}) => {
  const navigate = useNavigate();

  const fileInfoRef = useRef(null);
  const { updateFileAsync, updateFileLoading } = useUpdateFile({ skipQueryUpdate: true });
  const { addToast } = useToast();
  const { baTypes: contentTypes } = useBATypes("contentTypes");
  const { options: tagsAsOptions } = useBATypes("moodTags");
  const { data: projects, options: projectsAddressAsOptions } = useProjects();
  const { options: spacesAsOptions } = useSpaces();
  const { options: designStylesAsOptions } = useDesignStyles();
  const { options: finishesAsOptions } = useFinishes({});
  const { options: productTypeOptions } = useProductTypes();

  const [fileInfo, setFileInfo] = useState(null);

  const { isPdf, isVideo } = getFileType(selectedFile?.fileType);

  const productTypeOptionsFilter = useMemo(() => {
    return productTypeOptions?.filter((option) => option.productType.objectType === "Product") || [];
  }, [productTypeOptions]);

  useEffect(() => {
    if (!selectedFile && isFileDetailLoading) {
      setIsFileDetailLoading?.(true);
      const res = { ...selectedFile };
      Object.keys(res).forEach((key) => {
        if (res[key] === "EMPTY") {
          delete res[key];
        }
      });
      res.createdBy = {
        fullName: res?.addedBy,
      };
      setFileInfo(res);
      return;
    }
    if (!selectedFile) {
      return;
    }
    setFileInfo({
      ...selectedFile,
    });
  }, [selectedFile, isFileDetailLoading]);

  const onClickProjectLink = () => {
    navigate(`/projects/${fileInfo?.project?.id}`);
  };

  const updateFileRequest = async (data) => {
    const resp = await updateFileAsync(data);
    if (!resp?.updateFileInfo.success) {
      addToast("Error updating file, try again!");
      return;
    }
    refetchFileDetail?.();
    addToast("File info successfully updated");
  };

  const updateFileInfo = async (formState) => {
    try {
      const data = {
        id: selectedFile.id,
        finishes: formState?.draftFinishes?.map((item) => item.value),
        tags: formState?.fileTags?.map((tag) => ({ tagName: tag.value })),
        description: formState?.fileDescription,
        spacesId: formState?.draftSpaces?.length
          ? project
            ? formState?.draftSpaces?.map((projectSpace) => projectSpace.space?.spaceId)
            : formState?.draftSpaces?.map((space) => space.value)
          : [],
        projectSpacesId: formState?.draftSpaces?.length
          ? project
            ? formState?.draftSpaces?.map((projectSpace) => projectSpace.space?.id)
            : undefined
          : undefined,
        contentType: formState?.draftContentType,
        isFeatured: formState?.draftImageFeatured,
        designStylesId: formState?.draftDesignStyles?.length
          ? formState?.draftDesignStyles?.map((designStyle) => designStyle.value)
          : [],
        objects: formState?.draftProductTypes?.length
          ? formState?.draftProductTypes?.map((productType) => productType.value)
          : [],
      };
      return await updateFileRequest(data);
    } catch (error) {
      addToast("There was an error trying to update file");
    }
  };

  const projectSpacesAsOptions = useMemo(() => {
    if (project) {
      const spacesSorted = orderBy(project?.spaces, "sortOrder");
      return spacesSorted?.map((space) => ({
        value: space.id,
        title: space.spaceNickname || space.name,
        space,
      }));
    } else {
      return projects
        .find((project) => project.id === fileInfo?.project?.id)
        ?.spaces?.map((space) => {
          return {
            value: space.id,
            title: space.spaceNickname || space.name,
            space,
          };
        });
    }
  }, [project, fileInfo?.project?.id, projects]);

  const { options: projectSpaceObjectsAsOptions } = useGroupedProjectSpaceObjects({
    projectSpaceIds: projectSpacesAsOptions ? projectSpacesAsOptions.map((projectSpace) => projectSpace.space.id) : [],
  });

  const onFileNameUpdate = async (newName) => {
    try {
      return await updateFileRequest({
        id: selectedFile.id,
        name: newName,
      });
    } catch (error) {
      addToast("File name update failed");
    }
  };

  const commonProps = {
    onSaveFileInfo: updateFileInfo,
    onRename: () => onRename(fileInfo),
    onEditAltText: () => onEditAltText(fileInfo),
    onDownload: () => onDownload(fileInfo),
    onDelete: () => onDelete(fileInfo),
    isSaving: updateFileLoading,
    link: fileInfo?.url,
    by: {
      name: fileInfo?.createdBy?.fullName,
      avatar: fileInfo?.createdBy?.avatar,
      initials: generateInitials(fileInfo?.createdBy?.fullName),
    },
    spaceOptions: project ? projectSpacesAsOptions : spacesAsOptions,
    designStyleOptions: designStylesAsOptions,
    contentTypeOptions: contentTypes,
    tagOptions: tagsAsOptions,
    productTypeOptions: productTypeOptionsFilter,
    finishOptions: finishesAsOptions,
    src: isVideo ? fileInfo?.path?.split(".")?.[0] : isPdf ? selectedFile?.url : fileInfo?.path,
    contentType: fileInfo?.contentType,
    fileName: fileInfo?.name || "",
    isFeatured: fileInfo?.isFeatured,
    created: new Date(fileInfo?.uploadedAt),
    isProjectFilePage: !!project,
    onClickProjectLink,
    tags: fileInfo?.tags?.map((tag) => tag.tagName) || [],
    size: undefined,
    projectLink: getProjectLink(fileInfo?.project),
    fileId: fileInfo?.id || "",
  };

  useEffect(() => {
    if (!fileInfoRef.current) return;
    const header = fileInfoRef.current.firstChild;
    if (header) {
      header.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedFile]);

  return (
    <div className="relative mx-auto h-full max-w-[1760px]">
      <FileInfo
        {...fileInfo}
        {...commonProps}
        imageVisible={imageVisible}
        key={fileInfo && fileInfo.id}
        showGallery={setShowGallery}
        projectOptions={projectsAddressAsOptions}
        isShowGalleryOnContainerClick={fileInfo?.fileType?.includes("video") ? false : true}
        videoProps={{ width: 449, height: 302 }}
        productTypes={fileInfo?.objects}
        spaces={project ? fileInfo?.projectSpaces : fileInfo?.spaces}
        onCoordinatesEdit={() => setShowCoordinatesEdit(true)}
        showCoordinatesSection={!!projectSpaceObjectsAsOptions.length}
        onTitleChange={onFileNameUpdate}
        mRef={fileInfoRef}
        loadingData={isFileDetailLoading}
        onProjectViewFiles={onProjectViewFiles}
        imgTransformations={{
          width: 450,
          format: "jpg",
          placeholder: "blur",
        }}
      />
    </div>
  );
};

export default FileDetail;
