import { PLACE_STORIES } from "@src/utils/constants";
import { ProjectTypes } from "./view/utils";
import { AccessDifficultyOptions } from "./utils";

const formSchema = {
  title: "Create Project",
  properties: {
    address: {
      type: "address",
      required: true,
    },
    type: {
      type: "select",
      label: "Type",
      options: ProjectTypes.map((opt) => ({
        title: opt,
        value: opt,
      })),
    },
    floors: {
      type: "selectChip",
      label: "Number of Floors",
      description: "How many floors does this home have?",
      appearance: "black",
      options: PLACE_STORIES.map((opt) => ({
        title: opt,
        value: opt,
      })),
    },
    accessDifficulty: {
      type: "select",
      label: "Access Difficulty",
      options: AccessDifficultyOptions.map((opt) => ({
        title: opt,
        value: opt,
      })),
    },
    lockboxCode: {
      type: "string",
      label: "Lockbox Code",
    },
    contact: {
      type: "checkbox",
      isConditional: true,
      formCondition: {
        true: {
          linkedContact: {
            type: "people",
            label: "Select Contact",
            required: true,
          },
        },
        false: {
          contactName: {
            type: "person",
            label: "Contact First Name",
            required: true,
          },
          email: {
            type: "email",
            label: "Contact Email",
            required: true,
          },
          phone: {
            type: "phone",
            label: "Contact Phone",
            defaultCountry: "US",
            maxLength: 14,
          },
        },
      },
      label: "Already has a Contact",
    },
    staff: {
      type: "people",
      label: "Project Owner",
    },
  },
};

export default formSchema;
