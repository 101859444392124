import { gql } from "graphql-request";
import graphQLClient from "@src/utils/graphQLClient";

const GET_UI_PAGES = gql`
  query getUIPages {
    getUIPages {
      id
      title
      description
      path
      uiCentral {
        id
        tabs
        actions
        hasDelete
        uiTable {
          id
          fields
          filters
          layout
          rowMenuActions
        }
        productsTable {
          id
          fields
          filters
          layout
          rowMenuActions
        }
      }
      uiLeftColumn {
        id
        rowMenuActions
        hasRightClick
        hasCreate
        uiTable {
          id
          filters
          fields
          cardFields
          cardActions
          layout
          rowMenuActions
          showAllStages
        }
      }
    }
  }
`;

export const fetchAllUIPages = async () => {
  return await graphQLClient.request(GET_UI_PAGES);
};
