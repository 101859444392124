import { useState } from "react";
import { useMutation } from "react-query";
import { deleteProjectSpace } from "../api";

const useDeleteProjectSpace = (projectId) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading, mutateAsync } = useMutation(
    (data) => {
      setError("");
      return deleteProjectSpace(data);
    },
    {
      onSuccess: (data) => {
        if (!data.deleteProjectSpace.success) {
          setError(`Error: ${data.deleteProjectSpace.message}`);
          return;
        }
        setIsSuccess(true);
      },

      onError: () => {
        setError(`Error: ${error.message}`);
      },
    }
  );

  return {
    deleteProjectSpace: mutate,
    deleteProjectSpaceAsync: mutateAsync,
    isLoading,
    isSuccess,
    error,
  };
};

export default useDeleteProjectSpace;
