import { NavCard, TableCells, TableCards } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const messagesColumns = {
  id: {
    id: "id",
    Header: "Id",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  createdAt: {
    id: "date",
    Header: "Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  project: {
    id: "project",
    Header: "Project",
    accessor: ({ places }) => `${places?.addressLine1} ${places?.city} ${places?.state} ${places?.zipCode}`,
    Cell: ({ row: { original }, column, table }) => {
      return (
        <NavCard
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city ? original.project?.places?.city + "," : ""} ${
            original.project?.places?.state || ""
          } ${original.project?.places?.zipCode || ""}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          image={original.project?.thumbnail?.path}
          titleClassName="line-clamp-2"
          showPlaceholder
        />
      );
    },
    renderCard: ({ row: { original }, column }) => {
      return (
        <NavCard
          layout="card"
          title={original.project?.places?.addressLine1}
          description={`${original.project?.places?.city ? original.project?.places?.city + "," : ""} ${
            original.project?.places?.state || ""
          } ${original.project?.places?.zipCode || ""}`}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          image={original.project?.thumbnail?.path}
          showPlaceholder
        />
      );
    },
    maxWidth: "3fr",
  },
  messagesThreadId: {
    id: "threadId",
    Header: "Thread ID",
    accessor: "messagesThreadId",
    Cell: TableCells.TextCell,
  },
  messages: {
    id: "message",
    Header: "Message",
    accessor: "message",
    Cell: TableCells.TextCell,
  },
  sender: {
    id: "sender",
    Header: "Sender",
    accessor: (row) => row.sender.fullName,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={originalRow.value}
          avatarSrc={originalRow.row.original.sender?.avatar || ""}
          initials={generateInitials(originalRow.value)}
          row={originalRow.row}
          column={originalRow.column}
          {...originalRow}
        />
      );
    },
  },
  recipient: {
    id: "recipient",
    Header: "Recipient",
    accessor: (row) => row.recipient.fullName,
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          value={originalRow.value}
          avatarSrc={originalRow.row.original?.recipient?.avatar || ""}
          initials={generateInitials(originalRow.value)}
          row={originalRow.row}
          column={originalRow.column}
          {...originalRow}
        />
      );
    },
  },
};
