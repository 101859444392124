import { original } from "immer";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  purchases: [],
  purchaseItems: [],
};

export const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    setPurchases: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        purchases: action.payload,
      };
    },
    setPurchaseItems: (state, action) => {
      const originalState = original(state);
      return {
        ...originalState,
        purchaseItems: action.payload,
      };
    },
  },
});

export const { setPurchases, setPurchaseItems } = purchasesSlice.actions;

export default purchasesSlice.reducer;

export const getPurchases = (state) => state.purchases?.purchases;
export const getPurchaseItems = (state) => state.purchases?.purchaseItems;
