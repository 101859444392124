import CommonSendEmail from "../people/CommonSendEmail";
import AddContact from "./AddContact";
import EditContact from "./EditContact";

export const contactsActions = {
  add: {
    id: "add",
    label: "Create Contact",
    sidebar: AddContact,
  },
  edit: {
    id: "edit",
    label: "Edit product",
    modal: EditContact,
  },
  delete: {
    id: "delete",
    label: "Delete contact",
    modal: () => <div className="p-10">Delete contact form</div>,
  },
  send: {
    id: "send",
    label: "Send Message",
    component: CommonSendEmail,
    container: "collapsedExpand",
  },
};
