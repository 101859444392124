import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useToast from "@src/utils/hooks/useToast";
import { updatePartner } from "../../api";

const useUpdatePartner = () => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const { addToast } = useToast();

  const { mutate, isLoading, mutateAsync } = useMutation(
    (input) => {
      return updatePartner(input);
    },
    {
      onSuccess: (data) => {
        if (!data.updatePartner.success) {
          addToast("Partner failed to update.");
          setError("Failed to create partner");
        } else {
          setIsSuccess(true);
          queryClient.setQueryData("partners", (old) => {
            if (!old) return { getPartners: [data.updatePartner.data] };
            return {
              getPartners: old.getPartners
                .map((partner) => {
                  if (partner.id === data.updatePartner.data.id) {
                    return {
                      ...partner,
                      ...data.updatePartner.data,
                    };
                  }
                  return partner;
                })
                .filter((partner) => partner.isActive),
            };
          });
          queryClient.setQueryData(["partners", data.updatePartner.data.id], (old) => {
            if (!old) return { getPartner: [data.updatePartner.data] };
            return {
              getPartnerByID: {
                ...old,
                ...data.updatePartner.data,
              },
            };
          });
          addToast("Partner successfully updated.");
        }
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  return {
    updatePartner: mutate,
    isLoading,
    isSuccess,
    error,
    updatePartnerAsync: mutateAsync,
  };
};

export default useUpdatePartner;
