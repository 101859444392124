import { TableCells, TableFilters, TableCards } from "@buildappeal/react-component-library";
import { groupBy, orderBy } from "lodash";
import { getAddressFromPlaces, isAlgoliaAttributeValueEmpty, titleCase } from "@src/utils/common";
import generateInitials from "@src/utils/generateInitials";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Scheduled: "orange",
  Unscheduled: "gray",
  Completed: "green",
  Cancelled: "red",
};

export const commonConsultationsColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "client",
    Header: "Client",
    accessor: "clients",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          name={originalRow.getValue()?.[0]?.fullName}
          avatarSrc={originalRow.getValue()?.[0]?.avatar}
          initials={generateInitials(originalRow.getValue()?.[0]?.fullName || originalRow.getValue()?.[0]?.email)}
          {...originalRow}
        />
      );
    },
  },
  {
    id: "staff",
    Header: "Staff",
    accessor: (row) => {
      return row?.staff?.[0]?.fullName;
    },
    Cell: (originalRow) => {
      const { row, column } = originalRow;
      return row?.original?.staff?.map((staff, index) => {
        return (
          <TableCells.UserCell
            key={`${staff.id}-${staff.fullName}-${index}`}
            name={staff.fullName}
            column={column}
            row={row}
            avatarSrc={staff?.avatar}
            initials={generateInitials(staff?.fullName || staff?.email)}
            getValue={() => staff.fullName}
          />
        );
      });
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
    className: "whitespace-nowrap w-[10%]",
  },
  {
    id: "calendlyLink",
    Header: "Calendly Link",
    accessor: "calendlyLink",
    Cell: TableCells.UrlCell,
  },
  {
    id: "consultationDate",
    Header: "Date",
    accessor: "consultationDate",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  {
    id: "createdAt",
    Header: "Created At",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
    showTimeAccessor: true,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
];

export const columns = [...commonConsultationsColumns];
columns.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.project?.places?.addressLine1
      ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original.project?.places?.city}
        addressState={originalRow.row.original.project?.places?.state}
        addressZip={originalRow.row.original.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const CONSULTATION_STATUSES = {
  UNSCHEDULED: "Unscheduled",
  SCHEDULED: "Scheduled",
  CANCELLED: "Cancelled",
  COMPLETED: "Completed",
};

export function isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf("calendly") === 0;
}

export const loadingPlaceholderConsultation = {
  id: "",
  createdAt: "",
  consultationDate: "",
  calendlyLink: "",
  status: "",
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
  },
  staff: [
    {
      id: "",
      avatar: null,
      fullName: "",
      email: "",
    },
  ],
  client: {
    id: "",
    avatar: null,
    fullName: "",
    email: "",
  },
};

export const transformConsultationBoardViewData = (data) => {
  const groupedConsultations = groupBy(data, "status");
  return orderBy(
    Object.keys(groupedConsultations).map((key) => ({
      title: key,
      className: "dark:bg-neutral-700",
      items: orderBy(
        groupedConsultations[key].map((consultation) => ({
          ...consultation,
          title: consultation.clients?.[0]?.fullName,
          subtitle: getAddressFromPlaces(consultation?.project?.places),
          image: consultation?.project?.thumbnail?.path,
        })),
        "id",
        "desc"
      ),
    })),
    "items.[0].status",
    "desc"
  );
};

const STATUS_BG_COLOR_CODES = {
  "Awaiting Signature": "bg-orange-700",
  Scheduled: "bg-orange-700",
  Unscheduled: "bg-gray-700",
  Completed: "bg-green-700",
  Cancelled: "bg-red-700",
};
export const getStatusBgColor = (status) => STATUS_BG_COLOR_CODES[status];

export const CONSULTATION_ACTION_MODES = {
  RESCHEDULE: "reschedule",
  CANCEL: "cancel",
};

export const initCalendly = ({ schedulePageUrl, calendlyParams, client }) => {
  window?.Calendly.initPopupWidget({
    url: schedulePageUrl + calendlyParams,
    prefill: {
      email: client?.email,
      firstName: client?.firstName,
      lastName: client?.lastName,
      name: client?.fullName
        ? client?.fullName
        : `${client?.client?.firstName || ""} ${client?.client?.lastName || ""}`,
    },
  });
};

export const getCalendlyParams = (guests, client) => {
  const guestsText = guests
    .map((guest) => {
      if (guest.type === "staff" || guest?.id !== client?.id) {
        return guest.client?.email || guest.staff?.email || guest.email;
      }
      return null;
    })
    .filter(Boolean)
    .join(",");

  const calendlyParams = `?guests=${guestsText}`;

  return calendlyParams;
};

export const transformConsultationCurrentRefinementItems = (items) => {
  const refinementAttributeToLabelMap = {
    status: "Status",
    "staff.fullName": "Owner",
  };

  return items.map((item) => ({
    ...item,
    refinements: item.refinements.map((refinement) => ({
      ...refinement,
      label: isAlgoliaAttributeValueEmpty(refinement.label)
        ? `No ${refinementAttributeToLabelMap[item.attribute]}`
        : titleCase(refinement.label),
    })),
  }));
};
