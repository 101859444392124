import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectStages } from "../../api";
import { getProjectStages, setProjectStages } from "../../actionsObjectsSlice";

const useProjectStages = () => {
  const dispatch = useDispatch();
  const projectStages = useSelector(getProjectStages);

  const { data, isLoading } = useQuery("projectStages", () => fetchProjectStages({}), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setProjectStages(data.getProjectStages));
  }, [data, isLoading]);

  const projectStagesAsOptions = useMemo(() => {
    if (!projectStages?.length) {
      return [];
    }
    return projectStages
      .map((stage) => {
        return {
          value: stage.id,
          title: stage.name,
          stage,
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [projectStages]);

  return {
    data: projectStages,
    isLoading,
    options: projectStagesAsOptions,
  };
};

export default useProjectStages;
