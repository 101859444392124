import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { fetchObjects } from "../../api";
import { getObjects, getObjectsById, setObjects } from "../../actionsObjectsSlice";

const useObjects = () => {
  const dispatch = useDispatch();
  const objects = useSelector(getObjects);
  const objectsById = useSelector(getObjectsById);

  const { data, isLoading } = useQuery("objects", () => fetchObjects({}), {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    dispatch(setObjects(data.getObjects));
  }, [data, isLoading]);

  const objectsAsOptions = useMemo(() => {
    if (!objects?.length) {
      return [];
    }
    return objects
      .map((object) => {
        return {
          value: object.id,
          title: object.description,
          object,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [objects]);

  return {
    data: objects,
    dataById: objectsById,
    isLoading,
    options: objectsAsOptions,
  };
};

export default useObjects;
