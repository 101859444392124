import { BidStatusOptions } from "./utils";

const formSchema = {
  title: "Create Bid",
  properties: {
    project: {
      type: "project",
      label: "Project",
      required: true,
    },
    partner: {
      type: "people",
      label: "Partner",
      required: true,
    },
    status: {
      type: "select",
      label: "Status",
      options: BidStatusOptions.map((status) => ({ title: status, value: status })),
    },
    dueDate: {
      type: "datetime",
      label: "Due Date",
    },
    willBid: {
      type: "checkbox",
      label: "Will Bid",
    },
  },
};

export default formSchema;
