import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { UilPen as EditIcon } from "@iconscout/react-unicons";

import { Counter, Toggle, IconButton, Tooltip } from "@buildappeal/react-component-library";
import { ApplyProductType } from "./utils";

const ApplyProductItem = ({
  title,
  description,
  cost,
  productName,
  isSelected,
  type,
  quantity,
  onChange,
  onEdit,
  isAssigned,
  mRef,
}) => {
  return (
    <div
      data-testid="ApplyProductItem"
      ref={mRef}
      className={clsx(
        "flex items-center justify-between rounded border border-neutral-100 p-3",
        isSelected ? "bg-neutral-100" : "bg-white"
      )}
    >
      <div className="flex flex-1 flex-row justify-between">
        <div className="flex flex-col">
          <span className="text-xs">{title}</span>
          {description && <span className="text-xs text-neutral-500">{description}</span>}
        </div>
        {cost ? <div className="text-xs text-neutral-500">{cost}</div> : null}
      </div>
      {type === ApplyProductType.SqFt && (
        <div className="flex items-center gap-x-4">
          <div className="flex items-center gap-x-2">
            {isAssigned && (
              <Tooltip message={productName}>
                <div className="h-[18px] w-[18px] rounded-full border-4 border-white bg-green-700" />
              </Tooltip>
            )}
            {onEdit && <IconButton icon={EditIcon} onClick={onEdit} />}
          </div>
          <Toggle size="sm" checked={isSelected} onChange={onChange} />
        </div>
      )}
      {type === ApplyProductType.Picker && (
        <div>
          <Counter onChange={onChange} max={100} size="md" className="!p-0" value={quantity} />
        </div>
      )}
    </div>
  );
};

ApplyProductItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  productName: PropTypes.string,
  isSelected: PropTypes.bool,
  isAssigned: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(ApplyProductType)),
  quantity: PropTypes.number,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  mRef: PropTypes.shape({ current: PropTypes.any }),
};

export default ApplyProductItem;
