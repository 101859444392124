import React from "react";

import { history } from "instantsearch.js/es/lib/routers";
import { InstantSearch } from "react-instantsearch-hooks-web";
import { algoliaSearchClient } from "../algolia";
import "@algolia/autocomplete-theme-classic";

const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

const getRoutingConfig = (indexName) => ({
  router: history({
    createURL({ qsModule, routeState, location }) {
      const queryParameters = {};
      const baseUrl = location.href.split("?")[0];

      Object.keys(routeState).forEach((key) => {
        if (key === "q") {
          queryParameters.q = encodeURIComponent(routeState.q);
        }
        if (Array.isArray(routeState[key])) {
          queryParameters[key] = routeState[key].map(encodeURIComponent);
        }
      });

      const queryString = qsModule.stringify(queryParameters, {
        addQueryPrefix: true,
        arrayFormat: "repeat",
      });

      return `${baseUrl}${queryString}`;
    },
    parseURL({ qsModule, location }) {
      const params = qsModule.parse(location.search.slice(1));

      const urlParams = {};
      Object.keys(params).forEach((key) => {
        if (key === "q") {
          urlParams.q = decodeURIComponent(params[key]);
        } else {
          const value = Array.isArray(params[key]) ? params[key] : [params[key]].filter(Boolean);
          urlParams[key] = value.map(decodeURIComponent);
        }
      });
      return urlParams;
    },
  }),
  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState[indexName];
      const routeParams = {};
      if (indexUiState.query) {
        routeParams.q = indexUiState.query;
      }
      if (indexUiState.refinementList) {
        Object.keys(indexUiState.refinementList).forEach((key) => {
          routeParams[key] = indexUiState.refinementList[key];
        });
      }
      return routeParams;
    },
    routeToState(routeState) {
      const stateParams = {};
      Object.keys(routeState).forEach((key) => {
        if (key === "q") {
          stateParams.query = routeState[key];
        }

        if (Array.isArray(routeState[key])) {
          if (!stateParams.refinementList) {
            stateParams.refinementList = {};
          }
          stateParams.refinementList[key] = routeState[key];
        }
      });
      return {
        [indexName]: stateParams,
      };
    },
  },
});

const AlgoliaWrapper = (Component, { indexName, enableRouting = true } = {}) => {
  if (!indexName) {
    throw new Error("index name is required");
  }

  const routingConfig = getRoutingConfig(indexName);

  const WrappedComponent = (props) => {
    return (
      <InstantSearch
        indexName={indexName}
        searchClient={algoliaSearchClient}
        routing={enableRouting ? routingConfig : false}
      >
        <Component {...props} />
      </InstantSearch>
    );
  };
  WrappedComponent.displayName = getDisplayName(Component);
  return WrappedComponent;
};

export default AlgoliaWrapper;
