import { TableCells, TableFilters } from "@buildappeal/react-component-library";
import { STATUS_COLOR_CODES } from "../partners/utils";

export const columns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: TableCells.TextCell,
  },
  {
    id: "parent",
    Header: "Parent",
    accessor: (row) => row.parent?.name,
    Cell: TableCells.TextCell,
  },
];

export const servicePartnersColumn = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "partner",
    Header: "Partner",
    accessor: "companyName",
    Cell: (originalRow) => {
      const { original } = originalRow.row;
      return (
        <div className="flex">
          <TableCells.TitleCell
            getValue={() => original?.companyName}
            subtitle={original?.fullName}
            row={originalRow.row}
            column={originalRow.column}
            {...originalRow}
          />
        </div>
      );
    },
  },
  {
    id: "email",
    Header: "Company Email",
    accessor: "email",
    Cell: TableCells.TextCell,
  },
  {
    id: "officePhone",
    Header: "Company Phone",
    accessor: "officePhone",
    Cell: TableCells.PhoneNumberCell,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return (
        <TableCells.BadgeCell
          color={STATUS_COLOR_CODES[originalRow.value] || "gray"}
          value={originalRow.value}
          {...originalRow}
        />
      );
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
];

export const serviceMarketsColumn = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "market",
    Header: "Market",
    accessor: "name",
    Cell: (originalRow) => {
      const { original } = originalRow.row;
      return (
        <div className="flex">
          <TableCells.TitleCell
            getValue={() => original?.name}
            row={originalRow.row}
            column={originalRow.column}
            {...originalRow}
          />
        </div>
      );
    },
  },
];

export const getParent = (parentId, services) => {
  if (!parentId || !services) {
    return null;
  }
  const parent = services.find((service) => service.id === parentId);
  return parent ? parent : null;
};

export const loadingPlaceholderService = {
  id: 14,
  name: "",
  unitType: null,
  baseLaborCost: null,
  permit: null,
  code: null,
};
export const loadingPlaceholderServiceMarket = {
  id: "",
  name: "",
};

export const loadingPlaceholderServicePartner = {
  id: "",
  status: "",
  email: "",
  mobilePhone: "",
  officePhone: "",
  avatar: "",
  addressLine1: "",
  city: "",
  state: "",
  zipCode: "",
  companyName: "",
};
