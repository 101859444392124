import { TableCells, TableFilters, TableCards } from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  "Awaiting Signature": "orange",
  Pending: "orange",
  Unscheduled: "gray",
  Accepted: "green",
  TBD: "gray",
};

export const commonBidsColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "company",
    Header: "Company",
    accessor: (row) => row.partner.companyName,
    Cell: TableCells.TextCell,
  },
  {
    id: "willBid",
    Header: "Will Bid",
    accessor: (originalRow) => !!originalRow.willBid,
    Cell: TableCells.BooleanCell,
  },
  {
    id: "createdAt",
    Header: "Created Date",
    accessor: "createdAt",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  {
    id: "dueDate",
    Header: "Due Date",
    accessor: "dueDate",
    Cell: TableCells.DateCell,
    showTimeAccessor: true,
    renderCard: TableCards.DateRow,
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
];

export const columns = [...commonBidsColumns];
columns.splice(1, 0, {
  id: "project",
  Header: "Project",
  accessor: (originalRow) =>
    originalRow.project?.places?.addressLine1
      ? `${originalRow.project?.places?.addressLine1} ${originalRow.project?.places?.addressLine2 || ""}`.trim()
      : null,
  Cell: (originalRow) => {
    return (
      <TableCells.AddressCell
        addressCity={originalRow.row.original.project?.places?.city}
        addressState={originalRow.row.original.project?.places?.state}
        addressZip={originalRow.row.original.project?.places?.zipCode}
        {...originalRow}
      />
    );
  },
});

export const BidStatusOptions = ["TBD", "Pending", "Accepted", "Rejected"];

export const loadingPlaceholderBid = {
  id: "",
  createdAt: "",
  dueDate: "",
  willBid: true,
  status: "",
  project: {
    id: "",
    places: {
      addressLine1: "",
      addressLine2: null,
      city: "",
      state: "",
      zipCode: "",
    },
  },
  partner: {
    id: "",
    companyName: "",
  },
};
