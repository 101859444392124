import { TableCells } from "@buildappeal/react-component-library";

export const STATUS_COLOR_CODES = {
  Disabled: "gray",
  Active: "green",
};

export const commonColumns = [
  {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "name",
    Header: "Name",
    accessor: (row) => {
      return (row.firstName || "") + " " + (row.lastName || "") || row.fullName;
    },
    Cell: TableCells.TextCell,
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
    Cell: TableCells.TextCell,
  },
  {
    id: "partners",
    Header: "Partners",
    accessor: (row) => row.partners?.map((partner) => partner.companyName),
    Cell: TableCells.BadgesCell,
    colorAccessor: "gray",
  },
  {
    id: "suppliers",
    Header: "Suppliers",
    accessor: (row) => row.suppliers?.map((supplier) => supplier.name),
    Cell: TableCells.BadgesCell,
    colorAccessor: "gray",
  },
  {
    id: "status",
    Header: "Status",
    accessor: "status",
    Cell: (originalRow) => {
      return <TableCells.BadgeCell color={STATUS_COLOR_CODES[originalRow.getValue()] || "gray"} {...originalRow} />;
    },
  },
];

export const columns = [...commonColumns];

export const loadingPlaceholderContact = {
  id: "",
  fullName: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  avatar: "",
  isPrimary: true,
  createdAt: "",
  partners: [
    {
      id: "",
      companyName: "",
    },
  ],
  suppliers: [],
  status: "",
};

export const contactAssignedTo = {
  SUPPLIER: "Supplier",
  PARTNER: "Partner",
};
