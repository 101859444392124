import React, { useState } from "react";
import noop from "lodash/noop";
import { CreateEmail } from "@buildappeal/react-component-library";
import { v4 as uuidv4 } from "uuid";
import useToast from "@utils/hooks/useToast";
import isMediaFile from "@src/utils/isMedia";
import { algoliaSearchClient } from "@utils/algolia";
import useSendEmail from "@features/emails/hooks/useSendEmail";
import { FILE_CONTENT_TYPES, FILE_CONTENT_TYPES_LABEL, FILE_PATHS, FILE_TYPES } from "@src/features/files/utils";
import useEmails from "@src/features/emails/hooks/useEmails";
import useUser from "@src/features/auth/hooks/useUser";
import { prepareAttachments } from "../utils";
import useMultiFilesUpload from "../../files/hooks/useMultiFilesUpload";

const SendEmail = ({
  isOpen,
  onClose,
  projectId,
  to = [],
  onSuccess = noop,
  pageLevel,
  isInSidebar = false,
  fromType = "client",
  showFrom = true,
}) => {
  const { refetch } = useEmails();

  const { addToast } = useToast();
  const { startUpload } = useMultiFilesUpload();
  const { user } = useUser();
  const [isUploading, setIsUploading] = useState(false);
  const [isEmailSendLoading, setIsEmailSendLoading] = useState(false);

  const filters = projectId ? { projectIds: [projectId] } : {};
  const { sendEmailAsync, isLoading: isEmailSending } = useSendEmail(filters, {
    showToast: true,
    successCallback: onSuccess,
    pageLevel,
    updateCache: false,
  });

  const uploadFiles = async (emailId, filesList) => {
    const finalFilesList = (filesList || []).map((file) => {
      const fileKey = `${FILE_PATHS[FILE_CONTENT_TYPES.email]}/${emailId}/${uuidv4()}`;
      return {
        key: fileKey,
        contentType: FILE_CONTENT_TYPES_LABEL[FILE_CONTENT_TYPES.email],
        type: isMediaFile(file?.type) ? FILE_TYPES.media : FILE_TYPES.document,
        file,
        emailId,
      };
    });
    const results = await startUpload(finalFilesList);
    if (results?.uploadCount !== finalFilesList.length) {
      return null;
    }
    return results.uploadedFiles;
  };

  const sendEmailRequest = async ({ to, cc, bcc, message, subject, projectId, attachments, files, clientId }) => {
    const sendEmailResp = await sendEmailAsync({
      to,
      cc,
      bcc,
      messageBody: message,
      projectsId: projectId,
      subject,
      attachments,
      clientId,
    });
    setIsEmailSendLoading(false);
    if (sendEmailResp.sendEmail.success) {
      if (files.length) {
        setIsUploading(true);
        const emailId = sendEmailResp?.sendEmail?.data?.id;
        await uploadFiles(emailId, files);
        setIsUploading(false);
      }
      refetch();
      onClose();
    }
  };

  const handleSendEmail = async ({ to: toList, cc: ccList, bcc: bccList, message, subject, files }) => {
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 5242880) {
          addToast(` File size exceeds 5 mb (${files[i].name})`);
          return;
        }
      }
    }

    const attachments = files.length ? await prepareAttachments(files) : [];
    const to = toList?.map((item) => item.email)?.filter(Boolean);
    const cc = ccList?.map((item) => item.email)?.filter(Boolean);
    const bcc = bccList?.map((item) => item.email)?.filter(Boolean);
    const firstClient = toList?.find((item) => {
      return item.type === "client";
    });
    const clientId = projectId ? toList?.[0]?.id : firstClient?.id;
    let finalProjectId = projectId;
    if (firstClient && !projectId) {
      finalProjectId = firstClient?.projects?.[0]?.id;
    }

    sendEmailRequest({ to, cc, bcc, message, subject, projectId: finalProjectId, attachments, files, clientId });
  };

  return isOpen ? (
    <CreateEmail
      isOpen={isOpen}
      onClose={onClose}
      to={to}
      onSend={handleSendEmail}
      algoliaIndices={["people"]}
      algoliaSearchClient={algoliaSearchClient}
      isLoading={isEmailSending || isUploading || isEmailSendLoading}
      isInSidebar={isInSidebar}
      fromType={fromType}
      showFrom={showFrom}
      fromUserName={showFrom ? `${user?.firstName} ${user?.lastName}` : ""}
    />
  ) : null;
};

export default SendEmail;
