/* eslint react/forbid-prop-types: 0 */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { useLocation, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { Table } from "@buildappeal/react-component-library";
import { variantIdRegex } from "../utils";
import { QUERY_PARAMS } from "../constants";

const LeftColUI = ({
  fields = [],
  cardFields = [],
  data = [],
  onRowClick = noop,
  onRowHover = noop,
  onLayoutChange = noop,
  isLoading,
  isCollapsed = false,
  isHide = false,
  title,
  onCreate,
  createActions = [],
  createComponent,
  layout,
  selectedItem,
  isCustom = false,
  customLayout,
  tableColumnVisibility = {},
  className = "",
  selectedMultipleItems = [],
  onMultiSelect = noop,
  allowMultiSelect = false,
  viewControls,
  viewButtonGroup,
  activeView,
  totalCount,
  showLabelSpinner = false,
  overrideTotalCount = false,
  hideFilters = false,
  boardViewDataTransform,
  dataQueryIdAccessorFn,
  dataQueryIdFilterFn,
  externalFilters,
  onClearExternalFilters,
  hasExternalFiltersApplied,
  hidePagination,
  search: SearchComponent,
  pageProps,
  boardStages,
  showAllStages = false,
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const selectedMultipleItemsRows = useMemo(() => {
    const selectedFilesIndexes = [];
    selectedMultipleItems.forEach((item) => {
      selectedFilesIndexes.push(data.findIndex((d) => d.id === item.id));
    });
    return selectedFilesIndexes;
  }, [selectedMultipleItems]);

  const handleClick = (e, row) => {
    const id = dataQueryIdAccessorFn(row?.original);

    if (e.metaKey || e.ctrlKey) {
      const additionalQueryParam = searchParams.get("openIn") ? `&openIn=${searchParams.get("openIn")}` : "";
      window.open(`${location?.pathname}?id=${id}${additionalQueryParam}`, "_blank");
      e.stopPropagation();
    } else {
      if (!selectedMultipleItems.length) {
        onRowClick(row?.original);
      }
    }
  };

  const handleHover = (_, row) => {
    onRowHover(row?.original);
  };

  const handleTableRowSelection = (rowsSelected) => {
    if (selectedItem) {
      onRowClick(null);
    }
    const selectedItems = rowsSelected.map((itemIndex) => data[itemIndex]);
    onMultiSelect(selectedItems);
  };

  useEffect(() => {
    if (searchParams.has("id") && !isLoading && data?.length > 0) {
      let qpId = searchParams.get(QUERY_PARAMS.Id);
      if (!variantIdRegex.test(qpId)) {
        qpId = parseInt(searchParams.get(QUERY_PARAMS.Id), 10);
        qpId = Number.isNaN(qpId) ? searchParams.get(QUERY_PARAMS.Id) : qpId;
      }
      const row = data.find((item) => dataQueryIdFilterFn(qpId, item));
      row && onRowClick(row);
    }
  }, [isLoading, data]);

  return (
    <div data-testid="LeftColUI">
      {isCustom && !isHide ? customLayout : null}
      <Table
        containerClassName={clsx(isCustom || isHide ? "hidden" : "")}
        columns={fields}
        cardColumns={cardFields}
        data={data}
        isCollapse={isCollapsed}
        isLoading={isLoading}
        label={title}
        onCreate={onCreate}
        layout={layout}
        stickyTop={53}
        getRowProps={(row) => ({
          isSelected: dataQueryIdAccessorFn(selectedItem) === dataQueryIdAccessorFn(row?.original),
          onClick: (e) => {
            handleClick(e, row);
          },
          onMouseEnter: (e) => {
            handleHover(e, row);
          },
          onMouseLeave: (e) => {
            handleHover(e, null);
          },
          className:
            dataQueryIdAccessorFn(selectedItem) === dataQueryIdAccessorFn(row?.original)
              ? "children:bg-neutral-100  dark:children:bg-neutral-900 children:rounded-lg"
              : "",
        })}
        columnVisibility={{ ...tableColumnVisibility, select: isCollapsed ? false : true }}
        createActions={createActions}
        createComponent={createComponent}
        activeSearchParams
        onLayoutChange={onLayoutChange}
        selectedRows={selectedMultipleItemsRows}
        onRowSelection={allowMultiSelect ? handleTableRowSelection : undefined}
        viewControls={viewControls}
        activeView={activeView}
        totalCount={totalCount}
        showLabelSpinner={showLabelSpinner}
        overrideTotalCount={overrideTotalCount}
        boardViewDataTransform={boardViewDataTransform}
        onBoardViewItemClick={onRowClick}
        hideFilters={hideFilters}
        externalFilters={externalFilters}
        onClearExternalFilters={onClearExternalFilters}
        hasExternalFiltersApplied={hasExternalFiltersApplied}
        searchComponent={SearchComponent}
        hidePagination={hidePagination}
        pageProps={pageProps}
        viewButtonGroup={viewButtonGroup}
        boardStages={boardStages}
        showAllStages={showAllStages}
      />
    </div>
  );
};

LeftColUI.propTypes = {
  fields: PropTypes.array,
  cardFields: PropTypes.array,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  onRowHover: PropTypes.func,
  onLayoutChange: PropTypes.func,
  isCollapsed: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  onCreate: PropTypes.func,
  selectedItem: PropTypes.object,
  layout: PropTypes.arrayOf(PropTypes.string),
  isCustom: PropTypes.bool,
  customLayout: PropTypes.node,
  tableColumnVisibility: PropTypes.object,
  className: PropTypes.string,
  createActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      wrapper: PropTypes.element,
      onClick: PropTypes.func,
      icon: PropTypes.func,
      avatar: PropTypes.string,
    })
  ),
  createComponent: PropTypes.element,
  isHide: PropTypes.bool,
  allowMultiSelect: PropTypes.bool,
  selectedMultipleItems: PropTypes.arrayOf(PropTypes.object),
  onMultiSelect: PropTypes.func,
  viewControls: PropTypes.element,
  activeView: PropTypes.oneOf(["table", "map", "card", "board"]),
  totalCount: PropTypes.number,
  showLabelSpinner: PropTypes.bool,
  overrideTotalCount: PropTypes.bool,
  boardViewDataTransform: PropTypes.func,
  hideFilters: PropTypes.bool,
  viewButtonGroup: PropTypes.element,
};

export default LeftColUI;
