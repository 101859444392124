import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  UilAngleLeft as ArrowLeft,
  UilAngleRight as ArrowRight,
  UilInfoCircle as InfoIcon,
  UilImport as DownloadIcon,
  UilTimes as CloseIcon,
} from "@iconscout/react-unicons";
import { UimSpinner as SpinnerIcon } from "@iconscout/react-unicons-monochrome";

import { Audio, Modal, IconButton, Button, Video } from "@buildappeal/react-component-library";
import { ButtonAppearance } from "@utils/common";
import CloudinaryImage from "@utils/components/CloudinaryImage";
import { getFileType } from "@features/files/utils";
import FileInfo from "../FileInfo";
import PdfDocument from "./PdfDocument";

export const FileModal = ({
  isOpen,
  onClose,
  src,
  fileName,
  fileType = "images",
  onPrevious,
  onNext,
  onDownload,
  size,
  created,
  by,
  editable,
  tags,
  onSaveTags,
  description,
  onSaveDescription,
  space,
  onSaveSpace,
  spaceOptions,
  contentType,
  onSaveContentType,
  contentTypeOptions,
  videoProps = {},
  isShowInfoButton = true,
  imgTransformations = {},
  onRename,
  fileUrl,
  link,
  mRef = null,
  metadata,
  onEdit,
  isLoading,
}) => {
  const imgHeight = metadata?.height > window?.innerHeight - 160 ? window?.innerHeight - 160 : metadata?.height;
  const [showEditModal, setShowEditModal] = React.useState(false);

  const { isPdf, isVideo, isImage, isAudio, isOther } = getFileType(fileType);

  const handleKeyActions = useCallback(
    (e) => {
      if (e.keyCode === 37) {
        onPrevious?.();
      } else if (e.keyCode === 39) {
        onNext?.();
      }
    },
    [onPrevious, onNext]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyActions);

    return () => {
      window.removeEventListener("keydown", handleKeyActions);
    };
  }, [handleKeyActions]);

  return (
    <Modal
      isOpen={isOpen}
      fullScreen={true}
      customCloseButtonIcon={CloseIcon}
      onClose={onClose}
      title={fileName}
      titleClassName="text-sm font-normal text-neutral-800"
      classNameBg="bg-white dark:bg-midnight"
      leftHeader={
        <div className="flex items-center space-x-3">
          {isShowInfoButton && (
            <IconButton
              icon={InfoIcon}
              size="md"
              onClick={() => {
                onEdit ? onEdit() : setShowEditModal(true);
              }}
            />
          )}
          {onDownload && (
            <>
              <Button
                label="Download"
                appearance={ButtonAppearance.SECONDARY}
                icon={DownloadIcon}
                onClick={onDownload}
                className="hidden lg:flex"
              />
              <IconButton icon={DownloadIcon} size="md" onClick={onDownload} className="flex lg:hidden" />
            </>
          )}
        </div>
      }
      mRef={mRef}
    >
      <>
        <div className="absolute top-20 left-0 right-0 bottom-0" data-testid="FileModal">
          {onPrevious && (
            <div className="fixed left-8 top-1/2 hidden lg:block">
              <IconButton color="light" icon={ArrowLeft} size="md" onClick={onPrevious} />
            </div>
          )}
          <div className="item-center flex h-full w-full justify-center p-10">
            {isLoading ? <SpinnerIcon role="status" className="mr-2 h-10 w-10 animate-spin fill-primary-700" /> : null}
            {isPdf && !!src && <PdfDocument file={src} />}
            {isImage && !!src && (
              <div
                style={{
                  height: imgHeight,
                }}
              >
                <CloudinaryImage
                  imgSrc={src}
                  alt="File image"
                  className=""
                  transformations={{
                    ...imgTransformations,
                    height: imgHeight,
                    lazyload: true,
                  }}
                />
              </div>
            )}
            {isVideo && !!src && <Video videoPath={src} {...videoProps} />}
            {isAudio && !!src && <Audio path={src} className="flex w-3/4 items-center justify-center"></Audio>}
            {isOther && <span className="text-center text-xl font-medium text-neutral-800">No Preview Available</span>}
          </div>
          {onNext && (
            <div className="fixed right-8 top-1/2 hidden lg:block">
              <IconButton color="light" icon={ArrowRight} size="md" onClick={onNext} />
            </div>
          )}
        </div>
        {!onEdit && (
          <Modal
            customCloseButtonIcon={CloseIcon}
            isOpen={showEditModal}
            onClose={() => setShowEditModal(false)}
            title="File Info"
          >
            <div>
              {fileName && (
                <FileInfo
                  imageVisible={false}
                  fileName={fileName}
                  size={size}
                  created={created}
                  by={by}
                  tags={tags}
                  onSaveTags={onSaveTags}
                  description={description}
                  onSaveDescription={onSaveDescription}
                  space={space}
                  spaceOptions={spaceOptions}
                  onSaveSpace={onSaveSpace}
                  contentType={contentType}
                  onSaveContentType={onSaveContentType}
                  contentTypeOptions={contentTypeOptions}
                  editable={editable}
                  onRename={onRename}
                  fileUrl={fileUrl}
                  onDownload={onDownload}
                  link={link}
                />
              )}
            </div>
          </Modal>
        )}
      </>
    </Modal>
  );
};

FileModal.propTypes = {
  isOpen: PropTypes.bool,
  fileName: PropTypes.string,
  fileType: PropTypes.string,
  size: PropTypes.string,
  created: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  by: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    initials: PropTypes.string,
  }),
  editable: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  onSaveTags: PropTypes.func,
  description: PropTypes.string,
  onSaveDescription: PropTypes.func,
  space: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
  }),
  spaceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  onSaveSpace: PropTypes.func,
  contentType: PropTypes.string,
  onSaveContentType: PropTypes.func,
  contentTypeOptions: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  src: PropTypes.string,
  fileUrl: PropTypes.string,
  link: PropTypes.string,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onDownload: PropTypes.func,
  onRename: PropTypes.func,
  isShowInfoButton: PropTypes.bool,
  mRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line
  videoProps: PropTypes.object, // eslint-disable-line
  imgTransformations: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    responsive: PropTypes.bool,
    lazyload: PropTypes.bool,
  }),
  metadata: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  onEdit: PropTypes.func,
};

export default FileModal;
