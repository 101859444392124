import { NavCard, TableCards, TableCells, TableFilters } from "@buildappeal/react-component-library";

export const servicesColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
  name: {
    id: "name",
    Header: "Name",
    accessor: "name",
    Cell: TableCells.TextCell,
    renderCard: ({ row: { original }, column }) => {
      return (
        <NavCard
          layout="card"
          title={original.name}
          onOpenClick={(actionId) => column.onOpenClick(actionId, original)}
          className="mb-3"
          showPlaceholder
        />
      );
    },
  },
  parent: {
    id: "parent",
    Header: "Parent",
    accessor: (row) => row.parent?.name,
    Cell: TableCells.TextCell,
    renderCard: TableCards.TextRow,
  },
};

export const servicePartnersColumns = {
  id: {
    id: "id",
    Header: "ID",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  companyName: {
    id: "partner",
    Header: "Partner",
    accessor: "companyName",
    Cell: (originalRow) => {
      const { original } = originalRow.row;
      return (
        <div className="flex">
          <TableCells.TitleCell
            value={original?.companyName}
            subtitle={original?.fullName}
            row={originalRow.row}
            column={originalRow.column}
            {...originalRow}
          />
        </div>
      );
    },
  },
  services: {
    id: "services",
    Header: "Services",
    accessor: (originalRow) => {
      return originalRow?.services?.map((service) => service.name);
    },
    Cell: TableCells.BadgesCell,
    Filter: TableFilters.SelectColumnFilter,
    filter: TableFilters.filterMultiSelect,
  },
};
