import { TableCells, TableCards } from "@buildappeal/react-component-library";
import generateInitials from "../../utils/generateInitials";

export const columns = [
  {
    id: "id",
    Header: "Id",
    accessor: "id",
    Cell: TableCells.TextCell,
  },
  {
    id: "name",
    Header: "Name",
    accessor: "fullName",
    Cell: (originalRow) => {
      return (
        <TableCells.UserCell
          avatarSrc={originalRow.row.original.avatar || ""}
          initials={generateInitials(originalRow.value || originalRow.row.original.email)}
          {...originalRow}
        />
      );
    },
  },
  {
    id: "email",
    Header: "Email",
    accessor: "email",
    Cell: TableCells.TextCell,
  },
  {
    id: "phone",
    Header: "Phone",
    accessor: "phone",
    Cell: TableCells.PhoneNumberCell,
  },

  {
    id: "lastContact",
    Header: "Last Contact",
    accessor: "lastContact",
    Cell: TableCells.TextCell,
  },
  {
    id: "lastLogin",
    Header: "Last Login",
    accessor: (originalRow) => {
      if (!originalRow.lastLogin) return "";
      const dt = new Date(originalRow.lastLogin);
      return dt.toDateString();
    },
    Cell: TableCells.DateCell,
    renderCard: TableCards.DateRow,
  },
];

export const loadingPlaceholderClient = {
  id: "",
  fullName: "",
  firstName: "",
  lastName: "",
  email: "",
  avatar: null,
  phone: "",
  type: "",
  status: "",
  lastContact: null,
  createdAt: "",
  lastLogin: "",
  clientBudgetLow: "",
  clientBudgetHigh: "",
  projects: [],
  staff: {
    avatar: "",
    fullName: "",
    email: "",
  },
  rooms: [],
  leadStage: {
    id: "",
    name: "",
  },
  leadStageId: 1,
  places: null,
};
